import * as turf from "@turf/turf";
import { Feature, LineString, MultiPolygon, Polygon, Position, destination, length, lineSliceAlong, lineString, point, polygon } from "@turf/turf";
import { customDifference, customUnion, loCustom } from "../..";
import FeatureHelpers from "../../FeatureHelpers";
import { ILineSegment, IPivotSegment, IlateralSegment } from "./interfaces";



export const getSegmentLine_Line = (segment: ILineSegment, radius: number, options?: { trimStart?: number, trimEnd?: number }): Position[] => {
    const multiplier = segment.inside ? -1 : 1;
    const trimStart = options?.trimStart || 0;
    const trimEnd = options?.trimEnd || 0;
    const line = radius === 0
        ? lineString([ segment.p1, segment.p2 ])
        : loCustom(lineString([ segment.p1, segment.p2 ]), radius * multiplier, { units: 'feet' });
    if (trimStart === 0 && trimEnd === 0) {
        return line.geometry.coordinates;
    }
    const len = length(line, { units: 'feet' });
    return lineSliceAlong(
        line,
        trimStart,
        len - trimEnd,
        { units: 'feet' }
    ).geometry.coordinates;
}
export const getSegmentLine_Pivot = (segment: IPivotSegment, radius: number, options?: { trimStart?: number, trimEnd?: number }): Position[] => {
    if (radius === 0) {
        return [ segment.center ];
    }
    const trimStart = options?.trimStart || 0;
    const trimEnd = options?.trimEnd || 0;
    const arc = segment.type === 'clockwise'
        ? FeatureHelpers.GetLineArcDrawFeature(
            point(segment.center).geometry,
            radius,
            segment.b1,
            segment.b2,
            null,
            { units: 'feet', degreeIncrement: 0.1, direction: 'clockwise' }
        )
        : FeatureHelpers.GetLineArcDrawFeature(
            point(segment.center).geometry,
            radius,
            segment.b1,
            segment.b2,
            null,
            { units: 'feet', degreeIncrement: 0.1, direction: 'anticlockwise' }
        );

    if (trimStart === 0 && trimEnd === 0) {
        return arc.geometry.coordinates;
    }
    const len = length(arc, { units: 'feet' });
    const trimmed = lineSliceAlong(
        arc,
        trimStart,
        len - trimEnd,
        { units: 'feet' }
    );
    return trimmed.geometry.coordinates;
}
export const getSegmentLinePositions = (segment: IlateralSegment, radius: number, options?: { trimStart?: number, trimEnd?: number }): Position[] => {
    if (segment.type === 'line') {
        return getSegmentLine_Line(segment, radius, options);
    }
    else {
        return getSegmentLine_Pivot(segment, radius, options);
    }
}

export const getSegmentLength = (segment: (ILineSegment | IPivotSegment), radius: number): number => {
    const line: Feature<LineString> = lineString(getSegmentLinePositions(segment, radius));
    return length(line, { units: 'feet' });
}


export const lateralLineOffset = (
    line: LineString | Feature<LineString>, distance: number, options: { units: turf.Units, arc?: "clockwise" | "anticlockwise" | "natural", debug?: boolean } = { units: 'kilometers' }
): Feature<LineString> => {

    const incommingLine = line.type === 'Feature' 
        ? line.geometry
        : line;

    if (distance === 0) {
        return lineString(incommingLine.coordinates);
    };

    const coords: Position[] = [];

    const segments: { line: LineString, offset: LineString }[] = [];
    for (let i = 0; i < incommingLine.coordinates.length - 1; i++) {
        const c1 = incommingLine.coordinates[i];
        const c2 = incommingLine.coordinates[i+1];
        const ib = turf.bearing(c1, c2) + 90;
        const p1 = destination(c1, distance, ib, { units: options.units });
        const p2 = destination(c2, distance, ib, { units: options.units });
        segments.push({
            line: lineString([ c1, c2 ]).geometry,
            offset: lineString([ p1.geometry.coordinates, p2.geometry.coordinates ]).geometry
        });
    }

    coords.push(segments[0].offset.coordinates[0]);
    for (let i = 0; i < segments.length - 1; i++) {
        const s1 = segments[i];
        const s2 = segments[i + 1];
        const pc = s1.line.coordinates[1];
        const bLeft = turf.bearing(pc, s1.offset.coordinates[1], { final: true });
        const bRight = turf.bearing(pc, s2.offset.coordinates[0], { final: true });

        let arcOption = options.arc;
        // if (!arcOption || arcOption === 'natural') {
        //     const [ xc, yc ] = pc;
        //     const [ x1, y1 ] = s1.offset.coordinates[1];
        //     const [ x2, y2 ] = s2.offset.coordinates[0];
        //     const a1 = Math.atan2(xc - x2, yc - y2) / Math.PI * 180;
        //     const a2 =  Math.atan2(x1 - xc, y1 - yc) / Math.PI * 180;
        //     const a = Math.atan2(xc - x2, yc - y2) - Math.atan2(x1 - xc, y1 - yc);
        //     if (options.debug) console.log("a", a / Math.PI * 180, a1,a2)
        //     if (a < Math.PI && a > 0) {
        //         arcOption = 'clockwise';
        //     }
        //     else if (a > -Math.PI && a < 0) {
        //         arcOption = 'anticlockwise';
        //     }
        // }
        switch (arcOption) {
            case 'clockwise': {
                const arc = FeatureHelpers.GetLineArcDrawFeature(
                    point(pc).geometry, distance, bRight, bLeft, null, { units: options.units, degreeIncrement: 0.1 }
                )
                coords.push(...arc.geometry.coordinates.reverse());
                break;
            }
            case 'anticlockwise': {
                const arc = FeatureHelpers.GetLineArcDrawFeature(
                    point(pc).geometry, distance, bLeft, bRight, null, { units: options.units, degreeIncrement: 0.1 }
                )
                coords.push(...arc.geometry.coordinates);
                break;
            }
            default: {
                const inter = turf.lineIntersect(s1.offset, s2.offset);
                if (!inter.features.length) {
                    coords.push(s1.offset.coordinates[1], s2.offset.coordinates[0]);
                }
                else {
                    if (inter.features.length !== 1) {
                        throw new Error("too many intersections");
                    }
                    coords.push(inter.features[0].geometry.coordinates);
                }
                break;
            }
        }
    }
    coords.push(segments.slice(-1)[0].offset.coordinates[1]);
    
    const f = lineString(coords);
    
    return f;

}

export const getLateralLinePolygons = (
    segments: IlateralSegment[], 
    insideRadius: number, outsideRadius: number,
    exclude: Feature<Polygon | MultiPolygon> | null = null
): { polygons: Polygon[], updatedExclude:  Feature<Polygon | MultiPolygon> | null } => {

    const polygons: Polygon[] = [];
    let updatedExclude: Feature<Polygon | MultiPolygon> | null = exclude;

    const considerPolygon = (poly: Feature<Polygon>) => {
        const d = updatedExclude ? customDifference(poly, updatedExclude) : poly;
        if (d) {
            try {
                updatedExclude = updatedExclude ? customUnion(updatedExclude, d) : d;
            }
            catch (e) {
                console.log(">>", updatedExclude, d)
                throw e;
            }
            if (d.geometry.type === 'Polygon') {
                polygons.push(d.geometry);
            }
            else {
                for (const p of d.geometry.coordinates) {
                    if (p.length && p[0].length > 4) {
                        // only add valid polygons
                        polygons.push(polygon(p).geometry);
                    }
                }
            }
        }
    }
    
    for (let i = 0; i < segments.length; i++) {
        const segment = segments[i];
        const iPositions = getSegmentLinePositions(segment, insideRadius);
        const oPositions = getSegmentLinePositions(segment, outsideRadius);
        const poly = polygon([
            [
                ...iPositions,
                ...oPositions.reverse(),
                iPositions[0]
            ]
        ]);
        considerPolygon(poly);
    }
    return {
        polygons,
        updatedExclude
    };

}

export const getLateralLineString = (
    segments: IlateralSegment[], 
    radius: number
): LineString => {

    const positions: Position[] = [];
    
    for (let i = 0; i < segments.length; i++) {
        const segment = segments[i];
        const oPositions = getSegmentLinePositions(segment, radius);
        positions.push(...oPositions);
    }

    return lineString(positions).geometry;

}


export const getCombineLateralLinePolygons = (
    segments: IlateralSegment[], 
    insideRadius: number, outsideRadius: number,
    combined: Feature<Polygon | MultiPolygon> | null = null
): Feature<Polygon | MultiPolygon> | null => {

    let updatedCombined: Feature<Polygon | MultiPolygon> | null = combined;

    const considerPolygon = (poly: Feature<Polygon | MultiPolygon>) => {
        try {
            updatedCombined = updatedCombined ? customUnion(updatedCombined, poly) : poly;
        }
        catch (e) {
            console.log(">>", updatedCombined, poly)
            throw e;
        }
    }
    
    const polys: Position[][][] = [];
    for (let i = 0; i < segments.length; i++) {
        const segment = segments[i];
        // the opposing line segmnets when retracing cause artifacts in the
        // mapbox rendered polygon. As such, if we are retracing and the
        // inside radius is 0. Extend this inside the pivot slightly using -1.
        const ir = (insideRadius === 0 && segment.retracing && segment.type === 'line') ? -1 : insideRadius;
        const iPositions = getSegmentLinePositions(segment, ir);
        const oPositions = getSegmentLinePositions(segment, outsideRadius);
        polys.push([
            [
                ...iPositions,
                ...oPositions.reverse(),
                iPositions[0]
            ]
        ])
    }
    // The seams can sometimes cause problems with union:
    for (let i = 0; i < segments.length - 1; i++) {
        const segment1 = segments[i];
        const segment2 = segments[i + 1];
        const iSegment1Positions = getSegmentLinePositions(segment1, insideRadius);
        const oSegment1Positions = getSegmentLinePositions(segment1, outsideRadius);
        const iSegment2Positions = getSegmentLinePositions(segment2, insideRadius);
        const oSegment2Positions = getSegmentLinePositions(segment2, outsideRadius);
        polys.push([ 
            [
                iSegment1Positions.slice(-1)[0], 
                iSegment2Positions[0], 
                oSegment2Positions[0], 
                oSegment1Positions.slice(-1)[0], 
                iSegment1Positions.slice(-1)[0], 
            ]
        ]);
    }
    if (polys.length) {
        considerPolygon(turf.multiPolygon(polys));
    }
    return updatedCombined;
}

export const trimAndMutateSegments = (combinedSegments: IlateralSegment[], start: number, end: number, runningLength: number) => {
    const leftLostSegments: IlateralSegment[] = [];
    const rightLostSegments: IlateralSegment[] = [];
    if (start === 0 && end === 0) {
        return { leftLostSegments, rightLostSegments };
    }
    let trimStart = start;
    while (combinedSegments.length && trimStart >= getSegmentLength(combinedSegments[0], runningLength)) {
        trimStart -= getSegmentLength(combinedSegments[0], runningLength);
        leftLostSegments.push(...combinedSegments.splice(0, 1));
    }
    if (combinedSegments.length && trimStart) {
        const startSegment = combinedSegments[0];
        if (startSegment.type === 'line') {
            const segmentCopy = structuredClone(startSegment);
            const newLine = getSegmentLine_Line(startSegment, 0, { trimStart });
            startSegment.p1 = newLine[0];
            segmentCopy.p2 = newLine[0];
            leftLostSegments.push(segmentCopy);
        }
        else {
            const segmentCopy = structuredClone(startSegment);
            const newLine = getSegmentLine_Pivot(startSegment, runningLength, { trimStart });
            const p = newLine[0];
            startSegment.b1 = turf.bearing(startSegment.center, p, { final: true });
            segmentCopy.b2 = startSegment.b1;
            leftLostSegments.push(segmentCopy);
        }
    }
    
    let trimEnd = end;
    while (combinedSegments.length && trimEnd >= getSegmentLength(combinedSegments.slice(-1)[0], runningLength)) {
        trimEnd -= getSegmentLength(combinedSegments.slice(-1)[0], runningLength);
        rightLostSegments.push(...combinedSegments.splice(-1, 1));
    }
    if (combinedSegments.length && trimEnd) {
        const endSegment = combinedSegments.slice(-1)[0];
        if (endSegment.type === 'line') {
            const segmentCopy = structuredClone(endSegment);
            const newLine = getSegmentLine_Line(endSegment, 0, { trimEnd });
            endSegment.p2 = newLine[1];
            segmentCopy.p1 = newLine[1];
            rightLostSegments.push(segmentCopy);
        }
        else {
            const segmentCopy = structuredClone(endSegment);
            const newLine = getSegmentLine_Pivot(endSegment, runningLength, { trimEnd });
            const p = newLine.slice(-1)[0];
            endSegment.b2 = turf.bearing(endSegment.center, p, { final: true });
            segmentCopy.b1 = endSegment.b2;
            rightLostSegments.push(segmentCopy);
        }
    }
    return { leftLostSegments, rightLostSegments };
}