import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { IconButton, Tooltip } from "@mui/material";
import i18next from "i18next";
import IAction from "rdptypes/IAction";
import * as React from "react";
import AuthCtx from "../../../../auth/AuthCtx";
import IDbState from "../../../../db/IDbState";
import { importFieldJson } from './importFieldJson';
import { importFieldXml } from './importFieldXml';

interface Props {
    dbState: IDbState;
    gid: string;
}

const ImportFieldButton: React.FC<Props> = (props) => {

    const authState = React.useContext(AuthCtx);
    
  const handleImportField = async (ev: React.ChangeEvent<HTMLInputElement>) => {
    if (props.dbState.growers[props.gid]?.owner !== authState.assumedUserId &&
        props.dbState.growers[props.gid]?.sharedWithDealership === "readonly") {
            alert("You do not have permission to create fields for this grower.");
            return;
        }

    if (!ev.target.files || ev.target.files.length !== 1) {
        console.log("No file selected");
        ev.target.value = "";
        return;
    }

    const file = ev.target.files[0];
    ev.target.value = "";

    let actions: IAction[];
    if (file.name.toLowerCase().endsWith(".xml")) {
        actions = await importFieldXml(file, props.gid, authState);
    } else {
        actions = await importFieldJson(file, props.gid, authState);
    }
    if (actions.length) {
        const projectId = props.dbState.newProject(actions);
        console.log("Imported as ", projectId);
    }
  }

    return (
        <Tooltip title={i18next.format(i18next.t("import-field"), 'capitalize-each')} placement="bottom">
            <IconButton component="label" color="warning">
                <FileUploadOutlinedIcon/>
                <input type="file" hidden onChange={handleImportField} accept=".json,.xml" />
            </IconButton>
        </Tooltip>
    );
};

export default ImportFieldButton;
