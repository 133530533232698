import IAction from "rdptypes/IAction";
import IActionData from "rdptypes/IActionData";
import IAuthState from '../auth/IAuthState';
import { setValue } from '../helpers/objectPathResolver';
import ILayout from "../model/project/ILayout";
import IProject from "../model/project/IProject";
import { registerExecutor } from "./actionExecutorRegistry";
import { createAction, recordProjectChange } from './helpers';

const actionTypeId = "UpdateProjectProperty";

interface ActionData extends IActionData {
    property: string;
    value: any;
}

registerExecutor(actionTypeId, (action: IAction, state: IProject) => {
    const data = action.data as ActionData;

    if (data.property === "layouts") {
        // The JSON field import works by setting the layouts array directly. This should probably
        // use the CreateSystemAction instead, but it doesn't (yet).

        // If necessary we have to set the partsPackageId on each system to the initial value.

        const value: { [layoutId: string]: ILayout } = { ...data.value };
        for (const layout of Object.values(value)) {
            for (const sys of Object.values(layout.systems)) {
                if (!sys.partsPackageId) {
                    sys.partsPackageId = "2024060101";
                }
            }
        }

        setValue(state, data.property, value);
    } else {
        setValue(state, data.property, data.value);
    }

    recordProjectChange(action, state);
});

export const createNewUpdateProjectPropertyAction =
    (property: string, value: any, authState: IAuthState) => createAction(
        actionTypeId,
        {
            property,
            value
        } as ActionData,
        authState);