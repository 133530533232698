import { ISystemBase, SpanTypes, TireTypes, TowerHeights } from "rdptypes/project/ISystemBase.AutoGenerated";
import { GetTireHeightAdjustment } from "./SideFunctions";
import { HasSwingArmCorner, IsReverseTow } from "./SystemFunctions";
import { Side, Tower } from "./Types";
import { TowerAirCompressorOK, TowerControlLineBoosterPumpOK } from "./Valids.Valids";

export enum idxLeadType {
    ltStandard,
    ltLowProfile,
    ltSugargator,
    ltLong,
    ltSupergator,
}

    /**
     * A-B-C Boxes control the movement of the system. 
     */
    export enum BoxTypes {
        /**
         * A-Box is the standard control box for towers. Electrical controls (switches) go in these boxes. 
         */
        ABox,
        
        
        /**
         * B-Box is special because it has an "over-watering timer" and is ALWAYS before
         * the C Box. (Exceptions are Drop Span may not have a B-Box) 
         */
        BBox,
        
        /**
         * C-Box has fewer components in it. The C box is always at the LAST Tower.
         * The %timer tells the C-Box how fast to go. All other towers try to 
         * stay in-line with (follow) the last tower.B-Box is special because it has an "over-watering timer" and is ALWAYS before
         * the C Box. (Exceptions are Drop Span may not have a B-Box) 
         */
        CBox,
        /**
         * Box used to control sprinklers on a Pivoting Lateral exclusively 
         * when the Dual Sprinkler Package option is selected
         */
        SprinklerControlBox,
        
        /**
         * Same as an A-Box with guidance equipment in it. 
         */
        GuidanceBox
}

export const GetTowerHeight = (sys: ISystemBase, side: Side, tower: Tower) => {
    let tha = GetTireHeightAdjustment(tower.Tires);
    if (tower.TowerNumber !== side.Tower.length || !HasSwingArmCorner(sys)){
        if (!IsReverseTow(sys)){
            let st = side.Span[tower.TowerNumber - 1].SpanType;

            if (tower.TowerType === TowerHeights.LowProfile){
                if (st === SpanTypes.A60G) return 8 + tha;
                return 9 + GetTireHeightAdjustment(tower.Tires);
            }
            if (tower.TowerType === TowerHeights.Sugargator){
                if (st === SpanTypes.A80G) return 16 + tha;
                return 18 + tha;
            }
            if (st === SpanTypes.AlumIV || st === SpanTypes.A60G) return 12 + tha;
            return 13 + tha;
        }
        return 13 + tha;
    }

    if (tower.TowerType === TowerHeights.LowProfile) return 8 + tha;
    return 12 + tha;
}

export const AllSideTowerTiresNonDirectional = (side: Side) => 
    side.Tower.length && side.Tower.every(t => t.Tires.TireType === TireTypes.NonDirectional);

export const getTowerForAirCompressor_post2024081901 = (sys: ISystemBase, side: Side): { airCompressor: false } | { airCompressor: true, towerIdx: number } => {
    // NOTE: The Air Compressor will take priority of finding an available valid tower to fit too.
    // The Control Line Booster Pump will then try and fit after the Air Compressor has been fit.
    // This order has been chosen based on the electrical calculators use of air compressor location,
    // and I see no dependency on the location of the Control Line Booster Pump.
    const AirCompressor = !!sys.TowerProperties.AirCompressor;
    if (sys.partsPackageId < "2024081901" || !AirCompressor) {
        return { airCompressor: false }
    }
    for (let i = 0; i < side.Tower.length; i++) {
        const tower = side.Tower[i];
        if (
            TowerAirCompressorOK(AirCompressor, {
                WrapAroundSpanType: tower.WrapAroundSpan, 
                AquaValvesChecked: tower.AutomatedValves, 
                ControlLineBoosterPumpChecked: false
            })
        ) {
            return { airCompressor: true, towerIdx: i }
        }
    }
    return { airCompressor: true, towerIdx: -1 }
}
export const getTowerForControlLineBoosterPump_post2024081901 = (sys: ISystemBase, side: Side): { controlLineBoosterPump: false } | { controlLineBoosterPump: true, towerIdx: number } => {
    // NOTE: The Air Compressor will take priority of finding an available valid tower to fit too.
    // The Control Line Booster Pump will then try and fit after the Air Compressor has been fit.
    // This order has been chosen based on the electrical calculators use of air compressor location,
    // and I see no dependency on the location of the Control Line Booster Pump.
    const ControlLineBoosterPump = !!sys.TowerProperties.ControlLineBoosterPump;
    if (sys.partsPackageId < "2024081901" || !ControlLineBoosterPump) {
        return { controlLineBoosterPump: false };
    }
    const airCompressor = getTowerForAirCompressor_post2024081901(sys, side);
    if (airCompressor.airCompressor && airCompressor.towerIdx === -1) {
        return { controlLineBoosterPump: true, towerIdx: -1 };
    }
    for (let i = 0; i < side.Tower.length; i++) {
        const tower = side.Tower[i];
        if (
            TowerControlLineBoosterPumpOK(ControlLineBoosterPump, {
                WrapAroundSpanType: tower.WrapAroundSpan, 
                AquaValvesChecked: tower.AutomatedValves,
                AirCompressorChecked: airCompressor.airCompressor 
                    ? airCompressor.towerIdx === i
                        ? true
                        : false
                    : false
            })
        ) {
            return { controlLineBoosterPump: true, towerIdx: i };
        }
    }
    return { controlLineBoosterPump: true, towerIdx: -1 };
}