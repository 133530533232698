import { Text, View } from "@react-pdf/renderer";
import i18next, { t } from "i18next";
import { IMiscItem } from "rdptypes/project/ISystemBase.AutoGenerated";
import { ProposalSection } from "rdptypes/roe/IPartsCatalogEntry";
import * as React from "react";
import { FC } from "react";
import { partsPackages } from "roedata/data";
import { ISectionGroupedParts } from "roedata/roe_migration/PriceCalculator";
import QuoteClass from "roedata/roe_migration/QuoteClass";
import { HasSwingArmCorner } from "roedata/roe_migration/SystemFunctions";
import { IAncillaryPart, IGroupedSystemParts, proposalSectionIsAncillary } from "../../components/roe/componentRenderers/Proposal/ProposalHelpers";
import ISystem from "../../model/project/ISystem";
import DealerDataCtx from "../../userData/DealerDataCtx";
import { IDocumentType, formatCents } from "../DocumentGenerationHelpers";
import { documentStyles, primaryColor } from "../styles";
import PurchaseOrderTotalPricesTable from "./PurchaseOrderTotalPricesTable";
import TotalPricesTable from "./TotalPricesTable";
import FixedWidthTable, { ICell, IRow } from "./table/FixedWidthTable";

interface Props {
    groupedSystemParts: {partGroups: IGroupedSystemParts, partSections: ISectionGroupedParts};
    quote: QuoteClass;
    miscItems: IMiscItem[];
    system: ISystem;
    docType: IDocumentType;
};

export interface IMainlineValueInfo {
    basePartNumber: string;
    description: string;
    priceUsdCents: number;
    quantity: number;
}

const SystemComponentsTable: FC<Props> = (props) => {
    const user = React.useContext(DealerDataCtx);

    let {groupedSystemParts, quote, miscItems, system, docType} = props;

    const { roeData } = partsPackages[system.partsPackageId];

    miscItems = miscItems.filter(x => x.description);

    let standardRows: IRow[] = [{
        cells: [
            {
                text: t("common-phrases.sltQty"),
                header: true,
                widthPc: 7
            },
            {
                text: docType === IDocumentType.irrigationSystemPurchaseOrder ? t("common-phrases.sltPartNumber") : "",
                widthPc: docType === IDocumentType.irrigationSystemPurchaseOrder ? 16 : 0,
                header: true
            },
            {
                text: t("common-phrases.sltDescription"),
                header: true,
                widthPc: docType === IDocumentType.irrigationSystemPurchaseOrder ? 62 : 78
            }, 
            {
                text: "",
                header: true,
                widthPc: 15
            }
        ]
    }];

    let upgradeRows: IRow[] = [{
        cells: [
            {
                text: t("common-phrases.sltQty"),
                header: true,
                widthPc: 7
            },
            {
                text: docType === IDocumentType.irrigationSystemPurchaseOrder ? t("common-phrases.sltPartNumber") : "",
                widthPc: docType === IDocumentType.irrigationSystemPurchaseOrder ? 16 : 0,
                header: true
            },
            {
                text: t("common-phrases.sltDescription"),
                header: true,
                widthPc: docType === IDocumentType.irrigationSystemPurchaseOrder ? 62 : 78
            }, 
            {
                text: t("common-phrases.sltPrice"),
                header: true,
                widthPc: 15
            }
        ]
    }];
    let optionRows: IRow[] = [{
        cells: [
            {
                text: t("common-phrases.sltQty"),
                header: true,
                widthPc: 7
            },
            {
                text: docType === IDocumentType.irrigationSystemPurchaseOrder ? t("common-phrases.sltPartNumber") : "",
                widthPc: docType === IDocumentType.irrigationSystemPurchaseOrder ? 16 : 0,
                header: true
            }, 
            {
                text: t("common-phrases.sltDescription"),
                header: true,
                widthPc: docType === IDocumentType.irrigationSystemPurchaseOrder ? 62 : 78
            }, 
            {
                text: t("common-phrases.sltPrice"),
                header: true,
                widthPc: 15
            }
        ]
    }];

    let ancillaryRows: IRow[] = [{
        cells: [
            {
                text: t("common-phrases.sltQty"),
                header: true,
                widthPc: 7
            },
            {
                text: docType === IDocumentType.irrigationSystemPurchaseOrder ? t("common-phrases.sltPartNumber") : "",
                widthPc: docType === IDocumentType.irrigationSystemPurchaseOrder ? 16 : 0,
                header: true
            }, 
            {
                text: t("common-phrases.sltDescription"),
                header: true,
                widthPc: docType === IDocumentType.irrigationSystemPurchaseOrder ? 62 : 78
            }, 
            {
                text: t("common-phrases.sltPrice"),
                header: true,
                widthPc: 15
            }
        ]
    }];

    let standardPrice = 0;
    let optionsPrice = 0;
    let upgradesPrice = 0;

    let ancillaryPrice = 0;
    let ancillaryParts: IAncillaryPart[] = [];

    for (let sectionId in groupedSystemParts.partSections){
        groupedSystemParts.partSections[sectionId].forEach((p) => {
            let part = roeData.partsCatalog[p.partNumber];

            let desc = (part.customerReportsDescription === null || docType === IDocumentType.irrigationSystemPurchaseOrder || p.groupId.indexOf("Sprinklers_") !== -1) ? 
                part.mapicsDescription : 
                part.customerReportsDescription;

           let cells: ICell[] = [{
                text: p.qty.toString(),
                widthPc: 7
            },
            {
                text: docType === IDocumentType.irrigationSystemPurchaseOrder ? p.partNumber : "",
                widthPc: docType === IDocumentType.irrigationSystemPurchaseOrder ? 16 : 0
            },
            {
                child: <View>
                    <Text><Text style={{fontFamily: "Wingdings", color: primaryColor, fontSize: 8,  marginRight: 3}}>y</Text> {desc!}</Text>
                </View>,
                widthPc: docType === IDocumentType.irrigationSystemPurchaseOrder ? 62 : 78
            }];

            let price = "";
            let partPrice =  user.priceList[p.partNumber].unitPriceUsdCents;
            if (part.proposalSection === ProposalSection.Option){
                price = formatCents(p.qty * partPrice);
                if (proposalSectionIsAncillary(parseInt(sectionId))){
                    ancillaryParts.push({
                        section: parseInt(sectionId),
                        part,
                        qty: p.qty,
                        partPriceCents: partPrice
                    });
                    ancillaryPrice += p.qty * partPrice;
                }
                else {
                    optionsPrice += p.qty * partPrice;
                }
            }
            else if (part.proposalSection === ProposalSection.Upgrade && part.upgradeToPartNumber !== null){
                let standardPartPrice = user.priceList[part.upgradeToPartNumber].unitPriceUsdCents;
                price = formatCents(p.qty * (partPrice - standardPartPrice));
                upgradesPrice += p.qty * (partPrice - standardPartPrice);
                //include standard part in standard price
                standardPrice += p.qty * standardPartPrice;
            }
            else {
                standardPrice += p.qty * partPrice;
            }

            cells.push({
                text: price,
                widthPc: 15
            });

            if (part.proposalSection === ProposalSection.Standard){
                standardRows.push({cells});
            }
            else if (part.proposalSection === ProposalSection.Upgrade){
                upgradeRows.push({cells});
            }
            else if (proposalSectionIsAncillary(parseInt(sectionId))){
                ancillaryRows.push({cells});
            }
            else {
                optionRows.push({cells});
            }
        });
    }

    //Mainline Valves

    system.MainlineValveOptions.Valves.MainlineValve.forEach((valve) => {
        let info = quote.MainlineValvesClass.GetMainlineValveInfo(valve, user.priceList);
        let price = info.Price();
        let costStr = formatCents(price);

        if (!isNaN(price)){
            ancillaryPrice += price;
        }
        else {
            costStr = "Unknown";
        }
        
        let wrappedPartNumber = [info.PartNumber()];
        while (wrappedPartNumber[wrappedPartNumber.length - 1].length > 10) {
            const str = wrappedPartNumber[wrappedPartNumber.length - 1];
            wrappedPartNumber[wrappedPartNumber.length - 1] = str.substring(0, 10);
            wrappedPartNumber.push(str.substring(10));
        }
        let cells: ICell[] = [{
            text: info.Quantity.toString(),
            widthPc: 7
        },
        {
            text: docType === IDocumentType.irrigationSystemPurchaseOrder ? wrappedPartNumber.join("\n") : "",
            widthPc: docType === IDocumentType.irrigationSystemPurchaseOrder ? 16 : 0
        },
        {
            child: <View>
                <Text><Text style={{fontFamily: "Wingdings", color: primaryColor, fontSize: 8,  marginRight: 3}}>y</Text> {info.Description}</Text>
            </View>,
            widthPc: docType === IDocumentType.irrigationSystemPurchaseOrder ? 62 : 78
        },
        {
            text: costStr,
            widthPc: 15
        }];

        ancillaryRows.push({cells});
    });

    let standardCents = standardPrice;
    let specialOrderPartsRows: IRow[] = null;
    let swingArmPathPriceCents = 0;
    if (HasSwingArmCorner(quote.System)){
        swingArmPathPriceCents = quote.SwingArmClass.SpecialOrderPriceCents();
    }

    if (docType === IDocumentType.irrigationSystemPurchaseOrder && HasSwingArmCorner(quote.System)){


        if (swingArmPathPriceCents > 0){
            specialOrderPartsRows = [
                {
                    cells: [
                        {
                            text: t("common-phrases.sltQty"),
                            header: true,
                            widthPc: 7
                        },
                        {
                            text: docType === IDocumentType.irrigationSystemPurchaseOrder ? t("common-phrases.sltPartNumber") : "",
                            widthPc: docType === IDocumentType.irrigationSystemPurchaseOrder ? 16 : 0,
                            header: true
                        }, 
                        {
                            text: t("common-phrases.sltDescription"),
                            header: true,
                            widthPc: docType === IDocumentType.irrigationSystemPurchaseOrder ? 62 : 78
                        }, 
                        {
                            text: t("common-phrases.sltPrice"),
                            header: true,
                            widthPc: 15
                        }
                    ]
                },
                {
                    cells: [
                        {
                            text: "1",
                            widthPc: 7
                        },
                        {
                            text: quote.SwingArmClass.SACPathCreatorPartNum(),
                            widthPc: 16
                        },
                        {
                            child: <View>
                                <Text><Text style={{fontFamily: "Wingdings", color: primaryColor, fontSize: 8,  marginRight: 3}}>y</Text> {quote.SwingArmClass.SACPathCreatorPartDesc()}</Text>
                            </View>,
                            widthPc: 62
                        },
                        {
                            text: formatCents(swingArmPathPriceCents),
                            widthPc: 15
                        }
                    ]
                },
                {
                    cells: [{
                        text: t("common-phrases.sltTotal"),
                        header: true,
                        textAlignRight: true,
                        widthPc: 80
                    },
                    {
                        text: "",
                        widthPc: 5
                    },
                    {
                        child: <View style={{borderTop: "1 solid black"}}>
                            <Text style={{fontWeight: "bold"}}>{formatCents(swingArmPathPriceCents)}</Text>
                            </View>,
                        widthPc: 15
                    }]
                }
            ];
        }
    }

    if (docType !== IDocumentType.irrigationSystemPurchaseOrder){
        standardCents += swingArmPathPriceCents;
    }

    if (standardRows.length > 1){
        standardRows.push({
            cells: [{
                text: (docType === IDocumentType.irrigationSystemPurchaseOrder ? t("common-phrases.sltStandardSystem") : t("common-phrases.sltStandardListPrice")) + ":",
                header: true,
                textAlignRight: true,
                widthPc: 80
            },
            {
                text: "",
                widthPc: 5
            },
            {
                child: <View style={{borderTop: "1 solid black"}}>
                    <Text style={{fontWeight: "bold"}}>{formatCents(standardCents)}</Text>
                </View>,
                widthPc: 15
            }]
        });
    }

    if (optionRows.length > 1){
        optionRows.push({
            cells: [{
                text: (docType === IDocumentType.irrigationSystemPurchaseOrder ? t("common-phrases.sltTotalOptions") : t("common-phrases.sltOptionsListPrice")) + ":",
                header: true,
                textAlignRight: true,
                widthPc: 80
            },
            {
                text: "",
                widthPc: 5
            },
            {
                child: <View style={{borderTop: "1 solid black"}}>
                    <Text style={{fontWeight: "bold"}}>{formatCents(optionsPrice)}</Text>
                    </View>,
                widthPc: 15
            }]
        });
    }

    if (upgradeRows.length > 1){
        upgradeRows.push({
            cells: [{
                text: (docType === IDocumentType.irrigationSystemPurchaseOrder ? t("common-phrases.sltTotalUpgrades") : t("common-phrases.sltUpgradesListPrice"))+ ":",
                header: true,
                textAlignRight: true,
                widthPc: 80
            },
            {
                text: "",
                widthPc: 5
            },
            {
                child: <View style={{borderTop: "1 solid black"}}>
                    <Text style={{fontWeight: "bold"}}>{formatCents(upgradesPrice)}</Text>
                </View>,
                widthPc: 15
            }]
        });
    }

    if (ancillaryRows.length > 1){
        ancillaryRows.push({
            cells: [{
                text: (docType === IDocumentType.irrigationSystemPurchaseOrder ? t("common-phrases.sltTotalAncillary") : t("common-phrases.sltAncillaryListPrice")) + ":",
                header: true,
                textAlignRight: true,
                widthPc: 80
            },
            {
                text: "",
                widthPc: 5
            },
            {
                child: <View style={{borderTop: "1 solid black"}}>
                    <Text style={{fontWeight: "bold"}}>{formatCents(ancillaryPrice)}</Text>
                    </View>,
                widthPc: 15
            }]
        });
    }

    let miscItemsRows: IRow[] = null;
    if ((docType === IDocumentType.irrigationSystemProposal || docType === IDocumentType.irrigationSystemPurchaseAgreement) && miscItems.length > 0){
        miscItemsRows = [{
            cells: [
                {
                    text: "",
                    header: true,
                    widthPc: 7
                }, 
                {
                    text: t("common-phrases.sltDescription"),
                    header: true,
                    widthPc: 63
                },{
                    text: i18next.format(t('taxable'), 'capitalize') + "?",
                    header: true,
                    widthPc: 15
                },
                {
                    text: t("common-phrases.sltPrice"),
                    header: true,
                    widthPc: 15
                }
            ]
        }];
        miscItems.forEach((mi) => {
            miscItemsRows.push({
                cells: [
                    {
                        text: "",
                        header: true,
                        widthPc: 7
                    }, 
                    {
                        text: mi.description,
                        widthPc: 63
                    }, 
                    {
                        text: mi.taxable ? i18next.format(t('yes'), 'capitalize'): i18next.format(t('no'), 'capitalize'),
                        widthPc: 15
                    },
                    {
                        text: formatCents(mi.priceCents),
                        widthPc: 15
                    }
                ]
            })
        });
    }


    return (<View>
            <View wrap={false} style={documentStyles.section}>
                <View style={documentStyles.heading}>
                    <Text style={documentStyles.sectionHeader}>{t("common-phrases.sltSystemComponents")}</Text>
                </View>
                {standardRows.length > 1 && <View style={{paddingLeft: 15, paddingRight: 15}}>
                    <FixedWidthTable
                        data={{rows: standardRows}}
                    />
                </View>}
            </View>
            {upgradeRows.length > 1 && <View wrap={false} style={documentStyles.section}>
                <View style={documentStyles.heading}>
                    <Text style={documentStyles.sectionHeader}>{t("common-phrases.sltUpgrades")}</Text>
                </View>
                <View style={{paddingLeft: 15, paddingRight: 15}}>
                    <FixedWidthTable
                        data={{rows: upgradeRows}}
                    />
                </View>
            </View>}
            {optionRows.length > 1 && 
                <View wrap={false} style={documentStyles.section}>
                    <View style={documentStyles.heading}>
                        <Text style={documentStyles.sectionHeader}>{t("common-phrases.sltOptions")}</Text>
                    </View>
                <View style={{paddingLeft: 15, paddingRight: 15}}>
                    <FixedWidthTable
                        data={{rows: optionRows}}
                    />
                </View>
            </View>}
            {ancillaryRows.length > 1 && 
                <View wrap={false} style={documentStyles.section}>
                    <View style={documentStyles.heading}>
                        <Text style={documentStyles.sectionHeader}>{t("common-phrases.sltAncillary")}</Text>
                    </View>
                <View style={{paddingLeft: 15, paddingRight: 15}}>
                    <FixedWidthTable
                        data={{rows: ancillaryRows}}
                    />
                </View>
            </View>}
            {specialOrderPartsRows !== null && 
                <View wrap={false} style={documentStyles.section}>
                    <View style={documentStyles.heading}>
                        <Text style={documentStyles.sectionHeader}>{t("common-phrases.sltSpecialOrderPart")}</Text>
                    </View>
                <View style={{paddingLeft: 15, paddingRight: 15}}>
                    <FixedWidthTable
                        data={{rows: specialOrderPartsRows}}
                    />
                </View>
            </View>}
            {miscItems.length > 0 && miscItemsRows !== null &&
                <View wrap={false} style={documentStyles.section}>
                    <View style={documentStyles.heading}>
                        <Text style={documentStyles.sectionHeader}>{t("common-phrases.sltMiscItems")}</Text>
                    </View>
                    <View style={{paddingLeft: 15, paddingRight: 15}}>
                        <FixedWidthTable
                            data={{rows: miscItemsRows}}
                        />
                    </View>
            </View>}
            {(docType === IDocumentType.irrigationSystemProposal || docType === IDocumentType.irrigationSystemPurchaseAgreement) && 
                <TotalPricesTable
                    miscItems={miscItems} 
                    quote={quote} system={system}/>
            }
            {docType === IDocumentType.irrigationSystemPurchaseOrder && 
                <PurchaseOrderTotalPricesTable
                    quote={quote} system={system}
                    ancillaryParts={ancillaryParts}
                    groupedSections={groupedSystemParts.partSections}
                    standardPriceCents={standardPrice} optionsPriceCents={optionsPrice} upgradesPriceCents={upgradesPrice} />
            }
        </View>
    );
};

export default SystemComponentsTable;