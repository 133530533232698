import IAction from "rdptypes/IAction";
import IAuthState from "../auth/IAuthState";

export const createSystemDefaultActions = (
    layoutId: string,
    systemId: string,
    authState: IAuthState,
    ) => {
    const actions: IAction[] = [];
    
    //JB: this was commented out by BB on the 5th of Feb as a temp fix (presumably because Reinke prefer fields to remain blank)
    //this means that currently none of the default: values on page components are working (so setting a default value there won't do anything)
    //adding a select few default values to newSystemAction but ideally we would uncomment this and revert to defaults set on page components where required

    /*
    for (const page of roeData.pages) {
        for (const cmp of page.components) {
            actions.push(...createNewSystemDefaultsComponentActions(
                layoutId,
                systemId,
                cmp,
                authState)
            );
        }
    }   
    */

    return actions;
}