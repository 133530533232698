import { Geometry, GeometryCollection, GeometryTypes, LineString, Point, Polygon } from "@turf/turf";
import { BlobWriter, TextReader, ZipWriter } from "@zip.js/zip.js";
import { ExportProject } from "../common/interfaces";
import { EShapeType, IRapFile, IRapFilePoint, RAP_PASSWORD, RDP_HARDWARE_ID, RapFileStyles } from "../common/rapFiles";

interface IArgs {
    eProject: ExportProject;
}

const DOTNET_TICKS_OFFSET = 621_355_968_000_000_000n;

const pointsFromGeometry = (geometry: Geometry | GeometryCollection): IRapFilePoint[] => {
    switch (geometry.type as GeometryTypes) {
        case 'LineString':
            return (geometry as LineString).coordinates.map(position => ({ Longitude: position[0], Latitude: position[1], Altitude: null }));
        case 'Polygon':
            return (geometry as Polygon).coordinates[0].map(position => ({ Longitude: position[0], Latitude: position[1], Altitude: null }));
        case 'Point':
            return [(geometry as Point).coordinates].map(position => ({ Longitude: position[0], Latitude: position[1], Altitude: null }));    
        default:
            console.log("pointsFromGeometry called with geometry type:", geometry.type);
            return [];
    }
}

export const exportAsRapFile = async ({ eProject }: IArgs) => {
    const dateTicksLastModified = Number(BigInt(eProject.lastModifiedTimestamp) * 10000n + DOTNET_TICKS_OFFSET);
    const rapFileData: IRapFile = {
        ProjectData: {
            Id: eProject.projectId,
            GrowerName: eProject.growerName,
            FieldName: eProject.fieldName,
            DateTicksCreated: dateTicksLastModified, // TODO: Date created?
            DateTicksLastModified: dateTicksLastModified,
            Complete: true, // TODO: Is this always complete?
            Shapes: [],
        },
        HardwareId: RDP_HARDWARE_ID,
        HardwareIds: undefined
    }

    for (const feature of eProject.features) {
        const Points = pointsFromGeometry(feature.geometry);
        let data: { shapeType: EShapeType, title: string; style: RapFileStyles } | null = null;
        switch (feature.properties?.importType) {
            case 'boundary':
                data = {
                    shapeType: EShapeType.EquipmentBoundary,
                    title: "Equipment Boundary",
                    style: 'EquipmentBoundary'
                }
                break;
            case 'wetAreaBoundary':
                data = {
                    shapeType: EShapeType.EquipmentBoundary,
                    title: "Wet Area Boundary",
                    style: 'WetAreaBoundary'
                }
                break;
            case 'pivotCenterBoundary':
                data = {
                    shapeType: EShapeType.EquipmentBoundary,
                    title: "Pivot Center Boundary",
                    style: 'PivotCenterBoundary'
                }
                break;
            case 'obstacle':
                data = {
                    shapeType: EShapeType.SpanObstacle,
                    title: "Span Obstacle",
                    style: 'SpanObstacle'
                }
                break;
            case 'wheelObstacle':
                data = {
                    shapeType: EShapeType.WheelObstacle,
                    title: "Wheel Obstacle",
                    style: 'WheelObstacle'
                }
                break;
            case 'canal':
                data = {
                    shapeType: EShapeType.Canal,
                    title: "Canal",
                    style: 'Canal'
                }
                break;
            case 'waterLine':
                data = {
                    shapeType: EShapeType.WaterLine,
                    title: "Water",
                    style: 'WaterLine'
                }
                break;
            case 'electricLine':
                data = {
                    shapeType: EShapeType.ElectricLine,
                    title: "Electric",
                    style: 'ElectricLine'
                }
                break;
            case 'line':
                data = {
                    shapeType: EShapeType.Line,
                    title: "Line",
                    style: 'Line'
                }
                break;
            case 'pump':
                data = {
                    shapeType: EShapeType.Pump,
                    title: "Pump",
                    style: 'Pump'
                }
                break;
            case 'point':
                data = {
                    shapeType: EShapeType.Point,
                    title: "Point",
                    style: 'Point'
                }
                break;
            case 'pivotCenter':
                data = {
                    shapeType: EShapeType.PivotCenter,
                    title: "Center Pivot",
                    style: 'PivotCenter'
                }
                break;
            case 'feedLine':
                data = {
                    shapeType: EShapeType.PowerTrack,
                    title: "Lateral",
                    style: 'PowerTrack'
                }
                break;
            case 'wheelTrack': {
                let title = "";
                const wtId = feature.properties?.wtId !== undefined ? (feature.properties.wtId + 1).toString() : "";
                const wrapId = feature.properties?.wrapId !== undefined ? (feature.properties.wrapId + 1).toString() : "";
                let wtIdString: string;
                if (feature.properties?.side === 'flex') {
                    wtIdString = "F" + wtId;
                }
                else if (feature.properties?.side === 'rigid') {
                    wtIdString = "R" + wtId;
                }
                else {
                    wtIdString = wtId;
                }
                if (feature.properties.type === 'anticlockwiseWrap') {
                    title = "RW" + wrapId + " Span " + wtIdString;
                }
                else if (feature.properties.type === 'clockwiseWrap') {
                    title = "FW" + wrapId + " Span " + wtIdString;
                }
                else {
                    title = "Primary Coverage Span " + wtIdString;
                }
                data = {
                    shapeType: EShapeType.WheelTrack,
                    title,
                    style: 'Span'
                }
                break;
            }
            case 'gSAC':
                data = {
                    shapeType: EShapeType.G_SAC,
                    title: "Primary Coverage SAC Span",
                    style: 'G_SAC'
                }
                break;
            case 'nSAC':
                data = {
                    shapeType: EShapeType.N_SAC,
                    title: "Primary Coverage SAC Span",
                    style: 'N_SAC'
                }
                break;
            case 'ebSAC':
                data = {
                    shapeType: EShapeType.EndBoom,
                    title: "Primary Coverage SAC EndBoom",
                    style: 'EndBoom'
                }
                break;
                
            case 'gSSAC':
                data = {
                    shapeType: EShapeType.G_SSAC,
                    title: "Primary Coverage SSAC Span",
                    style: 'G_SSAC'
                }
                break;
            case 'nSSAC':
                data = {
                    shapeType: EShapeType.N_SSAC,
                    title: "Primary Coverage SSAC Span",
                    style: 'N_SSAC'
                }
                break;
            case 'ebSSAC':
                data = {
                    shapeType: EShapeType.EndBoom,
                    title: "Primary Coverage SSAC EndBoom",
                    style: 'EndBoom'
                }
                break;
                
            case 'gMSAC':
                data = {
                    shapeType: EShapeType.G_MSAC,
                    title: "Primary Coverage MSAC Span",
                    style: 'G_MSAC'
                }
                break;
            case 'nMSAC':
                data = {
                    shapeType: EShapeType.N_MSAC,
                    title: "Primary Coverage MSAC Span",
                    style: 'N_MSAC'
                }
                break;
            case 'ebMSAC':
                data = {
                    shapeType: EShapeType.EndBoom,
                    title: "Primary Coverage MSAC EndBoom",
                    style: 'EndBoom'
                }
                break;
                
            case 'endBoom': {
                let title = "";
                const wrapId = feature.properties?.wrapId !== undefined ? (feature.properties.wrapId + 1).toString() : "";
                let endingString: string;
                if (feature.properties?.side === 'flex') {
                    endingString = " EndBoom F";
                }
                else if (feature.properties?.side === 'rigid') {
                    endingString = " EndBoom R";
                }
                else {
                    endingString = " EndBoom";
                }
                if (feature.properties.type === 'anticlockwiseWrap') {
                    title = "RW" + wrapId + endingString;
                }
                else if (feature.properties.type === 'clockwiseWrap') {
                    title = "FW" + wrapId + endingString;
                }
                else {
                    title = "Primary Coverage" + endingString;
                }
                data = {
                    shapeType: EShapeType.EndBoom,
                    title,
                    style: 'EndBoom'
                }
                break;
            }

            default:
                console.log("Unkown RAP export type:", feature.properties?.importType)
                break;
        }
        if (data) {
            rapFileData.ProjectData.Shapes.push({
                ShapeType: data.shapeType,
                Points,
                Label: data.title,
                ShapeName: data.title,
                Style: data.style,
                Complete: true
            })
        }
    }


    const zipFileWriter = new BlobWriter();
    const jsonReader = new TextReader(JSON.stringify(rapFileData));
    const zipWriter = new ZipWriter(zipFileWriter, { password: RAP_PASSWORD });
    await zipWriter.add("layout.json", jsonReader);
    await zipWriter.close();
    const zipFileBlob = await zipFileWriter.getData();

    return blobToBase64(zipFileBlob)
}

export function blobToBase64(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (reader.result && typeof reader.result === 'string') {
            resolve(reader.result)
        }
        else {
            reject();
        }
      };
      reader.readAsDataURL(blob);
    });
  }