import IPartsPackage from "rdptypes/roe/IPartsPackage";
import IProductSet from "rdptypes/roe/IProductSet";


export const partsPackages: { [id: string]: IPartsPackage } = {};

import partsPackage_2024060101 from "./partsPackages/2024060101";
partsPackages[partsPackage_2024060101.id] = partsPackage_2024060101;

import partsPackage_2024081501 from "./partsPackages/2024081501";
partsPackages[partsPackage_2024081501.id] = partsPackage_2024081501;

import partsPackage_2024081901 from "./partsPackages/2024081901";
partsPackages[partsPackage_2024081901.id] = partsPackage_2024081901;


export const productSets: { [id: string]: IProductSet } = {};

import productSet_e2 from "./productSets/e2";
productSets[productSet_e2.id] = productSet_e2;