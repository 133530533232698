import { Checkbox, FormControl, FormControlLabel, InputAdornment, InputLabel, MenuItem, Select, Stack, TextField } from "@mui/material";
import { t } from "i18next";
import { DeviceTypes, DeviceWeights, HoseClamps, HoseDropWeights, HoseTopFittings, IPackage, RegulatorLocations, RegulatorTypes, UPipeFittings, UPipeReaches, UPipeTypes, WeightBottomFittings, WeightTopFittings } from "rdptypes/project/ISprinklers";
import { ISystemBase, SprinklerManufacturerTypes } from "rdptypes/project/ISystemBase.AutoGenerated";
import * as React from "react";
import { FC, useContext, useEffect, useState } from "react";
import { UseKometTrussRodSlings } from "roedata/roe_migration/Valids.Valids";
import { sprinklersPrefix } from "..";
import DevSettingsCtx from "../../../../../db/DevSettingsCtx";
import { feetToMeters, metersToFeet } from "../../../../../docGeneration/DocumentGenerationHelpers";
import { getImgSource } from "../../../../../images/ImagePathHelper";
import { pageStyles } from "../../../styles";
import { STxHBoptionEnabled } from "../helpers/Rules";
import { getHoseClampTypeLabel, getHoseDropDeviceWeightType, getHoseDropWeightTypeLabel, getHoseTopFittingLabel, getRegulatorLocationLabel, getUPipeFittingTypeLabel, getUPipeReachLabel, getUPipeTypeLabel, getWeightFittingTypeLabel } from "../helpers/SprinklerHelper";
import { ISprinklerPackageValidator } from "../sprinklerValidator/interfaces";

interface Props {
    package: IPackage;
    system: ISystemBase;
    isFlex: boolean;
    handleTooltipOpen: (src?: string) => void;
    handleTooltipClose: () => void;
    packageValidator: ISprinklerPackageValidator;
};

const HoseDropOptions: FC<Props> = (props) => {

    useEffect(() => {    }, []);

    let formInputLabelStyle = {...pageStyles.formInputLabelStyle};  
    var kometEnabled = UseKometTrussRodSlings(true, {UPipeType: props.package.HoseDrop.UPipeType});
    var screwClampOptionEnabled = props.package.Device === DeviceTypes.NelsonD3000Spray || props.package.Device === DeviceTypes.NelsonD3000FCNSpray ||
        props.package.Device === DeviceTypes.SenningerLDNSpray || props.package.Device === DeviceTypes.SenningerSuperSpray || props.package.Device === DeviceTypes.KometSpray;

    var dragAdapterShockEnabled = props.package.Device === DeviceTypes.NelsonD3000Spray || props.package.Device === DeviceTypes.NelsonD3000FCNSpray || 
        props.package.Device === DeviceTypes.SenningerLDNSpray || props.package.Device === DeviceTypes.SenningerSuperSpray;  

    const isKomet = props.system.SprinklerProperties.SprinklerEngineCode === SprinklerManufacturerTypes.Komet;

    const settings = useContext(DevSettingsCtx);
    const displaySettings = settings.dealerSettings.display.current;

    const getDisplayGroundClearance = () => {
        return (isFinite(props.packageValidator.hoseDrop.groundClearance.value) && props.packageValidator.hoseDrop.groundClearance.value !== null)
        ?  (displaySettings.lengths === "feet" ? props.packageValidator.hoseDrop.groundClearance.value.toFixed(2)
         : feetToMeters(props.packageValidator.hoseDrop.groundClearance.value).toFixed(2)) 
         : "";
    }

    const [displayGroundClearance, setDisplayGroundClearance] = useState<string>(getDisplayGroundClearance());

    React.useEffect(() => {
        setDisplayGroundClearance(getDisplayGroundClearance());
    }, [ displaySettings.lengths ]);

    return (
        <>
                <Stack direction="row" style={{alignItems: 'center', marginTop: 10, marginLeft: 10}}>
                    <Stack direction="column" style={{width: 900}}>
                        <Stack direction="row" style={{alignItems: 'center'}}>
                            <TextField
                                error={props.packageValidator.hoseDrop.groundClearance.isError}
                                style={{marginRight: 10, maxWidth: 290}}
                                InputLabelProps={{sx:formInputLabelStyle, shrink: true }}
                                fullWidth
                                variant="standard"
                                label={t(sprinklersPrefix + (displaySettings.lengths === "feet" ? "package.ground-clearance" : "package.ground-clearance-m"))}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">{displaySettings.lengths === "feet" ? "ft" : "m"}</InputAdornment>
                                }}
                                type="number"
                                value={displayGroundClearance}
                                onChange={(ev) => {
                                    setDisplayGroundClearance(ev.target.value);
                                }}
                                onBlur={() => {
                                    let val = parseFloatOrUndefined(displayGroundClearance);
                                    if (typeof val !== "undefined" && displaySettings.lengths === "meters"){
                                        val = metersToFeet(val);
                                    }

                                    props.packageValidator.hoseDrop.groundClearance.set(val);
                                }}
                            />
                            <FormControlLabel onMouseEnter={() => props.handleTooltipOpen(getImgSource("HoseDrop.ReinkeBlue"))} onMouseLeave={props.handleTooltipClose} control={<Checkbox 
                                style={{padding: 0, paddingLeft: 10, paddingRight: 5}}
                                checked={props.packageValidator.hoseDrop.reinkeBlue.value || false}
                                onChange={(ev) => props.packageValidator.hoseDrop.reinkeBlue.set(ev.target.checked)}
                            />} label={t(sprinklersPrefix + (displaySettings.minorLengths === "inches" ? "package.reinke-blue" :  "package.reinke-blue-m"))} />
                        </Stack>
                        <Stack direction="row" style={{alignItems: 'center', marginTop: 10,}}>
                            <FormControl variant="standard" fullWidth style={{marginRight: 10}} error={props.packageValidator.hoseDrop.uPipeReach.isError} >
                                <InputLabel shrink={true} sx={formInputLabelStyle} >{t(sprinklersPrefix + "package.u-pipe-reach")}</InputLabel>
                                <Select
                                    displayEmpty
                                    renderValue={(selected) => {
                                        if (!selected) return "Select";
                                        else return getUPipeReachLabel(selected, displaySettings);
                                    }}                          
                                    value={props.packageValidator.hoseDrop.uPipeReach.value || ""}
                                    onChange={(ev) => props.packageValidator.hoseDrop.uPipeReach.set(ev.target.value as UPipeReaches)}
                                >
                                    {
                                        props.packageValidator.hoseDrop.uPipeReach.allowableValues.map((key) => 
                                            <MenuItem key={key} value={key}>{getUPipeReachLabel(key, displaySettings)}</MenuItem>)
                                    }
                                </Select>
                            </FormControl>   
                            <FormControl variant="standard" fullWidth style={{marginRight: 10}} error={props.packageValidator.hoseDrop.uPipeFitting.isError}>
                                <InputLabel shrink={true} sx={formInputLabelStyle} >{t(sprinklersPrefix + "package.u-pipe-fitting")}</InputLabel>
                                <Select
                                    displayEmpty
                                    renderValue={(selected) => {
                                        if (!selected) return "Select";
                                        else return getUPipeFittingTypeLabel(selected);
                                    }}                          
                                    value={props.packageValidator.hoseDrop.uPipeFitting.value || ""}
                                    onChange={(ev) => props.packageValidator.hoseDrop.uPipeFitting.set(ev.target.value as UPipeFittings)}
                                >
                                    {
                                        props.packageValidator.hoseDrop.uPipeFitting.allowableValues.map((key) => 
                                            <MenuItem key={key} value={key}>{getUPipeFittingTypeLabel(key)}</MenuItem>)
                                    }
                                </Select>
                            </FormControl> 
                            <FormControl variant="standard" fullWidth style={{marginRight: 10}} disabled={props.package.Regulator.RegulatorType === RegulatorTypes.None} 
                                error={props.packageValidator.hoseDrop.regulatorLocation.isError}>
                                <InputLabel shrink={true} sx={formInputLabelStyle} >{t(sprinklersPrefix + "package.regulator-location")}</InputLabel>
                                <Select
                                    displayEmpty
                                    renderValue={(selected) => {
                                        if (!selected) return "Select";
                                        else return getRegulatorLocationLabel(selected);
                                    }}                         
                                    value={props.packageValidator.hoseDrop.regulatorLocation.value || ""}
                                    onChange={(ev) => props.packageValidator.hoseDrop.regulatorLocation.set(ev.target.value as RegulatorLocations)}
                                >
                                    {
                                        props.packageValidator.hoseDrop.regulatorLocation.allowableValues.map((key) => 
                                            <MenuItem key={key} value={key}>{getRegulatorLocationLabel(key)}</MenuItem>)
                                    }
                                </Select>
                            </FormControl>   
                        </Stack>
                    </Stack>
                    <Stack direction="column">
                        <FormControlLabel style={{}} control={<Checkbox 
                            disabled={!screwClampOptionEnabled}
                            style={{padding: 0, paddingLeft: 10, paddingRight: 5}}
                            checked={props.packageValidator.hoseDrop.screwClamp.value || false} 
                            onChange={(ev) => props.packageValidator.hoseDrop.screwClamp.set(ev.target.checked)}
                        />} label={t(sprinklersPrefix + "package.screw-clamp")} />
                         <FormControlLabel onMouseEnter={() => props.handleTooltipOpen(getImgSource("HoseDrop.DragAdapter"))} onMouseLeave={props.handleTooltipClose}
                          control={<Checkbox 
                            disabled={!dragAdapterShockEnabled}
                            style={{padding: 0, paddingLeft: 10, paddingRight: 5}}
                            checked={props.packageValidator.hoseDrop.dragAdapter.value || false} 
                            onChange={(ev) => props.packageValidator.hoseDrop.dragAdapter.set(ev.target.checked)}
                        />} label={t(sprinklersPrefix + "package.drag-adaptor")} />
                        <FormControlLabel style={{}} control={<Checkbox 
                            style={{padding: 0, paddingLeft: 10, paddingRight: 5}}
                            checked={props.packageValidator.hoseDrop.dragSock.value || false} 
                            disabled={!dragAdapterShockEnabled}
                            onChange={(ev) => props.packageValidator.hoseDrop.dragSock.set(ev.target.checked)}
                        />} label={t(sprinklersPrefix + "package.drag-shock")} />
                        <FormControlLabel onMouseEnter={() => props.handleTooltipOpen(getImgSource("HoseDrop.UseKometTrussRodSlings"))} onMouseLeave={props.handleTooltipClose} control={<Checkbox 
                                disabled={!kometEnabled}
                                style={{padding: 0, paddingLeft: 10, paddingRight: 5}}
                                checked={props.packageValidator.hoseDrop.useKometTrussRodSlings.value || false} 
                                onChange={(ev) => props.packageValidator.hoseDrop.useKometTrussRodSlings.set(ev.target.checked)}
                            />} label={t(sprinklersPrefix + "package.use-komet-truss")} />  
                        <FormControlLabel style={{}} control={<Checkbox 
                            disabled={props.package.HoseDrop.UPipeType !== UPipeTypes.BlackPlasticDouble125}
                            style={{padding: 0, paddingLeft: 10, paddingRight: 5}}
                            checked={props.packageValidator.hoseDrop.devicesDoubled.value || false} 
                            onChange={(ev) => props.packageValidator.hoseDrop.devicesDoubled.set(ev.target.checked)}
                        />} label={t(sprinklersPrefix + "package.all-devices-doubled")} />  
                    </Stack>
                </Stack>
                <Stack direction="row" style={{alignItems: 'center', marginTop: 10, marginLeft: 10}}>
                    <FormControl variant="standard" fullWidth style={{marginRight: 10}} error={props.packageValidator.hoseDrop.uPipeType.isError} >
                        <InputLabel shrink={true} sx={formInputLabelStyle} >{t(sprinklersPrefix + "package.u-pipe-type")}</InputLabel>
                        <Select
                            displayEmpty
                            renderValue={(selected) => {
                                if (!selected) return "Select";
                                else return getUPipeTypeLabel(selected);
                            }}                          
                            value={props.packageValidator.hoseDrop.uPipeType.value || ""}
                            onChange={(ev) => {
                                props.packageValidator.hoseDrop.uPipeType.set(ev.target.value as UPipeTypes);
                            }}
                        >
                            {
                                props.packageValidator.hoseDrop.uPipeType.allowableValues.map((key) => 
                                    <MenuItem onMouseEnter={() => props.handleTooltipOpen(getImgSource("HoseDrop.UPipeType", key.toString()))} onMouseLeave={props.handleTooltipClose}
                                    key={key} value={key}>{getUPipeTypeLabel(key)}</MenuItem>) 
                            }                                                
                        </Select>
                    </FormControl> 
                    <FormControl variant="standard" fullWidth style={{marginRight: 10}} error={props.packageValidator.hoseDrop.hoseTopFitting.isError}>
                        <InputLabel shrink={true} sx={formInputLabelStyle} >{t(sprinklersPrefix + "package.hose-top-fitting")}</InputLabel>
                        <Select
                            displayEmpty
                            renderValue={(selected) => {
                                if (!selected) return "Select";
                                else return getHoseTopFittingLabel(selected);
                            }}                            
                            value={props.packageValidator.hoseDrop.hoseTopFitting.value || ""}
                            onChange={(ev) => props.packageValidator.hoseDrop.hoseTopFitting.set(ev.target.value as HoseTopFittings)}
                        >
                            {
                                props.packageValidator.hoseDrop.hoseTopFitting.allowableValues.map((key) => 
                                    <MenuItem key={key} value={key}>{getHoseTopFittingLabel(key)}</MenuItem>)
                            }
                        </Select>
                    </FormControl>   
                    <FormControl variant="standard" fullWidth style={{marginRight: 10}} error={props.packageValidator.hoseDrop.hoseTopClamp.isError}>
                        <InputLabel shrink={true} sx={formInputLabelStyle} >{t(sprinklersPrefix + "package.hose-top-clamp")}</InputLabel>
                        <Select
                            displayEmpty
                            renderValue={(selected) => {
                                if (!selected) return "Select";
                                else return getHoseClampTypeLabel(selected);
                            }}                          
                            value={props.packageValidator.hoseDrop.hoseTopClamp.value || ""}
                            onChange={(ev) => props.packageValidator.hoseDrop.hoseTopClamp.set(ev.target.value as HoseClamps)}
                        >
                            {
                                props.packageValidator.hoseDrop.hoseTopClamp.allowableValues.map((key: HoseClamps) => 
                                    <MenuItem key={key} value={key}>{getHoseClampTypeLabel(key)}</MenuItem>)
                            }
                        </Select>
                    </FormControl>   
                    <FormControl variant="standard" fullWidth style={{marginRight: 10}} error={props.packageValidator.hoseDrop.hoseBottomClamp.isError}>
                        <InputLabel shrink={true} sx={formInputLabelStyle} >{t(sprinklersPrefix + "package.hose-bottom-clamp")}</InputLabel>
                        <Select
                            displayEmpty
                            renderValue={(selected) => {
                                if (!selected) return "Select";
                                else return getHoseClampTypeLabel(selected);
                            }}                         
                            value={props.packageValidator.hoseDrop.hoseBottomClamp.value || ""}
                            onChange={(ev) => props.packageValidator.hoseDrop.hoseBottomClamp.set(ev.target.value as HoseClamps)}
                        >
                            {
                                props.packageValidator.hoseDrop.hoseBottomClamp.allowableValues.map((key) => 
                                    <MenuItem key={key} value={key}>{getHoseClampTypeLabel(key)}</MenuItem>)
                            }
                        </Select>
                    </FormControl>   
                </Stack>
                <Stack direction="row" style={{alignItems: 'center', marginTop: 10, marginLeft: 10 }}>
                    <FormControl variant="standard" fullWidth style={{marginRight: 10}}  error={props.packageValidator.hoseDrop.weight.isError}>
                        <InputLabel shrink={true} sx={formInputLabelStyle} >{t(sprinklersPrefix + "package.drop-weight")}</InputLabel>
                        <Select
                            displayEmpty
                            renderValue={(selected) => {
                                if (!selected) return "Select";
                                else return getHoseDropWeightTypeLabel(selected, displaySettings);
                            }}                            
                            value={props.packageValidator.hoseDrop.weight.value || ""}
                            onChange={(ev) => props.packageValidator.hoseDrop.weight.set(ev.target.value as HoseDropWeights)}
                        >
                            {
                                props.packageValidator.hoseDrop.weight.allowableValues.map((key) => 
                                    <MenuItem onMouseEnter={() => props.handleTooltipOpen(getImgSource("HoseDrop.Weight", key.toString()))} onMouseLeave={props.handleTooltipClose}
                                     key={key} value={key}>{getHoseDropWeightTypeLabel(key, displaySettings)}</MenuItem>)
                            }
                        </Select>
                    </FormControl>   
                    <FormControl variant="standard" fullWidth style={{marginRight: 10}} error={props.packageValidator.hoseDrop.weightTopFitting.isError}>
                        <InputLabel shrink={true} sx={formInputLabelStyle} >{t(sprinklersPrefix + "package.weight-top-fitting")}</InputLabel>
                        <Select
                            displayEmpty
                            disabled={props.packageValidator.hoseDrop.weightTopFitting.allowableValues.length === 0}
                            renderValue={(selected) => {
                                if (!selected) return "Select";
                                else return getWeightFittingTypeLabel(selected);
                            }}                           
                            value={props.packageValidator.hoseDrop.weightTopFitting.value || ""}
                            onChange={(ev) => props.packageValidator.hoseDrop.weightTopFitting.set(ev.target.value as WeightTopFittings)}
                        >
                            {
                                props.packageValidator.hoseDrop.weightTopFitting.allowableValues.map((key) => 
                                    <MenuItem key={key} value={key}>{getWeightFittingTypeLabel(key)}</MenuItem>)
                            }
                        </Select>
                    </FormControl>   
                    <FormControl variant="standard" fullWidth style={{marginRight: 10}} error={props.packageValidator.hoseDrop.weightBottomFitting.isError}>
                        <InputLabel shrink={true} sx={formInputLabelStyle} >{t(sprinklersPrefix + "package.weight-bottom-fitting")}</InputLabel>
                        <Select
                            displayEmpty
                            disabled={props.packageValidator.hoseDrop.weightBottomFitting.allowableValues.length === 0}
                            renderValue={(selected) => {
                                if (!selected) return "Select";
                                else return getWeightFittingTypeLabel(selected);
                            }}                           
                            value={props.packageValidator.hoseDrop.weightBottomFitting.value || ""}
                            onChange={(ev) => props.packageValidator.hoseDrop.weightBottomFitting.set(ev.target.value as WeightBottomFittings)}
                        >
                            {
                                props.packageValidator.hoseDrop.weightBottomFitting.allowableValues.map((key) => 
                                    <MenuItem onMouseEnter={() => props.handleTooltipOpen(getImgSource("HoseDrop.WeightBottomFitting", key.toString()))} onMouseLeave={props.handleTooltipClose}
                                     key={key} value={key}>{getWeightFittingTypeLabel(key)}</MenuItem>)
                            }
                        </Select>
                    </FormControl>
                    {
                        isKomet &&
                        <div style={{width: '100%', height: 48}}></div>
                    } 
                    {
                        !isKomet &&
                        <FormControl variant="standard" fullWidth style={{marginRight: 10}} error={props.packageValidator.hoseDrop.deviceWeight.isError}>
                            <InputLabel shrink={true} sx={formInputLabelStyle} >{t(sprinklersPrefix + "package.device-weight")}</InputLabel>
                            <Select
                                displayEmpty
                                renderValue={(selected) => {
                                    if (!selected) return "Select";
                                    else return getHoseDropDeviceWeightType(selected);
                                }}                            
                                value={props.packageValidator.hoseDrop.deviceWeight.value || ""}
                                onChange={(ev) => props.packageValidator.hoseDrop.deviceWeight.set(ev.target.value as DeviceWeights)}
                            >
                                {
                                    props.packageValidator.hoseDrop.deviceWeight.allowableValues.map((key) => 
                                        <MenuItem onMouseEnter={() => props.handleTooltipOpen(getImgSource("HoseDrop.DeviceWeight", key))} onMouseLeave={props.handleTooltipClose}
                                        key={key} value={key}>{getHoseDropDeviceWeightType(key as DeviceWeights)}</MenuItem>)
                                }
                            </Select>
                        </FormControl>
                    }
                </Stack>
                <FormControlLabel style={{marginTop: 10, marginLeft: 10}} disabled={!STxHBoptionEnabled(props.package)} control={<Checkbox 
                                style={{padding: 0, paddingLeft: 10, paddingRight: 5, marginTop: 10}}
                                checked={props.packageValidator.hoseDrop.substituteSTxHB.value || false}
                                onChange={(ev) =>props.packageValidator.hoseDrop.substituteSTxHB.set(ev.target.checked)}
                            />} label={t(sprinklersPrefix + "package.substitute-adapter")} />
        </>
    );
};

const parseFloatOrUndefined = (str: string): number | undefined => {
    const num = parseFloat(str);
    return isNaN(num) ? undefined : num;
}

export default HoseDropOptions;