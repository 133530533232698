import DeleteIcon from '@mui/icons-material/Delete';
import { Badge, IconButton, Tooltip } from "@mui/material";
import i18next, { t } from "i18next";
import * as React from "react";
import { deleteLayoutAction } from '../../../../actions/DeleteLayoutAction';
import { createNewUpdateProjectPropertyAction } from '../../../../actions/UpdateProjectProperty';
import AuthCtx from '../../../../auth/AuthCtx';
import DbCtx from '../../../../db/DbCtx';
import { ProjectState } from '../../../../model/project/IProject';
import DeleteItemConfirmationDialog from './DeleteItemConfirmationDialog';
import { EDeleteItemMode } from './EDeleteItemMode';

interface Props {
    projectId: string;
    layoutId?: string;
}

const getToolTipTitle = (deleteMode: EDeleteItemMode): string => {
    switch (deleteMode) {
        case EDeleteItemMode.LAYOUT:
            return i18next.format(t("nav.layouts.delete-layout"), "capitalize-each");
        case EDeleteItemMode.PROJECT:
        default:
            return i18next.format(t("projects.delete-field"), "capitalize-each");
    }
}
const getBadgeContent = (deleteMode: EDeleteItemMode): string => {
    switch (deleteMode) {
        case EDeleteItemMode.LAYOUT:
            return i18next.format(t("project-list.layout-initial"), "capitalize-each");
        case EDeleteItemMode.PROJECT:
        default:
            return i18next.format(t("project-list.project-initial"), "capitalize-each");
    }
}

const DeleteItemButton: React.FC<Props> = (props) => {

    const dbCtx = React.useContext(DbCtx);
    const authState = React.useContext(AuthCtx);
    const [ open, setOpen ] = React.useState(false);

    const mode = props.layoutId ? EDeleteItemMode.LAYOUT : EDeleteItemMode.PROJECT;
    const tooltipTitle = getToolTipTitle(mode);
    const dbPrj = dbCtx.projects[props.projectId];
    const project = dbPrj.state;
    const showConfirmationDialog = () => {
        setOpen(true);
    }

    const handleOnCancel = () => {
        setOpen(false);
    }
    const handleOnConfirm = async () => {
        switch (mode) {
            case EDeleteItemMode.LAYOUT:
                await dbPrj.pushAction(
                    deleteLayoutAction(
                        authState, props.layoutId
                    ).action
                )
                break;        
            case EDeleteItemMode.PROJECT:
            default:
                await dbPrj.pushAction(
                    createNewUpdateProjectPropertyAction("projectState", ProjectState.Deleted, authState)
                );
                break;
        }
        setOpen(false);
    }

    return (
        <>
            <Tooltip title={tooltipTitle}>
                <IconButton aria-label={tooltipTitle} onClick={showConfirmationDialog} color="warning">
                    <Badge badgeContent={getBadgeContent(mode)}>
                        <DeleteIcon />
                    </Badge>
                </IconButton>
            </Tooltip>
            <DeleteItemConfirmationDialog
                onClose={handleOnCancel}
                onConfirm={handleOnConfirm}
                mode={mode}
                open={open}
                project={project}
                layoutId={props.layoutId}
            />
        </>
    )

}

export default DeleteItemButton;