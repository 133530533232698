import { Feature, LineString, Point, Polygon, Properties } from "@turf/turf";
import { EndGunTypes } from "rdptypes/project/ISystemBase.AutoGenerated";
import { IEndgunOnOffs } from "../../../optimization/centerPivot/endgun";
import { ISpanVertex } from "./systemCoordinates";


export interface IProperties {
    isCenterPivot: true;
    systemId: string;
    layoutId: string;
    activeSystem: boolean;
    isEditable: boolean;
}


export interface IEndgunInformation {
    center: Point;
    throwStartFeet: number;
    throwEndFeet: number;
    onOffs: IEndgunOnOffs[];
    type: EndGunTypes;
    isPrimary: boolean;
}

export type IGeoJSONFeatureResult = {
    verticies: ISpanVertex[];
    feature: Feature<Point, Properties>;
    wheelTracks?: undefined;
    endBoomTrack?: Feature<LineString, { type: 'anticlockwiseWrap' | 'center' | 'clockwiseWrap', wrapIndex?: number }>[];
} | {
    feature: Feature<Polygon, Properties>;
    verticies: ISpanVertex[];
    wheelTracks: Feature<LineString, { spanIndex: number, type: 'anticlockwiseWrap' | 'center' | 'clockwiseWrap', wrapIndex?: number }>[];
    endBoomTrack: Feature<LineString, { type: 'anticlockwiseWrap' | 'center' | 'clockwiseWrap', wrapIndex?: number }>[];
};