import {
    BoosterPumpTypes,
    IControlPanel as ControlPanel,
    EndGunAcresMethods,
    EndGunTypes,
    EndOfSystemTypes,
    EndTowerPositioningTypes,
    HoseFeedTypes,
    LightTypes,
    PanelModels,
    SACPipeTypes,
    SpanTypes,
    SwingArmLengths,
    SwitchMountTypes,
    SystemTypes,
    TowerHeights,
    ValveTypes,
    WrapAroundSpanTypes
} from 'rdptypes/project/ISystemBase.AutoGenerated';
import schema from "../data/partsPackages/2024060101/poSchema";
import CSection2, { SectionIDs } from "./CSection2";
import { PipeTypes } from "./EndOfSystemFunctions";
import PartsAssembler from "./PartsAssembler";
import { WarningLevels } from "./QuoteClass";
import * as sidef from "./SideFunctions";
import * as spanf from "./SpanFunctions";
import * as sysf from "./SystemFunctions";
import { strLanguageText } from "./TranslationsLib";
import { EndGun, EndOfSystem, Side, Span, TTowers as Towers } from "./Types";
import { CollectorReelICCIOptionCountOk, EndBoomOK, EndGunValveOK, EndLightConstantOnOK, EndOfSystemOK, StainlessSteelMountsOK, StrainerOK, SwingArmLengthOK, ValidateConductorCount } from "./Valids.Valids";
import { CollectorReelICIIOptionCountOkDto, ExtraConductorCountValidationFlag } from './Valids.dto.Valids';
import { StringFormat } from "./VbCompat";
export class EndOfSystemClass extends CSection2 {
    public Side: Side;
    private DependentSideSpanSpacing: number;
    public DependentSideSpanLength: number;
    public DependentSideSpanType: SpanTypes;
    public HasDependentSideSpanExt: boolean;
    public HasPowerTowerEndBoom: boolean;
    public HingeTowerHeight: TowerHeights;
    public IsSACRetro: boolean;

    public GetBoomInfo = (): {
        spanType: string,
        lengthFeet: number | undefined,
        spacingInches: number | undefined,
        endingLocationFeet: number
    } => {
        let i: number = this.EndBoomSpan();
        let spanType = "N/A";
        let spacingInches: number | undefined;
        let endingLocationFeet = 1;
        let lengthFeet: number | undefined;

        if (i !== 0) {
            spacingInches = this.ComputeEndBoomSpacing();
            spanType = spanf.SpanType(this.Quote.System, this.Side, this.Side.Span[i - 1]);
            endingLocationFeet = spanf.EndingLocation(this.Quote.System, this.Side, this.Side.Span[i - 1]);
            lengthFeet = this.Side.Span[i - 1].Length;
        }

        return {
            spacingInches,
            spanType,
            endingLocationFeet,
            lengthFeet
        }
    }
    public EndBoomSpan = (): number => {
        let i: number;
        i = sidef.Spans(this.Quote.System, this.Side).Count;
        if (i < 1) {
            return 0;
        }
        if (sidef.Spans(this.Quote.System, this.Side).Span[i - 1].EndBoom) {
            return i;
        }
        return 0;
    }
    public EndBoomExists = (): boolean => {
        return (this.EndBoomSpan() > 0);
    }
    public EndBoomLength = (): number => {
        if (this.EndBoomExists()) {
            return sidef.Spans(this.Quote.System, this.Side).Span[sidef.Spans(this.Quote.System, this.Side).Count - 1].Length;
        }
        else {
            return 1;
        }
    }
    private ComputeEndBoomType = (): SpanTypes => {
        if (sysf.HasSwingArmCorner(this.Quote.System)) {
            if (this.Quote.SwingArmClass.FieldSet.DataValid()) {
                switch (this.Quote.System.Circle.SwingArm.PipeCoating) {
                    case SACPipeTypes.Galvanized:
                        return SpanTypes.E2060;
                        break;
                    case SACPipeTypes.GalvanizedCNPlus:
                    case SACPipeTypes.PaintedCNPlus:
                        return SpanTypes.E2660;
                        break;
                }
            }
        }
        else {
            switch (this.DependentSideSpanType) {
                case SpanTypes.E2045:
                    return SpanTypes.E2045;
                    break;
                case SpanTypes.E2060:
                case SpanTypes.E2065:
                    return SpanTypes.E2060;
                    break;
                case SpanTypes.E2660:
                case SpanTypes.E2665:
                    return SpanTypes.E2660;
                    break;
                case SpanTypes.AlumIV:
                case SpanTypes.A60G:
                    return SpanTypes.AlumIV;
                    break;
                case SpanTypes.S2065G:
                    return SpanTypes.S2065G;
                    break;
                case SpanTypes.PL2065G:
                    return SpanTypes.PL2065G;
                    break;
            }
        }
    }
    public ComputeEndBoomSpacing = (): number => {
        // Added by Icon
        this.PerformDependencyAnalysis();

        if (sysf.HasSwingArmCorner(this.Quote.System)) {
            return 57;
        }
        else {
            switch (this.DependentSideSpanType) {
                case SpanTypes.E2045:
                    switch (this.DependentSideSpanSpacing) {
                        case 57:
                            return 57;
                            break;
                        case 114:
                            if (this.DependentSideSpanLength === 194 && this.EndBoomLength() === 61) {
                                return 114;
                            }
                            else {
                                switch (this.EndBoomLength()) {
                                    case 23:
                                    case 32:
                                    case 42:
                                    case 51:
                                        return 114;
                                        break;
                                    default:
                                        return 57;
                                        break;
                                }
                            }
                            break;
                    }
                    break;
                case SpanTypes.E2060:
                case SpanTypes.E2065:
                    switch (this.DependentSideSpanSpacing) {
                        case 40:
                            switch (this.EndBoomLength()) {
                                case 1:
                                case 13:
                                case 23:
                                case 32:
                                case 42:
                                case 51:
                                case 61:
                                    return 40;
                                    break;
                                case 85:
                                case 105:
                                    return 57;
                                    break;
                            }
                            break;
                        case 57:
                            return 57;
                            break;
                    }
                    break;
                case SpanTypes.E2660:
                case SpanTypes.E2665:
                case SpanTypes.PL2065G:
                    return 57;
                    break;
                case SpanTypes.AlumIV:
                case SpanTypes.A60G:
                case SpanTypes.S2065G:
                    return 40;
                    break;
                default:
                    // new Exception()
                    throw new Error(`Unable to determine Outlet Spacing for last span type ${this.DependentSideSpanType}, last span length ${this.DependentSideSpanLength}, last span outlet spacing ${this.DependentSideSpanSpacing}, and selected EndBoom length ${this.EndBoomLength()}.`);
                    break;
            }
        }
    }
/*public EndBoomPipeOutsideDiameter = (): number => {
if (this.EndBoomExists()) {
return spanf.PipeOutsideDiameter(sidef.Spans(this.Side).Span[sidef.Spans(this.Side).Count - 1]);
}
}
*/
public DataOK = (PA: PartsAssembler, WithWarnings: boolean = false, DependencyCheckInProgress: boolean = false): void => {
        if (!this.FieldSet.DataValid()) return;
        /*if (FieldSet.Value === FieldSetStates.Invalid) {
        return true;
        }
        if (!(FieldSet.ConfigurationValid())) {
        this.Quote.Warnings.Add(this.TranslatedKey);
        return false;
        }*/

        if (!(sysf.HasPowerTowerEndBoom(this.Quote.System) && this.SectionID === SectionIDs.LeftEOSID)
            && !this.Side.Tower.length) {
                return;
        }

        let bElectricEOSValid: boolean;
        let bPanelAllowsSAC: boolean = true;
        let bPanelAllowsSecondaryGun: boolean;

        const SystemType = this.Quote.System.SystemProperties.SystemType;
        let HoseFeedType: HoseFeedTypes | undefined;
        let bSecPowerTower = false;
        if (this.Quote.System.SystemProperties.SystemType === SystemTypes.HoseFeedMaxigator) {
            HoseFeedType = this.Quote.System.Lateral.HoseFeed.HoseFeedType;
            switch (this.Quote.System.Lateral.HoseFeed.HoseFeedType) {
                case HoseFeedTypes.DoubleEndFeed:
                    if (this.SectionID === SectionIDs.RightEOSID) {
                        bSecPowerTower = true;
                    }
            }
        }

        if (this.Quote.ControlPanelClass.FieldSet.DataValid()) {
            let cp: ControlPanel = this.Quote.System.ControlPanel;
            bElectricEOSValid = cp.EndTowerPositioning === EndTowerPositioningTypes.Resolver || cp.EndTowerPositioning === EndTowerPositioningTypes.GPS || cp.SwitchMount !== SwitchMountTypes.None || sysf.IsMaxigator(this.Quote.System);
            switch (cp.PanelModel) {
                case PanelModels.RPMBasic:
                    bPanelAllowsSAC = false;
                    break;
                case PanelModels.RPMAdvanced:
                case PanelModels.RPMPreferred:
                case PanelModels.RPMConnect:
                case PanelModels.RPMAdvancedPlus:
                    bPanelAllowsSecondaryGun = cp.EndTowerPositioning === EndTowerPositioningTypes.GPS;
                    break;
            }
            if (SystemType === SystemTypes.HoseFeedMaxigator && HoseFeedType === HoseFeedTypes.CF200 && cp.PanelModel === PanelModels.RPMStandard) {
                bPanelAllowsSecondaryGun = true;
            }
        }
        if (this.Quote.IsSACRetro()) {
            bElectricEOSValid = true;
            if (this.Quote.System.Circle.SACRetro.IsControlPanelVRISACSpanCompliant) {
                bPanelAllowsSecondaryGun = true;
            }
        }
        let bEDMP: boolean = sysf.IsEDMP(this.Quote.System);
        let b230Volt: boolean = sysf.Is230V(this.Quote.System);
        let bA100: boolean = this.Quote.IsA100_Only();

        this.PerformDependencyAnalysis();
        let eEndBoomLength: number = this.EndBoomLength();
        let eos: EndOfSystem = this.Side.EndOfSystem;
        let ega: number = 0;
        if (this.Quote.SprinklerChartClass.DataValid()) {
            if ((this.Side.SprinklerChart.IrrigationProperties.EndGunIrrigationProperties.EndGunTypeCalculated ?? EndGunTypes.None) !== EndGunTypes.None) {
                ega = this.Side.SprinklerChart.IrrigationProperties.EndGunIrrigationProperties.Radius * 12 / 5;
            }
        }
        if (this.Side.EndOfSystem.EndOfSystemType === EndOfSystemTypes.SAC) {
            let dEndingRadius: number;
            if (this.Quote.System.SystemProperties.SystemType === SystemTypes.SwingArmRetro) {
                dEndingRadius = this.Quote.System.Circle.SACRetro.LastWheelTrack;
            }
            else {
                dEndingRadius = spanf.EndingRadius(this.Quote.System, this.Side, this.Side.Span[sidef.NumberOfSpans(this.Quote.System.FlangedSide) - 1]);
            }
            switch (this.Side.EndOfSystem.SwingArmLength) {
                case SwingArmLengths.SAC175:
                    if (dEndingRadius < 560 + ega) {
                        this.Quote.Warnings.Add(strLanguageText("lsltSwingArmTooLong"), WarningLevels.Decision);
                    }
                    break;
                case SwingArmLengths.SAC194:
                    if (dEndingRadius < 650 + ega) {
                        this.Quote.Warnings.Add(strLanguageText("lsltSwingArmTooLong"), WarningLevels.Decision);
                    }
                    break;
                case SwingArmLengths.SAC213:
                    if (dEndingRadius < 740 + ega) {
                        this.Quote.Warnings.Add(strLanguageText("lsltSwingArmTooLong"), WarningLevels.Decision);
                    }
                    break;
            }
            if (eos.HingeTowerOnly) {
                this.Quote.Warnings.Add(strLanguageText("lsltSwingArmHingeTowerOnly"), WarningLevels.Informational)
            }
        }
        if (!(EndOfSystemOK(eos.EndOfSystemType, { SwingArmAllowed: sysf.SwingArmAllowed(this.Quote.System), SystemType: this.Quote.System.SystemProperties.SystemType }))) {
            this.Quote.Warnings.Add(strLanguageText("sltEndOfSystem"));
        }
        if (!(SwingArmLengthOK(eos.SwingArmLength, {
            EndOfSystemType: eos.EndOfSystemType,
            HingeTowerHeight: this.HingeTowerHeight,
        }))) {
            this.Quote.Warnings.AddWithTargets(
                [`dummy.endOfSystem.${sidef.FlangedSide(this.Quote.System, this.Side) ? "Flanged" : "Flex"}.swingArmLength`],
                strLanguageText("sltEndOfSystem"));
        }

        if (eos.EndGun.EndGunTypePrimary !== EndGunTypes.None &&
            ((eos.EndGun.EndGunAcresMethod === EndGunAcresMethods.Precision && eos.EndOfSystemType === EndOfSystemTypes.SAC) ||
                (eos.EndGun.EndGunAcresMethod === EndGunAcresMethods.Traditional &&
                    (this.Quote.System.SystemProperties.SystemType === SystemTypes.CanalFeedMaxigator || this.Quote.System.SystemProperties.SystemType === SystemTypes.HoseFeedMaxigator)))) {
            //if we have an end gun set and
            //system has a SAC but we are in precision mode
            //OR
            //system is not a pivot and we are in traditional mode
            //ERROR
            this.Quote.Warnings.AddWithTargets(
                [`${sidef.FlangedSide(this.Quote.System, this.Side) ? "Flanged" : "Flex"}Side.EndOfSystem.EndGun.EndGunAcresMethod`],
                strLanguageText("sltEndGunWillWater"));
        }

        if (!(EndBoomOK(eEndBoomLength, {
            EndOfSystemType: eos.EndOfSystemType,
            PowerTowerEndBoom: this.HasPowerTowerEndBoom,
            SpanType: this.DependentSideSpanType,
            SpanLength: this.DependentSideSpanLength,
            IsSpanExt: this.HasDependentSideSpanExt,
            SwingArmLength: eos.SwingArmLength
        }))) {
            this.Quote.Warnings.AddWithTargets(
                [`dummy.endOfSystem.${sidef.FlangedSide(this.Quote.System, this.Side) ? "Flanged" : "Flex"}.endBoomLength`],
                strLanguageText("sltEndBoom"));
        }
        if (!(this.IsSACRetro)) {
            if (!(StainlessSteelMountsOK(eos.StainlessSteelMounts, {
                DependentSideSpanType: this.DependentSideSpanType,
                EndBoomSpanLength: eEndBoomLength,
                IsA100: this.Quote.IsA100_CF200(),
                EndOfSystemType: eos.EndOfSystemType
            }))) {
                this.Quote.Warnings.Add(strLanguageText("sltStainlessSteelMounts"));
            }
            if (!(StrainerOK(eos.Strainer, this.DependentSideSpanType))) {
                this.Quote.Warnings.Add(strLanguageText("sltStrainer"));
            }
        }
        let eg: EndGun = eos.EndGun;
        if ((eg.EndGunTypePrimary ?? EndGunTypes.None) !== EndGunTypes.None) {
            if (bA100 || (bSecPowerTower && eEndBoomLength === 1)) {
                this.Quote.Warnings.AddWithTargets(
                    [`${sidef.FlangedSide(this.Quote.System, this.Side) ? "Flanged" : "Flex"}Side.EndOfSystem.EndGun.EndGunTypePrimary`],
                    strLanguageText("sltEndGun"));
            }
        }
        if (eg.EndGunTypePrimary === EndGunTypes.SR100NV) {
            if (!(bElectricEOSValid)) {
                this.Quote.Warnings.AddWithTargets(
                    [`${sidef.FlangedSide(this.Quote.System, this.Side) ? "Flanged" : "Flex"}Side.EndOfSystem.EndGun.EndGunTypePrimary`],
                    strLanguageText("sltEndGun"));
            }
        }
        let bSmallGunIsPrimary: boolean;
        let bBigGunIsPrimary: boolean;
        switch (eg.EndGunTypePrimary) {
            case EndGunTypes.R55i:
            case EndGunTypes.R55:
            case EndGunTypes.R75:
                bSmallGunIsPrimary = true;
                break;
            default:
                bBigGunIsPrimary = true;
                break;
        }
        let bSmallGunIsSecondary: boolean;
        switch (eg.EndGunTypeSecondary) {
            case EndGunTypes.R55i:
            case EndGunTypes.R55:
            case EndGunTypes.R75:
                bSmallGunIsSecondary = true;
                break;
        }
        if ((eg.EndGunTypeSecondary ?? EndGunTypes.None) !== EndGunTypes.None && !(bPanelAllowsSecondaryGun)) {
            this.Quote.Warnings.AddWithTargets(
                [`${sidef.FlangedSide(this.Quote.System, this.Side) ? "Flanged" : "Flex"}Side.EndOfSystem.EndGun.EndGunTypeSecondary`],
                strLanguageText("sltEndGun"));
        }
        if ((eg.EndGunTypeSecondary ?? EndGunTypes.None) !== EndGunTypes.None && !(bBigGunIsPrimary)) {
            this.Quote.Warnings.AddWithTargets(
                [`${sidef.FlangedSide(this.Quote.System, this.Side) ? "Flanged" : "Flex"}Side.EndOfSystem.EndGun.EndGunTypeSecondary`],
                strLanguageText("sltEndGun"));
        }
        if ((eg.EndGunTypeSecondary ?? EndGunTypes.None) !== EndGunTypes.None && !(bSmallGunIsSecondary)) {
            this.Quote.Warnings.AddWithTargets(
                [`${sidef.FlangedSide(this.Quote.System, this.Side) ? "Flanged" : "Flex"}Side.EndOfSystem.EndGun.EndGunTypeSecondary`],
                strLanguageText("sltEndGun"));
        }
        if ((eg.EndGunTypePrimary ?? EndGunTypes.None) !== EndGunTypes.None) {
            switch (eg.BoosterPump) {
                case BoosterPumpTypes.None:
                    break;
                case BoosterPumpTypes.TwoHP:
                    if (!(bElectricEOSValid) && !(this.IsSACRetro)) {
                        this.Quote.Warnings.AddWithTargets(
                            [`${sidef.FlangedSide(this.Quote.System, this.Side) ? "Flanged" : "Flex"}Side.EndOfSystem.EndGun.BoosterPump`],
                            strLanguageText("sltBoosterPump"));
                    }
                    if (!(bBigGunIsPrimary)) {
                        this.Quote.Warnings.AddWithTargets(
                            [`${sidef.FlangedSide(this.Quote.System, this.Side) ? "Flanged" : "Flex"}Side.EndOfSystem.EndGun.BoosterPump`],
                            strLanguageText("sltBoosterPump"));
                    }
                    if (b230Volt) {
                        this.Quote.Warnings.AddWithTargets(
                            [`${sidef.FlangedSide(this.Quote.System, this.Side) ? "Flanged" : "Flex"}Side.EndOfSystem.EndGun.BoosterPump`],
                            strLanguageText("sltBoosterPump"));
                    }
                    break;
                case BoosterPumpTypes.FiveHP:
                    if (!(bElectricEOSValid) && !(this.IsSACRetro)) {
                        this.Quote.Warnings.AddWithTargets(
                            [`${sidef.FlangedSide(this.Quote.System, this.Side) ? "Flanged" : "Flex"}Side.EndOfSystem.EndGun.BoosterPump`],
                            strLanguageText("sltBoosterPump"));
                    }
                    if (!(bBigGunIsPrimary)) {
                        this.Quote.Warnings.AddWithTargets(
                            [`${sidef.FlangedSide(this.Quote.System, this.Side) ? "Flanged" : "Flex"}Side.EndOfSystem.EndGun.BoosterPump`],
                            strLanguageText("sltBoosterPump"));
                    }
                    if (b230Volt) {
                        this.Quote.Warnings.AddWithTargets(
                            [`${sidef.FlangedSide(this.Quote.System, this.Side) ? "Flanged" : "Flex"}Side.EndOfSystem.EndGun.BoosterPump`],
                            strLanguageText("sltBoosterPump"));
                    }
                    if (this.DependentSideSpanType === SpanTypes.E2045) {
                        this.Quote.Warnings.AddWithTargets(
                            [`${sidef.FlangedSide(this.Quote.System, this.Side) ? "Flanged" : "Flex"}Side.EndOfSystem.EndGun.BoosterPump`],
                            strLanguageText("sltBoosterPump"));
                    }
                    break;
            }
            if (
                (eg.BoosterPump ?? BoosterPumpTypes.None) !== BoosterPumpTypes.None || 
                (   
                    sidef.HasEndGunPrimary(this.Side) &&
                    ([ValveTypes.Diaphragm, ValveTypes.Reinke, ValveTypes.Auto800, ValveTypes.Auto1000].indexOf(eg.Valve) !== -1)
                ) || 
                (
                    sidef.HasEndGunSecond(this.Side) &&
                    ([ValveTypes.Diaphragm, ValveTypes.Reinke, ValveTypes.Auto800, ValveTypes.Auto1000].indexOf(eg.SecondaryValve) !== -1)
                )                
            ) {
                for (let i: number = 1; i <= sidef.NumberOfSpans(this.Side); i++
                ) {
                    let sp = this.Side.Span[i - 1];
                    let iPrevExtraConductors: number;
                    if (i > 1) {
                        let spp: Span = this.Side.Span[i - 1 - 1];
                        iPrevExtraConductors = spp.ExtraCableConductors;
                    }
                    let result = ValidateConductorCount(this.Quote.System, sp.ExtraCableConductors, {
                        SystemType: this.Quote.System.SystemProperties.SystemType,
                        HoseFeedType: HoseFeedType,
                        SpanCableGauge: sp.MinCableGauge,
                        Side: this.Side,
                        CurrentSpanNumber: i,
                        PreviousSpanExtraConductors: iPrevExtraConductors
                    });
                    switch (result.ResultFlag) {
                        case ExtraConductorCountValidationFlag.OK:
                            break;
                            break;
                        case ExtraConductorCountValidationFlag.NotSupportedHoseFeedA100CF200:
                            this.Quote.Warnings.Add(strLanguageText("sltExtraConductors"));
                            break;
                        case ExtraConductorCountValidationFlag.ExtraConductorsExceedsUpperLimit:
                            this.Quote.Warnings.Add(`${strLanguageText("sltDueToASpanCableLimitationTheMaximum")} ${result.ExtraConductorUpperLimit}`);
                            break;
                        case ExtraConductorCountValidationFlag.ExtraConductorsExceedsPreviousSpan:
                            this.Quote.Warnings.Add(StringFormat(strLanguageText("sltThePreviousSpanHasExtraConductorsTheCurrent"), iPrevExtraConductors, iPrevExtraConductors));
                            break;
                    }
                }
            }
            if (!(EndGunValveOK(eg.Valve, {
                BoosterPumpType: eg.BoosterPump,
                EndGunType: eg.EndGunTypePrimary,
                SecEndGunType: eg.EndGunTypeSecondary,
                ElectricEOSValid: bElectricEOSValid,
                EvaluateSecondary: false
            }))) {
                this.Quote.Warnings.AddWithTargets(
                    [`${sidef.FlangedSide(this.Quote.System, this.Side) ? "Flanged" : "Flex"}Side.EndOfSystem.EndGun.Valve`],
                    strLanguageText("sltEndGunValve"));
            }
            if ((eg.EndGunTypeSecondary ?? EndGunTypes.None) !== EndGunTypes.None && !(EndGunValveOK(eg.SecondaryValve, {
                BoosterPumpType: BoosterPumpTypes.None,
                EndGunType: eg.EndGunTypePrimary,
                SecEndGunType: eg.EndGunTypeSecondary,
                ElectricEOSValid: bElectricEOSValid,
                EvaluateSecondary: true
            }))) {
                this.Quote.Warnings.AddWithTargets(
                    [`${sidef.FlangedSide(this.Quote.System, this.Side) ? "Flanged" : "Flex"}Side.EndOfSystem.EndGun.SecondaryValve`],
                    strLanguageText("sltEndGunValve"));
            }
        }
        if (eos.EndLight !== LightTypes.None) {
            if (bEDMP) {
                this.Quote.Warnings.Add(strLanguageText("sltEndLight"));
            }
            if (this.HasPowerTowerEndBoom) {
                this.Quote.Warnings.Add(strLanguageText("sltEndLight"));
            }
        }
        if (!(EndLightConstantOnOK(eos.EndLightConstantOn, {
            PivotLightType: eos.EndLight,
            EndOfSystemType: eos.EndOfSystemType,
        }))) {
            this.Quote.Warnings.Add(strLanguageText("sltEndLight"));
        }
        if (sysf.FieldSets(this.Quote.System).FlangedSpans.DataValid() && [EndOfSystemTypes.SAC].indexOf(eos.EndOfSystemType) !== -1) {
            let s: Side = this.Quote.System.FlangedSide;
            for (let i: number = 1; i <= sidef.NumberOfTowers(s); i++
            ) {
                if ([WrapAroundSpanTypes.TenDegree, WrapAroundSpanTypes.NinetyDegree].indexOf(s.Tower[i - 1].WrapAroundSpan) !== -1) {
                    this.Quote.Warnings.Add(strLanguageText("sltWrapAroundSpanSwingArmCorner"));
                }
            }
        }
        if (!(CollectorReelICCIOptionCountOk(this.Quote, (dto: CollectorReelICIIOptionCountOkDto) => {
            this.Quote.Warnings.Add(dto.FormattedMessage(), WarningLevels.Critical, true);
            return false;
        }
        ))) {
        }
    }
    public WritePO = (PO: PartsAssembler) => {
        if (this.Quote.System.FlangedSide.EndOfSystem.HingeTowerOnly) {
            return;
        }

        let eSpanPipe: PipeTypes;
        let eEndTowerPositioning: EndTowerPositioningTypes = EndTowerPositioningTypes.None;
        let bTowerUL: boolean = false;
        let eEndBoomLength: number;
        let eSwitchMount: SwitchMountTypes = SwitchMountTypes.None;
        let bUseGalvCN: boolean;
        let bHasSAC: boolean;
        let bIsMaxi: boolean;
        let bConduitClipWOSpring: boolean;
        let bTheftDeterrentClips: boolean;
        if (this.Quote.RightSpansClass.FieldSet.DataValid()) {
            bUseGalvCN = this.Quote.System.SpanProperties.GalvanizedCNPlus;
            bConduitClipWOSpring = this.Quote.System.SpanProperties.ConduitClipWOSpringClip;
            bTheftDeterrentClips = this.Quote.System.SpanProperties.TheftDeterrentClip;
        }
        bHasSAC = sysf.HasSwingArmCorner(this.Quote.System);
        bIsMaxi = sysf.IsMaxigator(this.Quote.System);
        let bSecPowerTower: boolean = false;
        switch (this.Quote.System.SystemProperties.SystemType) {
            case SystemTypes.HoseFeedMaxigator:
                if (this.Quote.System.Lateral.HoseFeed.HoseFeedType === HoseFeedTypes.DoubleEndFeed && this.SectionID === SectionIDs.RightEOSID) {
                    bSecPowerTower = true;
                }
                break;
        }
        this.PerformDependencyAnalysis();
        eEndBoomLength = this.EndBoomLength();
        if (!(this.IsSACRetro)) {
            switch (this.DependentSideSpanType) {
                case SpanTypes.E2060:
                case SpanTypes.E2065:
                case SpanTypes.E2045:
                    eSpanPipe = PipeTypes.Galvanized;
                    break;
                case SpanTypes.E2660:
                case SpanTypes.E2665:
                    eSpanPipe = PipeTypes.Painted;
                    break;
                case SpanTypes.AlumIV:
                    eSpanPipe = PipeTypes.Aluminum;
                    break;
                case SpanTypes.A60G:
                    eSpanPipe = PipeTypes.Aluminum;
                    break;
                case SpanTypes.S2065G:
                    eSpanPipe = PipeTypes.Stainless;
                    break;
                case SpanTypes.PL2065G:
                    eSpanPipe = PipeTypes.PolyLined;
                    break;
            }
            if (this.Quote.ControlPanelClass.FieldSet.DataValid()) {
                eEndTowerPositioning = this.Quote.System.ControlPanel.EndTowerPositioning;
                eSwitchMount = this.Quote.System.ControlPanel.SwitchMount;
                bTowerUL = this.Quote.System.ControlPanel.TowerUL;
            }
        }
        else {
            bTowerUL = this.Quote.System.Circle.SACRetro.TowerUL;
        }
        let eConduitClipSpacing: number;
        let eEndGunType: EndGunTypes;
        let bDiffuser: boolean;
        let eSecEndGunType: EndGunTypes;
        eEndGunType = this.Side.EndOfSystem.EndGun.EndGunTypePrimary;
        eSecEndGunType = this.Side.EndOfSystem.EndGun.EndGunTypeSecondary;
        bDiffuser = this.Side.EndOfSystem.EndGun.DiffuserLegacy;
        if (sysf.FieldSets(this.Quote.System).SprinklerChart.DataValid() && !(PO.SuppressDialog)) {
            eEndGunType = this.Side.SprinklerChart.IrrigationProperties.EndGunIrrigationProperties.EndGunTypeCalculated;
            bDiffuser = this.Side.SprinklerChart.IrrigationProperties.EndGunIrrigationProperties.DiffuserCalculated;
            eSecEndGunType = this.Side.SprinklerChart.IrrigationProperties.SecondaryEndGunIrrigationProperties.EndGunTypeCalculated;
        }
        let EndSignQty: number = 1;
        if (this.Side.EndOfSystem.ExtraReinkeEndSign) {
            EndSignQty = 2;
        }
        if (this.Quote.RightSpansClass.AnyAluminum() || (this.Quote.LeftSpansClass.FieldSet.DataValid() && this.Quote.LeftSpansClass.AnyAluminum())) {
            PO.InsertPart(schema.groups.EndofSystem_EndSign.groupId, "394948", EndSignQty); // SIGN-W/BRACKET REINKE ALUM RF
        }
        else {
            if (this.Quote.HasGPS()) {
                PO.InsertPart(schema.groups.EndofSystem_EndSign.groupId, "394848", EndSignQty); // SIGN-W/BRACKET REINKE GPS
            }
            else {
                PO.InsertPart(schema.groups.EndofSystem_EndSign.groupId, "394548", EndSignQty); // SIGN-W/BRACKET REINKE
            }
        }
        if (this.Side.EndOfSystem.EndOfSystemType === EndOfSystemTypes.EndBoom && (!(bSecPowerTower) || eEndBoomLength !== 1)) {
            switch (eSpanPipe) {
                case PipeTypes.Galvanized:
                    eConduitClipSpacing = this.DependentSideSpanSpacing;
                    switch (eEndBoomLength) {
                        case 1:
                            if (this.Side.EndOfSystem.EndGun.EndGunTypePrimary === EndGunTypes.None
                                || this.Side.EndOfSystem.EndGun.BoosterPump === BoosterPumpTypes.None) {
                                switch (this.DependentSideSpanType) {
                                    case SpanTypes.E2045:
                                        PO.InsertPart(schema.groups.EndofSystem_EndBoomAssembly.groupId, "396351", 1); // END BOOM-3FT-E2045
                                        break;
                                    default:
                                        PO.InsertPart(schema.groups.EndofSystem_EndBoomAssembly.groupId, "396350", 1); // END BOOM-3FT-E65/665/60/660
                                        break;
                                }
                            }
                            break;
                        case 13:
                            if (this.DependentSideSpanType === SpanTypes.E2045 && this.DependentSideSpanLength !== 194) {
                                PO.InsertPart(schema.groups.EndofSystem_EndBoomAssembly.groupId, "396352", 1); // END BOOM-13FT-57SP E2045
                            }
                            else {
                                switch (this.DependentSideSpanSpacing) {
                                    case 40:
                                        PO.InsertPart(schema.groups.EndofSystem_EndBoomAssembly.groupId, "396366-G", 1); // END BOOM-13FT-E65/60-LEPA-GALV
                                        break;
                                    case 57:
                                    case 114:
                                        PO.InsertPart(schema.groups.EndofSystem_EndBoomAssembly.groupId, "396358-G", 1); // END BOOM-13FT-57SP-E65/60-GALV
                                        break;
                                }
                            }
                            break;
                        case 23:
                            if (this.DependentSideSpanType === SpanTypes.E2045 && this.DependentSideSpanLength !== 194) {
                                if (this.DependentSideSpanSpacing === 114) {
                                    PO.InsertPart(schema.groups.EndofSystem_EndBoomAssembly.groupId, "396396", 1); // END BOOM-23FT-114SP-E2045
                                }
                                else {
                                    PO.InsertPart(schema.groups.EndofSystem_EndBoomAssembly.groupId, "396353", 1); // END BOOM-23FT-57SP-E2045
                                }
                            }
                            else {
                                switch (this.DependentSideSpanSpacing) {
                                    case 40:
                                        PO.InsertPart(schema.groups.EndofSystem_EndBoomAssembly.groupId, "396367-G", 1); // END BOOM-23FT-E65/60-LEPA-GALV
                                        break;
                                    case 57:
                                    case 114:
                                        PO.InsertPart(schema.groups.EndofSystem_EndBoomAssembly.groupId, "396359-G", 1); // END BOOM-23FT-57SP-E65/60-GALV
                                        break;
                                }
                            }
                            break;
                        case 32:
                            if (this.DependentSideSpanType === SpanTypes.E2045) {
                                if (this.DependentSideSpanLength === 194) {
                                    PO.InsertPart(schema.groups.EndofSystem_EndBoomAssembly.groupId, "396454", 1); // END BOOM-32FT-57SP/E2045 194'
                                }
                                else {
                                    if (this.DependentSideSpanSpacing === 114) {
                                        PO.InsertPart(schema.groups.EndofSystem_EndBoomAssembly.groupId, "396394", 1); // END BOOM-32FT-114SP-E2045
                                    }
                                    else {
                                        PO.InsertPart(schema.groups.EndofSystem_EndBoomAssembly.groupId, "396354", 1); // END BOOM-32FT-57SP-E2045
                                    }
                                }
                            }
                            else {
                                switch (this.DependentSideSpanSpacing) {
                                    case 40:
                                        PO.InsertPart(schema.groups.EndofSystem_EndBoomAssembly.groupId, "396368-G", 1); // END BOOM-32FT-E65/60-LEPA-GALV
                                        break;
                                    case 57:
                                        PO.InsertPart(schema.groups.EndofSystem_EndBoomAssembly.groupId, "396360-G", 1); // END BOOM-32FT-57SP-E65/60-GALV
                                        break;
                                }
                            }
                            break;
                        case 42:
                            if (this.DependentSideSpanType === SpanTypes.E2045) {
                                if (this.DependentSideSpanLength === 194) {
                                    PO.InsertPart(schema.groups.EndofSystem_EndBoomAssembly.groupId, "396455", 1); // END BOOM-42FT-57SP/E2045 194'
                                }
                                else {
                                    if (this.DependentSideSpanSpacing === 114) {
                                        PO.InsertPart(schema.groups.EndofSystem_EndBoomAssembly.groupId, "396395", 1); // END BOOM-42FT-114SP-E2045
                                    }
                                    else {
                                        PO.InsertPart(schema.groups.EndofSystem_EndBoomAssembly.groupId, "396355", 1); // END BOOM-42FT-57SP-E2045
                                    }
                                }
                            }
                            else {
                                switch (this.DependentSideSpanSpacing) {
                                    case 40:
                                        PO.InsertPart(schema.groups.EndofSystem_EndBoomAssembly.groupId, "396369-G", 1); // END BOOM-42FT-E65/60-LEPA-GALV
                                        break;
                                    case 57:
                                        PO.InsertPart(schema.groups.EndofSystem_EndBoomAssembly.groupId, "396361-G", 1); // END BOOM-42FT-57SP-E65/60-GALV
                                        break;
                                }
                            }
                            break;
                        case 51:
                            if (this.DependentSideSpanType === SpanTypes.E2045) {
                                if (this.DependentSideSpanLength === 194) {
                                    PO.InsertPart(schema.groups.EndofSystem_EndBoomAssembly.groupId, "396456", 1); // END BOOM-51FT-57SP/E2045 194'
                                }
                                else {
                                    if (this.DependentSideSpanSpacing === 114) {
                                        PO.InsertPart(schema.groups.EndofSystem_EndBoomAssembly.groupId, "396477", 1); // END BOOM-51FT-114SP-LTT-E2045
                                    }
                                    else {
                                        PO.InsertPart(schema.groups.EndofSystem_EndBoomAssembly.groupId, "396458", 1); // END BOOM-51FT-57SP-E2045
                                    }
                                }
                            }
                            else {
                                switch (this.DependentSideSpanSpacing) {
                                    case 40:
                                        PO.InsertPart(schema.groups.EndofSystem_EndBoomAssembly.groupId, "396370-G", 1); // END BOOM-51FT-E65/60-LEPA-GALV
                                        break;
                                    case 57:
                                        PO.InsertPart(schema.groups.EndofSystem_EndBoomAssembly.groupId, "396362-G", 1); // END BOOM-51FT-57SP-E65/60-GALV
                                        break;
                                }
                            }
                            break;
                        case 61:
                            if (this.DependentSideSpanType === SpanTypes.E2045) {
                                if (this.DependentSideSpanLength === 194) {
                                    if (this.DependentSideSpanSpacing === 114) {
                                        PO.InsertPart(schema.groups.EndofSystem_EndBoomAssembly.groupId, "396635", 1); // END BOOM-61FT-114SP GALV EII
                                    }
                                    else {
                                        PO.InsertPart(schema.groups.EndofSystem_EndBoomAssembly.groupId, "396457", 1); // END BOOM-61FT-57SP/E2045 194'
                                    }
                                }
                                else {
                                    if (this.DependentSideSpanSpacing === 114) {
                                        PO.InsertPart(schema.groups.EndofSystem_EndBoomAssembly.groupId, "396479", 1); // END BOOM-61FT-114SP/E2045LTT
                                    }
                                    else {
                                        PO.InsertPart(schema.groups.EndofSystem_EndBoomAssembly.groupId, "396459", 1); // END BOOM-61FT-57SP-E2045
                                    }
                                }
                            }
                            else {
                                switch (this.DependentSideSpanSpacing) {
                                    case 40:
                                        PO.InsertPart(schema.groups.EndofSystem_EndBoomAssembly.groupId, "396371-G", 1); // END BOOM-61FT-E65/60-LEPA-GALV
                                        break;
                                    case 57:
                                        PO.InsertPart(schema.groups.EndofSystem_EndBoomAssembly.groupId, "396363-G", 1); // END BOOM-61FT-57SP-E65/60-GALV
                                        break;
                                }
                            }
                            break;
                        case 85:
                            switch (this.DependentSideSpanLength) {
                                case 213:
                                    PO.InsertPart(schema.groups.EndofSystem_EndBoomAssembly.groupId, "396375-G", 1); // END BOOM-85'/213' EII GALV
                                    break;
                                case 175:
                                    PO.InsertPart(schema.groups.EndofSystem_EndBoomAssembly.groupId, "396556", 1); // END BOOM-85'/175' GALV EII
                                    break;
                                default:
                                    PO.InsertPart(schema.groups.EndofSystem_EndBoomAssembly.groupId, "396357-G", 1); // END BOOM-85' 6/6-5/8 EII GALV
                                    break;
                            }
                            break;
                        case 105:
                            PO.InsertPart(schema.groups.EndofSystem_EndBoomAssembly.groupId, "396372-G", 1); // END BOOM-105' 6/6-5/8 EII GALV
                            break;
                    }
                    break;
                case PipeTypes.Painted:
                    eConduitClipSpacing = 57;
                    switch (eEndBoomLength) {
                        case 1:
                            if (this.Side.EndOfSystem.EndGun.EndGunTypePrimary === EndGunTypes.None || this.Side.EndOfSystem.EndGun.BoosterPump === BoosterPumpTypes.None) {
                                PO.InsertPart(schema.groups.EndofSystem_EndBoomAssembly.groupId, "396350", 1); // END BOOM-3FT-E65/665/60/660
                            }
                            break;
                        case 13:
                            if (bUseGalvCN) {
                                PO.InsertPart(schema.groups.EndofSystem_EndBoomAssembly.groupId, "396358-G", 1); // END BOOM-13FT-57SP-E65/60-GALV
                            }
                            else {
                                PO.InsertPart(schema.groups.EndofSystem_EndBoomAssembly.groupId, "396558-G", 1); // END BOOM-13FT-57SP-E665/660-GA
                            }
                            break;
                        case 23:
                            if (bUseGalvCN) {
                                PO.InsertPart(schema.groups.EndofSystem_EndBoomAssembly.groupId, "396359-G", 1); // END BOOM-23FT-57SP-E65/60-GALV
                            }
                            else {
                                PO.InsertPart(schema.groups.EndofSystem_EndBoomAssembly.groupId, "396559-G", 1); // END BOOM-23FT-57SP-E665/660-GA
                            }
                            break;
                        case 32:
                            if (bUseGalvCN) {
                                PO.InsertPart(schema.groups.EndofSystem_EndBoomAssembly.groupId, "397503", 1); // END BOOM 32FT 57SP E2660G
                            }
                            else {
                                PO.InsertPart(schema.groups.EndofSystem_EndBoomAssembly.groupId, "396560-G", 1); // END BOOM-32FT-57SP-E665/660-GA
                            }
                            break;
                        case 42:
                            if (bUseGalvCN) {
                                PO.InsertPart(schema.groups.EndofSystem_EndBoomAssembly.groupId, "397504", 1); // END BOOM 42FT 57SP E2660G
                            }
                            else {
                                PO.InsertPart(schema.groups.EndofSystem_EndBoomAssembly.groupId, "396561-G", 1); // END BOOM-42FT-57SP-E665/660-GA
                            }
                            break;
                        case 51:
                            if (bUseGalvCN) {
                                PO.InsertPart(schema.groups.EndofSystem_EndBoomAssembly.groupId, "397505", 1); // END BOOM 51FT 57SP E2660G
                            }
                            else {
                                PO.InsertPart(schema.groups.EndofSystem_EndBoomAssembly.groupId, "396562-G", 1); // END BOOM-51FT-57SP-E665/660-GA
                            }
                            break;
                        case 61:
                            if (bUseGalvCN) {
                                PO.InsertPart(schema.groups.EndofSystem_EndBoomAssembly.groupId, "397506", 1); // END BOOM 61FT 57SP E2660G
                            }
                            else {
                                PO.InsertPart(schema.groups.EndofSystem_EndBoomAssembly.groupId, "396563-G", 1); // END BOOM-61FT-57SP-E665/660-GA
                            }
                            break;
                        case 85:
                            switch (this.DependentSideSpanLength) {
                                case 213:
                                    if (bUseGalvCN) {
                                        PO.InsertPart(schema.groups.EndofSystem_EndBoomAssembly.groupId, "396375-G", 1); // END BOOM-85'/213' EII GALV
                                    }
                                    else {
                                        PO.InsertPart(schema.groups.EndofSystem_EndBoomAssembly.groupId, "396575-G", 1); // END BOOM-85'/213' EII CT
                                    }
                                    break;
                                case 175:
                                    if (bUseGalvCN) {
                                        PO.InsertPart(schema.groups.EndofSystem_EndBoomAssembly.groupId, "396556", 1); // END BOOM-85'/175' GALV EII
                                    }
                                    else {
                                        PO.InsertPart(schema.groups.EndofSystem_EndBoomAssembly.groupId, "396555", 1); // END BOOM-85'/175' PAINTED EII
                                    }
                                    break;
                                default:
                                    if (bUseGalvCN) {
                                        PO.InsertPart(schema.groups.EndofSystem_EndBoomAssembly.groupId, "396357-G", 1); // END BOOM-85' 6/6-5/8 EII GALV
                                    }
                                    else {
                                        PO.InsertPart(schema.groups.EndofSystem_EndBoomAssembly.groupId, "396557-G", 1); // END BOOM-85'/194' PAINT EII
                                    }
                                    break;
                            }
                            break;
                        case 105:
                            if (bUseGalvCN) {
                                PO.InsertPart(schema.groups.EndofSystem_EndBoomAssembly.groupId, "396372-G", 1); // END BOOM-105' 6/6-5/8 EII GALV
                            }
                            else {
                                PO.InsertPart(schema.groups.EndofSystem_EndBoomAssembly.groupId, "396571-G", 1); // END BOOM-105' 6/6-5/8 CT EII G
                            }
                            break;
                    }
                    break;
                case PipeTypes.Aluminum:
                    eConduitClipSpacing = 40;
                    switch (eEndBoomLength) {
                        case 1:
                            if (this.Side.EndOfSystem.EndGun.EndGunTypePrimary === EndGunTypes.None || this.Side.EndOfSystem.EndGun.BoosterPump === BoosterPumpTypes.None) {
                                PO.InsertPart(schema.groups.EndofSystem_EndBoomAssembly.groupId, "396750", 1); // END BOOM-3 FT-A4/A-60-G
                            }
                            break;
                        case 13:
                            PO.InsertPart(schema.groups.EndofSystem_EndBoomAssembly.groupId, "396757-G", 1); // END BOOM-13 FT-A4/A60G-G
                            break;
                        case 23:
                            PO.InsertPart(schema.groups.EndofSystem_EndBoomAssembly.groupId, "396758-G", 1); // END BOOM-23 FT-A4/A60G-G
                            break;
                        case 32:
                            PO.InsertPart(schema.groups.EndofSystem_EndBoomAssembly.groupId, "396759-G", 1); // END BOOM-32 FT-A4/A60G-G
                            break;
                        case 42:
                            PO.InsertPart(schema.groups.EndofSystem_EndBoomAssembly.groupId, "396760-G", 1); // END BOOM-42 FT-A4/A60G-G
                            break;
                        case 51:
                            PO.InsertPart(schema.groups.EndofSystem_EndBoomAssembly.groupId, "396761-G", 1); // END BOOM-51 FT-A4/A60G-G
                            break;
                    }
                    break;
                case PipeTypes.Stainless:
                    eConduitClipSpacing = 40;
                    switch (eEndBoomLength) {
                        case 1:
                            if (this.Side.EndOfSystem.EndGun.EndGunTypePrimary === EndGunTypes.None || this.Side.EndOfSystem.EndGun.BoosterPump === BoosterPumpTypes.None) {
                                PO.InsertPart(schema.groups.EndofSystem_EndBoomAssembly.groupId, "396350", 1); // END BOOM-3FT-E65/665/60/660
                            }
                            break;
                        case 13:
                            PO.InsertPart(schema.groups.EndofSystem_EndBoomAssembly.groupId, "396366SS", 1); // ENDBOOM 13FT LEPA E65 STNLSS
                            break;
                        case 23:
                            PO.InsertPart(schema.groups.EndofSystem_EndBoomAssembly.groupId, "396367SS", 1); // END BOOM 23FT LEPA E65 STNLSS
                            break;
                        case 32:
                            PO.InsertPart(schema.groups.EndofSystem_EndBoomAssembly.groupId, "396368SS", 1); // END BOOM 32FT LEPA E65 STNLSS
                            break;
                        case 42:
                            PO.InsertPart(schema.groups.EndofSystem_EndBoomAssembly.groupId, "396369SS", 1); // END BOOM 42FT LEPA E65 STNLSS
                            break;
                        case 51:
                            PO.InsertPart(schema.groups.EndofSystem_EndBoomAssembly.groupId, "396370SS", 1); // END BOOM 51FT LEPA E65 STNLSS
                            break;
                        case 61:
                            PO.InsertPart(schema.groups.EndofSystem_EndBoomAssembly.groupId, "396371SS", 1); // END BOOM 61FT LEPA E65 STNLSS
                            break;
                    }
                    break;
                case PipeTypes.PolyLined:
                    eConduitClipSpacing = 40;
                    switch (eEndBoomLength) {
                        case 1:
                            if (this.Side.EndOfSystem.EndGun.EndGunTypePrimary === EndGunTypes.None || this.Side.EndOfSystem.EndGun.BoosterPump === BoosterPumpTypes.None) {
                                PO.InsertPart(schema.groups.EndofSystem_EndBoomAssembly.groupId, "396476", 1); // END BOOM-3FT-PL2065
                            }
                            break;
                        case 23:
                            PO.InsertPart(schema.groups.EndofSystem_EndBoomAssembly.groupId, "396472", 1); // END BOOM-23FT-57SP PL2065
                            break;
                        case 42:
                            PO.InsertPart(schema.groups.EndofSystem_EndBoomAssembly.groupId, "396473", 1); // END BOOM-42FT-57SP PL2065
                            break;
                    }
                    break;
            }
            if (!(sysf.IsEDMP(this.Quote.System))) {
                switch (eEndBoomLength) {
                    case 13:
                        switch (eConduitClipSpacing) {
                            case 40:
                                if (bTheftDeterrentClips) {
                                    PO.InsertPart(schema.groups.EndofSystem_EndBoomConduitClips.groupId, "397651", 1); // CONDUIT TDC PKG 13' EB LEPA
                                }
                                else {
                                    PO.InsertPart(schema.groups.EndofSystem_EndBoomConduitClips.groupId, "397283", 1); // CONDUIT CLIP CPLRS 13' EB LEPA
                                }
                                break;
                            case 57:
                            case 114:
                                if (bTheftDeterrentClips) {
                                    PO.InsertPart(schema.groups.EndofSystem_EndBoomConduitClips.groupId, "397652", 1); // CONDUIT TDC CPLRS 13' EB 57"
                                }
                                else {
                                    PO.InsertPart(schema.groups.EndofSystem_EndBoomConduitClips.groupId, "397284", 1); // CONDUIT CLIP PKG 13' EB 57"
                                }
                                break;
                        }
                        break;
                    case 23:
                        switch (eConduitClipSpacing) {
                            case 40:
                                if (bTheftDeterrentClips) {
                                    PO.InsertPart(schema.groups.EndofSystem_EndBoomConduitClips.groupId, "397653", 1); // CONDUIT TDC PKG 23' EB LEPA
                                }
                                else {
                                    PO.InsertPart(schema.groups.EndofSystem_EndBoomConduitClips.groupId, "397285", 1); // CONDUIT CLIP CPLRS 23' EB LEPA
                                }
                                break;
                            case 57:
                            case 114:
                                if (bTheftDeterrentClips) {
                                    PO.InsertPart(schema.groups.EndofSystem_EndBoomConduitClips.groupId, "397654", 1); // CONDUIT TDC PKG 23' EB 57"
                                }
                                else {
                                    PO.InsertPart(schema.groups.EndofSystem_EndBoomConduitClips.groupId, "397286", 1); // CONDUIT CLIP PKG 23' EB 57"
                                }
                                break;
                        }
                        break;
                    case 32:
                        switch (this.DependentSideSpanType) {
                            case SpanTypes.E2045:
                                if (bConduitClipWOSpring) {
                                    if (bTheftDeterrentClips) {
                                        PO.InsertPart(schema.groups.EndofSystem_EndBoomConduitClips.groupId, "397648", 1); // CONDUIT TDC CPLRS 32/42' EB 57
                                    }
                                    else {
                                        PO.InsertPart(schema.groups.EndofSystem_EndBoomConduitClips.groupId, "397280", 1); // CONDUIT CLP CPLRS 32/42' EB 57
                                    }
                                }
                                else {
                                    if (bTheftDeterrentClips) {
                                        if (this.DependentSideSpanSpacing === 114) {
                                            PO.InsertPart(schema.groups.EndofSystem_EndBoomConduitClips.groupId, "397672", 1); // CONDUIT TDC PKG 32/42 EB 114"
                                        }
                                        else {
                                            PO.InsertPart(schema.groups.EndofSystem_EndBoomConduitClips.groupId, "397637", 1); // CONDUIT TDC 32/42' EB-57 E2045
                                        }
                                    }
                                    else {
                                        if (this.DependentSideSpanSpacing === 114) {
                                            PO.InsertPart(schema.groups.EndofSystem_EndBoomConduitClips.groupId, "397267", 1); // CONDUIT PKG 32' EB-114 E2045
                                        }
                                        else {
                                            PO.InsertPart(schema.groups.EndofSystem_EndBoomConduitClips.groupId, "397264", 1); // CONDUIT PKG 32/42' EB-57 E2045
                                        }
                                    }
                                }
                                break;
                            default:
                                switch (eConduitClipSpacing) {
                                    case 40:
                                        if (bTheftDeterrentClips) {
                                            PO.InsertPart(schema.groups.EndofSystem_EndBoomConduitClips.groupId, "397656", 1); // CONDUIT TDC PKG 32' EB LEPA
                                        }
                                        else {
                                            PO.InsertPart(schema.groups.EndofSystem_EndBoomConduitClips.groupId, "397288", 1); // CONDUIT CLIP CPLRS 32' EB LEPA
                                        }
                                        break;
                                    case 57:
                                        if (bConduitClipWOSpring) {
                                            if (bTheftDeterrentClips) {
                                                PO.InsertPart(schema.groups.EndofSystem_EndBoomConduitClips.groupId, "397648", 1); // CONDUIT TDC CPLRS 32/42' EB 57
                                            }
                                            else {
                                                PO.InsertPart(schema.groups.EndofSystem_EndBoomConduitClips.groupId, "397280", 1); // CONDUIT CLP CPLRS 32/42' EB 57
                                            }
                                        }
                                        else {
                                            if (bTheftDeterrentClips) {
                                                PO.InsertPart(schema.groups.EndofSystem_EndBoomConduitClips.groupId, "397657", 1); // CONDUIT TDC PKG 32' EB 57"
                                            }
                                            else {
                                                PO.InsertPart(schema.groups.EndofSystem_EndBoomConduitClips.groupId, "397289", 1); // CONDUIT CLIP PKG 32' EB 57"
                                            }
                                        }
                                        break;
                                }
                                break;
                        }
                        break;
                    case 42:
                        switch (this.DependentSideSpanType) {
                            case SpanTypes.E2045:
                                if (bConduitClipWOSpring) {
                                    if (bTheftDeterrentClips) {
                                        PO.InsertPart(schema.groups.EndofSystem_EndBoomConduitClips.groupId, "397648", 1); // CONDUIT TDC CPLRS 32/42' EB 57
                                    }
                                    else {
                                        PO.InsertPart(schema.groups.EndofSystem_EndBoomConduitClips.groupId, "397280", 1); // CONDUIT CLP CPLRS 32/42' EB 57
                                    }
                                }
                                else {
                                    if (bTheftDeterrentClips) {
                                        if (this.DependentSideSpanSpacing === 114) {
                                            PO.InsertPart(schema.groups.EndofSystem_EndBoomConduitClips.groupId, "397672", 1); // CONDUIT TDC PKG 32/42 EB 114"
                                        }
                                        else {
                                            PO.InsertPart(schema.groups.EndofSystem_EndBoomConduitClips.groupId, "397637", 1); // CONDUIT TDC 32/42' EB-57 E2045
                                        }
                                    }
                                    else {
                                        if (this.DependentSideSpanSpacing === 114) {
                                            PO.InsertPart(schema.groups.EndofSystem_EndBoomConduitClips.groupId, "397268", 1); // CONDUIT PKG 42' EB-114 E2045
                                        }
                                        else {
                                            PO.InsertPart(schema.groups.EndofSystem_EndBoomConduitClips.groupId, "397264", 1); // CONDUIT PKG 32/42' EB-57 E2045
                                        }
                                    }
                                }
                                break;
                            default:
                                switch (eConduitClipSpacing) {
                                    case 40:
                                        if (bTheftDeterrentClips) {
                                            PO.InsertPart(schema.groups.EndofSystem_EndBoomConduitClips.groupId, "397658", 1); // CONDUIT TDC PKG 42' EB LEPA
                                        }
                                        else {
                                            PO.InsertPart(schema.groups.EndofSystem_EndBoomConduitClips.groupId, "397290", 1); // CONDUIT CLIP PKG 42' EB LEPA
                                        }
                                        break;
                                    case 57:
                                        if (bConduitClipWOSpring) {
                                            if (bTheftDeterrentClips) {
                                                PO.InsertPart(schema.groups.EndofSystem_EndBoomConduitClips.groupId, "397648", 1); // CONDUIT TDC CPLRS 32/42' EB 57
                                            }
                                            else {
                                                PO.InsertPart(schema.groups.EndofSystem_EndBoomConduitClips.groupId, "397280", 1); // CONDUIT CLP CPLRS 32/42' EB 57
                                            }
                                        }
                                        else {
                                            if (bTheftDeterrentClips) {
                                                PO.InsertPart(schema.groups.EndofSystem_EndBoomConduitClips.groupId, "397659", 1); // CONDUIT TDC PKG 42' EB 57"
                                            }
                                            else {
                                                PO.InsertPart(schema.groups.EndofSystem_EndBoomConduitClips.groupId, "397291", 1); // CONDUIT CLIP PKG 42' EB 57"
                                            }
                                        }
                                        break;
                                }
                                break;
                        }
                        break;
                    case 51:
                        switch (this.DependentSideSpanType) {
                            case SpanTypes.E2045:
                                if (bConduitClipWOSpring) {
                                    if (bTheftDeterrentClips) {
                                        PO.InsertPart(schema.groups.EndofSystem_EndBoomConduitClips.groupId, "397649", 1); // CONDUIT TDC CPLRS 51/61' EB 57
                                    }
                                    else {
                                        PO.InsertPart(schema.groups.EndofSystem_EndBoomConduitClips.groupId, "397281", 1); // CONDUIT CLP CPLRS 51/61' EB 57
                                    }
                                }
                                else {
                                    if (bTheftDeterrentClips) {
                                        if (this.DependentSideSpanSpacing === 114) {
                                            PO.InsertPart(schema.groups.EndofSystem_EndBoomConduitClips.groupId, "397673", 1); // CONDUIT TDC PKG 51/61 EB 114"
                                        }
                                        else {
                                            PO.InsertPart(schema.groups.EndofSystem_EndBoomConduitClips.groupId, "397638", 1); // CONDUIT TDC 51/61' EB 57 E2045
                                        }
                                    }
                                    else {
                                        if (this.DependentSideSpanSpacing === 114) {
                                            PO.InsertPart(schema.groups.EndofSystem_EndBoomConduitClips.groupId, "397269", 1); // CONDUIT PKG 51' EB-114 E2045
                                        }
                                        else {
                                            PO.InsertPart(schema.groups.EndofSystem_EndBoomConduitClips.groupId, "397265", 1); // CONDUIT PKG 51/61' EB-57 E2045
                                        }
                                    }
                                }
                                break;
                            default:
                                switch (eConduitClipSpacing) {
                                    case 40:
                                        if (bTheftDeterrentClips) {
                                            PO.InsertPart(schema.groups.EndofSystem_EndBoomConduitClips.groupId, "397660", 1); // CONDUIT TDC PKG 51' EB LEPA
                                        }
                                        else {
                                            PO.InsertPart(schema.groups.EndofSystem_EndBoomConduitClips.groupId, "397292", 1); // CONDUIT CLIP PKG 51' EB LEPA
                                        }
                                        break;
                                    case 57:
                                        if (bConduitClipWOSpring) {
                                            if (bTheftDeterrentClips) {
                                                PO.InsertPart(schema.groups.EndofSystem_EndBoomConduitClips.groupId, "397649", 1); // CONDUIT TDC CPLRS 51/61' EB 57
                                            }
                                            else {
                                                PO.InsertPart(schema.groups.EndofSystem_EndBoomConduitClips.groupId, "397281", 1); // CONDUIT CLP CPLRS 51/61' EB 57
                                            }
                                        }
                                        else {
                                            if (bTheftDeterrentClips) {
                                                PO.InsertPart(schema.groups.EndofSystem_EndBoomConduitClips.groupId, "397661", 1); // CONDUIT TDC PKG 51' EB 57"
                                            }
                                            else {
                                                PO.InsertPart(schema.groups.EndofSystem_EndBoomConduitClips.groupId, "397293", 1); // CONDUIT CLIP PKG 51' EB 57"
                                            }
                                        }
                                        break;
                                }
                                break;
                        }
                        break;
                    case 61:
                        switch (this.DependentSideSpanType) {
                            case SpanTypes.E2045:
                                if (bConduitClipWOSpring) {
                                    if (bTheftDeterrentClips) {
                                        PO.InsertPart(schema.groups.EndofSystem_EndBoomConduitClips.groupId, "397649", 1); // CONDUIT TDC CPLRS 51/61' EB 57
                                    }
                                    else {
                                        PO.InsertPart(schema.groups.EndofSystem_EndBoomConduitClips.groupId, "397281", 1); // CONDUIT CLP CPLRS 51/61' EB 57
                                    }
                                }
                                else {
                                    if (bTheftDeterrentClips) {
                                        if (this.DependentSideSpanSpacing === 114) {
                                            PO.InsertPart(schema.groups.EndofSystem_EndBoomConduitClips.groupId, "397673", 1); // CONDUIT TDC PKG 51/61 EB 114"
                                        }
                                        else {
                                            PO.InsertPart(schema.groups.EndofSystem_EndBoomConduitClips.groupId, "397638", 1); // CONDUIT TDC 51/61' EB 57 E2045
                                        }
                                    }
                                    else {
                                        if (this.DependentSideSpanLength === 194 && this.DependentSideSpanSpacing === 114) {
                                            PO.InsertPart(schema.groups.EndofSystem_EndBoomConduitClips.groupId, "397270", 1); // CONDUIT PKG 61' EB-114 E2045
                                        }
                                        else {
                                            PO.InsertPart(schema.groups.EndofSystem_EndBoomConduitClips.groupId, "397265", 1); // CONDUIT PKG 51/61' EB-57 E2045
                                        }
                                    }
                                }
                                break;
                            default:
                                switch (eConduitClipSpacing) {
                                    case 40:
                                        if (bTheftDeterrentClips) {
                                            PO.InsertPart(schema.groups.EndofSystem_EndBoomConduitClips.groupId, "397662", 1); // CONDUIT TDC PKG 61' EB LEPA
                                        }
                                        else {
                                            PO.InsertPart(schema.groups.EndofSystem_EndBoomConduitClips.groupId, "397294", 1); // CONDUIT CLIP CPLRS 61' EB LEPA
                                        }
                                        break;
                                    case 57:
                                        if (bConduitClipWOSpring) {
                                            if (bTheftDeterrentClips) {
                                                PO.InsertPart(schema.groups.EndofSystem_EndBoomConduitClips.groupId, "397649", 1); // CONDUIT TDC CPLRS 51/61' EB 57
                                            }
                                            else {
                                                PO.InsertPart(schema.groups.EndofSystem_EndBoomConduitClips.groupId, "397281", 1); // CONDUIT CLP CPLRS 51/61' EB 57
                                            }
                                        }
                                        else {
                                            if (bTheftDeterrentClips) {
                                                PO.InsertPart(schema.groups.EndofSystem_EndBoomConduitClips.groupId, "397663", 1); // CONDUIT TDC PKG 61' EB 57"
                                            }
                                            else {
                                                PO.InsertPart(schema.groups.EndofSystem_EndBoomConduitClips.groupId, "397295", 1); // CONDUIT CLIP PKG 61' EB 57"
                                            }
                                        }
                                        break;
                                }
                                break;
                        }
                        break;
                    case 85:
                        if (bTheftDeterrentClips) {
                            PO.InsertPart(schema.groups.EndofSystem_EndBoomConduitClips.groupId, "397664", 1); // CONDUIT TDC PKG 85' EB 57"
                        }
                        else {
                            PO.InsertPart(schema.groups.EndofSystem_EndBoomConduitClips.groupId, "397296", 1); // CONDUIT CLIP PKG 85' EB 57"
                        }
                        break;
                    case 105:
                        if (bTheftDeterrentClips) {
                            PO.InsertPart(schema.groups.EndofSystem_EndBoomConduitClips.groupId, "397665", 1); // CONDUIT TDC PKG 105' EB 57"
                        }
                        else {
                            PO.InsertPart(schema.groups.EndofSystem_EndBoomConduitClips.groupId, "397297", 1); // CONDUIT CLIP PKG 105' EB 57"
                        }
                        break;
                }
            }
        }
        if (!(bSecPowerTower) || eEndBoomLength !== 1) {
            switch (eEndGunType) {
                case EndGunTypes.None:
                    PO.InsertPart(schema.groups.EndofSystem_EndGunAssembly.groupId, "351930", 1); // NO END GUN OPTION-E60
                    break;
                case EndGunTypes.R55i:
                    PO.InsertPart(schema.groups.EndofSystem_EndGunAssembly.groupId, "350177", 1); // NELSON R55i VT NOZZLE DOWN
                    break;
                case EndGunTypes.R55:
                    PO.InsertPart(schema.groups.EndofSystem_EndGunAssembly.groupId, "350175", 1); // NELSON R55 VT NOZZLE UP
                    break;
                case EndGunTypes.R75LP:
                    PO.InsertPart(schema.groups.EndofSystem_EndGunAssembly.groupId, "350180", 1); // R75 NELSON GOLD 25-40 PSI
                    break;
                case EndGunTypes.R75:
                    PO.InsertPart(schema.groups.EndofSystem_EndGunAssembly.groupId, "350181", 1); // R75 NELSON GREY 40-60 PSI
                    break;
                case EndGunTypes.DualP85:
                    switch (this.Side.EndOfSystem.EndGun.BoosterPump) {
                        case BoosterPumpTypes.TwoHP:
                            PO.InsertPart(schema.groups.EndofSystem_EndGunAssembly.groupId, "353145", 1); // NELSON P85A EG-2-W/8RN FM BP
                            break;
                        default:
                            PO.InsertPart(schema.groups.EndofSystem_EndGunAssembly.groupId, "351931", 1); // NELSON P85A END GUNS-2-W/8RN
                            break;
                    }
                    break;
                case EndGunTypes.SingleP85:
                    switch (this.Side.EndOfSystem.EndGun.BoosterPump) {
                        case BoosterPumpTypes.TwoHP:
                            PO.InsertPart(schema.groups.EndofSystem_EndGunAssembly.groupId, "353146", 1); // NELSON P85A EG-1-W/8RN FM BP
                            break;
                        default:
                            PO.InsertPart(schema.groups.EndofSystem_EndGunAssembly.groupId, "351933", 1); // NELSON P85A END GUN-1-W/8RN
                            break;
                    }
                    break;
                case EndGunTypes.SR75:
                    switch (this.Side.EndOfSystem.EndGun.Valve) {
                        case ValveTypes.Auto800:
                            switch (this.Side.EndOfSystem.EndGun.BoosterPump) {
                                case BoosterPumpTypes.None:
                                    if (this.Side.EndOfSystem.StainlessSteelMounts) {
                                        PO.InsertPart(schema.groups.EndofSystem_EndGunAssembly.groupId, "353129SS", 1); // SR75/800P CTRL VLV-NO BST-SS
                                    }
                                    else {
                                        PO.InsertPart(schema.groups.EndofSystem_EndGunAssembly.groupId, "353129", 1); // SR75/800P CTRL VLV-NO BOOST
                                    }
                                    break;
                                case BoosterPumpTypes.TwoHP:
                                    PO.InsertPart(schema.groups.EndofSystem_EndGunAssembly.groupId, "353154", 1); // SR75/800P W/NOZZLE FM BP
                                    break;
                                default:
                                    PO.InsertPart(schema.groups.EndofSystem_EndGunAssembly.groupId, "353126", 1); // VALVE-CTRL SR75/800 W/NOZZLE
                                    break;
                            }
                            break;
                        case ValveTypes.Auto1000:
                            switch (this.Side.EndOfSystem.EndGun.BoosterPump) {
                                case BoosterPumpTypes.None:
                                    if (this.Side.EndOfSystem.StainlessSteelMounts) {
                                        PO.InsertPart(schema.groups.EndofSystem_EndGunAssembly.groupId, "350191SS", 1); // SR75/1000P CTRL VLV-NO BST-SS
                                    }
                                    else {
                                        PO.InsertPart(schema.groups.EndofSystem_EndGunAssembly.groupId, "350191", 1); // SR75/1000P CTRL VLV-NO BOOST
                                    }
                                    break;
                                case BoosterPumpTypes.TwoHP:
                                case BoosterPumpTypes.FiveHP:
                                    PO.InsertPart(schema.groups.EndofSystem_EndGunAssembly.groupId, "350197", 1); // SR75/1000P W/NOZZLE FM BP
                                    break;
                            }
                            break;
                        default:
                            PO.InsertPart(schema.groups.EndofSystem_EndGunAssembly.groupId, "351756", 1); // NELSON SR75 EG-18 DEG
                            break;
                    }
                    break;
                case EndGunTypes.SR100:
                    switch (this.Side.EndOfSystem.EndGun.Valve) {
                        case ValveTypes.Auto800:
                            switch (this.Side.EndOfSystem.EndGun.BoosterPump) {
                                case BoosterPumpTypes.None:
                                    if (this.Side.EndOfSystem.StainlessSteelMounts) {
                                        PO.InsertPart(schema.groups.EndofSystem_EndGunAssembly.groupId, "353128SS", 1); // SR100/800P CTRL VLV-NO BST-SS
                                    }
                                    else {
                                        PO.InsertPart(schema.groups.EndofSystem_EndGunAssembly.groupId, "353128", 1); // SR100/800P CTRL VLV-NO BOOST
                                    }
                                    break;
                                case BoosterPumpTypes.TwoHP:
                                    PO.InsertPart(schema.groups.EndofSystem_EndGunAssembly.groupId, "353153", 1); // SR100/800P W/NOZZLE FM BP
                                    break;
                                default:
                                    PO.InsertPart(schema.groups.EndofSystem_EndGunAssembly.groupId, "353124", 1); // SR100/800P CNTRL VLVE W/NOZZLE
                                    break;
                            }
                            break;
                        case ValveTypes.Auto1000:
                            switch (this.Side.EndOfSystem.EndGun.BoosterPump) {
                                case BoosterPumpTypes.None:
                                    if (this.Side.EndOfSystem.StainlessSteelMounts) {
                                        PO.InsertPart(schema.groups.EndofSystem_EndGunAssembly.groupId, "350190SS", 1); // SR100/1000P CTRL VLV-NO BST-SS
                                    }
                                    else {
                                        PO.InsertPart(schema.groups.EndofSystem_EndGunAssembly.groupId, "350190", 1); // SR100/1000P CTRL VLV-NO BOOST
                                    }
                                    break;
                                case BoosterPumpTypes.TwoHP:
                                    PO.InsertPart(schema.groups.EndofSystem_EndGunAssembly.groupId, "350196", 1); // SR100/1000P W/NOZZLE FM BP
                                    break;
                                case BoosterPumpTypes.FiveHP:
                                    PO.InsertPart(schema.groups.EndofSystem_EndGunAssembly.groupId, "350189", 1); // SR100/1000P CTRL VLV W/NOZZLE
                                    break;
                            }
                            break;
                        default:
                            PO.InsertPart(schema.groups.EndofSystem_EndGunAssembly.groupId, "351754", 1); // NELSON SR100 EG-18DEG-TPR NOZZ
                            break;
                    }
                    break;
                case EndGunTypes.SR100NV:
                    if ((this.Side.EndOfSystem.EndGun.EndGunTypePrimary === EndGunTypes.None || this.Side.EndOfSystem.EndGun.BoosterPump === BoosterPumpTypes.None)) {
                        PO.InsertPart(schema.groups.EndofSystem_EndGunAssembly.groupId, "353125", 1); // NELSON SR100NV EG-18DEG-TPR NZ
                    }
                    else {
                        PO.InsertPart(schema.groups.EndofSystem_EndGunAssembly.groupId, "353125DP", 1); // NELSON SRNV100DP EG-18DEG
                    }
                    break;
                case EndGunTypes.SimeWing:
                    PO.InsertPart(schema.groups.EndofSystem_EndGunAssembly.groupId, "351550", 1); // SIME END GUN WING 18 DEG
                    break;
                case EndGunTypes.TwinMax:
                    PO.InsertPart(schema.groups.EndofSystem_EndGunAssembly.groupId, "351928", 1); // KOMET END GUN TWIN MAX 18 DEG
                    break;
                case EndGunTypes.Twin101Ultra:
                    PO.InsertPart(schema.groups.EndofSystem_EndGunAssembly.groupId, "351927", 1); // KOMET END GUN TWIN 101 ULT 18D
                    break;
            }
        }
        switch (eSecEndGunType) {
            case EndGunTypes.R55i:
                PO.InsertPart(schema.groups.EndofSystem_EndGunAssembly.groupId, "350177", 1); // NELSON R55i VT NOZZLE DOWN
                break;
            case EndGunTypes.R55:
                PO.InsertPart(schema.groups.EndofSystem_EndGunAssembly.groupId, "350175", 1); // NELSON R55 VT NOZZLE UP
                break;
            case EndGunTypes.R75LP:
                PO.InsertPart(schema.groups.EndofSystem_EndGunAssembly.groupId, "350180", 1); // R75 NELSON GOLD 25-40 PSI
                break;
            case EndGunTypes.R75:
                PO.InsertPart(schema.groups.EndofSystem_EndGunAssembly.groupId, "350181", 1); // R75 NELSON GREY 40-60 PSI
                break;
        }
        if (this.Side.EndOfSystem.VerticalBoosterMount) {
            if (this.Side.EndOfSystem.EndGun.Valve === ValveTypes.Auto800) {
                PO.InsertPart(schema.groups.EndofSystem_VerticalBoosterPumpMount.groupId, "351741", 1); // KIT-VERT FLG MT BP W/800/1000P
            }
            else {
                PO.InsertPart(schema.groups.EndofSystem_VerticalBoosterPumpMount.groupId, "351740", 1); // KIT-VERT FLANGE MNT BOOST PUMP
            }
        }
        if (bDiffuser) {
            switch (eEndGunType) {
                case EndGunTypes.DualP85:
                    PO.InsertPart(schema.groups.EndofSystem_DiffuserAssembly.groupId, "351931-D", 1); // DIFFUSER ASY FOR 2-NELSON-P85A
                    break;
                case EndGunTypes.SingleP85:
                    PO.InsertPart(schema.groups.EndofSystem_DiffuserAssembly.groupId, "351933-D", 1); // DIFFUSER ASY FOR 1-NELSON-P85A
                    break;
            }
        }
        if ((this.Side.EndOfSystem.EndGun.EndGunTypePrimary ?? EndGunTypes.None) !== EndGunTypes.None) {
            switch (this.Side.EndOfSystem.EndGun.Valve) {
                case ValveTypes.Manual:
                    PO.InsertPart(schema.groups.EndofSystem_EndGunValve.groupId, "351932", 1); // MANUAL VALVE ASY-FOR END GUN
                    break;
                case ValveTypes.Diaphragm:
                    switch (this.Side.EndOfSystem.EndGun.BoosterPump) {
                        case BoosterPumpTypes.None:
                            PO.InsertPart(schema.groups.EndofSystem_EndGunValve.groupId, "353121", 1); // 2" ELECT DIAPH VALVE ASSY
                            break;
                        case BoosterPumpTypes.TwoHP:
                            switch (eEndGunType) {
                                case EndGunTypes.DualP85:
                                case EndGunTypes.SingleP85:
                                    if (this.Side.EndOfSystem.StainlessSteelMounts) {
                                        PO.InsertPart(schema.groups.EndofSystem_EndGunValve.groupId, "353150SS", 1); // 2" AQUAMATIC VLV-FM BP-P85-SS
                                    }
                                    else {
                                        PO.InsertPart(schema.groups.EndofSystem_EndGunValve.groupId, "353150", 1); // 2" AQUAMATIC VLV-FM BP-P85
                                    }
                                    break;
                                default:
                                    if (this.Side.EndOfSystem.StainlessSteelMounts) {
                                        PO.InsertPart(schema.groups.EndofSystem_EndGunValve.groupId, "353149SS", 1); // 2" AQUAMATIC VLV-FM BP-SS
                                    }
                                    else {
                                        PO.InsertPart(schema.groups.EndofSystem_EndGunValve.groupId, "353149", 1); // 2" AQUAMATIC VLV-FM BP
                                    }
                                    break;
                            }
                            break;
                        default:
                            if (this.Side.EndOfSystem.StainlessSteelMounts) {
                                PO.InsertPart(schema.groups.EndofSystem_EndGunValve.groupId, "353127SS", 1); // 2"ELECT DIAPH VALVE W/BP-SS
                            }
                            else {
                                PO.InsertPart(schema.groups.EndofSystem_EndGunValve.groupId, "353127", 1); // 2" ELECT DIAPH VALVE W/BP MNT
                            }
                            break;
                    }
                    break;
                case ValveTypes.Auto800:
                    switch (eEndGunType) {
                        case EndGunTypes.SR100:
                        case EndGunTypes.SR75:
                            break;
                        default:
                            switch (this.Side.EndOfSystem.EndGun.BoosterPump) {
                                case BoosterPumpTypes.None:
                                    if (this.Side.EndOfSystem.StainlessSteelMounts) {
                                        PO.InsertPart(schema.groups.EndofSystem_EndGunValve.groupId, "353130SS", 1); // 800P CTRL VLV W/SOLENOID-SS
                                    }
                                    else {
                                        PO.InsertPart(schema.groups.EndofSystem_EndGunValve.groupId, "353130", 1); // 800P CTRL VLV W/SOLENOID
                                    }
                                    break;
                                case BoosterPumpTypes.TwoHP:
                                    switch (eEndGunType) {
                                        case EndGunTypes.DualP85:
                                        case EndGunTypes.SingleP85:
                                            if (this.Side.EndOfSystem.StainlessSteelMounts) {
                                                PO.InsertPart(schema.groups.EndofSystem_EndGunValve.groupId, "353147SS", 1); // 800P CV-NO SOL-FM BP-P85-SS
                                            }
                                            else {
                                                PO.InsertPart(schema.groups.EndofSystem_EndGunValve.groupId, "353147", 1); // 800P CV-NO SOL-FM BP-P85
                                            }
                                            break;
                                        default:
                                            if (this.Side.EndOfSystem.StainlessSteelMounts) {
                                                PO.InsertPart(schema.groups.EndofSystem_EndGunValve.groupId, "353148SS", 1); // 800P CV-NO SOL-FM BP-SS
                                            }
                                            else {
                                                PO.InsertPart(schema.groups.EndofSystem_EndGunValve.groupId, "353148", 1); // 800P CV-NO SOL-FM BP
                                            }
                                            break;
                                    }
                                    break;
                                default:
                                    if (this.Side.EndOfSystem.StainlessSteelMounts) {
                                        PO.InsertPart(schema.groups.EndofSystem_EndGunValve.groupId, "353136SS", 1); // 800P CTRL VLV-NO SOLENOID-SS
                                    }
                                    else {
                                        PO.InsertPart(schema.groups.EndofSystem_EndGunValve.groupId, "353136", 1); // 800P CTRL VLV-NO SOLENOID
                                    }
                                    break;
                            }
                            break;
                    }
                    break;
                case ValveTypes.Auto1000:
                    switch (eEndGunType) {
                        case EndGunTypes.SR100:
                        case EndGunTypes.SR75:
                            break;
                        default:
                            switch (this.Side.EndOfSystem.EndGun.BoosterPump) {
                                case BoosterPumpTypes.None:
                                    if (this.Side.EndOfSystem.StainlessSteelMounts) {
                                        PO.InsertPart(schema.groups.EndofSystem_EndGunValve.groupId, "350192SS", 1); // 1000P CTRL VLV W/SOLENOID-SS
                                    }
                                    else {
                                        PO.InsertPart(schema.groups.EndofSystem_EndGunValve.groupId, "350192", 1); // 1000P CTRL VLV W/SOLENOID
                                    }
                                    break;
                                case BoosterPumpTypes.TwoHP:
                                    switch (eEndGunType) {
                                        case EndGunTypes.DualP85:
                                        case EndGunTypes.SingleP85:
                                            if (this.Side.EndOfSystem.StainlessSteelMounts) {
                                                PO.InsertPart(schema.groups.EndofSystem_EndGunValve.groupId, "350194SS", 1); // 1000P CV-NO SOL-FM BP-P85-SS
                                            }
                                            else {
                                                PO.InsertPart(schema.groups.EndofSystem_EndGunValve.groupId, "350194", 1); // 1000P CV-NO SOL-FM BP-P85
                                            }
                                            break;
                                        default:
                                            if (this.Side.EndOfSystem.StainlessSteelMounts) {
                                                PO.InsertPart(schema.groups.EndofSystem_EndGunValve.groupId, "350195SS", 1); // 1000P CV-NO SOL-FM BP-SS
                                            }
                                            else {
                                                PO.InsertPart(schema.groups.EndofSystem_EndGunValve.groupId, "350195", 1); // 1000P CV-NO SOL-FM BP
                                            }
                                            break;
                                    }
                                    break;
                                case BoosterPumpTypes.FiveHP:
                                    if (this.Side.EndOfSystem.StainlessSteelMounts) {
                                        PO.InsertPart(schema.groups.EndofSystem_EndGunValve.groupId, "350193SS", 1); // 1000P CTRL VLV-NO SOLENOID-SS
                                    }
                                    else {
                                        PO.InsertPart(schema.groups.EndofSystem_EndGunValve.groupId, "350193", 1); // 1000P CTRL VLV-NO SOLENOID
                                    }
                                    break;
                            }
                            break;
                    }
                    break;
                case ValveTypes.Reinke:
                    switch (this.Side.EndOfSystem.EndGun.BoosterPump) {
                        case BoosterPumpTypes.None:
                            PO.InsertPart(schema.groups.EndofSystem_EndGunValve.groupId, "353161", 1); // REINKE 2" VALVE NO BP
                            break;
                        case BoosterPumpTypes.TwoHP:
                            switch (eEndGunType) {
                                case EndGunTypes.DualP85:
                                case EndGunTypes.SingleP85:
                                    if (this.Side.EndOfSystem.StainlessSteelMounts) {
                                        PO.InsertPart(schema.groups.EndofSystem_EndGunValve.groupId, "353160SS", 1); // REINKE 2" VALVE W/BP SS
                                    }
                                    else {
                                        PO.InsertPart(schema.groups.EndofSystem_EndGunValve.groupId, "353160", 1); // REINKE 2" VALVE W/BP
                                    }
                                    break;
                                default:
                                    if (this.Side.EndOfSystem.StainlessSteelMounts) {
                                        PO.InsertPart(schema.groups.EndofSystem_EndGunValve.groupId, "353162SS", 1); // REINKE 2" VALVE-FM BP-SS
                                    }
                                    else {
                                        PO.InsertPart(schema.groups.EndofSystem_EndGunValve.groupId, "353162", 1); // REINKE 2" VALVE-FM BP
                                    }
                                    break;
                            }
                            break;
                        default:
                            if (this.Side.EndOfSystem.StainlessSteelMounts) {
                                PO.InsertPart(schema.groups.EndofSystem_EndGunValve.groupId, "353160SS", 1); // REINKE 2" VALVE W/BP SS
                            }
                            else {
                                PO.InsertPart(schema.groups.EndofSystem_EndGunValve.groupId, "353160", 1); // REINKE 2" VALVE W/BP
                            }
                            break;
                    }
                    break;
            }
        }
        if ((this.Side.EndOfSystem.EndGun.EndGunTypeSecondary ?? EndGunTypes.None) !== EndGunTypes.None) {
            switch (this.Side.EndOfSystem.EndGun.SecondaryValve) {
                case ValveTypes.Diaphragm:
                    PO.InsertPart(schema.groups.EndofSystem_EndGunValve.groupId, "353121", 1); // 2" ELECT DIAPH VALVE ASSY
                    break;
                case ValveTypes.Auto800:
                    if (this.Side.EndOfSystem.StainlessSteelMounts) {
                        PO.InsertPart(schema.groups.EndofSystem_EndGunValve.groupId, "353130SS", 1); // 800P CTRL VLV W/SOLENOID-SS
                    }
                    else {
                        PO.InsertPart(schema.groups.EndofSystem_EndGunValve.groupId, "353130", 1); // 800P CTRL VLV W/SOLENOID
                    }
                    break;
                case ValveTypes.Auto1000:
                    if (this.Side.EndOfSystem.StainlessSteelMounts) {
                        PO.InsertPart(schema.groups.EndofSystem_EndGunValve.groupId, "350192SS", 1); // 1000P CTRL VLV W/SOLENOID-SS
                    }
                    else {
                        PO.InsertPart(schema.groups.EndofSystem_EndGunValve.groupId, "350192", 1); // 1000P CTRL VLV W/SOLENOID
                    }
                    break;
                case ValveTypes.Reinke:
                    PO.InsertPart(schema.groups.EndofSystem_EndGunValve.groupId, "353161", 1); // REINKE 2" VALVE NO BP
                    break;
            }
        }
        if (eEndTowerPositioning === EndTowerPositioningTypes.None) {
            if (
                eEndGunType === EndGunTypes.SR100NV || 
                (
                    sidef.HasEndGunPrimary(this.Side) &&
                    ([ValveTypes.Diaphragm, ValveTypes.Auto800, ValveTypes.Auto1000, ValveTypes.Reinke].indexOf(this.Side.EndOfSystem.EndGun.Valve) !== -1)
                )
            ) {
                switch (this.Quote.System.SystemProperties.SystemType) {
                    case SystemTypes.KwikTow:
                        PO.InsertPart(schema.groups.EndofSystem_EndGunSwitch.groupId, "353132", 1); // ELECT END GUN SW ASY-K T& MAXI
                        break;
                    case SystemTypes.CenterPivot:
                        if (eSwitchMount === SwitchMountTypes.CamPlate) {
                            PO.InsertPart(schema.groups.EndofSystem_EndGunSwitch.groupId, "352837", 1); // ASY END GUN SW-CAM PLT STYLE
                        }
                        else {
                            PO.InsertPart(schema.groups.EndofSystem_EndGunSwitch.groupId, "353131", 1); // ELECT END GUN SW ASY-STD PIVOT
                        }
                        break;
                    case SystemTypes.SwingArmRetro:
                        PO.InsertPart(schema.groups.EndofSystem_EndGunSwitch.groupId, "353131", 1); // ELECT END GUN SW ASY-STD PIVOT
                        break;
                }
            }
        }
        if ((this.Side.EndOfSystem.EndGun.EndGunTypePrimary ?? EndGunTypes.None) !== EndGunTypes.None) {
            switch (this.Side.EndOfSystem.EndGun.BoosterPump) {
                case BoosterPumpTypes.None:
                    switch (eEndGunType) {
                        case EndGunTypes.SR100:
                        case EndGunTypes.SR75:
                            if (this.Side.EndOfSystem.StainlessSteelMounts) {
                                if ([ValveTypes.Auto800, ValveTypes.Auto1000].indexOf(this.Side.EndOfSystem.EndGun.Valve) !== -1) {
                                    PO.InsertPart(schema.groups.EndofSystem_EndGunRiserReducer.groupId, "353171SS", 1); // FLANGED ELBOW 2" W/HDWR SS
                                }
                                else {
                                    PO.InsertPart(schema.groups.EndofSystem_EndGunRiserReducer.groupId, "353141SS", 1); // 2" ELL W/DRN-SR100 W/O BP-SS
                                }
                            }
                            else {
                                if ([ValveTypes.Auto800, ValveTypes.Auto1000].indexOf(this.Side.EndOfSystem.EndGun.Valve) !== -1) {
                                    PO.InsertPart(schema.groups.EndofSystem_EndGunRiserReducer.groupId, "353171", 1); // FLANGED ELBOW 2" W/HDWR
                                }
                                else {
                                    PO.InsertPart(schema.groups.EndofSystem_EndGunRiserReducer.groupId, "353141", 1); // 2IN ELBOW W/DRAIN-SR100 W/O BP
                                }
                            }
                            break;
                        case EndGunTypes.SR100NV:
                        case EndGunTypes.SimeWing:
                        case EndGunTypes.TwinMax:
                        case EndGunTypes.Twin101Ultra:
                            if (this.Side.EndOfSystem.StainlessSteelMounts) {
                                PO.InsertPart(schema.groups.EndofSystem_EndGunRiserReducer.groupId, "353141SS", 1); // 2" ELL W/DRN-SR100 W/O BP-SS
                            }
                            else {
                                PO.InsertPart(schema.groups.EndofSystem_EndGunRiserReducer.groupId, "353141", 1); // 2IN ELBOW W/DRAIN-SR100 W/O BP
                            }
                            break;
                    }
                    break;
                case BoosterPumpTypes.TwoHP:
                    switch (this.Side.EndOfSystem.EndGun.Valve) {
                        case ValveTypes.None:
                            if (this.Side.EndOfSystem.StainlessSteelMounts) {
                                PO.InsertPart(schema.groups.EndofSystem_EndGunRiserReducer.groupId, "353152SS", 1); // NOZ VALVE/NO VALVE-FM BP-SS
                            }
                            else {
                                PO.InsertPart(schema.groups.EndofSystem_EndGunRiserReducer.groupId, "353152", 1); // NOZ VALVE/NO VALVE-FM BP
                            }
                            break;
                    }
                    break;
                case BoosterPumpTypes.FiveHP:
                    switch (eEndGunType) {
                        case EndGunTypes.DualP85:
                        case EndGunTypes.SingleP85:
                            switch (this.Side.EndOfSystem.EndGun.Valve) {
                                case ValveTypes.None:
                                    // case EndGunTypes.SR100NV:
                                    if (this.Side.EndOfSystem.StainlessSteelMounts) {
                                        PO.InsertPart(schema.groups.EndofSystem_EndGunRiserReducer.groupId, "353142SS", 1); // ELBOW-MOUNT 2IN BP SR100 W/B P
                                    }
                                    else {
                                        PO.InsertPart(schema.groups.EndofSystem_EndGunRiserReducer.groupId, "353142", 1); // 2IN BP ELBOW MOUNT-SR100 W/B P
                                    }
                                    break;
                            }
                            break;
                        case EndGunTypes.SR100:
                        case EndGunTypes.SR75:
                        case EndGunTypes.SimeWing:
                        case EndGunTypes.TwinMax:
                        case EndGunTypes.Twin101Ultra:
                            switch (this.Side.EndOfSystem.EndGun.Valve) {
                                case ValveTypes.Diaphragm:
                                    if (this.Side.EndOfSystem.StainlessSteelMounts) {
                                        PO.InsertPart(schema.groups.EndofSystem_EndGunRiserReducer.groupId, "353141SS", 1); // 2" ELL W/DRN-SR100 W/O BP-SS
                                    }
                                    else {
                                        PO.InsertPart(schema.groups.EndofSystem_EndGunRiserReducer.groupId, "353141", 1); // 2IN ELBOW W/DRAIN-SR100 W/O BP
                                    }
                                    break;
                                default:
                                    if (this.Side.EndOfSystem.StainlessSteelMounts) {
                                        PO.InsertPart(schema.groups.EndofSystem_EndGunRiserReducer.groupId, "353142SS", 1); // ELBOW-MOUNT 2IN BP SR100 W/B P
                                    }
                                    else {
                                        PO.InsertPart(schema.groups.EndofSystem_EndGunRiserReducer.groupId, "353142", 1); // 2IN BP ELBOW MOUNT-SR100 W/B P
                                    }
                                    break;
                            }
                            break;
                        case EndGunTypes.SR100NV:
                            if (this.Side.EndOfSystem.StainlessSteelMounts) {
                                PO.InsertPart(schema.groups.EndofSystem_EndGunRiserReducer.groupId, "353142SS", 1); // ELBOW-MOUNT 2IN BP SR100 W/B P
                            }
                            else {
                                PO.InsertPart(schema.groups.EndofSystem_EndGunRiserReducer.groupId, "353142", 1); // 2IN BP ELBOW MOUNT-SR100 W/B P
                            }
                            break;
                    }
                    break;
            }
        }
        if (this.Side.EndOfSystem.Strainer) {
            PO.InsertPart(schema.groups.EndofSystem_Strainer.groupId, "357041", 1); // STRAINER-LAST TOWER-END BOOM
        }
        if (eEndBoomLength !== 1 && (this.Side.EndOfSystem.EndGun.EndGunTypePrimary === EndGunTypes.None || this.Side.EndOfSystem.EndGun.BoosterPump === BoosterPumpTypes.None)) {
            if (this.Side.EndOfSystem.StainlessSteelMounts) {
                PO.InsertPart(schema.groups.EndofSystem_EndGunRiserReducer.groupId, "351742SS", 1); // ENG GUN MNT 3X2 ADPTR W/O BPSS
            }
            else {
                switch (eEndBoomLength) {
                    case 85:
                    case 105:
                        PO.InsertPart(schema.groups.EndofSystem_EndGunRiserReducer.groupId, "351743", 1); // END GUN MNT-85 FT EB-W/O BP
                        break;
                    default:
                        PO.InsertPart(schema.groups.EndofSystem_EndGunRiserReducer.groupId, "351742", 1); // END GUN MNT-3X2 ADAPTER
                        break;
                }
            }
        }
        switch (eEndGunType) {
            case EndGunTypes.R55i:
                PO.InsertPart(schema.groups.EndofSystem_EndGunRiserReducer.groupId, "353117", 1); // MOUNT R55i 2"X3" W/ELBOW
                break;
            case EndGunTypes.R55:
            case EndGunTypes.R75LP:
            case EndGunTypes.R75:
                PO.InsertPart(schema.groups.EndofSystem_EndGunRiserReducer.groupId, "353159", 1); // MNT SECONDARY END SPRNKLR SNGL
                break;
        }
        switch (eSecEndGunType) {
            case EndGunTypes.R55i:
                PO.InsertPart(schema.groups.EndofSystem_EndGunRiserReducer.groupId, "353118", 1); // MNT R55i NO VLV 3" BOOM W/GUN
                break;
            case EndGunTypes.R55:
            case EndGunTypes.R75LP:
            case EndGunTypes.R75:
                if (eEndBoomLength === 85 || eEndBoomLength === 105) {
                    PO.InsertPart(schema.groups.EndofSystem_EndGunRiserReducer.groupId, "353114", 1); // MNT SEC GUN-W/PRIMRY GUN 4-1/2
                }
                else {
                    PO.InsertPart(schema.groups.EndofSystem_EndGunRiserReducer.groupId, "353157", 1); // MNT SECONDRY GUN-W/PRIMARY GUN
                }
                PO.InsertPart(schema.groups.EndofSystem_EndGunRiserReducer.groupId, "353158", 1); // MOUNT SECOND GUN 18"
                break;
        }
        if (
            eEndGunType === EndGunTypes.SR100NV || 
            (
                sidef.HasEndGunPrimary(this.Side) &&
                ([ValveTypes.Diaphragm, ValveTypes.Auto800, ValveTypes.Auto1000, ValveTypes.Reinke].indexOf(this.Side.EndOfSystem.EndGun.Valve) !== -1)
            )
        ) {
            switch (eEndBoomLength) {
                case 1:
                    PO.InsertPart(schema.groups.EndofSystem_EndGunWire.groupId, "392580", 1); // END GUN TUBING KIT-3 FT EB
                    break;
                case 13:
                    PO.InsertPart(schema.groups.EndofSystem_EndGunWire.groupId, "392582", 1); // END GUN TUBING KIT-13 FT EB
                    break;
                case 23:
                    PO.InsertPart(schema.groups.EndofSystem_EndGunWire.groupId, "392584", 1); // END GUN TUBING KIT-23 FT EB
                    break;
                case 32:
                    PO.InsertPart(schema.groups.EndofSystem_EndGunWire.groupId, "392586", 1); // END GUN TUBING KIT-32 FT EB
                    break;
                case 42:
                    PO.InsertPart(schema.groups.EndofSystem_EndGunWire.groupId, "392588", 1); // END GUN TUBING KIT-42 FT EB
                    break;
                case 51:
                    PO.InsertPart(schema.groups.EndofSystem_EndGunWire.groupId, "392590", 1); // END GUN TUBING KIT-51 FT EB
                    break;
                case 61:
                    PO.InsertPart(schema.groups.EndofSystem_EndGunWire.groupId, "392592", 1); // END GUN TUBING KIT-61 FT EB
                    break;
                case 85:
                    if (!(bHasSAC)) {
                        if (bIsMaxi) {
                            PO.InsertPart(schema.groups.EndofSystem_EndGunWire.groupId, "392596", 1); // END GUN TUBING KT-85 FT EB-M&S
                        }
                        else {
                            PO.InsertPart(schema.groups.EndofSystem_EndGunWire.groupId, "392594", 1); // END GUN TUBING KIT-85 FT EB
                        }
                    }
                    break;
                case 105:
                    if (!(bHasSAC)) {
                        if (bIsMaxi) {
                            PO.InsertPart(schema.groups.EndofSystem_EndGunWire.groupId, "392597", 1); // END GUN TUBING KIT-105 EB-M&SS
                        }
                        else {
                            PO.InsertPart(schema.groups.EndofSystem_EndGunWire.groupId, "392595", 1); // END GUN TUBING KIT-105 FT EB
                        }
                    }
                    break;
            }
        }
        if (
            eSecEndGunType === EndGunTypes.SR100NV || 
            (
                sidef.HasEndGunSecond(this.Side) &&
                ([ValveTypes.Diaphragm, ValveTypes.Auto800, ValveTypes.Auto1000, ValveTypes.Reinke].indexOf(this.Side.EndOfSystem.EndGun.SecondaryValve) !== -1)
            )
        ) {
            switch (eEndBoomLength) {
                case 1:
                    PO.InsertPart(schema.groups.EndofSystem_EndGunWire.groupId, "392580", 1); // END GUN TUBING KIT-3 FT EB
                    break;
                case 13:
                    PO.InsertPart(schema.groups.EndofSystem_EndGunWire.groupId, "392582", 1); // END GUN TUBING KIT-13 FT EB
                    break;
                case 23:
                    PO.InsertPart(schema.groups.EndofSystem_EndGunWire.groupId, "392584", 1); // END GUN TUBING KIT-23 FT EB
                    break;
                case 32:
                    PO.InsertPart(schema.groups.EndofSystem_EndGunWire.groupId, "392586", 1); // END GUN TUBING KIT-32 FT EB
                    break;
                case 42:
                    PO.InsertPart(schema.groups.EndofSystem_EndGunWire.groupId, "392588", 1); // END GUN TUBING KIT-42 FT EB
                    break;
                case 51:
                    PO.InsertPart(schema.groups.EndofSystem_EndGunWire.groupId, "392590", 1); // END GUN TUBING KIT-51 FT EB
                    break;
                case 61:
                    PO.InsertPart(schema.groups.EndofSystem_EndGunWire.groupId, "392592", 1); // END GUN TUBING KIT-61 FT EB
                    break;
                case 85:
                    if (bIsMaxi) {
                        PO.InsertPart(schema.groups.EndofSystem_EndGunWire.groupId, "392596", 1); // END GUN TUBING KT-85 FT EB-M&S
                    }
                    else {
                        PO.InsertPart(schema.groups.EndofSystem_EndGunWire.groupId, "392594", 1); // END GUN TUBING KIT-85 FT EB
                    }
                    break;
                case 105:
                    if (bIsMaxi) {
                        PO.InsertPart(schema.groups.EndofSystem_EndGunWire.groupId, "392597", 1); // END GUN TUBING KIT-105 EB-M&SS
                    }
                    else {
                        PO.InsertPart(schema.groups.EndofSystem_EndGunWire.groupId, "392595", 1); // END GUN TUBING KIT-105 FT EB
                    }
                    break;
            }
        }
        let treatAsSugargator: boolean = false;
        if (this.Quote.System.SystemProperties.SystemType === SystemTypes.SwingArmRetro) {
            treatAsSugargator = (this.Quote.System.Circle.SACRetro.HingeTowerType === TowerHeights.Sugargator);
        }
        else {
            let towers: Towers = sidef.Towers(this.Quote.System.FlangedSide);
            treatAsSugargator = towers.ToList.length && (towers.Tower[towers.Count - 1].TowerType === TowerHeights.Sugargator);
        }
        if ((this.Side.EndOfSystem.EndGun.EndGunTypePrimary ?? EndGunTypes.None) !== EndGunTypes.None) {
            switch (this.Side.EndOfSystem.EndGun.BoosterPump) {
                case BoosterPumpTypes.None:
                    break;
                case BoosterPumpTypes.FiveHP:
                    if (this.HasPowerTowerEndBoom) {
                        PO.InsertPart(schema.groups.EndofSystem_PowerTowerBoosterWire.groupId, "351758", 1); // WIRE-10C X25'-PWR TWR BST PUMP
                    }
                    if (this.Side.EndOfSystem.StainlessSteelMounts) {
                        switch (eEndBoomLength) {
                            case 1:
                                PO.InsertPart(schema.groups.EndofSystem_BoosterPump.groupId, "351765SS", 1); // 5HP BST PMP ASY W/3' BM-SS
                                break;
                            case 13:
                                PO.InsertPart(schema.groups.EndofSystem_BoosterPump.groupId, "351767SS", 1); // 5HP BST PMP ASY-13' BM-SS
                                break;
                            case 23:
                                PO.InsertPart(schema.groups.EndofSystem_BoosterPump.groupId, "351768SS", 1); // 5HP BST PMP ASY-23' BM-SS
                                break;
                            case 32:
                                PO.InsertPart(schema.groups.EndofSystem_BoosterPump.groupId, "351769SS", 1); // 5HP BST PMP ASY-32' BM-SS
                                break;
                            case 42:
                                PO.InsertPart(schema.groups.EndofSystem_BoosterPump.groupId, "351770SS", 1); // 5HP BST PMP ASY-42' BM-SS
                                break;
                            case 51:
                                PO.InsertPart(schema.groups.EndofSystem_BoosterPump.groupId, "351771SS", 1); // 5HP BST PMP ASY-51' BM-SS
                                break;
                            case 61:
                                PO.InsertPart(schema.groups.EndofSystem_BoosterPump.groupId, "351772SS", 1); // 5HP BST PMP ASY-61' BM-SS
                                break;
                        }
                    }
                    else {
                        switch (eEndBoomLength) {
                            case 1:
                                PO.InsertPart(schema.groups.EndofSystem_BoosterPump.groupId, "351765", 1); // 5HP BOOST PUMP ASY W/3 FT BOOM
                                break;
                            case 13:
                                PO.InsertPart(schema.groups.EndofSystem_BoosterPump.groupId, "351767", 1); // 5HP BOOSTER PUMP ASY-13FT BOOM
                                break;
                            case 23:
                                PO.InsertPart(schema.groups.EndofSystem_BoosterPump.groupId, "351768", 1); // 5HP BOOSTER PUMP ASY-23FT BOOM
                                break;
                            case 32:
                                PO.InsertPart(schema.groups.EndofSystem_BoosterPump.groupId, "351769", 1); // 5HP BOOSTER PUMP ASY-32FT BOOM
                                break;
                            case 42:
                                PO.InsertPart(schema.groups.EndofSystem_BoosterPump.groupId, "351770", 1); // 5HP BOOSTER PUMP ASY-42FT BOOM
                                break;
                            case 51:
                                if (bHasSAC) {
                                    PO.InsertPart(schema.groups.EndofSystem_BoosterPump.groupId, "351946", 1); // 5 HP BSTR PUMP ASY-51 FT MSAC
                                }
                                else {
                                    PO.InsertPart(schema.groups.EndofSystem_BoosterPump.groupId, "351771", 1); // 5HP BOOSTER PUMP ASY-51FT BOOM
                                }
                                break;
                            case 61:
                                if (bHasSAC) {
                                    PO.InsertPart(schema.groups.EndofSystem_BoosterPump.groupId, "351948", 1); // 5 HP BSTR PUMP ASY-61 FT MSSAC
                                }
                                else {
                                    PO.InsertPart(schema.groups.EndofSystem_BoosterPump.groupId, "351772", 1); // 5HP BOOSTER PUMP ASY-61FT BOOM
                                }
                                break;
                            case 85:
                                if (bHasSAC) {
                                    if (treatAsSugargator) {
                                        PO.InsertPart(schema.groups.EndofSystem_BoosterPump.groupId, "351773", 1); // 5HP BSTR PUMP ASY-85' SAC SG
                                    }
                                    else {
                                        PO.InsertPart(schema.groups.EndofSystem_BoosterPump.groupId, "351763E", 1); // 5 HP BSTR PUMP ASY-85 FT ESAC
                                    }
                                }
                                else if (bIsMaxi) {
                                    PO.InsertPart(schema.groups.EndofSystem_BoosterPump.groupId, "351763", 1); // 5 HP BSTR PUMP ASY-85 FT SAC
                                }
                                else {
                                    PO.InsertPart(schema.groups.EndofSystem_BoosterPump.groupId, "351775", 1); // 5 HP BSTR PUMP ASY-85 FT BOOM
                                }
                                break;
                            case 105:
                                if (bHasSAC) {
                                    if (treatAsSugargator) {
                                        PO.InsertPart(schema.groups.EndofSystem_BoosterPump.groupId, "351796", 1); // 5HP BSTR PUMP ASY-105' SSAC SG
                                    }
                                    else {
                                        PO.InsertPart(schema.groups.EndofSystem_BoosterPump.groupId, "351764E", 1); // 5 HP BSTR PMP ASY-105 FT ESSAC
                                    }
                                }
                                else if (bIsMaxi) {
                                    PO.InsertPart(schema.groups.EndofSystem_BoosterPump.groupId, "351764", 1); // 5 HP BSTR PUMP ASY-105 FT SAC
                                }
                                else {
                                    PO.InsertPart(schema.groups.EndofSystem_BoosterPump.groupId, "351777", 1); // 5 HP BSTR PUMP ASY-105 FT BOOM
                                }
                                break;
                        }
                    }
                    break;
                case BoosterPumpTypes.TwoHP:
                    if (this.HasPowerTowerEndBoom) {
                        PO.InsertPart(schema.groups.EndofSystem_PowerTowerBoosterWire.groupId, "351758", 1); // WIRE-10C X25'-PWR TWR BST PUMP
                    }
                    switch (eEndBoomLength) {
                        case 1:
                            PO.InsertPart(schema.groups.EndofSystem_BoosterPump.groupId, "351744F", 1); // 2HP BOOST PUMP ASY W/3 FT BOOM
                            break;
                        case 13:
                            PO.InsertPart(schema.groups.EndofSystem_BoosterPump.groupId, "351745F", 1); // 2 HP BSTR PUMP ASY-13 FT BOOM
                            break;
                        case 23:
                            PO.InsertPart(schema.groups.EndofSystem_BoosterPump.groupId, "351746F", 1); // 2 HP BSTR PUMP ASY-23 FT BOOM
                            break;
                        case 32:
                            PO.InsertPart(schema.groups.EndofSystem_BoosterPump.groupId, "351747F", 1); // 2 HP BSTR PUMP ASY-32 FT BOOM
                            break;
                        case 42:
                            PO.InsertPart(schema.groups.EndofSystem_BoosterPump.groupId, "351748F", 1); // 2 HP BSTR PUMP ASY-42 FT BOOM
                            break;
                        case 51:
                            if (bHasSAC) {
                                PO.InsertPart(schema.groups.EndofSystem_BoosterPump.groupId, "351945", 1); // 2 HP BSTR PUMP ASY-51 FT MSAC
                            }
                            else {
                                PO.InsertPart(schema.groups.EndofSystem_BoosterPump.groupId, "351749F", 1); // 2 HP BSTR PUMP ASY-51 FT BOOM
                            }
                            break;
                        case 61:
                            if (bHasSAC) {
                                PO.InsertPart(schema.groups.EndofSystem_BoosterPump.groupId, "351947", 1); // 2 HP BSTR PUMP ASY-61 FT MSSAC
                            }
                            else {
                                PO.InsertPart(schema.groups.EndofSystem_BoosterPump.groupId, "351750F", 1); // 2 HP BSTR PUMP ASY-61 FT BOOM
                            }
                            break;
                        case 85:
                            if (bHasSAC) {
                                if (treatAsSugargator) {
                                    PO.InsertPart(schema.groups.EndofSystem_BoosterPump.groupId, "351766", 1); // 2 HP BSTR PUMP ASY-85' SAC SG
                                }
                                else {
                                    PO.InsertPart(schema.groups.EndofSystem_BoosterPump.groupId, "351761E", 1); // 2 HP BSTR PUMP ASY-85 FT ESAC
                                }
                            }
                            else if (bIsMaxi) {
                                PO.InsertPart(schema.groups.EndofSystem_BoosterPump.groupId, "351761F", 1); // 2 HP BSTR PUMP ASY-85 FT SAC
                            }
                            else {
                                PO.InsertPart(schema.groups.EndofSystem_BoosterPump.groupId, "351751F", 1); // 2 HP BSTR PUMP ASY-85 FT BOOM
                            }
                            break;
                        case 105:
                            if (bHasSAC) {
                                if (treatAsSugargator) {
                                    PO.InsertPart(schema.groups.EndofSystem_BoosterPump.groupId, "351795", 1); // 2HP BSTR PUMP ASY-105' SSAC SG
                                }
                                else {
                                    PO.InsertPart(schema.groups.EndofSystem_BoosterPump.groupId, "351762E", 1); // 2 HP BSTR PMP ASY-105 FT ESSAC
                                }
                            }
                            else if (bIsMaxi) {
                                PO.InsertPart(schema.groups.EndofSystem_BoosterPump.groupId, "351762F", 1); // 2 HP BSTR PUMP ASY-105 FT SSAC
                            }
                            else {
                                PO.InsertPart(schema.groups.EndofSystem_BoosterPump.groupId, "351752F", 1); // 2 HP BSTR PUMP ASY-105 FT BOOM
                            }
                            break;
                    }
                    break;
            }
            switch (this.Side.EndOfSystem.EndGun.BoosterPump) {
                case BoosterPumpTypes.None:
                    break;
                case BoosterPumpTypes.TwoHP:
                    if (bHasSAC) {
                        PO.InsertPart(schema.groups.EndofSystem_BoosterPumpControlBox.groupId, "351106", 1); // S BOX RPM BP 2HP OPT
                        PO.InsertPart(schema.groups.SwingArmCorner_SwingArmTowerBox.groupId, "351108", 1); // S BOX RPM AIR/BP RELAY OPT
                    }
                    else {
                        if (bTowerUL) {
                            PO.InsertPart(schema.groups.EndofSystem_BoosterPumpControlBox.groupId, "352996", 1); // BOX-CTRL 2HP BP PS UL
                        }
                        else {
                            PO.InsertPart(schema.groups.EndofSystem_BoosterPumpControlBox.groupId, "352995", 1); // BOX-CTRL 2HP BP PS
                        }
                    }
                    break;
                case BoosterPumpTypes.FiveHP:
                    if (bHasSAC) {
                        PO.InsertPart(schema.groups.EndofSystem_BoosterPumpControlBox.groupId, "351107", 1); // S BOX RPM BP 5HP OPT
                        PO.InsertPart(schema.groups.SwingArmCorner_SwingArmTowerBox.groupId, "351108", 1); // S BOX RPM AIR/BP RELAY OPT
                    }
                    else {
                        if (bTowerUL) {
                            PO.InsertPart(schema.groups.EndofSystem_BoosterPumpControlBox.groupId, "352998", 1); // BOX-CTRL 5HP BP PS UL
                        }
                        else {
                            PO.InsertPart(schema.groups.EndofSystem_BoosterPumpControlBox.groupId, "352997", 1); // BOX-CTRL 5HP BP PS
                        }
                    }
                    break;
            }
        }
        switch (this.Side.EndOfSystem.EndLight) {
            case LightTypes.Standard:
                if (bHasSAC) {
                    PO.InsertPart(schema.groups.EndofSystem_EndLight.groupId, "352574", 1); // END TWR LIGHT-SWING ARM CORNER
                }
                else if (this.Side.EndOfSystem.EndLightConstantOn) {
                    PO.InsertPart(schema.groups.EndofSystem_EndLight.groupId, "352508-R", 1); // PIVOT LIGHT ASY-CONST ON-RAMS
                }
                else {
                    PO.InsertPart(schema.groups.EndofSystem_EndLight.groupId, "352508-E", 1); // END LIGHT ASSEMBLY-ELECT/ALUMI
                }
                break;
            case LightTypes.StrobeClear:
            case LightTypes.StrobeAmber:
            case LightTypes.StrobeBlue:
            case LightTypes.StrobeGreen:
            case LightTypes.StrobeRed:
                if (bHasSAC) {
                    PO.InsertPart(schema.groups.EndofSystem_EndLight.groupId, "352575", 1); // STROBE LIGHT ASY-END TWR-SAC
                }
                else if (this.Side.EndOfSystem.EndLightConstantOn) {
                    PO.InsertPart(schema.groups.EndofSystem_EndLight.groupId, "352509-R", 1); // STROBE LIGHT ASY-CONST ON-RAMS
                }
                else {
                    PO.InsertPart(schema.groups.EndofSystem_EndLight.groupId, "352509-E", 1); // STROBE LIGHT ASY-END TOWER-EA
                }
                switch (this.Side.EndOfSystem.EndLight) {
                    case LightTypes.StrobeClear:
                        PO.InsertPart(schema.groups.EndofSystem_StrobeLightBulb.groupId, "352757-C", 1); // BULB-CLEAR STROBE-120V SOCKET
                        break;
                    case LightTypes.StrobeAmber:
                        PO.InsertPart(schema.groups.EndofSystem_StrobeLightBulb.groupId, "352757-A", 1); // BULB-AMBER STROBE-120V SOCKET
                        break;
                    case LightTypes.StrobeBlue:
                        PO.InsertPart(schema.groups.EndofSystem_StrobeLightBulb.groupId, "352757-B", 1); // BULB-BLUE STROBE -120V SOCKET
                        break;
                    case LightTypes.StrobeGreen:
                        PO.InsertPart(schema.groups.EndofSystem_StrobeLightBulb.groupId, "352757-G", 1); // BULB-GREEN STROBE-120V SOCKET
                        break;
                    case LightTypes.StrobeRed:
                        PO.InsertPart(schema.groups.EndofSystem_StrobeLightBulb.groupId, "352757-R", 1); // BULB-RED STROBE-120V SOCKET
                        break;
                }
                break;
            case LightTypes.Strobe400Clear:
                if (bHasSAC) {
                    PO.InsertPart(schema.groups.EndofSystem_StrobeLightBulb.groupId, "352989-C", 1); // PVT LGT-STROBE-400ECP-20-CLR
                }
                else if (this.Side.EndOfSystem.EndLightConstantOn) {
                    PO.InsertPart(schema.groups.EndofSystem_StrobeLightBulb.groupId, "352986-C", 1); // END-STROBE-CONST ON 400ECP-CLR
                }
                else {
                    PO.InsertPart(schema.groups.EndofSystem_StrobeLightBulb.groupId, "352990-C", 1); // PVT LGT ASSY-STROBE-400ECP-CLR
                }
                break;
            case LightTypes.Strobe400Amber:
                if (bHasSAC) {
                    PO.InsertPart(schema.groups.EndofSystem_StrobeLightBulb.groupId, "352989-A", 1); // PVT LGT-STROBE-400ECP-20-AMB
                }
                else if (this.Side.EndOfSystem.EndLightConstantOn) {
                    PO.InsertPart(schema.groups.EndofSystem_StrobeLightBulb.groupId, "352986-A", 1); // END-STROBE-CONST ON 400ECP-AMB
                }
                else {
                    PO.InsertPart(schema.groups.EndofSystem_StrobeLightBulb.groupId, "352990-A", 1); // PVT LGT ASSY-STROBE-400ECP-AMB
                }
                break;
            case LightTypes.Strobe400Blue:
                if (bHasSAC) {
                    PO.InsertPart(schema.groups.EndofSystem_StrobeLightBulb.groupId, "352989-B", 1); // PVT LGT-STROBE-400ECP-20-BLU
                }
                else if (this.Side.EndOfSystem.EndLightConstantOn) {
                    PO.InsertPart(schema.groups.EndofSystem_StrobeLightBulb.groupId, "352986-B", 1); // END-STROBE-CONST ON 400ECP-BLU
                }
                else {
                    PO.InsertPart(schema.groups.EndofSystem_StrobeLightBulb.groupId, "352990-B", 1); // PVT LGT ASSY-STROBE-400ECP-BLU
                }
                break;
            case LightTypes.Strobe400Green:
                if (bHasSAC) {
                    PO.InsertPart(schema.groups.EndofSystem_StrobeLightBulb.groupId, "352989-G", 1); // PVT LGT-STROBE-400ECP-20-GRN
                }
                else if (this.Side.EndOfSystem.EndLightConstantOn) {
                    PO.InsertPart(schema.groups.EndofSystem_StrobeLightBulb.groupId, "352986-G", 1); // END-STROBE-CONST ON 400ECP-GRN
                }
                else {
                    PO.InsertPart(schema.groups.EndofSystem_StrobeLightBulb.groupId, "352990-G", 1); // PVT LGT ASSY-STROBE-400ECP-GRN
                }
                break;
            case LightTypes.Strobe400Red:
                if (bHasSAC) {
                    PO.InsertPart(schema.groups.EndofSystem_StrobeLightBulb.groupId, "352989-R", 1); // PVT LGT-STROBE-400ECP-20-RED
                }
                else if (this.Side.EndOfSystem.EndLightConstantOn) {
                    PO.InsertPart(schema.groups.EndofSystem_StrobeLightBulb.groupId, "352986-R", 1); // END-STROBE-CONST ON 400ECP-RED
                }
                else {
                    PO.InsertPart(schema.groups.EndofSystem_StrobeLightBulb.groupId, "352990-R", 1); // PVT LGT ASSY-STROBE-400ECP-RED
                }
                break;
        }
        if (this.Side.EndOfSystem.AudibleAlarms > 0) {
            PO.InsertPart(schema.groups.RadioTelemetry_CableTheft.groupId, "350701", this.Side.EndOfSystem.AudibleAlarms); // TRAC-AUDIBLE CABLE THEFT ALARM
        }
    }
    public PerformDependencyAnalysis = () => {
        this.HasPowerTowerEndBoom = false;
        this.IsSACRetro = false;
        if (sysf.HasPowerTowerEndBoom(this.Quote.System) && this.SectionID === SectionIDs.LeftEOSID) {
            this.HasPowerTowerEndBoom = true;
        }
        if (this.Quote.IsSACRetro()) {
            this.IsSACRetro = true;
            this.DependentSideSpanType = SpanTypes.E2065;
            this.HingeTowerHeight = this.Quote.System.Circle.SACRetro.HingeTowerType;
            return;
        }
        if (this.HasPowerTowerEndBoom) {
            let sp: Span = this.Quote.System.FlangedSide.Span[1 - 1];
            this.DependentSideSpanLength = sp.Length;
            this.DependentSideSpanType = sp.SpanType;
            this.DependentSideSpanSpacing = sp.Spacing;
            this.HasDependentSideSpanExt = sp.Extension;
        }
        else {
            let iDependentSideNumberOfSpans: number = sidef.NumberOfSpans(this.Side);
            if (this.SectionID === SectionIDs.RightEOSID && sysf.IsDualSided(this.Quote.System)) {
                this.HingeTowerHeight = this.Side.Tower[sidef.NumberOfTowers(this.Side) - 1].TowerType;
            }
            let sp: Span = this.Side.Span[iDependentSideNumberOfSpans - 1];
            if (sp){
                //won't always have spans e.g. we are creating a new system with eos defaults
                this.DependentSideSpanLength = sp.Length;
                this.DependentSideSpanType = sp.SpanType;
                this.DependentSideSpanSpacing = sp.Spacing;
                this.HasDependentSideSpanExt = sp.Extension;
            }
        }
    }
}