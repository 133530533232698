import { Feature, Point } from "@turf/turf";
import IAction from "rdptypes/IAction";
import { SystemTypes } from "rdptypes/project/ISystemBase.AutoGenerated";
import { productSets } from "roedata/data";
import CenterPivotGeometryHelper from "../../../../GeometryHelpers/SystemGeometryHelpers/CenterPivotGeometryHelper";
import { createNewMultiAction } from "../../../../actions/MultiAction";
import { createNewSystemActionV2 } from "../../../../actions/NewSystemActionV2";
import { createNewUpdateSystemPropertyAction } from "../../../../actions/UpdateSystemProperty";
import { createSystemDefaultActions } from "../../../../actions/defaultActions";
import IAuthState from "../../../../auth/IAuthState";
import { IMapContext } from "../../../../components/Map/mapContext";
import { staticDevSettingsDbProvider } from "../../../../db/DevSettingsDbProvider";
import IDbProject from "../../../../db/IDbProject";
import { handleCreateNewOptimizedSystem } from "../../../../helpers/optimizedSystemHelpers/handleCreateNewOptimizedSystem";
import * as optimizeCenterPivotModule from "../../../../optimization/centerPivot";
import { IInteractionState_DrawPivotSystem } from "../interactionState";

export const handleDrawPivotSystem = async (
    features: Feature[],
    dbPrj: IDbProject,
    layoutId: string,
    authState: IAuthState,
    mapContext: IMapContext,
    interactionState: IInteractionState_DrawPivotSystem,
    systemDefaults?: ((layoutId: string, systemId: string, authState: IAuthState) => IAction[])
) => {
    if (features.length !== 1 && features[0].geometry.type !== "Point") {
        throw new Error(`Unexpected feature created`);
    }
    
    if (!interactionState.optimizationArgs) {
        const customerSettings = staticDevSettingsDbProvider.customer.get();
        const dealerPricing = staticDevSettingsDbProvider.dealerPricing.get();
        var { systemId, action } = createNewSystemActionV2(
            layoutId!, SystemTypes.CenterPivot, 
            customerSettings, dealerPricing,
            authState,
            productSets[dbPrj.state.productSetId].latestPartsPackageId
        );
        const actions: IAction[] = [action];
        if (systemDefaults) {
            for (const def of systemDefaults(layoutId, systemId, authState)) {
                actions.push(def);
            }
        }
        else {
            actions.push(...createSystemDefaultActions(layoutId, systemId, authState));
        }
        actions.push(createNewUpdateSystemPropertyAction(
            layoutId!,
            systemId,
            "centerPivot.point",
            features[0].geometry,
            authState
        ));
        dbPrj.pushAction(
            createNewMultiAction(actions, authState)
        );
        const g = new CenterPivotGeometryHelper({
            project: dbPrj.state,
            systemId,
            layoutId
        })
        setTimeout(() => {
            // TODO: This call is buried in timeout. If it
            // is not it does not seem to switch the mode
            // correctly?
            mapContext.changeMode("center_pivot_select", {
                definition: g.propertiesForAll
            });
        }, 0);
    }
    else {
        const center = features[0].geometry as Point;
        const optimizationArgs = optimizeCenterPivotModule.createOptimizationArgs({
            layoutId,
            project: dbPrj.state,
            sacType: interactionState.optimizationArgs?.sacType
        });
        const optimizedSystem = await optimizeCenterPivotModule.optimize({
            optimizationSettings: {
                ...interactionState.optimizationArgs,
                center
            },
            optimizationArgs
        });
        if (!optimizedSystem) {
            console.log("Optimized system was not created");
        }
        else {
            const customerSettings = staticDevSettingsDbProvider.customer.get();
            const dealerPricing = staticDevSettingsDbProvider.dealerPricing.get();
            const systemId = handleCreateNewOptimizedSystem({
                optimizedSystem,
                layoutId,
                dbPrj,
                authState,
                systemDefaults,
                spanDefaults: staticDevSettingsDbProvider.span.getUseCustom()
                    ? staticDevSettingsDbProvider.span.get()
                    : undefined,
                towerDefaults: staticDevSettingsDbProvider.tower.getUseCustom()
                    ? staticDevSettingsDbProvider.tower.get()
                    : undefined,
                customerSettings,
                dealerPricing
            });
        }
    }
}
