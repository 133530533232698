import schema from "../data/partsPackages/2024060101/poSchema";
import CSection2 from "./CSection2";
import PartsAssembler from "./PartsAssembler";
export class FlowmeterComponentsClass extends CSection2 {
    public DataOK = (PA: PartsAssembler, WithWarnings: boolean = false, DependencyCheckInProgress: boolean = false): void => {
        if (!this.FieldSet.DataValid()) return;
        /*if (FieldSet.Value === FieldSetStates.Invalid) {
        return true;
        }
        if (!(FieldSet.ConfigurationValid())) {
        this.Quote.Warnings.Add(this.TranslatedKey);
        return false;
        }*/
    }
    public WritePO = (PO: PartsAssembler) => {
        if (this.Quote.System.FlowmeterComponents.PropHoriz6 > 0) {
            PO.InsertPart(schema.groups.Flowmeters.groupId, "353254", this.Quote.System.FlowmeterComponents.PropHoriz6); // FLOWMETER,6"MCPROP-HRZ
        }
        if (this.Quote.System.FlowmeterComponents.PropHorizPulse6 > 0) {
            PO.InsertPart(schema.groups.Flowmeters.groupId, "353256", this.Quote.System.FlowmeterComponents.PropHorizPulse6); // FLOWMETER,6"MCPROP-HRZ-PULS
        }
        if (this.Quote.System.FlowmeterComponents.PropVert6 > 0) {
            PO.InsertPart(schema.groups.Flowmeters.groupId, "353255", this.Quote.System.FlowmeterComponents.PropVert6); // FLOWMETER,6"MCPROP-VRT
        }
        if (this.Quote.System.FlowmeterComponents.PropVertPulse6 > 0) {
            PO.InsertPart(schema.groups.Flowmeters.groupId, "353257", this.Quote.System.FlowmeterComponents.PropVertPulse6); // FLOWMETER,6"MCPROP-VRT-PULS
        }
        if (this.Quote.System.FlowmeterComponents.PropHoriz8 > 0) {
            PO.InsertPart(schema.groups.Flowmeters.groupId, "355319", this.Quote.System.FlowmeterComponents.PropHoriz8); // FLOWMETER,8"MCPROP-HRZ
        }
        if (this.Quote.System.FlowmeterComponents.PropHorizPulse8 > 0) {
            PO.InsertPart(schema.groups.Flowmeters.groupId, "353407", this.Quote.System.FlowmeterComponents.PropHorizPulse8); // FLOWMETER,8"MCPROP-HRZ-PULS
        }
        if (this.Quote.System.FlowmeterComponents.PropVert8 > 0) {
            PO.InsertPart(schema.groups.Flowmeters.groupId, "355316", this.Quote.System.FlowmeterComponents.PropVert8); // FLOWMETER,8"MCPROP-VRT
        }
        if (this.Quote.System.FlowmeterComponents.PropVertPulse8 > 0) {
            PO.InsertPart(schema.groups.Flowmeters.groupId, "355321", this.Quote.System.FlowmeterComponents.PropVertPulse8); // FLOWMETER,8"MCPROP-VRT-PULS
        }
        if (this.Quote.System.FlowmeterComponents.PropHoriz10 > 0) {
            PO.InsertPart(schema.groups.Flowmeters.groupId, "355320", this.Quote.System.FlowmeterComponents.PropHoriz10); // FLOWMETER,10"MCPROP-HRZ
        }
        if (this.Quote.System.FlowmeterComponents.PropHorizPulse10 > 0) {
            PO.InsertPart(schema.groups.Flowmeters.groupId, "353407-10", this.Quote.System.FlowmeterComponents.PropHorizPulse10); // FLOWMETER,10"MCPROP-HRZ-PULS
        }
        if (this.Quote.System.FlowmeterComponents.PropVert10 > 0) {
            PO.InsertPart(schema.groups.Flowmeters.groupId, "355318", this.Quote.System.FlowmeterComponents.PropVert10); // FLOWMETER,10"MCPROP-VRT
        }
        if (this.Quote.System.FlowmeterComponents.PropVertPulse10 > 0) {
            PO.InsertPart(schema.groups.Flowmeters.groupId, "355317", this.Quote.System.FlowmeterComponents.PropVertPulse10); // FLOWMETER,10"MCPROP-VRT-PULS
        }
        if (this.Quote.System.FlowmeterComponents.PulseCable > 0) {
            PO.InsertPart(schema.groups.Flowmeters.groupId, "353253", this.Quote.System.FlowmeterComponents.PulseCable); // PULSE CABLE-16'-MCCROMETER
        }
        if (this.Quote.System.FlowmeterComponents.Mag6 > 0) {
            PO.InsertPart(schema.groups.Flowmeters.groupId, "353252", this.Quote.System.FlowmeterComponents.Mag6); // FLOWMETER,6"MCMAG 3000 W/PULSE
        }
        if (this.Quote.System.FlowmeterComponents.Mag8 > 0) {
            PO.InsertPart(schema.groups.Flowmeters.groupId, "353262", this.Quote.System.FlowmeterComponents.Mag8); // FLOWMETER,8"MCMAG 3000 W/PULSE
        }
        if (this.Quote.System.FlowmeterComponents.Mag10 > 0) {
            PO.InsertPart(schema.groups.Flowmeters.groupId, "353272", this.Quote.System.FlowmeterComponents.Mag10); // FLOWMETER,10"MCMAG 3000W/PULSE
        }
        if (this.Quote.System.FlowmeterComponents.FS6 > 0) {
            PO.InsertPart(schema.groups.Flowmeters.groupId, "353259", this.Quote.System.FlowmeterComponents.FS6); // MCCROMETER 6" FLOW STRAIGHTENR
        }
        if (this.Quote.System.FlowmeterComponents.FS8 > 0) {
            PO.InsertPart(schema.groups.Flowmeters.groupId, "353269", this.Quote.System.FlowmeterComponents.FS8); // MCCROMETER 8" FLOW STRAIGHTENR
        }
        if (this.Quote.System.FlowmeterComponents.RPShort > 0) {
            PO.InsertPart(schema.groups.Flowmeters.groupId, "353283", this.Quote.System.FlowmeterComponents.RPShort); // SHORT 8" RISER PIPE-FLOW METER
        }
        if (this.Quote.System.FlowmeterComponents.RPLong > 0) {
            PO.InsertPart(schema.groups.Flowmeters.groupId, "353284", this.Quote.System.FlowmeterComponents.RPLong); // LONG 8" RISER PIPE-FLOW METER
        }
        if (this.Quote.System.FlowmeterComponents.FTProp > 0) {
            PO.InsertPart(schema.groups.Flowmeters.groupId, "353265", this.Quote.System.FlowmeterComponents.FTProp); // FLOW TUBE-8" X 14"-MCROMTR MAG
        }
        if (this.Quote.System.FlowmeterComponents.FTMag > 0) {
            PO.InsertPart(schema.groups.Flowmeters.groupId, "353266", this.Quote.System.FlowmeterComponents.FTMag); // FLOW TUBE-8" X 14"-MCRMTR PROP
        }
        if (this.Quote.System.FlowmeterComponents.FTwithVanes > 0) {
            PO.InsertPart(schema.groups.Flowmeters.groupId, "353267", this.Quote.System.FlowmeterComponents.FTwithVanes); // FLOW TUBE-8" X 34" W/VANES
        }
        if (this.Quote.System.FlowmeterComponents.FTwithoutVanes > 0) {
            PO.InsertPart(schema.groups.Flowmeters.groupId, "353268", this.Quote.System.FlowmeterComponents.FTwithoutVanes); // FLOW TUBE-8"-FOR FS100
        }
    }
}