import { Text, View } from "@react-pdf/renderer";
import { t } from "i18next";
import * as React from "react";
import { FC } from "react";
import { POSections } from "roedata/roe_migration/POConstants";
import PriceCalculator, { ISectionGroupedParts } from "roedata/roe_migration/PriceCalculator";
import QuoteClass from "roedata/roe_migration/QuoteClass";
import { HasSwingArmCorner } from "roedata/roe_migration/SystemFunctions";
import { IAncillaryPart, getDiscountConfigFromUserData, getSectionLabel } from "../../components/roe/componentRenderers/Proposal/ProposalHelpers";
import ISystem from "../../model/project/ISystem";
import DealerDataCtx from "../../userData/DealerDataCtx";
import { formatCents } from "../DocumentGenerationHelpers";
import FixedWidthTable, { IRow } from "./table/FixedWidthTable";

export interface Props {
    system: ISystem;
    quote: QuoteClass;
    standardPriceCents: number;
    upgradesPriceCents: number;
    optionsPriceCents: number;
    ancillaryParts: IAncillaryPart[];
    groupedSections: ISectionGroupedParts;
}

const PurchaseOrderTotalPricesTable: FC<Props> = (props) => {
    let ns = "common-phrases.";
    const user = React.useContext(DealerDataCtx);

    let {quote, standardPriceCents, upgradesPriceCents, optionsPriceCents, ancillaryParts, groupedSections} = props;

    let ancillaryGroups: {sectionId: POSections, totalPriceCents}[] = [];

    ancillaryParts.forEach((p) => {
        let idx = ancillaryGroups.findIndex(x => x.sectionId === p.section);
        if (idx === -1){
            ancillaryGroups.push({sectionId: p.section, totalPriceCents: 0});
            idx = ancillaryGroups.findIndex(x => x.sectionId === p.section);
        }

        let ag = ancillaryGroups[idx];
        ag.totalPriceCents += p.partPriceCents * p.qty;
    });

    //Mainline Valves

    quote.System.MainlineValveOptions.Valves.MainlineValve.forEach((valve) => {
        let info = quote.MainlineValvesClass.GetMainlineValveInfo(valve, user.priceList);
        let idx = ancillaryGroups.findIndex(x => x.sectionId === POSections.MainlineValves);
        if (idx === -1){
            ancillaryGroups.push({sectionId: POSections.MainlineValves, totalPriceCents: 0});
            idx = ancillaryGroups.findIndex(x => x.sectionId === POSections.MainlineValves);
        }

        let ag = ancillaryGroups[idx];
        ag.totalPriceCents += info.Price();
    });

    let swingArmPathPriceCents = 0;
    if (HasSwingArmCorner(quote.System)){
        swingArmPathPriceCents = quote.SwingArmClass.SpecialOrderPriceCents();
    }

    let totalCostCents = standardPriceCents + upgradesPriceCents + optionsPriceCents + ancillaryGroups.reduce((n, {totalPriceCents}) => n + totalPriceCents, 0) + swingArmPathPriceCents;

    const discountConfig = getDiscountConfigFromUserData(user, quote.System as ISystem);

    let pc = new PriceCalculator(quote, totalCostCents - ancillaryGroups.reduce((n, {totalPriceCents}) => n + totalPriceCents, 0) - swingArmPathPriceCents, discountConfig, groupedSections, user.priceList);

    let rows: IRow[] = [];
    
    rows.push({cells: [{
        text: "",
        widthPc: 40
    }, {
        text: "",
        widthPc: 30,
    }, {
        text: t('common-phrases.sltListPrice'),
        widthPc: 15,
        header: true
    }, {
        text: t('common-phrases.sltNetPrice'),
        widthPc: 15,
        header: true
    }]});

    rows.push({cells: [{
        text: "",
        widthPc: 40
    }, {
        text: t('common-phrases.sltStandardSystem'),
        widthPc: 30,
    }, {
        text: formatCents(standardPriceCents),
        widthPc: 15,
    }, {
        text: formatCents(pc.getSystemDealerPriceCents(standardPriceCents)),
        widthPc: 15,
    }]});

    rows.push({cells: [{
        text: "",
        widthPc: 40
    }, {
        text: t('common-phrases.sltUpgrades'),
        widthPc: 30,
    }, {
        text: formatCents(upgradesPriceCents),
        widthPc: 15,
    }, {
        text: formatCents(pc.getSystemDealerPriceCents(upgradesPriceCents)),
        widthPc: 15,
    }]});

    rows.push({cells: [{
        text: "",
        widthPc: 40
    }, {
        text: t('common-phrases.sltOptions'),
        widthPc: 30,
    }, {
        text: formatCents(optionsPriceCents),
        widthPc: 15
    }, {
        text: formatCents(pc.getSystemDealerPriceCents(optionsPriceCents)),
        widthPc: 15,
    }]});

    if (swingArmPathPriceCents > 0){
        rows.push({cells: [{
            text: "",
            widthPc: 40
        }, {
            text: t('common-phrases.sltSpecialOrderPart'),
            widthPc: 30,
        }, {
            text: formatCents(swingArmPathPriceCents),
            widthPc: 15
        }, {
            text: formatCents(swingArmPathPriceCents),
            widthPc: 15,
        }]});
    }


    if (ancillaryGroups.length > 0){
        rows.push({cells: [{
            text: "",
            widthPc: 40
        }, {
            text: t("common-phrases.sltAncillary"),
            widthPc: 30,
        }, {
            text: "",
            widthPc: 30,
        }]});

        ancillaryGroups.forEach((ag) => {
            let discountLabel = pc.getDiscountSectionValue(ag.sectionId);
            rows.push({cells: [{
                text: "",
                widthPc: 41
            }, {
                text: `${getSectionLabel(ag.sectionId)} (${discountLabel}% ${t("common-phrases.sltDiscount")})`,
                widthPc: 29,
            }, {
                text: formatCents(ag.totalPriceCents),
                widthPc: 15
            }, {
                text: formatCents(pc.getDiscountBasedOnSection(ag.sectionId)),
                widthPc: 15,
            }]});
        });
    }


    rows.push({cells: [{
        text: "",
        widthPc: 40
    }, {
        text: t("common-phrases.sltTotalPrice"),
        widthPc: 30,
        header: true
    }, {
        text: formatCents(totalCostCents),
        widthPc: 15,
        header: true
    }, {
        text: formatCents(pc.getTotalDealerPriceCents()),
        widthPc: 15,
        header: true
    }]});
    
    let elements: JSX.Element[] = [<FixedWidthTable
        data={{rows}}
    />];

    if (props.system.QuoteProperties.ProposalInformation?.factoryNotes){
        elements.push(<View style={{paddingLeft: 15, paddingRight: 15}}><Text>{t(ns + "sltFactoryNotes")}: {props.system.QuoteProperties.ProposalInformation.factoryNotes}
            </Text>
        </View>);
    }

    return (<View style={{paddingLeft: 15, paddingRight: 15}}>
            {elements}
        </View>
    );
};

export default PurchaseOrderTotalPricesTable;