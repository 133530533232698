import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Snackbar } from "@mui/material";
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import i18next, { t } from "i18next";
import IChoice from "rdptypes/roe/IChoice";
import * as React from "react";
import { FC, useContext, useState } from "react";
import { createNewMultiAction } from "../../../actions/MultiAction";
import { createNewUpdateSystemPropertyAction } from "../../../actions/UpdateSystemProperty";
import AuthCtx from "../../../auth/AuthCtx";
import DevSettingsCtx from "../../../db/DevSettingsCtx";
import IDbProject from '../../../db/IDbProject';
import { forceRoeDisabled } from "../../../helpers/configrationConstants";
import { getValue } from "../../../helpers/objectPathResolver";
import { translate, translateUnit } from "../../../helpers/translation";
import { getImgSource } from "../../../images/ImagePathHelper";
import { pageStyles, primaryColor, secondaryColor } from "../styles";
import { SystemValidationResult } from "../validation/SystemValidator";
import IComponentRenderContext from "./IComponentRenderContext";
import { getComponentRenderer } from "./componentRendererFactory";

const MenuProps = {
  PaperProps: {
    style: {
      /*minWidth: 250,
      width: '100%',
      border: 0,*/
    },
  },
};

interface Props {
  cmp: IChoice,
  dbPrj: IDbProject,
  layoutId: string,
  systemId: string,
  ctx: IComponentRenderContext,
  parentGroupLabel: string | null, //either the same as the current component, don't show title; different from parent, do show title in grey; null, not in a group so display in blue
  small?: boolean;
};

const OPTION_CLEAR_FIELD_DUMMY_VALUE = "__OPTION_CLEAR_FIELD__";
const OPTION_CLEAR_FIELD_TITLE = "Clear field...";

const OPTION_CUSTON_VALUE_DUMMY_VALUE = "__OPTION_CUSTON_VALUE__";

const MultiChoiceRenderer: FC<Props> = (props) => {
  const [ fullValidationResult, setFullValidationResult ]
    = useState<SystemValidationResult | undefined>(undefined);
  
  const fieldPath = props.ctx.fieldRoot + props.cmp.fieldPath;
  const settings = React.useContext(DevSettingsCtx);
  const displaySettings = settings.dealerSettings.display.current;

  const getDisplayedItems = (): string => {
      var list = "";
      const value = getValue(system, fieldPath);

      var option = props.cmp.options.find(x => typeof(x) !== "undefined" && x.id === value);
        if(option){
            list = list.length > 0 ? ", " + translateUnit(option, displaySettings) : translateUnit(option, displaySettings);
        }
        else if (props.cmp.customValueOption) {
          // If we are using a custom value, and the entered value
          // was not found in the options. Then append the word custom to the
          // display value.
          list = value + " " + t("custom");
        }
        if(props.cmp.additionalBooleans){
          props.cmp.additionalBooleans.forEach(ab => {
            if(!!getValue(system, props.ctx.fieldRoot + ab.fieldPath)){
              list+= list.length > 0 ? ", " + translateUnit(ab, displaySettings) : translateUnit(ab, displaySettings);
            }
          });          
        } 

        if (list === "") {
          list = props.ctx.editingDealerDefaults ? "Select (No Default)" : "Select";
        }
    
    return list;
  }

  const authState = useContext(AuthCtx);
  const project = props.dbPrj.state;
  const layout = project.layouts[props.layoutId];
  const system = layout.systems[props.systemId];
  const currentValue = getValue(system, fieldPath);
  const [ openImgSource, setOpenImgSource ] = useState<string | null>(null);
  const [ showCustomDialog, setShowCustomDialog ] = useState(false);

  let showLabel = props.parentGroupLabel !== props.cmp.title;
  var menuItems: JSX.Element[] = [];

  const handleTooltipOpen = (src?: string) => {
    if(src){
      setOpenImgSource(src);
    }  
  }

  const handleTooltipClose = (source?: string) => {
    if(source){
      setOpenImgSource(null);
    }    
  }

  const svr = fullValidationResult ?? props.ctx.vws.validationResult;
  const fvr = svr?.getField(fieldPath);
  let errorSeverity = fvr?.severity;


  if (props.ctx.editingDealerDefaults) {
    var menuItemCmp = <MenuItem key="__OPTION_SELECT__" value={undefined}>
                        <ListItemText primary="Select (No Default)" />
                      </MenuItem>;
    menuItems.push(menuItemCmp);
    menuItems.push(<Divider />);
  }

  props.cmp.options.forEach((option, i) => {
    const disabled = fvr?.badValues.has(option.id);
    let toolTipText = (fvr?.badValues.get(option.id)?.reasons ?? []).join(", ");
    if (toolTipText !== "") {
      toolTipText = "Selecting this option would introduce validation errors: " + toolTipText;
    }

    const imgSource = getImgSource(props.cmp.fieldPath, option.id);
       
        var menuItemCmp = <MenuItem onMouseEnter={() => handleTooltipOpen(imgSource) } onMouseLeave={() => handleTooltipClose(imgSource)} 
                            key={option.id} value={option.id}
                            style={{color: disabled ? "lightgrey" : undefined}} // Cannot use the disabled prop here because it blocks the tooltips
                            title={toolTipText}>                          
                            <ListItemText primary={translateUnit(option, displaySettings)} />
                          </MenuItem>;
        menuItems.push(menuItemCmp);
    });

    if(props.cmp.additionalBooleans){
      props.cmp.additionalBooleans.filter(ab => {
        if (!ab.visible) return true;
        return ab.visible(system);
      }).forEach(ab =>{
        const trueValue = ab.trueValue !== undefined ? ab.trueValue : true;
        const falseValue = ab.falseValue !== undefined ? ab.falseValue : false;

        const abFieldPath = props.ctx.fieldRoot + ab.fieldPath;
        const isChecked = getValue(system, abFieldPath) === trueValue;

        const abFvr =  props.ctx.vws.validationResult.getField(abFieldPath);

        const disabled = ab.validate !== false
          && abFvr
          && !abFvr.badValues.has(isChecked ? trueValue : falseValue)
          && abFvr.badValues.has(isChecked ? falseValue : trueValue);
        let toolTipText = (fvr?.badValues.get(isChecked ? falseValue : trueValue)?.reasons ?? []).join(", ");
        if (toolTipText !== "") {
            toolTipText = "Selecting this option would introduce validation errors: " + toolTipText;
        }
        
        if (abFvr?.severity && errorSeverity !== "error") {
          errorSeverity = abFvr.severity;
        }
        
        const imgSource = getImgSource(ab.fieldPath);

        var menuItemCheckBox = 
        <MenuItem
                                title={toolTipText}
                                disabled={forceRoeDisabled && disabled}
                                sx={{backgroundColor: ab.validate !== false && (abFvr?.severity === "error" || abFvr?.severity === "notset") ? '#fdeded' : 'unset'}}
                                onMouseEnter={() => handleTooltipOpen(imgSource)} onMouseLeave={() => handleTooltipClose(imgSource)} key={ab.fieldPath} value={ab.fieldPath} /*style={{opacity: disabled ? 0.5 : 1}} title={toolTipText}*/>
                                <Checkbox
                                disabled={forceRoeDisabled && disabled}
                                checked={isChecked} style={{color: (!forceRoeDisabled && disabled) ? "lightgrey" : undefined}}  title={toolTipText} />
                                <ListItemText primary={translateUnit(ab, displaySettings)} style={{color: (!forceRoeDisabled && disabled) ? "lightgrey" : undefined}} title={toolTipText} />
                              </MenuItem>;
        menuItems.push(menuItemCheckBox);
      });
    }

    if (props.cmp.appendClearOption || props.cmp.customValueOption) {
      
      if (menuItems.length) {
        menuItems.push(<Divider />)
      }

      if (props.cmp.customValueOption) {
        menuItems.push(
          <MenuItem 
            key={OPTION_CUSTON_VALUE_DUMMY_VALUE} 
            value={OPTION_CUSTON_VALUE_DUMMY_VALUE} 
          >
            <em>{i18next.format(t('custom'), 'capitalize')}...</em>
          </MenuItem>
        );
      }

      if (props.cmp.appendClearOption) {
        const disabled = currentValue === undefined;
        menuItems.push(
          <MenuItem 
            key={OPTION_CLEAR_FIELD_DUMMY_VALUE} 
            value={OPTION_CLEAR_FIELD_DUMMY_VALUE} 
            disabled={forceRoeDisabled && disabled}
            style={{opacity: (!forceRoeDisabled && disabled) ? 0.5 : 1}} 
          >
            <em>{OPTION_CLEAR_FIELD_TITLE}</em>
          </MenuItem>
        );
      }

    }

    let sx = showLabel ? (props.parentGroupLabel === null ? {marginLeft: props.small ? "4px" : "10px", marginRight: props.small ? "4px" : "10px"} :{}) : {
        '& .MuiSelect-select .notranslate::after':  translate(props.cmp)
            ? {
                content: `"${ translate(props.cmp)}"`,
                opacity: 0.42,
              }
            : {},
    };

    let formInputLabelStyle = {...pageStyles.formInputLabelStyle};
    if (errorSeverity !== "error" && errorSeverity !== "notset"){
      formInputLabelStyle["color"] = primaryColor;
    }
    if (props.parentGroupLabel === null)
    {
        formInputLabelStyle["marginLeft"] = props.small ? "4px" : "10px";
        formInputLabelStyle["marginRight"] = props.small ? "4px" : "10px";
    }
    else if (errorSeverity !== "error" && errorSeverity !== "notset" && props.parentGroupLabel !== props.cmp.title)
    {
        formInputLabelStyle["color"] = secondaryColor;
    }

    const [ selectOpen, setOpen ] = React.useState(false);

    const handleClose = () => {
      setFullValidationResult(undefined);
      setOpen(false);
    };
  
    const handleOpen = () => {
      setFullValidationResult(
        props.ctx.vws.validateCmp(system, props.cmp, props.ctx.fieldRoot, ""));
      setOpen(true);
    };

    const handleCustomDialogOK = () => {
      setShowCustomDialog(false);
    }

  let control = (
    <div>
      <FormControl variant="standard" sx={{ m: 1, width: '100%', margin: 0 }} error={errorSeverity === "error" || errorSeverity === "notset"}>
        <InputLabel shrink={true} style={{...formInputLabelStyle}}>{translateUnit(props.cmp, displaySettings)}</InputLabel>
        <Select sx={{...sx, marginTop: !props.cmp.title ? '0px !important' : 'unset', color: (errorSeverity === "error" || errorSeverity === "notset") ? "red": undefined, fontSize: props.small ? "12px" : "1rem" }}
          labelId="demo-multiple-checkbox-label"
          id="multiple-with-checkboxes"
          multiple
          disabled={props.cmp.disabled?.(system)}
          value={[getValue(system, fieldPath)]}
          open={selectOpen}
          onClose={handleClose}
          onOpen={handleOpen}
          onChange={(ev) => {
            var values = (ev.target.value as string[]);
            var selectValues = values.filter(x =>!props.cmp.additionalBooleans || !props.cmp.additionalBooleans.map(ab => ab.fieldPath).includes(x));
            var additionalValues = values.filter(x => props.cmp.additionalBooleans && props.cmp.additionalBooleans.map(ab => ab.fieldPath).includes(x));
            
            if(selectValues.length){
              //only one item can be selected from non-checkbox ones
              const value = selectValues[selectValues.length -1];
              if (value === OPTION_CLEAR_FIELD_DUMMY_VALUE) {
                props.ctx.pushActionAndImproveScores(createNewMultiAction(props.ctx.changeFieldRoots.map(fieldRoot => createNewUpdateSystemPropertyAction(
                  props.layoutId,
                  props.systemId,
                  fieldRoot + props.cmp.fieldPath,
                  undefined,
                  authState
                )), authState), props.ctx.changeFieldRoots.map(fieldRoot => fieldRoot + props.cmp.fieldPath), true);
              }
              else if (value === OPTION_CUSTON_VALUE_DUMMY_VALUE) {
                setShowCustomDialog(true);
              }
              else {
                if (fvr?.badValues.has(value)) {
                  // Disabled
                  return;
                }
                props.ctx.pushActionAndImproveScores(createNewMultiAction(props.ctx.changeFieldRoots.flatMap(fieldRoot => { 
                  var actions = [createNewUpdateSystemPropertyAction(
                    props.layoutId,
                    props.systemId,
                    fieldRoot + props.cmp.fieldPath,
                    value,
                    authState
                  )];

                  const hookResult = props.cmp.hook?.(value, system) || [];
                  for (const r of hookResult) {
                      actions.push(
                          createNewUpdateSystemPropertyAction(
                              props.layoutId,
                              props.systemId,
                              fieldRoot + r.fieldPath,
                              r.value,
                              authState,
                          )
                      )
                  }
                  return actions;
              }), authState), props.ctx.changeFieldRoots.map(fieldRoot => fieldRoot + props.cmp.fieldPath), true);
                
              }
            }
            if(additionalValues.length > 0){
              //update the checkbox section
              additionalValues.forEach(av =>{
                props.ctx.pushActionAndImproveScores(createNewMultiAction(props.ctx.changeFieldRoots.map(fieldRoot => createNewUpdateSystemPropertyAction(
                  props.layoutId,
                  props.systemId,
                  fieldRoot + av,
                  !getValue(system, props.ctx.fieldRoot + av),
                  authState
                )), authState), props.ctx.changeFieldRoots.map(fieldRoot => fieldRoot + av), true);
              });
            }
            else {
              //if we can't select multiple then close the select
              handleClose();
            }

           
          }}         
          renderValue={(selected) => getDisplayedItems()}
          MenuProps={MenuProps}   
        >
           {menuItems}
        </Select>
      </FormControl>
      {
        openImgSource &&
        <Snackbar
            open={openImgSource !== null}
            autoHideDuration={6000}
            onClose={() => setOpenImgSource(null)}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}                
        >
            <div style={{marginTop: 50, marginRight: 20}}><img src={openImgSource} /></div>
        </Snackbar>
      }

      {
        showCustomDialog && props.cmp.customValueOption && (
          <Dialog open={true}>
            <DialogTitle>{i18next.format(t('custom-value'), 'capitalize-each')}</DialogTitle>
            <DialogContent>
              <Box sx={{ width: "95%" }}>
                {
                  getComponentRenderer(
                    props.cmp.customValueOption, 
                    props.dbPrj, 
                    props.layoutId, 
                    props.systemId, 
                    props.ctx, 
                    props.parentGroupLabel
                  )
                }
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCustomDialogOK}>{t("ok")}</Button>
            </DialogActions>
          </Dialog>
        )
      }
    </div>
  );

  return control;
}

export default MultiChoiceRenderer;