import IAction from "rdptypes/IAction";
import IActionData from "rdptypes/IActionData";
import { AgriInjectTypes, Countries, GeneratorOptions, SystemTypes } from 'rdptypes/project/ISystemBase.AutoGenerated';
import { v4 as uuidv4 } from 'uuid';
import IAuthState from '../auth/IAuthState';
import { IDealerCustomerSettings, IDealerPricing } from '../db/IDevSettingsState';
import IProject from "../model/project/IProject";
import { registerExecutor } from "./actionExecutorRegistry";
import { createAction, recordLayoutChange, recordProjectChange, recordSystemChange } from './helpers';

const actionTypeId = "NewSystemV2";

// NOTE: The new system action has been superceeded by V2.
//      v2: remove localStorage calls (via static settings provider) as localStorage is not
//          accessable via workers
// The V1 action has been left so to not crash existing DBs
// As the action is no longer imported anywhere, the registor does not execute
// It would be sensible to import it here, but this would lead to the same localStorage 
// import access problem. Thus, the module is imported at the root of the pwa.

interface ActionData extends IActionData {
    layoutId: string;

    systemId: string;

    systemType: SystemTypes;

    country: Countries;

    customerSettings: IDealerCustomerSettings;
    dealerPricing?: IDealerPricing;
    
    /**
     * This field is optional because it was introduced subsequent to this class. It should be assumed as
     * 2024060101 (the initial parts package) if left undefined.
     */
    partsPackageId?: string;
}

const getBaseName = (systemType: SystemTypes) => {
    return systemType === SystemTypes.SwingArmRetro ? "Swing Arm Retro" : systemType === SystemTypes.Ancillary ? "Ancillary" : systemType === SystemTypes.CanalFeedMaxigator || systemType === SystemTypes.HoseFeedMaxigator ? "Lateral" : "Center Pivot";
}

registerExecutor(actionTypeId, (action: IAction, state: IProject) => {
    const data = action.data as ActionData;
    let baseName = getBaseName(data.systemType);

    const customerSettings = data.customerSettings;
    const dealerPricing = data.dealerPricing;
    
    let sameSystemTypeCount = 0;
    for (var systemId in state.layouts[data.layoutId].systems) {
        if (getBaseName(state.layouts[data.layoutId].systems[systemId].SystemProperties.SystemType) === baseName) {
            sameSystemTypeCount ++;
        }
    }

    state.layouts[data.layoutId].systems[data.systemId] = {
        partsPackageId: data.partsPackageId ?? "2024060101", // Use the initial parts package ID for older systems

        name: `${baseName} ${sameSystemTypeCount + 1}`,

        lastModifiedTimestamp: 0, // Updated to the actual timestamp in recordSystemChange
        lastModifiedUserId: "", // Updated to the actual user ID in recordLayoutChange

        SystemProperties: {
            SystemType: data.systemType
        },

        DealerProperties: {
            Country: data.country ?? Countries.UnitedStates
        },

        MainlineValveOptions: {
            Valves: {
                MainlineValve: []
            }
        },

        Ontrac: {
            Radio: {},
            SatellitePlus: {
                SatellitePlusUnit: []
            },
            Cell: {}
        },

        SprinklerProperties: {
            VRIZones: {
                Zone: []
            }
        },

        Options: {
            GeneratorOption: GeneratorOptions.None
        },

        FlangedSide: {
            Span: [],
            Tower: [],
            EndOfSystem: {
                EndGun: {}
            },
            Sprinklers: {
                BoomBacks: {},
                Package: []
            },
            SprinklerChart: {
                IrrigationProperties: {
                    EndGunIrrigationProperties: {},
                    SecondaryEndGunIrrigationProperties: {}
                },
                Outlet: []
            }
        },

        FlexSide: {
            Span: [],
            Tower: [],
            EndOfSystem: {
                EndGun: {}
            },
            Sprinklers: {
                BoomBacks: {},
                Package: []
            },
            SprinklerChart: {
                IrrigationProperties: {
                    EndGunIrrigationProperties: {},
                    SecondaryEndGunIrrigationProperties: {}
                },
                Outlet: []
            }
        },

        AgriInject: {
            AgriInjectType: AgriInjectTypes.None
        },
        SprinklerLube: {},
        HeatExchangers: {},
        ClemonsFilters: {},
        CheckValves: {},
        FlowmeterComponents: {},
        RC10: {},
        Circle: {
            CenterPivot: {
                TowOptions: {
                    Tires: {}
                }
            },
            KwikTow: {
                Tires: {}
            },
            SwingArm: {
                Tires: {}
            },
            SACRetro: {}
        },

        CropX: {},

        Lateral: {
            Tires: {},
            HoseFeed: {
                ElectricDragCordLength: 0, //setting a temporary default value here as currently page cmp defaults are not being used (defaultActions.tsx)
                HardHoseAdapters: 1 //setting a temporary default value here as currently page cmp defaults are not being used (defaultActions.tsx) - be careful because we need to check that Hose and Couplers type is not None (verified where we add PO records)
            },
            CanalFeed: {},
            Guidance: {
                FurrowOptions: {},
                CableOptions: {},
                BuriedWireOptions: {
                    Wire: {}
                }
            }
        },

        ControlPanel: {
            RemotePanelOptions: {}
        },

        SpanProperties: {},

        TowerProperties: {
            TireProperties: {}
        },

        mcp: {
        },

        QuoteProperties: {
            ProposalInformation: {
                pricing: {
                    installation: {
                        isTaxable: customerSettings.installationIsTaxable
                    },
                    freight: {
                        isTaxable: customerSettings.freightIsTaxable
                    },
                    pivotPad: {
                        isTaxable: customerSettings.pivotPadIsTaxable
                    },
                    tradeIn: {
                        isTaxable: customerSettings.tradeinIsTaxable
                    },
                    system: {
                        isTaxable: customerSettings.systemIsTaxable
                    },
                    onlyPrintTotalPrice: customerSettings.onlyPrintTotalPrice,
                    printListPrice: customerSettings.printListPrice,
                    markupPercentOverDealerCost: customerSettings.markupPercentOverDealerCost,
                    markupDollarOverDealerCost: customerSettings.markupDollarOverDealerCost,
                    listPriceDiscountPercent: customerSettings.listPriceDiscountPercent,
                    quotedPriceDollar: 0,
                    salesTaxPercent: customerSettings.salesTaxPercent,
                    deliveryDiscountPercent: dealerPricing ? dealerPricing.deliveryDiscountPercent : undefined,
                    tmDiscountPercent:  0,
                    programDiscountPercent:  dealerPricing ? dealerPricing.programDiscountPercent : undefined
                }
            }
        }
    };
   
    recordProjectChange(action, state);
    recordLayoutChange(action, state, data.layoutId);
    recordSystemChange(action, state, data.layoutId, data.systemId);
});


export const createNewSystemActionV2 = (
    layoutId: string, systemType: SystemTypes, 
    customerSettings: IDealerCustomerSettings, dealerPricing: IDealerPricing | undefined = undefined,
    authState: IAuthState,
    partsPackageId: string
) => {
    const systemId = uuidv4();
    const action = createAction(
        actionTypeId,
        {
            layoutId,
            systemId,
            systemType,
            country: Countries.UnitedStates,
            customerSettings,
            dealerPricing,
            partsPackageId
        } as ActionData,
        authState);
    return {
        systemId,
        action
    };
};