import schema from "../data/partsPackages/2024060101/poSchema";
import CSection2 from "./CSection2";
import PartsAssembler from "./PartsAssembler";
export class SprinklerLubeClass extends CSection2 {
    public DataOK = (PA: PartsAssembler, WithWarnings: boolean = false, DependencyCheckInProgress: boolean = false): void => {
        if (!this.FieldSet.DataValid()) return;
        /*if (FieldSet.Value === FieldSetStates.Invalid) {
        return true;
        }
        if (!(FieldSet.ConfigurationValid())) {
        this.Quote.Warnings.Add(this.TranslatedKey);
        return false;
        }*/
    }
    public WritePO = (PO: PartsAssembler) => {
        if (this.Quote.System.SprinklerLube.SL1200_35LBPailQty > 0) {
            PO.InsertPart(schema.groups.SprinklerLube_SprinklerLube1200.groupId, "119100", this.Quote.System.SprinklerLube.SL1200_35LBPailQty); // SPRINKLER LUBE 1200 35 LB PAIL
        }
        if (this.Quote.System.SprinklerLube.SL1200_120LBPKegQty > 0) {
            PO.InsertPart(schema.groups.SprinklerLube_SprinklerLube1200.groupId, "119101", this.Quote.System.SprinklerLube.SL1200_120LBPKegQty); // SPRINKLER LUBE 1200 120 LB KEG
        }
        if (this.Quote.System.SprinklerLube.SL1200_400LBDrumQty > 0) {
            PO.InsertPart(schema.groups.SprinklerLube_SprinklerLube1200.groupId, "119102", this.Quote.System.SprinklerLube.SL1200_400LBDrumQty); // SPRINKLER LUBE 1200 400LB DRUM
        }
        if (this.Quote.System.SprinklerLube.SL220_35LBPailQty > 0) {
            PO.InsertPart(schema.groups.SprinklerLube_SprinklerLube220.groupId, "119080", this.Quote.System.SprinklerLube.SL220_35LBPailQty); // SPRINKLER LUBE 220-2/17.5 LB
        }
        if (this.Quote.System.SprinklerLube.SL220_220LBKegQty > 0) {
            PO.InsertPart(schema.groups.SprinklerLube_SprinklerLube220.groupId, "119082", this.Quote.System.SprinklerLube.SL220_220LBKegQty); // SPRINKLER LUBE 220-220 LB
        }
        if (this.Quote.System.SprinklerLube.SL220_400LBDrumQty > 0) {
            PO.InsertPart(schema.groups.SprinklerLube_SprinklerLube220.groupId, "119083", this.Quote.System.SprinklerLube.SL220_400LBDrumQty); // SPRINKLER LUBE 220-400 LB
        }
        if (this.Quote.System.SprinklerLube.PumpManual35LBPailQty > 0) {
            PO.InsertPart(schema.groups.SprinklerLube_PumpOptions.groupId, "119095", this.Quote.System.SprinklerLube.PumpManual35LBPailQty); // PUMP-LUBE MANUAL 5GAL PAIL
        }
        if (this.Quote.System.SprinklerLube.PumpManual120LBKegQty > 0) {
            PO.InsertPart(schema.groups.SprinklerLube_PumpOptions.groupId, "119096", this.Quote.System.SprinklerLube.PumpManual120LBKegQty); // PUMP-LUBE MANUAL 16GAL DRUM
        }
        if (this.Quote.System.SprinklerLube.Pneumatic120LBKegQty > 0) {
            PO.InsertPart(schema.groups.SprinklerLube_PumpOptions.groupId, "119092", this.Quote.System.SprinklerLube.Pneumatic120LBKegQty); // PUMP-SPK LUBE-120#-W/O REEL
        }
        if (this.Quote.System.SprinklerLube.Pneumatic120LBKegWithReelQty > 0) {
            PO.InsertPart(schema.groups.SprinklerLube_PumpOptions.groupId, "119091", this.Quote.System.SprinklerLube.Pneumatic120LBKegWithReelQty); // PUMP-SPK LUBE-120#-W/REEL
        }
        if (this.Quote.System.SprinklerLube.Pneumatic400LBDrumQty > 0) {
            PO.InsertPart(schema.groups.SprinklerLube_PumpOptions.groupId, "119090", this.Quote.System.SprinklerLube.Pneumatic400LBDrumQty); // PUMP-SPK LUBE-400#-W/O REEL
        }
        if (this.Quote.System.SprinklerLube.Pneumatic400LBDrumWithReelQty > 0) {
            PO.InsertPart(schema.groups.SprinklerLube_PumpOptions.groupId, "119089", this.Quote.System.SprinklerLube.Pneumatic400LBDrumWithReelQty); // PUMP-SPK LUBE-400#-W/ REEL
        }
    }
}