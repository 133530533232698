import { MenuItem } from "@mui/material";
import { Feature, Polygon, area, cleanCoords, unkinkPolygon } from "@turf/turf";
import i18next, { t } from "i18next";
import * as React from "react";
import CommonDialogsCtx from "../../../../../../commonDialogs/CommonDialogsCtx";
import { ImportExportFeature } from "../../../../../../helpers/importExport";
import DealerDataCtx from "../../../../../../userData/DealerDataCtx";
import { ImportExportProject } from "../common/interfaces";
import { ImportFileType, importFile } from "./importFile";

interface IProps {
    onClick: (features: ImportExportProject) => void;
    onCancel: () => void;
}

const ImportMenuItem: React.FC<IProps> = ({
    onClick, onCancel
}) => {
    const user = React.useContext(DealerDataCtx);
    const commonDialogs = React.useContext(CommonDialogsCtx);


    const handleFileOnChange = async (ev: React.ChangeEvent<HTMLInputElement>) => {

        if (!ev.target.files || ev.target.files.length < 1) {
            onCancel();
            return;
        }

        const features: ImportExportFeature[] = [];
        try {
            for (const file of ev.target.files) {
                const extension = file.name.split(".").pop();
                let fileType: ImportFileType;
                switch (extension) {
                    case 'rap':
                        fileType = ImportFileType.RAP;
                        if (!user.permissions.enableRapFileImport) {
                            commonDialogs.showFeatureNotEnabled();
                            return;
                        }
                        break;
                    case 'shp':
                        fileType = ImportFileType.ShapeFile;
                        break;
                    default:
                        continue;
                }
                const fileFeatures = await importFile({
                    blob: file,
                    fileType
                });

                // SW NOTE (2024.04.29): 
                //  It was found that a RAP file can have self intersecting polygons.
                //  The sanitization bellow is designed to handle kinked polygons imported
                //  from any file shape. Currently only Polygons are handled here, when more
                //  corner cases are found in the future. Please add them bellow:
                const sanitizedFileFeatures: ImportExportFeature[] = [];
                for (const ffIn of fileFeatures.features) {
                    const ff = cleanCoords(ffIn);
                    if (ff.geometry.type === 'Polygon') {
                        const uk = unkinkPolygon(ff as Feature<Polygon>);
                        let best:  { feature: Feature<Polygon>, area: number } | undefined = undefined;
                        for (const k of uk.features) {
                            const a = area(k);
                            if (!best || best.area < a) {
                                best = {
                                    feature: k, area: a
                                }
                            }
                        }
                        if (best) {
                            sanitizedFileFeatures.push({
                                ...best.feature,
                                properties: {
                                    ...ff.properties
                                }
                            })
                        }
                    }
                    else {
                        sanitizedFileFeatures.push(ff);
                    }
                }
                features.push(...sanitizedFileFeatures);
            }
            onClick({
                features
            });
        }
        catch (e) {
            console.error(e);
            onCancel();
        }

        ev.target.value = '';
    }

    return (
        <>
            <MenuItem component="label">
                {i18next.format(t('import'), 'capitalize')}
                <input
                    type="file"
                    hidden
                    onChange={handleFileOnChange}
                    accept=".shp, .rap"
                    multiple
                />
            </MenuItem>
        </>
    )
}


export default ImportMenuItem;