import { Point, destination } from "@turf/turf";
import { ECircleDirection, getWrapAngle } from "rdptypes/helpers/Wraps";
import { WrapAroundSpanTypes } from "rdptypes/project/ISystemBase.AutoGenerated";
import * as spanf from "roedata/roe_migration/SpanFunctions";
import ISystem from "../../../model/project/ISystem";

export interface ISpanSegment {
    center: Point;
    innerRadiusFeet: number;
    outerRadiusFeet: number;
    startBearing: number;
    sweepAngle: number;
    type: 'anticlockwiseWrap' | 'center' | 'clockwiseWrap';
    hasAddedDropSpan?: boolean;
    wrapNumber?: number;
}

export type ISpanVertex = {
    type: 'pivotCenter' | 'anticlockwiseWrapSpan' | 'clockwiseWrapSpan' | 'clockwiseCompassHeadingStart' | 'clockwiseCompassHeadingEnd';
    spanIndex: number;
    handle: Point;
    center: Point;
} | {
    type: 'dropSpanStart' | 'dropSpanEnd';
    spanIndex: number;
    handle: Point;
    segments: ISpanSegment[];
}

type ISpanVertexForCalc = {
    type: 'clockwiseWrapSpan' | 'anticlockwiseWrapSpan' | 'clockwiseCompassHeadingEnd' | 'clockwiseCompassHeadingStart';
    center: Point;
    radiusFeet: number;
    spanIndex: number;
    bearing: number;
} | {
    type: 'dropSpanStart' | 'dropSpanEnd';
    center: Point;
    radiusFeet: number;
    spanIndex: number;
    bearing: number;
    segments: ISpanSegment[];
}

export const calculateSpanSegments = (system: ISystem, spanTowerIndex: number): {
    segments: ISpanSegment[];
    verticies: ISpanVertex[];
} => {
    const pivotBearingFrom = (system.Circle.CenterPivot.isPartialPivot && system.Circle!.CenterPivot!.clockwiseCompassHeadingStart) || 0;
    const pivotBearingTo = (system.Circle.CenterPivot.isPartialPivot && system.Circle!.CenterPivot!.clockwiseCompassHeadingEnd) || 360;
    let sweepAngle = (pivotBearingTo - pivotBearingFrom + 360) % 360;
    if (sweepAngle === 0) {
        sweepAngle = 360;
    }

    const startRadius = system.FlangedSide.Span.length
        ? spanf.StartingRadius(system, system.FlangedSide, system.FlangedSide.Span[0])
        : 0;

    // seed segments:
    let segments: ISpanSegment[] = [
        {
            center: system.centerPivot!.point,
            innerRadiusFeet: startRadius,
            outerRadiusFeet: startRadius,
            startBearing: pivotBearingFrom,
            sweepAngle: sweepAngle,
            type: 'center'
        }
    ]

    let endVertex: ISpanVertexForCalc = {
        type: 'clockwiseCompassHeadingEnd',
        center: system.centerPivot!.point,
        spanIndex: -1,
        radiusFeet: startRadius,
        bearing: pivotBearingTo
    }
    let startVertex: ISpanVertexForCalc = {
        type: 'clockwiseCompassHeadingStart',
        center: system.centerPivot!.point,
        spanIndex: -1,
        radiusFeet: startRadius,
        bearing: pivotBearingFrom
    }
    let wrapNumber = -1;

    // consider all span towers up to but not including the request span.
    // We do not want the drop or wrap information for the current span, as these
    // become effective for the following spans
    for (let i = 0; i < spanTowerIndex; i++) {
        const span = system.FlangedSide.Span[i];
        const tower = system.FlangedSide.Tower[i];
        const spanLength = Number.isFinite(spanf.LengthInFeet(system.FlangedSide, span))
            ? spanf.LengthInFeet(system.FlangedSide, span)
            : 0;
        if (endVertex.type !== 'dropSpanEnd') {
            endVertex.radiusFeet += spanLength;
        }
        if (startVertex.type !== 'dropSpanStart') {
            startVertex.radiusFeet += spanLength;
        }

        // increase radius of each segment
        for (const segment of segments) {
            segment.innerRadiusFeet = segment.outerRadiusFeet;
            segment.outerRadiusFeet += spanLength;
        }

        // if this span tower has a drop span:
        if (span.Disconnecting) {
            let dropSpanStartRelativeToPreviousSpanStart = span.dropSpanStartRelativeToPreviousSpanStart || 0;
            let dropSpanEndRelativeToPreviousSpanEnd = span.dropSpanEndRelativeToPreviousSpanEnd || 0;

            const segmentsClone = structuredClone(segments).map(x => ({
                ...x,
                startBearing: (x.startBearing + 360) % 360
            }));

            // remove any anticlockwise wraps before the start:
            if (dropSpanStartRelativeToPreviousSpanStart > 0) {
                while (segments.length && segments[0].sweepAngle < dropSpanStartRelativeToPreviousSpanStart) {
                    dropSpanStartRelativeToPreviousSpanStart -= segments[0].sweepAngle;
                    segments.splice(0, 1);
                }
                if (segments.length) {
                    const segment = segments[0];
                    segment.startBearing += dropSpanStartRelativeToPreviousSpanStart;
                    segment.sweepAngle -= dropSpanStartRelativeToPreviousSpanStart;
                }
            }
            else {
                // we will ignore negative drops:
            }

            if (segments.length) {
                // start drop vertex
                const segment = segments[0];

                startVertex = {
                    type: 'dropSpanStart',
                    spanIndex: i,
                    segments: segmentsClone,
                    
                    radiusFeet: segment.innerRadiusFeet,
                    center: segment.center,
                    bearing: segment.startBearing
                }
            }
            
            // remove any clockwise wraps after the end:
            if (dropSpanEndRelativeToPreviousSpanEnd > 0) {
                while (segments.length && segments[segments.length - 1].sweepAngle < dropSpanEndRelativeToPreviousSpanEnd) {
                    dropSpanEndRelativeToPreviousSpanEnd -= segments[segments.length - 1].sweepAngle;
                    segments.splice(segments.length - 1);
                }
                if (segments.length) {
                    const segment = segments[segments.length - 1];
                    segment.sweepAngle -= dropSpanEndRelativeToPreviousSpanEnd;
                }
            }
            else {
                // we will ignore negative drops:
            }
            
            if (segments.length) {
                // start drop vertex
                const segment = segments[segments.length - 1];

                endVertex = {
                    type: 'dropSpanEnd',
                    spanIndex: i,
                    segments: segmentsClone,
                    
                    radiusFeet: segment.innerRadiusFeet,
                    center: segment.center,
                    bearing: segment.startBearing + segment.sweepAngle
                }
            }

            segments.forEach(x => x.hasAddedDropSpan = true);
        }
        
        const hasWrap = system.Circle.CenterPivot.isPartialPivot && !span.EndBoom && tower.WrapAroundSpan !== WrapAroundSpanTypes.None && segments.length;
        if (hasWrap) {
            wrapNumber++;
        }
        // if this span tower has a acw wrap span:
        if (hasWrap) {
            const anticlockwiseWrapAngleRelativeToPreviousSpanDegrees = getWrapAngle(system, tower, ECircleDirection.REV);
            const preceedingSegment = segments[0];
            const center = destination(
                preceedingSegment.center,
                preceedingSegment.outerRadiusFeet,
                preceedingSegment.startBearing,
                { units: 'feet' }
            ).geometry;
            startVertex = {
                type: 'anticlockwiseWrapSpan',
                spanIndex: i,
                radiusFeet: 0,
                center,
                bearing: preceedingSegment.startBearing - anticlockwiseWrapAngleRelativeToPreviousSpanDegrees
            }

            // add the wrap as the first segment if the sweep angle is defined:
            if (anticlockwiseWrapAngleRelativeToPreviousSpanDegrees > 0) {
                segments = [
                    {
                        center,
                        innerRadiusFeet: 0,
                        outerRadiusFeet: 0,
                        startBearing: preceedingSegment.startBearing - anticlockwiseWrapAngleRelativeToPreviousSpanDegrees,
                        sweepAngle: anticlockwiseWrapAngleRelativeToPreviousSpanDegrees,
                        type: 'anticlockwiseWrap',
                        wrapNumber
                    },
                    ...segments
                ]
            }
        }
        
        // if this span tower has a cw wrap span:
        if (hasWrap) {
            const clockwiseWrapAngleRelativeToPreviousSpanDegrees = getWrapAngle(system, tower, ECircleDirection.FWD);
            const preceedingSegment = segments[segments.length - 1];
            const center = destination(
                preceedingSegment.center,
                preceedingSegment.outerRadiusFeet,
                preceedingSegment.startBearing + preceedingSegment.sweepAngle,
                { units: 'feet' }
            ).geometry;
            endVertex = {
                type: 'clockwiseWrapSpan',
                spanIndex: i,
                radiusFeet: 0,
                center,
                bearing: preceedingSegment.startBearing + preceedingSegment.sweepAngle + clockwiseWrapAngleRelativeToPreviousSpanDegrees
            }
            
            // add the wrap as the last segment if the sweep angle is defined:
            if (clockwiseWrapAngleRelativeToPreviousSpanDegrees > 0) {
                segments = [
                    ...segments,
                    {
                        center,
                        innerRadiusFeet: 0,
                        outerRadiusFeet: 0,
                        startBearing: preceedingSegment.startBearing + preceedingSegment.sweepAngle,
                        sweepAngle: clockwiseWrapAngleRelativeToPreviousSpanDegrees,
                        type: 'clockwiseWrap',
                        wrapNumber
                    }
                ]
            }
            
        }
    }

    // extend the segments to the outside span line:
    const span = system.FlangedSide.Span[spanTowerIndex];
    const tower = system.FlangedSide.Tower[spanTowerIndex];
    const spanLength = Number.isFinite(spanf.LengthInFeet(system.FlangedSide, span))
        ? spanf.LengthInFeet(system.FlangedSide, span)
        : 0;
    const verticies: ISpanVertex[] = [];

    // TODO: make logical when re-factoring
    // NOTE: End boom as last span has not really been accounted for here, as
    // the tower for the end boom does not exist, the tower is undefined.
    // The span.EndBoom check catches this


    const hasHandle = (segments.length) && (
        (
            system.Circle.CenterPivot.isPartialPivot && !span.EndBoom && tower.WrapAroundSpan !== WrapAroundSpanTypes.None
        ) || span.Disconnecting || spanTowerIndex === system.FlangedSide.Tower.length - 1
    );
    
    // end vertex
    if (hasHandle) {
        if (endVertex.type === 'dropSpanEnd' || endVertex.type === 'dropSpanStart') {
            const handle = destination(
                segments[segments.length - 1].center,
                segments[segments.length - 1].outerRadiusFeet + 0.5 * spanLength,
                endVertex.bearing,
                { units: 'feet' }
            ).geometry;
            verticies.push({
                type: endVertex.type,
                spanIndex: endVertex.spanIndex,
                handle,
                segments: endVertex.segments
            })
        }
        else if (system.Circle.CenterPivot.isPartialPivot) {
            const handle = destination(
                segments[segments.length - 1].center,
                segments[segments.length - 1].outerRadiusFeet + spanLength,
                endVertex.bearing,
                { units: 'feet' }
            ).geometry;
            verticies.push({
                type: endVertex.type,
                spanIndex: endVertex.spanIndex,
                center: endVertex.center,
                handle
            })
        }
    }

    // start vertex:
    if (hasHandle) {
        
        if (startVertex.type === 'dropSpanEnd' || startVertex.type === 'dropSpanStart') {
            const handle = destination(
                segments[0].center,
                segments[0].outerRadiusFeet + 0.5 * spanLength,
                startVertex.bearing,
                { units: 'feet' }
            ).geometry;
            verticies.push({
                type: startVertex.type,
                spanIndex: startVertex.spanIndex,
                handle,
                segments: startVertex.segments
            })
        }
        else if (system.Circle.CenterPivot.isPartialPivot) {
            const handle = destination(
                segments[0].center,
                segments[0].outerRadiusFeet + spanLength,
                startVertex.bearing,
                { units: 'feet' }
            ).geometry;
            verticies.push({
                type: startVertex.type,
                spanIndex: startVertex.spanIndex,
                center: startVertex.center,
                handle
            })
        }
    }

    return {
        segments: segments.map(x => ({
            ...x,
            innerRadiusFeet: x.outerRadiusFeet,
            outerRadiusFeet: x.outerRadiusFeet + spanLength,
            startBearing: (x.startBearing + 360) % 360
        })),
        verticies: verticies
    }
}


// export const calculateSpanSegmentsAll = (system: ISystem): {
//     segments: ISpanSegment[];
//     verticies: ISpanVertex[];
// }[] => {
//     const pivotBearingFrom = system.centerPivot!.clockwiseCompassHeadingStart || 0;
//     const pivotBearingTo = system.centerPivot!.clockwiseCompassHeadingEnd || 360;
//     let sweepAngle = (pivotBearingTo - pivotBearingFrom + 360) % 360;
//     if (sweepAngle === 0) {
//         sweepAngle = 360;
//     }

//     const result: {
//         segments: ISpanSegment[];
//         verticies: ISpanVertex[];
//     }[] = [];

//     // seed segments:
//     let segments: ISpanSegment[] = [
//         {
//             center: system.centerPivot!.point,
//             innerRadiusFeet: 0,
//             outerRadiusFeet: 0,
//             startBearing: pivotBearingFrom,
//             sweepAngle: sweepAngle,
//             type: 'center'
//         }
//     ]

//     let endVertex: ISpanVertexForCalc = {
//         type: 'clockwiseCompassHeadingEnd',
//         center: system.centerPivot!.point,
//         spanIndex: -1,
//         radiusFeet: 0,
//         bearing: pivotBearingTo
//     }
//     let startVertex: ISpanVertexForCalc = {
//         type: 'clockwiseCompassHeadingStart',
//         center: system.centerPivot!.point,
//         spanIndex: -1,
//         radiusFeet: 0,
//         bearing: pivotBearingFrom
//     }
    
//     // do end 
//     {
//         // extend the segments to the outside span line:
//         const span = system.FlangedSide.Span[0];
//         const tower = system.FlangedSide.Tower[0];
//         const spanLength = span.Length || 0;
//         const verticies: ISpanVertex[] = [];
    
//         // TODO: make logical when re-factoring
//         // NOTE: End boom as last span has not really been accounted for here, as
//         // the tower for the end boom does not exist, the tower is undefined.
//         // The span.EndBoom check catches this
    
    
//         const hasHandle = (segments.length) && (
//             (
//                 !span.EndBoom && tower.WrapAroundSpan !== WrapAroundSpanTypes.None
//             ) || span.Disconnecting
//         );
        
//         // end vertex
//         if (hasHandle) {
//             const handle = destination(
//                 segments[segments.length - 1].center,
//                 segments[segments.length - 1].outerRadiusFeet + spanLength,
//                 endVertex.bearing,
//                 { units: 'feet' }
//             ).geometry;
//             verticies.push({
//                 type: endVertex.type,
//                 spanIndex: endVertex.spanIndex,
//                 center: endVertex.center,
//                 handle
//             })
//         }
    
//         // start vertex:
//         if (hasHandle) {
//             const handle = destination(
//                 segments[0].center,
//                 segments[0].outerRadiusFeet + spanLength,
//                 startVertex.bearing,
//                 { units: 'feet' }
//             ).geometry;
//             verticies.push({
//                 type: startVertex.type,
//                 spanIndex: startVertex.spanIndex,
//                 center: startVertex.center,
//                 handle
//             })
//         }
    
//         result.push({
//             segments: segments.map(x => ({
//                 ...x,
//                 innerRadiusFeet: x.outerRadiusFeet,
//                 outerRadiusFeet: x.outerRadiusFeet + spanLength,
//                 startBearing: (x.startBearing + 360) % 360
//             })),
//             verticies: verticies
//         })
//     }

//     // consider all span towers up to but not including the request span.
//     // We do not want the drop or wrap information for the current span, as these
//     // become effective for the following spans
//     for (let i = 0; i < system.FlangedSide.Tower.length - 1; i++) {
//         const span = system.FlangedSide.Span[i];
//         const tower = system.FlangedSide.Tower[i];
//         const spanLength = span.Length || 0;
//         if (endVertex.type !== 'dropSpanEnd') {
//             endVertex.radiusFeet += spanLength;
//         }
//         if (startVertex.type !== 'dropSpanStart') {
//             startVertex.radiusFeet += spanLength;
//         }

//         // increase radius of each segment
//         for (const segment of segments) {
//             segment.innerRadiusFeet = segment.outerRadiusFeet;
//             segment.outerRadiusFeet += spanLength;
//         }

//         // if this span tower has a drop span:
//         if (span.Disconnecting) {
//             let dropSpanStartRelativeToPreviousSpanStart = span.dropSpanStartRelativeToPreviousSpanStart || 0;
//             let dropSpanEndRelativeToPreviousSpanEnd = span.dropSpanEndRelativeToPreviousSpanEnd || 0;

//             const segmentsClone = structuredClone(segments).map(x => ({
//                 ...x,
//                 startBearing: (x.startBearing + 360) % 360
//             }));

//             // remove any anticlockwise wraps before the start:
//             if (dropSpanStartRelativeToPreviousSpanStart > 0) {
//                 while (segments.length && segments[0].sweepAngle < dropSpanStartRelativeToPreviousSpanStart) {
//                     dropSpanStartRelativeToPreviousSpanStart -= segments[0].sweepAngle;
//                     segments.splice(0, 1);
//                 }
//                 if (segments.length) {
//                     const segment = segments[0];
//                     segment.startBearing += dropSpanStartRelativeToPreviousSpanStart;
//                     segment.sweepAngle -= dropSpanStartRelativeToPreviousSpanStart;
//                 }
//             }
//             else {
//                 // we will ignore negative drops:
//             }

//             if (segments.length) {
//                 // start drop vertex
//                 const segment = segments[0];

//                 startVertex = {
//                     type: 'dropSpanStart',
//                     spanIndex: i,
//                     segments: segmentsClone,
                    
//                     radiusFeet: segment.innerRadiusFeet,
//                     center: segment.center,
//                     bearing: segment.startBearing
//                 }
//             }
            
//             // remove any clockwise wraps after the end:
//             if (dropSpanEndRelativeToPreviousSpanEnd > 0) {
//                 while (segments.length && segments[segments.length - 1].sweepAngle < dropSpanEndRelativeToPreviousSpanEnd) {
//                     dropSpanEndRelativeToPreviousSpanEnd -= segments[segments.length - 1].sweepAngle;
//                     segments.splice(segments.length - 1);
//                 }
//                 if (segments.length) {
//                     const segment = segments[segments.length - 1];
//                     segment.sweepAngle -= dropSpanEndRelativeToPreviousSpanEnd;
//                 }
//             }
//             else {
//                 // we will ignore negative drops:
//             }
            
//             if (segments.length) {
//                 // start drop vertex
//                 const segment = segments[segments.length - 1];

//                 endVertex = {
//                     type: 'dropSpanEnd',
//                     spanIndex: i,
//                     segments: segmentsClone,
                    
//                     radiusFeet: segment.innerRadiusFeet,
//                     center: segment.center,
//                     bearing: segment.startBearing + segment.sweepAngle
//                 }
//             }

//             segments.forEach(x => x.hasAddedDropSpan = true);
//         }
        
//         const hasWrap = !span.EndBoom && tower.WrapAroundSpan !== WrapAroundSpanTypes.None && segments.length;
//         // if this span tower has a acw wrap span:
//         if (hasWrap) {
//             const anticlockwiseWrapAngleRelativeToPreviousSpanDegrees = tower.anticlockwiseWrapAngleRelativeToPreviousSpanDegrees || 0
//             const preceedingSegment = segments[0];
//             const center = destination(
//                 preceedingSegment.center,
//                 preceedingSegment.outerRadiusFeet,
//                 preceedingSegment.startBearing,
//                 { units: 'feet' }
//             ).geometry;
//             startVertex = {
//                 type: 'anticlockwiseWrapSpan',
//                 spanIndex: i,
//                 radiusFeet: 0,
//                 center,
//                 bearing: preceedingSegment.startBearing - anticlockwiseWrapAngleRelativeToPreviousSpanDegrees
//             }

//             // add the wrap as the first segment if the sweep angle is defined:
//             if (anticlockwiseWrapAngleRelativeToPreviousSpanDegrees > 0) {
//                 segments = [
//                     {
//                         center,
//                         innerRadiusFeet: 0,
//                         outerRadiusFeet: 0,
//                         startBearing: preceedingSegment.startBearing - anticlockwiseWrapAngleRelativeToPreviousSpanDegrees,
//                         sweepAngle: anticlockwiseWrapAngleRelativeToPreviousSpanDegrees,
//                         type: 'anticlockwiseWrap'
//                     },
//                     ...segments
//                 ]
//             }
//         }
        
//         // if this span tower has a cw wrap span:
//         if (hasWrap) {
//             const clockwiseWrapAngleRelativeToPreviousSpanDegrees = tower.clockwiseWrapAngleRelativeToPreviousSpanDegrees || 0;
//             const preceedingSegment = segments[segments.length - 1];
//             const center = destination(
//                 preceedingSegment.center,
//                 preceedingSegment.outerRadiusFeet,
//                 preceedingSegment.startBearing + preceedingSegment.sweepAngle,
//                 { units: 'feet' }
//             ).geometry;
//             endVertex = {
//                 type: 'clockwiseWrapSpan',
//                 spanIndex: i,
//                 radiusFeet: 0,
//                 center,
//                 bearing: preceedingSegment.startBearing + preceedingSegment.sweepAngle + clockwiseWrapAngleRelativeToPreviousSpanDegrees
//             }
            
//             // add the wrap as the last segment if the sweep angle is defined:
//             if (clockwiseWrapAngleRelativeToPreviousSpanDegrees > 0) {
//                 segments = [
//                     ...segments,
//                     {
//                         center,
//                         innerRadiusFeet: 0,
//                         outerRadiusFeet: 0,
//                         startBearing: preceedingSegment.startBearing + preceedingSegment.sweepAngle,
//                         sweepAngle: clockwiseWrapAngleRelativeToPreviousSpanDegrees,
//                         type: 'clockwiseWrap'
//                     }
//                 ]
//             }
            
//         }

//         // do end 
//         {
//             // extend the segments to the outside span line:
//             const span = system.FlangedSide.Span[i+1];
//             const tower = system.FlangedSide.Tower[i+1];
//             const spanLength = span.Length || 0;
//             const verticies: ISpanVertex[] = [];
        
//             // TODO: make logical when re-factoring
//             // NOTE: End boom as last span has not really been accounted for here, as
//             // the tower for the end boom does not exist, the tower is undefined.
//             // The span.EndBoom check catches this
        
        
//             const hasHandle = (segments.length) && (
//                 (
//                     !span.EndBoom && tower.WrapAroundSpan !== WrapAroundSpanTypes.None
//                 ) || span.Disconnecting || i+1 === system.FlangedSide.Tower.length - 1
//             );
            
//             // end vertex
//             if (hasHandle) {
//                 if (endVertex.type === 'dropSpanEnd' || endVertex.type === 'dropSpanStart') {
//                     const handle = destination(
//                         segments[segments.length - 1].center,
//                         segments[segments.length - 1].outerRadiusFeet + 0.5 * spanLength,
//                         endVertex.bearing,
//                         { units: 'feet' }
//                     ).geometry;
//                     verticies.push({
//                         type: endVertex.type,
//                         spanIndex: endVertex.spanIndex,
//                         handle,
//                         segments: endVertex.segments
//                     })
//                 }
//                 else {
//                     const handle = destination(
//                         segments[segments.length - 1].center,
//                         segments[segments.length - 1].outerRadiusFeet + spanLength,
//                         endVertex.bearing,
//                         { units: 'feet' }
//                     ).geometry;
//                     verticies.push({
//                         type: endVertex.type,
//                         spanIndex: endVertex.spanIndex,
//                         center: endVertex.center,
//                         handle
//                     })
//                 }
//             }
        
//             // start vertex:
//             if (hasHandle) {
                
//                 if (startVertex.type === 'dropSpanEnd' || startVertex.type === 'dropSpanStart') {
//                     const handle = destination(
//                         segments[0].center,
//                         segments[0].outerRadiusFeet + 0.5 * spanLength,
//                         startVertex.bearing,
//                         { units: 'feet' }
//                     ).geometry;
//                     verticies.push({
//                         type: startVertex.type,
//                         spanIndex: startVertex.spanIndex,
//                         handle,
//                         segments: startVertex.segments
//                     })
//                 }
//                 else {
//                     const handle = destination(
//                         segments[0].center,
//                         segments[0].outerRadiusFeet + spanLength,
//                         startVertex.bearing,
//                         { units: 'feet' }
//                     ).geometry;
//                     verticies.push({
//                         type: startVertex.type,
//                         spanIndex: startVertex.spanIndex,
//                         center: startVertex.center,
//                         handle
//                     })
//                 }
//             }
        
//             result.push({
//                 segments: segments.map(x => ({
//                     ...x,
//                     innerRadiusFeet: x.outerRadiusFeet,
//                     outerRadiusFeet: x.outerRadiusFeet + spanLength,
//                     startBearing: (x.startBearing + 360) % 360
//                 })),
//                 verticies: verticies
//             })
//         }

//     }
    
//     return result;
// }




// const centerBearingToDropAngle = (system: ISystem, spanTowerIndex: number, bearing: number) => {
//     const spanSegments = calculateSpanSegments(system, spanTowerIndex);
//     let bearingCalc = 0;
//     let iSegment = 0;
//     do {

//     } while()

// }


// const systemCoordinateToWrapAngles = (systemCoordinate: number, system: ISystem): number[] => {
// }

// const wrapAnglesToSystemCoordinate = (wrapAngle: number[]): number => {
//     return 0;
// }