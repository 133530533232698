import { BoomBackTypes, IBoomBack as BoomBacks } from "rdptypes/project/ISprinklers";
import {
    BaseInletSizes,
    CartTypes,
    CenterPivotTypes,
    CollectorRingTypes,
    GuidanceLocations,
    GuidanceTypes,
    HoseFeedTypes,
    ISystemBase,
    PivotingLateralPivotCenterTypes,
    ISpanProperties as SpanProperties,
    SpanTypes,
    SystemTypes,
    TowTypes,
    TowerHeights,
    WaterFeedTypes,
    WrapAroundSpanTypes
} from 'rdptypes/project/ISystemBase.AutoGenerated';
import schema from "../data/partsPackages/2024060101/poSchema";
import CSection2, { SectionIDs } from "./CSection2";
import { MaxAmpsFor10GWire, MaximumSpans } from "./CommonConstants";
import { DistanceStringWithUnits, SpacingText2, SpanLengthsToFeet, SpanTypeText } from "./CommonFunctions";
import * as cpf from "./ControlPanelFunctions";
import { ISpanAutoGaugeResult } from './ElectricalCalculator';
import { EndOfSystemClass } from "./EndOfSystemClass";
import { RaiseError, errOther } from "./Errors";
import * as ohf from "./OtherHelpers";
import PartsAssembler, { LocationCodes } from "./PartsAssembler";
import { WarningLevels } from "./QuoteClass";
import * as sidef from "./SideFunctions";
import * as spanf from "./SpanFunctions";
import { CableTypes, SpanPipeTypes, idxCableConductors } from "./SpanFunctions";
import * as sysf from "./SystemFunctions";
import { TowersClass } from "./TowersClass";
import { strLanguageText } from "./TranslationsLib";
import { Side, Span } from "./Types";
import { LargeTrussRodsOK, OutletSpacingOK, SpanExtOK, SpanStabilizersOK, SpanTypeOK, ValidateConductorCount } from "./Valids.Valids";
import { ExtraConductorCountValidationFlag } from './Valids.dto.Valids';
import { StringFormat } from "./VbCompat";
export class SpansClass extends CSection2 {
    public Side: Side;
    public TowersClass: TowersClass;
    public EOSClass: EndOfSystemClass;
    public OtherSideSpansClass: SpansClass;
    private bFlangedSide: boolean;
    private bFlexSide: boolean;
    private eGuidanceLocation: GuidanceLocations;


    public PerformStainlessSteelAnalysis = (): { bSSRequired: boolean, bSSAvailable: boolean, bSSIndeterminate: boolean } => {
        let iPrevSpanType: SpanTypes | undefined = undefined;

        let bSSRequired = false;
        let bSSAvailable = false;
        let bSSIndeterminate = false;

        for (const span of this.Side.Span) {
            if (span.EndBoom || span.SwingArm) continue;
            if (iPrevSpanType) {
                if ([SpanTypes.E2065, SpanTypes.E2060, SpanTypes.E2665, SpanTypes.E2660].indexOf(iPrevSpanType) !== -1
                    && [SpanTypes.E2065, SpanTypes.E2060, SpanTypes.E2665, SpanTypes.E2660].indexOf(span.SpanType) !== -1) {
                    bSSAvailable = true;
                } else if ([SpanTypes.S2085G, SpanTypes.PL2085G, SpanTypes.S2065G, SpanTypes.PL2065G].indexOf(iPrevSpanType) !== -1) {
                    if ([SpanTypes.S2085G, SpanTypes.PL2085G, SpanTypes.S2065G, SpanTypes.PL2065G].indexOf(span.SpanType) !== -1) {
                        bSSRequired = true;
                    } else {
                        bSSIndeterminate = true;
                    }
                }
            }
            iPrevSpanType = span.SpanType;
        }

        if (bSSIndeterminate) bSSRequired = false;
        return {
            bSSRequired,
            bSSAvailable,
            bSSIndeterminate
        };
    }

public Any114 = (excludeEOS = false): boolean => {
    if (excludeEOS) {
        return sidef.Spans(this.Quote.System, this.Side).ToList().some((s) => s.Spacing === 114 && !(s.EndBoom) && !(s.SwingArm));
    }
    else {
        return sidef.Spans(this.Quote.System, this.Side).ToList().some((s) => s.Spacing === 114);
    }
}

public Any57 = (): boolean => {
    return sidef.Spans(this.Quote.System, this.Side).Any57();
}
public AnyStainless = (): boolean => {
        return sidef.Spans(this.Quote.System, this.Side).AnyStainless();
    }
public AnyAluminum = (): boolean => {
    return sidef.Spans(this.Quote.System, this.Side).AnyAluminum();
}
public AnyAlum6 = (): boolean => {
    return sidef.Spans(this.Quote.System, this.Side).AnyAlum6();
}
public AnySteel = (): boolean => {
    return sidef.Spans(this.Quote.System, this.Side).AnySteel();
}
public AnyPoly = (start: number = 0, end: number = 0): boolean => {
    if (end === 0) {
        end = sidef.NumberOfSpans(this.Side);
    }
    if (start < 1) {
        start = 1;
    }
    for (let i: number = start; i <= end; i++
    ) {
        if (spanf.PipeType(this.Quote.System, this.Side, this.Side.Span[i - 1]) === SpanPipeTypes.Poly) {
            return true;
        }
    }
    return false;
}
public AnyDisconnecting = (start: number = 0, end: number = 0): boolean => {
if (end === 0) {
end = sidef.NumberOfSpans(this.Side);
}
if (start < 1) {
start = 1;
}
return sidef.Spans(this.Quote.System, this.Side).ToList().filter((w) => w.SpanNumber >= start && w.SpanNumber <= end).some((s) => s.Disconnecting);
}

public Any2045 = (iStart: number = 0, iEnd: number = 0): boolean => {
    if (iEnd === 0) {
        iEnd = sidef.NumberOfSpans(this.Side);
    }
    if (iStart < 1) {
        iStart = 1;
    }
    for (let i: number = iStart; i <= iEnd; i++) {
        if (this.Side.Span[i - 1].SpanType === SpanTypes.E2045) {
            return true;
        }
    }
    return false;
}


public GetPipeSpacingText = (): string => {
    let spanTextList: string[] = [];
    let LastSpanNumber: number = sidef.NumberOfSpans(this.Side);
    for (let i: number = 1; i <= LastSpanNumber; i++) {
        let sp: Span = this.Side.Span[i - 1];
        if ([SpanTypes.E2100, SpanTypes.E2085, SpanTypes.A80G, SpanTypes.S2085G, SpanTypes.PL2085G].indexOf(sp.SpanType) !== -1) {
            continue;
        }
        if (sp.EndBoom) {
            continue;
        }

        let str = SpacingText2(sp.Spacing);
        if (spanTextList.indexOf(str) === -1) {
            spanTextList.push(str);
        }
    }
    return spanTextList.join("/");
}

public GetPipeModel = (model: string) => {
    let types: string[] = [];
    let LastSpanNumber: number = sidef.NumberOfSpans(this.Side);
    for (let i = 0; i < LastSpanNumber; i++) {
        let sp: Span = this.Side.Span[i];
        if (typeof sp.SpanType !== "undefined"){
            let s = SpanTypeText(sp.SpanType);
            if (types.indexOf(s) === -1 && model.indexOf(s) === -1 && typeof s !== "undefined") {
                types.push(s);
            }
        }
    }
    return types.join("/");
}

private static SpanLengthTypeMismatch = (sl: number, st: SpanTypes): string => {
        return StringFormat(strLanguageText("sltASpanIsNotValidWithAn"), DistanceStringWithUnits(SpanLengthsToFeet(sl)), SpanTypeText(st));
    }
    public DataOK = (PA: PartsAssembler, WithWarnings: boolean = false, DependencyCheckInProgress: boolean = false): void => {
        if (!this.FieldSet.DataValid()) return;
        /*if (FieldSet.Value === FieldSetStates.Invalid) {
        return true;
        }
        if (!(FieldSet.ConfigurationValid())) {
        this.Quote.Warnings.Add(this.TranslatedKey);
        return false;
        }*/
        let bAlum6Exists: boolean = this.AnyAlum6();
        if (!(bAlum6Exists)) {
            if (this.OtherSideSpansClass?.FieldSet.DataValid()) {
                bAlum6Exists = this.OtherSideSpansClass.AnyAlum6();
            }
        }
        let eSystemType: SystemTypes;
        let bReverseTow: boolean;
        let eCenterPivotType: CenterPivotTypes;
        let eHoseFeedType: HoseFeedTypes;
        let HasCenterLift: boolean;
        let ePivotingLateralPivotCenterType: PivotingLateralPivotCenterTypes;
        let eHoseFeedBaseInlets: BaseInletSizes;
        let bOppositeSideStainless: boolean;
        let bSingleSpanMaxi: boolean;
        let eWaterFeed: WaterFeedTypes;
        let bDEF: boolean;
        let bHoseFeedEndFeed: boolean;
        let bIsMaxi: boolean;
        let bPainted: boolean;
        let bGalvanized: boolean;
        let LastSpanNumber: number = sidef.NumberOfSpans(this.Side);
        const IsTowable = this.Quote.IsTowable();
        const IsEDMP = sysf.IsEDMP(this.Quote.System);
        const RigidSpan = this.Quote.RigidSpan();
        const Is230Volt = sysf.Is230V(this.Quote.System);
        const IsA100 = this.Quote.IsA100_Only();
        let bPivotingLateral: boolean;
        let bFurrowGuidance: boolean;
        let sys: ISystemBase = this.Quote.System;
        eSystemType = sys.SystemProperties.SystemType;
        bReverseTow = sysf.IsReverseTow(this.Quote.System);
        bIsMaxi = sysf.IsMaxigator(this.Quote.System);
        switch (eSystemType) {
            case SystemTypes.CenterPivot:
                eCenterPivotType = sys.Circle.CenterPivot.CenterPivotType;
                break;
            case SystemTypes.HoseFeedMaxigator:
                const l = sys.Lateral;
                eWaterFeed = l.WaterFeed;
                this.eGuidanceLocation = l.Guidance.Location;
                bFurrowGuidance = l.Guidance.GuidanceType === GuidanceTypes.Furrow;
                if (eWaterFeed === WaterFeedTypes.EndFeed) {
                    bHoseFeedEndFeed = true;
                }
                const hf = l.HoseFeed;
                HasCenterLift = hf.CenterLift;
                eHoseFeedType = hf.HoseFeedType;
                ePivotingLateralPivotCenterType = hf.PivotingLateralPivotCenterType;
                eHoseFeedBaseInlets = hf.BaseInletSize;
                switch (eHoseFeedType) {
                    case HoseFeedTypes.DoubleEndFeed:
                        bDEF = true;
                        break;
                    case HoseFeedTypes.PivotingLateral:
                        bPivotingLateral = true;
                        break;
                }
                if (hf.SingleSpanMaxi) {
                    bSingleSpanMaxi = true;
                }
                if (this.bFlexSide && sys.FlangedSide.Span.length) {
                    switch (sys.FlangedSide.Span[1 - 1].SpanType) {
                        case SpanTypes.S2065G:
                        case SpanTypes.PL2065G:
                        case SpanTypes.S2085G:
                        case SpanTypes.PL2085G:
                            bOppositeSideStainless = true;
                            break;
                    }
                }
                break;
            case SystemTypes.CanalFeedMaxigator:
                if (this.bFlexSide && sys.FlangedSide.Span.length) {
                    switch (sys.FlangedSide.Span[1 - 1].SpanType) {
                        case SpanTypes.S2065G:
                        case SpanTypes.PL2065G:
                        case SpanTypes.S2085G:
                        case SpanTypes.PL2085G:
                            bOppositeSideStainless = true;
                            break;
                    }
                }
                break;
        }
        if (bPivotingLateral && bFurrowGuidance) {
            if (sidef.Spans(this.Quote.System, this.Side).Count > 1) {
                if (spanf.EndingLocation(this.Quote.System, this.Side, this.Side.Span[LastSpanNumber - 1]) > 600) {
                    const options = this.Quote.System.Lateral.Guidance.FurrowOptions;
                    if (options.LongSystem === false) {
                        this.Quote.Warnings.Add(strLanguageText("sltLongPLNoFurrowWheel"), WarningLevels.Informational);
                    }
                }
                else {
                    const options = this.Quote.System.Lateral.Guidance.FurrowOptions;
                    if (options.LongSystem === true) {
                        this.Quote.Warnings.Add(strLanguageText("sltShortPLFurrowWheel"), WarningLevels.Informational);
                    }
                }
            }
        }
        if (LastSpanNumber === 1) {
            if (bDEF) {
                this.Quote.Warnings.Add(strLanguageText("sltDoubleEndFeedSystems2Spans"), WarningLevels.SectionBlock);
            }
            if (bHoseFeedEndFeed) {
                if (!(bSingleSpanMaxi)) {
                    this.Quote.Warnings.Add(strLanguageText("sltNonSingle2Spans"), WarningLevels.SectionBlock);
                }
            }
        }
        else if (LastSpanNumber > 1) {
            // UI ensures that the system must have at least one span
            if (IsEDMP) {
                this.Quote.Warnings.Add(strLanguageText("sltOnlyOneSpanIsAllowedWith"));
            }
            if (bSingleSpanMaxi) {
                this.Quote.Warnings.Add(strLanguageText("sltOnlyOneSpanIsAllowedSSM"));
            }
        }
        if (LastSpanNumber > MaximumSpans) {
            this.Quote.Warnings.Add(strLanguageText("sltOnly24SpansAllowed"));
        }
        if (bReverseTow) {
            if (LastSpanNumber > 8) {
                this.Quote.Warnings.Add(strLanguageText("sltOnly8SpansAllowedRTS"));
            }
        }
        if (bAlum6Exists && bIsMaxi) {
            if (!(this.Quote.System.SpanProperties.AlumIVSupportRod)) {
                this.Quote.Warnings.Add(strLanguageText("sltAlumRodsRequired"));
            }
        }
        if (eSystemType === SystemTypes.CenterPivot) {
            if (Is230Volt) {
                if (LastSpanNumber > 5) {
                    this.Quote.Warnings.Add(strLanguageText("sltOnly5Spans230VoltSinglePhase"));
                }
            }
        }
        if (this.bFlexSide) {
            if (this.Quote.RightSpansClass.FieldSet.DataValid()) {
                if (LastSpanNumber + sidef.NumberOfSpans(this.Quote.System.FlangedSide) > 24) {
                    this.Quote.Warnings.Add(strLanguageText("sltOnly24SpansAllowedEntire"));
                }
            }
        }
        if (this.bFlangedSide) {
            if (this.Quote.LeftSpansClass.FieldSet.DataValid()) {
                if (LastSpanNumber + sidef.NumberOfSpans(this.Quote.System.FlexSide) > 24) {
                    this.Quote.Warnings.Add(strLanguageText("sltOnly24SpansAllowedEntire"));
                }
            }
        }
        let sAlumLength: number;
        let sE2045Length: number;
        let bA4onMaxi: boolean;
        let bCNPlus6Exists: boolean;
        let bOutletSpacingMismatch: boolean;
        let bTowerReverseTowError: boolean;
        let bReverseSpanError: boolean;
        let ePrevSpanType: SpanTypes;
        let bTowerReverseTow: boolean;
        let ePrevCableGauge: number;
        let iPrevExtraConductors: number;
        for (let i: number = 1; i <= LastSpanNumber; i++
        ) {
            if (i > 1) {
                let sp: Span = this.Side.Span[i - 1 - 1];
                ePrevSpanType = sp.SpanType;
                ePrevCableGauge = sp.MinCableGauge;
                iPrevExtraConductors = sp.ExtraCableConductors;
                if (this.Side.Span[i - 1].Spacing !== sp.Spacing) {
                    bOutletSpacingMismatch = true;
                }
            }
            let bReversedSpan: boolean = bIsMaxi && this.bFlangedSide && i <= RigidSpan;
            if (bReversedSpan) {
                bTowerReverseTow = IsTowable;
            }
            else {
                bTowerReverseTow = bReverseTow;
            }
            let s: Span = this.Side.Span[i - 1];
            if (!(SpanTypeOK(s.SpanType, {
                SpanNumber: i, SpanCount: LastSpanNumber, SystemType: eSystemType,
                CenterPivotType: eCenterPivotType, HoseFeedType: eHoseFeedType, HoseFeedBaseInlets: eHoseFeedBaseInlets,
                IsSingleSpanMaxi: bSingleSpanMaxi, IsEDMP: IsEDMP, IsReverseTow: bTowerReverseTow, Is230Volt: Is230Volt,
                IsFlexSide: this.bFlexSide, PreviousSpanType: ePrevSpanType, IsOppositeSideStainless: bOppositeSideStainless,
                IsReversedSpan: bReversedSpan, PivotingLateralPivotCenterType: ePivotingLateralPivotCenterType
            }))) {
                let side = sidef.FlangedSide(this.Quote.System, this.Side) ? "Flanged" : "Flex";

                if (i !== LastSpanNumber && !((bTowerReverseTow || bReversedSpan))) {
                    this.Quote.Warnings.AddWithTargets(
                        [`${side}Side.Span[${s.SpanNumber - 1}].SpanType`],
                        StringFormat(strLanguageText("sltInvalidSpanType"), i));
                }
                if (i !== LastSpanNumber) {
                    bReverseSpanError = !(SpanTypeOK(s.SpanType, {
                        SpanNumber: i, SpanCount: LastSpanNumber, SystemType: eSystemType,
                        CenterPivotType: eCenterPivotType, HoseFeedType: eHoseFeedType, HoseFeedBaseInlets: eHoseFeedBaseInlets,
                        IsSingleSpanMaxi: bSingleSpanMaxi, IsEDMP: IsEDMP, IsReverseTow: false, Is230Volt: Is230Volt,
                        IsFlexSide: this.bFlexSide, PreviousSpanType: ePrevSpanType, IsOppositeSideStainless: bOppositeSideStainless,
                        IsReversedSpan: bReversedSpan, PivotingLateralPivotCenterType: ePivotingLateralPivotCenterType
                    }));
                    bTowerReverseTowError = !(SpanTypeOK(s.SpanType, {
                        SpanNumber: i, SpanCount: LastSpanNumber, SystemType: eSystemType,
                        CenterPivotType: eCenterPivotType, HoseFeedType: eHoseFeedType, HoseFeedBaseInlets: eHoseFeedBaseInlets,
                        IsSingleSpanMaxi: bSingleSpanMaxi, IsEDMP: IsEDMP, IsReverseTow: bTowerReverseTow, Is230Volt: Is230Volt,
                        IsFlexSide: this.bFlexSide, PreviousSpanType: ePrevSpanType, IsOppositeSideStainless: bOppositeSideStainless,
                        IsReversedSpan: false, PivotingLateralPivotCenterType: ePivotingLateralPivotCenterType
                    }));
                    if (bTowerReverseTowError) {
                        if (bReverseSpanError) {
                            this.Quote.Warnings.AddWithTargets(
                                [`${side}Side.Span[${s.SpanNumber - 1}].SpanType`],
                                StringFormat(strLanguageText("sltInvalidSpanType"), i));
                        }
                        else {
                            this.Quote.Warnings.AddWithTargets(
                                [`${side}Side.Span[${s.SpanNumber - 1}].SpanType`],
                                StringFormat(strLanguageText("sltReverseTowAlum"), i - 1, i));
                        }
                    }
                    if (bReverseSpanError) {
                        if (bTowerReverseTowError) {
                            this.Quote.Warnings.AddWithTargets(
                                [`${side}Side.Span[${s.SpanNumber - 1}].SpanType`],
                                StringFormat(strLanguageText("sltInvalidSpanType"), i));
                        }
                        else {
                            this.Quote.Warnings.AddWithTargets(
                                [`${side}Side.Span[${s.SpanNumber - 1}].SpanType`],
                                StringFormat(strLanguageText("sltE2045CannotBeReversed"), i - 1, i));
                        }
                    }
                }
                else {
                    if (!(SpanTypeOK(s.SpanType, {
                        SpanNumber: i, SpanCount: LastSpanNumber + 1, SystemType: eSystemType,
                        CenterPivotType: eCenterPivotType, HoseFeedType: eHoseFeedType, HoseFeedBaseInlets: eHoseFeedBaseInlets,
                        IsSingleSpanMaxi: bSingleSpanMaxi, IsEDMP: IsEDMP, IsReverseTow: bTowerReverseTow, Is230Volt: Is230Volt,
                        IsFlexSide: this.bFlexSide, PreviousSpanType: ePrevSpanType, IsOppositeSideStainless: bOppositeSideStainless,
                        IsReversedSpan: bReversedSpan, PivotingLateralPivotCenterType: ePivotingLateralPivotCenterType
                    }))) {
                        this.Quote.Warnings.AddWithTargets(
                            [`${side}Side.Span[${s.SpanNumber - 1}].SpanType`],
                            StringFormat(strLanguageText("sltInvalidSpanType"), i));
                    }
                    else {
                        this.Quote.Warnings.AddWithTargets(
                            [],
                            StringFormat(strLanguageText("sltAnE1060SpanNotLast"), SpanTypeText(s.SpanType)));
                    }
                }
            }
            switch (s.SpanType) {
                case SpanTypes.AlumIV:
                    sAlumLength += s.Length;
                    if (bIsMaxi) {
                        bA4onMaxi = true;
                    }
                    break;
                case SpanTypes.A60G:
                    sAlumLength += s.Length;
                    break;
                case SpanTypes.E2045:
                    sE2045Length += s.Length;
                    break;
                case SpanTypes.E2660:
                    bPainted = true;
                    if (this.Quote.System.SpanProperties.GalvanizedCNPlus) {
                        bCNPlus6Exists = true;
                        bGalvanized = true;
                    }
                    break;
                case SpanTypes.E2665:
                    bPainted = true;
                    if (this.Quote.System.SpanProperties.GalvanizedCNPlus) {
                        bGalvanized = true;
                    }
                    break;
            }
            switch (s.Length) {
                case 80:
                case 99:
                    switch (s.SpanType) {
                        case SpanTypes.PL2085G:
                        case SpanTypes.E2045:
                            this.Quote.Warnings.Add(SpansClass.SpanLengthTypeMismatch(s.Length, s.SpanType), WarningLevels.Critical, false, "sltASpanIsNotValidWithAn");
                            break;
                    }
                    switch (eSystemType) {
                        case SystemTypes.KwikTow:
                            this.Quote.Warnings.Add(StringFormat(strLanguageText("sltASpanCannotKT"), DistanceStringWithUnits(SpanLengthsToFeet(s.Length))), WarningLevels.Informational, true);
                            break;
                    }
                    break;
                case 118:
                    break;
                case 120:
                    switch (s.SpanType) {
                        case SpanTypes.E2100:
                            break;
                        case undefined:
                            // No error if span type not selected yet
                            break;
                        default:
                            this.Quote.Warnings.Add(SpansClass.SpanLengthTypeMismatch(s.Length, s.SpanType), WarningLevels.Critical, false, "sltASpanIsNotValidWithAn");
                            break;
                    }
                    break;
                case 137:
                    break;
                case 140:
                    switch (s.SpanType) {
                        case SpanTypes.A60G:
                        case SpanTypes.AlumIV:
                        case SpanTypes.S2085G:
                        case SpanTypes.S2065G:
                        case SpanTypes.E2065:
                        case SpanTypes.E2060:
                        case SpanTypes.E2085:
                            break;
                        case undefined:
                            // No error if span type not selected yet
                            break;
                        default:
                            this.Quote.Warnings.Add(SpansClass.SpanLengthTypeMismatch(s.Length, s.SpanType), WarningLevels.Critical, false, "sltASpanIsNotValidWithAn");
                            break;
                    }
                    break;
                case 156:
                    switch (s.SpanType) {
                        case SpanTypes.E2100:
                            this.Quote.Warnings.Add(SpansClass.SpanLengthTypeMismatch(s.Length, s.SpanType)), WarningLevels.Critical, false, "sltASpanIsNotValidWithAn";
                            break;
                    }
                    break;
                case 160:
                    if (i === 1) {
                        switch (s.SpanType) {
                            case SpanTypes.A80G:
                                switch (eSystemType) {
                                    case SystemTypes.KwikTow:
                                        this.Quote.Warnings.Add(StringFormat(strLanguageText("sltASpanCannotKT"), DistanceStringWithUnits(SpanLengthsToFeet(s.Length))));
                                        break;
                                }
                                break;
                            case SpanTypes.AlumIV:
                            case SpanTypes.A60G:
                                switch (eSystemType) {
                                    case SystemTypes.KwikTow:
                                        this.Quote.Warnings.Add(StringFormat(strLanguageText("sltASpanCannotKT"), DistanceStringWithUnits(SpanLengthsToFeet(s.Length))));
                                        break;
                                    case SystemTypes.CanalFeedMaxigator:
                                        if (!(this.bFlexSide)) {
                                            this.Quote.Warnings.Add(StringFormat(strLanguageText("sltFirstCFM"), DistanceStringWithUnits(SpanLengthsToFeet(s.Length))));
                                        }
                                        break;
                                }
                                break;
                            case SpanTypes.E2660:
                            case SpanTypes.E2060:
                                switch (eSystemType) {
                                    case SystemTypes.CanalFeedMaxigator:
                                        if (!(this.bFlexSide)) {
                                            switch (s.SpanType) {
                                                case SpanTypes.E2660:
                                                case SpanTypes.E2060:
                                                    this.Quote.Warnings.Add(StringFormat(strLanguageText("sltFirstCFM"), DistanceStringWithUnits(SpanLengthsToFeet(s.Length))));
                                                    break;
                                            }
                                        }
                                        break;
                                }
                                break;
                        }
                    }
                    switch (s.SpanType) {
                        case SpanTypes.E2100:
                        case SpanTypes.E2045:
                            this.Quote.Warnings.Add(SpansClass.SpanLengthTypeMismatch(s.Length, s.SpanType), WarningLevels.Critical, false, "sltASpanIsNotValidWithAn");
                            break;
                    }
                    break;
                case 175:
                    switch (s.SpanType) {
                        case SpanTypes.E2065:
                        case SpanTypes.E2060:
                        case SpanTypes.E2045:
                        case SpanTypes.E2665:
                        case SpanTypes.E2660:
                        case SpanTypes.S2065G:
                        case SpanTypes.PL2065G:
                            break;
                        case undefined:
                            // No error if span type not selected yet
                            break;
                        default:
                            this.Quote.Warnings.Add(SpansClass.SpanLengthTypeMismatch(s.Length, s.SpanType), WarningLevels.Critical, false, "sltASpanIsNotValidWithAn");
                            break;
                    }
                    break;
                case 180:
                    if (i === 1 && !(this.bFlexSide)) {
                        switch (eSystemType) {
                            case SystemTypes.HoseFeedMaxigator:
                                if (!(bSingleSpanMaxi) && eHoseFeedType !== HoseFeedTypes.PivotingLateral) {
                                    this.Quote.Warnings.AddWithTargets(
                                        [`FlangedSide.Span[${0}].Length`],
                                        StringFormat(strLanguageText("sltFirstMaxi"), DistanceStringWithUnits(SpanLengthsToFeet(s.Length))));
                                }
                                break;
                            case SystemTypes.CanalFeedMaxigator:
                                this.Quote.Warnings.AddWithTargets(
                                    [`FlangedSide.Span[${0}].Length`],
                                    StringFormat(strLanguageText("sltFirstMaxi"), DistanceStringWithUnits(SpanLengthsToFeet(s.Length))));
                                break;
                        }
                    }
                    switch (s.SpanType) {
                        case SpanTypes.E2065:
                        case SpanTypes.E2060:
                            break;
                        case undefined:
                            // No error if span type not selected yet
                            break;
                        default:
                            this.Quote.Warnings.Add(SpansClass.SpanLengthTypeMismatch(s.Length, s.SpanType), WarningLevels.Critical, false, "sltASpanIsNotValidWithAn");
                            break;
                    }
                    break;
                case 186.7:
                    if (i === 1 && !(this.bFlexSide)) {
                        switch (eSystemType) {
                            case SystemTypes.HoseFeedMaxigator:
                                if (!(bSingleSpanMaxi) && eHoseFeedType !== HoseFeedTypes.PivotingLateral) {
                                    this.Quote.Warnings.AddWithTargets(
                                        [`FlangedSide.Span[${0}].Length`],
                                        StringFormat(strLanguageText("sltFirstMaxi"), DistanceStringWithUnits(SpanLengthsToFeet(s.Length))));
                                }
                                break;
                            case SystemTypes.CanalFeedMaxigator:
                                this.Quote.Warnings.AddWithTargets(
                                    [`FlangedSide.Span[${0}].Length`],
                                    StringFormat(strLanguageText("sltFirstMaxi"), DistanceStringWithUnits(SpanLengthsToFeet(s.Length))));
                                break;
                        }
                    }
                    switch (s.SpanType) {
                        case SpanTypes.E2065:
                        case SpanTypes.E2060:
                            break;
                        case undefined:
                            // No error if span type not selected yet
                            break;
                        default:
                            this.Quote.Warnings.Add(SpansClass.SpanLengthTypeMismatch(s.Length, s.SpanType), WarningLevels.Critical, false, "sltASpanIsNotValidWithAn");
                            break;
                    }
                    break;
                case 194:
                    if (i === 1 && !(this.bFlexSide)) {
                        switch (eSystemType) {
                            case SystemTypes.HoseFeedMaxigator:
                                if (!(bSingleSpanMaxi) && !(bPivotingLateral)) {
                                    this.Quote.Warnings.AddWithTargets(
                                        [`FlangedSide.Span[${0}].Length`],
                                        StringFormat(strLanguageText("sltFirstMaxi"), DistanceStringWithUnits(SpanLengthsToFeet(s.Length))));
                                }
                                break;
                            case SystemTypes.CanalFeedMaxigator:                                    
                                this.Quote.Warnings.AddWithTargets(
                                    [`FlangedSide.Span[${0}].Length`],
                                    StringFormat(strLanguageText("sltFirstMaxi"), DistanceStringWithUnits(SpanLengthsToFeet(s.Length))));
                                break;
                        }
                    }
                    switch (s.SpanType) {
                        case SpanTypes.E2065:
                        case SpanTypes.E2060:
                        case SpanTypes.E2665:
                        case SpanTypes.E2660:
                        case SpanTypes.PL2065G:
                        case SpanTypes.E2045:
                            break;
                        case undefined:
                            // No error if span type not selected yet
                            break;
                        default:
                            this.Quote.Warnings.Add(SpansClass.SpanLengthTypeMismatch(s.Length, s.SpanType), WarningLevels.Critical, false, "sltASpanIsNotValidWithAn");
                            break;
                    }
                    break;
                case 204:
                    if (i === 1 && !(this.bFlexSide)) {
                        switch (eSystemType) {
                            case SystemTypes.HoseFeedMaxigator:
                            case SystemTypes.CanalFeedMaxigator:
                                if (!(this.bFlexSide) && !bSingleSpanMaxi) {
                                    this.Quote.Warnings.AddWithTargets(
                                        [`FlangedSide.Span[${0}].Length`],
                                        StringFormat(strLanguageText("sltFirstMaxi"), DistanceStringWithUnits(SpanLengthsToFeet(s.Length))));
                                }
                                break;
                            case SystemTypes.KwikTow:
                                this.Quote.Warnings.Add(StringFormat(strLanguageText("sltASpanCannotKT"), DistanceStringWithUnits(SpanLengthsToFeet(s.Length))));
                                break;
                        }
                    }
                    switch (s.SpanType) {
                        case SpanTypes.E2065:
                        case SpanTypes.E2060:
                            break;
                        case undefined:
                            // No error if span type not selected yet
                            break;
                        default:
                            this.Quote.Warnings.Add(SpansClass.SpanLengthTypeMismatch(s.Length, s.SpanType), WarningLevels.Critical, false, "sltASpanIsNotValidWithAn");
                            break;
                    }
                    break;
                case 213:
                    if (bIsMaxi && i === 1 && !(this.bFlexSide) && !(IsA100 || this.Quote.IsCF200())) {
                        this.Quote.Warnings.AddWithTargets(
                            [`FlangedSide.Span[${0}].Length`],
                            StringFormat(strLanguageText("sltFirstMaxi"), DistanceStringWithUnits(SpanLengthsToFeet(s.Length))));
                    }
                    if (i < LastSpanNumber && (IsTowable || bIsMaxi) && !(this.bFlexSide)) {
                        this.Quote.Warnings.Add(strLanguageText("sltLastMaxi"), WarningLevels.SectionBlock);
                    }
                    let bDeny213Use = false;
                    if (bReverseTow && (bIsMaxi || eSystemType === SystemTypes.KwikTow) && !(IsA100 || this.Quote.IsCF200())) {
                        bDeny213Use = true;
                    }
                    if (bDeny213Use) {
                        this.Quote.Warnings.Add(strLanguageText("sltNotValSpanLen"));
                    }
                    if (i === 1 && eSystemType === SystemTypes.CenterPivot && this.Quote.ControlPanelClass.FieldSet.DataValid() && this.Quote.System.ControlPanel.CollectorRing === CollectorRingTypes.None && ohf.PercentOfCircle(this.Quote.System) === 100) {
                        this.Quote.Warnings.Add(strLanguageText("sltNotValSpanLen"));
                    }
                    switch (s.SpanType) {
                        case SpanTypes.E2060:
                        case SpanTypes.E2660:
                            break;
                        case undefined:
                            // No error if span type not selected yet
                            break;
                        default:
                            this.Quote.Warnings.Add(SpansClass.SpanLengthTypeMismatch(s.Length, s.SpanType), WarningLevels.Critical, false, "sltASpanIsNotValidWithAn");
                            break;
                    }
                    break;
            }
            if (!(SpanStabilizersOK(s.Stabilizers, s.SpanType, s.Length))) {
                this.Quote.Warnings.Add(strLanguageText("sltSpanStabilizers"));
            }
            if (!(LargeTrussRodsOK(s.LargeTrussRods, s.SpanType, s.Length))) {
                this.Quote.Warnings.Add(strLanguageText("sltLargeTrussRods"));
            }
            if (!(OutletSpacingOK(s.Spacing, {
                SpanType: s.SpanType,
                SpanLength: s.Length,
                Spans: sidef.Spans(this.Quote.System, this.Side)
            }))) {
                this.Quote.Warnings.Add(strLanguageText("sltOutletSpacing"));
            }
            if (!(SpanExtOK(s.Extension, {
                SpanType: s.SpanType,
                SpanLength: s.Length,
                IsA100: eHoseFeedType === HoseFeedTypes.A100,
                IsHoseFeed: eSystemType === SystemTypes.HoseFeedMaxigator,
                SpanNumber: i,
            }))) {
                this.Quote.Warnings.AddWithTargets(
                    [`${sidef.FlangedSide(this.Quote.System, this.Side) ? "Flanged" : "Flex"}Side.Span[${0}].Extension`],
                    strLanguageText("slt4footSpanExt"));
            }
            if (s.Disconnecting) {
                if (Is230Volt) {
                    this.Quote.Warnings.Add(strLanguageText("sltSpanDisconnect"));
                }
                if (bDEF) {
                    this.Quote.Warnings.Add(strLanguageText("sltSpanDisconnectNotAllowedDEFS"));
                }
                switch (s.SpanType) {
                    case SpanTypes.E2065:
                    case SpanTypes.E2060:
                    case SpanTypes.E2665:
                    case SpanTypes.E2660:
                        if (i === 2) {
                            if (eSystemType === SystemTypes.KwikTow) {
                                this.Quote.Warnings.Add(strLanguageText("sltSpanDisconnectNotAllowedKTS"));
                            }
                            if (bHoseFeedEndFeed) {
                                this.Quote.Warnings.Add(strLanguageText("sltSpanDisconnectNotAllowedHFEFS"));
                            }
                        }
                        if (i > 1) {
                            switch (ePrevSpanType) {
                                case SpanTypes.E2065:
                                case SpanTypes.E2060:
                                case SpanTypes.E2665:
                                case SpanTypes.E2660:
                                    break;
                                default:
                                    this.Quote.Warnings.Add(strLanguageText("sltSpanDisconnectAllowedE65"));
                                    break;
                            }
                        }
                        else {
                            this.Quote.Warnings.Add(strLanguageText("sltSpanDisconnectNotAllowedFirst"));
                        }
                        break;
                    default:
                        this.Quote.Warnings.Add(strLanguageText("sltSpanDisconnectAllowedAlumIV"));
                        break;
                }
            }
            let iConductorsRequired: number = spanf.ConductorsRequired(sys, this.Side, s);
            switch (s.MinCableGauge) {
                case 12:
                    if (iConductorsRequired > 14) {
                        this.Quote.Warnings.Add(StringFormat(strLanguageText("sltSpanCableProblem"), spanf.ConductorsRequired(sys, this.Side, s), i, this.TranslatedKey), WarningLevels.SectionBlock);
                    }
                    break;
                case 10:
                    if (i > 1) {
                        switch (ePrevCableGauge) {
                            case 12:
                                this.Quote.Warnings.Add(strLanguageText("sltSpanCableGaugeProblem"), WarningLevels.SectionBlock);
                                break;
                        }
                    }
                    if (iConductorsRequired > 14) {
                        this.Quote.Warnings.Add(StringFormat(strLanguageText("sltSpanCableProblem"), spanf.ConductorsRequired(sys, this.Side, s), i, this.TranslatedKey), WarningLevels.SectionBlock);
                    }
                    break;
                case 8:
                    if (10 - sidef.Spans(this.Quote.System, this.Side).FundamentalConductorCount(this.Quote.System) < 0) {
                        this.Quote.Warnings.Add(StringFormat(strLanguageText("sltSpanCableProblem"), spanf.ConductorsRequired(sys, this.Side, s), i, this.TranslatedKey));
                    }
                    if (i > 1) {
                        if (ePrevCableGauge !== 8) {
                            this.Quote.Warnings.Add(strLanguageText("sltSpanCableGaugeProblem"), WarningLevels.SectionBlock);
                        }
                    }
                    if (iConductorsRequired > 10) {
                        this.Quote.Warnings.Add(StringFormat(strLanguageText("sltSpanCableProblem"), spanf.ConductorsRequired(sys, this.Side, s), i, this.TranslatedKey), WarningLevels.SectionBlock);
                    }
                    break;
            }
            let result = ValidateConductorCount(this.Quote.System, s.ExtraCableConductors, {
                SystemType: eSystemType,
                HoseFeedType: eHoseFeedType,
                SpanCableGauge: s.MinCableGauge,
                Side: this.Side,
                CurrentSpanNumber: i,
                PreviousSpanExtraConductors: iPrevExtraConductors
            });
            switch (result.ResultFlag) {
                case ExtraConductorCountValidationFlag.OK:
                    break;
                    break;
                case ExtraConductorCountValidationFlag.NotSupportedHoseFeedA100CF200:
                    this.Quote.Warnings.Add(strLanguageText("sltExtraConductors"));
                    break;
                case ExtraConductorCountValidationFlag.ExtraConductorsExceedsUpperLimit:
                    this.Quote.Warnings.Add(`${strLanguageText("sltDueToASpanCableLimitationTheMaximum")} ${result.ExtraConductorUpperLimit}`);
                    break;
                case ExtraConductorCountValidationFlag.ExtraConductorsExceedsPreviousSpan:
                    this.Quote.Warnings.Add(StringFormat(strLanguageText("sltThePreviousSpanHasExtraConductorsTheCurrent"), iPrevExtraConductors, iPrevExtraConductors));
                    break;
            }
            let iFirstDisc: number = sidef.FirstDisconnectingSpan(this.Side);
            switch (spanf.CableConductors(this.Quote.System, this.Side, s)) {
                case idxCableConductors.cc10:
                    break;
                case idxCableConductors.cc11:
                    if (iFirstDisc > 0 && i >= iFirstDisc) {
                        this.Quote.Warnings.Add(strLanguageText("sltSpanCableSpanDiscProblem"));
                    }
                    break;
                case idxCableConductors.cc14:
                    if (iFirstDisc > 0 && i >= iFirstDisc) {
                        this.Quote.Warnings.Add(strLanguageText("sltSpanCableSpanDiscProblem"));
                    }
                    switch (eSystemType) {
                        case SystemTypes.CenterPivot:
                        case SystemTypes.KwikTow:
                            this.Quote.Warnings.Add(StringFormat(strLanguageText("sltSpanCableProblem"), spanf.ConductorsRequired(this.Quote.System, this.Side, s), i, this.TranslatedKey), WarningLevels.SectionBlock);
                            break;
                    }
                    if (eSystemType === SystemTypes.CenterPivot) {
                        if (this.Quote.ControlPanelClass.FieldSet.DataValid()) {
                            switch (this.Quote.System.ControlPanel.CollectorRing) {
                                case CollectorRingTypes.None:
                                case CollectorRingTypes.a14Ring:
                                    break;
                                default:
                                    this.Quote.Warnings.Add(strLanguageText("sltLargerRingCollectorReelRequired"), WarningLevels.Decision);
                                    break;
                            }
                        }
                    }
                    break;
            }
        }
        let bLongSystemAlignment: boolean = this.Quote.System.SpanProperties.LongSystemAlignment;
        if (IsA100) {
            if (bLongSystemAlignment) {
                this.Quote.Warnings.Add(strLanguageText("sltLongSysAlignment"));
            }
            if (this.Quote.System.SpanProperties.AlumIVSupportRod) {
                this.Quote.Warnings.Add(strLanguageText("sltAlumIVLongitudinal"));
            }
        }
        if (bIsMaxi) {
            if (!(bLongSystemAlignment) && LastSpanNumber > 5) {
                this.Quote.Warnings.Add(strLanguageText("sltLongSystemWireAlignmentRecommendedIfInstalled"), WarningLevels.Decision);
            }
        }
        else {
            if (!(bLongSystemAlignment) && LastSpanNumber > 8) {
                this.Quote.Warnings.Add(strLanguageText("sltLongSystemWireAlignmentWarning"), WarningLevels.Decision);
            }
        }
        if (sAlumLength > 1560) {
            this.Quote.Warnings.Add(strLanguageText("sltTheTotal6inch1560feet"));
        }
        if (sE2045Length > 1300) {
            this.Quote.Warnings.Add(strLanguageText("sltTheTotal4inch1300feet"));
        }
        if (LastSpanNumber === 0) {
            //this.Quote.Warnings.Add(strLanguageText("sltMustHaveASpan"));
            return;
        }
        let sEndingLocation: number = spanf.EndingLocation(this.Quote.System, this.Side, this.Side.Span[LastSpanNumber - 1]);
        if (Is230Volt) {
            if (sEndingLocation > 1000) {
                this.Quote.Warnings.Add(strLanguageText("sltTheTotalLength1000feet"));
            }
        }
        switch (eSystemType) {
            case SystemTypes.CenterPivot:
                switch (this.Side.Span[1 - 1].SpanType) {
                    case SpanTypes.E2100:
                    case SpanTypes.E2085:
                    case SpanTypes.S2085G:
                    case SpanTypes.PL2085G:
                        break;
                    default:
                        if (sEndingLocation > 2600) {
                            this.Quote.Warnings.Add(strLanguageText("sltTheTotalLength2600feet"));
                        }
                        break;
                }
                if (eCenterPivotType === CenterPivotTypes.E2045) {
                    if (sEndingLocation > 1300) {
                        this.Quote.Warnings.Add(strLanguageText("sltTotalLenth1300E2045"));
                    }
                }
                if (this.Quote.System.Circle.CenterPivot.TowOptions.TowType === TowTypes.FourWheelPivotMover) {
                    if (sEndingLocation > 1800) {
                        this.Quote.Warnings.Add(strLanguageText("sltTheTotalLength1800feet"));
                    }
                }
                break;
            case SystemTypes.KwikTow:
                if (sEndingLocation > 1320) {
                    this.Quote.Warnings.Add(strLanguageText("sltTheTotalLength1320feet"));
                }
                break;
            case SystemTypes.HoseFeedMaxigator:
                switch (eHoseFeedType) {
                    case HoseFeedTypes.PivotingLateral:
                        switch (this.eGuidanceLocation) {
                            case GuidanceLocations.PowerTower:
                                if (sEndingLocation > 1400) {
                                    this.Quote.Warnings.Add(strLanguageText("sltPivotingLateral1400Feet"));
                                }
                                break;
                            case GuidanceLocations.OuterTower:
                                if (sEndingLocation > 1700) {
                                    this.Quote.Warnings.Add(strLanguageText("sltPivotingLateral1700Feet"));
                                }
                                break;
                        }
                        break;
                }
                if (eWaterFeed === WaterFeedTypes.EndFeed) {
                    if (sEndingLocation > 3000) {
                        this.Quote.Warnings.Add(strLanguageText("sltTheTotalLength3000feet"));
                    }
                }
                else {
                    if (sEndingLocation > 2000) {
                        this.Quote.Warnings.Add(strLanguageText("sltTheTotalLength2000feet"));
                    }
                }
                if (HasCenterLift && sEndingLocation > 1600) {
                    this.Quote.Warnings.Add(strLanguageText("sltTheTotalLength1600feetCenterLift"));
                }
                break;
            case SystemTypes.CanalFeedMaxigator:
                if (eWaterFeed === WaterFeedTypes.EndFeed) {
                    if (sEndingLocation > 3000) {
                        this.Quote.Warnings.Add(strLanguageText("sltTheTotalLength3000feet"));
                    }
                }
                else {
                    if (sEndingLocation > 2000) {
                        this.Quote.Warnings.Add(strLanguageText("sltTheTotalLength2000feet"));
                    }
                }
                break;
            default:
                if (sEndingLocation > 2600) {
                    this.Quote.Warnings.Add(strLanguageText("sltTheTotalLength2600feet"));
                }
                break;
        }
        let bSpansOutOfSync: boolean;
        let iNumberOfTowers: number;
        if (this.TowersClass.FieldSet.DataValid()) {
            iNumberOfTowers = sidef.Towers(this.Side).Count;
        }
        else {
            bSpansOutOfSync = true;
        }
        switch (eSystemType) {
            case SystemTypes.HoseFeedMaxigator:
                if (eHoseFeedType === HoseFeedTypes.DoubleEndFeed) {
                    if (iNumberOfTowers !== LastSpanNumber - 1) {
                        bSpansOutOfSync = true;
                    }
                }
                else {
                    if (iNumberOfTowers !== LastSpanNumber) {
                        bSpansOutOfSync = true;
                    }
                }
                break;
            default:
                if (iNumberOfTowers !== LastSpanNumber) {
                    bSpansOutOfSync = true;
                }
                break;
        }
        if (bA4onMaxi) {
            this.Quote.Warnings.Add(strLanguageText("sltAlumIVOnMaxi"), WarningLevels.Decision);
        }
        if ((bPainted || bGalvanized)) {
            if ((bPainted && bGalvanized)) {
                this.Quote.Warnings.Add(strLanguageText("sltGalvanizedCNPipe"), WarningLevels.Decision);
            }
            else if ((bPainted)) {
                this.Quote.Warnings.Add(strLanguageText("sltPaintedCNPipe"), WarningLevels.Decision);
            }
            else {
                this.Quote.Warnings.Add(strLanguageText("sltGalvanizedCNPipe"), WarningLevels.Decision);
            }
        }
        if (bCNPlus6Exists) {
            this.Quote.Warnings.Add(strLanguageText("sltGalvCNPlusWarning"), WarningLevels.Informational);
        }
        if (bOutletSpacingMismatch) {
            this.Quote.Warnings.Add(strLanguageText("sltOutletsSpacingInconsistent"), WarningLevels.Decision);
        }
        if (this.bFlangedSide && bIsMaxi) {
            if (sidef.Spans(this.Quote.System, this.Side).Count > 0) {
                if (spanf.PipeType(this.Quote.System, this.Side, this.Side.Span[1 - 1]) === SpanPipeTypes.Poly) {
                    this.Quote.Warnings.Add(strLanguageText("sltPowerTowerGalv"), WarningLevels.Informational);
                }
            }
        }

        const agr = PA.EC.AutoGauge();
        if (agr.error) {
            this.Quote.Warnings.Add(agr.error);
        }
    }
// Removed unsupported inner class SpansBOMErrorCheck
// TODO implement equivalent check
/*private DetectPOErrors = (): boolean => {
let LocalPO: PartsAssembler;
let p: SpansBOMErrorCheck;
LocalPO.SpecialBOM = p;
try {this.WritePO(LocalPO);
}
catch {
this.Quote.Warnings.Add(Err.Description);
return false;
}
if (p.StainlessSteelJointUsed && p.NonStainlessSteelJointUsed) {
this.Quote.Warnings.Add(strLanguageText("sltSpanJointsMixed"), WarningLevels.Decision);
}
}
*/public WritePO = (PO: PartsAssembler) => {
        let currentSpanNumber: number;
        let RigidSpan: number;
        let bAgriTrac: boolean;
        let bFirstSpanKwikTow: boolean;
        let bEDMP: boolean;
        let bDoubleEndFeed: boolean;
        let bSSM: boolean;
        let bReverseTow: boolean;
        let SpanLength: number;
        let iEndBoomLength: number;
        let bSAC: boolean;
        let bStainless: boolean;
        let b90WrapAroundSpan: boolean;
        let eNextSpanType: SpanTypes;
        let eNextSpanLength: number;
        let bNextSpanIsDisconnectingSpan: boolean;
        let bUseGalvCN: boolean;
        let Amps: number;
        if (PO.GeneratingBOM) {
            Amps = PO.EC.FullLoadAmps;
        }
        let bTheftDeterrentClip: boolean;
        let bConduitClipWOSpring: boolean;
        let sys: ISystemBase = this.Quote.System;

        if (sys.SystemProperties.SystemType === SystemTypes.Ancillary) return;

        RigidSpan = this.Quote.RigidSpan();
        bEDMP = sysf.IsEDMP(this.Quote.System);
        bSSM = this.Quote.IsSingleSpanMaxi();
        bDoubleEndFeed = this.Quote.IsDEF();
        bReverseTow = sysf.IsReverseTow(this.Quote.System);
        bSAC = sysf.HasSwingArmCorner(this.Quote.System);
        let sprop: SpanProperties = sys.SpanProperties;
        bStainless = sprop.StainlessSteelJoints;
        bUseGalvCN = sprop.GalvanizedCNPlus;
        bConduitClipWOSpring = sprop.ConduitClipWOSpringClip;
        bTheftDeterrentClip = sprop.TheftDeterrentClip;
        if (this.EOSClass.FieldSet.DataValid()) {
            iEndBoomLength = this.EOSClass.EndBoomLength();
        }
        else {
            iEndBoomLength = 1;
        }
        let eLocationCode: LocationCodes;
        if (this.bFlangedSide) {
            eLocationCode = LocationCodes.SpansRight;
        }
        else {
            eLocationCode = LocationCodes.SpansLeft;
        }

        let sSpanLength: number;
        let sCableLength: number;
        let CableType: CableTypes;
        let LastSpanNumber: number = sidef.NumberOfSpans(this.Side);

        let cableGauges: ISpanAutoGaugeResult[];
        if (!(bEDMP)) {
            if (cpf.SourceVoltage(this.Quote.System.ControlPanel) > 0) {
                const agr = PO.EC.AutoGauge();
                cableGauges = this.bFlangedSide ? agr.flangedSide : agr.flexSide;
            }
            else {
                cableGauges = [];
                for (let i: number = 1; i <= LastSpanNumber; i++
                ) {
                    cableGauges.push({
                        cableGauge: this.Side.Span[i - 1].MinCableGauge
                    });
                }
            }
        } else {
            cableGauges = [];
            for (let i: number = 1; i <= LastSpanNumber; i++
            ) {
                cableGauges.push({
                    cableGauge: 14
                });
            }
        }

        for (currentSpanNumber = 1; currentSpanNumber <= LastSpanNumber; currentSpanNumber++
        ) {
            let bTowerOutlet: boolean = false;
            let bLPDrainKit: boolean = false;
            let bWatermanDrain: boolean = false;
            const cableGauge = cableGauges[currentSpanNumber - 1];
            if (this.Quote.RightTowersClass.DataValid()) {
                if (sys.TowerProperties.DrainCouplers) {
                    bTowerOutlet = true;
                }
                if (sys.TowerProperties.LPDrainKit) {
                    bLPDrainKit = true;
                }
                if (sys.TowerProperties.WatermanDrain) {
                    bWatermanDrain = true;
                }
            }
            if (this.Quote.SprinklerConfigClass.FieldSet.DataValid()) {
                let bb: BoomBacks = this.Side.Sprinklers.BoomBacks;
                if (bb.BoomBackType === BoomBackTypes.Tower && bb.FirstTower <= currentSpanNumber) {
                    bTowerOutlet = true;
                }
            }
            if (currentSpanNumber < LastSpanNumber) {
                eNextSpanType = this.Side.Span[currentSpanNumber + 1 - 1].SpanType;
                bNextSpanIsDisconnectingSpan = this.Side.Span[currentSpanNumber + 1 - 1].Disconnecting;
                eNextSpanLength = this.Side.Span[currentSpanNumber + 1 - 1].Length;
            }
            b90WrapAroundSpan = false;
            if (this.Quote.RightTowersClass.FieldSet.DataValid()) {
                if (currentSpanNumber <= sidef.Towers(this.Side).Count) {
                    switch (this.Side.Tower[currentSpanNumber - 1].WrapAroundSpan) {
                        case WrapAroundSpanTypes.NinetyDegree:
                            b90WrapAroundSpan = true;
                            break;
                    }
                }
            }
            let sp: Span = this.Side.Span[currentSpanNumber - 1];
            SpanLength = sp.Length;
            bFirstSpanKwikTow = (currentSpanNumber === 1) && sys.SystemProperties.SystemType === SystemTypes.KwikTow;
            bAgriTrac = false;
            if (currentSpanNumber !== LastSpanNumber || !(bDoubleEndFeed)) {
                if (this.TowersClass.FieldSet.DataValid()) {
                    if (currentSpanNumber <= sidef.NumberOfTowers(this.Side)) {
                        bAgriTrac = this.Side.Tower[currentSpanNumber - 1].AgriTrac;
                    }
                }
            }

            switch (sp.SpanType) {
                case SpanTypes.E2100:
                    switch (SpanLength) {
                        case 80:
                            PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396101-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-80' LEPA 10" EII GALV
                            break;
                        case 99:
                            PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396102-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-99' LEPA 10" EII GALV
                            break;
                        case 118:
                            PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396103-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-118' LEPA 10" EII GALV
                            break;
                        case 120:
                            PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396104-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-120' LEPA 10" EII GALV
                            break;
                        case 137:
                            PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396105-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-137' LEPA 10" EII GALV
                            break;
                    }
                    break;
                case SpanTypes.E2085:
                    switch (SpanLength) {
                        case 80:
                            PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396201-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-80' LEPA 8-5/8" EII GALV
                            break;
                        case 99:
                            PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396202-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-99' LEPA 8-5/8" EII GALV
                            break;
                        case 118:
                            PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396203-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-118' LEPA 8-5/8" EII GALV
                            break;
                        case 137:
                            PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396204-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-137' LEPA 8-5/8" EII GALV
                            break;
                        case 140:
                            PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396435", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-140' LEPA E2085
                            break;
                        case 156:
                            PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396205-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-156' LEPA 8-5/8" EII GALV
                            break;
                        case 160:
                            PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396206-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-160' LEPA 8-5/8" EII GALV
                            break;
                    }
                    break;
                case SpanTypes.PL2085G:
                    switch (SpanLength) {
                        case 118:
                            PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396440", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-118' PL2085
                            break;
                        case 137:
                            PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396441", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-137' PL2085
                            break;
                        case 156:
                            PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396442", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-156' PL2085
                            break;
                        case 160:
                            PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396443", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-160' PL2085
                            break;
                    }
                    break;
                case SpanTypes.E2065:
                    switch (SpanLength) {
                        case 80:
                            switch (sp.Spacing) {
                                case 40:
                                    if (!(sp.LargeTrussRods)) {
                                        PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396381-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-80' LEPA 6-5/8' EII GALV
                                    }
                                    else {
                                        PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396381X", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-80' E2065 LEPA 3/4R
                                    }
                                    break;
                                case 57:
                                    if (!(sp.LargeTrussRods)) {
                                        PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396320-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN 80' 57SP 6-5/8 EII GALV
                                    }
                                    else {
                                        PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396320X", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN 80' E2065 57SP 3/4R
                                    }
                                    break;
                            }
                            break;
                        case 99:
                            switch (sp.Spacing) {
                                case 40:
                                    if (!(sp.LargeTrussRods)) {
                                        PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396382-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-99' LEPA 6-5/8 EII GALV
                                    }
                                    else {
                                        PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396382X", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-99' E2065 LEPA 3/4R
                                    }
                                    break;
                                case 57:
                                    if (!(sp.LargeTrussRods)) {
                                        PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396321-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-99' 57SP 6-5/8" EII GALV
                                    }
                                    else {
                                        PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396321X", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-99' E2065 57SP 3/4R
                                    }
                                    break;
                            }
                            break;
                        case 118:
                            switch (sp.Spacing) {
                                case 40:
                                    if (!(sp.LargeTrussRods)) {
                                        PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396383-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-118' LEPA 6-5/8" EII GALV
                                    }
                                    else {
                                        PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396383X", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-118' E2065 LEPA 3/4R
                                    }
                                    break;
                                case 57:
                                    if (!(sp.LargeTrussRods)) {
                                        PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396310-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-118' 57 SP6-5/8" EII GALV
                                    }
                                    else {
                                        PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396310X", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-118' E2065 3/4R
                                    }
                                    break;
                            }
                            break;
                        case 137:
                            switch (sp.Spacing) {
                                case 40:
                                    if (!(sp.LargeTrussRods)) {
                                        PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396384-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-137' LEPA 6-5/8" EII GALV
                                    }
                                    else {
                                        PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396384X", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-137' E2065 LEPA 3/4R
                                    }
                                    break;
                                case 57:
                                    if (!(sp.LargeTrussRods)) {
                                        PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396311-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-137' 57SP 6-5/8" EII GALV
                                    }
                                    else {
                                        PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396311X", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-137' E2065 57SP 3/4R
                                    }
                                    break;
                            }
                            break;
                        case 140:
                            switch (sp.Spacing) {
                                case 40:
                                    if (!(sp.LargeTrussRods)) {
                                        PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396267", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-140' LEPA E2065
                                    }
                                    else {
                                        PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396267X", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-140' LEPA E2065 3/4R
                                    }
                                    break;
                            }
                            break;
                        case 156:
                            switch (sp.Spacing) {
                                case 40:
                                    if (!(sp.LargeTrussRods)) {
                                        PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396385-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-156' LEPA 6-5/8" EII GALV
                                    }
                                    else {
                                        PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396385X", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-156' E2065 LEPA 3/4R
                                    }
                                    break;
                                case 57:
                                    if (!(sp.LargeTrussRods)) {
                                        PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396312-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-156' 57SP 6-5/8" EII GALV
                                    }
                                    else {
                                        PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396312X", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-156' E2065 57SP 3/4R
                                    }
                                    break;
                            }
                            break;
                        case 160:
                            switch (sp.Spacing) {
                                case 40:
                                    if (!(sp.LargeTrussRods)) {
                                        PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396315-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-160' LEPA 6-5/8" EII GALV
                                    }
                                    else {
                                        PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396315X", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-160' E2065 LEPA 3/4R
                                    }
                                    break;
                                case 57:
                                    if (!(sp.LargeTrussRods)) {
                                        PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396215-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-160' 57 SP 6-5/8" EII GAL
                                    }
                                    else {
                                        PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396215X", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-160' E2065 57SP 3/4R
                                    }
                                    break;
                            }
                            break;
                        case 175:
                            switch (sp.Spacing) {
                                case 40:
                                    PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396386-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-175' LEPA 6-5/8" EII GALV
                                    break;
                                case 57:
                                    PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396313-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-175' 57SP 6-5/8"EII GALV
                                    break;
                            }
                            break;
                        case 180:
                            PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396316-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-180' LEPA 6-5/8" EII GALV
                            break;
                        case 186.7:
                            PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396322-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-186.7 LEPA 6-5/8 EII GALV
                            break;
                        case 194:
                            switch (sp.Spacing) {
                                case 40:
                                    PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396387-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-194' LEPA 6-5/8 EII GALV
                                    break;
                                case 57:
                                    PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396314-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-194' 57SP 6-5/8" EII GALV
                                    break;
                            }
                            break;
                        case 204:
                            PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396391-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-204' LEPA 6-5/8" EII GALV
                            break;
                    }
                    if (sp.Extension) {
                        PO.InsertPart(schema.groups.SpanAssemblies_SpanExtensionAssembly.groupId, "354503-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN KIT-4 FT EXTENSION-E65
                    }
                    break;
                case SpanTypes.E2060:
                    switch (SpanLength) {
                        case 80:
                            switch (sp.Spacing) {
                                case 40:
                                    if (!(sp.LargeTrussRods)) {
                                        PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396481-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-80' LEPA 6" EII GALV
                                    }
                                    else {
                                        PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396481X", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-80' E2060 LEPA 3/4R
                                    }
                                    break;
                                case 57:
                                    if (!(sp.LargeTrussRods)) {
                                        PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396420-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-80' 57SP 6" EII GALV
                                    }
                                    else {
                                        PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396420X", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-80' E2060 57SP 3/4R
                                    }
                                    break;
                            }
                            break;
                        case 99:
                            switch (sp.Spacing) {
                                case 40:
                                    if (!(sp.LargeTrussRods)) {
                                        PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396482-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-99' LEPA 6" EII GALV
                                    }
                                    else {
                                        PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396482X", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-99' E2060 LEPA 3/4R
                                    }
                                    break;
                                case 57:
                                    if (!(sp.LargeTrussRods)) {
                                        PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396421-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-99' 57SP 6" EII GALV
                                    }
                                    else {
                                        PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396421X", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-99' E2060 57SP 3/4R
                                    }
                                    break;
                            }
                            break;
                        case 118:
                            switch (sp.Spacing) {
                                case 40:
                                    if (!(sp.LargeTrussRods)) {
                                        PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396483-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-118' LEPA 6" EII GALV
                                    }
                                    else {
                                        PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396483X", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-118' E2060 LEPA
                                    }
                                    break;
                                case 57:
                                    if (!(sp.LargeTrussRods)) {
                                        PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396410-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-118' 57 SP 6" EII GALV
                                    }
                                    else {
                                        PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396410X", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-118' E2060 57SP 3/4R
                                    }
                                    break;
                            }
                            break;
                        case 137:
                            switch (sp.Spacing) {
                                case 40:
                                    if (!(sp.LargeTrussRods)) {
                                        PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396484-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-137' LEPA 6" EII GALV
                                    }
                                    else {
                                        PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396484X", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-137' E2060 LEPA 3/4R
                                    }
                                    break;
                                case 57:
                                    if (!(sp.LargeTrussRods)) {
                                        PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396411-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-137' 57SP 6" EII GALV
                                    }
                                    else {
                                        PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396411X", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-137' E2060 57SP
                                    }
                                    break;
                            }
                            break;
                        case 140:
                            switch (sp.Spacing) {
                                case 40:
                                    if (!(sp.LargeTrussRods)) {
                                        PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396269", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-140' LEPA E2060
                                    }
                                    else {
                                        PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396269X", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-140' LEPA E2060 3/4R
                                    }
                                    break;
                            }
                            break;
                        case 156:
                            switch (sp.Spacing) {
                                case 40:
                                    if (!(sp.LargeTrussRods)) {
                                        PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396485-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-156' LEPA 6" EII GALV
                                    }
                                    else {
                                        PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396485X", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-156' E2060 LEPA 3/4R
                                    }
                                    break;
                                case 57:
                                    if (!(sp.LargeTrussRods)) {
                                        PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396412-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-156' 57SP 6" EII GALV
                                    }
                                    else {
                                        PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396412X", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-156' E2060 57SP 3/4R
                                    }
                                    break;
                            }
                            break;
                        case 160:
                            switch (sp.Spacing) {
                                case 40:
                                    if (!(sp.LargeTrussRods)) {
                                        PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396415-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-160' LEPA 6" EII GALV
                                    }
                                    else {
                                        PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396415X", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-160' E2060 LEPA 3/4R
                                    }
                                    break;
                                case 57:
                                    if (!(sp.LargeTrussRods)) {
                                        PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396915-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-160' 57 SP 6" EII GALV
                                    }
                                    else {
                                        PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396915X", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-160' E2060 57SP 3/4R
                                    }
                                    break;
                            }
                            break;
                        case 175:
                            switch (sp.Spacing) {
                                case 40:
                                    if (!(sp.LargeTrussRods)) {
                                        PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396486-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-175' LEPA 6" EII GALV
                                    }
                                    else {
                                        PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396486X", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-175' E2060 LEPA 3/4R
                                    }
                                    break;
                                case 57:
                                    if (!(sp.LargeTrussRods)) {
                                        PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396413-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-175' 57SP 6" EII GALV
                                    }
                                    else {
                                        PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396413X", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-175' E2060 57SP 3/4R
                                    }
                                    break;
                            }
                            break;
                        case 180:
                            PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396416-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-180' LEPA 6" EII GALV
                            break;
                        case 186.7:
                            PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396422-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-186.7' LEPA 6" EII GALV
                            break;
                        case 194:
                            switch (sp.Spacing) {
                                case 40:
                                    PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396487-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-194' LEPA 6" EII GALV
                                    break;
                                case 57:
                                    PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396414-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-194' 57SP 6" EII GALV
                                    break;
                            }
                            break;
                        case 204:
                            PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396491-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-204' LEPA 6" EII GALV
                            break;
                        case 213:
                            switch (sp.Spacing) {
                                case 40:
                                    PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396489-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-213' LEPA 6" EII GALV
                                    break;
                                case 57:
                                    PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396419-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-213' 57SP 6" EII GALV
                                    break;
                            }
                            break;
                    }
                    if (sp.Extension) {
                        PO.InsertPart(schema.groups.SpanAssemblies_SpanExtensionAssembly.groupId, "354501-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN KIT-4 FT EXTENSION-E60
                    }
                    break;
                case SpanTypes.E2045:
                    switch (SpanLength) {
                        case 118:
                            if ((sp.Spacing === 114)) {
                                PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396115-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-118' 114SP 4-1/2 EII GALV
                            }
                            else {
                                PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396110-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-118' 57SP 4-1/2" EII GALV
                            }
                            break;
                        case 137:
                            if ((sp.Spacing === 114)) {
                                PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396116-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-137' 114SP 4-1/2 EII GALV
                            }
                            else {
                                PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396111-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-137' 57SP 4-1/2" EII GALV
                            }
                            break;
                        case 156:
                            if ((sp.Spacing === 114)) {
                                PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396117-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-156' 114SP 4-1/2 EII GALV
                            }
                            else {
                                PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396112-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-156' 57SP 4-1/2" EII GALV
                            }
                            break;
                        case 175:
                            if ((sp.Spacing === 114)) {
                                PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396118-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-175' 114SP 4-1/2 EII GALV
                            }
                            else {
                                PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396113-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-175' 57SP 4-1/2" EII GALV
                            }
                            break;
                        case 194:
                            if ((sp.Spacing === 114)) {
                                PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396120", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-194' 114SP 4-1/2 EII GALV
                            }
                            else {
                                PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396119", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-194' 57SP 4-1/2" EII GALV
                            }
                            break;
                    }
                    break;
                case SpanTypes.E2665:
                    switch (SpanLength) {
                        case 80:
                            if (bUseGalvCN) {
                                if (!(sp.LargeTrussRods)) {
                                    PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "397400", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-80' 57SP E2665G
                                }
                                else {
                                    PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "397400X", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-80' E2665G 57SP 3/4R
                                }
                            }
                            else {
                                if (!(sp.LargeTrussRods)) {
                                    PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396508-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-80' 57SP 6-5/8" CT EII G
                                }
                                else {
                                    PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396508X", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-80' E2665 57SP 3/4R
                                }
                            }
                            break;
                        case 99:
                            if (bUseGalvCN) {
                                if (!(sp.LargeTrussRods)) {
                                    PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "397401", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-99' 57SP E2665G
                                }
                                else {
                                    PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "397401X", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-99' E2665G 57SP 3/4R
                                }
                            }
                            else {
                                if (!(sp.LargeTrussRods)) {
                                    PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396521-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-99' 57SP 6-5/8 CT EII GAL
                                }
                                else {
                                    PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396521X", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-99' E2665 57SP 3/4R
                                }
                            }
                            break;
                        case 118:
                            if (bUseGalvCN) {
                                if (!(sp.LargeTrussRods)) {
                                    PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "397402", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-118' 57SP E2665G
                                }
                                else {
                                    PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "397402X", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-118' E2665G 57SP 3/4R
                                }
                            }
                            else {
                                if (!(sp.LargeTrussRods)) {
                                    PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396510-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-118' 57 SP 6-5/8 CT EII G
                                }
                                else {
                                    PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396510X", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-118' E2665 57SP 3/4R
                                }
                            }
                            break;
                        case 137:
                            if (bUseGalvCN) {
                                if (!(sp.LargeTrussRods)) {
                                    PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "397403", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-137' 57SP E2665G
                                }
                                else {
                                    PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "397403X", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-137' E2665G 57SP 3/4R
                                }
                            }
                            else {
                                if (!(sp.LargeTrussRods)) {
                                    PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396511-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-137' 57SP 6-5/8" CT EII G
                                }
                                else {
                                    PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396511X", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-137' E2665 57SP 3/4R
                                }
                            }
                            break;
                        case 156:
                            if (bUseGalvCN) {
                                if (!(sp.LargeTrussRods)) {
                                    PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "397404", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-156' 57SP E2665G
                                }
                                else {
                                    PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "397404X", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-156' E2665G 57SP 3/4R
                                }
                            }
                            else {
                                if (!(sp.LargeTrussRods)) {
                                    PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396512-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-156' 57SP 6-5/8" CT EII G
                                }
                                else {
                                    PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396512X", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-156' E2665 57SP 3/4R
                                }
                            }
                            break;
                        case 160:
                            if (bUseGalvCN) {
                                if (!(sp.LargeTrussRods)) {
                                    PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "397405", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-160' 57SP E2665G
                                }
                                else {
                                    PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "397405X", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-160' E2665G 57SP 3/4R
                                }
                            }
                            else {
                                if (!(sp.LargeTrussRods)) {
                                    PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396515-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-160' 57 SP 6-5/8 CT EII G
                                }
                                else {
                                    PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396515X", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-160' E2665 57SP 3/4R
                                }
                            }
                            break;
                        case 175:
                            if (bUseGalvCN) {
                                PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "397406", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-175' 57SP E2665G
                            }
                            else {
                                PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396513-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-175' 57SP 6-5/8" CT EII G
                            }
                            break;
                        case 194:
                            if (bUseGalvCN) {
                                PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "397407", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-194' 57SP E2665G
                            }
                            else {
                                PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396514-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-194' 57SP 6-5/8" CT EII G
                            }
                            break;
                    }
                    if (sp.Extension) {
                        if (bUseGalvCN) {
                            PO.InsertPart(schema.groups.SpanAssemblies_SpanExtensionAssembly.groupId, "354509", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN KIT-4' EXT-6 5/8-E2665G
                        }
                        else {
                            PO.InsertPart(schema.groups.SpanAssemblies_SpanExtensionAssembly.groupId, "354504-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN KIT-4 FT EXTENSION-E665
                        }
                    }
                    break;
                case SpanTypes.E2660:
                    switch (SpanLength) {
                        case 80:
                            if (bUseGalvCN) {
                                if (!(sp.LargeTrussRods)) {
                                    PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "397300", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-80' 57SP E2660G
                                }
                                else {
                                    PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "397300X", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-80' 57SP E2660G 3/4R
                                }
                            }
                            else {
                                if (!(sp.LargeTrussRods)) {
                                    PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396620-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-80' 57SP 6" CT EII GALV
                                }
                                else {
                                    PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396620X", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-80' E2660 57SP 3/4R
                                }
                            }
                            break;
                        case 99:
                            if (bUseGalvCN) {
                                if (!(sp.LargeTrussRods)) {
                                    PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "397301", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-99' 57SP E2660G
                                }
                                else {
                                    PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "397301X", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-99' 57SP E2660G 3/4R
                                }
                            }
                            else {
                                if (!(sp.LargeTrussRods)) {
                                    PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396621-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-99' 57SP 6" CT EII GALV
                                }
                                else {
                                    PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396621X", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-99' E2660 57SP 3/4R
                                }
                            }
                            break;
                        case 118:
                            if (bUseGalvCN) {
                                if (!(sp.LargeTrussRods)) {
                                    PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "397302", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-118' 57SP E2660G
                                }
                                else {
                                    PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "397302X", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-118' E2660G 57SP 3/4R
                                }
                            }
                            else {
                                if (!(sp.LargeTrussRods)) {
                                    PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396610-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-118' 57 SP 6" CT EII GALV
                                }
                                else {
                                    PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396610X", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-118' E2660 57SP 3/4R
                                }
                            }
                            break;
                        case 137:
                            if (bUseGalvCN) {
                                if (!(sp.LargeTrussRods)) {
                                    PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "397303", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-137' 57SP E2660G
                                }
                                else {
                                    PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "397303X", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-137' E2660G 57SP 3/4R
                                }
                            }
                            else {
                                if (!(sp.LargeTrussRods)) {
                                    PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396611-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-137' 57SP 6" CT EII GALV
                                }
                                else {
                                    PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396611X", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-137' E2660 57SP 3/4R
                                }
                            }
                            break;
                        case 156:
                            if (bUseGalvCN) {
                                if (!(sp.LargeTrussRods)) {
                                    PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "397304", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-156' 57SP E2660G
                                }
                                else {
                                    PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "397304X", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-156' E2660G 57SP 3/4R
                                }
                            }
                            else {
                                if (!(sp.LargeTrussRods)) {
                                    PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396612-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-156' 57SP 6" CT EII GALV
                                }
                                else {
                                    PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396612X", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-156' E2660 57SP 3/4R
                                }
                            }
                            break;
                        case 160:
                            if (bUseGalvCN) {
                                if (!(sp.LargeTrussRods)) {
                                    PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "397305", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-160' 57SP E2660G
                                }
                                else {
                                    PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "397305X", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-160' E2660G 57SP 3/4R
                                }
                            }
                            else {
                                if (!(sp.LargeTrussRods)) {
                                    PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396615-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-160' 57 SP 6" CT EII GALV
                                }
                                else {
                                    PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396615X", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-160' E2660 57SP 3/4R
                                }
                            }
                            break;
                        case 175:
                            if (bUseGalvCN) {
                                if (!(sp.LargeTrussRods)) {
                                    PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "397306", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-175' 57SP E2660G
                                }
                                else {
                                    PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "397306X", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-175' E2660G 57SP 3/4R
                                }
                            }
                            else {
                                if (!(sp.LargeTrussRods)) {
                                    PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396613-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-175' 57SP 6" CT EII GALV
                                }
                                else {
                                    PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396613X", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-175' E2660 57SP 3/4R
                                }
                            }
                            break;
                        case 194:
                            if (bUseGalvCN) {
                                PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "397307", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-194' 57SP E2660G
                            }
                            else {
                                PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396614-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-194' 57SP 6" CT EII GALV
                            }
                            break;
                        case 213:
                            if (bUseGalvCN) {
                                PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "397308", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-213' 57SP E2660G
                            }
                            else {
                                PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396619-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-213' 57SP 6" CT EII GALV
                            }
                            break;
                    }
                    if (sp.Extension) {
                        if (bUseGalvCN) {
                            PO.InsertPart(schema.groups.SpanAssemblies_SpanExtensionAssembly.groupId, "354508", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN KIT-4' EXT-6-E2660G
                        }
                        else {
                            PO.InsertPart(schema.groups.SpanAssemblies_SpanExtensionAssembly.groupId, "354502-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN KIT-4 FT EXTENSION-E660
                        }
                    }
                    break;
                case SpanTypes.A80G:
                    switch (SpanLength) {
                        case 80:
                            PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "397001-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // 80 FOOT SPAN-A80-G
                            break;
                        case 99:
                            PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "397002-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // 99 FOOT SPAN-A80-G
                            break;
                        case 118:
                            PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "397003-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN ASY-118 FT-40 SP-A80-G
                            break;
                        case 137:
                            PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "397004-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN ASY-137 FT-40 SP-A80-G
                            break;
                        case 156:
                            PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "397008-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN ASY-156 FT-40 SP-A80-G
                            break;
                        case 160:
                            PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "397009-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN ASY 160 FT 40 SPCG A80G
                            break;
                    }
                    break;
                case SpanTypes.A60G:
                    switch (SpanLength) {
                        case 80:
                            PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396813-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN ASY-80FT-A60G-LEPA GALV
                            break;
                        case 99:
                            PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396814-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN ASY-99FT-A60G-LEPA GALV
                            break;
                        case 118:
                            PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396815-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN ASY-118FT-A60G-LEPA GALV
                            break;
                        case 137:
                            PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396816-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN ASY-137FT-A60G-LEPA GALV
                            break;
                        case 140:
                            PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396811-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN ASY-140 FT-40 SP-A60G
                            break;
                        case 156:
                            PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396817-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN ASY-156FT-A60G-LEPA GALV
                            break;
                        case 160:
                            PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396812-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN ASY-160 FT-40 SP-A60G
                            break;
                    }
                    if (sys.SpanProperties.AlumIVSupportRod) {
                        if (currentSpanNumber === LastSpanNumber) {
                            PO.InsertPart(schema.groups.SpanAssemblies_AlumIVLongitudinalSupport.groupId, "396782-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // LONG. SUPP ROD OPT-END SPAN-A4
                        }
                        else {
                            PO.InsertPart(schema.groups.SpanAssemblies_AlumIVLongitudinalSupport.groupId, "396781-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // LONG SUPP ROD OPT-INT SPAN-A4
                        }
                    }
                    if (sp.Extension) {
                        PO.InsertPart(schema.groups.SpanAssemblies_SpanExtensionAssembly.groupId, "354505-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN KIT-4FT EXTENSION-A60-G
                    }
                    break;
                case SpanTypes.AlumIV:
                    switch (SpanLength) {
                        case 80:
                            PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396711-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN ASSY-80FT-ALUMI 4-LEPA
                            break;
                        case 99:
                            PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396712-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN ASY-99FT-ALUMI 4-LEPA
                            break;
                        case 118:
                            PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396713-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN ASY-118FT-ALLUMI 4-LEPA
                            break;
                        case 137:
                            PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396714-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN ASY-137FT-ALUMI 4-LEPA
                            break;
                        case 140:
                            PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396716-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-140'-LEPA-A4
                            break;
                        case 156:
                            PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396715-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN ASY-156FT-ALUMI 4-LEPA
                            break;
                        case 160:
                            PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396717-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN ASY-160FT-ALUMI 4-LEPA
                            break;
                    }
                    if (sys.SpanProperties.AlumIVSupportRod) {
                        if (currentSpanNumber === LastSpanNumber) {
                            PO.InsertPart(schema.groups.SpanAssemblies_AlumIVLongitudinalSupport.groupId, "396782-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // LONG. SUPP ROD OPT-END SPAN-A4
                        }
                        else {
                            PO.InsertPart(schema.groups.SpanAssemblies_AlumIVLongitudinalSupport.groupId, "396781-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // LONG SUPP ROD OPT-INT SPAN-A4
                        }
                    }
                    if (sp.Extension) {
                        PO.InsertPart(schema.groups.SpanAssemblies_SpanExtensionAssembly.groupId, "354505-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN KIT-4FT EXTENSION-A60-G
                    }
                    break;
                case SpanTypes.S2085G:
                    switch (SpanLength) {
                        case 80:
                            PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396151", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-80' LEPA S2085
                            break;
                        case 99:
                            PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396152", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-99' LEPA S2085
                            break;
                        case 118:
                            PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396543", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-118' LEPA S2085
                            break;
                        case 137:
                            PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396544", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-137' LEPA S2085
                            break;
                        case 140:
                            PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396545", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-140' LEPA S2085
                            break;
                        case 156:
                            PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396546", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-156' LEPA S2085
                            break;
                        case 160:
                            PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396547", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-160' LEPA S2085
                            break;
                    }
                    break;
                case SpanTypes.S2065G:
                    switch (SpanLength) {
                        case 80:
                            if (!(sp.LargeTrussRods)) {
                                PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396251", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-80' LEPA S2065
                            }
                            else {
                                PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396251X", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-80' S2065 LEPA 3/4R
                            }
                            break;
                        case 99:
                            if (!(sp.LargeTrussRods)) {
                                PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396252", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-99' LEPA S2065
                            }
                            else {
                                PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396252X", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-99' S2065 LEPA 3/4R
                            }
                            break;
                        case 118:
                            if (!(sp.LargeTrussRods)) {
                                PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396253", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-118' LEPA S2065
                            }
                            else {
                                PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396253X", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-118' S2065 LEPA 3/4R
                            }
                            break;
                        case 137:
                            if (!(sp.LargeTrussRods)) {
                                PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396254", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-137' LEPA S2065
                            }
                            else {
                                PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396254X", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-137' S2065 LEPA 3/4R
                            }
                            break;
                        case 140:
                            if (!(sp.LargeTrussRods)) {
                                PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396266", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-140' LEPA S2065
                            }
                            else {
                                PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396266X", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-140' S2065 LEPA 3/4R
                            }
                            break;
                        case 156:
                            if (!(sp.LargeTrussRods)) {
                                PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396255", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-156' LEPA S2065
                            }
                            else {
                                PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396255X", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-156' S2065 LEPA 3/4R
                            }
                            break;
                        case 160:
                            if (!(sp.LargeTrussRods)) {
                                PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396388", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-160' LEPA S2065
                            }
                            else {
                                PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396388X", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-160' S2065 LEPA 3/4R
                            }
                            break;
                        case 175:
                            PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396256", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-175' LEPA S2065
                            break;
                    }
                    if (sp.Extension) {
                        PO.InsertPart(schema.groups.SpanAssemblies_SpanExtensionAssembly.groupId, "354503SS", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN KIT-4' EXTENSION-S65G
                    }
                    break;
                case SpanTypes.PL2065G:
                    switch (SpanLength) {
                        case 80:
                            if (!(sp.LargeTrussRods)) {
                                PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396426", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-80' PL2065
                            }
                            else {
                                PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396426X", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-80' PL2065 3/4R
                            }
                            break;
                        case 99:
                            if (!(sp.LargeTrussRods)) {
                                PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396427", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-99' PL2065
                            }
                            else {
                                PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396427X", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-99' PL2065 3/4R
                            }
                            break;
                        case 118:
                            if (!(sp.LargeTrussRods)) {
                                PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396428", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-118' PL2065
                            }
                            else {
                                PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396428X", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-118' PL2065 3/4R
                            }
                            break;
                        case 137:
                            if (!(sp.LargeTrussRods)) {
                                PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396429", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-137' PL2065
                            }
                            else {
                                PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396429X", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-137' PL2065 3/4R
                            }
                            break;
                        case 156:
                            if (!(sp.LargeTrussRods)) {
                                PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396430", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-156' PL2065
                            }
                            else {
                                PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396430X", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-156' PL2065 3/4R
                            }
                            break;
                        case 160:
                            if (!(sp.LargeTrussRods)) {
                                PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396433", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-160'-57SP-PL2065
                            }
                            else {
                                PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396433X", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-160'-57SP-PL2065 3/4R
                            }
                            break;
                        case 175:
                            PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396431", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-175' PL2065
                            break;
                        case 194:
                            PO.InsertPart(schema.groups.SpanAssemblies_SpanAssembly.groupId, "396432", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-194' PL2065
                            break;
                    }
                    if (sp.Extension) {
                        PO.InsertPart(schema.groups.SpanAssemblies_SpanExtensionAssembly.groupId, "354516", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN KIT-4 FT EXT PL2065
                    }
                    break;
            }
            switch (sp.SpanType) {
                case SpanTypes.E2100:
                    if (currentSpanNumber < LastSpanNumber) {
                        switch (eNextSpanType) {
                            case SpanTypes.E2100:
                                if (!(bTowerOutlet)) {
                                    PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396126", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // JOINT-SPAN-E2100
                                }
                                else {
                                    PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396217", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPN-JNT 10 W/CPG EII
                                }
                                break;
                            default:
                                if (!(bTowerOutlet)) {
                                    PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396127", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // JOINT-SPAN-E2100/E2085 GALV
                                }
                                else {
                                    PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396240", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN JOINT 10"/8-5/8" EII GALV
                                }
                                break;
                        }
                    }
                    break;
                case SpanTypes.E2085:
                    if (currentSpanNumber < LastSpanNumber) {
                        switch (eNextSpanType) {
                            case SpanTypes.E2085:
                                if (bFirstSpanKwikTow) {
                                    if (!(bTowerOutlet)) {
                                        PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396228", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPN JNT FIRST TWR KT 8 5/8 EII
                                    }
                                    else {
                                        PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396241", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // JOINT-S2085 HOOK TO A80G MAXI
                                    }
                                }
                                else {
                                    if (!(bTowerOutlet)) {
                                        PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396226", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // JOINT-SPAN-E2085
                                    }
                                    else {
                                        PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396212", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN JNT W/CPLG-30" SPG E2085
                                    }
                                }
                                break;
                            case SpanTypes.A80G:
                                if (this.bFlangedSide && RigidSpan > currentSpanNumber) {
                                    PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396238-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // JOINT-2085G HOOK TO A80G-MAXI
                                }
                                else {
                                    if (!(bTowerOutlet)) {
                                        PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396270", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPN JNT-E2085 TO A80-G
                                    }
                                    else {
                                        PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396271", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPN JNT W/CPLG-E2085 TO A80-G
                                    }
                                }
                                break;
                            case SpanTypes.A60G:
                                if (bFirstSpanKwikTow) {
                                    if (!(bTowerOutlet)) {
                                        PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396229", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPN JT TRAN KT 8 5/8-6 5/8 EII
                                    }
                                    else {
                                        PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396242", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPN JT 8-5/8/6-5/8 KT W/CP EII
                                    }
                                }
                                else {
                                    if (this.bFlangedSide && RigidSpan > currentSpanNumber) {
                                        if (!(bTowerOutlet)) {
                                            PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396239-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // JOINT-2085G RED HOOK-E60 RCVR
                                        }
                                        else {
                                            PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396249", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // JNT-2085 RED HK-E60 RCVR W/CP
                                        }
                                    }
                                    else {
                                        if (!(bTowerOutlet)) {
                                            PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396227", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // JOINT-SPAN-TRANS-E2085/E2065
                                        }
                                        else {
                                            PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396213", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN JNT RED E2085/E2065-30"
                                        }
                                    }
                                }
                                break;
                            default:
                                if (bFirstSpanKwikTow) {
                                    if (!(bTowerOutlet)) {
                                        PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396229", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPN JT TRAN KT 8 5/8-6 5/8 EII
                                    }
                                    else {
                                        PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396242", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPN JT 8-5/8/6-5/8 KT W/CP EII
                                    }
                                }
                                else {
                                    if (this.bFlangedSide && RigidSpan > currentSpanNumber) {
                                        if (!(bTowerOutlet)) {
                                            PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396231-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // JOINT-8 5/8" FLG TO 6 5/8"HOOK
                                        }
                                        else {
                                            PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396246", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // JNT-MAXI-2085-2065 HOOK W/CPLG
                                        }
                                    }
                                    else {
                                        if (!(bTowerOutlet)) {
                                            PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396227", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // JOINT-SPAN-TRANS-E2085/E2065
                                        }
                                        else {
                                            PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396213", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN JNT RED E2085/E2065-30"
                                        }
                                    }
                                }
                                break;
                        }
                    }
                    break;
                case SpanTypes.PL2085G:
                    if (currentSpanNumber < LastSpanNumber) {
                        switch (eNextSpanType) {
                            case SpanTypes.PL2085G:
                                PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396135", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // JOINT-SPAN PL2085
                                break;
                            default:
                                PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396136", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // JOINT-SPAN-TRANS-PL2085/PL2065
                                break;
                        }
                    }
                    break;
                case SpanTypes.E2065:
                    if (currentSpanNumber === LastSpanNumber) {
                        if (!((bDoubleEndFeed || bSAC))) {
                            switch (iEndBoomLength) {
                                case 85:
                                case 105:
                                    if (!(bTowerOutlet)) {
                                        if (sprop.GalvanizedCNJoints) {
                                            PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396548", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // LAST TWR TOP 85/105 EB E2665G
                                        }
                                        else {
                                            PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396340", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // LAST TWR TOP 85/105 E2065
                                        }
                                    }
                                    else {
                                        if (sprop.GalvanizedCNJoints) {
                                            PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396245-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // LAST TWR 85/105 W/CPG EII
                                        }
                                        else {
                                            PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396244", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // LAST TWR 85/100 W/CPLR E2065
                                        }
                                    }
                                    break;
                                default:
                                    if (bStainless) {
                                        if (!(bTowerOutlet)) {
                                            PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396025SS", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // LAST TOWER TOP 6-5/8 SS EII
                                        }
                                        else {
                                            PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396223", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // LAST TWR W/CPG SS EII
                                        }
                                    }
                                    else {
                                        if (!(bTowerOutlet)) {
                                            if (sprop.GalvanizedCNJoints) {
                                                PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396595", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // LAST TWR TOP E2665G
                                            }
                                            else {
                                                PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396325", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // LAST TWR TOP E2065
                                            }
                                        }
                                        else {
                                            if (sprop.GalvanizedCNJoints) {
                                                PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396734", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // LAST TWR TOP W/CPLR E2665G
                                            }
                                            else {
                                                PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396250-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // LAST TWR TOP W/CPLR E2065
                                            }
                                        }
                                    }
                                    break;
                            }
                        }
                    }
                    else {
                        if (bFirstSpanKwikTow) {
                            if (bStainless) {
                                if (!(bTowerOutlet)) {
                                    PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396028", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPN-JNT 1 TWR KT 6 5/8 SS EII
                                }
                                else {
                                    PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396029", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPN-JNT 1 TWR KT W/CG6-5/8 SS EII
                                }
                            }
                            else {
                                if (!(bTowerOutlet)) {
                                    PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396598", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN JOINT1ST TKT E2665G W/CPG
                                }
                                else {
                                    PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396598", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN JOINT1ST TKT E2665G W/CPG
                                }
                            }
                        }
                        else if (bNextSpanIsDisconnectingSpan) {
                            PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396527", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // JOINT-SPAN-DISC-EII
                        }
                        else if (b90WrapAroundSpan) {
                            PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396533", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // JOINT OPT-90 DEG PVT TWR
                        }
                        else if (bStainless) {
                            if (!(bTowerOutlet)) {
                                PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396026SS", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN JOINT 6-5/8 SS EII
                            }
                            else {
                                PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396027", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPN JNT W/CPG-6 5/8 SS EII
                            }
                        }
                        else {
                            switch (eNextSpanType) {
                                case SpanTypes.A60G:
                                case SpanTypes.AlumIV:
                                    if (this.bFlangedSide && RigidSpan > currentSpanNumber) {
                                        if (!(bTowerOutlet)) {
                                            PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396234-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // JOINT-2060 HOOK-E60 RCVR
                                        }
                                        else {
                                            PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396278", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // JNT-2060 HOOK-E60 RCVR W/CPLG
                                        }
                                    }
                                    else {
                                        if (!(bTowerOutlet)) {
                                            PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396235-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // JOINT-2665G RCVR-A60G/A4 HOOK
                                        }
                                        else {
                                            PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396274", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // JOINT-2665G RCVR W/CPLG-A4 HK
                                        }
                                    }
                                    break;
                                case SpanTypes.E2045:
                                    switch (eNextSpanLength) {
                                        case 194:
                                            PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "397038", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN JOINT E2065/E2045 194'
                                            break;
                                        default:
                                            PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "397037", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // JOINT-SPAN-TRANS-E2665G/E2045
                                            break;
                                    }
                                    break;
                                default:
                                    if (!(bTowerOutlet)) {
                                        if (sprop.GalvanizedCNJoints) {
                                            PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396596", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // JOINT SPAN E2665G
                                        }
                                        else {
                                            PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396326", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // JOINT SPAN E2065
                                        }
                                    }
                                    else {
                                        if (sprop.GalvanizedCNJoints) {
                                            PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396733", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // JOINT SPAN W/CPLR E2665G
                                        }
                                        else {
                                            PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396243", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // JOINT SPAN W/CPLR E2065
                                        }
                                    }
                                    break;
                            }
                        }
                    }
                    break;
                case SpanTypes.E2060:
                    if (currentSpanNumber === LastSpanNumber) {
                        if (!((bDoubleEndFeed || bSAC))) {
                            switch (iEndBoomLength) {
                                case 85:
                                case 105:
                                    if (!(bTowerOutlet)) {
                                        if (sprop.GalvanizedCNJoints) {
                                            PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396548", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // LAST TWR TOP 85/105 EB E2665G
                                        }
                                        else {
                                            PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396340", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // LAST TWR TOP 85/105 E2065
                                        }
                                    }
                                    else {
                                        if (sprop.GalvanizedCNJoints) {
                                            PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396245-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // LAST TWR 85/105 W/CPG EII
                                        }
                                        else {
                                            PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396244", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // LAST TWR 85/100 W/CPLR E2065
                                        }
                                    }
                                    break;
                                default:
                                    if (bStainless) {
                                        if (!(bTowerOutlet)) {
                                            PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396025SS", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // LAST TOWER TOP 6-5/8 SS EII
                                        }
                                        else {
                                            PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396223", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // LAST TWR W/CPG SS EII
                                        }
                                    }
                                    else {
                                        if (!(bTowerOutlet)) {
                                            if (sprop.GalvanizedCNJoints) {
                                                PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396595", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // LAST TWR TOP E2665G
                                            }
                                            else {
                                                PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396325", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // LAST TWR TOP E2065
                                            }
                                        }
                                        else {
                                            if (sprop.GalvanizedCNJoints) {
                                                PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396734", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // LAST TWR TOP W/CPLR E2665G
                                            }
                                            else {
                                                PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396250-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // LAST TWR TOP W/CPLR E2065
                                            }
                                        }
                                    }
                                    break;
                            }
                        }
                    }
                    else {
                        if (bFirstSpanKwikTow) {
                            if (bStainless) {
                                if (!(bTowerOutlet)) {
                                    PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396028", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPN-JNT 1 TWR KT 6 5/8 SS EII
                                }
                                else {
                                    PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396029", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPN-JNT 1 TWR KT W/CG6-5/8 SS EII
                                }
                            }
                            else {
                                if (!(bTowerOutlet)) {
                                    PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396598", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN JOINT1ST TKT E2665G W/CPG
                                }
                                else {
                                    PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396598", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN JOINT1ST TKT E2665G W/CPG
                                }
                            }
                        }
                        else if (bNextSpanIsDisconnectingSpan) {
                            PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396527", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // JOINT-SPAN-DISC-EII
                        }
                        else if (b90WrapAroundSpan) {
                            PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396533", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // JOINT OPT-90 DEG PVT TWR
                        }
                        else if (bStainless && eNextSpanType !== SpanTypes.E2045) {
                            if (!(bTowerOutlet)) {
                                PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396026SS", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN JOINT 6-5/8 SS EII
                            }
                            else {
                                PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396027", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPN JNT W/CPG-6 5/8 SS EII
                            }
                        }
                        else {
                            switch (eNextSpanType) {
                                case SpanTypes.A60G:
                                case SpanTypes.AlumIV:
                                    if (this.bFlangedSide && RigidSpan > currentSpanNumber) {
                                        if (!(bTowerOutlet)) {
                                            PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396234-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // JOINT-2060 HOOK-E60 RCVR
                                        }
                                        else {
                                            PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396278", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // JNT-2060 HOOK-E60 RCVR W/CPLG
                                        }
                                    }
                                    else {
                                        if (!(bTowerOutlet)) {
                                            PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396235-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // JOINT-2665G RCVR-A60G/A4 HOOK
                                        }
                                        else {
                                            PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396274", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // JOINT-2665G RCVR W/CPLG-A4 HK
                                        }
                                    }
                                    break;
                                case SpanTypes.E2045:
                                    switch (eNextSpanLength) {
                                        case 194:
                                            PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "397038", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN JOINT E2065/E2045 194'
                                            break;
                                        default:
                                            PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "397037", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // JOINT-SPAN-TRANS-E2665G/E2045
                                            break;
                                    }
                                    break;
                                default:
                                    if (!(bTowerOutlet)) {
                                        if (sprop.GalvanizedCNJoints) {
                                            PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396596", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // JOINT SPAN E2665G
                                        }
                                        else {
                                            PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396326", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // JOINT SPAN E2065
                                        }
                                    }
                                    else {
                                        if (sprop.GalvanizedCNJoints) {
                                            PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396733", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // JOINT SPAN W/CPLR E2665G
                                        }
                                        else {
                                            PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396243", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // JOINT SPAN W/CPLR E2065
                                        }
                                    }
                                    break;
                            }
                        }
                    }
                    break;
                case SpanTypes.E2045:
                    if (currentSpanNumber === LastSpanNumber) {
                        if (!((bDoubleEndFeed || bSAC))) {
                            switch (SpanLength) {
                                case 194:
                                    PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "397041", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // LAST TWR ASM E2045 194'
                                    break;
                                default:
                                    PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "397036", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // LAST TWR TOP E2045
                                    break;
                            }
                        }
                    }
                    else {
                        if (bFirstSpanKwikTow) {
                            PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "397039", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN JOINT 1ST TWR KT E2045
                        }
                        else {
                            if (eNextSpanLength === 194 || SpanLength === 194) {
                                PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "397040", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // JOINT SPAN 194 E2045 CN+
                            }
                            else {
                                PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "397035", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // JOINT-SPAN-E2045
                            }
                        }
                    }
                    break;
                case SpanTypes.E2665:
                    if (currentSpanNumber === LastSpanNumber) {
                        if (!((bDoubleEndFeed || bSAC))) {
                            switch (iEndBoomLength) {
                                case 85:
                                case 105:
                                    if (!(bTowerOutlet)) {
                                        PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396548", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // LAST TWR TOP 85/105 EB E2665G
                                    }
                                    else {
                                        PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396245-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // LAST TWR 85/105 W/CPG EII
                                    }
                                    break;
                                default:
                                    if (bStainless) {
                                        if (!(bTowerOutlet)) {
                                            PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396025SS", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // LAST TOWER TOP 6-5/8 SS EII
                                        }
                                        else {
                                            PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396223", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // LAST TWR W/CPG SS EII
                                        }
                                    }
                                    else {
                                        if (!(bTowerOutlet)) {
                                            PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396595", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // LAST TWR TOP E2665G
                                        }
                                        else {
                                            PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396734", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // LAST TWR TOP W/CPLR E2665G
                                        }
                                    }
                                    break;
                            }
                        }
                    }
                    else {
                        if (bFirstSpanKwikTow) {
                            if (bStainless) {
                                if (!(bTowerOutlet)) {
                                    PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396028", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPN-JNT 1 TWR KT 6 5/8 SS EII
                                }
                                else {
                                    PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396029", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPN-JNT 1 TWR KT W/CG6-5/8 SS EII
                                }
                            }
                            else {
                                if (!(bTowerOutlet)) {
                                    PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396598", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN JOINT1ST TKT E2665G W/CPG
                                }
                                else {
                                    PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396733", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // JOINT SPAN W/CPLR E2665G
                                }
                            }
                        }
                        else if (bNextSpanIsDisconnectingSpan) {
                            PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396527", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // JOINT-SPAN-DISC-EII
                        }
                        else if (b90WrapAroundSpan) {
                            PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396533", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // JOINT OPT-90 DEG PVT TWR
                        }
                        else if (bStainless) {
                            if (!(bTowerOutlet)) {
                                PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396026SS", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN JOINT 6-5/8 SS EII
                            }
                            else {
                                PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396027", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPN JNT W/CPG-6 5/8 SS EII
                            }
                        }
                        else {
                            switch (eNextSpanType) {
                                case SpanTypes.A60G:
                                case SpanTypes.AlumIV:
                                    if (this.bFlangedSide && RigidSpan > currentSpanNumber) {
                                        if (!(bTowerOutlet)) {
                                            PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396234", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // JOINT-2660 HOOK-E660 RCVR
                                        }
                                        else {
                                            PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396279", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // JNT 2660 HOOK-E660 RCVR W/CPLG
                                        }
                                    }
                                    else {
                                        if (!(bTowerOutlet)) {
                                            PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396599", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN JNT E2665G REC/A4-A60G HK
                                        }
                                        else {
                                            PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396274", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // JOINT-2665G RCVR W/CPLG-A4 HK
                                        }
                                    }
                                    break;
                                default:
                                    if (!(bTowerOutlet)) {
                                        PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396596", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // JOINT SPAN E2665G
                                    }
                                    else {
                                        PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396733", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // JOINT SPAN W/CPLR E2665G
                                    }
                                    break;
                            }
                        }
                    }
                    break;
                case SpanTypes.E2660:
                    if (currentSpanNumber === LastSpanNumber) {
                        if (!((bDoubleEndFeed || bSAC))) {
                            switch (iEndBoomLength) {
                                case 85:
                                case 105:
                                    if (!(bTowerOutlet)) {
                                        PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396548", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // LAST TWR TOP 85/105 EB E2665G
                                    }
                                    else {
                                        PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396245-G", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // LAST TWR 85/105 W/CPG EII
                                    }
                                    break;
                                default:
                                    if (bStainless) {
                                        if (!(bTowerOutlet)) {
                                            PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396025SS", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // LAST TOWER TOP 6-5/8 SS EII
                                        }
                                        else {
                                            PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396223", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // LAST TWR W/CPG SS EII
                                        }
                                    }
                                    else {
                                        if (!(bTowerOutlet)) {
                                            PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396595", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // LAST TWR TOP E2665G
                                        }
                                        else {
                                            PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396734", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // LAST TWR TOP W/CPLR E2665G
                                        }
                                    }
                                    break;
                            }
                        }
                    }
                    else {
                        if (bFirstSpanKwikTow) {
                            if (bStainless) {
                                if (!(bTowerOutlet)) {
                                    PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396028", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPN-JNT 1 TWR KT 6 5/8 SS EII
                                }
                                else {
                                    PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396029", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPN-JNT 1 TWR KT W/CG6-5/8 SS EII
                                }
                            }
                            else {
                                if (!(bTowerOutlet)) {
                                    PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396598", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN JOINT1ST TKT E2665G W/CPG
                                }
                                else {
                                    PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396733", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // JOINT SPAN W/CPLR E2665G
                                }
                            }
                        }
                        else if (bNextSpanIsDisconnectingSpan) {
                            PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396527", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // JOINT-SPAN-DISC-EII
                        }
                        else if (b90WrapAroundSpan) {
                            PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396533", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // JOINT OPT-90 DEG PVT TWR
                        }
                        else if (bStainless) {
                            if (!(bTowerOutlet)) {
                                PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396026SS", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN JOINT 6-5/8 SS EII
                            }
                            else {
                                PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396027", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPN JNT W/CPG-6 5/8 SS EII
                            }
                        }
                        else {
                            switch (eNextSpanType) {
                                case SpanTypes.A60G:
                                case SpanTypes.AlumIV:
                                    if (this.bFlangedSide && RigidSpan > currentSpanNumber) {
                                        if (!(bTowerOutlet)) {
                                            PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396234", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // JOINT-2660 HOOK-E660 RCVR
                                        }
                                        else {
                                            PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396279", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // JNT 2660 HOOK-E660 RCVR W/CPLG
                                        }
                                    }
                                    else {
                                        if (!(bTowerOutlet)) {
                                            PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396599", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN JNT E2665G REC/A4-A60G HK
                                        }
                                        else {
                                            PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396274", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // JOINT-2665G RCVR W/CPLG-A4 HK
                                        }
                                    }
                                    break;
                                default:
                                    if (!(bTowerOutlet)) {
                                        PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396596", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // JOINT SPAN E2665G
                                    }
                                    else {
                                        PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396733", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // JOINT SPAN W/CPLR E2665G
                                    }
                                    break;
                            }
                        }
                    }
                    break;
                case SpanTypes.A80G:
                    if (currentSpanNumber < LastSpanNumber) {
                        switch (eNextSpanType) {
                            case SpanTypes.A80G:
                                if (bFirstSpanKwikTow) {
                                    if (!(bTowerOutlet)) {
                                        PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "397028", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN JOINT-#1 TWR KT-A80-G
                                    }
                                    else {
                                        PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "397031", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPN-JT 1-TWR KT W/CPG A80-G
                                    }
                                }
                                else {
                                    if (!(bTowerOutlet)) {
                                        PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "397026", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN JOINT-A80-G
                                    }
                                    else {
                                        PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "397030", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPN-JNT W/CPG A80-G
                                    }
                                }
                                break;
                            case SpanTypes.A60G:
                                if (bFirstSpanKwikTow) {
                                    if (!(bTowerOutlet)) {
                                        PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "397029", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPN-JNT 1- TWR KT A80G/A60G
                                    }
                                    else {
                                        PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "397033", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SP-JT 1-TWR KT W/CP A80G/A60G
                                    }
                                }
                                else {
                                    if (this.bFlangedSide && RigidSpan > currentSpanNumber) {
                                        if (!(bTowerOutlet)) {
                                            PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396233", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // JOINT TRANS-A80G/A60G-MAXI
                                        }
                                        else {
                                            PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396248", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // JOINT-A80G/A60G-MAXI W/CPLG
                                        }
                                    }
                                    else {
                                        if (!(bTowerOutlet)) {
                                            PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "397027", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN JOINT-TRANSITION-A80-G
                                        }
                                        else {
                                            PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "397032", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPN-JNT W/CPG A80-G/A60-G
                                        }
                                    }
                                }
                                break;
                            default:
                                if (bFirstSpanKwikTow) {
                                    if (!(bTowerOutlet)) {
                                        PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "397029", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPN-JNT 1- TWR KT A80G/A60G
                                    }
                                    else {
                                        PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "397033", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SP-JT 1-TWR KT W/CP A80G/A60G
                                    }
                                }
                                else {
                                    if (this.bFlangedSide && RigidSpan > currentSpanNumber) {
                                        if (!(bTowerOutlet)) {
                                            PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396232", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // JOINT TRANS-A80G/ALUM 4-MAXI
                                        }
                                        else {
                                            PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396247", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // JOINT-A80G/A4-MAXI W/CPLG
                                        }
                                    }
                                    else {
                                        if (!(bTowerOutlet)) {
                                            PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "397027", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN JOINT-TRANSITION-A80-G
                                        }
                                        else {
                                            PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "397032", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPN-JNT W/CPG A80-G/A60-G
                                        }
                                    }
                                }
                                break;
                        }
                    }
                    break;
                case SpanTypes.A60G:
                    if (currentSpanNumber === LastSpanNumber) {
                        if (!((bDoubleEndFeed || bSAC))) {
                            if (!(bTowerOutlet)) {
                                PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396827", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // LAST TOWER TOP OPTION-A60G
                            }
                            else {
                                PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396830", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // LAST TWR TOP W/CPG A60G
                            }
                        }
                    }
                    else {
                        if (bFirstSpanKwikTow) {
                            if (!(bTowerOutlet)) {
                                PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396828", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN JOINT-#1 TWR KT-A60G
                            }
                            else {
                                PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396831", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPN-JNT 1-TWR KT W/CPG A60G
                            }
                        }
                        else {
                            if (!(bTowerOutlet)) {
                                PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396826", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN JOINT-A60G
                            }
                            else {
                                PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396829", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPN-JNT W/CPG A60G
                            }
                        }
                    }
                    break;
                case SpanTypes.AlumIV:
                    if (currentSpanNumber === LastSpanNumber) {
                        if (!((bDoubleEndFeed || bSAC))) {
                            if (!(bTowerOutlet)) {
                                PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396727", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // LAST TOWER TOP OPTION-ALUMI 4
                            }
                            else {
                                PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396731", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // LAST TWR TOP AL W/CPLG
                            }
                        }
                    }
                    else {
                        if (bFirstSpanKwikTow) {
                            if (!(bTowerOutlet)) {
                                PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396728", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN JOINT-FIRST TWR KT-ALUM 4
                            }
                            else {
                                PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396732", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPN-JT 1-TWR KT W/CPG A4
                            }
                        }
                        else {
                            if (!(bTowerOutlet)) {
                                PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396726", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN JOINT-ALUMI 4
                            }
                            else {
                                PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396730", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPN-JNT W/CPG A4
                            }
                        }
                    }
                    break;
                case SpanTypes.S2085G:
                    if (currentSpanNumber < LastSpanNumber) {
                        switch (eNextSpanType) {
                            case SpanTypes.S2085G:
                                if (!(bTowerOutlet)) {
                                    PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "397106SS", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN JOINT-S2085G
                                }
                                else {
                                    PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396219", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN-118' 30SP E2085
                                }
                                break;
                            case SpanTypes.S2065G:
                                if (this.bFlangedSide && RigidSpan > currentSpanNumber) {
                                    if (!(bTowerOutlet)) {
                                        PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "397109SS", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // JOINT-MAXI-S2085G/S2065G
                                    }
                                    else {
                                        PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "397111SS", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPN-JNT S65G/S85G W/CPG
                                    }
                                }
                                else {
                                    if (!(bTowerOutlet)) {
                                        PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "397108SS", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // JOINT-SPAN-TRANS-S2085/S2065
                                    }
                                    else {
                                        PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396242SS", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPN-JNT S85G/S65G W/CPG
                                    }
                                }
                                break;
                            case SpanTypes.A80G:
                                if (this.bFlangedSide && RigidSpan > currentSpanNumber) {
                                    if (!(bTowerOutlet)) {
                                        PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "397112SS", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // TRANS HOOK JNT ASY-S65PL/S85G
                                    }
                                    else {
                                        PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "397113SS", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // JOINT-MAXI-S2085/S2065PL
                                    }
                                }
                                else {
                                    PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396282", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // JNT-S2085 HOOK-A80G RCVR
                                }
                                break;
                        }
                    }
                    break;
                case SpanTypes.S2065G:
                    if (currentSpanNumber === LastSpanNumber) {
                        if (!((bDoubleEndFeed || bSAC))) {
                            if (!(bTowerOutlet)) {
                                PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396025SS", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // LAST TOWER TOP 6-5/8 SS EII
                            }
                            else {
                                PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396223", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // LAST TWR W/CPG SS EII
                            }
                        }
                    }
                    else {
                        switch (eNextSpanType) {
                            case SpanTypes.A60G:
                            case SpanTypes.AlumIV:
                                if (this.bFlangedSide && RigidSpan > currentSpanNumber) {
                                    if (!(bTowerOutlet)) {
                                        PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396280", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // JOINT-S2065 HOOK-S65 RECEIVER
                                    }
                                    else {
                                        PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396281", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // JNT-S2065 HOOK-E65 RCVR W/CPLG
                                    }
                                }
                                else {
                                    if (!(bTowerOutlet)) {
                                        PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396237SS", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // JOINT-S2065G RCVR-A60G HOOK
                                    }
                                    else {
                                        PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396276", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // JOINT-SPAN E2085 DBL ROD
                                    }
                                }
                                break;
                            default:
                                if (bFirstSpanKwikTow) {
                                    if (!(bTowerOutlet)) {
                                        PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396328SS", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN JOINT-1ST TWR-KWIKTW-S65G
                                    }
                                    else {
                                        PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396220", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPN-JNT 1-TWR KT W/CPG S65G
                                    }
                                }
                                else {
                                    if (!(bTowerOutlet)) {
                                        PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396026SS", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN JOINT 6-5/8 SS EII
                                    }
                                    else {
                                        PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396027", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPN JNT W/CPG-6 5/8 SS EII
                                    }
                                }
                                break;
                        }
                    }
                    break;
                case SpanTypes.PL2065G:
                    if (currentSpanNumber === LastSpanNumber) {
                        if (!((bDoubleEndFeed || bSAC))) {
                            PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396551", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // LAST TWR TOP PL2065
                        }
                    }
                    else {
                        PO.InsertPart(schema.groups.SpanAssemblies_SpanJointAssembly.groupId, "396550", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN JOINT PL2065
                    }
                    break;
            }
            if (bLPDrainKit) {
                PO.InsertPart(schema.groups.SpanAssemblies_LPDrainKit.groupId, "351985", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // LOW PRESS DRAIN DIVERTING KIT
            }
            if (bWatermanDrain) {
                PO.InsertPart(schema.groups.SpanAssemblies_LPDrainKit.groupId, "351973", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // DRAIN LOW PRESS 1-1/2" PER TWR
            }
            let bLongSystemAlign: boolean = false;
            if (sys.SpanProperties.LongSystemAlignment || bAgriTrac) {
                switch (sys.SystemProperties.SystemType) {
                    case SystemTypes.CenterPivot:
                    case SystemTypes.KwikTow:
                        if (currentSpanNumber > 1) {
                            bLongSystemAlign = true;
                        }
                        switch (sp.SpanType) {
                            case SpanTypes.AlumIV:
                            case SpanTypes.A60G:
                                bLongSystemAlign = true;
                                break;
                        }
                        break;
                    case SystemTypes.CanalFeedMaxigator:
                    case SystemTypes.HoseFeedMaxigator:
                        switch (this.SectionID) {
                            case SectionIDs.RightSpansID:
                                if (currentSpanNumber !== RigidSpan) {
                                    bLongSystemAlign = true;
                                }
                                break;
                            case SectionIDs.LeftSpansID:
                                bLongSystemAlign = true;
                                break;
                        }
                        break;
                }
            }
            if (bLongSystemAlign) {
                switch (sp.SpanType) {
                    case SpanTypes.E2060:
                    case SpanTypes.E2660:
                    case SpanTypes.AlumIV:
                    case SpanTypes.A60G:
                        if (sprop.StainlessSteelCable) {
                            PO.InsertPart(schema.groups.SpanAssemblies_LongSystemAlignment.groupId, "396342SS", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // LONG SYSTEM ALIGN-SS 6" SPAN
                        }
                        else {
                            PO.InsertPart(schema.groups.SpanAssemblies_LongSystemAlignment.groupId, "396342", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // LONG SYSTEM ALIGNMENT-6" SPAN
                        }
                        switch (SpanLength) {
                            case 120:
                            case 137:
                            case 140:
                                PO.InsertPart(schema.groups.SpanAssemblies_FlangeBracket.groupId, "396334", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // FLANGE BRACKET ASY-6"-LSA
                                break;
                        }
                        break;
                    case SpanTypes.E2065:
                    case SpanTypes.E2665:
                    case SpanTypes.S2065G:
                    case SpanTypes.PL2065G:
                        if (sprop.StainlessSteelCable) {
                            PO.InsertPart(schema.groups.SpanAssemblies_LongSystemAlignment.groupId, "396343SS", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // LONG SYSTEM ALIGN-SS 6-5/8"
                        }
                        else {
                            PO.InsertPart(schema.groups.SpanAssemblies_LongSystemAlignment.groupId, "396343", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // LONG SYSTEM ALIGN 6-5/8" SPAN
                        }
                        switch (SpanLength) {
                            case 120:
                            case 137:
                            case 140:
                                PO.InsertPart(schema.groups.SpanAssemblies_FlangeBracket.groupId, "396335", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // FLANGE BRACKET ASY 6-5/8" LSA
                                break;
                        }
                        break;
                    case SpanTypes.A80G:
                        if (sprop.StainlessSteelCable) {
                            PO.InsertPart(schema.groups.SpanAssemblies_LongSystemAlignment.groupId, "396344SS", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // LONG SYSTEM ALIGNMENT-SS 8"
                        }
                        else {
                            PO.InsertPart(schema.groups.SpanAssemblies_LongSystemAlignment.groupId, "396344", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // LONG SYSTEM ALIGNMENT-8" SPAN
                        }
                        switch (SpanLength) {
                            case 120:
                            case 137:
                            case 140:
                                PO.InsertPart(schema.groups.SpanAssemblies_FlangeBracket.groupId, "396336", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // FLANGE BRACKET ASSEMBLY-8"-LSA
                                break;
                        }
                        break;
                    case SpanTypes.E2085:
                    case SpanTypes.S2085G:
                    case SpanTypes.PL2085G:
                        if (sprop.StainlessSteelCable) {
                            PO.InsertPart(schema.groups.SpanAssemblies_LongSystemAlignment.groupId, "396346SS", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // LONG SYSTEM ALIGN-SS 8-5/8"
                        }
                        else {
                            PO.InsertPart(schema.groups.SpanAssemblies_LongSystemAlignment.groupId, "396346", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // LONG SYSTEM ALIGN 8-5/8" SPANS
                        }
                        switch (SpanLength) {
                            case 120:
                            case 137:
                            case 140:
                                PO.InsertPart(schema.groups.SpanAssemblies_FlangeBracket.groupId, "396339", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // FLANGE BRACKET ASM-8-5/8" -LSA
                                break;
                        }
                        break;
                    case SpanTypes.E2100:
                        if (sprop.StainlessSteelCable) {
                            PO.InsertPart(schema.groups.SpanAssemblies_LongSystemAlignment.groupId, "396345SS", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // LONG SYSTEM ALIGNMENT-SS 10"
                        }
                        else {
                            PO.InsertPart(schema.groups.SpanAssemblies_LongSystemAlignment.groupId, "396345", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // LONG SYSTEM ALIGNMENT-10" SPAN
                        }
                        switch (SpanLength) {
                            case 120:
                            case 137:
                            case 140:
                                PO.InsertPart(schema.groups.SpanAssemblies_FlangeBracket.groupId, "396337", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // FLANGE BRACKET ASSY-10"-LSA
                                break;
                        }
                        break;
                    case SpanTypes.E2045:
                        if (sprop.StainlessSteelCable) {
                            PO.InsertPart(schema.groups.SpanAssemblies_LongSystemAlignment.groupId, "396130SS", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // LONG SYSTEM ALIGN-SS 4-1/2"
                        }
                        else {
                            PO.InsertPart(schema.groups.SpanAssemblies_LongSystemAlignment.groupId, "396130", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // LONG SYSTEM ALIGN 4-1/2" SPAN
                        }
                        switch (SpanLength) {
                            case 120:
                            case 140:
                                PO.InsertPart(schema.groups.SpanAssemblies_FlangeBracket.groupId, "396334", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // FLANGE BRACKET ASY-6"-LSA
                                break;
                        }
                        break;
                }
            }
            if (!(bEDMP)) {
                switch (sp.SpanType) {
                    case SpanTypes.A60G:
                    case SpanTypes.A80G:
                    case SpanTypes.AlumIV:
                        break;
                    case SpanTypes.E2045:
                        switch (SpanLength) {
                            case 118:
                                if (bConduitClipWOSpring) {
                                    if (bTheftDeterrentClip) {
                                        PO.InsertPart(schema.groups.SpanAssemblies_ConduitClips.groupId, "397641", 1); // CONDUIT TDC COUPLERS 118'-57"
                                    }
                                    else {
                                        PO.InsertPart(schema.groups.SpanAssemblies_ConduitClips.groupId, "397273", 1); // CONDUIT CLP COUPLERS 118'-57"
                                    }
                                }
                                else {
                                    if (bTheftDeterrentClip) {
                                        if (sp.Spacing === 114) {
                                            PO.InsertPart(schema.groups.SpanAssemblies_ConduitClips.groupId, "397667", 1); // CONDUIT TDC COUPLERS 118'-114"
                                        }
                                        else {
                                            PO.InsertPart(schema.groups.SpanAssemblies_ConduitClips.groupId, "397632", 1); // CONDUIT TDC PKG 118'-57 E2045
                                        }
                                    }
                                    else {
                                        if (sp.Spacing === 114) {
                                            PO.InsertPart(schema.groups.SpanAssemblies_ConduitClips.groupId, "397256", 1); // CONDUIT CLP PKG 118'-114 E2045
                                        }
                                        else {
                                            PO.InsertPart(schema.groups.SpanAssemblies_ConduitClips.groupId, "397251", 1); // CONDUIT CLIP PKG 118'-57 E2045
                                        }
                                    }
                                }
                                break;
                            case 137:
                                if (bConduitClipWOSpring) {
                                    if (bTheftDeterrentClip) {
                                        PO.InsertPart(schema.groups.SpanAssemblies_ConduitClips.groupId, "397642", 1); // CONDUIT TDC COUPLERS 137'-57"
                                    }
                                    else {
                                        PO.InsertPart(schema.groups.SpanAssemblies_ConduitClips.groupId, "397274", 1); // CONDUIT CLP COUPLERS 137'-57"
                                    }
                                }
                                else {
                                    if (bTheftDeterrentClip) {
                                        if (sp.Spacing === 114) {
                                            PO.InsertPart(schema.groups.SpanAssemblies_ConduitClips.groupId, "397668", 1); // CONDUIT TDC COUPLERS 137'-114"
                                        }
                                        else {
                                            PO.InsertPart(schema.groups.SpanAssemblies_ConduitClips.groupId, "397633", 1); // CONDUIT TDC PKG 137'-57 E2045
                                        }
                                    }
                                    else {
                                        if (sp.Spacing === 114) {
                                            PO.InsertPart(schema.groups.SpanAssemblies_ConduitClips.groupId, "397257", 1); // CONDUIT CLP PKG 137'-114 E2045
                                        }
                                        else {
                                            PO.InsertPart(schema.groups.SpanAssemblies_ConduitClips.groupId, "397252", 1); // CONDUIT CLIP PKG 137'-57 E2045
                                        }
                                    }
                                }
                                break;
                            case 156:
                                if (bConduitClipWOSpring) {
                                    if (bTheftDeterrentClip) {
                                        PO.InsertPart(schema.groups.SpanAssemblies_ConduitClips.groupId, "397643", 1); // CONDUIT TDC COUPLERS 156'-57"
                                    }
                                    else {
                                        PO.InsertPart(schema.groups.SpanAssemblies_ConduitClips.groupId, "397275", 1); // CONDUIT CLP COUPLERS 156'-57"
                                    }
                                }
                                else {
                                    if (bTheftDeterrentClip) {
                                        if (sp.Spacing === 114) {
                                            PO.InsertPart(schema.groups.SpanAssemblies_ConduitClips.groupId, "397669", 1); // CONDUIT TDC COUPLERS 156'-114"
                                        }
                                        else {
                                            PO.InsertPart(schema.groups.SpanAssemblies_ConduitClips.groupId, "397634", 1); // CONDUIT TDC PKG 156'-57 E2045
                                        }
                                    }
                                    else {
                                        if (sp.Spacing === 114) {
                                            PO.InsertPart(schema.groups.SpanAssemblies_ConduitClips.groupId, "397258", 1); // CONDUIT CLP PKG 156'-114 E2045
                                        }
                                        else {
                                            PO.InsertPart(schema.groups.SpanAssemblies_ConduitClips.groupId, "397253", 1); // CONDUIT CLIP PKG 156'-57 E2045
                                        }
                                    }
                                }
                                break;
                            case 175:
                                if (bConduitClipWOSpring) {
                                    if (bTheftDeterrentClip) {
                                        PO.InsertPart(schema.groups.SpanAssemblies_ConduitClips.groupId, "397645", 1); // CONDUIT TDC COUPLERS 175'-57"
                                    }
                                    else {
                                        PO.InsertPart(schema.groups.SpanAssemblies_ConduitClips.groupId, "397277", 1); // CONDUIT CLP COUPLERS 175'-57"
                                    }
                                }
                                else {
                                    if (bTheftDeterrentClip) {
                                        if (sp.Spacing === 114) {
                                            PO.InsertPart(schema.groups.SpanAssemblies_ConduitClips.groupId, "397670", 1); // CONDUIT TDC COUPLERS 175'-114"
                                        }
                                        else {
                                            PO.InsertPart(schema.groups.SpanAssemblies_ConduitClips.groupId, "397635", 1); // CONDUIT TDC PKG 175'-57 E2045
                                        }
                                    }
                                    else {
                                        if (sp.Spacing === 114) {
                                            PO.InsertPart(schema.groups.SpanAssemblies_ConduitClips.groupId, "397259", 1); // CONDUIT CLP PKG 175'-114 E2045
                                        }
                                        else {
                                            PO.InsertPart(schema.groups.SpanAssemblies_ConduitClips.groupId, "397254", 1); // CONDUIT CLIP PKG 175'-57 E2045
                                        }
                                    }
                                }
                                break;
                            case 194:
                                if (bConduitClipWOSpring) {
                                    if (bTheftDeterrentClip) {
                                        PO.InsertPart(schema.groups.SpanAssemblies_ConduitClips.groupId, "397646", 1); // CONDUIT TDC COUPLERS 194'-57"
                                    }
                                    else {
                                        PO.InsertPart(schema.groups.SpanAssemblies_ConduitClips.groupId, "397278", 1); // CONDUIT CLP COUPLERS 194'-57"
                                    }
                                }
                                else {
                                    if (bTheftDeterrentClip) {
                                        if (sp.Spacing === 114) {
                                            PO.InsertPart(schema.groups.SpanAssemblies_ConduitClips.groupId, "397671", 1); // CONDUIT TDC COUPLERS 194'-114"
                                        }
                                        else {
                                            PO.InsertPart(schema.groups.SpanAssemblies_ConduitClips.groupId, "397636", 1); // CONDUIT TDC PKG 194'-57 E2045
                                        }
                                    }
                                    else {
                                        if (sp.Spacing === 114) {
                                            PO.InsertPart(schema.groups.SpanAssemblies_ConduitClips.groupId, "397260", 1); // CONDUIT CLP PKG 194'-114 E2045
                                        }
                                        else {
                                            PO.InsertPart(schema.groups.SpanAssemblies_ConduitClips.groupId, "397255", 1); // CONDUIT CLIP PKG 194'-57 E2045
                                        }
                                    }
                                }
                                break;
                        }
                        break;
                    default:
                        switch (SpanLength) {
                            case 80:
                                switch (sp.Spacing) {
                                    case 40:
                                        if (bTheftDeterrentClip) {
                                            PO.InsertPart(schema.groups.SpanAssemblies_ConduitClips.groupId, "397600", 1); // CONDUIT TDC PKG 80'-LEPA
                                        }
                                        else {
                                            PO.InsertPart(schema.groups.SpanAssemblies_ConduitClips.groupId, "397200", 1); // CONDUIT CLIP CPLRS 80'-LEPA
                                        }
                                        break;
                                    case 57:
                                        if (bConduitClipWOSpring) {
                                            if (bTheftDeterrentClip) {
                                                PO.InsertPart(schema.groups.SpanAssemblies_ConduitClips.groupId, "397639", 1); // CONDUIT TDC CPLRS 80'-57"
                                            }
                                            else {
                                                PO.InsertPart(schema.groups.SpanAssemblies_ConduitClips.groupId, "397271", 1); // CONDUIT CLP COUPLERS 80'-57"
                                            }
                                        }
                                        else {
                                            if (bTheftDeterrentClip) {
                                                PO.InsertPart(schema.groups.SpanAssemblies_ConduitClips.groupId, "397601", 1); // CONDUIT TDC PKG 80'-57"
                                            }
                                            else {
                                                if (sp.SpanType === SpanTypes.PL2065G) {
                                                    PO.InsertPart(schema.groups.SpanAssemblies_ConduitClips.groupId, "397202", 1); // CONDUIT CLIP PKG 80' 6&6-5/8
                                                }
                                                else {
                                                    PO.InsertPart(schema.groups.SpanAssemblies_ConduitClips.groupId, "397201", 1); // CONDUIT CLIP PKG 80'-57"
                                                }
                                            }
                                        }
                                        break;
                                }
                                break;
                            case 99:
                                switch (sp.Spacing) {
                                    case 40:
                                        if (bTheftDeterrentClip) {
                                            PO.InsertPart(schema.groups.SpanAssemblies_ConduitClips.groupId, "397602", 1); // CONDUIT TDC PKG 99'-LEPA
                                        }
                                        else {
                                            PO.InsertPart(schema.groups.SpanAssemblies_ConduitClips.groupId, "397204", 1); // CONDUIT CLIP CPLRS 99'-LEPA
                                        }
                                        break;
                                    case 57:
                                        if (bConduitClipWOSpring) {
                                            if (bTheftDeterrentClip) {
                                                PO.InsertPart(schema.groups.SpanAssemblies_ConduitClips.groupId, "397640", 1); // CONDUIT TDC COUPLERS 99'-57"
                                            }
                                            else {
                                                PO.InsertPart(schema.groups.SpanAssemblies_ConduitClips.groupId, "397272", 1); // CONDUIT CLP COUPLERS 99'-57"
                                            }
                                        }
                                        else {
                                            if (bTheftDeterrentClip) {
                                                PO.InsertPart(schema.groups.SpanAssemblies_ConduitClips.groupId, "397603", 1); // CONDUIT TDC PKG 99'-57"
                                            }
                                            else {
                                                if (sp.SpanType === SpanTypes.PL2065G) {
                                                    PO.InsertPart(schema.groups.SpanAssemblies_ConduitClips.groupId, "397206", 1); // CONDUIT CLIP PKG 99' 6&6-5/8
                                                }
                                                else {
                                                    PO.InsertPart(schema.groups.SpanAssemblies_ConduitClips.groupId, "397205", 1); // CONDUIT CLIP PKG 99'-57"
                                                }
                                            }
                                        }
                                        break;
                                }
                                break;
                            case 118:
                            case 120:
                                switch (sp.Spacing) {
                                    case 40:
                                        if (bTheftDeterrentClip) {
                                            PO.InsertPart(schema.groups.SpanAssemblies_ConduitClips.groupId, "397604", 1); // CONDUIT TDC PKG 118'-LEPA
                                        }
                                        else {
                                            PO.InsertPart(schema.groups.SpanAssemblies_ConduitClips.groupId, "397208", 1); // CONDUIT CLIP CPLRS 118'-LEPA
                                        }
                                        break;
                                    case 57:
                                    case 114:
                                        if (bConduitClipWOSpring) {
                                            if (bTheftDeterrentClip) {
                                                PO.InsertPart(schema.groups.SpanAssemblies_ConduitClips.groupId, "397641", 1); // CONDUIT TDC COUPLERS 118'-57"
                                            }
                                            else {
                                                PO.InsertPart(schema.groups.SpanAssemblies_ConduitClips.groupId, "397273", 1); // CONDUIT CLP COUPLERS 118'-57"
                                            }
                                        }
                                        else {
                                            if (bTheftDeterrentClip) {
                                                PO.InsertPart(schema.groups.SpanAssemblies_ConduitClips.groupId, "397605", 1); // CONDUIT TDC PKG 118'-57"
                                            }
                                            else {
                                                if (sp.SpanType === SpanTypes.PL2085G) {
                                                    PO.InsertPart(schema.groups.SpanAssemblies_ConduitClips.groupId, "397309", 1); // CONDUIT CLIP PKG 80' 8-5/8"
                                                }
                                                else if (sp.SpanType === SpanTypes.PL2065G) {
                                                    PO.InsertPart(schema.groups.SpanAssemblies_ConduitClips.groupId, "397210", 1); // CONDUIT CLIP PKG 118' 6&6-5/8
                                                }
                                                else {
                                                    PO.InsertPart(schema.groups.SpanAssemblies_ConduitClips.groupId, "397209", 1); // CONDUIT CLIP PKG 118'-57"
                                                }
                                            }
                                        }
                                        break;
                                }
                                break;
                            case 137:
                            case 140:
                                switch (sp.Spacing) {
                                    case 40:
                                        if (bTheftDeterrentClip) {
                                            PO.InsertPart(schema.groups.SpanAssemblies_ConduitClips.groupId, "397606", 1); // CONDUIT TDC PKG 137'-LEPA
                                        }
                                        else {
                                            PO.InsertPart(schema.groups.SpanAssemblies_ConduitClips.groupId, "397212", 1); // CONDUIT CLIP CPLRS 137'-LEPA
                                        }
                                        break;
                                    case 57:
                                    case 114:
                                        if (bConduitClipWOSpring) {
                                            if (bTheftDeterrentClip) {
                                                PO.InsertPart(schema.groups.SpanAssemblies_ConduitClips.groupId, "397642", 1); // CONDUIT TDC COUPLERS 137'-57"
                                            }
                                            else {
                                                PO.InsertPart(schema.groups.SpanAssemblies_ConduitClips.groupId, "397274", 1); // CONDUIT CLP COUPLERS 137'-57"
                                            }
                                        }
                                        else {
                                            if (bTheftDeterrentClip) {
                                                PO.InsertPart(schema.groups.SpanAssemblies_ConduitClips.groupId, "397607", 1); // CONDUIT TDC PKG 137'-57"
                                            }
                                            else {
                                                if (sp.SpanType === SpanTypes.PL2085G) {
                                                    PO.InsertPart(schema.groups.SpanAssemblies_ConduitClips.groupId, "397310", 1); // CONDUIT CLIP PKG 137' 8-5/8"
                                                }
                                                else if (sp.SpanType === SpanTypes.PL2065G) {
                                                    PO.InsertPart(schema.groups.SpanAssemblies_ConduitClips.groupId, "397214", 1); // CONDUIT CLIP PKG 137' 6&6-5/8
                                                }
                                                else {
                                                    PO.InsertPart(schema.groups.SpanAssemblies_ConduitClips.groupId, "397213", 1); // CONDUIT CLIP PKG 137'-57"
                                                }
                                            }
                                        }
                                        break;
                                }
                                break;
                            case 156:
                                switch (sp.Spacing) {
                                    case 40:
                                        if (bTheftDeterrentClip) {
                                            PO.InsertPart(schema.groups.SpanAssemblies_ConduitClips.groupId, "397608", 1); // CONDUIT TDC PKG 156'-LEPA
                                        }
                                        else {
                                            PO.InsertPart(schema.groups.SpanAssemblies_ConduitClips.groupId, "397216", 1); // CONDUIT CLIP CPLRS 156'-LEPA
                                        }
                                        break;
                                    case 57:
                                    case 114:
                                        if (bConduitClipWOSpring) {
                                            if (bTheftDeterrentClip) {
                                                PO.InsertPart(schema.groups.SpanAssemblies_ConduitClips.groupId, "397643", 1); // CONDUIT TDC COUPLERS 156'-57"
                                            }
                                            else {
                                                PO.InsertPart(schema.groups.SpanAssemblies_ConduitClips.groupId, "397275", 1); // CONDUIT CLP COUPLERS 156'-57"
                                            }
                                        }
                                        else {
                                            if (bTheftDeterrentClip) {
                                                PO.InsertPart(schema.groups.SpanAssemblies_ConduitClips.groupId, "397609", 1); // CONDUIT TDC PKG 156'-57"
                                            }
                                            else {
                                                if (sp.SpanType === SpanTypes.PL2085G) {
                                                    PO.InsertPart(schema.groups.SpanAssemblies_ConduitClips.groupId, "397311", 1); // CONDUIT CLIP PKG 156' 8-5/8"
                                                }
                                                else if (sp.SpanType === SpanTypes.PL2065G) {
                                                    PO.InsertPart(schema.groups.SpanAssemblies_ConduitClips.groupId, "397218", 1); // CONDUIT CLIP PKG 156' 6&6-5/8
                                                }
                                                else {
                                                    PO.InsertPart(schema.groups.SpanAssemblies_ConduitClips.groupId, "397217", 1); // CONDUIT CLIP PKG 156'-57"
                                                }
                                            }
                                        }
                                        break;
                                }
                                break;
                            case 160:
                                switch (sp.Spacing) {
                                    case 40:
                                        if (bTheftDeterrentClip) {
                                            PO.InsertPart(schema.groups.SpanAssemblies_ConduitClips.groupId, "397610", 1); // CONDUIT TDC PKG 160'-LEPA
                                        }
                                        else {
                                            PO.InsertPart(schema.groups.SpanAssemblies_ConduitClips.groupId, "397220", 1); // CONDUIT CLIP CPLRS 160'-LEPA
                                        }
                                        break;
                                    case 57:
                                        if (bConduitClipWOSpring) {
                                            if (bTheftDeterrentClip) {
                                                PO.InsertPart(schema.groups.SpanAssemblies_ConduitClips.groupId, "397644", 1); // CONDUIT TDC COUPLERS 160'-57"
                                            }
                                            else {
                                                PO.InsertPart(schema.groups.SpanAssemblies_ConduitClips.groupId, "397276", 1); // CONDUIT CLP COUPLERS 160'-57"
                                            }
                                        }
                                        else {
                                            if (bTheftDeterrentClip) {
                                                PO.InsertPart(schema.groups.SpanAssemblies_ConduitClips.groupId, "397611", 1); // CONDUIT TDC PKG 160'-57"
                                            }
                                            else {
                                                if (sp.SpanType === SpanTypes.PL2085G) {
                                                    PO.InsertPart(schema.groups.SpanAssemblies_ConduitClips.groupId, "397312", 1); // CONDUIT CLIP PKG 160' 8-5/8"
                                                }
                                                else if (sp.SpanType === SpanTypes.PL2065G) {
                                                    PO.InsertPart(schema.groups.SpanAssemblies_ConduitClips.groupId, "397261", 1); // CONDUIT CLIP PKG 160' 6&6-5/8
                                                }
                                                else {
                                                    PO.InsertPart(schema.groups.SpanAssemblies_ConduitClips.groupId, "397221", 1); // CONDUIT CLIP PKG 160'-57"
                                                }
                                            }
                                        }
                                        break;
                                }
                                break;
                            case 175:
                                switch (sp.Spacing) {
                                    case 40:
                                        if (bTheftDeterrentClip) {
                                            PO.InsertPart(schema.groups.SpanAssemblies_ConduitClips.groupId, "397612", 1); // CONDUIT TDC PKG 175'-LEPA
                                        }
                                        else {
                                            PO.InsertPart(schema.groups.SpanAssemblies_ConduitClips.groupId, "397223", 1); // CONDUIT CLIP CPLRS 175'-LEPA
                                        }
                                        break;
                                    case 57:
                                    case 114:
                                        if (bConduitClipWOSpring) {
                                            if (bTheftDeterrentClip) {
                                                PO.InsertPart(schema.groups.SpanAssemblies_ConduitClips.groupId, "397645", 1); // CONDUIT TDC COUPLERS 175'-57"
                                            }
                                            else {
                                                PO.InsertPart(schema.groups.SpanAssemblies_ConduitClips.groupId, "397277", 1); // CONDUIT CLP COUPLERS 175'-57"
                                            }
                                        }
                                        else {
                                            if (bTheftDeterrentClip) {
                                                PO.InsertPart(schema.groups.SpanAssemblies_ConduitClips.groupId, "397613", 1); // CONDUIT TDC PKG 175'-57"
                                            }
                                            else {
                                                if (sp.SpanType === SpanTypes.PL2065G) {
                                                    PO.InsertPart(schema.groups.SpanAssemblies_ConduitClips.groupId, "397225", 1); // CONDUIT CLIP PKG 175' 6&6-5/8
                                                }
                                                else {
                                                    PO.InsertPart(schema.groups.SpanAssemblies_ConduitClips.groupId, "397224", 1); // CONDUIT CLIP PKG 175'-57"
                                                }
                                            }
                                        }
                                        break;
                                }
                                break;
                            case 180:
                                switch (sp.Spacing) {
                                    case 40:
                                        if (bTheftDeterrentClip) {
                                            PO.InsertPart(schema.groups.SpanAssemblies_ConduitClips.groupId, "397614", 1); // CONDUIT TDC PKG 180'-LEPA
                                        }
                                        else {
                                            PO.InsertPart(schema.groups.SpanAssemblies_ConduitClips.groupId, "397227", 1); // CONDUIT CLIP CPLRS 180'-LEPA
                                        }
                                        break;
                                }
                                break;
                            case 186.7:
                                switch (sp.Spacing) {
                                    case 40:
                                        if (bTheftDeterrentClip) {
                                            PO.InsertPart(schema.groups.SpanAssemblies_ConduitClips.groupId, "397615", 1); // CONDUIT TDC PKG 186.5'-LEPA
                                        }
                                        else {
                                            PO.InsertPart(schema.groups.SpanAssemblies_ConduitClips.groupId, "397228", 1); // CONDUIT CLIP CPLRS 186.5'-LEPA
                                        }
                                        break;
                                }
                                break;
                            case 194:
                                switch (sp.Spacing) {
                                    case 40:
                                        if (bTheftDeterrentClip) {
                                            PO.InsertPart(schema.groups.SpanAssemblies_ConduitClips.groupId, "397616", 1); // CONDUIT TDC CPLRS 194'-LEPA
                                        }
                                        else {
                                            PO.InsertPart(schema.groups.SpanAssemblies_ConduitClips.groupId, "397229", 1); // CONDUIT CLIP CPLRS 194'-LEPA
                                        }
                                        break;
                                    case 57:
                                    case 114:
                                        if (bConduitClipWOSpring) {
                                            if (bTheftDeterrentClip) {
                                                PO.InsertPart(schema.groups.SpanAssemblies_ConduitClips.groupId, "397646", 1); // CONDUIT TDC COUPLERS 194'-57"
                                            }
                                            else {
                                                PO.InsertPart(schema.groups.SpanAssemblies_ConduitClips.groupId, "397278", 1); // CONDUIT CLP COUPLERS 194'-57"
                                            }
                                        }
                                        else {
                                            if (bTheftDeterrentClip) {
                                                PO.InsertPart(schema.groups.SpanAssemblies_ConduitClips.groupId, "397617", 1); // CONDUIT TDC PKG 194'-57"
                                            }
                                            else {
                                                if (sp.SpanType === SpanTypes.PL2065G) {
                                                    PO.InsertPart(schema.groups.SpanAssemblies_ConduitClips.groupId, "397262", 1); // CONDUIT CLIP PKG 194' 6&6-5/8
                                                }
                                                else {
                                                    PO.InsertPart(schema.groups.SpanAssemblies_ConduitClips.groupId, "397230", 1); // CONDUIT CLIP PKG 194'-57"
                                                }
                                            }
                                        }
                                        break;
                                }
                                break;
                            case 204:
                                switch (sp.Spacing) {
                                    case 40:
                                        if (bTheftDeterrentClip) {
                                            PO.InsertPart(schema.groups.SpanAssemblies_ConduitClips.groupId, "397618", 1); // CONDUIT TDC PKG 204'-LEPA
                                        }
                                        else {
                                            PO.InsertPart(schema.groups.SpanAssemblies_ConduitClips.groupId, "397232", 1); // CONDUIT CLIP CPLRS 204'-LEPA
                                        }
                                        break;
                                }
                                break;
                            case 213:
                                switch (sp.Spacing) {
                                    case 40:
                                        if (bTheftDeterrentClip) {
                                            PO.InsertPart(schema.groups.SpanAssemblies_ConduitClips.groupId, "397619", 1); // CONDUIT TDC PKG 213'-LEPA
                                        }
                                        else {
                                            PO.InsertPart(schema.groups.SpanAssemblies_ConduitClips.groupId, "397233", 1); // CONDUIT CLIP CPLRS 213'-LEPA
                                        }
                                        break;
                                    case 57:
                                        if (bConduitClipWOSpring) {
                                            if (bTheftDeterrentClip) {
                                                PO.InsertPart(schema.groups.SpanAssemblies_ConduitClips.groupId, "397647", 1); // CONDUIT TDC COUPLERS 213'-57"
                                            }
                                            else {
                                                PO.InsertPart(schema.groups.SpanAssemblies_ConduitClips.groupId, "397279", 1); // CONDUIT CLP COUPLERS 213'-57"
                                            }
                                        }
                                        else {
                                            if (bTheftDeterrentClip) {
                                                PO.InsertPart(schema.groups.SpanAssemblies_ConduitClips.groupId, "397620", 1); // CONDUIT TDC PKG 213'-57"
                                            }
                                            else {
                                                PO.InsertPart(schema.groups.SpanAssemblies_ConduitClips.groupId, "397234", 1); // CONDUIT CLIP PKG 213'-57"
                                            }
                                        }
                                        break;
                                }
                                break;
                        }
                        break;
                }
            }
            if (sp.Extension && bFirstSpanKwikTow) {
                PO.InsertPart(schema.groups.SpanAssemblies_TowRodExtension.groupId, "392800", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // TOW ROD EXT KIT-4'
            }
            if (sp.Stabilizers) {
                switch (sp.Length) {
                    case 80:
                        PO.InsertPart(schema.groups.SpanAssemblies_SpanStabilizers.groupId, "396583", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN STAB OPT 80FT EII
                        break;
                    case 99:
                    case 118:
                        PO.InsertPart(schema.groups.SpanAssemblies_SpanStabilizers.groupId, "396584", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN STAB OPT 99/118FT EII
                        break;
                    case 137:
                    case 140:
                        PO.InsertPart(schema.groups.SpanAssemblies_SpanStabilizers.groupId, "396586", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN STAB OPT 137FT EII
                        break;
                    case 156:
                        PO.InsertPart(schema.groups.SpanAssemblies_SpanStabilizers.groupId, "396587", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN STAB OPT 156FT EII
                        break;
                    case 160:
                        PO.InsertPart(schema.groups.SpanAssemblies_SpanStabilizers.groupId, "396582", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN STAB OPT 160FT EII
                        break;
                    case 175:
                    case 180:
                        PO.InsertPart(schema.groups.SpanAssemblies_SpanStabilizers.groupId, "396585", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // SPAN STAB OPT 175/180FT EII
                        break;
                }
            }
            sSpanLength = SpanLengthsToFeet(sp.Length);
            if (sp.Extension) {
                sSpanLength += 4;
            }
            if (!(bEDMP)) {
                if (cableGauge.cableGauge === 8 && spanf.CableConductors(this.Quote.System, this.Side, sp) !== idxCableConductors.cc10) {
                    RaiseError(errOther, StringFormat(strLanguageText("sltSpanCableProblem"), spanf.ConductorsRequired(this.Quote.System, this.Side, sp), currentSpanNumber, this.TranslatedKey));
                }
                if (currentSpanNumber === 1) {
                    switch (sys.SystemProperties.SystemType) {
                        case SystemTypes.CenterPivot:
                            if (sys.ControlPanel.CollectorRing === CollectorRingTypes.None) {
                                switch (sys.Circle.CenterPivot.PivotCenterHeight) {
                                    case TowerHeights.LowProfile:
                                        sCableLength = sSpanLength + 11;
                                        break;
                                    case TowerHeights.Standard:
                                        sCableLength = sSpanLength + 15;
                                        break;
                                    case TowerHeights.Sugargator:
                                        sCableLength = sSpanLength + 19;
                                        break;
                                }
                            }
                            else {
                                sCableLength = sSpanLength + 7;
                            }
                            switch (sp.Length) {
                                case 213:
                                case 204:
                                    sCableLength += 0.5;
                                    break;
                            }
                            CableType = CableTypes.FirstSpanPivot10C8G;
                            break;
                        case SystemTypes.KwikTow:
                            sCableLength = sSpanLength + 15.5;
                            CableType = CableTypes.FirstSpanKwikTow10C8G;
                            break;
                        case SystemTypes.CanalFeedMaxigator:
                            switch (sys.Lateral.WaterFeed) {
                                case WaterFeedTypes.CenterFeed:
                                    if (this.bFlangedSide) {
                                        if (Amps > MaxAmpsFor10GWire) {
                                            sCableLength = sSpanLength + 20.5;
                                            if (sys.Lateral.CanalFeed.Cart === CartTypes.Sugargator) {
                                                sCableLength += 4;
                                            }
                                            CableType = CableTypes.FirstSpanMaxiEnd10C8G;
                                        }
                                        else {
                                            sCableLength = sSpanLength + 10;
                                            CableType = CableTypes.FirstSpanCanalCenter10C8G;
                                        }
                                    }
                                    else {
                                        switch (sp.Length) {
                                            case 213:
                                                sCableLength = sSpanLength + 11;
                                                break;
                                            default:
                                                sCableLength = sSpanLength + 6.5;
                                                break;
                                        }
                                        CableType = CableTypes.Standard10C8G;
                                    }
                                    break;
                                case WaterFeedTypes.EndFeed:
                                    sCableLength = sSpanLength + 20.5;
                                    if (sys.Lateral.CanalFeed.Cart === CartTypes.Sugargator) {
                                        sCableLength += 4;
                                    }
                                    CableType = CableTypes.FirstSpanMaxiEnd10C8G;
                                    break;
                            }
                            break;
                        case SystemTypes.HoseFeedMaxigator:
                            switch (sys.Lateral.WaterFeed) {
                                case WaterFeedTypes.CenterFeed:
                                    if (Amps > MaxAmpsFor10GWire && this.bFlangedSide) {
                                        switch (sys.Lateral.HoseFeed.HoseFeedType) {
                                            case HoseFeedTypes.Sugargator:
                                                sCableLength = sSpanLength + 21;
                                                break;
                                            default:
                                                sCableLength = sSpanLength + 17;
                                                break;
                                        }
                                        CableType = CableTypes.FirstSpanKwikTow10C8G;
                                    }
                                    else {
                                        switch (sp.Length) {
                                            case 213:
                                                sCableLength = sSpanLength + 11;
                                                break;
                                            default:
                                                sCableLength = sSpanLength + 6.5;
                                                break;
                                        }
                                        CableType = CableTypes.Standard10C8G;
                                    }
                                    break;
                                case WaterFeedTypes.EndFeed:
                                    switch (sys.Lateral.HoseFeed.HoseFeedType) {
                                        case HoseFeedTypes.Sugargator:
                                            sCableLength = sSpanLength + 21;
                                            CableType = CableTypes.FirstSpanKwikTow10C8G;
                                            break;
                                        case HoseFeedTypes.PivotingLateral:
                                            sCableLength = sSpanLength + 7;
                                            CableType = CableTypes.FirstSpanPivotingLateralC14;
                                            break;
                                        case HoseFeedTypes.CF200:
                                            sCableLength = sSpanLength;
                                            CableType = CableTypes.FirstSpanCF200C14;
                                            break;
                                        default:
                                            sCableLength = sSpanLength + 17;
                                            CableType = CableTypes.FirstSpanMaxiEnd10C8G;
                                            break;
                                    }
                                    break;
                            }
                            break;
                    }
                }
                else if (sp.Disconnecting) {
                    if (currentSpanNumber === LastSpanNumber && bSAC) {
                        sCableLength = sSpanLength + 12.5;
                        CableType = CableTypes.Disc10C10GLastSpanSAC;
                    }
                    else {
                        switch (sp.Length) {
                            case 213:
                                sCableLength = sSpanLength + 11;
                                break;
                            default:
                                sCableLength = sSpanLength + 6.5;
                                break;
                        }
                        CableType = CableTypes.Disc10C8G;
                    }
                }
                else if (currentSpanNumber === LastSpanNumber && bSAC) {
                    sCableLength = sSpanLength + 12.5;
                    CableType = CableTypes.Standard10C8G;
                }
                else {
                    switch (sp.Length) {
                        case 213:
                            sCableLength = sSpanLength + 11;
                            break;
                        default:
                            sCableLength = sSpanLength + 6.5;
                            break;
                    }
                    CableType = CableTypes.Standard10C8G;
                }
                switch (CableType) {
                    case CableTypes.FirstSpanPivot10C8G:
                        switch (spanf.CableConductors(this.Quote.System, this.Side, sp)) {
                            case idxCableConductors.cc10:
                                switch (cableGauge.cableGauge) {
                                    case 8:
                                        CableType = CableTypes.FirstSpanPivot10C8G;
                                        break;
                                    case 10:
                                        CableType = CableTypes.FirstSpanPivot10C10G;
                                        break;
                                    case 12:
                                        CableType = CableTypes.FirstSpanPivot10C12G;
                                        break;
                                }
                                break;
                            case idxCableConductors.cc11:
                                CableType = CableTypes.FirstSpanPivot11C10G;
                                break;
                            default:
                                CableType = CableTypes.FirstSpanPivot14C10G;
                                break;
                        }
                        break;
                    case CableTypes.FirstSpanKwikTow10C8G:
                        switch (spanf.CableConductors(this.Quote.System, this.Side, sp)) {
                            case idxCableConductors.cc10:
                                switch (cableGauge.cableGauge) {
                                    case 8:
                                        CableType = CableTypes.FirstSpanKwikTow10C8G;
                                        break;
                                    case 10:
                                        CableType = CableTypes.FirstSpanKwikTow10C10G;
                                        break;
                                    case 12:
                                        CableType = CableTypes.FirstSpanKwikTow10C12G;
                                        break;
                                }
                                break;
                            case idxCableConductors.cc11:
                                CableType = CableTypes.FirstSpanKwikTow11C10G;
                                break;
                            default:
                                CableType = CableTypes.FirstSpanKwikTow14C10G;
                                break;
                        }
                        break;
                    case CableTypes.FirstSpanCanalCenter10C8G:
                        switch (spanf.CableConductors(this.Quote.System, this.Side, sp)) {
                            case idxCableConductors.cc10:
                                switch (cableGauge.cableGauge) {
                                    case 8:
                                        CableType = CableTypes.FirstSpanCanalCenter10C8G;
                                        break;
                                    case 10:
                                    case 12:
                                        CableType = CableTypes.FirstSpanCanalCenter10C10G;
                                        break;
                                }
                                break;
                            case idxCableConductors.cc11:
                                switch (cableGauge.cableGauge) {
                                    case 8:
                                        CableType = CableTypes.FirstSpanMaxi11C8G;
                                        break;
                                    case 10:
                                    case 12:
                                        CableType = CableTypes.FirstSpanMaxi11C10G;
                                        break;
                                }
                                break;
                            default:
                                CableType = CableTypes.FirstSpanMaxi14C10G;
                                break;
                        }
                        break;
                    case CableTypes.FirstSpanMaxiEnd10C8G:
                        switch (spanf.CableConductors(this.Quote.System, this.Side, sp)) {
                            case idxCableConductors.cc10:
                                switch (cableGauge.cableGauge) {
                                    case 8:
                                        CableType = CableTypes.FirstSpanMaxiEnd10C8G;
                                        break;
                                    case 10:
                                    case 12:
                                        CableType = CableTypes.FirstSpanMaxiEnd10C10G;
                                        break;
                                }
                                break;
                            case idxCableConductors.cc11:
                                switch (cableGauge.cableGauge) {
                                    case 8:
                                        CableType = CableTypes.FirstSpanMaxi11C8G;
                                        break;
                                    case 10:
                                    case 12:
                                        CableType = CableTypes.FirstSpanMaxi11C10G;
                                        break;
                                }
                                break;
                            default:
                                CableType = CableTypes.FirstSpanMaxi14C10G;
                                break;
                        }
                        break;
                    case CableTypes.Disc10C8G:
                        switch (cableGauge.cableGauge) {
                            case 8:
                                CableType = CableTypes.Disc10C8G;
                                break;
                            case 10:
                            case 12:
                                CableType = CableTypes.Disc10C10G;
                                break;
                        }
                        break;
                    case CableTypes.Disc10C10GLastSpanSAC:
                        break;
                    case CableTypes.Standard10C8G:
                        switch (spanf.CableConductors(this.Quote.System, this.Side, sp)) {
                            case idxCableConductors.cc10:
                                switch (cableGauge.cableGauge) {
                                    case 8:
                                        CableType = CableTypes.Standard10C8G;
                                        break;
                                    case 10:
                                        CableType = CableTypes.Standard10C10G;
                                        break;
                                    case 12:
                                        CableType = CableTypes.Standard10C12G;
                                        break;
                                }
                                break;
                            case idxCableConductors.cc11:
                                CableType = CableTypes.Standard11C10G;
                                break;
                            default:
                                CableType = CableTypes.Standard14C10G;
                                break;
                        }
                        break;
                    case CableTypes.FirstSpanCF200C14:
                        break;
                }
                const spanCableIteraterNumber = SpansClass.SelectSpanCable(CableType, sCableLength);
                PO.InsertPartByIteration(schema.groups.SpanAssemblies_SpanCableAssembly.groupId, spanCableIteraterNumber, 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber });
                if (sp.Disconnecting) {
                    PO.InsertPart(schema.groups.SpanAssemblies_SpanDisconnectWinch.groupId, "396536", 1, { locationCode: eLocationCode, locationPosition: currentSpanNumber }); // JOINT-SPAN-DISC/WINCH-A/S-EII
                }
            }
        }
        if (bSSM) {
            PO.InsertPart(schema.groups.SpanAssemblies_SMMWireAlignmentKit.groupId, "353515", 1, { locationCode: eLocationCode, locationPosition: 1 }); // WIRE ALIGN PKG SNGL SPAN MAXI
        }
        if (LastSpanNumber !== 0) { // Be careful, SAC retro can have a swing arm span but no spans to add here
            let iLastSpanLength: number;
            let dLastSpanEndingLocation: number;
            let lastSp: Span = this.Side.Span[LastSpanNumber - 1];
            iLastSpanLength = lastSp.Length;
            dLastSpanEndingLocation = spanf.EndingLocation(this.Quote.System, this.Side, lastSp);
            if (bReverseTow && !(bDoubleEndFeed) && !(bSSM) && (dLastSpanEndingLocation > 685 || iLastSpanLength === 175 || iLastSpanLength === 180 || iLastSpanLength === 186.7 || iLastSpanLength === 194 || iLastSpanLength === 204 || iLastSpanLength === 213)) {
                switch (iLastSpanLength) {
                    case 160:
                    case 175:
                        PO.InsertPart(schema.groups.SpanAssemblies_TowCable.groupId, "352219", 1, { locationCode: eLocationCode, locationPosition: LastSpanNumber }); // TOW CABLE-175FT SPAN-REV TOW
                        break;
                    case 180:
                    case 186.7:
                    case 194:
                        PO.InsertPart(schema.groups.SpanAssemblies_TowCable.groupId, "352221", 1, { locationCode: eLocationCode, locationPosition: LastSpanNumber }); // TOW CABLE-194FT SPAN-REV TOW
                        break;
                    case 204:
                        PO.InsertPart(schema.groups.SpanAssemblies_TowCable.groupId, "352226", 1, { locationCode: eLocationCode, locationPosition: LastSpanNumber }); // TOW CABLE-204FT SPAN-REV TOW
                        break;
                    case 213:
                        PO.InsertPart(schema.groups.SpanAssemblies_TowCable.groupId, "352227", 1, { locationCode: eLocationCode, locationPosition: LastSpanNumber }); // TOW CABLE-213FT SPAN-REV TOW
                        break;
                    default:
                        PO.InsertPart(schema.groups.SpanAssemblies_TowCable.groupId, "352223", 1, { locationCode: eLocationCode, locationPosition: LastSpanNumber }); // TOW CABLE-156FT SPAN-REV TOW
                        break;
                }
            }
        }
    }
    private static SelectSpanCable = (CableType: CableTypes, CableLength: number): number => {
        switch (CableType) {
            case CableTypes.FirstSpanPivot10C8G:
                if (CableLength <= 87) {
                    return 703;
                }
                else if (CableLength <= 106) {
                    return 603;
                }
                else if (CableLength <= 125) {
                    return 1;
                }
                else if (CableLength <= 127) {
                    return 2;
                }
                else if (CableLength <= 144) {
                    return 3;
                }
                else if (CableLength <= 147) {
                    return 4;
                }
                else if (CableLength <= 163) {
                    return 5;
                }
                else if (CableLength <= 167) {
                    return 6;
                }
                else if (CableLength <= 182) {
                    return 7;
                }
                else if (CableLength <= 187) {
                    return 8;
                }
                else if (CableLength <= 193.7) {
                    return 9;
                }
                else if (CableLength <= 201) {
                    return 10;
                }
                else if (CableLength <= 211.5) {
                    return 11;
                }
                else if (CableLength <= 220.5) {
                    return 12;
                }
                else if (CableLength <= 224) {
                    return 300;
                }
                else if (CableLength <= 240) {
                    return 854;
                }
                else if (true) {
                    return 854;
                }
                break;
            case CableTypes.FirstSpanPivot10C10G:
                if (CableLength <= 87) {
                    return 700;
                }
                else if (CableLength <= 106) {
                    return 600;
                }
                else if (CableLength <= 125) {
                    return 20;
                }
                else if (CableLength <= 127) {
                    return 21;
                }
                else if (CableLength <= 144) {
                    return 22;
                }
                else if (CableLength <= 147) {
                    return 23;
                }
                else if (CableLength <= 163) {
                    return 24;
                }
                else if (CableLength <= 167) {
                    return 25;
                }
                else if (CableLength <= 182) {
                    return 26;
                }
                else if (CableLength <= 187) {
                    return 27;
                }
                else if (CableLength <= 193.7) {
                    return 28;
                }
                else if (CableLength <= 201) {
                    return 29;
                }
                else if (CableLength <= 211.5) {
                    return 30;
                }
                else if (CableLength <= 220.5) {
                    return 31;
                }
                else if (CableLength <= 224) {
                    return 330;
                }
                else if (CableLength <= 240) {
                    return 853;
                }
                else if (true) {
                    return 853;
                }
                break;
            case CableTypes.FirstSpanPivot10C12G:
                if (CableLength <= 87) {
                    return 801;
                }
                else if (CableLength <= 106) {
                    return 802;
                }
                else if (CableLength <= 125) {
                    return 420;
                }
                else if (CableLength <= 127) {
                    return 421;
                }
                else if (CableLength <= 144) {
                    return 422;
                }
                else if (CableLength <= 147) {
                    return 423;
                }
                else if (CableLength <= 163) {
                    return 424;
                }
                else if (CableLength <= 167) {
                    return 425;
                }
                else if (CableLength <= 182) {
                    return 426;
                }
                else if (CableLength <= 187) {
                    return 427;
                }
                else if (CableLength <= 193.7) {
                    return 428;
                }
                else if (CableLength <= 201) {
                    return 429;
                }
                else if (CableLength <= 211.5) {
                    return 430;
                }
                else if (CableLength <= 220.5) {
                    return 431;
                }
                else if (CableLength <= 224) {
                    return 520;
                }
                else if (CableLength <= 240) {
                    return 852;
                }
                else if (true) {
                    return 852;
                }
                break;
            case CableTypes.FirstSpanPivot11C10G:
                if (CableLength <= 87) {
                    return 704;
                }
                else if (CableLength <= 106) {
                    return 604;
                }
                else if (CableLength <= 125) {
                    return 40;
                }
                else if (CableLength <= 127) {
                    return 41;
                }
                else if (CableLength <= 144) {
                    return 42;
                }
                else if (CableLength <= 147) {
                    return 43;
                }
                else if (CableLength <= 163) {
                    return 44;
                }
                else if (CableLength <= 167) {
                    return 45;
                }
                else if (CableLength <= 182) {
                    return 46;
                }
                else if (CableLength <= 187) {
                    return 47;
                }
                else if (CableLength <= 193.7) {
                    return 48;
                }
                else if (CableLength <= 201) {
                    return 49;
                }
                else if (CableLength <= 211.5) {
                    return 50;
                }
                else if (CableLength <= 220.5) {
                    return 51;
                }
                else if (CableLength <= 224) {
                    return 360;
                }
                else if (CableLength <= 240) {
                    return 855;
                }
                else if (true) {
                    return 855;
                }
                break;
            case CableTypes.FirstSpanPivot14C10G:
                if (CableLength <= 97) {
                    return 825;
                }
                else if (CableLength <= 116) {
                    return 827;
                }
                else if (CableLength <= 125) {
                    return 60;
                }
                else if (CableLength <= 144) {
                    return 61;
                }
                else if (CableLength <= 147) {
                    return 62;
                }
                else if (CableLength <= 163) {
                    return 63;
                }
                else if (CableLength <= 167) {
                    return 64;
                }
                else if (CableLength <= 182) {
                    return 65;
                }
                else if (CableLength <= 186.5 - 3) {
                    return 377;
                }
                else if (CableLength <= 193.2 - 3) {
                    return 378;
                }
                else if (CableLength <= 200.5 - 3) {
                    return 379;
                }
                else if (CableLength <= 210.5 - 3) {
                    return 380;
                }
                else if (true) {
                    return 381;
                }
                break;
            case CableTypes.FirstSpanKwikTow10C8G:
                if (CableLength <= 133.5) {
                    return 70;
                }
                else if (CableLength <= 152.5) {
                    return 71;
                }
                else if (CableLength <= 155.5) {
                    return 72;
                }
                else if (CableLength <= 171.5) {
                    return 73;
                }
                else if (CableLength <= 175.5) {
                    return 74;
                }
                else if (CableLength <= 190.5) {
                    return 75;
                }
                else if (CableLength <= 195.5) {
                    return 76;
                }
                else if (CableLength <= 202.2) {
                    return 77;
                }
                else if (CableLength <= 209.5) {
                    return 78;
                }
                else if (true) {
                    return 79;
                }
                break;
            case CableTypes.FirstSpanKwikTow10C10G:
                if (CableLength <= 133.5) {
                    return 90;
                }
                else if (CableLength <= 152.5) {
                    return 91;
                }
                else if (CableLength <= 155.5) {
                    return 92;
                }
                else if (CableLength <= 171.5) {
                    return 93;
                }
                else if (CableLength <= 175.5) {
                    return 94;
                }
                else if (CableLength <= 190.5) {
                    return 95;
                }
                else if (CableLength <= 195.5) {
                    return 96;
                }
                else if (CableLength <= 202.2) {
                    return 97;
                }
                else if (CableLength <= 209.5) {
                    return 98;
                }
                else if (true) {
                    return 99;
                }
                break;
            case CableTypes.FirstSpanKwikTow10C12G:
                if (CableLength <= 133.5) {
                    return 440;
                }
                else if (CableLength <= 152.5) {
                    return 441;
                }
                else if (CableLength <= 155.5) {
                    return 442;
                }
                else if (CableLength <= 171.5) {
                    return 443;
                }
                else if (CableLength <= 175.5) {
                    return 444;
                }
                else if (CableLength <= 190.5) {
                    return 445;
                }
                else if (CableLength <= 195.5) {
                    return 446;
                }
                else if (CableLength <= 202.2) {
                    return 447;
                }
                else if (CableLength <= 209.5) {
                    return 448;
                }
                else if (true) {
                    return 449;
                }
                break;
            case CableTypes.FirstSpanKwikTow11C10G:
                if (CableLength <= 101) {
                    return 708;
                }
                else if (CableLength <= 120) {
                    return 608;
                }
                else if (CableLength <= 133.5) {
                    return 110;
                }
                else if (CableLength <= 139) {
                    return 111;
                }
                else if (CableLength <= 152.5) {
                    return 112;
                }
                else if (CableLength <= 155.5) {
                    return 113;
                }
                else if (CableLength <= 158) {
                    return 114;
                }
                else if (CableLength <= 171.5) {
                    return 115;
                }
                else if (CableLength <= 175.5) {
                    return 116;
                }
                else if (CableLength <= 177) {
                    return 117;
                }
                else if (CableLength <= 181) {
                    return 118;
                }
                else if (CableLength <= 190.5) {
                    return 119;
                }
                else if (CableLength <= 195.5) {
                    return 120;
                }
                else if (CableLength <= 196) {
                    return 121;
                }
                else if (CableLength <= 202.2) {
                    return 122;
                }
                else if (CableLength <= 209.5) {
                    return 123;
                }
                else if (true) {
                    return 124;
                }
                break;
            case CableTypes.FirstSpanKwikTow14C10G:
                if (CableLength <= 87.5) {
                    return 814;
                }
                else if (CableLength <= 101) {
                    return 841;
                }
                else if (CableLength <= 106.5) {
                    return 815;
                }
                else if (CableLength <= 120) {
                    return 842;
                }
                else if (CableLength <= 139) {
                    return 130;
                }
                else if (CableLength <= 158) {
                    return 131;
                }
                else if (CableLength <= 177) {
                    return 132;
                }
                else if (CableLength <= 181) {
                    return 133;
                }
                else if (CableLength <= 196) {
                    return 134;
                }
                else if (CableLength <= 200.5 - 3) {
                    return 379;
                }
                else if (CableLength <= 210.5 - 3) {
                    return 380;
                }
                else if (true) {
                    return 381;
                }
                break;
            case CableTypes.FirstSpanCanalCenter10C8G:
                if (CableLength <= 90) {
                    return 845;
                }
                else if (CableLength <= 109) {
                    return 832;
                }
                else if (CableLength <= 128) {
                    return 140;
                }
                else if (CableLength <= 147) {
                    return 142;
                }
                else if (CableLength <= 150) {
                    return 143;
                }
                else if (CableLength <= 166) {
                    return 146;
                }
                else if (CableLength <= 170) {
                    return 147;
                }
                else if (CableLength <= 181.5 - 3) {
                    return 291;
                }
                else if (CableLength <= 186.5 - 3) {
                    return 292;
                }
                else if (CableLength <= 187.5 - 3) {
                    return 293;
                }
                else if (CableLength <= 192.5 - 3) {
                    return 294;
                }
                else if (CableLength <= 193.2 - 3) {
                    return 295;
                }
                else if (CableLength <= 199.2 - 3) {
                    return 296;
                }
                else if (CableLength <= 200.5 - 3) {
                    return 297;
                }
                else if (CableLength <= 206.5 - 3) {
                    return 298;
                }
                else if (CableLength <= 210.5 - 3) {
                    return 299;
                }
                else if (true) {
                    return 300;
                }
                break;
            case CableTypes.FirstSpanCanalCenter10C10G:
                if (CableLength <= 90) {
                    return 811;
                }
                else if (CableLength <= 128) {
                    return 160;
                }
                else if (CableLength <= 147) {
                    return 162;
                }
                else if (CableLength <= 150) {
                    return 163;
                }
                else if (CableLength <= 166) {
                    return 166;
                }
                else if (CableLength <= 170) {
                    return 167;
                }
                else if (CableLength <= 181.5 - 3) {
                    return 321;
                }
                else if (CableLength <= 186.5 - 3) {
                    return 322;
                }
                else if (CableLength <= 187.5 - 3) {
                    return 323;
                }
                else if (CableLength <= 192.5 - 3) {
                    return 324;
                }
                else if (CableLength <= 193.2 - 3) {
                    return 325;
                }
                else if (CableLength <= 199.2 - 3) {
                    return 326;
                }
                else if (CableLength <= 200.5 - 3) {
                    return 327;
                }
                else if (CableLength <= 206.5 - 3) {
                    return 328;
                }
                else if (CableLength <= 210.5 - 3) {
                    return 329;
                }
                else if (true) {
                    return 330;
                }
                break;
            case CableTypes.FirstSpanMaxiEnd10C8G:
                if (CableLength <= 100.5) {
                    return 835;
                }
                else if (CableLength <= 119.5) {
                    return 838;
                }
                else if (CableLength <= 138.5) {
                    return 141;
                }
                else if (CableLength <= 157.5) {
                    return 144;
                }
                else if (CableLength <= 160.5) {
                    return 145;
                }
                else if (CableLength <= 176.5) {
                    return 148;
                }
                else if (CableLength <= 180.5) {
                    return 149;
                }
                else if (CableLength <= 186.5 - 3) {
                    return 292;
                }
                else if (CableLength <= 187.5 - 3) {
                    return 293;
                }
                else if (CableLength <= 192.5 - 3) {
                    return 294;
                }
                else if (CableLength <= 193.2 - 3) {
                    return 295;
                }
                else if (CableLength <= 199.2 - 3) {
                    return 296;
                }
                else if (CableLength <= 200.5 - 3) {
                    return 297;
                }
                else if (CableLength <= 206.5 - 3) {
                    return 298;
                }
                else if (CableLength <= 210.5 - 3) {
                    return 299;
                }
                else if (true) {
                    return 300;
                }
                break;
            case CableTypes.FirstSpanMaxiEnd10C10G:
                if (CableLength <= 100.5) {
                    return 812;
                }
                else if (CableLength <= 119.5) {
                    return 813;
                }
                else if (CableLength <= 138.5) {
                    return 161;
                }
                else if (CableLength <= 157.5) {
                    return 164;
                }
                else if (CableLength <= 160.5) {
                    return 165;
                }
                else if (CableLength <= 176.5) {
                    return 168;
                }
                else if (CableLength <= 180.5) {
                    return 169;
                }
                else if (CableLength <= 186.5 - 3) {
                    return 322;
                }
                else if (CableLength <= 187.5 - 3) {
                    return 323;
                }
                else if (CableLength <= 192.5 - 3) {
                    return 324;
                }
                else if (CableLength <= 193.2 - 3) {
                    return 325;
                }
                else if (CableLength <= 199.2 - 3) {
                    return 326;
                }
                else if (CableLength <= 200.5 - 3) {
                    return 327;
                }
                else if (CableLength <= 206.5 - 3) {
                    return 328;
                }
                else if (CableLength <= 210.5 - 3) {
                    return 329;
                }
                else if (true) {
                    return 330;
                }
                break;
            case CableTypes.FirstSpanMaxi11C8G:
                if (CableLength <= 138.5) {
                    return 180;
                }
                else if (CableLength <= 157.5) {
                    return 181;
                }
                else if (CableLength <= 160.5) {
                    return 182;
                }
                else if (CableLength <= 176.5) {
                    return 183;
                }
                else if (true) {
                    return 184;
                }
                break;
            case CableTypes.FirstSpanMaxi11C10G:
                if (CableLength <= 90) {
                    return 830;
                }
                else if (CableLength <= 97) {
                    return 826;
                }
                else if (CableLength <= 105.5) {
                    return 836;
                }
                else if (CableLength <= 109) {
                    return 833;
                }
                else if (CableLength <= 116) {
                    return 828;
                }
                else if (CableLength <= 119.5) {
                    return 839;
                }
                else if (CableLength <= 128) {
                    return 190;
                }
                else if (CableLength <= 135) {
                    return 191;
                }
                else if (CableLength <= 147) {
                    return 192;
                }
                else if (CableLength <= 150) {
                    return 193;
                }
                else if (CableLength <= 154) {
                    return 194;
                }
                else if (CableLength <= 157) {
                    return 195;
                }
                else if (CableLength <= 166) {
                    return 196;
                }
                else if (CableLength <= 170) {
                    return 197;
                }
                else if (CableLength <= 173) {
                    return 198;
                }
                else if (CableLength <= 177) {
                    return 199;
                }
                else if (CableLength <= 192) {
                    return 200;
                }
                else if (CableLength <= 197) {
                    return 201;
                }
                else if (CableLength <= 203.7) {
                    return 202;
                }
                else if (CableLength <= 211) {
                    return 203;
                }
                else if (true) {
                    return 360;
                }
                break;
            case CableTypes.FirstSpanMaxi14C10G:
                if (CableLength <= 90) {
                    return 829;
                }
                else if (CableLength <= 97) {
                    return 825;
                }
                else if (CableLength <= 100.5) {
                    return 834;
                }
                else if (CableLength <= 109) {
                    return 831;
                }
                else if (CableLength <= 116) {
                    return 827;
                }
                else if (CableLength <= 119.5) {
                    return 837;
                }
                else if (CableLength <= 128) {
                    return 210;
                }
                else if (CableLength <= 135) {
                    return 211;
                }
                else if (CableLength <= 138.5) {
                    return 212;
                }
                else if (CableLength <= 147) {
                    return 213;
                }
                else if (CableLength <= 150) {
                    return 214;
                }
                else if (CableLength <= 154) {
                    return 215;
                }
                else if (CableLength <= 157) {
                    return 216;
                }
                else if (CableLength <= 157.5) {
                    return 217;
                }
                else if (CableLength <= 160.5) {
                    return 218;
                }
                else if (CableLength <= 166) {
                    return 219;
                }
                else if (CableLength <= 170) {
                    return 220;
                }
                else if (CableLength <= 173) {
                    return 221;
                }
                else if (CableLength <= 176.5) {
                    return 222;
                }
                else if (CableLength <= 177) {
                    return 223;
                }
                else if (CableLength <= 180.5) {
                    return 224;
                }
                else if (CableLength <= 192) {
                    return 225;
                }
                else if (CableLength <= 197) {
                    return 226;
                }
                else if (CableLength <= 203.7) {
                    return 227;
                }
                else if (CableLength <= 211) {
                    return 228;
                }
                else if (true) {
                    return 381;
                }
                break;
            case CableTypes.Disc10C8G:
                if (CableLength <= 86.5) {
                    return 819;
                }
                else if (CableLength <= 105.5) {
                    return 818;
                }
                else if (CableLength <= 124.5) {
                    return 240;
                }
                else if (CableLength <= 143.5) {
                    return 241;
                }
                else if (CableLength <= 146.5) {
                    return 242;
                }
                else if (CableLength <= 162.5) {
                    return 243;
                }
                else if (CableLength <= 166.5) {
                    return 244;
                }
                else if (CableLength <= 181.5) {
                    return 245;
                }
                else if (CableLength <= 186.5) {
                    return 246;
                }
                else if (CableLength <= 193.2) {
                    return 247;
                }
                else if (CableLength <= 200.5) {
                    return 248;
                }
                else if (CableLength <= 210.5) {
                    return 249;
                }
                else if (true) {
                    return 250;
                }
                break;
            case CableTypes.Disc10C10G:
                if (CableLength <= 86.5) {
                    return 800;
                }
                else if (CableLength <= 105.5) {
                    return 843;
                }
                else if (CableLength <= 124.5) {
                    return 260;
                }
                else if (CableLength <= 143.5) {
                    return 261;
                }
                else if (CableLength <= 146.5) {
                    return 262;
                }
                else if (CableLength <= 162.5) {
                    return 263;
                }
                else if (CableLength <= 166.5) {
                    return 264;
                }
                else if (CableLength <= 181.5) {
                    return 265;
                }
                else if (CableLength <= 186.5) {
                    return 266;
                }
                else if (CableLength <= 193.2) {
                    return 267;
                }
                else if (CableLength <= 200.5) {
                    return 268;
                }
                else if (CableLength <= 210.5) {
                    return 269;
                }
                else if (true) {
                    return 270;
                }
                break;
            case CableTypes.Disc10C10GLastSpanSAC:
                if (CableLength <= 122 + 12.5) {
                    return 271;
                }
                else if (CableLength <= 137 + 12.5) {
                    return 272;
                }
                else if (CableLength <= 141 + 12.5) {
                    return 273;
                }
                else if (CableLength <= 156 + 12.5) {
                    return 274;
                }
                else if (CableLength <= 160 + 12.5) {
                    return 275;
                }
                else if (CableLength <= 175 + 12.5) {
                    return 276;
                }
                else if (CableLength <= 180 + 12.5) {
                    return 277;
                }
                else if (CableLength <= 186.7 + 12.5) {
                    return 278;
                }
                else if (true) {
                    return 279;
                }
                break;
            case CableTypes.Standard10C8G:
                if (CableLength <= 86.5) {
                    return 705;
                }
                else if (CableLength <= 92.5) {
                    return 707;
                }
                else if (CableLength <= 105.5) {
                    return 605;
                }
                else if (CableLength <= 111.5) {
                    return 607;
                }
                else if (CableLength <= 124.5) {
                    return 280;
                }
                else if (CableLength <= 126.5) {
                    return 281;
                }
                else if (CableLength <= 130.5) {
                    return 282;
                }
                else if (CableLength <= 143.5) {
                    return 283;
                }
                else if (CableLength <= 146.5) {
                    return 284;
                }
                else if (CableLength <= 149.5) {
                    return 285;
                }
                else if (CableLength <= 152.5) {
                    return 286;
                }
                else if (CableLength <= 162.5) {
                    return 287;
                }
                else if (CableLength <= 166.5) {
                    return 288;
                }
                else if (CableLength <= 168.5) {
                    return 289;
                }
                else if (CableLength <= 172.5) {
                    return 290;
                }
                else if (CableLength <= 181.5) {
                    return 291;
                }
                else if (CableLength <= 186.5) {
                    return 292;
                }
                else if (CableLength <= 187.5) {
                    return 293;
                }
                else if (CableLength <= 192.5) {
                    return 294;
                }
                else if (CableLength <= 193.2) {
                    return 295;
                }
                else if (CableLength <= 199.2) {
                    return 296;
                }
                else if (CableLength <= 200.5) {
                    return 297;
                }
                else if (CableLength <= 206.5) {
                    return 298;
                }
                else if (CableLength <= 210.5) {
                    return 299;
                }
                else if (true) {
                    return 300;
                }
                break;
            case CableTypes.Standard10C10G:
                if (CableLength <= 86.5) {
                    return 701;
                }
                else if (CableLength <= 92.5) {
                    return 702;
                }
                else if (CableLength <= 105.5) {
                    return 601;
                }
                else if (CableLength <= 111.5) {
                    return 602;
                }
                else if (CableLength <= 124.5) {
                    return 310;
                }
                else if (CableLength <= 126.5) {
                    return 311;
                }
                else if (CableLength <= 130.5) {
                    return 312;
                }
                else if (CableLength <= 143.5) {
                    return 313;
                }
                else if (CableLength <= 146.5) {
                    return 314;
                }
                else if (CableLength <= 149.5) {
                    return 315;
                }
                else if (CableLength <= 152.5) {
                    return 316;
                }
                else if (CableLength <= 162.5) {
                    return 317;
                }
                else if (CableLength <= 166.5) {
                    return 318;
                }
                else if (CableLength <= 168.5) {
                    return 319;
                }
                else if (CableLength <= 172.5) {
                    return 320;
                }
                else if (CableLength <= 181.5) {
                    return 321;
                }
                else if (CableLength <= 186.5) {
                    return 322;
                }
                else if (CableLength <= 187.5) {
                    return 323;
                }
                else if (CableLength <= 192.5) {
                    return 324;
                }
                else if (CableLength <= 193.2) {
                    return 325;
                }
                else if (CableLength <= 199.2) {
                    return 326;
                }
                else if (CableLength <= 200.5) {
                    return 327;
                }
                else if (CableLength <= 206.5) {
                    return 328;
                }
                else if (CableLength <= 210.5) {
                    return 329;
                }
                else if (true) {
                    return 330;
                }
                break;
            case CableTypes.Standard10C12G:
                if (CableLength <= 86.5) {
                    return 805;
                }
                else if (CableLength <= 92.5) {
                    return 807;
                }
                else if (CableLength <= 105.5) {
                    return 806;
                }
                else if (CableLength <= 111.5) {
                    return 808;
                }
                else if (CableLength <= 124.5) {
                    return 500;
                }
                else if (CableLength <= 126.5) {
                    return 501;
                }
                else if (CableLength <= 130.5) {
                    return 502;
                }
                else if (CableLength <= 143.5) {
                    return 503;
                }
                else if (CableLength <= 146.5) {
                    return 504;
                }
                else if (CableLength <= 149.5) {
                    return 505;
                }
                else if (CableLength <= 152.5) {
                    return 506;
                }
                else if (CableLength <= 162.5) {
                    return 507;
                }
                else if (CableLength <= 166.5) {
                    return 508;
                }
                else if (CableLength <= 168.5) {
                    return 509;
                }
                else if (CableLength <= 172.5) {
                    return 510;
                }
                else if (CableLength <= 181.5) {
                    return 511;
                }
                else if (CableLength <= 186.5) {
                    return 512;
                }
                else if (CableLength <= 187.5) {
                    return 513;
                }
                else if (CableLength <= 192.5) {
                    return 514;
                }
                else if (CableLength <= 193.2) {
                    return 515;
                }
                else if (CableLength <= 199.2) {
                    return 516;
                }
                else if (CableLength <= 200.5) {
                    return 517;
                }
                else if (CableLength <= 206.5) {
                    return 518;
                }
                else if (CableLength <= 210.5) {
                    return 519;
                }
                else if (true) {
                    return 520;
                }
                break;
            case CableTypes.Standard11C10G:
                if (CableLength <= 86.5) {
                    return 706;
                }
                else if (CableLength <= 92.5) {
                    return 840;
                }
                else if (CableLength <= 105.5) {
                    return 606;
                }
                else if (CableLength <= 111.5) {
                    return 844;
                }
                else if (CableLength <= 124.5) {
                    return 340;
                }
                else if (CableLength <= 126.5) {
                    return 341;
                }
                else if (CableLength <= 130.5) {
                    return 342;
                }
                else if (CableLength <= 143.5) {
                    return 343;
                }
                else if (CableLength <= 146.5) {
                    return 344;
                }
                else if (CableLength <= 149.5) {
                    return 345;
                }
                else if (CableLength <= 152.5) {
                    return 346;
                }
                else if (CableLength <= 162.5) {
                    return 347;
                }
                else if (CableLength <= 166.5) {
                    return 348;
                }
                else if (CableLength <= 168.5) {
                    return 349;
                }
                else if (CableLength <= 172.5) {
                    return 350;
                }
                else if (CableLength <= 181.5) {
                    return 351;
                }
                else if (CableLength <= 186.5) {
                    return 352;
                }
                else if (CableLength <= 187.5) {
                    return 353;
                }
                else if (CableLength <= 192.5) {
                    return 354;
                }
                else if (CableLength <= 193.2) {
                    return 355;
                }
                else if (CableLength <= 199.2) {
                    return 356;
                }
                else if (CableLength <= 200.5) {
                    return 357;
                }
                else if (CableLength <= 206.5) {
                    return 358;
                }
                else if (CableLength <= 210.5) {
                    return 359;
                }
                else if (true) {
                    return 360;
                }
                break;
            case CableTypes.Standard14C10G:
                if (CableLength <= 86.5) {
                    return 816;
                }
                else if (CableLength <= 105.5) {
                    return 817;
                }
                else if (CableLength <= 124.5) {
                    return 370;
                }
                else if (CableLength <= 126.5) {
                    return 371;
                }
                else if (CableLength <= 143.5) {
                    return 372;
                }
                else if (CableLength <= 146.5) {
                    return 373;
                }
                else if (CableLength <= 162.5) {
                    return 374;
                }
                else if (CableLength <= 166.5) {
                    return 375;
                }
                else if (CableLength <= 181.5) {
                    return 376;
                }
                else if (CableLength <= 186.5) {
                    return 377;
                }
                else if (CableLength <= 193.2) {
                    return 378;
                }
                else if (CableLength <= 200.5) {
                    return 379;
                }
                else if (CableLength <= 210.5) {
                    return 380;
                }
                else if (true) {
                    return 381;
                }
                break;
            case CableTypes.FirstSpanPivotingLateralC14:
                if (CableLength <= 87.5) {
                    return 814;
                }
                else if (CableLength <= 106) {
                    return 815;
                }
                else if (CableLength <= 125) {
                    return 60;
                }
                else if (CableLength <= 144) {
                    return 61;
                }
                else if (CableLength <= 147) {
                    return 62;
                }
                else if (CableLength <= 163) {
                    return 63;
                }
                else if (CableLength <= 167) {
                    return 64;
                }
                else if (CableLength <= 182) {
                    return 65;
                }
                else if (CableLength <= 187) {
                    return 67;
                }
                else if (CableLength <= 193.7) {
                    return 68;
                }
                else if (CableLength <= 201) {
                    return 66;
                }
                else if (true) {
                    return 66;
                }
                break;
            case CableTypes.FirstSpanCF200C14:
                if (CableLength <= 80.5) {
                    return 900; // 392964-14	WIRE-14C 80' 1ST CF200
                } else if (CableLength <= 99.5) {
                    return 901; // 392965-14	WIRE-14C 99' 1ST CF200
                } else if (CableLength <= 118.5) {
                    return 903; // 392967-14	WIRE-14C 118' 1ST CF200
                } else if (CableLength <= 137.5) {
                    return 904; // 392972-14	WIRE-14C 137' 1ST CF200
                } else if (CableLength <= 140.5) {
                    return 902; // 393966-14	WIRE-14C 140' 1ST CF200
                } else if (CableLength <= 156.5) {
                    return 905; // 392973-14	WIRE-14C 156' 1ST CF200
                } else if (CableLength <= 160.5) {
                    return 908; // 393000-14	WIRE-14C 160' 1ST CF200
                } else if (CableLength <= 175.5) {
                    return 906; // 392998-14	WIRE-14C 175' 1ST CF200
                } else if (CableLength <= 180.5) {
                    return 909; // 393003-14	WIRE-14C 180' 1ST CF200
                } else if (CableLength <= 187.2) {
                    return 910; // 393004-14	WIRE-14C 186.7' 1ST CF200
                } else if (CableLength <= 194.5) {
                    return 907; // 392999-14	WIRE-14C 194' 1ST CF200
                } else if (CableLength <= 204.5) {
                    return 911; // 392963-14	WIRE-14C 204' 1ST CF200
                } else if (CableLength <= 213.5) {
                    return 912; // 392962-14	WIRE-14C 213' 1ST CF200
                } else { // > 213.5 (213') & Higher
                    return 912; // 392962-14	WIRE-14C 213' 1ST CF200
                }
        }
    }
}