import { ISystemBase } from "rdptypes/project/ISystemBase.AutoGenerated";
import IPartsList from "rdptypes/roe/IPartsList";
import IBillOfMaterialsEntry from "rdptypes/roe/IPartsListEntry";
import { partsPackages } from "./data";
import { POSections } from "./roe_migration/POConstants";
import { ISectionGroupedParts } from "./roe_migration/PriceCalculator";

export interface IGroupedSystemParts {
    [groupId: string]: {
        qty: number, 
        partNumber: string
    }[];
}

export const getGroupedPartsForSystem = (system: ISystemBase) => {
    let partGroups: IGroupedSystemParts = {};
    let partSections: ISectionGroupedParts = {};
    let partEntries: {[partNumber: string]: IBillOfMaterialsEntry} = {};

    const { roeData } = partsPackages[system.partsPackageId];

    for (const plg of roeData.partsListGenerators) {
        let pl: IPartsList;
        try {
            pl = plg(system);
        } catch (ex) {
            /* Unhandled exceptions in a parts list generator should never happen and need to
            be fixed in the code. However the user might be able to work around the error themselves.
            For this reason we skip this parts list generator. There is a rule which will generate a
            critical error if the parts list generation fails and so the user won't be able to ignore
            it and submit an order. */
            break;
        }

        for (const e of pl.entries) {
            if (e.partNumber in partEntries) {
                partEntries[e.partNumber].qty += e.qty;
            } else {
                if (!(e.groupId in partGroups)){
                    partGroups[e.groupId] = [];
                }
                if (!(e.sectionId in partSections)){
                    partSections[e.sectionId] = [];
                }
    
                partEntries[e.partNumber] = e;
                partGroups[e.groupId].push(e);
                partSections[e.sectionId].push(e);
            }
        }
    }

    return {partGroups, partSections};
}

export const proposalSectionIsAncillary = (section: POSections) => {
    switch(section){
        case POSections.AgriInject:
        case POSections.MainlineValves:
        case POSections.RadioTelemetry:
        case POSections.ClemonsFilters:
        case POSections.CheckValves:
        case POSections.HeatExchangers:
        case POSections.SprinklerLube:
        case POSections.FlowmeterComponentsPartsPricing:
        case POSections.RC10:
        case POSections.RC10Retro:
        case POSections.CropX:
            return true;
        default:
            return false;
    }
}