import IAction from "rdptypes/IAction";
import { ISpan, ITower } from "rdptypes/project/Types";
import { createNewMultiAction } from "../../actions/MultiAction";
import IAuthState from "../../auth/IAuthState";
import IDbProject from "../../db/IDbProject";
import { IDealerCustomerSettings, IDealerPricing } from "../../db/IDevSettingsState";
import * as optimizeCenterPivotModule from "../../optimization/centerPivot";
import { createCenterPivotActionsFromOptimizedSystem } from "./createCenterPivotActionsFromOptimizedSystem";

export const handleCreateNewOptimizedSystem = (args: {
    optimizedSystem: optimizeCenterPivotModule.IOptimizedSystem;
    layoutId: string;
    authState: IAuthState;
    dbPrj: IDbProject;
    spanDefaults?: ISpan;
    towerDefaults?: ITower;
    customerSettings: IDealerCustomerSettings;
    dealerPricing?: IDealerPricing;
    systemDefaults?: ((layoutId: string, systemId: string, authState: IAuthState) => IAction[])
}) => {
    const { layoutId, authState, dbPrj, optimizedSystem, systemDefaults, spanDefaults, towerDefaults, customerSettings, dealerPricing } = args;
    const { systemId, actions } = createCenterPivotActionsFromOptimizedSystem({
        systemDefaults,
        optimizedSystem,
        layoutId,
        authState,
        spanDefaults,
        towerDefaults,
        customerSettings,
        dealerPricing,
        dbPrj
    })
    dbPrj.pushAction(
        createNewMultiAction(actions, authState)
    );
    return systemId;
}