import { Feature, MultiPolygon, Polygon, bearing, difference, feature, lineString, multiPolygon, point, polygon } from "@turf/turf";
import { customUnion, loCustom } from ".";
import IObstacle from "../model/project/IObstacle";
import FeatureHelpers from "./FeatureHelpers";
import SegmentHelper, { ESegmentClearanceType } from "./SegmentHelper";
import SegmentPolygonHelper from "./SegmentPolygonHelper";

export class ObstacleHelper extends SegmentPolygonHelper {

    static getClearancePolygons = (obstacle: IObstacle, clearanceType: ESegmentClearanceType = ESegmentClearanceType.EquipmentClearance): Polygon[] => {
        if (!ObstacleHelper.booleanValidPolygon(obstacle)) return [];

        if (obstacle.segments.every(s => SegmentHelper.getClearance(s, clearanceType) === 0)) {
            return [ ObstacleHelper.getPolygon(obstacle) ];
        }

        const polys: Feature<Polygon>[] = [];
        for (let i = 0; i < obstacle.segments.length; i++) {
            const leftSegment = obstacle.segments[i];
            const rightSegment = obstacle.segments[i === obstacle.segments.length - 1 ? 0 : i + 1];

            const leftSegmentLine = lineString([ leftSegment.start, leftSegment.end ]);

            const centerPoint = point(leftSegment.end);
            
            const lb = bearing(centerPoint, leftSegment.start, { final: true });
            const rb = bearing(centerPoint, rightSegment.end, { final: true });

            const leftClearance = SegmentHelper.getClearance(leftSegment, clearanceType);
            if (leftClearance) {
                const leftOffsetSegmentLine = loCustom(leftSegmentLine, -leftClearance, { units: 'feet' });
                const offsetPolygon = polygon([
                    [
                        ...leftSegmentLine.geometry.coordinates,
                        ...leftOffsetSegmentLine.geometry.coordinates.slice().reverse(),
                        leftSegmentLine.geometry.coordinates[0]
                    ]
                ])
                polys.push(offsetPolygon);
                
                const leftSector = FeatureHelpers.GetSectorDrawFeature(
                    centerPoint.geometry,
                    leftClearance,
                    lb, rb,
                    null,
                    { units: 'feet', degreeIncrement: 10 }
                )
                polys.push(leftSector);
            }

            const rightClearance = SegmentHelper.getClearance(rightSegment, clearanceType);
            if (rightClearance) {                
                const rightSector = FeatureHelpers.GetSectorDrawFeature(
                    centerPoint.geometry,
                    rightClearance,
                    lb, rb,
                    null,
                    { units: 'feet', degreeIncrement: 10 }
                )
                polys.push(rightSector);
            }
        }

        let bufferResult: Feature<Polygon | MultiPolygon> | null = feature(ObstacleHelper.getPolygon(obstacle));
        if (polys.length) {
            const mp = multiPolygon(polys.map(p => p.geometry.coordinates))
            bufferResult = customUnion(bufferResult, mp);
        }
        if (!bufferResult) return [];
        if (bufferResult.geometry.type === 'Polygon') {
            return [ bufferResult.geometry ];
        }
        return bufferResult.geometry.coordinates.map(ring => polygon(ring).geometry);
    }
   
    static getPolygonMinusClearancePolygon = (obstacle: IObstacle): Polygon | MultiPolygon | null => {
        const clearancePolygons = ObstacleHelper.getClearancePolygons(obstacle);
        if (clearancePolygons.length === 0) return null;
        let clearanceMinusPolygon: Feature<Polygon | MultiPolygon> | null = clearancePolygons.length === 1
            ? feature(clearancePolygons[0])
            : multiPolygon(clearancePolygons.map(x => x.coordinates));
        clearanceMinusPolygon = difference(clearanceMinusPolygon, ObstacleHelper.getPolygon(obstacle));
        if (!clearanceMinusPolygon) return null;
        return clearanceMinusPolygon.geometry;
    }
    static getClearancePolygonMinusSTowerClearance = (obstacle: IObstacle): Polygon | MultiPolygon | null => {
        const swingArmClearancePolygons = ObstacleHelper.getClearancePolygons(obstacle, ESegmentClearanceType.STowerClearance);
        if (swingArmClearancePolygons.length === 0) return null;
        let swingArmClearanceMinusPolygon: Feature<Polygon | MultiPolygon> | null = swingArmClearancePolygons.length === 1
            ? feature(swingArmClearancePolygons[0])
            : multiPolygon(swingArmClearancePolygons.map(x => x.coordinates));

        const clearancePolygons = ObstacleHelper.getClearancePolygons(obstacle);
        for (const clearancePolygon of clearancePolygons) {
            swingArmClearanceMinusPolygon = difference(swingArmClearanceMinusPolygon, clearancePolygon);
            if (!swingArmClearanceMinusPolygon) return null;
        }
            
        swingArmClearanceMinusPolygon = difference(swingArmClearanceMinusPolygon, ObstacleHelper.getPolygon(obstacle));
        if (!swingArmClearanceMinusPolygon) return null;
        return swingArmClearanceMinusPolygon.geometry;
    }
    static getClearancePolygonMinusHTowerClearance = (obstacle: IObstacle): Polygon | MultiPolygon | null => {
        const swingArmClearancePolygons = ObstacleHelper.getClearancePolygons(obstacle, ESegmentClearanceType.HTowerClearance);
        if (swingArmClearancePolygons.length === 0) return null;
        let swingArmClearanceMinusPolygon: Feature<Polygon | MultiPolygon> | null = swingArmClearancePolygons.length === 1
            ? feature(swingArmClearancePolygons[0])
            : multiPolygon(swingArmClearancePolygons.map(x => x.coordinates));

        const clearancePolygons = ObstacleHelper.getClearancePolygons(obstacle);
        for (const clearancePolygon of clearancePolygons) {
            swingArmClearanceMinusPolygon = difference(swingArmClearanceMinusPolygon, clearancePolygon);
            if (!swingArmClearanceMinusPolygon) return null;
        }
            
        swingArmClearanceMinusPolygon = difference(swingArmClearanceMinusPolygon, ObstacleHelper.getPolygon(obstacle));
        if (!swingArmClearanceMinusPolygon) return null;
        return swingArmClearanceMinusPolygon.geometry;
    }
}