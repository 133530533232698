import {
    Alert
} from "@mui/material";
import {
    Feature,
    Geometry,
    LineString,
    Point,
    Polygon,
    bbox,
    featureCollection,
} from "@turf/turf";
import i18next, { t } from "i18next";
import IAction from "rdptypes/IAction";
import * as React from "react";
import { useContext, useState } from "react";
import SegmentHelper from "../../../../../../GeometryHelpers/SegmentHelper";
import { createNewMultiAction } from "../../../../../../actions/MultiAction";
import { createNewLineAnnotation } from "../../../../../../actions/NewLineAnnotationAction";
import { createNewObstacleAction } from "../../../../../../actions/NewObstacleAction";
import { createNewPointAnnotation } from "../../../../../../actions/NewPointAnnotationAction";
import { createNewTextAnnotation } from "../../../../../../actions/NewTextAnnotationAction";
import { createNewWheelObstacleAction } from "../../../../../../actions/NewWheelObstacleAction";
import { createNewUpdateLayoutPropertyAction } from "../../../../../../actions/UpdateLayoutProperty";
import AuthCtx from "../../../../../../auth/AuthCtx";
import IAuthState from "../../../../../../auth/IAuthState";
import { IFieldSettings } from "../../../../../../components/DealerSettingsDialog/FieldSettings";
import { MapContext } from "../../../../../../components/Map/mapContext";
import DevSettingsCtx from "../../../../../../db/DevSettingsCtx";
import IDbProject from "../../../../../../db/IDbProject";
import { ImportExportFeature, ImportExportTypes } from "../../../../../../helpers/importExport";
import { AnnotationType } from "../../../../../../model/project/IAnnotation";
import IFieldBoundary from "../../../../../../model/project/IFieldBoundary";
import ImportExportDialogBase, { IRow } from "../ImportExportDialogBase";

type ImportResult = {
    success: false
} | {
    success: true, action: IAction
}

const handleImportBoundary = (
    geometry: Geometry, layoutId: string, authState: IAuthState, 
    fieldSettings: IFieldSettings, boundaryType: "fieldBoundary" | "wetAreaBoundary" | "pivotCenterBoundary"
): ImportResult => {
    if (geometry.type !== "Polygon") return { success: false };
    const boundary: IFieldBoundary = {
        segments: SegmentHelper.polygonToSegments(geometry as Polygon, fieldSettings.minBoundaryClearanceFeet)
    }
    return {
        success: true,
        action: createNewUpdateLayoutPropertyAction(layoutId, boundaryType, boundary, authState)
    };
};

const handleImportObstacle = (geometry: Geometry, layoutId: string, authState: IAuthState, fieldSettings: IFieldSettings): ImportResult  => {
    if (geometry.type !== "Polygon") return { success: false };
    return {
        success: true,
        action: createNewObstacleAction(
            layoutId,
            {
                polygon: geometry as Polygon,
                clearance: fieldSettings.minObstacleClearanceFeet
            },
            authState
        )
    };
}
const handleImportWheelObstacle = (geometry: Geometry, layoutId: string, authState: IAuthState, fieldSettings: IFieldSettings): ImportResult => {
    if (geometry.type !== "Polygon") return { success: false };
    return {
        success: true,
        action: createNewWheelObstacleAction(
            layoutId,
            {
                polygon: geometry as Polygon,
                clearance: fieldSettings.minObstacleClearanceFeet
            },
            authState
        )
    };
}
const handleImportPoint = (geometry: Geometry, layoutId: string, authState: IAuthState, annotationType: AnnotationType): ImportResult => {
    if (geometry.type !== "Point") return { success: false };
    return {
        success: true,
        action: createNewPointAnnotation(
            layoutId,
            annotationType,
            geometry as Point,
            authState
        )
    };
}
const handleImportLine = (geometry: Geometry, layoutId: string, authState: IAuthState, annotationType: AnnotationType): ImportResult => {
    if (geometry.type !== "LineString") return { success: false };
    return {
        success: true,
        action: createNewLineAnnotation(
            layoutId,
            annotationType,
            geometry as LineString,
            authState
        )
    };
}
const handleImportLabel = (geometry: Geometry, layoutId: string, authState: IAuthState, labelAnnotationText: string | undefined): ImportResult => {
    if (geometry.type !== "Point") return { success: false };
    if (!labelAnnotationText) return { success: false };
    return {
        success: true,
        action: createNewTextAnnotation(
            layoutId,
            AnnotationType.label,
            geometry as Point,
            labelAnnotationText,
            authState
        )
    };
}


interface IProps {
    features: ImportExportFeature[];    
    onClose: () => void;
    dbPrj: IDbProject;
    layoutId: string;
}
const ImportDialog: React.FC<IProps> = ({
    features, onClose, dbPrj, layoutId
}) => {

    const authState = useContext(AuthCtx);
    const [alerts, setAlerts] = useState<React.ReactNode[]>([]);
    const [disableConfirm, setDisableConfirm] = useState<boolean>(false);
    const fieldSettings = useContext(DevSettingsCtx).fieldSettings.current;
    const map = useContext(MapContext);

    const onConfirm = (selectedRows: IRow[]) => {
        const actions: IAction[] = [];
        const importedFeatures: Feature[]  = [];
        for (const importRow of selectedRows) {
            const feature = importRow.feature;
            if (!feature || feature.geometry.type === 'GeometryCollection') {
                continue;
            }
            const geometry = feature.geometry as Geometry;
            switch (importRow.feature.properties.importType) {
                case "boundary": {
                    const result = handleImportBoundary(geometry, layoutId, authState, fieldSettings, "fieldBoundary");
                    if (result.success) actions.push(result.action);
                    break;
                }
                case "wetAreaBoundary": {
                    const result = handleImportBoundary(geometry, layoutId, authState, fieldSettings, "wetAreaBoundary");
                    if (result.success) actions.push(result.action);
                    break;
                }
                case "pivotCenterBoundary": {
                    const result = handleImportBoundary(geometry, layoutId, authState, fieldSettings, "pivotCenterBoundary");
                    if (result.success) actions.push(result.action);
                    break;
                }
                case "obstacle": {
                    const result = handleImportObstacle(geometry, layoutId, authState, fieldSettings);
                    if (result.success) actions.push(result.action);
                    break;
                }
                case "wheelObstacle": {
                    const result = handleImportWheelObstacle(geometry, layoutId, authState, fieldSettings);
                    if (result.success) actions.push(result.action);
                    break;
                }
                case "pump": {
                    const result = handleImportPoint(geometry, layoutId, authState, AnnotationType.pump);
                    if (result.success) actions.push(result.action);
                    break;
                }
                case "point": {
                    const result = handleImportPoint(geometry, layoutId, authState, AnnotationType.point);
                    if (result.success) actions.push(result.action);
                    break;
                }
                case "line": {
                    const result = handleImportLine(geometry, layoutId, authState, AnnotationType.line);
                    if (result.success) actions.push(result.action);
                    break;
                }
                case "canal": {
                    const result = handleImportLine(geometry, layoutId, authState, AnnotationType.canal);
                    if (result.success) actions.push(result.action);
                    break;
                }
                case "electricLine": {
                    const result = handleImportLine(geometry, layoutId, authState, AnnotationType.electricLine);
                    if (result.success) actions.push(result.action);
                    break;
                }
                case "waterLine": {
                    const result = handleImportLine(geometry, layoutId, authState, AnnotationType.waterLine);
                    if (result.success) actions.push(result.action);
                    break;
                }
                case "label": {
                    const result = handleImportLabel(geometry, layoutId, authState, importRow.feature.properties.label);
                    if (result.success) actions.push(result.action);
                    break;
                }
                default: {
                    const result = handleImportLabel(geometry, layoutId, authState, importRow.feature.properties.label);
                    if (result.success) actions.push(result.action);
                    break;
                }
            }
            importedFeatures.push(feature);
        }
        if (actions.length) {
            dbPrj.pushAction(
                createNewMultiAction(actions, authState)
            );
        }
        if (map && importedFeatures.length) {
            const fc = featureCollection(importedFeatures);
            const env = bbox(fc);
            map.fitBounds(env as [number,number,number,number])
        }
        onClose();
    };

    const onRowSelectionChanged = (selectedRows: IRow[]) => {
        const layout = dbPrj.state.layouts[layoutId];
        const alerts: React.ReactNode[] = [];
        let hasFatalAlert = false;

        const checkBoundaryTypes: ImportExportTypes[] = [
            'boundary', 'wetAreaBoundary', 'pivotCenterBoundary'
        ];
        for (const boundaryType of checkBoundaryTypes) {
            let hasExistingBoundary: boolean;
            let boundaryTypeUserString: string = "";
            switch (boundaryType) {
                case 'boundary':
                    hasExistingBoundary = layout ? !!layout.fieldBoundary : false;
                    boundaryTypeUserString = i18next.format(t('field-boundary'), 'capitalize-each');
                    break;
                case 'wetAreaBoundary':
                    hasExistingBoundary = layout ? !!layout.wetAreaBoundary : false;
                    boundaryTypeUserString = i18next.format(t('wet-area-boundary'), 'capitalize-each');
                    break;
                case 'pivotCenterBoundary':
                    hasExistingBoundary = layout ? !!layout.pivotCenterBoundary : false;
                    boundaryTypeUserString = i18next.format(t('pivot-center-boundary'), 'capitalize-each');
                    break;            
                default:
                    hasExistingBoundary = false;
                    break;
            }
            const importBoundaryCount = selectedRows.filter((x) => x.feature.properties.importType === boundaryType);
    
            if (hasExistingBoundary && importBoundaryCount.length > 0) {
                alerts.push(
                    <Alert key={'alert-existing-boundary'} severity='warning'>
                        This field already contains a {boundaryTypeUserString}. Importing a new {boundaryTypeUserString} will overwrite the existing {boundaryTypeUserString}.
                    </Alert>
                )
            }
            if (importBoundaryCount.length > 1) {
                hasFatalAlert = true;
                alerts.push(
                    <Alert key={'alert-multiple-boundaries'} severity='error'>
                        Only one {boundaryTypeUserString} may be imported.
                    </Alert>
                )
            }

        }

        setAlerts(alerts);
        if (disableConfirm !== hasFatalAlert) {
            setDisableConfirm(hasFatalAlert);
        }
    }

    
    

    return(
        <ImportExportDialogBase
            title="Import Shapes"
            features={features}
            onConfirm={onConfirm}
            onCancel={onClose}
            onRowSelectionChanged={onRowSelectionChanged}
            disableConfirm={disableConfirm}
            type="import"
        >
            {alerts}
        </ImportExportDialogBase>
    )
}

export default ImportDialog;