import {
    ICropX as CropX,
    NetworkProviderTypes
} from 'rdptypes/project/ISystemBase.AutoGenerated';
import schema from "../data/partsPackages/2024060101/poSchema";
import CSection2 from "./CSection2";
import PartsAssembler from "./PartsAssembler";
import { WarningLevels } from "./QuoteClass";
import { strLanguageText } from "./TranslationsLib";
import { NetworkProviderOptionOk, ProbelessIrrigationSchedulingOptionOk } from "./Valids.Valids";
export class CropXClass extends CSection2 {
    public DataOK = (PA: PartsAssembler, WithWarnings: boolean = false, DependencyCheckInProgress: boolean = false): void => {
        if (!this.FieldSet.DataValid()) return;
        /*if (FieldSet.Value === FieldSetStates.Invalid) {
        return true;
        }
        if (!(FieldSet.ConfigurationValid())) {
        this.Quote.Warnings.Add(strLanguageText("sltCropXSection"));
        return false;
        }*/
        let x: CropX = this.Quote.System.CropX;
        if (x.ProbelessIrrigationScheduling) {
            this.Quote.Warnings.Add(strLanguageText("sltCropXProbelessIrrigationSchedulingFee"), WarningLevels.Informational);
        }
        if (!(NetworkProviderOptionOk(x.NetworkProviderType, {
            HasProbelessIrrigationScheduling: x.ProbelessIrrigationScheduling,
            Country: this.Quote.System.DealerProperties.Country,
            QtySoilProbeV40: x.SoilProbeV40Qty,
            QtyAntennaExtension3mCable: x.AntennaExtension3mCableQty,
            QtyAntennaCableV40: x.AntennaCableV40Qty,
            QtyPulseAntennaV40: x.PulseAntennaV40Qty,
            QtyInstallationKitV40: x.InstallationKitV40Qty,
            QtyBatteryPackReplacementV40: x.BatteryPackReplacementV40Qty,
        }))) {
            this.Quote.Warnings.Add(strLanguageText("sltNetworkProvider"));
        }
        if (!(ProbelessIrrigationSchedulingOptionOk(x.ProbelessIrrigationScheduling, {
            QtySoilProbeV40: x.SoilProbeV40Qty,
            QtyAntennaExtension3mCable: x.AntennaExtension3mCableQty,
            QtyAntennaCableV40: x.AntennaCableV40Qty,
            QtyPulseAntennaV40: x.PulseAntennaV40Qty,
            QtyInstallationKitV40: x.InstallationKitV40Qty,
            QtyBatteryPackReplacementV40: x.BatteryPackReplacementV40Qty,
        }))) {
            this.Quote.Warnings.Add(strLanguageText("sltCropXQtyNotZero"));
        }
    }
    public WritePO = (PO: PartsAssembler) => {
        let x: CropX = this.Quote.System.CropX;
        if (x.ProbelessIrrigationScheduling) {
            PO.InsertPart(schema.groups.CropX_ProbelessIrrigationScheduling.groupId, "350680", 1); // CROPX PROBELESS IRR SCHEDULING
        }
        else {
            if (x.AntennaExtension3mCableQty > 0) {
                PO.InsertPart(schema.groups.CropX_Antenna.groupId, "350629", x.AntennaExtension3mCableQty); // CROPX ANTENNA EXTENSION 3M CBL
            }
            if (x.AntennaCableV40Qty > 0) {
                PO.InsertPart(schema.groups.CropX_Antenna.groupId, "350704", x.AntennaCableV40Qty)  // CROPX V4 ANTENNA CABLE
            }
            if (x.PulseAntennaV40Qty > 0) {
                PO.InsertPart(schema.groups.CropX_Antenna.groupId, "350705", x.PulseAntennaV40Qty)  // CROPX V4 OMNI ANTENNA
            }
            if (x.InstallationKitV40Qty > 0) {
                PO.InsertPart(schema.groups.CropX_InstallKit.groupId, "350703", x.InstallationKitV40Qty)  // CROPX V4 INSTALLATION KIT
            }
            if (x.BatteryPackReplacementV40Qty > 0) {
                PO.InsertPart(schema.groups.CropX_InstallKit.groupId, "350706", x.BatteryPackReplacementV40Qty)  // CROPX V4 BATTERY PACK
            }

            switch (x.NetworkProviderType) {
                case NetworkProviderTypes.Provider_1:
                    PO.InsertPart(schema.groups.CropX_SoilProbe.groupId, "350691", x.SoilProbeV40Qty); // CROPXV4PROBE-CATM1H-AT&T-SUB
                    break;
                case NetworkProviderTypes.Provider_2:
                    PO.InsertPart(schema.groups.CropX_SoilProbe.groupId, "350693", x.SoilProbeV40Qty); // CROPXV4PROBE-CATM1V-VRIZN-SUB
                    break;
                case NetworkProviderTypes.Provider_3:
                    PO.InsertPart(schema.groups.CropX_SoilProbe.groupId, "350694", x.SoilProbeV40Qty); // CROPXV4PROBE-CAT1H-VIEARO-SUB
                    break;
                case NetworkProviderTypes.Provider_4:
                    PO.InsertPart(schema.groups.CropX_SoilProbe.groupId, "350690", x.SoilProbeV40Qty); // CROPXV4PROBE-CATM1T-CA/BR-SUB
                    break;
                case NetworkProviderTypes.Provider_5:
                    PO.InsertPart(schema.groups.CropX_SoilProbe.groupId, "350691", x.SoilProbeV40Qty); // CROPXV4PROBE-CATM1H-AT&T-SUB
                    break;
                case NetworkProviderTypes.Provider_6:
                    PO.InsertPart(schema.groups.CropX_SoilProbe.groupId, "350691", x.SoilProbeV40Qty); // CROPXV4PROBE-CATM1H-AT&T-SUB
                    break;
                case NetworkProviderTypes.Provider_7:
                    PO.InsertPart(schema.groups.CropX_SoilProbe.groupId, "350692", x.SoilProbeV40Qty); // CROPXV4PROBE-CAT1T-TELIT-SUB
                    break;
            }
        }
    }
}