import {
    CellBaseModels,
    CellControlPanelTypes,
    CellMountingBrackets,
    Countries,
    OntracRadio_Mhz,
    PanelModels,
    RadioTelemetryTypes,
    SatellitePlusBaseModelTypes,
    SatellitePlusControlPanelTypes
} from 'rdptypes/project/ISystemBase.AutoGenerated';
import schema from "../data/partsPackages/2024060101/poSchema";
import CSection2 from "./CSection2";
import * as ohf from "./OtherHelpers";
import PartsAssembler, { LocationCodes } from "./PartsAssembler";
import { strLanguageText } from "./TranslationsLib";
import { RadioTelemetryCellSubscriptionOK, RadioTelemetryMountOK, RadioTelemetryNavigatorGPSOK, RadioTelemetryNavigatorGPSRetroOK, RadioTelemetryPlusModelOK, RadioTelemetrySystemPositionOK, RadioTelemetrySystemPressureOK, RadioTelemetrySystemStartStopOK, RadioTelemetrySystemStopOK } from "./Valids.Valids";
export class OntracClass extends CSection2 {
    public DataOK = (PA: PartsAssembler, WithWarnings: boolean = false, DependencyCheckInProgress: boolean = false): void => {
        if (!this.FieldSet.DataValid()) return;
        /*if (FieldSet.Value === FieldSetStates.Invalid) {
        return true;
        }
        if (!(FieldSet.ConfigurationValid())) {
        this.Quote.Warnings.Add(this.TranslatedKey);
        return false;
        }*/
        let bRetro: boolean = !(this.Quote.ControlPanelClass.FieldSet.DataValid());
        if (!(bRetro)) {
            switch (this.Quote.System.ControlPanel.PanelModel) {
                case PanelModels.RPMBasic:
                case PanelModels.RPMStandard:
                    if (this.Quote.System.Ontrac.RadioTelemetryType !== RadioTelemetryTypes.Cell) {
                        this.Quote.Warnings.Add(strLanguageText("sltRadioTelemetry"));
                    }
                    break;
            }
        }
        if (this.Quote.System.DealerProperties.Country !== Countries.UnitedStates) {
            if (this.Quote.System.Ontrac.RadioTelemetryType !== RadioTelemetryTypes.SatellitePlus) {
                this.Quote.Warnings.Add(strLanguageText("sltRadioTelemetry"));
            }
        }
        switch (this.Quote.System.Ontrac.RadioTelemetryType) {
            case RadioTelemetryTypes.SatellitePlus:
                {
                    let j: number = this.Quote.System.Ontrac.SatellitePlus.SatellitePlusUnit.length;
                    for (let i: number = 1; i <= j; i++
                    ) {
                        if (!(RadioTelemetryPlusModelOK(this.Quote.System.Ontrac.SatellitePlus.SatellitePlusUnit[i - 1].BaseModel, this.Quote.System.DealerProperties.Country))) {
                            this.Quote.Warnings.Add(strLanguageText("sltRadioTelemetry"));
                        }
                    }
                }
                break;
            case RadioTelemetryTypes.Cell:
                {
                    let j: number = this.Quote.System.Ontrac.Cell.CellUnit.length;
                    for (let i: number = 1; i <= j; i++
                    ) {
                        let ePanelType: CellControlPanelTypes = this.Quote.System.Ontrac.Cell.CellUnit[i - 1].ControlPanelType;
                        if (!(RadioTelemetryNavigatorGPSOK(this.Quote.System.Ontrac.Cell.CellUnit[i - 1].ExistingNavigatorGPS, ePanelType))) {
                            this.Quote.Warnings.Add(strLanguageText("sltRadioTelemetry"));
                        }
                        if (!(RadioTelemetrySystemPressureOK(this.Quote.System.Ontrac.Cell.CellUnit[i - 1].SystemPressure, this.Quote.System.Ontrac.Cell.CellUnit[i - 1].WaterStatus))) {
                            this.Quote.Warnings.Add(strLanguageText("sltRadioTelemetry"));
                        }
                        if (!(RadioTelemetrySystemPositionOK(this.Quote.System.Ontrac.Cell.CellUnit[i - 1].SystemPosition, this.Quote.System.Ontrac.Cell.CellUnit[i - 1].ExistingNavigatorGPS))) {
                            this.Quote.Warnings.Add(strLanguageText("sltRadioTelemetry"));
                        }
                        if (!(RadioTelemetryNavigatorGPSRetroOK(this.Quote.System.Ontrac.Cell.CellUnit[i - 1].NavigatorRetro, this.Quote.System.Ontrac.Cell.CellUnit[i - 1].ExistingNavigatorGPS, this.Quote.System.Ontrac.Cell.CellUnit[i - 1].SystemPosition))) {
                            this.Quote.Warnings.Add(strLanguageText("sltRadioTelemetry"));
                        }
                        if (!(RadioTelemetrySystemStartStopOK(this.Quote.System.Ontrac.Cell.CellUnit[i - 1].SystemStartStop, ePanelType, this.Quote.System.Ontrac.Cell.CellUnit[i - 1].WireTheft, this.Quote.System.Ontrac.Cell.CellUnit[i - 1].ExistingNavigatorGPS, this.Quote.System.Ontrac.Cell.CellUnit[i - 1].NavigatorRetro, this.Quote.System.Ontrac.Cell.CellUnit[i - 1].SystemPosition))) {
                            this.Quote.Warnings.Add(strLanguageText("sltRadioTelemetry"));
                        }
                        if (!(RadioTelemetrySystemStopOK(this.Quote.System.Ontrac.Cell.CellUnit[i - 1].SystemStop, this.Quote.System.Ontrac.Cell.CellUnit[i - 1].SystemStartStop))) {
                            this.Quote.Warnings.Add(strLanguageText("sltRadioTelemetry"));
                        }
                        if (!(RadioTelemetryMountOK(this.Quote.System.Ontrac.Cell.CellUnit[i - 1].MountingBracket, ePanelType, this.Quote.System.Ontrac.Cell.CellUnit[i - 1].WireTheft, this.Quote.System.Ontrac.Cell.CellUnit[i - 1].ExistingNavigatorGPS, this.Quote.System.Ontrac.Cell.CellUnit[i - 1].NavigatorRetro, this.Quote.System.Ontrac.Cell.CellUnit[i - 1].SystemPosition, this.Quote.System.Ontrac.Cell.CellUnit[i - 1].SystemStartStop))) {
                            this.Quote.Warnings.Add(strLanguageText("sltRadioTelemetry"));
                        }
                        if (!(RadioTelemetryCellSubscriptionOK(this.Quote.System.Ontrac.Cell.CellUnit[i - 1].SubscriptionPeriod, this.Quote.System.Ontrac.Cell.CellUnit[i - 1].WireTheft))) {
                            this.Quote.Warnings.Add(strLanguageText("sltRadioTelemetry"));
                        }
                    }
                }
                break;
        }
    }
/*public UpdatePriceWindow = (f: FListPrice, PA: PartsAssembler) => {
switch (this.Quote.System.Ontrac.RadioTelemetryType) {
case RadioTelemetryTypes.Radio:
f.lblPrice.Text = Format(PA.GenerateBOM.ListPriceBySectionGroup(POSections.RadioTelemetry, POGroups.RadioTelemetry_Radio, ohf.IsInternational(this.Quote.System.DealerProperties)), ReportCurrencyFormat);
break;
case RadioTelemetryTypes.SatellitePlus:
{
f.lblPrice.Text = Format(PA.GenerateBOM.ListPriceBySectionGroup(POSections.RadioTelemetry, POGroups.RadioTelemetry_SatellitePlusRetro, ohf.IsInternational(this.Quote.System.DealerProperties)) + PA.GenerateBOM.ListPriceBySectionGroup(POSections.ControlPanel, POGroups.ControlPanel_SatellitePlusKit, ohf.IsInternational(this.Quote.System.DealerProperties)), ReportCurrencyFormat);
}
break;
case RadioTelemetryTypes.Cell:
{
f.lblPrice.Text = Format(PA.GenerateBOM.ListPriceBySectionGroup(POSections.RadioTelemetry, POGroups.RadioTelemetry_Cell, ohf.IsInternational(this.Quote.System.DealerProperties)), ReportCurrencyFormat);
}
break;
}
}
*//*public NumberOfFees = (): number => {
if (!(FieldSet.DataValid())) {
return 0;
}
switch (this.Quote.System.Ontrac.RadioTelemetryType) {
case RadioTelemetryTypes.Radio:
return 0;
break;
case RadioTelemetryTypes.SatellitePlus:
{
return ohf.SatellitePlusCount(this.Quote.System.Ontrac.SatellitePlus);
}
break;
case RadioTelemetryTypes.Cell:
{
return ohf.CellCount(this.Quote.System.Ontrac.Cell);
}
break;
}
}
*//*public TotalAnnualFees = (PA: PartsAssembler): number => {
if (!(FieldSet.DataValid())) {
return 0;
}
switch (this.Quote.System.Ontrac.RadioTelemetryType) {
case RadioTelemetryTypes.Radio:
return 0;
break;
case RadioTelemetryTypes.SatellitePlus:
{
return PA.GenerateBOM.SumOfInfoBySectionGroup(POSections.RadioTelemetry, POGroups.RadioTelemetry_SatellitePlusRetro) + PA.GenerateBOM.SumOfInfoBySectionGroup(POSections.ControlPanel, POGroups.ControlPanel_SatellitePlusKit);
}
break;
case RadioTelemetryTypes.Cell:
{
return PA.GenerateBOM.SumOfInfoBySectionGroup(POSections.RadioTelemetry, POGroups.RadioTelemetry_Cell);
}
break;
}
}
*//*public GetOntracFees = (i: number, Fee: number, Period: string) => {
switch (this.Quote.System.Ontrac.RadioTelemetryType) {
case RadioTelemetryTypes.Radio:
Fee = 0;
Period = "";
break;
case RadioTelemetryTypes.SatellitePlus:
{
Fee = this.SatellitePlusUnitSubcriptionPrice(this.Quote.System.Ontrac.SatellitePlus.SatellitePlusUnit[i - 1].BaseModel, this.Quote.System.Ontrac.SatellitePlus.SatellitePlusUnit[i - 1].SubscriptionPeriod);
Period = OntracSubscriptionText(this.Quote.System.Ontrac.SatellitePlus.SatellitePlusUnit[i - 1].SubscriptionPeriod);
}
break;
case RadioTelemetryTypes.Cell:
{
Fee = this.CellUnitSubcriptionPrice(this.Quote.System.Ontrac.Cell.CellUnit[i - 1].BaseModel, this.Quote.System.Ontrac.Cell.CellUnit[i - 1].SubscriptionPeriod);
Period = OntracSubscriptionText(this.Quote.System.Ontrac.Cell.CellUnit[i - 1].SubscriptionPeriod);
}
break;
}
}
*//*public UpdatePriceWindowAux = (f: FListPrice, PA: PartsAssembler) => {
switch (this.Quote.System.Ontrac.RadioTelemetryType) {
case RadioTelemetryTypes.SatellitePlus:
case RadioTelemetryTypes.Cell:
{
f.lblAuxText.Text = "Ontrac Annual Subscription Fee:";
f.lblAux.Text = Format(this.TotalAnnualFees(PA), ReportCurrencyFormat);
}
break;
}
}
*/public WritePO = (PA: PartsAssembler) => {
        let bRetro: boolean = !(this.Quote.ControlPanelClass.FieldSet.DataValid());
        switch (this.Quote.System.Ontrac.RadioTelemetryType) {
            case RadioTelemetryTypes.Radio:
                if (this.Quote.System.Ontrac.Radio.RadioType === OntracRadio_Mhz.a450) {
                    PA.InsertPart(schema.groups.RadioTelemetry_Radio.groupId, "350940", this.Quote.System.Ontrac.Radio.BaseStation); // RAMS TELEMETRY BASE STATION
                    PA.InsertPart(schema.groups.RadioTelemetry_Radio.groupId, "350947", this.Quote.System.Ontrac.Radio.TouchScreen); // RADIO MODEM-RPM TOUCH
                    PA.InsertPart(schema.groups.RadioTelemetry_Radio.groupId, "350942", this.Quote.System.Ontrac.Radio.Advanced); // RADIO MODEM-RPM ADV
                    PA.InsertPart(schema.groups.RadioTelemetry_Radio.groupId, "350941", this.Quote.System.Ontrac.Radio.RAMS); // RADIO MODEM-RPM PREF
                    PA.InsertPart(schema.groups.RadioTelemetry_Radio.groupId, "350940A", this.Quote.System.Ontrac.Radio.Omni100); // RAMS TELEMETRY RADIO/ANTENNA
                    PA.InsertPart(schema.groups.RadioTelemetry_Radio.groupId, "353985", this.Quote.System.Ontrac.Radio.OmniWhip); // ANTENNA-8" OMNI 12 CBL 450MHZ
                    PA.InsertPart(schema.groups.RadioTelemetry_Radio.groupId, "350941A", this.Quote.System.Ontrac.Radio.Yagi); // ANTENNA,450-470,YAGI
                }
                else {
                    PA.InsertPart(schema.groups.RadioTelemetry_Radio.groupId, "350980", this.Quote.System.Ontrac.Radio.BaseStation); // RADIO MODEM-900 MHZ BASE
                    PA.InsertPart(schema.groups.RadioTelemetry_Radio.groupId, "350981", this.Quote.System.Ontrac.Radio.TouchScreen); // TELEMETRY KIT-RPM TCH 900MHZ
                    PA.InsertPart(schema.groups.RadioTelemetry_Radio.groupId, "350982", this.Quote.System.Ontrac.Radio.Advanced); // TELEMETRY KIT-RPM ADV 900MHZ
                    PA.InsertPart(schema.groups.RadioTelemetry_Radio.groupId, "350983", this.Quote.System.Ontrac.Radio.RAMS); // TELEMETRY KIT-RPM RAMS 900MHZ
                    PA.InsertPart(schema.groups.RadioTelemetry_Radio.groupId, "350995", this.Quote.System.Ontrac.Radio.Omni100); // ANTENNA-OMNI BASE 6' 900MHZ
                    PA.InsertPart(schema.groups.RadioTelemetry_Radio.groupId, "350985", this.Quote.System.Ontrac.Radio.OmniWhip); // ANTENNA-12" OMNI 12 CBL 900MHZ
                    PA.InsertPart(schema.groups.RadioTelemetry_Radio.groupId, "350984", this.Quote.System.Ontrac.Radio.Yagi); // ANTENNA-YAGI 15 CBL 900MHZ
                    PA.InsertPart(schema.groups.RadioTelemetry_Radio.groupId, "350994", this.Quote.System.Ontrac.Radio.Repeater); // TELEMETRY KIT-900 MHZ REPEATER
                    PA.InsertPart(schema.groups.RadioTelemetry_Radio.groupId, "350987", this.Quote.System.Ontrac.Radio.Omni2); // ANTENNA-6' OMNI 15CBL RPTR 900
                    PA.InsertPart(schema.groups.RadioTelemetry_Radio.groupId, "350988", this.Quote.System.Ontrac.Radio.Omni40); // ANTENNA-6' OMNI 40' CBL 900MHZ
                    PA.InsertPart(schema.groups.RadioTelemetry_Radio.groupId, "350992", this.Quote.System.Ontrac.Radio.OmniRubberWhip); // ANTENNA-RUBBER DUCK 900MHZ
                }
                PA.InsertPart(schema.groups.RadioTelemetry_Radio.groupId, "350989", this.Quote.System.Ontrac.Radio.Mount); // ANTENNA MOUNT-PIVOT TOWER
                break;
            case RadioTelemetryTypes.SatellitePlus:
                {
                    let j: number = ohf.SatellitePlusCount(this.Quote.System.Ontrac.SatellitePlus);
                    for (let i: number = 1; i <= j; i++
                    ) {
                        if (bRetro) {
                            switch (this.Quote.System.Ontrac.SatellitePlus.SatellitePlusUnit[i - 1].BaseModel) {
                                case SatellitePlusBaseModelTypes.CellPlusVerizon:
                                    {
                                        switch (this.Quote.System.Ontrac.SatellitePlus.SatellitePlusUnit[i - 1].ControlPanelType) {
                                            case SatellitePlusControlPanelTypes.Advanced:
                                                {
                                                }
                                                break;
                                            case SatellitePlusControlPanelTypes.Preferred:
                                                {
                                                }
                                                break;
                                            case SatellitePlusControlPanelTypes.RAMS:
                                                {
                                                }
                                                break;
                                        }
                                    }
                                    break;
                                case SatellitePlusBaseModelTypes.SatellitePlus:
                                    {
                                        switch (this.Quote.System.Ontrac.SatellitePlus.SatellitePlusUnit[i - 1].ControlPanelType) {
                                            case SatellitePlusControlPanelTypes.Advanced:
                                                {
                                                }
                                                break;
                                            case SatellitePlusControlPanelTypes.Preferred:
                                                {
                                                }
                                                break;
                                            case SatellitePlusControlPanelTypes.RAMS:
                                                {
                                                }
                                                break;
                                        }
                                    }
                                    break;
                            }
                        }
                        else {
                            switch (this.Quote.System.Ontrac.SatellitePlus.SatellitePlusUnit[i - 1].BaseModel) {
                                case SatellitePlusBaseModelTypes.CellPlusVerizon:
                                    {
                                        switch (this.Quote.System.ControlPanel.PanelModel) {
                                            case PanelModels.RPMPreferred:
                                                break;
                                            default:
                                                break;
                                        }
                                    }
                                    break;
                                case SatellitePlusBaseModelTypes.SatellitePlus:
                                    {
                                        switch (this.Quote.System.ControlPanel.PanelModel) {
                                            case PanelModels.RPMPreferred:
                                                break;
                                            default:
                                                break;
                                        }
                                    }
                                    break;
                            }
                        }
                    }
                }
                break;
            case RadioTelemetryTypes.Cell:
                {
                    let j: number = ohf.CellCount(this.Quote.System.Ontrac.Cell);
                    for (let i: number = 1; i <= j; i++
                    ) {
                        switch (this.Quote.System.Ontrac.Cell.CellUnit[i - 1].BaseModel) {
                            case CellBaseModels.Verizon:
                                {
                                }
                                break;
                            case CellBaseModels.ATT:
                                {
                                }
                                break;
                            case CellBaseModels.Satellite:
                                {
                                }
                                break;
                        }
                        switch (this.Quote.System.Ontrac.Cell.CellUnit[i - 1].MountingBracket) {
                            case CellMountingBrackets.PivotCenter:
                                {
                                }
                                break;
                            case CellMountingBrackets.Span:
                                {
                                }
                                break;
                        }
                        if (this.Quote.System.Ontrac.Cell.CellUnit[i - 1].WaterStatus) {
                        }
                        if (this.Quote.System.Ontrac.Cell.CellUnit[i - 1].SystemPressure) {
                        }
                        if (this.Quote.System.Ontrac.Cell.CellUnit[i - 1].SystemPosition) {
                            if (this.Quote.System.Ontrac.Cell.CellUnit[i - 1].ExistingNavigatorGPS || this.Quote.System.Ontrac.Cell.CellUnit[i - 1].NavigatorRetro) {
                            }
                            else {
                            }
                            if (this.Quote.System.Ontrac.Cell.CellUnit[i - 1].NavigatorRetro) {
                                PA.InsertPart(schema.groups.RadioTelemetry_Cell.groupId, "350970", 1, { locationCode: LocationCodes.Ontrac, locationPosition: i }); // GPS CDW MCP KIT
                                PA.InsertPart(schema.groups.RadioTelemetry_Cell.groupId, "350970A", 1, { locationCode: LocationCodes.Ontrac, locationPosition: i }); // GPS CDW EOS KIT
                            }
                        }
                        if (this.Quote.System.Ontrac.Cell.CellUnit[i - 1].WireTheft) {
                        }
                        if (this.Quote.System.Ontrac.Cell.CellUnit[i - 1].RainGauge) {
                        }
                        if (this.Quote.System.Ontrac.Cell.CellUnit[i - 1].SystemStartStop || this.Quote.System.Ontrac.Cell.CellUnit[i - 1].SystemStop) {
                        }
                    }
                }
                break;
        }
    }
    /*public SatellitePlusUnitSubcriptionPrice = (BaseModel: SatellitePlusBaseModelTypes, SubscriptionPeriod: RadioTelemetrySubscriptionPeriods): number => {
    switch (BaseModel) {
    case SatellitePlusBaseModelTypes.CellPlusVerizon:
    {
    switch (SubscriptionPeriod) {
    case RadioTelemetrySubscriptionPeriods.a4Months:
    return 100;
    break;
    case RadioTelemetrySubscriptionPeriods.a6Months:
    return 125;
    break;
    case RadioTelemetrySubscriptionPeriods.a9Months:
    return 175;
    break;
    case RadioTelemetrySubscriptionPeriods.a12Months:
    return 200;
    break;
    }
    }
    break;
    case SatellitePlusBaseModelTypes.SatellitePlus:
    {
    switch (SubscriptionPeriod) {
    case RadioTelemetrySubscriptionPeriods.a4Months:
    return 225;
    break;
    case RadioTelemetrySubscriptionPeriods.a6Months:
    return 275;
    break;
    case RadioTelemetrySubscriptionPeriods.a9Months:
    return 315;
    break;
    case RadioTelemetrySubscriptionPeriods.a12Months:
    return 355;
    break;
    }
    }
    break;
    }
    }
    *//*public CellUnitSubcriptionPrice = (BaseModel: CellBaseModels, SubscriptionPeriod: RadioTelemetrySubscriptionPeriods): number => {
    switch (BaseModel) {
    case CellBaseModels.Satellite:
    {
    switch (SubscriptionPeriod) {
    case RadioTelemetrySubscriptionPeriods.a4Months:
    return 225;
    break;
    case RadioTelemetrySubscriptionPeriods.a6Months:
    return 275;
    break;
    case RadioTelemetrySubscriptionPeriods.a9Months:
    return 315;
    break;
    case RadioTelemetrySubscriptionPeriods.a12Months:
    return 355;
    break;
    }
    }
    break;
    default:
    switch (SubscriptionPeriod) {
    case RadioTelemetrySubscriptionPeriods.a4Months:
    return 100;
    break;
    case RadioTelemetrySubscriptionPeriods.a6Months:
    return 125;
    break;
    case RadioTelemetrySubscriptionPeriods.a9Months:
    return 175;
    break;
    case RadioTelemetrySubscriptionPeriods.a12Months:
    return 200;
    break;
    }
    break;
    }
    }
    *//*public NumberOfSatellitePlusUnits = (): number => {
    if (!(FieldSet.DataValid())) {
    return 0;
    }
    if (this.Quote.System.Ontrac.RadioTelemetryType !== RadioTelemetryTypes.SatellitePlus) {
    return 0;
    }
    return ohf.SatellitePlusCount(this.Quote.System.Ontrac.SatellitePlus);
    }
    *//*public NumberOfCellUnits = (): number => {
    if (!(FieldSet.DataValid())) {
    return 0;
    }
    if (this.Quote.System.Ontrac.RadioTelemetryType !== RadioTelemetryTypes.Cell) {
    return 0;
    }
    return ohf.CellCount(this.Quote.System.Ontrac.Cell);
    }
    *//*protected NewForm = (): FSectionForm => {
    let selectedType: RadioTelemetryTypes;
    if (DataValidBeforeEdit) {
    selectedType = this.Quote.System.Ontrac.RadioTelemetryType;
    }
    else {
    let f: ReinCloudSelectForm;
    selectedType = f.Edit(this.Quote.frmQuote);
    }
    switch (selectedType) {
    case RadioTelemetryTypes.Radio:
    return new OntracRadioForm();
    break;
    case RadioTelemetryTypes.Cell:
    {
    return new OntracCellForm();
    }
    break;
    case RadioTelemetryTypes.SatellitePlus:
    {
    return new OntracSatellitePlusForm();
    }
    break;
    default:
    return null;
    break;
    }
    }
    *//*constructor(pQuote: QuoteClass, ID: SectionIDs, DependentOnList: CSection2[]) {
    super(pQuote, ID, DependentOnList);
    }*/
}