import { IOutlet } from "rdptypes/project/ISprinklerChart";
import { BoomBackDevices, DeviceTypes, DropTypes, IPackage, RegulatorPressures, RegulatorTypes, ThreadTypes, UPipeReaches } from "rdptypes/project/ISprinklers";
import { BoosterPumpTypes, EndGunTypes, SACDistributionFlowRate, SACZoneControlTypes, SwingArmLengths } from "rdptypes/project/ISystemBase.AutoGenerated";
import { MaxPackages, SystemMotionEnum } from "./CommonConstants";
import { FloatsAreEqual, GetMinimalEndGunPressures, PressureLossHazenWilliams, PressureLossSimpsonsRule, RequiredGPMGivenArc } from "./CommonFunctions";
import { EndGunHelper } from "./EndGunHelper";
import MultiSprinklerEngineException from "./MultiSprinklerEngineException";
import { DeviceNozzle, DeviceNozzleSet, NozzleRecord } from "./Nozzle";
import SprinklerEngineException from "./SprinklerEngineException";
import { ComputeNextSprinklerResult } from "./SprinklerEngineTypes/ComputeNextSprinklerResult";
import { ESACZoneFactors } from "./SprinklerEngineTypes/ESACZoneFactors";
import { ESACZoneOutletRelations } from "./SprinklerEngineTypes/ESACZoneOutletRelations";
import { EndGunRecord } from "./SprinklerEngineTypes/EndGunRecord";
import { EngineRecord } from "./SprinklerEngineTypes/EngineRecord";
import { NozzlePressureCheck, OutletTypes, SpaceDrop, SpaceReduction, WarningSeverity, WarningTypes } from "./SprinklerEngineTypes/Enums";
import { OutletControlRecord } from "./SprinklerEngineTypes/OutletControlRecord";
import { OutletMaxGPMData } from "./SprinklerEngineTypes/OutletMaxGPMData";
import { OutletPressureData } from "./SprinklerEngineTypes/OutletPressureData";
import { OutletRecord } from "./SprinklerEngineTypes/OutletRecord";
import { PackageRecord } from "./SprinklerEngineTypes/PackageRecord";
import { SelectBestNozzleResult } from "./SprinklerEngineTypes/SelectBestNozzleResult";
import { SelectNozzleResult } from "./SprinklerEngineTypes/SelectNozzleResult";
import { SpacingResult } from "./SprinklerEngineTypes/SpacingResult";
import { SpanRecord } from "./SprinklerEngineTypes/SpanRecord";
import { SystemRecord } from "./SprinklerEngineTypes/SystemRecord";
import { strLanguageText } from "./TranslationsLib";
import { Side, Span } from "./Types";
import { StringFormat } from "./VbCompat";

export class SprinklerEngine {
    private _MaxSpans = 35;
    public Side: Side;
    private readonly Spans: SpanRecord[] = [];
    public readonly Outlets: OutletRecord[] = [];
    private readonly OutletControl: OutletControlRecord = new OutletControlRecord();
    public Package: PackageRecord[] = [ ...Array(MaxPackages + 1).keys() ].map(x => ({} as PackageRecord));
    private SpanCount: number = 0;
    public readonly EngineInfo: EngineRecord = new EngineRecord();
    public readonly SystemInfo: SystemRecord = new SystemRecord();
    public readonly EndGunInfo: EndGunRecord = new EndGunRecord();
    public readonly ZoneFactors: ESACZoneFactors = new ESACZoneFactors();
    private readonly ZoneOutletRelations = new ESACZoneOutletRelations();
    private _nextSprinkler: number = 0;
    private _lastSprinkler: number = 0;
    private _curESP: number = 0;
    private _highFlowCount: number = 0;
    private _GPMExceededCount: number = 0;
    private _Spacing: number = 0;
    private _useBuckBoostSpace: boolean = false;
    private _SpaceReduction: SpaceReduction = SpaceReduction.NoneAttempted;
    private _useESPMode: boolean = false;
    private _IsESPStarted: boolean = false;
    private _forceSpecialESPSpacing: boolean = false;
    private _ESPPivotPressure: number = 0;
    constructor() {
        this.EngineInfo.Reset();
        this.SystemInfo.Reset();
        this.ResetGlobalVariables();
    }

    private FurrowArm = (PackageRec: PackageRecord): number => {
        switch (PackageRec.Drops) {
            case DropTypes.Hose:
                switch (PackageRec.HDUPipeReach) {
                    case UPipeReaches.a12Inch:
                        if (PackageRec.Spacing === 50) {
                            return 120;
                        }
                        break;
                    case UPipeReaches.a20Inch:
                        if (PackageRec.Spacing === 50) {
                            return 200;
                        }
                        break;
                }
                break;
        }
        return 0;
    }

    public CalcEndPressure = (TotalCoverage: number) => {
        let i: number;
        let sLastPressure: number;
        let sPressureLoss: number;
        let sPivotPressure: number = this.PivotPressure;
        if (FloatsAreEqual(sPivotPressure, 0)) {
            return;
        }
        if (!this.OutletControl.SprinklersExist) {
            sPressureLoss = 0;
            for (i = 1; i <= this.SpanCount; i++) {
                sPressureLoss += this.SpanPressureLoss(i, TotalCoverage);
            }
            this.EngineInfo.NonAdjustedEndPressure = sPivotPressure - sPressureLoss;
            this.EngineInfo.AdjustedEndPressure = this.EngineInfo.NonAdjustedEndPressure;
        } else {
            for (i = 1; i <= this.OutletControl.OutletCount; i++) {
                let o: OutletRecord = this.Outlets[i];
                if (o.IsInUse) {
                    sLastPressure = o.PressureAtOutlet;
                    break;
                }
            }
            this.EngineInfo.NonAdjustedEndPressure = sLastPressure;
            this.EngineInfo.AdjustedEndPressure = this.EngineInfo.NonAdjustedEndPressure;
        }
    }

    public get PivotPressure() {
        let i: number;
        let lLastPipeLocation: number;
        let sLastGPM: number;
        let sLastPressure: number;
        let sPressureLoss: number;
        if (!this.SystemInfo.CalculatePivotPressure) {
            return this.SystemInfo.FixedPivotPressure;
        }
        if (!this.OutletControl.SprinklersExist) {
            sPressureLoss = 0;
            for (i = 1; i <= this.SpanCount; i++) {
                sPressureLoss += this.SpanPressureLoss(i);
            }
            return sPressureLoss + this.EngineInfo.AdjustedEndPressure;
        } else {
            for (i = 1; i <= this.OutletControl.OutletCount; i++) {
                let o: OutletRecord = this.Outlets[i];
                if (o.IsInUse) {
                    sLastPressure = o.PressureAtOutlet;
                    lLastPipeLocation = o.PipeLocation;
                    sLastGPM = o.GPMRequired + o.DownstreamGPM;
                    break;
                }
            }
            if (this.OutletControl.OutletCount === 0) {
                return 0;
            } else {
                return sLastPressure + this.PressureLoss(sLastGPM, 0, lLastPipeLocation);
            }
        }
    }

    public set PivotPressure(dat: number) {
        this.SystemInfo.FixedPivotPressure = dat;
        if (FloatsAreEqual(this.SystemInfo.FixedPivotPressure, 0)) {
            this.SystemInfo.CalculatePivotPressure = true;
        } else {
            this.SystemInfo.CalculatePivotPressure = false;
        }
    }

    public get SystemPipeRadius() {
        if (this.OutletControl.OutletCount === 0) {
            return -1;
        }
        if (this._useESPMode) {
            return this.ParentSystemPipeRadius;
        }
        return this.Outlets[this.OutletControl.OutletCount].HookRadius;
    }

    private get ParentSystemPipeRadius() {
        if (this.OutletControl.OutletCount === 0) {
            return -1;
        }
        let i: number;
        for (i = this.OutletControl.OutletCount; i >= 1; i += -1) {
            if (this.Outlets[i].Radius === this.Outlets[i].PipeLocation) {
                return this.Outlets[i].HookRadius;
            }
        }
    }

    public get SystemCoverageRadius() {
        return this.SystemPipeRadius + this.EOSSprinklerCoverage;
    }

    public get ParentSystemCoverageRadius() {
        return this.ParentSystemPipeRadius + this.EOSSprinklerCoverage;
    }

    public Coverage = (): number => {
        if (!this._useESPMode && this.EndGunInfo.EGH.EndGunType !== EndGunTypes.None) {
            return this.SystemPipeRadius + Math.round(this.EndGunInfo.EGH.Radius * 120);
        }
        return this.SystemCoverageRadius;
    }

    private get EOSSprinklerCoverage() {
        if (this.EngineInfo.EndOfSystemCoverage !== 0) {
            return this.EngineInfo.EndOfSystemCoverage;
        }
        if (this.OutletControl.SprinklersExist) {
            return Math.round(
                ((this.Outlets[this.SystemInfo.LastPossibleOutletNumber].Radius - this.Outlets[this.PrevSprinkler(this.PrevSprinkler(this.SystemInfo.LastPossibleOutletNumber))].Radius) / 2.75) - (this.SystemPipeRadius - this.Outlets[this.SystemInfo.LastPossibleOutletNumber].Radius)
            );
        }
        let LastPackageRec: PackageRecord = this.Package[this.LastPkg()];
        if (FloatsAreEqual(this.EngineInfo.AdjustedEndPressure, 0)) {
            throw new SprinklerEngineException(WarningSeverity.Critical, WarningTypes.InvalidData, strLanguageText("sltEstEOSSprinklerCoverage"), strLanguageText("sltEndPressureIsZero"));
        }
        let s: number;
        switch (LastPackageRec.DeviceType) {
            case DeviceTypes.Nelson1518MediumSpacingBrassImpact:
            case DeviceTypes.Senninger12MediumSpacingPlasticImpact:
                return 1200;
                break;
            case DeviceTypes.SenningerLowAngleIWobWhite:
            case DeviceTypes.SenningerLowAngleIWobBlue:
            case DeviceTypes.SenningerStandardAngleIWobGray:
            case DeviceTypes.SenningerHighAngleIWobBlack:
            case DeviceTypes.SenningerXiWobBlue:
            case DeviceTypes.SenningerXiWobGray:
            case DeviceTypes.SenningerXiWobBlack:
            case DeviceTypes.SenningerXiWobWhite:
            case DeviceTypes.SenningerXcelWobbler:
            case DeviceTypes.NelsonS3000SpinnerRed:
            case DeviceTypes.NelsonS3000SpinnerPurple:
            case DeviceTypes.NelsonS3000SpinnerPurpleLowPressure:
            case DeviceTypes.NelsonS3000SpinnerYellow:
            case DeviceTypes.NelsonS3000SpinnerYellowLowPressure:
            case DeviceTypes.NelsonA3000AcceleratorMaroon:
            case DeviceTypes.NelsonA3000AcceleratorGold:
            case DeviceTypes.NelsonA3000AcceleratorNavy:
            case DeviceTypes.NelsonA3000AcceleratorNavyLowPressure:
            case DeviceTypes.SenningerSuperSpray:
            case DeviceTypes.SenningerLDNSpray:
            case DeviceTypes.SenningerQuadSpray:
            case DeviceTypes.NelsonD3000Spray:
            case DeviceTypes.NelsonD3000FCNSpray:
            case DeviceTypes.KometSpray:
                return 240;
                break;
            default:
                if (!this.SystemInfo.IsLateralMove) {
                    s = (6 * this.EngineInfo.SideGPM) / this.MaximumSprinklerGPM(LastPackageRec.DeviceType, this.EngineInfo.AdjustedEndPressure, LastPackageRec.DevicesDoubled) - 2;
                    if (s < 2) {
                        throw new SprinklerEngineException(WarningSeverity.Critical, WarningTypes.SystemParams, strLanguageText("sltEstEOSSprinklerCoverage"), strLanguageText("sltTheSystemGPMAndPressureNotSufficient"));
                    }
                    return Math.round((s - Math.sqrt(Math.pow(s, 2) - 4)) * this.SystemPipeRadius / 2);
                } else {
                    return Math.round(
                        (this.MaximumSprinklerGPM(LastPackageRec.DeviceType, this.EngineInfo.AdjustedEndPressure, LastPackageRec.DevicesDoubled) * this.SystemPipeRadius) / (3 * this.EngineInfo.SideGPM - this.MaximumSprinklerGPM(LastPackageRec.DeviceType, this.EngineInfo.AdjustedEndPressure, LastPackageRec.DevicesDoubled))
                    );
                }
                break;
        }
    }

    public SpanGPMRequired = (iSpan: number): number => {
        let sGPM: number = 0;
        let j: number;
        for (j = 1; j <= this.OutletControl.OutletCount; j++) {
            let o: OutletRecord = this.Outlets[j];
            if (o.SpanNumber > iSpan) {
                break;
            }
            if (o.SpanNumber === iSpan && o.IsInUse && !o.IsESPOutlet) {
                sGPM += o.GPMReqNonAdjusted;
            }
        }
        return sGPM;
    }

    public SpanGPMDelivered = (iSpan: number): number => {
        let sGPM: number = 0;
        let j: number;
        for (j = 1; j <= this.OutletControl.OutletCount; j++) {
            let o: OutletRecord = this.Outlets[j];
            if (o.SpanNumber > iSpan) {
                break;
            }
            if (o.SpanNumber === iSpan && o.IsInUse && !o.IsESPOutlet) {
                sGPM += o.GPMDelivered;
            }
        }
        return sGPM;
    }

    private CalcGPM = (eDevice: DeviceTypes, iNozzle: number, sPressure: number, DevicesDoubled: boolean): number => {
        let nr: NozzleRecord = DeviceNozzle(eDevice).Nozzles(iNozzle);
        let gpm: number = nr.A + nr.B * sPressure + nr.C * Math.pow(sPressure, 2);
        return gpm * (DevicesDoubled ? 2 : 1);
    }

    private MaximumSprinklerGPM = (eDevice: DeviceTypes, sPressure: number, DevicesDoubled: boolean): number => {
        return this.CalcGPM(eDevice, DeviceNozzle(eDevice).Count() - 1, sPressure, DevicesDoubled);
    }

    private GPM = (oldgpm: number, oldpsi: number, newpsi: number): number => {
        if (newpsi < 0) {
            throw new SprinklerEngineException(WarningSeverity.Critical, WarningTypes.InvalidData, strLanguageText("sltGPM"), strLanguageText("sltPSIIsNegative"));
        }
        return oldgpm * Math.sqrt(newpsi / oldpsi);
    }

    private RequiredGPM = (lPrev: number, lNext: number): number => {
        let c: number = this.Coverage();
        if (c === 0) {
            throw new SprinklerEngineException(WarningSeverity.Critical, WarningTypes.InvalidData, strLanguageText("sltRequiredSprinklerGPM"), strLanguageText("sltCoverage"));
        }
        let SystemMotion: SystemMotionEnum = this.SystemInfo.IsLateralMove ? SystemMotionEnum.Lateral : SystemMotionEnum.Pivot;
        return RequiredGPMGivenArc(this.EngineInfo.SideGPM, lPrev / 120, lNext / 120, c / 120, SystemMotion);
    }

    private RequiredSprinklerGPM = (iPrevOutlet: number, lNextLocation: number, bAdjust: boolean): number => {
        let sGPM: number;
        if (iPrevOutlet === 0 && !this.SystemInfo.IsLateralMove) {
            sGPM = this.RequiredGPM(1800, lNextLocation) / 2;
        } else {
            sGPM = this.RequiredGPM(this.ActualOutletRadius(iPrevOutlet), lNextLocation) / 2;
        }
        if (bAdjust) {
            if (iPrevOutlet > 0 && !this._useESPMode) {
                let o: OutletRecord = this.Outlets[iPrevOutlet];
                sGPM += (o.GPMRequired - o.GPMDelivered) / 2;
            }
        }
        if (sGPM < 0) {
            sGPM = 0.1;
        }
        return sGPM;
    }

    private ForecastRequiredSprinklerGPM = (iPrevOutlet: number, iCurOutlet: number): number => {
        let lNextLocation: number;
        let i: number;
        let iNewOutlet: number;
        if (iCurOutlet === this.SystemInfo.LastPossibleOutletNumber) {
            lNextLocation = this.SystemCoverageRadius * 2 - this.Outlets[iCurOutlet].Radius;
        } else {
            lNextLocation = 2 * this.Outlets[iCurOutlet].Radius - this.ActualOutletRadius(iPrevOutlet);
            let exitFor = false;
            for (i = iCurOutlet + 1; i <= this.SystemInfo.LastPossibleOutletNumber; i++) {
                switch (this.GetOutletType(i)) {
                    case OutletTypes.Imaginary:
                        break;
                    default:
                        if (this.MinOutletRadius(i) > lNextLocation) {
                            exitFor = true;
                        }
                        break;
                }
                if (exitFor) {
                    break;
                }
            }
            if (i >= this.SystemInfo.LastPossibleOutletNumber) {
                iNewOutlet = this.SystemInfo.LastPossibleOutletNumber;
            } else if (i === iCurOutlet + 1) {
                iNewOutlet = i;
            } else if (i === iCurOutlet + 2) {
                if (this.GetOutletType(i - 1) === OutletTypes.Regular) {
                    iNewOutlet = i - 1;
                } else {
                    iNewOutlet = i;
                }
            } else if (true) {
                if (this.GetOutletType(i - 1) === OutletTypes.Regular) {
                    iNewOutlet = i - 1;
                } else {
                    iNewOutlet = i - 2;
                }
            }
            lNextLocation = this.Outlets[iNewOutlet].Radius;
        }
        return this.RequiredSprinklerGPM(iPrevOutlet, lNextLocation, true);
    }

    private HWPressureLoss = (sGPM: number, lDistance: number, iCFactor: number, sInsideDiameter: number): number => {
        return PressureLossHazenWilliams(sGPM, lDistance / 120, iCFactor, sInsideDiameter);
    }

    private SimpsonsRulePressureLoss = (lStart: number, lEnd: number, lStartRadius: number, lEndRadius: number, sInsideDiameter: number, iCFactor: number, TotalCoverage: number = 0): number => {
        let c: number;
        if (TotalCoverage > 0) {
            c = TotalCoverage;
        } else {
            c = this.Coverage();
            if (c === 0) {
                throw new SprinklerEngineException(WarningSeverity.Critical, WarningTypes.InvalidData, strLanguageText("sltRequiredSprinklerGPM"), strLanguageText("sltCoverage"));
            }
        }
        let SystemMotion: SystemMotionEnum = this.SystemInfo.IsLateralMove ? SystemMotionEnum.Lateral : SystemMotionEnum.Pivot;
        return PressureLossSimpsonsRule(this.EngineInfo.SideGPM, lStart / 120, lEnd / 120, lStartRadius / 120, lEndRadius / 120, c / 120, SystemMotion, iCFactor, sInsideDiameter);
    }

    private SpanPressureLoss = (spanNumber: number, TotalCoverage: number = 0): number => {
        let s: SpanRecord = this.Spans[spanNumber];
        if (FloatsAreEqual(s.InsideDiameter, 0)) {
            throw new SprinklerEngineException(WarningSeverity.Critical, WarningTypes.InvalidData, strLanguageText("sltPressureLoss"), strLanguageText("sltInsideDiameter"));
        }
        if (s.CFactor === 0) {
            throw new SprinklerEngineException(WarningSeverity.Critical, WarningTypes.InvalidData, strLanguageText("sltPressureLoss"), strLanguageText("sltCFactor"));
        }
        return this.SimpsonsRulePressureLoss(s.StartLocation, s.EndLocation, s.StartRadius, s.EndRadius, s.InsideDiameter, s.CFactor, TotalCoverage);
    }

    private PressureLoss = (sGPM: number, startLocation: number, endLocation: number): number => {
        let startSpan: number = this.GetSpanNumberByLocation(startLocation);
        let endSpan: number = this.GetSpanNumberByLocation(endLocation);
        if (startSpan !== endSpan) {
            if (startLocation === this.Spans[startSpan].EndLocation && this.Spans[startSpan].EndLocation === this.Spans[endSpan].StartLocation) {
                startSpan += 1;
            } else {
                return this.PressureLoss(sGPM, startLocation, this.Spans[startSpan].EndLocation) + this.PressureLoss(sGPM, this.Spans[endSpan].StartLocation, endLocation);
            }
        }
        let s: SpanRecord = this.Spans[startSpan];
        if (FloatsAreEqual(s.InsideDiameter, 0)) {
            throw new SprinklerEngineException(WarningSeverity.Critical, WarningTypes.InvalidData, strLanguageText("sltPressureLoss"), strLanguageText("sltInsideDiameter"));
        }
        if (s.CFactor === 0) {
            throw new SprinklerEngineException(WarningSeverity.Critical, WarningTypes.InvalidData, strLanguageText("sltPressureLoss"), strLanguageText("sltCFactor"));
        }
        if (sGPM < 0) {
            throw new SprinklerEngineException(WarningSeverity.Critical, WarningTypes.InvalidData, strLanguageText("sltPressureLoss"), `Error computing Pressure Loss - ${strLanguageText("sltGPM")}: ${strLanguageText("sGPM")}`);
        }
        return this.HWPressureLoss(sGPM, (endLocation - startLocation), s.CFactor, s.InsideDiameter);
    }

    private PressureLossEstimate = (startLocation: number, endLocation: number, lStartRadius: number, lEndRadius: number): number => {
        let startSpan: number = this.GetSpanNumberByLocation(startLocation);
        let endSpan: number = this.GetSpanNumberByLocation(endLocation);
        let retry;
        do {
            retry = false;
            if (startSpan !== endSpan) {
                if (startLocation === this.Spans[startSpan].EndLocation && this.Spans[startSpan].EndLocation === this.Spans[startSpan + 1].StartLocation) {
                    startSpan += 1;
                    retry = true;
                } else {
                    return this.PressureLossEstimate(startLocation, this.Spans[startSpan].EndLocation, lStartRadius, this.Spans[startSpan].EndRadius) + this.PressureLossEstimate(this.Spans[startSpan].EndLocation, endLocation, this.Spans[startSpan].EndRadius, lEndRadius);
                }
            }
        } while (retry);
        let s: SpanRecord = this.Spans[startSpan];
        if (FloatsAreEqual(s.InsideDiameter, 0)) {
            throw new SprinklerEngineException(WarningSeverity.Critical, WarningTypes.InvalidData, strLanguageText("sltPressureLoss"), strLanguageText("sltInsideDiameter"));
        }
        return this.SimpsonsRulePressureLoss(startLocation, endLocation, lStartRadius, lEndRadius, s.InsideDiameter, s.CFactor);
    }

    public get PressureExceededCount() {
        return this.OutletControl.PressureExceededCount;
    }

    public get PressureNotMetCount() {
        return this.OutletControl.PressureNotMetCount;
    }

    private NextSprinkler = (iCurOutlet: number): number => {
        let i: number;
        if (iCurOutlet === this.OutletControl.OutletCount) {
            return 0;
        }
        for (i = iCurOutlet + 1; i <= this.OutletControl.OutletCount; i++) {
            if (this.Outlets[i].IsInUse) {
                return i;
            }
        }
        return 0;
    }

    private PrevSprinkler = (iCurOutlet: number): number => {
        for (let i: number = iCurOutlet - 1; i >= 1; i += -1) {
            if (this.Outlets[i].IsInUse) {
                return i;
            }
        }
        return 0;
    }

    private SwitchESPMode = (bOn: boolean) => {
        if (bOn) {
            this._useESPMode = true;
        } else {
            this._useESPMode = false;
        }
    }

    private LastPkg = (): number => {
        for (let i: number = MaxPackages; i >= 0; i += -1) {
            if (i === 0) {
                return 0;
            }
            if (this.Package[i].DeviceType !== DeviceTypes.None) {
                return i;
            }
        }
    }

    private GetSpanNumberByLocation = (location: number): number => {
        if (location < -1) {
            throw new Error();
            /* TODO: Throw New ArgumentException($"{NameOf(GetSpanNumberByLocation)}: {NameOf(location)}: '{location}', value cannot be less than -1 ") */
        }
        if (location === 0) {
            return 1;
        }
        if (location === -1) {
            return this.SpanCount;
        }
        for (let i: number = 1; i <= this.SpanCount; i++) {
            let s: SpanRecord = this.Spans[i];
            if (location >= s.StartLocation && location <= s.EndLocation) {
                return i;
            }
        }
    }

    private IsSACOutlet = (i: number): boolean => {
        return this.Outlets[i].PipeLocation !== this.Outlets[i].Radius;
    }

    private IsFurrowArm = (i: number): boolean => {
        return this.FurrowArm(this.Package[this.Outlets[i].PackageNumber]) > 0;
    }

    private MinOutletRadius = (i: number): number => {
        return this.Outlets[i].Radius - this.FurrowArm(this.Package[this.Outlets[i].PackageNumber]);
    }

    private MaxOutletRadius = (i: number): number => {
        return this.Outlets[i].Radius + this.FurrowArm(this.Package[this.Outlets[i].PackageNumber]);
    }

    private ActualOutletRadius = (i: number): number => {
        let o: OutletRecord = this.Outlets[i];
        return o.Radius + o.FurrowArmAdjustment;
    }

    private GetAnOutletHalfwayBetween = (iCurOutlet: number, iNext: number): number => {
        if (iNext - iCurOutlet === 2) {
            return iCurOutlet + 1;
        }
        let lCurRadius: number = this.ActualOutletRadius(iCurOutlet);
        let lNextRadius: number = this.ActualOutletRadius(iNext);
        let lIdealRadius: number = lCurRadius + (lNextRadius - lCurRadius) * 0.5;
        let i: number;
        let iTest1: number;
        let iTest2: number;
        for (i = iCurOutlet + 1; i <= iNext - 1; i++) {
            if (this.Outlets[i].Radius > lIdealRadius) {
                break;
            }
        }
        if (i === iNext) {
            i = iNext - 1;
        }
        iTest2 = i;
        if (i - 1 === iCurOutlet) {
            i += 1;
        }
        iTest1 = i - 1;
        let lTest1Dist: number = Math.abs(this.Outlets[iTest1].Radius - lIdealRadius);
        let lTest2Dist: number = Math.abs(this.Outlets[iTest2].Radius - lIdealRadius);
        if (lTest1Dist <= lTest2Dist) {
            return iTest1;
        } else {
            return iTest2;
        }
    }

    private SelectReg = (PackageRec: PackageRecord, sGPMReq: number): RegulatorTypes => {
        switch (PackageRec.DeviceType) {
            case DeviceTypes.Nelson15Single3RNBrassImpact:
            case DeviceTypes.Nelson15Double3RNBrassImpact:
            case DeviceTypes.Nelson8Double3RNPlasticImpact:
                switch (PackageRec.RegulatorType) {
                    case RegulatorTypes.NelsonBlueLFHF:
                        return RegulatorTypes.NelsonBlueFTHF;
                        break;
                }
                return RegulatorTypes.None;
                break;
        }
        switch (PackageRec.RegulatorType) {
            case RegulatorTypes.NelsonBlueLFHF:
                if ((sGPMReq >= 6 && PackageRec.RegulatorPSI === RegulatorPressures.a6) || sGPMReq >= 8 || this._highFlowCount >= 3) {
                    this._highFlowCount += 1;
                    if (PackageRec.ThreadType === ThreadTypes.Square) {
                        return RegulatorTypes.NelsonBlueSTHF;
                    } else {
                        return RegulatorTypes.NelsonBlueFTHF;
                    }
                } else {
                    if (PackageRec.ThreadType === ThreadTypes.Square) {
                        return RegulatorTypes.NelsonBlueSTLF;
                    } else {
                        return RegulatorTypes.NelsonBlueFTLF;
                    }
                }
                break;
            case RegulatorTypes.SenningerLFMF:
                if ((sGPMReq >= 4.5 && (PackageRec.RegulatorPSI === RegulatorPressures.a6 || PackageRec.RegulatorPSI === RegulatorPressures.a10)) || sGPMReq >= 6 || (PackageRec.RegulatorPSI === RegulatorPressures.a25 || PackageRec.RegulatorPSI === RegulatorPressures.a30 || PackageRec.RegulatorPSI === RegulatorPressures.a40) || this._highFlowCount >= 3) {
                    this._highFlowCount += 1;
                    return RegulatorTypes.SenningerMF;
                } else {
                    return RegulatorTypes.SenningerLF;
                }
                break;
            case RegulatorTypes.KometKPR:
                return RegulatorTypes.KometKPR;
                break;
            case RegulatorTypes.SenningerPSR:
                return RegulatorTypes.SenningerPSR;
                break;
            case RegulatorTypes.KometKPR:
                return RegulatorTypes.KometKPR;
                break;
        }
    }

    private SelectPressure = (iOutlet: number): OutletPressureData => {
        let result = new OutletPressureData();
        let curOutlet = this.Outlets[iOutlet];
        let iLastSprinkler: number = this.PrevSprinkler(iOutlet);
        let sLastPressure: number;
        let sLastGPM: number;
        let lLastLocation: number;
        if (iLastSprinkler === 0) {
            sLastPressure = this.PivotPressure;
            sLastGPM = this.EngineInfo.SideGPM;
            lLastLocation = 0;
        } else {
            let o: OutletRecord = this.Outlets[iLastSprinkler];
            sLastPressure = o.PressureAtOutlet;
            sLastGPM = o.DownstreamGPM;
            lLastLocation = o.PipeLocation;
        }
        if (sLastGPM < -30) {
            let s = Math.floor(curOutlet.PipeLocation / 120).toFixed(2);
            s = "Insufficient GPM at " + s + " feet";
            throw new SprinklerEngineException(WarningSeverity.Critical, WarningTypes.OutofWater, strLanguageText("sltSelectPressure"), s)
        } else if (sLastGPM <= 0) {
            sLastGPM = 0;
        }
        if (!this._useESPMode) {
            result.PressureAtOutlet = sLastPressure - this.PressureLoss(sLastGPM, lLastLocation, curOutlet.PipeLocation);
        } else {
            result.PressureAtOutlet = this._ESPPivotPressure - this.PressureLossEstimate(this.Outlets[0].PipeLocation, curOutlet.PipeLocation, this.Outlets[0].Radius, curOutlet.Radius);
        }
        if (iOutlet > 0) {
            if (this.Package[curOutlet.PackageNumber].FixedDropLength > 0) {
                result.PressureAtDevice = result.PressureAtOutlet - (-this.Package[curOutlet.PackageNumber].FixedDropLength) * 0.4;
            } else if (this.Package[curOutlet.PackageNumber].GroundClearance > 0) {
                result.PressureAtDevice = result.PressureAtOutlet + (curOutlet.AbsoluteHeight - this.Package[curOutlet.PackageNumber].GroundClearance) * 0.4;
            } else {
                result.PressureAtDevice = result.PressureAtOutlet;
            }
        } else {
            result.PressureAtDevice = result.PressureAtOutlet;
        }
        let eRegPSI: RegulatorPressures;
        if (iOutlet > 0) {
            eRegPSI = curOutlet.RegulatorPSI;
        } else {
            eRegPSI = this.Package[curOutlet.PackageNumber].RegulatorPSI;
        }
        result.RegPressure = result.PressureAtDevice;
        result.RegAvail = this.Package[curOutlet.PackageNumber].RegulatorType !== RegulatorTypes.None;
        result.RegPressureMet = true;
        if (result.RegAvail) {
            result.ForceReg = !this.Package[curOutlet.PackageNumber].RegsAsNeeded;
            if (curOutlet.IsTowerBoomBackOutlet) {
                switch (eRegPSI) {
                    case RegulatorPressures.a6:
                        result.RegPressure = 6;
                        break;
                    case RegulatorPressures.a10:
                        result.RegPressure = 10;
                        break;
                    case RegulatorPressures.a15:
                        result.RegPressure = 15;
                        break;
                    default:
                        result.RegPressure = 20;
                        break;
                }
            } else {
                switch (eRegPSI) {
                    case RegulatorPressures.a6:
                        result.RegPressure = 6;
                        break;
                    case RegulatorPressures.a10:
                        result.RegPressure = 10;
                        break;
                    case RegulatorPressures.a15:
                        result.RegPressure = 15;
                        break;
                    case RegulatorPressures.a20:
                        result.RegPressure = 20;
                        break;
                    case RegulatorPressures.a25:
                        result.RegPressure = 25;
                        break;
                    case RegulatorPressures.a30:
                        result.RegPressure = 30;
                        break;
                    case RegulatorPressures.a40:
                        result.RegPressure = 40;
                        break;
                }
            }
            let sPressureAtRegulator: number;
            if (this.Package[curOutlet.PackageNumber].IsRegOnTop) {
                sPressureAtRegulator = result.PressureAtOutlet;
                result.RegPressure += result.PressureAtDevice - result.PressureAtOutlet;
            } else {
                sPressureAtRegulator = result.PressureAtDevice;
            }
            if (sPressureAtRegulator - this.EngineInfo.Elevation / 2.31 < result.RegPressure + 3) {
                result.RegPressureMet = false;
            }
        }
        return result;
    }

    private SelectBestNozzle = (eDevice: DeviceTypes, sGPMReq: number, sPressure: number, ExcludeNozzle1: number, ExcludeNozzle2: number, bExcludeD3000: boolean, DevicesDoubled: boolean): SelectBestNozzleResult => {
        let result = new SelectBestNozzleResult();
        let dns: DeviceNozzleSet = DeviceNozzle(eDevice);
        let lastGPMDelta: number = 0;
        for (let i: number = 0; i <= dns.Count() - 1; i++) {
            if (i === ExcludeNozzle1) {
                continue;
            }
            if (i === ExcludeNozzle2) {
                continue;
            }
            if (bExcludeD3000) {
                if (ExcludeNozzle1 % 2 === i % 2) {
                    continue;
                }
            }
            let sTrialGPM: number = this.CalcGPM(eDevice, i, sPressure, DevicesDoubled);
            if (result.PreferredNozzle === 0 || Math.abs(sTrialGPM - sGPMReq) < Math.abs(lastGPMDelta)) {
                lastGPMDelta = sTrialGPM - sGPMReq;
                result.GPMDelivered = sTrialGPM;
                result.GPMDelta = sTrialGPM - sGPMReq;
                result.PreferredNozzle = i;
            }
            if (result.PreferredNozzle > 0 && result.PreferredNozzle + 1 < i) {
                break;
            }
        }
        if (result.PreferredNozzle === 0) {
            if (this.SystemInfo.IsLateralMove) {
                throw new SprinklerEngineException(WarningSeverity.Critical, WarningTypes.NozzleSpacingNotMet, strLanguageText("sltSelectNozzle"), strLanguageText("sltSpacingRequirementExceedsNarrower"));
            }
            throw new SprinklerEngineException(WarningSeverity.Critical, WarningTypes.NozzleCritical, strLanguageText("sltSelectNozzle"), strLanguageText("sltCriticalErrorPleaseContact"));
        }
        return result;
    }

    private ComputeMaxGPM = (eDevice: DeviceTypes, sPressure: number, DevicesDoubled: boolean): number => {
        let dns: DeviceNozzleSet = DeviceNozzle(eDevice);
        let maxNozzle: number = dns.Count() - 1;
        let sTrialGPM: number = this.CalcGPM(eDevice, maxNozzle, sPressure, DevicesDoubled);
        return sTrialGPM;
    }

    private CheckNozzlePressure = (eDevice: DeviceTypes, sPressure: number, iNozzle: number): NozzlePressureCheck => {
        let dns: DeviceNozzleSet = DeviceNozzle(eDevice);
        let nozzle: NozzleRecord = dns.Nozzles(iNozzle);
        if (nozzle.MaxPSI < sPressure) {
            return NozzlePressureCheck.TooHigh;
        } else if (nozzle.MinPSI > sPressure) {
            return NozzlePressureCheck.TooLow;
        } else {
            return NozzlePressureCheck.OK;
        }
    }

    private SelectNozzle = (iOutlet: number, GPMRequired: number): SelectNozzleResult => {
        let result: SelectNozzleResult = Object.assign(
            new SelectNozzleResult(), {
                GPMRequired
            });
        let curOutlet = this.Outlets[iOutlet];
        curOutlet.RegulatorPSI = this.Package[curOutlet.PackageNumber].RegulatorPSI;

        let iBestW: number;
        let sGPMDelW: number;
        let iBestWO: number;
        let iBestWO2ndChoice: number;
        let sGPMDelWO2ndChoice: number;
        let sGPMDeltaWO2ndChoice: number;
        let sGPMDelWO: number;
        let eNozPressWO: NozzlePressureCheck;
        let bForecastGPM: boolean;
        let outletPressureData: OutletPressureData;

        let retry;
        do {
            retry = false;
            outletPressureData = this.SelectPressure(iOutlet);
            result.PressureAtOutlet = outletPressureData.PressureAtOutlet;
            result.PressureAtDevice = outletPressureData.PressureAtDevice;
            if (outletPressureData.ForceReg && !outletPressureData.RegPressureMet) {
                throw new SprinklerEngineException(WarningSeverity.Critical, WarningTypes.SystemParams, strLanguageText("sltSelectNozzle"), strLanguageText("sltRegulatorPressureNotMetRaise"));
            }
            let iPrevSprinkler: number = this.PrevSprinkler(iOutlet);
            if (!this._useESPMode) {
                if (FloatsAreEqual(result.GPMRequired, 0)) {
                    bForecastGPM = true;
                    result.GPMRequired = this.ForecastRequiredSprinklerGPM(iPrevSprinkler, iOutlet);
                }
            } else {
                result.GPMRequired = this.ComputeESPGPM(iPrevSprinkler, this.NextSprinkler(iOutlet), this.NextSprinkler(this.NextSprinkler(iOutlet)));
            }
            if (curOutlet.IsTowerBoomBackOutlet) {
                result.DeviceType = this.TranslateBoomBackToDeviceType(this.EngineInfo.BoomBackDevice);
                curOutlet.DeviceType = result.DeviceType;
            } else {
                result.DeviceType = this.Package[curOutlet.PackageNumber].DeviceType;
            }
            let gotoTryWithRegs = false;
            if (outletPressureData.ForceReg) {
                gotoTryWithRegs = true;
            }
            let gotoReachConclusion = false;
            if (!gotoTryWithRegs) {
                result.GPMMaximum = this.ComputeMaxGPM(result.DeviceType, outletPressureData.PressureAtDevice, curOutlet.DeviceDoubled);
                let bestNozzleResultWithoutRegulator1st = this.SelectBestNozzle(result.DeviceType, result.GPMRequired, outletPressureData.PressureAtDevice, 0, 0, false, curOutlet.DeviceDoubled);
                iBestWO = bestNozzleResultWithoutRegulator1st.PreferredNozzle;
                sGPMDelWO = bestNozzleResultWithoutRegulator1st.GPMDelivered;
                let sGPMDeltaWO: number = bestNozzleResultWithoutRegulator1st.GPMDelta;
                eNozPressWO = this.CheckNozzlePressure(result.DeviceType, outletPressureData.PressureAtDevice, iBestWO);
                iBestWO2ndChoice = 0;
                sGPMDelWO2ndChoice = 0;
                sGPMDeltaWO2ndChoice = 0;

                switch (eNozPressWO) {
                    case NozzlePressureCheck.OK:
                        gotoReachConclusion = true;
                        if (outletPressureData.RegAvail && outletPressureData.RegPressureMet && (iBestWO === 1 || (result.DeviceType === DeviceTypes.NelsonD3000Spray && iBestWO === 2))) {
                            if (sGPMDelWO / result.GPMRequired > 1.5) {
                                gotoReachConclusion = false;
                            }
                        }
                        break;
                    case NozzlePressureCheck.TooHigh:
                    case NozzlePressureCheck.TooLow:
                        let bestNozzleResultWithoutRegulator2nd = this.SelectBestNozzle(result.DeviceType, result.GPMRequired, outletPressureData.PressureAtDevice, iBestWO, 0, (result.DeviceType === DeviceTypes.NelsonD3000Spray), curOutlet.DeviceDoubled);
                        iBestWO2ndChoice = bestNozzleResultWithoutRegulator2nd.PreferredNozzle;
                        sGPMDelWO2ndChoice = bestNozzleResultWithoutRegulator2nd.GPMDelivered;
                        sGPMDeltaWO2ndChoice = bestNozzleResultWithoutRegulator2nd.GPMDelta;
                        if (this.CheckNozzlePressure(result.DeviceType, outletPressureData.PressureAtDevice, iBestWO2ndChoice) !== NozzlePressureCheck.OK) {
                            iBestWO2ndChoice = 0;
                        } else if ((result.DeviceType === DeviceTypes.NelsonD3000Spray)) {
                            gotoReachConclusion = true;
                        } else if (Math.abs(sGPMDeltaWO2ndChoice / result.GPMRequired) - Math.abs(sGPMDeltaWO / result.GPMRequired) > 0.02) {
                            iBestWO2ndChoice = 0;
                        } else {
                            gotoReachConclusion = true;
                        }
                        if (!gotoReachConclusion && eNozPressWO === NozzlePressureCheck.TooLow) {
                            switch (result.DeviceType) {
                                case DeviceTypes.NelsonR3000RotatorBlue:
                                case DeviceTypes.NelsonR3000RotatorWhite:
                                case DeviceTypes.NelsonR3000RotatorGreen:
                                    if (iBestWO === 1 || iBestWO === 2) {
                                        bestNozzleResultWithoutRegulator2nd = this.SelectBestNozzle(result.DeviceType, result.GPMRequired, outletPressureData.RegPressure, iBestWO, 2, false, curOutlet.DeviceDoubled);
                                        iBestWO2ndChoice = bestNozzleResultWithoutRegulator2nd.PreferredNozzle;
                                        sGPMDelWO2ndChoice = bestNozzleResultWithoutRegulator2nd.GPMDelivered;
                                        sGPMDeltaWO2ndChoice = bestNozzleResultWithoutRegulator2nd.GPMDelta;
                                        if (this.CheckNozzlePressure(result.DeviceType, outletPressureData.RegPressure, iBestWO2ndChoice) === NozzlePressureCheck.OK) {
                                            iBestWO = iBestWO2ndChoice;
                                            sGPMDeltaWO = sGPMDeltaWO2ndChoice;
                                            sGPMDelWO = sGPMDelWO2ndChoice;
                                            gotoReachConclusion = true;
                                        }
                                    } else {
                                        gotoReachConclusion = true;
                                    }
                                    break;
                                default:
                                    gotoReachConclusion = true;
                                    break;
                            }
                        }
                        break;
                }
            }
            if (!gotoReachConclusion) {
                result.GPMMaximum = this.ComputeMaxGPM(result.DeviceType, outletPressureData.RegPressure, curOutlet.DeviceDoubled);
                if (!outletPressureData.RegAvail || !outletPressureData.RegPressureMet) {
                    gotoReachConclusion = true;
                }
                if (!gotoReachConclusion) {
                    let bestNozzleResultWithRegulator1st = this.SelectBestNozzle(result.DeviceType, result.GPMRequired, outletPressureData.RegPressure, 0, 0, false, curOutlet.DeviceDoubled);
                    iBestW = bestNozzleResultWithRegulator1st.PreferredNozzle;
                    sGPMDelW = bestNozzleResultWithRegulator1st.GPMDelivered;
                    let sGPMDeltaW = bestNozzleResultWithRegulator1st.GPMDelta;
                    let bestNozzleResultWithRegulator2nd: SelectBestNozzleResult = null;
                    let iBestW2ndChoice: number = 0;
                    let sGPMDelW2ndChoice: number = 0;
                    let sGPMDeltaW2ndChoice: number = 0;
                    switch (this.CheckNozzlePressure(result.DeviceType, outletPressureData.RegPressure, iBestW)) {
                        case NozzlePressureCheck.OK:
                            break;
                        case NozzlePressureCheck.TooHigh:
                            throw new SprinklerEngineException(WarningSeverity.Critical, WarningTypes.NozzlePressureExceeded, strLanguageText("sltSelectNozzle"),
                                                StringFormat(strLanguageText("sltRegulatorPressureExceedsAt"), Math.floor(curOutlet.PipeLocation / 120)));
                            break;
                        case NozzlePressureCheck.TooLow:
                            switch (result.DeviceType) {
                                case DeviceTypes.NelsonD3000Spray:
                                    bestNozzleResultWithRegulator2nd = this.SelectBestNozzle(result.DeviceType, result.GPMRequired, outletPressureData.RegPressure, iBestW, 0, true, curOutlet.DeviceDoubled);
                                    iBestW2ndChoice = bestNozzleResultWithRegulator2nd.PreferredNozzle;
                                    sGPMDelW2ndChoice = bestNozzleResultWithRegulator2nd.GPMDelivered;
                                    sGPMDeltaW2ndChoice = bestNozzleResultWithRegulator2nd.GPMDelta;
                                    if (this.CheckNozzlePressure(result.DeviceType, outletPressureData.RegPressure, iBestW2ndChoice) === NozzlePressureCheck.OK) {
                                        iBestW = iBestW2ndChoice;
                                        sGPMDeltaW = sGPMDeltaW2ndChoice;
                                        sGPMDelW = sGPMDelW2ndChoice;
                                    }
                                    break;
                                case DeviceTypes.NelsonS3000SpinnerRed:
                                    break;
                                case DeviceTypes.NelsonR3000RotatorBlue:
                                case DeviceTypes.NelsonR3000RotatorWhite:
                                case DeviceTypes.NelsonR3000RotatorGreen:
                                    if (iBestW === 1 || iBestW === 2) {
                                        bestNozzleResultWithRegulator2nd = this.SelectBestNozzle(result.DeviceType, result.GPMRequired, outletPressureData.RegPressure, iBestW, 2, false, curOutlet.DeviceDoubled);
                                        iBestW2ndChoice = bestNozzleResultWithRegulator2nd.PreferredNozzle;
                                        sGPMDelW2ndChoice = bestNozzleResultWithRegulator2nd.GPMDelivered;
                                        sGPMDeltaW2ndChoice = bestNozzleResultWithRegulator2nd.GPMDelta;
                                        if (this.CheckNozzlePressure(result.DeviceType, outletPressureData.RegPressure, iBestW2ndChoice) === NozzlePressureCheck.OK) {
                                            iBestW = iBestW2ndChoice;
                                            sGPMDeltaW = sGPMDeltaW2ndChoice;
                                            sGPMDelW = sGPMDelW2ndChoice;
                                        }
                                    } else {
                                        throw new SprinklerEngineException(WarningSeverity.Critical, WarningTypes.NozzlePressureNotMet, strLanguageText("sltSelectNozzle"),
                                                                        StringFormat(strLanguageText("sltRegulatorPressureDoesNotPackage"), outletPressureData.RegPressure, Math.floor(curOutlet.PipeLocation / 120)));
                                    }
                                    break;
                                case DeviceTypes.NelsonA3000AcceleratorGold:
                                case DeviceTypes.NelsonA3000AcceleratorMaroon:
                                case DeviceTypes.NelsonA3000AcceleratorNavyLowPressure:
                                    switch (curOutlet.RegulatorPSI) {
                                        case RegulatorPressures.a6:
                                            curOutlet.RegulatorPSI = RegulatorPressures.a10;
                                            retry = true;
                                            break;
                                        case RegulatorPressures.a10:
                                            curOutlet.RegulatorPSI = RegulatorPressures.a15;
                                            retry = true;
                                            break;
                                        default:
                                            throw new SprinklerEngineException(WarningSeverity.Critical, WarningTypes.NozzlePressureNotMet, strLanguageText("sltSelectNozzle"),
                                                            StringFormat(strLanguageText("sltRegulatorPressureDoesNotPackage"), outletPressureData.RegPressure, Math.floor(curOutlet.PipeLocation / 120)));
                                    }
                                    
                                    break;
                                default:
                                    throw new SprinklerEngineException(WarningSeverity.Critical, WarningTypes.NozzlePressureNotMet, strLanguageText("sltSelectNozzle"),
                                                                StringFormat(strLanguageText("sltRegulatorPressureDoesNotPackage"), outletPressureData.RegPressure, Math.floor(curOutlet.PipeLocation / 120)));
                                    break;
                            }
                            break;
                    }
                }
            }
        } while (retry);

        if (iBestW > 0) {
            result.PreferredNozzle = iBestW;
            result.GPMDeliverd = sGPMDelW;
            result.RegUsed = true;
        } else if (iBestWO2ndChoice > 0) {
            result.PreferredNozzle = iBestWO2ndChoice;
            result.GPMDeliverd = sGPMDelWO2ndChoice;
            result.RegUsed = false;
        } else if (iBestWO > 0) {
            switch (eNozPressWO) {
                case NozzlePressureCheck.TooHigh:
                    throw new SprinklerEngineException(WarningSeverity.Critical, WarningTypes.NozzlePressureExceeded, strLanguageText("sltSelectNozzle"), StringFormat(strLanguageText("sltInlinePressureTooHigh"), Math.round(outletPressureData.RegPressure), Math.floor(curOutlet.PipeLocation / 120)) + "\n" + strLanguageText("sltYouMustEitherLower"));
                    break;
                case NozzlePressureCheck.TooLow:
                    if (!bForecastGPM) {
                        // TODO
                        /*switch (MsgBox(String.Format(strLanguageText("sltInlinePressureTooLow"), Math.floor(curOutlet.PipeLocation / 120)) + ControlChars.CrLf + strLanguageText("sltYouShouldEitherIncrease") + Chr(13) + Chr(13) + strLanguageText("sltDoYouWishContinueLow"), MsgBoxStyle.YesNo)) {
                            case MsgBoxResult.Yes:
                                break;
                            case MsgBoxResult.No:
                                throw new Error();
                                throw new SprinklerEngineException(WarningSeverity.UserStop, WarningTypes.UserStop, strLanguageText("sltSelectNozzle"), "")
                                break;
                        }*/
                    }
                    break;
            }
            result.PreferredNozzle = iBestWO;
            result.GPMDeliverd = sGPMDelWO;
            result.RegUsed = false;
        } else if (outletPressureData.RegAvail && !outletPressureData.RegPressureMet) {
            throw new SprinklerEngineException(WarningSeverity.Critical, WarningTypes.SystemParams, strLanguageText("sltSelectNozzle"), strLanguageText("sltRegulatorPressureNotMetRaise"));
        }
        if (result.PreferredNozzle === 0) {
            throw new SprinklerEngineException(WarningSeverity.Critical, WarningTypes.NozzleCritical, strLanguageText("sltSelectNozzle"), strLanguageText("sltCriticalErrorPleaseContact"))
        }
        return result;
    }

    private MaxGPM = (iOutlet: number): OutletMaxGPMData => {
        let sMaxGPM: number;
        let sRegMaxGPM: number;
        let eDevice: DeviceTypes;
        let outletPressureData = this.SelectPressure(iOutlet);
        if (outletPressureData.ForceReg && !outletPressureData.RegPressureMet) {
            throw new SprinklerEngineException(WarningSeverity.Critical, WarningTypes.SystemParams, strLanguageText("sltSelectNozzle"), strLanguageText("sltRegulatorPressureNotMetRaise"));
        }
        let oRec: OutletRecord = this.Outlets[iOutlet];
        if (oRec.IsTowerBoomBackOutlet) {
            eDevice = this.TranslateBoomBackToDeviceType(this.EngineInfo.BoomBackDevice);
        } else {
            eDevice = this.Package[oRec.PackageNumber].DeviceType;
        }
        let nozzleCount: number = DeviceNozzle(eDevice).Count() - 1;
        let j: number;
        if (!outletPressureData.ForceReg) {
            for (j = nozzleCount; j >= 1; j += -1) {
                let nozzle: NozzleRecord = DeviceNozzle(eDevice).Nozzles(j);
                if (nozzle.MaxPSI >= outletPressureData.PressureAtDevice && nozzle.MinPSI <= outletPressureData.PressureAtDevice) {
                    break;
                }
            }
            if (j === 0) {
                j = 1;
            }
            sMaxGPM = this.CalcGPM(eDevice, j, outletPressureData.PressureAtDevice, oRec.DeviceDoubled);
        }
        if (outletPressureData.RegAvail && outletPressureData.RegPressureMet) {
            for (j = nozzleCount; j >= 1; j += -1) {
                if (DeviceNozzle(eDevice).Nozzles(j).MaxPSI >= outletPressureData.RegPressure && DeviceNozzle(eDevice).Nozzles(j).MinPSI <= outletPressureData.RegPressure) {
                    break;
                }
            }
            if (j === 0) {
                j = 1;
            }
            sRegMaxGPM = this.CalcGPM(eDevice, j, outletPressureData.RegPressure, oRec.DeviceDoubled);
        } else {
            sRegMaxGPM = 0;
        }
        let outletMaxGPMData: OutletMaxGPMData = Object.assign(
            new OutletMaxGPMData(), {
                OutletPressureData: outletPressureData
        });
        if (outletPressureData.ForceReg || sRegMaxGPM > sMaxGPM) {
            outletMaxGPMData.MaxGPM = sRegMaxGPM;
            outletMaxGPMData.MaxGPMIgnoreMinPressure = 0;
        } else {
            outletMaxGPMData.MaxGPM = sMaxGPM;
            outletMaxGPMData.MaxGPMIgnoreMinPressure = this.CalcGPM(eDevice, nozzleCount, outletPressureData.PressureAtDevice, oRec.DeviceDoubled);
        }
        return outletMaxGPMData;
    }

    private MaxSpace = (iOutlet: number, bESPOverride: boolean, bPredictNozzle: boolean): number => {
        let eDevice: DeviceTypes;
        let iNozzle: number;
        let outlet = this.Outlets[iOutlet];
        if (iOutlet === 0) {
            eDevice = this.Package[1].DeviceType;
            iNozzle = 1;
            if (!this.SystemInfo.IsLateralMove) {
                let iTempSpace: number = DeviceNozzle(eDevice).Nozzles(iNozzle).MaxSpace * 10;
                switch (eDevice) {
                    case DeviceTypes.NelsonD3000Spray:
                    case DeviceTypes.NelsonD3000FCNSpray:
                    case DeviceTypes.SenningerSuperSpray:
                    case DeviceTypes.SenningerQuadSpray:
                    case DeviceTypes.SenningerLDNSpray:
                    case DeviceTypes.KometSpray:
                    case DeviceTypes.SenningerXcelWobbler:
                        if (iTempSpace < 214) {
                            return 214;
                        } else {
                            return iTempSpace;
                        }
                        break;
                    default:
                        if (iTempSpace < 282) {
                            return 282;
                        } else {
                            return iTempSpace;
                        }
                        break;
                }
            } else {
                return this.Package[1].Spacing;
            }
        }
        if (outlet.IsTowerBoomBackOutlet) {
            eDevice = this.TranslateBoomBackToDeviceType(this.EngineInfo.BoomBackDevice);
        } else {
            eDevice = this.Package[outlet.PackageNumber].DeviceType;
        }
        if (bESPOverride) {
            switch (eDevice) {
                case DeviceTypes.Nelson15Single3RNBrassImpact:
                case DeviceTypes.Nelson15Double3RNBrassImpact:
                case DeviceTypes.Nelson15DoubleFCNBrassImpact:
                case DeviceTypes.Nelson8Double3RNPlasticImpact:
                case DeviceTypes.Nelson8DoubleFCNPlasticImpact:
                case DeviceTypes.Senninger6DoubleWhiteVanePlasticImpact:
                case DeviceTypes.Senninger6DoubleRedVanePlasticImpact:
                case DeviceTypes.NelsonR3000RotatorRed:
                case DeviceTypes.NelsonR3000RotatorBlue:
                case DeviceTypes.NelsonR3000RotatorWhite:
                case DeviceTypes.NelsonR3000FCNRotatorBlue:
                case DeviceTypes.NelsonR3000RotatorGreen:
                case DeviceTypes.NelsonR3000FCNRotatorGreen:
                case DeviceTypes.NelsonR3000RotatorOrange:
                case DeviceTypes.NelsonR3000RotatorBrown:
                case DeviceTypes.NelsonR3000RotatorOlive:
                    return 200;
                    break;
            }
        }

        let returnValue: number;
        switch (eDevice) {
            case DeviceTypes.NelsonD3000Spray:
            case DeviceTypes.NelsonD3000FCNSpray:
            case DeviceTypes.SenningerSuperSpray:
            case DeviceTypes.SenningerQuadSpray:
            case DeviceTypes.SenningerLDNSpray:
            case DeviceTypes.KometSpray:
                returnValue = this.Package[outlet.PackageNumber].Spacing;
                break;
            case DeviceTypes.NelsonO3000OrbitorBlack:
            case DeviceTypes.NelsonO3000OrbitorBlue:
            case DeviceTypes.NelsonO3000OrbitorPurple:
            case DeviceTypes.NelsonO3030OrbitorFXBlack:
            case DeviceTypes.NelsonO3030OrbitorFXWhite:
            case DeviceTypes.NelsonA3000AcceleratorMaroon:
            case DeviceTypes.NelsonA3000AcceleratorGold:
            case DeviceTypes.SenningerLowAngleIWobWhite:
            case DeviceTypes.SenningerLowAngleIWobBlue:
            case DeviceTypes.SenningerStandardAngleIWobGray:
            case DeviceTypes.SenningerHighAngleIWobBlack:
            case DeviceTypes.SenningerXiWobBlue:
            case DeviceTypes.SenningerXiWobGray:
            case DeviceTypes.SenningerXiWobBlack:
            case DeviceTypes.SenningerXiWobWhite:
            case DeviceTypes.SenningerXcelWobbler:
            case DeviceTypes.NelsonS3000SpinnerRed:
            case DeviceTypes.NelsonS3000SpinnerPurple:
            case DeviceTypes.NelsonS3000SpinnerPurpleLowPressure:
            case DeviceTypes.NelsonS3000SpinnerYellow:
            case DeviceTypes.NelsonS3000SpinnerYellowLowPressure:
            case DeviceTypes.NelsonR3000RotatorRed:
            case DeviceTypes.NelsonR3000RotatorBlue:
            case DeviceTypes.NelsonR3000RotatorWhite:
            case DeviceTypes.NelsonR3000FCNRotatorBlue:
            case DeviceTypes.NelsonR3000RotatorGreen:
            case DeviceTypes.NelsonR3000FCNRotatorGreen:
            case DeviceTypes.NelsonR3000RotatorOrange:
            case DeviceTypes.NelsonR3000RotatorBrown:
            case DeviceTypes.NelsonR3000RotatorOlive:
            case DeviceTypes.NelsonA3000AcceleratorNavy:
            case DeviceTypes.NelsonA3000AcceleratorNavyLowPressure:
            case DeviceTypes.KometTwisterBlack:
            case DeviceTypes.KometTwisterBlue:
            case DeviceTypes.KometTwisterYellow:
            case DeviceTypes.KometTwisterWhite:
            case DeviceTypes.KometTwisterPartCircle:
                returnValue = this.Package[outlet.PackageNumber].Spacing;
                if (!bPredictNozzle) {
                    iNozzle = outlet.NozzleUsed;
                } else {
                    let selectNozzleResult = this.SelectNozzle(iOutlet, 0);
                    iNozzle = selectNozzleResult.PreferredNozzle;
                }
                if (DeviceNozzle(eDevice).Nozzles(iNozzle).MaxSpace * 10 < returnValue) {
                    returnValue = DeviceNozzle(eDevice).Nozzles(iNozzle).MaxSpace * 10;
                }
                break;
            default:
                if (!bPredictNozzle) {
                    iNozzle = outlet.NozzleUsed;
                } else {
                    let selectNozzleResult = this.SelectNozzle(iOutlet, 0);
                    iNozzle = selectNozzleResult.PreferredNozzle;
                }
                returnValue = DeviceNozzle(eDevice).Nozzles(iNozzle).MaxSpace * 10;
                break;
        }
        if (this.SystemInfo.IsLateralMove) {
            if (returnValue > this.Package[outlet.PackageNumber].Spacing) {
                return this.Package[outlet.PackageNumber].Spacing;
            }
        }
        return returnValue;
    }

    private GetSpaceDrop = (iOutlet: number): SpaceDrop => {
        switch (this.Package[this.Outlets[iOutlet].PackageNumber].DeviceType) {
            case DeviceTypes.Nelson1518MediumSpacingBrassImpact:
            case DeviceTypes.Senninger12MediumSpacingPlasticImpact:
            case DeviceTypes.NelsonD3000Spray:
            case DeviceTypes.NelsonD3000FCNSpray:
            case DeviceTypes.SenningerSuperSpray:
            case DeviceTypes.SenningerLDNSpray:
            case DeviceTypes.SenningerQuadSpray:
            case DeviceTypes.KometSpray:
                return SpaceDrop.Stop;
                break;
            default:
                if (this.Package[this.Outlets[iOutlet].PackageNumber].NormalSpacing) {
                    return SpaceDrop.Gradual;
                } else {
                    return SpaceDrop.User;
                }
                break;
        }
    }

    private ComputeESPGPM = (iPrevOutlet: number, iNextOutlet: number, iNextNextOutlet: number): number => {
        let sPrevSprinklerESPReq: number;
        let sNextSprinklerESPReq: number;
        let iPrevPrevOutlet: number = this.PrevSprinkler(iPrevOutlet);
        let bSaveESPMode: boolean;
        if (this.Outlets[iPrevPrevOutlet].IsESPOutlet) {
            iPrevPrevOutlet = this.PrevSprinkler(iPrevPrevOutlet);
        }
        bSaveESPMode = this._useESPMode;
        this.SwitchESPMode(true);
        sPrevSprinklerESPReq = (this.RequiredSprinklerGPM(iPrevPrevOutlet, this.ActualOutletRadius(iNextOutlet), true) - this.Outlets[iPrevOutlet].GPMDelivered);
        sNextSprinklerESPReq = (this.RequiredSprinklerGPM(iPrevOutlet, this.ActualOutletRadius(iNextNextOutlet), true) - this.Outlets[iNextOutlet].GPMDelivered);
        this.SwitchESPMode(bSaveESPMode);
        return (sPrevSprinklerESPReq + sNextSprinklerESPReq) / 2;
    }

    private SelectSprinklerPackageNumberByLocation = (location: number): number => {
        if (location === 0) {
            return 1;
        }
        let iLastPkg: number = this.LastPkg();
        if (location === -1) {
            return iLastPkg;
        }
        if (iLastPkg === 1) {
            return 1;
        }
        let packageStartLocation: number;
        for (let i: number = 1; i <= iLastPkg; i++) {
            if (i === 1) {
                packageStartLocation = 0;
            } else {
                packageStartLocation = this.Package[i - 1].End;
            }
            let pkg = this.Package[i];
            if (location >= packageStartLocation && (location <= pkg.End || pkg.End === 0)) {
                return i;
            }
        }
    }

    private ComputeSprinklerLocations = () => {
        this._SpaceReduction = SpaceReduction.NoneAttempted;
        let bOK: boolean;
        do {
            if (this._lastSprinkler === 0) {
                bOK = this.PlaceInitialSprinkler();
            } else {
                bOK = this.PlaceSprinkler(this._nextSprinkler, this._lastSprinkler);
            }
        } while (!(!bOK));
        if (this.SystemInfo.HasSACESP) {
            this.SwitchESPMode(true);
            this._curESP = this.NextSprinkler(0);
            bOK = true;
            this._IsESPStarted = false;
            this._forceSpecialESPSpacing = false;
            this.OutletControl.SprinklersExist = true;
            this._ESPPivotPressure = this.PivotPressure;
            this.OutletControl.SprinklersExist = false;
            while (!(!bOK)) {
                bOK = this.PlaceESP();
            };
            this.SwitchESPMode(false);
        }
        this.OutletControl.SprinklersExist = true;
    }

    public PlaceSprinklers = () => {
        let bLowPressure: boolean;
        for (let i: number = 1; i <= MaxPackages; i++) {
            bLowPressure = false;
            if (this.Package[i].RegulatorType === RegulatorTypes.None) {
                bLowPressure = this.EngineInfo.EndPressure < 15;
            } else {
                switch (this.Package[i].RegulatorPSI) {
                    case RegulatorPressures.a6:
                    case RegulatorPressures.a10:
                        bLowPressure = true;
                        break;
                }
            }
            switch (this.Package[i].DeviceType) {
                case DeviceTypes.NelsonS3000SpinnerYellow:
                    if (bLowPressure) {
                        this.Package[i].DeviceType = DeviceTypes.NelsonS3000SpinnerYellowLowPressure;
                    }
                    break;
                case DeviceTypes.NelsonS3000SpinnerYellowLowPressure:
                    if (!bLowPressure) {
                        this.Package[i].DeviceType = DeviceTypes.NelsonS3000SpinnerYellow;
                    }
                    break;
                case DeviceTypes.NelsonS3000SpinnerPurple:
                    if (bLowPressure) {
                        this.Package[i].DeviceType = DeviceTypes.NelsonS3000SpinnerPurpleLowPressure;
                    }
                    break;
                case DeviceTypes.NelsonS3000SpinnerPurpleLowPressure:
                    if (!bLowPressure) {
                        this.Package[i].DeviceType = DeviceTypes.NelsonS3000SpinnerPurple;
                    }
                    break;
                case DeviceTypes.NelsonA3000AcceleratorNavy:
                    if (bLowPressure) {
                        this.Package[i].DeviceType = DeviceTypes.NelsonA3000AcceleratorNavyLowPressure;
                    }
                    break;
                case DeviceTypes.NelsonA3000AcceleratorNavyLowPressure:
                    if (!bLowPressure) {
                        this.Package[i].DeviceType = DeviceTypes.NelsonA3000AcceleratorNavy;
                    }
                    break;
            }
        }
        this.SystemInfo.LastPossibleOutletNumber = this.OutletControl.OutletCount;
        if (this.GetOutletType(this.OutletControl.OutletCount) === OutletTypes.Imaginary) {
            this.SystemInfo.LastPossibleOutletNumber -= 1;
        }
        this.EngineInfo.Reset();
        this.ClearEndGun();
        this.ClearSprinklers();
        this.ComputeSprinklerLocations();
        this.EngineInfo.EndOfSystemCoverage = this.EOSSprinklerCoverage;
        this.ClearEndGun();
        this.ClearSprinklers();
        this.ComputeSprinklerLocations();
        this.Outlets.forEach((o) => {
            this.EngineInfo.SystemGPM += o.GPMDelivered;
            if (o.IsSACOutlet && o.OutletNumber < this.Outlets.length - 1) {
                this.EngineInfo.MaximumSystemGPM += o.SACDistributionGPM;
                if (o.GPMMaximum < o.SACDistributionGPM) {
                    this.EngineInfo.Warnings.push({
                        WarningSeverity: WarningSeverity.Critical,
                        WarningType: WarningTypes.SystemParams,
                        Context: "",
                        Message: "●  Outlet({o.OutletNumber}) / SAC Outlet({o.SACOutletNumber}) with {o.GPMDelivered:0.00} GPM delivered and {o.SACDistributionGPM:0.00} GPM adjusted by Distribution Flow Factor:{o.SACDistributionFactor:0.000} exceeds the Maximum GPM of {o.GPMMaximum:0.00} for selected Device.</br>"
                    });
                }
            } else {
                this.EngineInfo.MaximumSystemGPM += o.GPMDelivered;
                this.EngineInfo.MinimumSystemGPM += o.GPMDelivered;
            }
        }
        );
        if (this.EndGunInfo.EndGun !== EndGunTypes.None) {
            this.EngineInfo.MaximumSystemGPM += this.EndGunInfo.EGH.GPM;
        }
        let firstUsedOutlet: OutletRecord = this.Outlets.filter((o) =>
            o.IsInUse).shift();
        firstUsedOutlet.SACDistributionDownstreamGPM = this.EngineInfo.MaximumSystemGPM - firstUsedOutlet.SACDistributionGPM;
        let a: OutletRecord | undefined;
        for (const b of this.Outlets.filter((o) =>o.IsInUse)) {
            if (a) {
                b.SACDistributionDownstreamGPM = a.SACDistributionDownstreamGPM - b.SACDistributionGPM;
            }
            a = b;
        }
        this.EngineInfo.SystemPressureLossAtMaxSystemGPM = this.PressureLoss(this.EngineInfo.MaximumSystemGPM, 0, firstUsedOutlet.PipeLocation);
        a = undefined;
        for (const b of this.Outlets.filter((o) =>o.IsInUse)) {
            if (a) {
                this.EngineInfo.SystemPressureLossAtMaxSystemGPM += this.PressureLoss(a.SACDistributionDownstreamGPM, a.PipeLocation, b.PipeLocation);
            }
            a = b;
        }
        const sideGPMDelivered = this.Outlets.filter((o) =>o.IsInUse).reduce((a, b) => a + b.GPMDelivered, 0);
        this.Outlets.filter((o) =>o.IsInUse).reduce((a, b): number => {
            b.DownstreamGPMDelivered = a - b.GPMDelivered;
            return b.DownstreamGPMDelivered;
        }, sideGPMDelivered);
        this.EngineInfo.SystemPressureLoss = this.PressureLoss(sideGPMDelivered, 0, firstUsedOutlet.PipeLocation);
        a = undefined;
        for (const b of this.Outlets.filter((o) =>o.IsInUse)) {
            if (a) {
                this.EngineInfo.SystemPressureLoss += this.PressureLoss(a.DownstreamGPMDelivered, a.PipeLocation, b.PipeLocation);
            }
            a = b;
        }
        let lastOutletWithReg: OutletRecord = this.Outlets.filter((o) =>
            o.RegulatorType !== RegulatorTypes.None).pop();
        let endOfSystemPSI: number = 0;
        if (!lastOutletWithReg || lastOutletWithReg.RegulatorType === RegulatorTypes.None) {
            endOfSystemPSI = this.SystemInfo.DesignedEndPressure;
        } else {
            endOfSystemPSI = this.ConvertRegulatorPressuresToDouble(lastOutletWithReg.RegulatorPSI) + 5;
        }
        this.EngineInfo.MaximumTopInletPSI = endOfSystemPSI + (this.EngineInfo.Elevation / 2.31) + this.EngineInfo.SystemPressureLossAtMaxSystemGPM;
        if (this.EngineInfo.Warnings?.length > 0) {
            let sb: "";
            sb += ("<b>The Required GPM exceeds the Delivered GPM on the swing arm.  You must change one or more of the following parameters for the System flow to fall within the range of the design criteria:</br></br>");
            sb += ("1) Reduce the System GPM and/or Increase the System End pressure and use a higher Pressure Regulator</br>");
            sb += ("2) If using the ESAC 6.0 Medium then select either the 12.0 Medium or the 6.0 Low instead</br>");
            sb += ("3) If using the ESAC 12.0 High then select either the 12.0 Medium or the 12.0 Low instead</br>");
            sb += ("4) If using the SAC VRI then reduce System GPM and/or use a higher Pressure Regulator</br></br>");
            sb += ("Please contact Reinke if further assistance is required.</br></br></b>");
            this.EngineInfo.Warnings = [{
                WarningSeverity: WarningSeverity.Critical,
                WarningType: WarningTypes.SystemParams,
                Context: "",
                Message: sb
            }, ...this.EngineInfo.Warnings];
            throw new MultiSprinklerEngineException(this.EngineInfo.Warnings);
        }
    }

    public ConvertRegulatorPressuresToDouble = (rp: RegulatorPressures): number => {
        switch (rp) {
            case RegulatorPressures.a6:
                return 6;
                break;
            case RegulatorPressures.a10:
                return 10;
                break;
            case RegulatorPressures.a15:
                return 15;
                break;
            case RegulatorPressures.a20:
                return 20;
                break;
            case RegulatorPressures.a25:
                return 25;
                break;
            case RegulatorPressures.a30:
                return 30;
                break;
            case RegulatorPressures.a40:
                return 40;
                break;
        }
    }

    public InspectAndModifyP85s = () => {
        if ([EndGunTypes.SingleP85, EndGunTypes.DualP85].indexOf(this.EndGunInfo.EGH.EndGunType) === -1) {
            return true;
        }
        const dWarningPSI = GetMinimalEndGunPressures(this.EndGunInfo.EGH.EndGunType, this.EndGunInfo.EGH.NozzleDiameter, false).sWarningPSI;
        let bLowPSI: boolean = this.EndGunInfo.EGH.Pressure < dWarningPSI;
        let bLowGPM: boolean = (this.EndGunInfo.EGH.GPM < this.EndGunInfo.EGH.MinimumRequiredEndGunGPM) && (this.EndGunInfo.EGH.NozzleDiameter >= 22 / 32);
        let bConsiderDiffuser: boolean = !this.EndGunInfo.EGH.UseDiffuser && (this.EndGunInfo.BoosterPump === BoosterPumpTypes.None);
        let bConsiderDual: boolean = this.EndGunInfo.EGH.EndGunType === EndGunTypes.SingleP85;
        if (bLowPSI && bConsiderDiffuser) {
            this.EndGunInfo.EGH = new EndGunHelper(this.SystemInfo.IsLateralMove, this.EngineInfo.SideGPM, this.EndGunInfo.BoosterPump, this.EndGunInfo.FrequencyHz, this.SystemPipeRadius, this.SystemCoverageRadius, this.EngineInfo.AdjustedEndPressure, this.EndGunInfo.EGH.EndGunType, true);
            return false;
        }
        if (bLowGPM && bConsiderDual) {
            this.EndGunInfo.EGH = new EndGunHelper(this.SystemInfo.IsLateralMove, this.EngineInfo.SideGPM, this.EndGunInfo.BoosterPump, this.EndGunInfo.FrequencyHz, this.SystemPipeRadius, this.SystemCoverageRadius, this.EngineInfo.AdjustedEndPressure, EndGunTypes.DualP85, false);
            return false;
        }
        return true;
    }

    private ComputeFAAdjustment = (i: number, lIdeal: number): number => {
        if (lIdeal === 0) {
            return 0;
        }
        let lFAAdjustment: number = 0;
        if (this.IsFurrowArm(i)) {
            if (this.Outlets[i].Radius !== lIdeal) {
                lFAAdjustment = lIdeal - this.Outlets[i].Radius;
                if (this.Outlets[i].Radius + lFAAdjustment > this.MaxOutletRadius(i)) {
                    lFAAdjustment = this.MaxOutletRadius(i) - this.Outlets[i].Radius;
                }
                if (this.Outlets[i].Radius + lFAAdjustment < this.MinOutletRadius(i)) {
                    lFAAdjustment = this.MinOutletRadius(i) - this.Outlets[i].Radius;
                }
            }
        }
        return lFAAdjustment;
    }

    private GetOutletType = (iOutlet: number): OutletTypes => {
        let o: OutletRecord = this.Outlets[iOutlet];
        if (!o.IsTowerBoomBackOutlet) {
            return OutletTypes.Regular;
        } else if ((this.EngineInfo.TowerBoomBackStart <= o.SpanNumber && this.EngineInfo.TowerBoomBackEnd >= o.SpanNumber)) {
            return OutletTypes.BoomBack;
        } else {
            return OutletTypes.Imaginary;
        }
    }

    private GetTrialOutlet = (lMax: number, iCurOutlet: number, computeByGPM: boolean, bESPOverride: boolean): SpacingResult => {
        let result = new SpacingResult();
        let i: number;
        if (iCurOutlet >= this.SystemInfo.LastPossibleOutletNumber) {
            result.NoMoreOutlets = true;
            return result;
        }
        if (this._lastSprinkler === 0) {
            computeByGPM = false;
        }
        let curRadius: number = this.ActualOutletRadius(iCurOutlet);
        if (this.IsSACOutlet(iCurOutlet)) {
            if (!this.IsSACOutlet(iCurOutlet - 1)) {
                if ([SwingArmLengths.SAC156, SwingArmLengths.SAC175].indexOf(this.SystemInfo.SwingArmLength) !== -1) {
                    result.NewOutlet = iCurOutlet + 2;
                } else {
                    result.NewOutlet = iCurOutlet + 1;
                }
            } else {
                result.NewOutlet = iCurOutlet + 2;
            }
            if (this.SystemInfo.HasESAC && [SACZoneControlTypes.ESAC120, SACZoneControlTypes.ESAC125].indexOf(this.SystemInfo.ZoneControlType) !== -1) {
                switch (this.Side.EndOfSystem.SwingArmLength) {
                    case SwingArmLengths.SAC156:
                        if (this.OutletControl.OutletCount - iCurOutlet <= 18) {
                            result.NewOutlet = iCurOutlet + 1;
                        }
                        break;
                    case SwingArmLengths.SAC175:
                        if (this.OutletControl.OutletCount - iCurOutlet <= 24) {
                            result.NewOutlet = iCurOutlet + 1;
                        }
                        break;
                    case SwingArmLengths.SAC194:
                        if (this.OutletControl.OutletCount - iCurOutlet <= 34) {
                            result.NewOutlet = iCurOutlet + 1;
                        }
                        break;
                    case SwingArmLengths.SAC213:
                        if (this.OutletControl.OutletCount - iCurOutlet <= 42) {
                            result.NewOutlet = iCurOutlet + 1;
                        }
                        break;
                }
            }
            result.Space = this.Outlets[result.NewOutlet].Radius - curRadius;
            result.GPMRequired = this.RequiredSprinklerGPM(this._lastSprinkler, this.Outlets[result.NewOutlet].Radius, true);
            return result;
        }
        let nextSprinklerRadius: number;
        let lastRadius: number = this.ActualOutletRadius(this._lastSprinkler);
        if (computeByGPM) {
            nextSprinklerRadius = lastRadius + lMax * 2;
        } else {
            nextSprinklerRadius = curRadius + lMax;
        }
        result.Ideal = nextSprinklerRadius;
        let gotoCont1 = false;
        let gotoWrapUp = false;
        let bHitLastOutlet: boolean = true;
        for (i = iCurOutlet + 1; i <= this.SystemInfo.LastPossibleOutletNumber; i++) {
            switch (this.GetOutletType(i)) {
                case OutletTypes.BoomBack:
                    if (i === this.SystemInfo.LastPossibleOutletNumber) {
                        if (this.MinOutletRadius(i) > nextSprinklerRadius) {
                            gotoCont1 = true;
                        }
                    } else {
                        if (this.MinOutletRadius(i) > nextSprinklerRadius + 1) {
                            gotoCont1 = true;
                        }
                    }
                    if (!gotoCont1 && this._lastSprinkler > 0 && !computeByGPM) {
                        result.NewOutlet = i;
                        gotoWrapUp = true;
                    }
                    break;
                case OutletTypes.Regular:
                    if (i === this.SystemInfo.LastPossibleOutletNumber) {
                        if (this.MinOutletRadius(i) > nextSprinklerRadius) {
                            gotoCont1 = true;
                        }
                    } else {
                        if (this.MinOutletRadius(i) > nextSprinklerRadius + 1) {
                            gotoCont1 = true;
                        }
                    }
                    break;
            }
            if (gotoCont1 || gotoWrapUp) {
                bHitLastOutlet = false;
                break;
            }
        }
        if (!gotoWrapUp) {
            if (bHitLastOutlet) {
                result.NewOutlet = i - 1;
            } else {
                switch (i) {
                    case iCurOutlet + 1:
                        result.NewOutlet = i;
                        break;
                    case iCurOutlet + 2:
                        if (this.GetOutletType(i - 1) === OutletTypes.Regular) {
                            result.NewOutlet = i - 1;
                        } else {
                            result.NewOutlet = i;
                        }
                        break;
                    default:
                        if (this.GetOutletType(i - 1) === OutletTypes.Regular) {
                            result.NewOutlet = i - 1;
                        } else {
                            result.NewOutlet = i - 2;
                        }
                        break;
                }
            }
        }
        let FAAdjustment: number = 0;
        if (!computeByGPM && this._lastSprinkler > 0 && iCurOutlet > 0) {
            i = iCurOutlet;
            let curMaxSpace: number = this.MaxSpace(iCurOutlet, bESPOverride, false);
            let exitWhile = false;
            while (i < result.NewOutlet && !exitWhile) {
                i += 1;
                let iMaxSpace: number;
                switch (this.GetOutletType(i)) {
                    case OutletTypes.BoomBack:
                        iMaxSpace = this.MaxSpace(i, bESPOverride, true);
                        if (curMaxSpace !== iMaxSpace) {
                            if (this.Outlets[i].Radius - curRadius > iMaxSpace * 12) {
                                let j: number;
                                for (j = iCurOutlet + 1; j <= i - 1; j++) {
                                    if (this.Outlets[i].Radius - this.Outlets[j].Radius <= iMaxSpace * 12) {
                                        break;
                                    }
                                }
                                result.NewOutlet = j;
                                this._useBuckBoostSpace = true;
                                exitWhile = true;
                                continue;
                            }
                        }
                        break;
                    case OutletTypes.Regular:
                        iMaxSpace = this.MaxSpace(i, bESPOverride, true);
                        if (curMaxSpace !== iMaxSpace) {
                            FAAdjustment = this.ComputeFAAdjustment(i, nextSprinklerRadius);
                            if (this.Outlets[i].Radius + FAAdjustment - curRadius > iMaxSpace * 12) {
                                result.NewOutlet = i - 1;
                                if (this.GetOutletType(result.NewOutlet) === OutletTypes.Imaginary) {
                                    result.NewOutlet -= 1;
                                }
                                switch (this._SpaceReduction) {
                                    case SpaceReduction.NoneAttempted:
                                        this._SpaceReduction = SpaceReduction.Trial;
                                        break;
                                    case SpaceReduction.OkToCommit:
                                        this._SpaceReduction = SpaceReduction.Commit;
                                        break;
                                }
                                exitWhile = true;
                                continue;
                            }
                        }
                        break;
                    case OutletTypes.Imaginary:
                        break;
                }
            };
            if (result.NewOutlet <= iCurOutlet) {
                result.NewOutlet = iCurOutlet + 1;
            }
            if (this.GetOutletType(result.NewOutlet) === OutletTypes.Imaginary) {
                result.NewOutlet += 1;
            }
        } else {
            FAAdjustment = this.ComputeFAAdjustment(result.NewOutlet, nextSprinklerRadius);
        }
        if (this.IsSACOutlet(result.NewOutlet)) {
            while (this.IsSACOutlet(result.NewOutlet - 1)) {
                result.NewOutlet -= 1;
            };
        }
        if (!computeByGPM && this.Outlets[iCurOutlet].DeviceType === DeviceTypes.SenningerQuadSpray) {
            if ((iCurOutlet + 2 === result.NewOutlet) && this.Outlets[iCurOutlet + 1].HookPipeLocation > 0) {
                if (this.MinOutletRadius(result.NewOutlet) - nextSprinklerRadius > 360) {
                    throw new SprinklerEngineException(WarningSeverity.Critical, WarningTypes.SystemParams, strLanguageText("sltMaxSprinklerSpacing"), StringFormat(strLanguageText("sltOutletSpacingExceedsDevice"), Math.floor(this.Outlets[iCurOutlet].PipeLocation / 120)));
                }
            } else {
                if (this.MinOutletRadius(result.NewOutlet) > nextSprinklerRadius) {
                    throw new SprinklerEngineException(WarningSeverity.Critical, WarningTypes.SystemParams, strLanguageText("sltMaxSprinklerSpacing"), StringFormat(strLanguageText("sltOutletSpacingExceedsDevice"), Math.floor(this.Outlets[iCurOutlet].PipeLocation / 120)));
                }
            }
        }
        result.GPMRequired = this.RequiredSprinklerGPM(this._lastSprinkler, this.Outlets[result.NewOutlet].Radius + FAAdjustment, true);
        result.Space = this.Outlets[result.NewOutlet].Radius + FAAdjustment - curRadius;
        return result;
    }

    private IsESPCompliant = (iCurOutlet: number, iNewOutlet: number): boolean => {
        if (!this.SystemInfo.HasSACESP) {
            return true;
        }
        if (iCurOutlet === 0) {
            return true;
        }
        if (iNewOutlet - iCurOutlet <= 1) {
            return this.IsSACOutlet(iNewOutlet);
        }
        if (this.IsSACOutlet(iNewOutlet) && this.IsSACOutlet(iNewOutlet - 1)) {
            return true;
        }
        let iPrev: number = this.PrevSprinkler(iCurOutlet);
        if (!this._IsESPStarted) {
            if (this.ComputeESPGPM(iPrev, iCurOutlet, iNewOutlet) < 0.7) {
                return true;
            }
        }
        this._IsESPStarted = true;
        if ((iNewOutlet - iCurOutlet > 1)) {
            let i: number = this.GetAnOutletHalfwayBetween(iCurOutlet, iNewOutlet);
            if ((i > 0)) {
                this.Outlets[i].IsESPOutlet = true;
                return true;
            }
        }
        return false;
    }

    private OKToCrossATower = (lSMaxSpace: number, lSSpace: number, iSOutlet: number, iGOutlet: number, iCurOutlet: number, sGGPMReq: number, sMaxGPM: number, bESPOverride: boolean,
        bDropSpacing: boolean, lNewSpacing: number): {
        result: boolean,
        bDropSpacing: boolean,
        lNewSpacing: number
    } => {
        let MoveUp: number = 1;
        let returnValue = {
            result: false,
            bDropSpacing,
            lNewSpacing
        };
        if (this.IsSACOutlet(iSOutlet)) {
            return returnValue;
        }
        let i: number;
        let gotoTowerFound = false;
        for (i = iCurOutlet; i <= iSOutlet; i++) {
            if (this.Outlets[i].HookPipeLocation > 0) {
                gotoTowerFound = true;
                break;
            }
        }
        if (!gotoTowerFound && i <= this.OutletControl.OutletCount) {
            if (this.GetOutletType(i) === OutletTypes.Imaginary) {
                MoveUp = 2;
                gotoTowerFound = true;
            }
        }
        if (!gotoTowerFound) {
            return returnValue;
        }
        if (lSSpace === lSMaxSpace) {
            return returnValue;
        }
        if (iSOutlet + MoveUp > iGOutlet) {
            if (iCurOutlet === iGOutlet - 1) {
                return returnValue;
            }
            returnValue.bDropSpacing = (!this.SystemInfo.IsLateralMove) && (lSSpace < lSMaxSpace * 0.9) && (iSOutlet < this.SystemInfo.LastPossibleOutletNumber);
            return returnValue;
        }
        let lIdealRadius = this.ActualOutletRadius(iCurOutlet) + lSMaxSpace;
        let lDistToPrev = lIdealRadius - this.Outlets[iSOutlet].Radius;
        let lDistToNext = this.Outlets[iSOutlet + MoveUp].Radius - lIdealRadius;
        if (this.SystemInfo.IsLateralMove) {
            if (lDistToNext > lDistToPrev * 1.05) {
                return returnValue;
            }
        } else {
            if (lDistToNext > lDistToPrev * 1.112) {
                return returnValue;
            }
        }
        if (iSOutlet + MoveUp === iGOutlet) {
            if (sGGPMReq * 1.01 > sMaxGPM) {
                returnValue.bDropSpacing = !this.SystemInfo.IsLateralMove;
                return returnValue;
            }
        }
        let lFAAdjust = this.ComputeFAAdjustment(iSOutlet + MoveUp, lIdealRadius);
        let sTempGPMReq: number = this.RequiredSprinklerGPM(this.PrevSprinkler(iCurOutlet), this.Outlets[iSOutlet + MoveUp].Radius + lFAAdjust, true);
        let iTempNozzle = this.Outlets[iCurOutlet].NozzleUsed;
        let selectNozzleResult = this.SelectNozzle(iCurOutlet, sTempGPMReq);
        this.Outlets[iCurOutlet].NozzleUsed = selectNozzleResult.PreferredNozzle;
        let iNewMaxSpace = this.MaxSpace(iCurOutlet, bESPOverride, false);
        this.Outlets[iCurOutlet].NozzleUsed = iTempNozzle;
        if (iNewMaxSpace * 12 < lSMaxSpace) {
            if ((!this.SystemInfo.IsLateralMove) && (lSSpace < iNewMaxSpace * 12 * 0.9) && (iSOutlet < this.SystemInfo.LastPossibleOutletNumber)) {
                returnValue.bDropSpacing = true;
                returnValue.lNewSpacing = iNewMaxSpace * 12;
            }
            return returnValue;
        }
        returnValue.result = true;
        return returnValue;
    }

    private TrySpecialESPSpacing = (iCurOutlet: number, iNewSprinkler: number, lOldSpacing: number) => {
        let lSaveSpacing: number = this._Spacing;
        this._Spacing = lOldSpacing;
        this.Outlets[iCurOutlet].MaximumSpacingSetting = this._Spacing;
        let nextSprinklerResult = this.ComputeNextSprinkler(iCurOutlet, true);
        if (nextSprinklerResult.Continue) {
            this._forceSpecialESPSpacing = true;
            iNewSprinkler = nextSprinklerResult.NewSprinklerLocation;
        } else {
            this._Spacing = lSaveSpacing;
            this.Outlets[iCurOutlet].MaximumSpacingSetting = this._Spacing;
        }
    }

    private HuntForAnESPCompliantOutlet = (iCurOutlet: number, iNewSprinkler: number) => {
        if (this.IsESPCompliant(iCurOutlet, iNewSprinkler)) {
            return;
        }
        if (this.IsESPCompliant(iCurOutlet, iNewSprinkler - 1)) {
            iNewSprinkler -= 1;
        } else if (this.IsESPCompliant(iCurOutlet, iNewSprinkler - 2)) {
            iNewSprinkler -= 2;
        } else if (this.IsESPCompliant(iCurOutlet, iNewSprinkler - 3)) {
            iNewSprinkler -= 3;
        } else {
            throw new SprinklerEngineException(WarningSeverity.Critical, WarningTypes.SystemParams, strLanguageText("sltMaxSprinklerSpacing"), StringFormat(strLanguageText("sltUnableToReserveOutletESP"), Math.floor(this.Outlets[iCurOutlet].PipeLocation / 120)));
        }
    }

    private ComputeNextSprinkler = (iCurOutlet: number, useSpecialESPSpacing: boolean): ComputeNextSprinklerResult => {
        let result = new ComputeNextSprinklerResult();
        let reCalcIterationCount: number = 0;
        let spaceDrop: SpaceDrop;
        let nonAdjustedMaxGPM: number;
        let newSpacing: number;
        let towerCouldNotBeCrossed: boolean;
        if (this._forceSpecialESPSpacing) {
            useSpecialESPSpacing = true;
        }
        let GPMDeltaFromPreviousOutlet: number = 0;
        let prevOutlet: number = this.PrevSprinkler(iCurOutlet);
        if (prevOutlet > 0) {
            let o: OutletRecord = this.Outlets[prevOutlet];
            GPMDeltaFromPreviousOutlet = (o.GPMRequired - o.GPMDelivered) / 2;
        }
        let tryExceedGPM: boolean = false;
        let oldSpacing: number = this._Spacing;
        let curOutletRec: OutletRecord = this.Outlets[iCurOutlet];
        let outletMaxGPMData = this.MaxGPM(iCurOutlet);

        let spacingByGPM: SpacingResult;
        let spacingByMaxDist: SpacingResult;

        let recalc;
        let gotoExitFunc = false;
        do {
            recalc = false;
            reCalcIterationCount += 1;
            let maxGPM: number = outletMaxGPMData.MaxGPM;
            let maxGPMIgnoreMinPressure: number = outletMaxGPMData.MaxGPMIgnoreMinPressure;
            nonAdjustedMaxGPM = maxGPM;
            if (tryExceedGPM) {
                maxGPM *= 1.03;
            }
            spaceDrop = this.GetSpaceDrop(iCurOutlet);
            let maxDistanceByDeviceSpacing: number;
            let maxDistanceByDeviceGPM: number;
            let maxSpace: number = this.MaxSpace(iCurOutlet, useSpecialESPSpacing, false);
            maxDistanceByDeviceSpacing = maxSpace * 12;
            if (this._Spacing === 0) {
                this._Spacing = maxDistanceByDeviceSpacing;
                curOutletRec.MaximumSpacingSetting = this._Spacing;
            } else {
                if (this._useBuckBoostSpace) {
                    maxDistanceByDeviceSpacing = this._Spacing;
                    this._useBuckBoostSpace = false;
                } else if (maxDistanceByDeviceSpacing >= this._Spacing) {
                    maxDistanceByDeviceSpacing = this._Spacing;
                } else {
                    if (!curOutletRec.IsTowerBoomBackOutlet) {
                        this._Spacing = maxDistanceByDeviceSpacing;
                        curOutletRec.MaximumSpacingSetting = this._Spacing;
                    }
                }
            }
            if (towerCouldNotBeCrossed && newSpacing > 0) {
                maxDistanceByDeviceGPM = (newSpacing + curOutletRec.ActualOutletRadius - this.ActualOutletRadius(this._lastSprinkler)) / 2;
            } else {
                if (this.SystemInfo.IsLateralMove) {
                    maxDistanceByDeviceGPM = Math.floor(((maxGPM - GPMDeltaFromPreviousOutlet) / this.EngineInfo.SideGPM) * this.Coverage());
                } else {
                    maxDistanceByDeviceGPM = Math.floor((maxGPM - GPMDeltaFromPreviousOutlet) * Math.pow(this.Coverage(), 2) / (2 * this.EngineInfo.SideGPM * curOutletRec.ActualOutletRadius));
                }
            }
            spacingByGPM = this.GetTrialOutlet(maxDistanceByDeviceGPM, iCurOutlet, true, useSpecialESPSpacing);
            spacingByMaxDist = this.GetTrialOutlet(maxDistanceByDeviceSpacing, iCurOutlet, false, useSpecialESPSpacing);
            if (spacingByMaxDist.NoMoreOutlets || spacingByGPM.NoMoreOutlets) {
                result.Continue = false;
                return result;
            }
            if (this.Outlets[spacingByMaxDist.NewOutlet].IsTowerBoomBackOutlet) {
                result.NewSprinklerLocation = spacingByMaxDist.NewOutlet;
                result.Continue = true;
                return result;
            }
            if (reCalcIterationCount > 100) {
                /* TODO
                let time = DateTime.UtcNow;
                let sb = new StringBuilder();
                sb.AppendLine(GetErrorInfoHeader(time));
                sb.AppendLine("{NameOf(ComputeNextSprinkler)}: At iteration {reCalcIterationCount}");
                sb.AppendLine("Current Outlet:{iCurOutlet}, Previous Outlet:{prevOutlet}");
                sb.AppendLine("Max GPM Data at Outlet: MaxGPM:{outletMaxGPMData.MaxGPM:0.00000}, MaxGPMIgnoreMinPressure:{outletMaxGPMData.MaxGPMIgnoreMinPressure:0.00000}");
                sb.AppendLine("        ForceReg:{outletMaxGPMData.OutletPressureData.ForceReg}, PressureAtDevice:{outletMaxGPMData.OutletPressureData.PressureAtDevice:0.00000}, PressureAtOutlet:{outletMaxGPMData.OutletPressureData.PressureAtOutlet:0.00000}");
                sb.AppendLine("        ForceReg:{outletMaxGPMData.OutletPressureData.RegAvail}, RegPressure:{outletMaxGPMData.OutletPressureData.RegPressure:0.0}, RegPressureMet:{outletMaxGPMData.OutletPressureData.RegPressureMet}  ");
                sb.AppendLine("        MaxDistanceByDeviceSpacing:{maxDistanceByDeviceSpacing}, MaxDistanceByDeviceGPM:{maxDistanceByDeviceGPM}");
                sb.AppendLine("Spacing By GPM details: Ideal:{spacingByGPM.Ideal}, GPMRequired:{spacingByGPM.GPMRequired}, NewOutlet:{spacingByGPM.NewOutlet}, Space:{spacingByGPM.Space}, NoMoreOutlets:{spacingByGPM.NoMoreOutlets}");
                sb.AppendLine("Spacing By MaxDist details:  Ideal:{spacingByMaxDist.Ideal}, GPMRequired:{spacingByMaxDist.GPMRequired}, NewOutlet:{spacingByMaxDist.NewOutlet}, Space:{spacingByMaxDist.Space}, NoMoreOutlets:{spacingByMaxDist.NoMoreOutlets}");
                sb.AppendLine("Is new Outlet a TowerBoomBackOutlet:{Outlets(spacingByMaxDist.NewOutlet).IsTowerBoomBackOutlet}");
                let quoteForm = AppClass.Instance.frmMain.GetTopmostQuote();
                SendErrorToCloud(quoteForm?.Quote, sb.ToString(), time);
                Throw New Exception(sb.ToString()) */
                throw new Error();
            }
            if (spacingByMaxDist.GPMRequired > maxGPM || towerCouldNotBeCrossed || this._SpaceReduction === SpaceReduction.Commit) {
                if (spaceDrop === SpaceDrop.User && this._Spacing <= 1320) {
                    spaceDrop = SpaceDrop.Gradual;
                }
                switch (spaceDrop) {
                    case SpaceDrop.Gradual:
                        if (this._SpaceReduction === SpaceReduction.Commit) {
                            this._Spacing = spacingByMaxDist.Space + 180;
                            curOutletRec.MaximumSpacingSetting = this._Spacing;
                            this._SpaceReduction = SpaceReduction.Committed;
                            towerCouldNotBeCrossed = false;
                            recalc = true;
                            continue;
                        }
                        if (!tryExceedGPM && this._GPMExceededCount < 2) {
                            tryExceedGPM = true;
                            recalc = true;
                            continue;
                        }
                        if (maxGPMIgnoreMinPressure > maxGPM) {
                            throw new SprinklerEngineException(WarningSeverity.Critical, WarningTypes.NozzlePressureNotMet, strLanguageText("sltSelectNozzle"),
                                                        StringFormat(strLanguageText("sltInlinePressureTooLow"), Math.floor(curOutletRec.PipeLocation / 120)) + "\n" + strLanguageText("sltYouShouldEitherIncrease"));
                        }
                        result.NewSprinklerLocation = spacingByGPM.NewOutlet;
                        oldSpacing = this._Spacing;
                        if (towerCouldNotBeCrossed && newSpacing > 0) {
                            this._Spacing = newSpacing + 180;
                            curOutletRec.MaximumSpacingSetting = this._Spacing;
                        } else {
                            this._Spacing = spacingByGPM.Space + 180;
                            curOutletRec.MaximumSpacingSetting = this._Spacing;
                        }
                        if (!this.IsESPCompliant(iCurOutlet, result.NewSprinklerLocation)) {
                            if (!useSpecialESPSpacing) {
                                this.TrySpecialESPSpacing(iCurOutlet, result.NewSprinklerLocation, oldSpacing);
                            }
                            this.HuntForAnESPCompliantOutlet(iCurOutlet, result.NewSprinklerLocation);
                        }
                        this.Outlets[result.NewSprinklerLocation].FurrowArmAdjustment = this.ComputeFAAdjustment(result.NewSprinklerLocation, spacingByGPM.Ideal);
                        break;
                    case SpaceDrop.User:
                        if (this._SpaceReduction === SpaceReduction.Commit) {
                            this._SpaceReduction = SpaceReduction.Committed;
                        }
                        if (this._Spacing >= 2880) {
                            this._Spacing = 2400;
                            curOutletRec.MaximumSpacingSetting = this._Spacing;
                        } else {
                            this._Spacing = 1320;
                            curOutletRec.MaximumSpacingSetting = this._Spacing;
                        }
                        towerCouldNotBeCrossed = false;
                        recalc = true;
                        continue;
                        break;
                    case SpaceDrop.Stop:
                        if (this._SpaceReduction === SpaceReduction.Commit) {
                            this._Spacing = spacingByMaxDist.Space + 180;
                            curOutletRec.MaximumSpacingSetting = this._Spacing;
                            this._SpaceReduction = SpaceReduction.Committed;
                            towerCouldNotBeCrossed = false;
                            recalc = true;
                            continue;
                        }
                        if (!tryExceedGPM && this._GPMExceededCount < 2) {
                            tryExceedGPM = true;
                            recalc = true;
                            continue;
                        }
                        if (maxGPMIgnoreMinPressure > maxGPM) {
                            throw new SprinklerEngineException(WarningSeverity.Critical, WarningTypes.NozzlePressureNotMet, strLanguageText("sltSelectNozzle"),
                                                        StringFormat(strLanguageText("sltInlinePressureTooLow"), Math.floor(curOutletRec.PipeLocation / 120)) + "\n" + strLanguageText("sltYouShouldEitherIncrease"));
                        } else {
                            throw new SprinklerEngineException(WarningSeverity.Critical, WarningTypes.SystemParams, strLanguageText("sltMaxSprinklerSpacing"), StringFormat(strLanguageText("sltTheGPMRequiredExceeds"), Math.floor(curOutletRec.PipeLocation / 120)));
                        }
                        break;
                }
                gotoExitFunc = true;
                break;
            }
            result.NewSprinklerLocation = spacingByMaxDist.NewOutlet;
            const okToCrossResult = this.OKToCrossATower(maxDistanceByDeviceSpacing, spacingByMaxDist.Space, spacingByMaxDist.NewOutlet, spacingByGPM.NewOutlet, iCurOutlet, spacingByGPM.GPMRequired, maxGPM, useSpecialESPSpacing, towerCouldNotBeCrossed, newSpacing);
            towerCouldNotBeCrossed = okToCrossResult.bDropSpacing;
            newSpacing = okToCrossResult.lNewSpacing;
            if (okToCrossResult.result) {
                result.NewSprinklerLocation += 1;
                if (this.GetOutletType(result.NewSprinklerLocation) === OutletTypes.Imaginary) {
                    result.NewSprinklerLocation += 1;
                }
                this.HuntForAnESPCompliantOutlet(iCurOutlet, result.NewSprinklerLocation);
                gotoExitFunc = true;
                break;
            }
            if (towerCouldNotBeCrossed) {
                if (!this.SystemInfo.HasSACESP) {
                    recalc = true;
                    continue;
                }
            }
        } while (recalc);

        if (!gotoExitFunc) {
            if (!this.IsESPCompliant(iCurOutlet, result.NewSprinklerLocation)) {
                if (!useSpecialESPSpacing) {
                    this.TrySpecialESPSpacing(iCurOutlet, result.NewSprinklerLocation, oldSpacing);
                }
                this.HuntForAnESPCompliantOutlet(iCurOutlet, result.NewSprinklerLocation);
            }
            this.Outlets[result.NewSprinklerLocation].FurrowArmAdjustment = this.ComputeFAAdjustment(result.NewSprinklerLocation, spacingByMaxDist.Ideal);
        }
        if (!tryExceedGPM) {
            this._GPMExceededCount = 0;
        } else {
            if (spacingByGPM.GPMRequired > nonAdjustedMaxGPM) {
                this._GPMExceededCount += 1;
            }
        }
        result.Continue = true;
        return result;
    }

    private PlaceInitialSprinkler = (): boolean => {
        let nextSprinklerResult = this.ComputeNextSprinkler(0, false);
        if (!nextSprinklerResult.Continue) {
            return false;
        }
        this._lastSprinkler = 0;
        return this.PlaceSprinkler(this._nextSprinkler, this._lastSprinkler);
    }

    private PlaceSprinkler = (iCurOutlet: number, iLast: number): boolean => {
        let sLastGPM: number;
        let bNextFound: boolean;
        let bRegUsed: boolean;
        let iTempNozzle: number;
        let iRetryAttempts: number;
        let lSaveSpacing: number;
        let oCur: OutletRecord = this.Outlets[iCurOutlet];
        let pkg: PackageRecord = this.Package[oCur.PackageNumber];
        oCur.IsInUse = true;
        oCur.DeviceType = pkg.DeviceType;
        oCur.UseNelson3030 = pkg.UseNelson3030;
        oCur.UseNelsonAD3030MT = pkg.UseNelsonAD3030MT;
        oCur.RegulatorPSI = pkg.RegulatorPSI;
        oCur.DeviceDoubled = pkg.DevicesDoubled;
        if (iLast === 0) {
            sLastGPM = this.EngineInfo.SideGPM;
        } else {
            sLastGPM = this.Outlets[iLast].DownstreamGPM;
        }
        let selectNozzleResult = this.SelectNozzle(iCurOutlet, 0);
        oCur.NozzleUsed = selectNozzleResult.PreferredNozzle;
        oCur.PressureAtOutlet = selectNozzleResult.PressureAtOutlet;
        oCur.PressureAtDevice = selectNozzleResult.PressureAtDevice;
        oCur.GPMRequired = selectNozzleResult.GPMRequired;
        oCur.GPMDelivered = selectNozzleResult.GPMDeliverd;
        oCur.GPMMaximum = selectNozzleResult.GPMMaximum;
        bRegUsed = selectNozzleResult.RegUsed;
        iRetryAttempts = 0;
        lSaveSpacing = this._Spacing;
        this._SpaceReduction = SpaceReduction.NoneAttempted;
        let gotoRetryWithNewSpacing: boolean;
        do {
            gotoRetryWithNewSpacing = false;
            this._Spacing = lSaveSpacing;
            oCur.MaximumSpacingSetting = this._Spacing;
            oCur.DownstreamGPM = sLastGPM - oCur.GPMDelivered;
            iTempNozzle = oCur.NozzleUsed;
            if (this.SystemInfo.IsLateralMove && this.SystemInfo.HasDualSprinklerPackage) {
            }
            let nextSprinklerResult = this.ComputeNextSprinkler(iCurOutlet, false);
            this._nextSprinkler = nextSprinklerResult.NewSprinklerLocation;
            bNextFound = nextSprinklerResult.Continue;
            if (bNextFound || iLast === 0) {
                oCur.GPMRequired = this.RequiredSprinklerGPM(iLast, this.ActualOutletRadius(this._nextSprinkler), true);
                oCur.GPMReqNonAdjusted = this.RequiredSprinklerGPM(iLast, this.ActualOutletRadius(this._nextSprinkler), false);
            } else {
                oCur.GPMRequired = this.RequiredSprinklerGPM(iLast, this.SystemCoverageRadius * 2 - oCur.Radius, true);
                oCur.GPMReqNonAdjusted = this.RequiredSprinklerGPM(iLast, this.SystemCoverageRadius * 2 - oCur.Radius, false);
            }
            selectNozzleResult = this.SelectNozzle(iCurOutlet, oCur.GPMRequired);
            oCur.NozzleUsed = selectNozzleResult.PreferredNozzle;
            oCur.PressureAtOutlet = selectNozzleResult.PressureAtOutlet;
            oCur.PressureAtDevice = selectNozzleResult.PressureAtDevice;
            oCur.GPMRequired = selectNozzleResult.GPMRequired;
            oCur.GPMDelivered = selectNozzleResult.GPMDeliverd;
            oCur.GPMMaximum = selectNozzleResult.GPMMaximum;
            bRegUsed = selectNozzleResult.RegUsed;
            // @ts-ignore TODO Reinke apparently we never get in here
            if (this._SpaceReduction === SpaceReduction.Trial) {
                this._SpaceReduction = SpaceReduction.OkToCommit;
                gotoRetryWithNewSpacing = true;
                continue;
            }
            this._SpaceReduction = SpaceReduction.NoneAttempted;
            if (iTempNozzle !== oCur.NozzleUsed) {
                iRetryAttempts += 1;
                if (iRetryAttempts < 4) {
                    gotoRetryWithNewSpacing = true;
                    continue;
                }
            }
        } while (gotoRetryWithNewSpacing);
        if (bRegUsed) {
            if (oCur.IsTowerBoomBackOutlet) {
                switch (oCur.RegulatorPSI) {
                    case RegulatorPressures.a6:
                    case RegulatorPressures.a10:
                    case RegulatorPressures.a15:
                        break;
                    default:
                        oCur.RegulatorPSI = RegulatorPressures.a20;
                        break;
                }
            }
            oCur.RegulatorType = this.SelectReg(pkg, oCur.GPMDelivered);
        } else {
            oCur.RegulatorType = RegulatorTypes.None;
        }
        oCur.DownstreamGPM = sLastGPM - oCur.GPMDelivered;
        oCur.SACDistributionGPM = oCur.GPMDelivered;
        if (this.IsSACOutlet(iCurOutlet)) {
            let isSacOutlet = oCur.IsSACOutlet;
            let lastPossibleOutlet: number = this.Outlets[this.Outlets.length - 1].Outlet.OutletNumber;
            switch (this.Side.EndOfSystem.SwingArmLength) {
                case SwingArmLengths.SAC156:
                    oCur.SACOutletNumber = iCurOutlet - (lastPossibleOutlet - 43);
                    break;
                case SwingArmLengths.SAC175:
                    oCur.SACOutletNumber = iCurOutlet - (lastPossibleOutlet - 49);
                    break;
                case SwingArmLengths.SAC194:
                    oCur.SACOutletNumber = iCurOutlet - (lastPossibleOutlet - 58);
                    break;
                case SwingArmLengths.SAC213:
                    oCur.SACOutletNumber = iCurOutlet - (lastPossibleOutlet - 66);
                    break;
            }
            oCur.SACZoneNumber = this.ZoneOutletRelations.SelectZone(this.SystemInfo.ZoneControlType, this.Side.EndOfSystem.SwingArmLength, oCur.SACOutletNumber);
            if (this.SystemInfo.VRISACSpan) {
                oCur.SACDistributionFactor = this.ZoneFactors.SelectFactor(SACZoneControlTypes.ESAC125, SACDistributionFlowRate.High, this.Side.EndOfSystem.SwingArmLength, oCur.SACZoneNumber);
            } else {
                oCur.SACDistributionFactor = this.ZoneFactors.SelectFactor(this.SystemInfo.ZoneControlType, this.SystemInfo.DistributionFlowRate, this.Side.EndOfSystem.SwingArmLength, oCur.SACZoneNumber);
            }
            oCur.SACDistributionGPM = oCur.GPMDelivered * oCur.SACDistributionFactor;
        }
        this._lastSprinkler = iCurOutlet;

        //console.log(`${iCurOutlet},${oCur.GPMDelivered}`);

        return bNextFound;
    }

    private PlaceESP = (): boolean => {
        let iNext: number = this.NextSprinkler(this._curESP);
        if (iNext - this._curESP <= 1) {
            return false;
        }
        if (this.IsSACOutlet(iNext)) {
            if (this.IsSACOutlet(iNext - 1)) {
                return false;
            }
        }
        let iCurOutlet: number = 0;
        let i: number;
        for (i = this._curESP; i <= iNext; i++) {
            if (this.Outlets[i].IsESPOutlet) {
                iCurOutlet = i;
                break;
            }
        }
        if (iCurOutlet === 0) {
            this._curESP = iNext;
            return true;
        }
        let oCur: OutletRecord = this.Outlets[iCurOutlet];
        oCur.IsInUse = true;
        oCur.DeviceType = this.Package[oCur.PackageNumber].DeviceType;
        oCur.RegulatorPSI = this.Package[oCur.PackageNumber].RegulatorPSI;
        let selectedNozzleResult = this.SelectNozzle(iCurOutlet, 0);
        oCur.NozzleUsed = selectedNozzleResult.PreferredNozzle;
        oCur.PressureAtOutlet = selectedNozzleResult.PressureAtOutlet;
        oCur.PressureAtDevice = selectedNozzleResult.PressureAtDevice;
        oCur.GPMRequired = selectedNozzleResult.GPMRequired;
        oCur.GPMDelivered = selectedNozzleResult.GPMDeliverd;
        if (selectedNozzleResult.RegUsed) {
            oCur.RegulatorType = this.SelectReg(this.Package[oCur.PackageNumber], oCur.GPMRequired);
        } else {
            oCur.RegulatorType = RegulatorTypes.None;
        }
        oCur.DownstreamGPM = 0;
        this._curESP = iNext;
        return true;
    }

    private ResetGlobalVariables = () => {
        this._nextSprinkler = 0;
        this._lastSprinkler = 0;
        this._IsESPStarted = false;
        this._forceSpecialESPSpacing = false;
        this._highFlowCount = 0;
        this._GPMExceededCount = 0;
        this._Spacing = 0;
        this.OutletControl.Reset();
    }

    private ClearSprinklers = () => {
        this.ResetGlobalVariables();
        this.Outlets.forEach((o) =>
            o.Reset()
        );
    }

    public ClearEndGun = () => {
        let egr: EndGunRecord = this.EndGunInfo;
        if (!!egr.EGH) {
            egr.EGH = new EndGunHelper(this.SystemInfo.IsLateralMove, this.EngineInfo.SideGPM, egr.BoosterPump, egr.FrequencyHz, this.SystemPipeRadius, this.SystemCoverageRadius, this.EngineInfo.AdjustedEndPressure, egr.EGH.EndGunType, egr.EGH.UseDiffuser);
        } else {
            egr.EGH = new EndGunHelper(this.SystemInfo.IsLateralMove, this.EngineInfo.SideGPM, egr.BoosterPump, egr.FrequencyHz, this.SystemPipeRadius, this.SystemCoverageRadius, this.EngineInfo.AdjustedEndPressure, egr.EndGun, false);
        }
    }

    public AddOutlet = (outlet: IOutlet, curSpan: Span, curPackage: IPackage) => {
        if (this.OutletControl.OutletCount === 0) {
            let startLocationConverted = this.SystemInfo.StartingLocation * 120;
            let o = new OutletRecord();
            Object.assign(o, {
                SpanNumber: 1,
                PipeLocation: Math.round(startLocationConverted),
                PackageNumber: this.SelectSprinklerPackageNumberByLocation(startLocationConverted),
                Radius: Math.round(startLocationConverted)
            });
            this.Outlets.push(o);
        }
        let pipeLocationConverted = outlet.Location * 120;
        let o = new OutletRecord();
        Object.assign(o, {
            Outlet: outlet,
            Span: curSpan,
            Package: curPackage,
            PipeLocation: Math.round(pipeLocationConverted),
            PackageNumber: this.SelectSprinklerPackageNumberByLocation(pipeLocationConverted),
            Radius: Math.round(outlet.Radius * 120),
            HookPipeLocation: Math.round(outlet.HookLocation * 120),
            HookRadius: Math.round(outlet.HookRadius * 120),
            SpanNumber: outlet.SpanNumber,
            OutletNumber: outlet.OutletNumber,
            IsSACOutlet: outlet.IsSACOutlet,
            IsEndBoomOutlet: outlet.IsEndBoomOutlet,
            IsTowerBoomBackOutlet: outlet.IsTowerBoomBackOutlet,
            AbsoluteHeight: outlet.AbsoluteHeight
        });
        this.Outlets.push(o);
        this.OutletControl.OutletCount += 1;
    }

    public AddSpan = (curSpan: Span, insideDiameter: number, cFactor: number, startLocations: number, startRadius: number, endLocation: number, endRadius: number) => {
        if (this.SpanCount === this._MaxSpans) {
            return;
        }
        if (this.Spans.length === 0) {
            this.Spans.push(new SpanRecord());
        }
        let s: SpanRecord = {
            Span: curSpan,
            StartLocation: startLocations * 120,
            StartRadius: startRadius * 120,
            InsideDiameter: insideDiameter,
            CFactor: cFactor,
            EndLocation: endLocation * 120,
            EndRadius: endRadius * 120
        };
        this.Spans.push(s);
        this.SpanCount += 1;
    }

    public TranslateBoomBackToDeviceType = (val: BoomBackDevices): DeviceTypes => {
        switch (val) {
            case BoomBackDevices.D3000:
                return DeviceTypes.NelsonD3000PartCircle;
                break;
            case BoomBackDevices.S3000:
                return DeviceTypes.NelsonS3000PartCircle;
                break;
            case BoomBackDevices.R3000:
                return DeviceTypes.NelsonR3000PartCircle;
                break;
            case BoomBackDevices.LDN:
                return DeviceTypes.SenningerLDNSpray;
                break;
            case BoomBackDevices.Fan:
                return DeviceTypes.Senninger180Fan;
                break;
            case BoomBackDevices.Komet:
                return DeviceTypes.KometSprayPartCircle;
                break;
            case BoomBackDevices.KometTwisterPartCircle:
                return DeviceTypes.KometTwisterPartCircle;
                break;
            default:
                throw new Error();
                /* TODO: Throw New ArgumentException($"{NameOf(TranslateBoomBackToDeviceType)}: Unhandled {NameOf(BoomBackDevices)} value '{val}'") */
                break;
        }
    }

}

