import { X2jOptions, XMLParser } from "fast-xml-parser";
import { DefaultOutlet } from "rdptypes/project/ISprinklerChart";
import { BoomBackTypes, DefaultPackage, DeviceTypes } from "rdptypes/project/ISprinklers";
import { AgriInjectTypes, BoosterPumpTypes, BuckBoostTypes, FlowmeterModels, FlowmeterTypes, GeneratorFrameTypes, GeneratorOptions, ISystemBase, LightTypes, SystemTypes, TowTypes } from "rdptypes/project/ISystemBase.AutoGenerated";
import { mergeDeep } from "roedata/helpers/mergeDeep";
import { Side, Span, Tower } from "roedata/roe_migration/Types";
import { convertQuoteProperties } from "./IOldProposalInformation";

const emptyStringsToBooleans = (o: any): void => {
    for (const k of Object.keys(o)) {
        if (o[k] === ""
            && k !== "Description" // MiscItem description
            && k !== "ESAC125DistributionFlowRateFactors"
            && k.indexOf("Comments") === -1
            && k.indexOf("Notes") === -1
            && k.indexOf("GPSCoverageStudy") === -1
            && k.indexOf("Address") === -1
            ) {
            o[k] = true;
        } else if (typeof o[k] === "object") {
            emptyStringsToBooleans(o[k]);
        }
    }
}

export default class XmlImporter {
    public decodeXml(xml: string | Buffer): ISystemBase {
        const options: X2jOptions = {
            ignoreAttributes: false,
            attributeNamePrefix: "",
            parseAttributeValue: true,
            isArray: (name, jpath, isLeafNode, isAttribute) => name === "Package" || name === "Tower" || name === "Span" || name === "Outlet",
            updateTag: (tagName, jPath, attrs) => {
                if (jPath.endsWith("EndOfSystem.EndGun.EndGun")) return "EndGunTypePrimary";
                if (jPath.endsWith("EndOfSystem.EndGun.EndGunSecondary")) return "EndGunTypeSecondary";
                if (jPath.endsWith("EndGunIrrigationProperties.EndGun")) return "EndGunTypeCalculated";
                if (jPath.endsWith("SecondaryEndGunIrrigationProperties.EndGun")) return "EndGunTypeCalculated";
                if (jPath.endsWith("Circle.CenterPivot.RiserPipe")) return "RiserPipeType";
                if (jPath.endsWith("ControlPanel.LowPressureSafety")) return "LowPressureSafetyReg";
                if (jPath.endsWith("WheelGearSeries")) return "WheelGear";
                if (jPath.endsWith("DropNextToEndGunField")) return "DropNextToEndGun";
                if (jPath.endsWith("SACZoneControlType")) return "ZoneControlType";
                if (jPath.endsWith("SACDistributionFlowRate")) return "DistributionFlowRate";
                if (jPath.endsWith("TireInfo")) return "TireProperties";
                if (jPath.endsWith("RC10FacePlateUpgrade")) return "FacePlateOption";
                if (jPath.endsWith("TheftDeterentClip")) return "TheftDeterrentClip";
                if (jPath.endsWith("RC10.BaseModel")) return "ModemBaseModel";
                if (jPath.endsWith("FlowTubeWithVanes")) return "FlowtubeWithVanes";
                return undefined;
            },
            tagValueProcessor: (tagName, tagValue, jPath, hasAttributes, isLeafNode) => {
                if (tagValue === "") {
                    return true;
                }
                if ((tagName === "Spacing" || tagName === "Length" || tagName == "MinCableGauge") && tagValue.startsWith("a")) {
                    if (tagValue === "a1867") {
                        return 186.7;
                    } else if (jPath.endsWith("RigidDrop.Length")) {
                        return 0.1 * parseInt(tagValue.substring(1));
                    } else {
                        return parseInt(tagValue.substring(1));
                    }
                }
                return tagValue;
            },
            parseTagValue: true
        };
        const parser = new XMLParser(options);
        const quote = parser.parse(xml);

        const sys: ISystemBase = quote.Main.Field.Systems.System;
        emptyStringsToBooleans(sys);

        if (sys.FlangedSide) {
            this.cleanupSide(sys.FlangedSide);
        }
        if (sys.FlexSide) {
            this.cleanupSide(sys.FlexSide);
        }

        const fullSys: ISystemBase = {
            SystemProperties: {
                ReverseTow: false,
                SystemType: SystemTypes.Ancillary // Default to Ancillary if system type not set
            },

            DealerProperties: {},

            MainlineValveOptions: {
                Valves: {
                    MainlineValve: []
                }
            },

            Ontrac: {
                IsEnabled: false,
                Radio: {},
                SatellitePlus: {
                    SatellitePlusUnit: []
                },
                Cell: {}
            },

            SprinklerProperties: {
                VRIZones: {
                    Zone: []
                },
                PeakElevation: 0,
                EndPressureGauge: false,
                DualSprinklerPackage: false,
                EndGunOperationPivotMode: false,
                EndGunOperationLateralMode: false,
                ControlLineBoosterPump: false
            },

            Options: {
                PivotLight: LightTypes.None,
                DoubleEndFeedSafetyValve: false,
                FlushValve: false,
                SafetyValve: false,
                IntakeDrainValve: false,
                SpareBoltPackage: false,
                ReinkeSignMount: false,
                ChemTankMountKit: false,
                AuxAccessStep: false,
                PivotLightRAMSConstantOn: false,
                JackFootKit: false,
                GeneratorSlideRails: false,
                EngDigitalPanel: false,
                FuelTank: false,
                GeneratorOption: GeneratorOptions.None,
                GeneratorFrameType: GeneratorFrameTypes.OpenFrame,
            },

            FlangedSide: {
                Span: [],
                Tower: [],
                EndOfSystem: {
                    EndLightConstantOn: false,
                    VerticalBoosterMount: false,
                    StainlessSteelMounts: false,
                    Strainer: false,
                    ExtraReinkeEndSign: false,
                    EndLight: LightTypes.None,
                    EndGun: {
                        BoosterPump: BoosterPumpTypes.None,
                        DiffuserLegacy: false
                    }
                },
                Sprinklers: {
                    BoomBacks: {
                        BoomBackType: BoomBackTypes.None
                    },
                    Package: []
                },
                SprinklerChart: {
                    IrrigationProperties: {
                        EndGunIrrigationProperties: {},
                        SecondaryEndGunIrrigationProperties: {}
                    },
                    Outlet: []
                }
            },

            FlexSide: {
                Span: [],
                Tower: [],
                EndOfSystem: {
                    EndLightConstantOn: false,
                    VerticalBoosterMount: false,
                    StainlessSteelMounts: false,
                    Strainer: false,
                    ExtraReinkeEndSign: false,
                    EndLight: LightTypes.None,
                    EndGun: {
                        BoosterPump: BoosterPumpTypes.None,
                        DiffuserLegacy: false}
                },
                Sprinklers: {
                    BoomBacks: {},
                    Package: []
                },
                SprinklerChart: {
                    IrrigationProperties: {
                        EndGunIrrigationProperties: {},
                        SecondaryEndGunIrrigationProperties: {}
                    },
                    Outlet: []
                }
            },

            AgriInject: {
                AgriInjectType: AgriInjectTypes.None,
            },
            SprinklerLube: {},
            HeatExchangers: {},
            ClemonsFilters: {},
            CheckValves: {},
            FlowmeterComponents: {},
            RC10: {
                IsEnabled: false,
                LowPressureShutdownSwitch: false,
                SwingArmCorner: false,
                PowerAutoRestart: false,
                HasRPMStandardWithAutoReverse: false,
                AutoRestartPanelKit: false,
                EndOfSystemGPSPanelKit: false,
                PumpControl: false,
                ChemControl: false,
                EndGunControl: false,
                AuxControl: false,
                SpeedControl: false,
                StartRelayKit: false,
                PowerRelay: false,
                Rain: false,
                Wind: false,
                Humidity: false,
                Temperature: false,
                RainWindMountingKit: false,
                PressureSwitch20Psi: false,
                PressureSwitch45Psi: false,
                PressureTransducer0200: false,
                PressureTransducer025: false,
            },
            Circle: {
                CenterPivot: {
                    TowOptions: {
                        TowType: TowTypes.None,
                        Tires: {
                            OneTireMountedInReverse: false,
                            TubesForTires: false
                        },
                        ExtraHitch: false
                    },
                E2045FoundationFormKit: false,
                Walkway: false,
                MaleRingLock: false,
                PipeBottomAdapter8to6: false,
                PipeBottomAdapter: false,
                PerpendicularOffset: false,
                RiserPipeFor6InchValve: false,
                RiserPipeFor6x19FlangeDevice: false,
                RiserPipeFor8x23FlangeDevice: false
                },
                KwikTow: {
                    Tires: {
                        TubesForTires: false,
                        OneTireMountedInReverse: false
                    },
                    MotorizedCenter: false,
                    HydraulicLift: false,
                    SafetyValve: false
                },
                SwingArm: {
                    Tires: {
                        TubesForTires: false,
                        OneTireMountedInReverse: false
                    },
                    SteeringMotorCover: false,
                    ExtraGPSDataCable: false,
                    GPSBaseConvert: false,
                    LeadingSwingArm: false,
                    EnergySaverPackage: false,
                    ControlLineBoosterESP: false,
                    ControlLineBooster: false,
                    AirCompressor: false,
                    VRISACSpan: false,
                    GPSCoverageStudyCompleted: false,
                },
                SACRetro: {
                    TowerUL: false,
                    IsControlPanelVRISACSpanCompliant: false,
                    TowerBoxKit: false,
                    NewLastSpanWire: false,
                    OmitStiffenerStabilizer: false,
                },
                SinglePhase230VoltSystem: false,
                EngineDrivenMiniPivot: false
            },

            CropX: {
                IsEnabled: false,
                ProbelessIrrigationScheduling: false
            },

            Lateral: {
                Tires: {
                    TubesForTires: false,
                    OneTireMountedInReverse: false
                },
                HoseFeed: {
                    PivotCenterWithSSRiserPipe: false,
                    HydraulicLift: false,
                    JunctionBoxWithPlug: false,
                    PivotOption: false,
                    PowerTowerEndBoom: false,
                    SingleSpanMaxi: false,
                    Towable: false,
                    CenterLift: false,
                    HydraulicPumpKit: false,
                    RotateCart: false,
                    BallastKit: false,
                    HDWheelGear: false,
                    SafetyValve: false,
                },
                CanalFeed: {
                    BoosterPumpSelfCleaningScreen: false,
                },
                Guidance: {
                    FurrowOptions: {
                        LongSystem: false
                    },
                    CableOptions: {},
                    BuriedWireOptions: {
                        Wire: {
                            
                        },
                        SolarPowered: false
                    },
                    GPSCoverageStudyCompleted: false
                },
                ButterflyValves: false,
                DropFlexSide: false,
            },

            ControlPanel: {
                RemotePanelOptions: {
                    RemotePanel: false
                },
                FlowmeterModel: FlowmeterModels.None,
                Flowmeter: FlowmeterTypes.None,
                PACTimer: false,
                LightningArrestor: false,
                EndGunShutoffOverride: false,
                PowerAutoRestart: false,
                PivotAutoStop: false,
                PivotAutoReverse: false,
                CartAutoReverse: false,
                ChemAuxControl: false,
                InjectorPump: false,
                InjectorPump30Amp: false,
                GenStartControl: false,
                RemoteMCPLessMount: false,
                LowPressureSafetyReg: false,
                LowPressureSafetyLP: false,
                LowVoltageSafety: false,
                FrostControl: false,
                WeepHolePlug5Pack: false,
                WeepHolePlug50Pack: false,
                RainGauge: false,
                WindGauge: false,
                LargeServiceWireKit: false,
                PanelUL: false,
                TowerUL: false,
                GFI: false,
                GroundingBar: false,
                NonFuseCarrier: false,
                PressureTransducer0to25PSI: false,
                PressureRestart: false,
                TemperatureProbe: false,
                FlowtubeWithVanes: false,
                FlowtubeWithFS100: false,
                FlowmeterPulse: false,
                MCPSpanCableDisconnect: false
            },

            SpanProperties: {},

            TowerProperties: {
                TireProperties: {
                    TubesForTires: false,
                    OneTireMountedInReverse: false,
                },
                UMCCoverKit: false,
                CattleGuards: false,
                DrainCouplers: false,
                LPDrainKit: false,
                WatermanDrain: false,
            },

            mcp: {
            },
        };

        mergeDeep(fullSys, sys);

        fullSys.CropX.IsEnabled = quote.Main.Field.Systems.System.FieldSets.CropX === "Valid" || quote.Main.Field.Systems.System.FieldSets.CropX === "Dirty";
        fullSys.RC10.IsEnabled = quote.Main.Field.Systems.System.FieldSets.RC10 === "Valid" || quote.Main.Field.Systems.System.FieldSets.RC10 === "Dirty";
        fullSys.Ontrac.IsEnabled = quote.Main.Field.Systems.System.FieldSets.Ontrac === "Valid" || quote.Main.Field.Systems.System.FieldSets.Ontrac === "Dirty";
        
        if (quote.Main.Field.Systems.System.Circle?.CenterPivot?.PercentOfCircle !== undefined) {
            fullSys.Circle.CenterPivot.isPartialPivot = true;
            fullSys.Circle.CenterPivot.clockwiseCompassHeadingStart = 0;
            fullSys.Circle.CenterPivot.isPartialPivot = true;
            fullSys.Circle.CenterPivot.clockwiseCompassHeadingEnd = 360 * (quote.Main.Field.Systems.System.Circle.CenterPivot.PercentOfCircle / 100);
        }

        fullSys.QuoteProperties = convertQuoteProperties(quote.Main.Field.Systems.System.QuoteProperties);

        /*if (fullSys.RC10.IsEnabled) {
            const etp = fullSys.RC10.PositioningDeviceOption ?? EndTowerPositioningTypes.None;
            const cpt = fullSys.RC10.RC10ControlPanel;
            switch (cpt) {
                case RC10PanelModels.RPMBasic:
                case RC10PanelModels.RPMStandard:
                case RC10PanelModels.RPMAdvanced:
                case RC10PanelModels.RPMAdvancedPlus:
                    case RC10PanelModels.RPMConnect:
                                        if ([EndTowerPositioningTypes.Resolver, EndTowerPositioningTypes.GPS].indexOf(etp) !== -1) {
                        fullSys.RC10.PumpControl = true;
                        fullSys.RC10.ChemControl = false;
                        fullSys.RC10.EndGunControl = true;
                        fullSys.RC10.AuxControl = true;
                        fullSys.RC10.SpeedControl = true;
                        fullSys.RC10.StartRelayKit = true;
                        fullSys.RC10.PowerRelay = false;
                }
                    break;
                case RC10PanelModels.RPMPreferred:
                    if ([EndTowerPositioningTypes.Resolver, EndTowerPositioningTypes.GPS].indexOf(etp) !== -1) {
                        fullSys.RC10.PumpControl = true;
                        fullSys.RC10.ChemControl = true;
                        fullSys.RC10.EndGunControl = true;
                        fullSys.RC10.AuxControl = true;
                        fullSys.RC10.SpeedControl = true;
                        fullSys.RC10.StartRelayKit = true;
                        fullSys.RC10.PowerRelay = false;
            }
                    break;
            }
        }*/

        return fullSys as any;
    }

    private cleanupSide(side: Side) {
        if ((side as any).Towers) {
            side.Tower = (side as any).Towers.Tower;
            for (let i = 0; i < side.Tower.length; i++) {
                side.Tower[i] = mergeDeep({
                    BuckBoost: BuckBoostTypes.aNone,
                    AutomatedValves: false,
                    // NOTE: The XML importer does not define a partsPackageId, which will mean that the createNewImportSystemAction
                    // called from importFieldXml will set the partsPackageId to 2024060101.
                    // As such, this partsPackage still contains the AirCompressor and ControlLineBoosterPump
                    // fields on the Tower object.
                    AirCompressor: false, // See NOTE above
                    ControlLineBoosterPump: false, // See NOTE above
                    HeavyDuty: false,
                    TowerCrossBrace: false,
                    StiffenerTrussToPipe: false,
                    StiffenerStabilizers: false,
                    TowerBaseAccessStep: false,
                    TowerAutoReverse: false,
                    TowerAutoStop: false,
                    Guidance: false,
                    ThreeTires: false,
                    ThreeTiresFlex: false,
                    AgriTrac: false,
                    Tires: {
                        TwelveLugs: false
                    }
                } as Tower, side.Tower[i]);
                const untypedTower: any = side.Tower[i];
                delete untypedTower.Tires.HeightAdjustment;
                delete untypedTower.BoxType;
                delete untypedTower.Height;
            }
        }

        if ((side as any).Spans) {
            side.Span = (side as any).Spans.Span;
            for (let i = 0; i < side.Span.length; i++) {
                side.Span[i] = mergeDeep({
                    Extension: false,
                    Disconnecting: false,
                    Stabilizers: false,
                    LargeTrussRods: false,
                    EndBoom: false,
                    SwingArm: false,
                } as Span, side.Span[i]);
                const untypedSpan: any = side.Span[i];
                delete untypedSpan.LengthInFeet;
                delete untypedSpan.PipeInsideDiameter;
                delete untypedSpan.PipeOutsideDiameter;
                delete untypedSpan.CFactor;
                delete untypedSpan.CableGauge;
                delete untypedSpan.StartingLocation;
                delete untypedSpan.EndingLocation;
                delete untypedSpan.StartingRadius;
                delete untypedSpan.EndingRadius;
                delete untypedSpan.GPMRequired;
                delete untypedSpan.GPMDelivered;
                if (side.Span[i].EndBoom || side.Span[i].SwingArm) {
                    delete untypedSpan.SpanType;
                    delete untypedSpan.Spacing;
                }
            }
        }

        if ((side as any).SprinklerChart?.Outlets) {
            side.SprinklerChart.Outlet = (side as any).SprinklerChart.Outlets.Outlet;

            for (let i = 0; i < side.SprinklerChart.Outlet.length; i++) {
                const o = side.SprinklerChart.Outlet[i];
                if (o.Device !== undefined) {
                    o.Device = DeviceTypes[o.Device] as any;
                }
                if (o.Radius === undefined) {
                    o.Radius = o.Location;
                }
                side.SprinklerChart.Outlet[i] = mergeDeep({}, DefaultOutlet, o);
            }
        }

        if ((side as any).Sprinklers?.Packages) {
            side.Sprinklers.Package = (side as any).Sprinklers.Packages.Package;

            for (let i = 0; i < 3; i++) {
                const pkg = side.Sprinklers.Package[i];
                if (pkg?.Device !== undefined) {
                    pkg.Device = DeviceTypes[pkg.Device] as any;
                }
                side.Sprinklers.Package[i] = mergeDeep({}, DefaultPackage, {
                    PackageNumber: i + 1
                }, pkg);
            }
        }
    }
}