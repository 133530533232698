import { Position, point } from "@turf/turf";
import IAction from "rdptypes/IAction";
import { SideEnum } from "rdptypes/helpers/SideEnum";
import { SystemTypes } from "rdptypes/project/ISystemBase.AutoGenerated";
import { ISpan, ITower } from "rdptypes/project/Types";
import * as React from "react";
import { FC, PropsWithChildren, useState } from "react";
import { createAddSpanTowerAction } from "../actions/AddSpan";
import { MultiActionData } from "../actions/MultiAction";
import { createSetEndOfSystemAction } from "../actions/SetEndOfSystemAction";
import { createNewUpdateSystemPropertyAction } from "../actions/UpdateSystemProperty";
import { executeAllActions } from "../actions/actionExecutorRegistry";
import { IGetDefaultNewProjectLayoutSystemActionsArgs, IGetDefaultNewProjectLayoutSystemActionsResult, getDefaultNewProjectLayoutSystemActions } from "../actions/defaultActions";
import IAuthState from "../auth/IAuthState";
import { CUSTOM_FIELD_SETTINGS_DEFAULT_FORM_STATE, IFieldSettings } from "../components/DealerSettingsDialog/FieldSettings";
import { CUSTOM_SAC_OPTIMIZER_SETTINGS_DEFAULT_FORM_STATE, ISacOptimizerSettingsFormState } from "../components/DealerSettingsDialog/SacOptimizerSettings";
import { ILogoSettingsFormState } from "../components/DealerSettingsDialog/TabDealerLogo";
import { CUSTOM_DISPLAY_SETTINGS_DEFAULT_FORM_STATE, IDisplaySettingsFormState } from "../components/DealerSettingsDialog/TabDisplaySettings";
import { CUSTOM_MAP_SETTINGS_DEFAULT_FORM_STATE, IMapSettingsFormState } from "../components/DealerSettingsDialog/TabMapSettings";
import { MapLayerType } from "../components/Map";
import { IOptimizeCenterPivotFormState, OPTIMIZATION_CENTER_PIVOT_FORM_STATE_DEFAULT } from "../components/OptimizeSystemDialog/OptimizeCenterPivotForm";
import { IOptimizeLateralFormState, OPTIMIZATION_LATERAL_FORM_STATE_DEFAULT } from "../components/OptimizeSystemDialog/OptimizeLateralForm";
import { ERdpFeatureType } from "../helpers/ERdpFeatureTypes";
import { ERdpLabels } from "../helpers/ERdpLabels";
import { ProjectType } from "../model/project/IProject";
import DevSettingsCtx from "./DevSettingsCtx";
import IDevSettingsState, { DEFUALT_MAP_LABELS_SETTINGS, IDealerCustomerSettings, IDealerPricing } from "./IDevSettingsState";


export const DEFAULT_CUSTOMER_SETTINGS: IDealerCustomerSettings = {
  systemIsTaxable: true,
  installationIsTaxable: true,
  listPriceDiscountPercent: 0,
  markupPercentOverDealerCost: 0,
  markupDollarOverDealerCost: 0,
  salesTaxPercent: 20,
  tradeinIsTaxable: true,
  freightIsTaxable: true,
  pivotPadIsTaxable: true,
  onlyPrintTotalPrice: false,
  printListPrice: true
}

const localStorageProvider = {
  // System settings:
  customSystemSettingsActions: {
    get: () => {
      const lsValue = window.localStorage.getItem('rdp.dev.customSystemSettingsActionsV3');
      return lsValue === null ? DEFAULT_NEW_PROJECT_LAYOUT_SYSTEM : JSON.parse(lsValue) as IGetDefaultNewProjectLayoutSystemActionsResult;
    },
    set: (value: IGetDefaultNewProjectLayoutSystemActionsResult) => {
      localStorage.setItem('rdp.dev.customSystemSettingsActionsV3', JSON.stringify(value));
    }
  },
  useCustomSystemSettings: {
    get: () => {
      const lsValue = window.localStorage.getItem('rdp.dev.useCustomSystemSettingsV3');
      return lsValue === null ? false : JSON.parse(lsValue) as boolean;
    },
    set: (value: boolean) => {
      localStorage.setItem('rdp.dev.useCustomSystemSettingsV3', JSON.stringify(value));
    }
  },
  useCustomCustomerSettings: {
    get: () => {
      const lsValue = window.localStorage.getItem('rdp.dev.useCustomCustomerSettings');
      return lsValue === null ? false : JSON.parse(lsValue) as boolean;
    },
    set: (value: boolean) => {
      localStorage.setItem('rdp.dev.useCustomCustomerSettings', JSON.stringify(value));
    }
  },
  customCustomerSettings: {
    get: () => {
      const lsValue = window.localStorage.getItem('rdp.dev.customCustomerSettings');
      return lsValue === null ? DEFAULT_CUSTOMER_SETTINGS : JSON.parse(lsValue) as IDealerCustomerSettings;
    },
    set: (value: IDealerCustomerSettings) => {
      localStorage.setItem('rdp.dev.customCustomerSettings', JSON.stringify(value));
    }
  },
  useCustomDealerPricing: {
    get: () => {
      const lsValue = window.localStorage.getItem('rdp.dev.useCustomDealerPricing');
      return lsValue === null ? false : JSON.parse(lsValue) as boolean;
    },
    set: (value: boolean) => {
      localStorage.setItem('rdp.dev.useCustomDealerPricing', JSON.stringify(value));
    }
  },
  customDealerPricing: {
    get: () => {
      const lsValue = window.localStorage.getItem('rdp.dev.customDealerPricing');
      return lsValue === null ? undefined : JSON.parse(lsValue) as IDealerPricing;
    },
    set: (value: IDealerPricing) => {
      localStorage.setItem('rdp.dev.customDealerPricing', JSON.stringify(value));
    }
  },
  mapLayerTypeSettings: {
    get: () => {
      const type = window.localStorage.getItem('rdp.dev.mapLayerType');
      return Number(type) as MapLayerType;
    },
    set: (type: MapLayerType) => {
      localStorage.setItem('rdp.dev.mapLayerType', JSON.stringify(type));
    }
  },
  mapShowLabelSetting: {
    get: () => {
      const lsValue = window.localStorage.getItem('rdp.dev.mapShowLabelSetting');
      return lsValue === 'true';
    },
    set: (value: boolean) => {
      localStorage.setItem('rdp.dev.mapShowLabelSetting', JSON.stringify(value));
    }
  },
  mapLabelsSettings: {
    get: () => {
      const lsValue = window.localStorage.getItem('rdp.dev.mapLabelsSettings');
      return lsValue === null ? DEFUALT_MAP_LABELS_SETTINGS : JSON.parse(lsValue) as (ERdpFeatureType | ERdpLabels)[];
    },
    set: (value: (ERdpFeatureType | ERdpLabels)[]) => {
      localStorage.setItem('rdp.dev.mapLabelsSettings', JSON.stringify(value));
    }
  },
  mapShowClearancesSetting: {
    get: () => {
      const lsValue = window.localStorage.getItem('rdp.dev.mapShowClearancesSetting');
      return lsValue === 'true';
    },
    set: (value: boolean) => {
      localStorage.setItem('rdp.dev.mapShowClearancesSetting', JSON.stringify(value));
    }
  },
  //growerFilterSettings
  growerFilterShowDeletedSetting: {
    get: () => {
      const lsValue = window.localStorage.getItem('rdp.dev.growerFilterShowDeletedSetting');
      return lsValue === 'true';
    },
    set: (value: boolean) => {
      localStorage.setItem('rdp.dev.growerFilterShowDeletedSetting', JSON.stringify(value));
    }
  },
  growerFilterShowSharedWithMeSetting: {
    get: () => {
      const lsValue = window.localStorage.getItem('rdp.dev.growerFilterShowSharedWithMeSetting');
      return lsValue !== "false";
    },
    set: (value: boolean) => {
      localStorage.setItem('rdp.dev.growerFilterShowSharedWithMeSetting', JSON.stringify(value));
    }
  },
  //projectFilterSettings
  projectFilterShowDeletedSetting: {
    get: () => {
      const lsValue = window.localStorage.getItem('rdp.dev.projectFilterShowDeletedSetting');
      return lsValue === 'true';
    },
    set: (value: boolean) => {
      localStorage.setItem('rdp.dev.projectFilterShowDeletedSetting', JSON.stringify(value));
    }
  },
  // Optimization settings (center pivot):
  customOptimizationSettingsCenterPivot: {
    get: () => {
      const lsValue = window.localStorage.getItem('rdp.dev.customOptimizationSettingsV3');
      return lsValue === null ? OPTIMIZATION_CENTER_PIVOT_FORM_STATE_DEFAULT : JSON.parse(lsValue) as IOptimizeCenterPivotFormState;
    },
    set: (value: IOptimizeCenterPivotFormState) => {
      localStorage.setItem('rdp.dev.customOptimizationSettingsV3', JSON.stringify(value));
    }
  },
  useCustomOptimizationSettingsCenterPivot: {
    get: () => {
      const lsValue = window.localStorage.getItem('rdp.dev.useCustomOptimizationSettings');
      return lsValue === null ? false : JSON.parse(lsValue) as boolean;
    },
    set: (value: boolean) => {
      localStorage.setItem('rdp.dev.useCustomOptimizationSettings', JSON.stringify(value));
    }
  },
  // Optimization settings (lateral):
  customOptimizationSettingsLateral: {
    get: () => {
      const lsValue = window.localStorage.getItem('rdp.dev.customOptimizationSettingsLateralV3');
      return lsValue === null ? OPTIMIZATION_LATERAL_FORM_STATE_DEFAULT : JSON.parse(lsValue) as IOptimizeLateralFormState;
    },
    set: (value: IOptimizeLateralFormState) => {
      localStorage.setItem('rdp.dev.customOptimizationSettingsLateralV3', JSON.stringify(value));
    }
  },
  useCustomOptimizationSettingsLateral: {
    get: () => {
      const lsValue = window.localStorage.getItem('rdp.dev.useCustomOptimizationSettingsLateral');
      return lsValue === null ? false : JSON.parse(lsValue) as boolean;
    },
    set: (value: boolean) => {
      localStorage.setItem('rdp.dev.useCustomOptimizationSettingsLateral', JSON.stringify(value));
    }
  },
  // Display Settings
  customDisplaySettings: {
    get: () => {
      const lsValue = window.localStorage.getItem('rdp.dev.customDisplaySettings');
      return lsValue === null ? CUSTOM_DISPLAY_SETTINGS_DEFAULT_FORM_STATE
       // In case new custom display settings are added in the future then use these as defaults
      : { ...CUSTOM_DISPLAY_SETTINGS_DEFAULT_FORM_STATE, ...JSON.parse(lsValue) } as IDisplaySettingsFormState;
    },
    set: (value: IDisplaySettingsFormState) => {
      localStorage.setItem('rdp.dev.customDisplaySettings', JSON.stringify(value));
    }
  },
  useCustomDisplaySettings: {
    get: () => {
      const lsValue = window.localStorage.getItem('rdp.dev.useCustomDisplaySettings');
      return lsValue === null ? false : JSON.parse(lsValue) as boolean;
    },
    set: (value: boolean) => {
      localStorage.setItem('rdp.dev.useCustomDisplaySettings', JSON.stringify(value));
    }
  },
  // Map Settings
  customMapSettings: {
    get: () => {
      const lsValue = window.localStorage.getItem('rdp.dev.customMapSettings');
      return lsValue === null ? CUSTOM_MAP_SETTINGS_DEFAULT_FORM_STATE : JSON.parse(lsValue) as IMapSettingsFormState;
    },
    set: (value: IMapSettingsFormState) => {
      localStorage.setItem('rdp.dev.customMapSettings', JSON.stringify(value));
    }
  },
  useCustomMapSettings: {
    get: () => {
      const lsValue = window.localStorage.getItem('rdp.dev.useCustomMapSettings');
      return lsValue === null ? false : JSON.parse(lsValue) as boolean;
    },
    set: (value: boolean) => {
      localStorage.setItem('rdp.dev.useCustomMapSettings', JSON.stringify(value));
    }
  },
  // SAC Opptimizer Settings
  customSacOptimizerSettings: {
    get: () => {
      const lsValue = window.localStorage.getItem('rdp.dev.customSacOptimizerSettings');
      return lsValue === null ? CUSTOM_SAC_OPTIMIZER_SETTINGS_DEFAULT_FORM_STATE : JSON.parse(lsValue) as ISacOptimizerSettingsFormState;
    },
    set: (value: ISacOptimizerSettingsFormState) => {
      localStorage.setItem('rdp.dev.customSacOptimizerSettings', JSON.stringify(value));
    }
  },
  useCustomSacOptimizerSettings: {
    get: () => {
      const lsValue = window.localStorage.getItem('rdp.dev.useCustomSacOptimizerSettings');
      return lsValue === null ? false : JSON.parse(lsValue) as boolean;
    },
    set: (value: boolean) => {
      localStorage.setItem('rdp.dev.useCustomSacOptimizerSettings', JSON.stringify(value));
    }
  },
    //LOGO
    customLogoSettings: {
      get: () => {
        const lsValue = window.localStorage.getItem('rdp.dev.customLogoSettings');
        return lsValue === null ? null : JSON.parse(lsValue) as ILogoSettingsFormState;
      },
      set: (value: ILogoSettingsFormState) => {
        localStorage.setItem('rdp.dev.customLogoSettings', JSON.stringify(value));
      }
    },
    useCustomLogoSettings: {
      get: () => {
        const lsValue = window.localStorage.getItem('rdp.dev.useCustomLogoSettings');
        return lsValue === null ? false : JSON.parse(lsValue) as boolean;
      },
      set: (value: boolean) => {
        localStorage.setItem('rdp.dev.useCustomLogoSettings', JSON.stringify(value));
      }
    },
  // field settings:
  customFieldSettings: {
    get: () => {
      const lsValue = window.localStorage.getItem('rdp.dev.customFieldSettings');
      return lsValue === null ? CUSTOM_FIELD_SETTINGS_DEFAULT_FORM_STATE : JSON.parse(lsValue) as IFieldSettings;
    },
    set: (value: IFieldSettings) => {
      localStorage.setItem('rdp.dev.customFieldSettings', JSON.stringify(value));
    }
  },
  useCustomFieldSettings: {
    get: () => {
      const lsValue = window.localStorage.getItem('rdp.dev.useCustomFieldSettings');
      return lsValue === null ? false : JSON.parse(lsValue) as boolean;
    },
    set: (value: boolean) => {
      localStorage.setItem('rdp.dev.useCustomFieldSettings', JSON.stringify(value));
    }
  },

}

// NOTE: The default underlaying system for the system settings has it's partsPackageId set to 2024060101. 
// partsPackage 2024081901 moved some tower properties into TowerProperties from Tower options. However, 
// these propoerties are marked as disableDealerSettings on the page object. As such, this change wrt defaults can be safely ignored for now.
const DEFAULT_NEW_PROJECT_LAYOUT_SYSTEM_ARGS: IGetDefaultNewProjectLayoutSystemActionsArgs = {
  projectName: "dealer settings",
  growerId: "dealer settings",
  projectType: ProjectType.LayoutAndDesign,
  approximateLocation: [0,0] as Position,
  systemType: SystemTypes.CenterPivot,
  centerPivotPoint: point([0,0]).geometry,
  customerSettings: DEFAULT_CUSTOMER_SETTINGS,
  dealerPricing: undefined,
  productSetId: "e2", // TODO when introducing e3 we'll need to decide how to handle system defaults,
  partsPackageId: "2024060101"
}
const DUMMY_AUTH_STATE: IAuthState = {
  setLoggedInUserId: function (loggedInUserId: string): void {
  },
  setAssumedUserId: function (assumedUserId: string): void {
  }
}

export const getStaticFieldSettings = () => {
  const useCustom = localStorageProvider.useCustomFieldSettings.get();
  return useCustom 
    ? localStorageProvider.customFieldSettings.get() 
    : CUSTOM_FIELD_SETTINGS_DEFAULT_FORM_STATE;
}
const createDefaultsWithASpan = () => {
  const res = getDefaultNewProjectLayoutSystemActions(
    DEFAULT_NEW_PROJECT_LAYOUT_SYSTEM_ARGS, DUMMY_AUTH_STATE
  )
  res.actions.push(
    createAddSpanTowerAction(res.layoutId, res.systemId, SideEnum.Flanged, {}, DUMMY_AUTH_STATE)
  )
  return res;
}
const DEFAULT_NEW_PROJECT_LAYOUT_SYSTEM = createDefaultsWithASpan();


const getSpanDefaultsFromActions = (customSystemSettingsActions: IGetDefaultNewProjectLayoutSystemActionsResult): ISpan => {
  const prj = executeAllActions(customSystemSettingsActions.actions);
  return structuredClone(
    prj.layouts[customSystemSettingsActions.layoutId].systems[customSystemSettingsActions.systemId].FlangedSide.Span[0]
  );
}
const getTowerDefaultsFromActions = (customSystemSettingsActions: IGetDefaultNewProjectLayoutSystemActionsResult): ITower => {
  const prj = executeAllActions(customSystemSettingsActions.actions);
  return structuredClone(
    prj.layouts[customSystemSettingsActions.layoutId].systems[customSystemSettingsActions.systemId].FlangedSide.Tower[0]
  );
}

/**
 * Provides a database context which stores all actions in memory. This is intended to be used for
 * initial development only. In the future we will develop a Local Storage database including
 * synchronization to the cloud.
 */

const DevSettingsDbProvider: FC<PropsWithChildren<{}>> = (props) => {
  const [ customSystemSettingsActions, setCustomSettingsActions ] = useState<IGetDefaultNewProjectLayoutSystemActionsResult>(localStorageProvider.customSystemSettingsActions.get());
  const [ useCustomSystemSettings, setUseCustomSystemSettings ] = useState(localStorageProvider.useCustomSystemSettings.get());

  const [ customCustomerSettings, setCustomCustomerSettings ] = useState<IDealerCustomerSettings>(localStorageProvider.customCustomerSettings.get());
  const [ useCustomCustomerSettings, setUseCustomCustomerSettings ] = useState(localStorageProvider.useCustomCustomerSettings.get());
  const [ customDealerPricing, setCustomDealerPricing ] = useState<IDealerPricing>(localStorageProvider.customDealerPricing.get());
  const [ useCustomDealerPricing, setUseCustomDealerPricing ] = useState(localStorageProvider.useCustomDealerPricing.get());

  const [ mapLayerTypeSetting, setMapLayerTypeSetting ] = useState(localStorageProvider.mapLayerTypeSettings.get());
  const [ mapShowLabelSetting, setMapShowLabelSetting ] = useState(localStorageProvider.mapShowLabelSetting.get());
  const [ mapLabelsSettings, setMapLabelsSettings ] = useState(localStorageProvider.mapLabelsSettings.get());
  const [ mapShowClearancesSetting, setMapShowClearancesSetting ] = useState(localStorageProvider.mapShowClearancesSetting.get());

  const [ growerFilterShowDeletedSetting, setGrowerFilterShowDeletedSetting ] = useState(localStorageProvider.growerFilterShowDeletedSetting.get());
  const [ growerFilterShowSharedWithMeSetting, setGrowerFilterShowSharedWithMeSetting ] = useState(localStorageProvider.growerFilterShowSharedWithMeSetting.get());
  const [ projectFilterShowDeletedSetting, setProjectFilterShowDeletedSetting ] = useState(localStorageProvider.projectFilterShowDeletedSetting.get());
  
  const [ customOptimizationSettingsCenterPivot, setCustomOptimizationSettingsCenterPivot ] = useState<IOptimizeCenterPivotFormState>(localStorageProvider.customOptimizationSettingsCenterPivot.get());
  const [ useCustomOptimizationSettingsCenterPivot, setUseCustomOptimizationSettingsCenterPivot ] = useState(localStorageProvider.useCustomOptimizationSettingsCenterPivot.get());

  const [ customOptimizationSettingsLateral, setCustomOptimizationSettingsLateral ] = useState<IOptimizeLateralFormState>(localStorageProvider.customOptimizationSettingsLateral.get());
  const [ useCustomOptimizationSettingsLateral, setUseCustomOptimizationSettingsLateral ] = useState(localStorageProvider.useCustomOptimizationSettingsLateral.get());
  
  const [ customDisplaySettings, setCustomDisplaySettings ] = useState<IDisplaySettingsFormState>(localStorageProvider.customDisplaySettings.get());
  const [ useCustomDisplaySettings, setUseCustomDisplaySettings ] = useState(localStorageProvider.useCustomDisplaySettings.get());

  const [ customMapSettings, setCustomMapSettings ] = useState<IMapSettingsFormState>(localStorageProvider.customMapSettings.get());
  const [ useCustomMapSettings, setUseCustomMapSettings ] = useState(localStorageProvider.useCustomMapSettings.get());

  const [ customLogoSettings, setCustomLogoSettings ] = useState<ILogoSettingsFormState>(localStorageProvider.customLogoSettings.get());
  const [ useCustomLogoSettings, setUseCustomLogoSettings ] = useState(localStorageProvider.useCustomLogoSettings.get());
  
  const [ customSacOptimizerSettings, setCustomSacOptimizerSettings ] = useState<ISacOptimizerSettingsFormState>(localStorageProvider.customSacOptimizerSettings.get());
  const [ useCustomSacOptimizerSettings, setUseCustomSacOptimizerSettings ] = useState(localStorageProvider.useCustomSacOptimizerSettings.get());
    
  const [ customFieldSettings, setCustomFieldSettings ] = useState<IFieldSettings>(localStorageProvider.customFieldSettings.get());
  const [ useCustomFieldSettings, setUseCustomFieldSettings ] = useState(localStorageProvider.useCustomFieldSettings.get());
  
  // Note that this is very inefficient because it replays all actions on every state change. But
  // it's OK because this database provider is intended to be used only for early development.
  const devStateToDbCtxValue = () => {
    const dbCtxValue: IDevSettingsState = {
      mapSettings: {
        layerType: mapLayerTypeSetting,
        setMapLayerType: (type: MapLayerType) => {
          localStorageProvider.mapLayerTypeSettings.set(type);
          setMapLayerTypeSetting(type);
        },
        showLabels: mapShowLabelSetting,
        setShowLabels: (value: boolean) => {
          localStorageProvider.mapShowLabelSetting.set(value);
          setMapShowLabelSetting(value);
        },
        mapLabels: mapLabelsSettings,
        setMapLabels: (value) => {
          localStorageProvider.mapLabelsSettings.set(value);
          setMapLabelsSettings(value);
        },
        showClearances: mapShowClearancesSetting,
        setShowClearances: (value: boolean) => {
          localStorageProvider.mapShowClearancesSetting.set(value);
          setMapShowClearancesSetting(value);
        }
      },
      growerFilterSettings: {
        showDeleted: growerFilterShowDeletedSetting,
        setShowDeleted: (value: boolean) => {
          localStorageProvider.growerFilterShowDeletedSetting.set(value);
          setGrowerFilterShowDeletedSetting(value);
        },
        showSharedWithMe: growerFilterShowSharedWithMeSetting,
        setShowSharedWithMe: (value: boolean) => {
          localStorageProvider.growerFilterShowSharedWithMeSetting.set(value);
          setGrowerFilterShowSharedWithMeSetting(value);
        }
      },
      projectFilterSettings: {
        showDeleted: projectFilterShowDeletedSetting,
        setShowDeleted: (value: boolean) => {
          localStorageProvider.projectFilterShowDeletedSetting.set(value);
          setProjectFilterShowDeletedSetting(value);
        },
      },
      dealerSettings: {
        system: {
          custom: {
            dbProject: {
              state: executeAllActions(customSystemSettingsActions.actions),
              pushAction: async (action: IAction) => {
                setCustomSettingsActions((prevState) => {
                  const nextState: IGetDefaultNewProjectLayoutSystemActionsResult = {
                    ...prevState,
                    actions: [
                      ...prevState.actions,
                      action
                    ]
                  }
                  localStorageProvider.customSystemSettingsActions.set(nextState);
                  return nextState;
                });
                return executeAllActions(customSystemSettingsActions.actions);
              },
              readonly: false,
            },
            layoutId: customSystemSettingsActions.layoutId,
            systemId: customSystemSettingsActions.systemId,
            getSystemProperties: (layoutId: string, systemId: string, authState: IAuthState) => {
              // Here we accumulate only the latest system property values, ignoring the system specifc values
              // defined in ignoreProperties
              const ignoreProperties = [
                'Circle.CenterPivot.isPartialPivot', 
                'Circle.CenterPivot.clockwiseCompassHeadingStart', 
                'Circle.CenterPivot.clockwiseCompassHeadingEnd',
                'mcp.current',
                'mcp.lengthFt',
                'mcp.wireMaterial',
                'mcp.wireGauge',
                "FlangedSide.Span",
                "FlangedSide.Tower",
                "SystemProperties",
                "partsPackageId",
                "DealerProperties",
                "QuoteProperties",
                "name",
                "lastModifiedTimestamp",
                "lastModifiedUserId",
                "productLine",
                "lastModifiedTimestamp",
                "lateral",
                "centerPivot",
                "sacOptimizerResult",
                "SprinklerEngineResultWarnings",
                "pathData",
                "createQuoteStatus",
                "proposalGenerated",
                "orderSubmissionStatus"
              ];

              const flattenActions = (x: IAction): IAction[] => {
                if (x.actionTypeId === "MultiAction") {
                  return (x.data as MultiActionData).actions.flatMap(flattenActions);
                }
                else {
                  return [ x as IAction ];
                }
              }
              const flattenedActions = customSystemSettingsActions.actions.flatMap(flattenActions);

              const returnActions: IAction[] = [];
              for (const x of flattenedActions) {
                if (x.actionTypeId === 'UpdateSystemProperty') {
                  const property: string = (x.data as any).property;
                  const value: string = (x.data as any).value;
                  if (ignoreProperties.some(ip => property.startsWith(ip))) continue;
                  if (property.startsWith("RC10") && property !== "RC10.IsEnabled" && property !== "RC10.ModemBaseModel") continue;
                  if (property.startsWith("MainlineValveOptions")) continue;
                  if (property.startsWith("Ontrac")) continue;
                  if (property.startsWith("Options")
                    // Pivot light (and constant on kit) is set on the Center Pivot page, not the Options page, and needs to be
                    // allowed as a dealer default.
                    && property !== "Options.PivotLight"
                    && property !== "Options.PivotLightRAMSConstantOn") {
                    continue;
                  }
                  if (property.startsWith("AgriInject")) continue;
                  if (property.startsWith("SprinklerLube")) continue;
                  if (property.startsWith("HeatExchangers")) continue;
                  if (property.startsWith("ClemonsFilters")) continue;
                  if (property.startsWith("CheckValves")) continue;
                  if (property.startsWith("FlowmeterComponents")) continue;

                  returnActions.push(createNewUpdateSystemPropertyAction(
                    layoutId,
                    systemId,
                    property,
                    value,
                    authState
                  ));
                } else if (x.actionTypeId === 'SetEndOfSystem') {
                  const data: any = { ...x.data };
                  delete data.layoutId;
                  delete data.systemId;
                  returnActions.push(createSetEndOfSystemAction(
                    layoutId,
                    systemId,
                    (x.data as any).side,
                    data,
                    authState
                  ));
                }
              }

              return returnActions;
            },
            getSpanProperties: () => getSpanDefaultsFromActions(customSystemSettingsActions),
            getTowerProperties: () => getTowerDefaultsFromActions(customSystemSettingsActions)
          },
          useCustom: useCustomSystemSettings,
          setUseCustom: (useCustom: boolean) => {
            localStorageProvider.useCustomSystemSettings.set(useCustom);
            setUseCustomSystemSettings(useCustom);
          }
        },
        customer: {
          custom: {
            formState: customCustomerSettings,
            setFormState: (updatedFormState: IDealerCustomerSettings) => {
              localStorageProvider.customCustomerSettings.set(updatedFormState);
              setCustomCustomerSettings(updatedFormState);
            }
          },
          useCustom: useCustomCustomerSettings,
          setUseCustom: (useCustom: boolean) => {
            localStorageProvider.useCustomCustomerSettings.set(useCustom);
            setUseCustomCustomerSettings(useCustom);
          }
        },       
        dealerPricing: {
          custom: {
            formState: customDealerPricing,
            setFormState: (updatedFormState: IDealerPricing) => {
              localStorageProvider.customDealerPricing.set(updatedFormState);
              setCustomDealerPricing(updatedFormState);
            }
          },
          useCustom: useCustomDealerPricing,
          setUseCustom: (useCustom: boolean) => {
            localStorageProvider.useCustomDealerPricing.set(useCustom);
            setUseCustomDealerPricing(useCustom);
          }
        },
        optimization: {
          centerPivot: {
            custom: {
              formState: customOptimizationSettingsCenterPivot,
              setFormState: (updatedFormState: IOptimizeCenterPivotFormState) => {
                localStorageProvider.customOptimizationSettingsCenterPivot.set(updatedFormState);
                setCustomOptimizationSettingsCenterPivot(updatedFormState);
              }
            },
            useCustom: useCustomOptimizationSettingsCenterPivot,
            setUseCustom: (useCustom: boolean) => {
              localStorageProvider.useCustomOptimizationSettingsCenterPivot.set(useCustom);
              setUseCustomOptimizationSettingsCenterPivot(useCustom);
            }
          },
          lateral: {
            custom: {
              formState: customOptimizationSettingsLateral,
              setFormState: (updatedFormState: IOptimizeLateralFormState) => {
                localStorageProvider.customOptimizationSettingsLateral.set(updatedFormState);
                setCustomOptimizationSettingsLateral(updatedFormState);
              }
            },
            useCustom: useCustomOptimizationSettingsLateral,
            setUseCustom: (useCustom: boolean) => {
              localStorageProvider.useCustomOptimizationSettingsLateral.set(useCustom);
              setUseCustomOptimizationSettingsLateral(useCustom);
            }
          }
        },
        display: {
          custom: {
            formState: customDisplaySettings,
            setFormState: (updatedFormState: IDisplaySettingsFormState) => {
              localStorageProvider.customDisplaySettings.set(updatedFormState);
              setCustomDisplaySettings(updatedFormState);
            }
          },
          useCustom: useCustomDisplaySettings,
          setUseCustom: (useCustom: boolean) => {
            localStorageProvider.useCustomDisplaySettings.set(useCustom);
            setUseCustomDisplaySettings(useCustom);
          },
          current: useCustomDisplaySettings ? customDisplaySettings : CUSTOM_DISPLAY_SETTINGS_DEFAULT_FORM_STATE
        },
        map: {
          custom: {
            formState: customMapSettings,
            setFormState: (updatedFormState: IMapSettingsFormState) => {
              localStorageProvider.customMapSettings.set(updatedFormState);
              setCustomMapSettings(updatedFormState);
            }
          },
          useCustom: useCustomMapSettings,
          setUseCustom: (useCustom: boolean) => {
            localStorageProvider.useCustomMapSettings.set(useCustom);
            setUseCustomMapSettings(useCustom);
          },
          current: useCustomMapSettings ? customMapSettings : CUSTOM_MAP_SETTINGS_DEFAULT_FORM_STATE
        },
        sacOptimizer: {
          custom: {
            formState: customSacOptimizerSettings,
            setFormState: (updatedFormState: ISacOptimizerSettingsFormState) => {
              localStorageProvider.customSacOptimizerSettings.set(updatedFormState);
              setCustomSacOptimizerSettings(updatedFormState);
            }
          },
          useCustom: useCustomSacOptimizerSettings,
          setUseCustom: (useCustom: boolean) => {
            localStorageProvider.useCustomSacOptimizerSettings.set(useCustom);
            setUseCustomSacOptimizerSettings(useCustom);
          },
          current: useCustomSacOptimizerSettings ? customSacOptimizerSettings : CUSTOM_SAC_OPTIMIZER_SETTINGS_DEFAULT_FORM_STATE
        },
        logo: {
          custom: {
            formState: customLogoSettings,
            setFormState: (updatedFormState: ILogoSettingsFormState) => {
              localStorageProvider.customLogoSettings.set(updatedFormState);
              setCustomLogoSettings(updatedFormState);
            }
          },
          useCustom: useCustomLogoSettings,
          setUseCustom: (useCustom: boolean) => {
            localStorageProvider.useCustomLogoSettings.set(useCustom);
            setUseCustomLogoSettings(useCustom);
          },
          current: useCustomLogoSettings ? customLogoSettings : null
        }
      },
      fieldSettings: {
        custom: {
          formState: customFieldSettings,
          setFormState: (updatedFormState: IFieldSettings) => {
            localStorageProvider.customFieldSettings.set(updatedFormState);
            setCustomFieldSettings(updatedFormState);
          }
        },
        useCustom: useCustomFieldSettings,
        setUseCustom: (useCustom: boolean) => {
          localStorageProvider.useCustomFieldSettings.set(useCustom);
          setUseCustomFieldSettings(useCustom);
        },
        current: useCustomFieldSettings ? customFieldSettings : CUSTOM_FIELD_SETTINGS_DEFAULT_FORM_STATE
      },
    };
    return dbCtxValue;
  };
  
  return (
      <DevSettingsCtx.Provider value={devStateToDbCtxValue()}>
        {props.children}
      </DevSettingsCtx.Provider>
    );
};

export default DevSettingsDbProvider;

export const staticDevSettingsDbProvider = {
  span: {
    getUseCustom: () => localStorageProvider.useCustomSystemSettings.get(),
    get: () => {
      const useCustom = localStorageProvider.useCustomSystemSettings.get();
      if (!useCustom) return {};
      return getSpanDefaultsFromActions(localStorageProvider.customSystemSettingsActions.get());
    }
  },
  tower: {
    getUseCustom: () => localStorageProvider.useCustomSystemSettings.get(),
    get: () => {
      const useCustom = localStorageProvider.useCustomSystemSettings.get();
      if (!useCustom) return {};
      return getTowerDefaultsFromActions(localStorageProvider.customSystemSettingsActions.get());
    }
  },
  display: {
    get: () => {
      const useCustom = localStorageProvider.useCustomDisplaySettings.get();
      return useCustom 
        ? localStorageProvider.customDisplaySettings.get() 
        : CUSTOM_DISPLAY_SETTINGS_DEFAULT_FORM_STATE;
    }
  },
  customer: {
    get: () => {
      const useCustom = localStorageProvider.useCustomCustomerSettings.get();
      return useCustom ? localStorageProvider.customCustomerSettings.get() : 
        DEFAULT_CUSTOMER_SETTINGS
    }
  },
  dealerPricing: {
    get: () => {
      const useCustom = localStorageProvider.useCustomDealerPricing.get();
      return useCustom ? localStorageProvider.customDealerPricing.get() : 
        undefined
    }
  },
  sacOptimizer: {
    get: () => {
      const useCustom = localStorageProvider.useCustomSacOptimizerSettings.get();
      return useCustom 
        ? localStorageProvider.customSacOptimizerSettings.get() 
        : CUSTOM_SAC_OPTIMIZER_SETTINGS_DEFAULT_FORM_STATE;
    }
  },
  mapSettings: {
    showLabels: {
      get: () => {
        return localStorageProvider.mapShowLabelSetting.get();
      }
    },
    mapLabels: {
      get: () => {
        return localStorageProvider.mapLabelsSettings.get();
      }
    },
    showClearances: {
      get: () => {
        return localStorageProvider.mapShowClearancesSetting.get();
      }
    }
  }
}