import {
    Countries,
    GPSBaseOptions,
    GuidanceLocations,
    GuidanceTypes,
    HoseFeedTypes,
    SpanTypes,
    SystemTypes,
    TowerHeights,
    WinchTypes,
    WireTypes
} from 'rdptypes/project/ISystemBase.AutoGenerated';
import schema from "../data/partsPackages/2024060101/poSchema";
import CSection2 from "./CSection2";
import { RollsToOrder } from "./CommonFunctions";
import * as hff from "./HoseFeedFunctions";
import PartsAssembler from "./PartsAssembler";
import { WarningLevels } from "./QuoteClass";
import * as sidef from "./SideFunctions";
import { strLanguageText } from "./TranslationsLib";
import { GuidanceGPSBaseOK, GuidanceLocationOK, GuidanceTypeOK, SolarPoweredOK } from "./Valids.Valids";
export class GuidanceClass extends CSection2 {
    public DataOK = (PA: PartsAssembler, WithWarnings: boolean = false, DependencyCheckInProgress: boolean = false): void => {
        if (!this.FieldSet.DataValid()) return;

        let eHoseFeedType: HoseFeedTypes;
        let hoseFeedType: HoseFeedTypes | undefined;
        let bPivoting: boolean;
        if (this.Quote.HoseFeedClass.FieldSet.DataValid()) {
            if (this.Quote.System.Lateral.HoseFeed.PivotOption || hff.Pivoting(this.Quote.System.Lateral.HoseFeed)) {
                bPivoting = true;
            }
            eHoseFeedType = this.Quote.System.Lateral.HoseFeed.HoseFeedType;
            hoseFeedType = this.Quote.System.Lateral.HoseFeed.HoseFeedType;
        }
        const g = this.Quote.System.Lateral.Guidance;
        if (!(GuidanceTypeOK(g.GuidanceType, {
            SystemType: this.Quote.System.SystemProperties.SystemType,
            HoseFeedType: eHoseFeedType,
            SingleSpanMaxi: this.Quote.IsSingleSpanMaxi(),
            IsTowable: this.Quote.IsTowable(),
            PivotingKit: bPivoting,
            GuidanceLocation: GuidanceLocations.PowerTower, // TODO: Reinke should we be passing real guidance location here?
        }))) {
            this.Quote.Warnings.AddWithTargets(["Lateral.Guidance.GuidanceType"], strLanguageText("sltGuidanceType"));
        }
        if (g.GuidanceType === GuidanceTypes.NavigatorGPS && !(GuidanceGPSBaseOK(g.GPSBase, {
            GuidanceType: g.GuidanceType,
            Country: this.Quote.System.DealerProperties.Country,
            GuidanceLocation: g.Location,
            HoseFeedType: hoseFeedType
        }))) {
            this.Quote.Warnings.Add(strLanguageText("sltGPSBaseOptions"));
        }
        if (!(GuidanceLocationOK(g.Location, {
            SystemType: this.Quote.System.SystemProperties.SystemType,
            HoseFeedType: eHoseFeedType,
            GuidanceType: g.GuidanceType,
            IsSingleSpanMaxi: this.Quote.IsSingleSpanMaxi()
        }))) {
            this.Quote.Warnings.AddWithTargets(["Lateral.Guidance.Location"], strLanguageText("sltGuidanceLocation"));
        }
        if (!(SolarPoweredOK(g.BuriedWireOptions.SolarPowered, {
            GuidanceType: g.GuidanceType
        }))) {
            this.Quote.Warnings.Add(strLanguageText("sltSolarPowered"));
        }
        if (this.Quote.System.Lateral.LengthOfField < 15) {
            this.Quote.Warnings.Add(strLanguageText("sltLengthOfRunMustBe"));
        }
        if (g.CableOptions.RollsOfCable > 0) {
            if (g.GuidanceType !== GuidanceTypes.Cable) {
                this.Quote.Warnings.Add(strLanguageText("sltGuideCableRolls"));
            }
        }
        if (g.CableOptions.ExtraCableStops > 0) {
            if (g.GuidanceType !== GuidanceTypes.Cable) {
                this.Quote.Warnings.Add(strLanguageText("sltExtraCableStop"));
            }
        }
        if (g.BuriedWireOptions.Wire.RollsOfWire > 0) {
            if (g.GuidanceType !== GuidanceTypes.BuriedWire) {
                this.Quote.Warnings.Add(strLanguageText("sltBuriedWireRolls"));
            }
        }
        if (g.GuidanceType === GuidanceTypes.NavigatorGPS) {
            switch (g.GPSBase) {
                case GPSBaseOptions.WithBase:
                case GPSBaseOptions.ExistingReinkeFreewave:
                    this.Quote.Warnings.Add(strLanguageText("sltGPSGuidanceWarning"), WarningLevels.Informational);
                    break;
                case GPSBaseOptions.ExistingNonReinke:
                case GPSBaseOptions.ExistingReinke:
                    this.Quote.Warnings.Add(strLanguageText("sltGPSLineOfSight"), WarningLevels.Informational);
                    break;
            }
        }
        if (g.GuidanceType === GuidanceTypes.BuriedWire && this.Quote.System.Lateral.LengthOfField){
            let cableLength = this.Quote.System.Lateral.LengthOfField + 1000
            let rolls = RollsToOrder(cableLength, 10000);
            
            this.Quote.Warnings.Add(`${strLanguageText("sltFeetOfBuriedCableReq")} ${cableLength}', ${strLanguageText("sltRollsOfBuriedCableRec")} ${rolls}`, WarningLevels.Informational);
        }
        else if (g.GuidanceType === GuidanceTypes.Cable && this.Quote.System.Lateral.LengthOfField){
            let cableLength = this.Quote.System.Lateral.LengthOfField + 20
            let rolls = RollsToOrder(cableLength, 300);
            
            this.Quote.Warnings.Add(`${strLanguageText("sltFeetOfGuideCableReq")} ${cableLength}', ${strLanguageText("sltRollsOfGuideCableRec")} ${rolls}`, WarningLevels.Informational);
        }
    }

    public WritePO = (PO: PartsAssembler) => {
        let HoseFeedType: HoseFeedTypes;
        let bPivotKit: boolean;
        let bPivotingCenterLift: boolean;
        let i: number = 0;
        let iRigidSpan: number = this.Quote.RigidSpan();
        let iWinchQty: number;
        if (this.Quote.HoseFeedClass.FieldSet.DataValid()) {
            HoseFeedType = this.Quote.System.Lateral.HoseFeed.HoseFeedType;
            bPivotKit = this.Quote.System.Lateral.HoseFeed.PivotOption;
            bPivotingCenterLift = this.Quote.System.Lateral.HoseFeed.CenterLift;
        }
        let SpanType: SpanTypes;
        let TowerHeight: TowerHeights;
        // TODO check these .SpanType calls. If they might refer to end booms then need to use SpanFunctions.
        if (this.Quote.RightSpansClass.FieldSet.DataValid() && this.Quote.RightTowersClass.FieldSet.DataValid()) {
            i = sidef.GuidanceTower(this.Quote.System.FlangedSide);
            if (i > 0) {
                if (i < iRigidSpan) {
                    SpanType = this.Quote.System.FlangedSide.Span[i + 1 - 1].SpanType;
                }
                else {
                    SpanType = this.Quote.System.FlangedSide.Span[i - 1].SpanType;
                }
                TowerHeight = this.Quote.System.FlangedSide.Tower[i - 1].TowerType;
                // GoTo GuidanceType;
            }
        }
        if (this.Quote.LeftSpansClass.FieldSet.DataValid() && this.Quote.LeftTowersClass.FieldSet.DataValid()
            && !(this.Quote.RightSpansClass.FieldSet.DataValid() && this.Quote.RightTowersClass.FieldSet.DataValid() && i > 0)) { // Emulates GoTo in old ROE code
            i = sidef.GuidanceTower(this.Quote.System.FlexSide);
            if (i > 0) {
                SpanType = this.Quote.System.FlexSide.Span[i - 1].SpanType;
                TowerHeight = this.Quote.System.FlexSide.Tower[i - 1].TowerType;
            }
        }
        // GuidanceType:
        switch (this.Quote.System.Lateral.Guidance.GuidanceType) {
            case GuidanceTypes.Furrow:
                iWinchQty = 1;
                if (HoseFeedType === HoseFeedTypes.PivotingLateral) {
                    if (this.Quote.System.Lateral.Guidance.FurrowOptions.LongSystem) {
                        PO.InsertPart(schema.groups.MaxigatorGuidance_FurrowGuidance.groupId, "396004", 1); // FURROW WHEEL GUIDANCE-PIV LAT
                    }
                    else {
                        PO.InsertPart(schema.groups.MaxigatorGuidance_FurrowGuidance.groupId, "395993", 1); // PIPE FURROW GUIDE-PIV-LATERAL
                    }
                }
                switch (this.Quote.System.Lateral.Guidance.Location) {
                    case GuidanceLocations.PowerTower:
                        if (bPivotKit || bPivotingCenterLift) {
                            PO.InsertPart(schema.groups.MaxigatorGuidance_FurrowGuidance.groupId, "395808", 1); // FURROW G.-CEN LIFT PVT LINEAR
                            if (HoseFeedType === HoseFeedTypes.Sugargator) {
                                PO.InsertPart(schema.groups.MaxigatorGuidance_FurrowGuidanceAdapter.groupId, "395807", 1); // ADPTR-FUR G-SUGAR-CEN LIFT LIN
                            }
                            iWinchQty = 2;
                        }
                        else if (HoseFeedType === HoseFeedTypes.PivotingLateral) {
                            PO.InsertPart(schema.groups.ControlPanel_MCPMaxiKit.groupId, "350823", 1); // PIV LAT CART GUIDE MCP KIT
                            PO.InsertPart(schema.groups.ControlPanel_MCPMaxiKit.groupId, "350820", 1); // PIV LAT CART FURROW VFD KIT
                        }
                        else {
                            switch (HoseFeedType) {
                                case HoseFeedTypes.A100:
                                    PO.InsertPart(schema.groups.MaxigatorGuidance_FurrowGuidance.groupId, "395956", 1)  // GUIDE OPT-PIPE FURROW-A100MAXI
                                    break;
                                case HoseFeedTypes.CF200:
                                    if (this.Quote.IsSingleSpanMaxi()) {
                                        if (this.Quote.System.Lateral.Guidance.FurrowOptions.LongSystem) {
                                            PO.InsertPart(schema.groups.MaxigatorGuidance_FurrowGuidance.groupId, "352811", 1)  // GUIDANCE-FURROW-HOSE DG-REV2
                                            break;
                                        } else {
                                            PO.InsertPart(schema.groups.MaxigatorGuidance_FurrowGuidance.groupId, "352815", 1)  // GUIDANCE-FUR/PIPE-HOSE DG-REV2
                                            break;
                                        }
                                    } else {
                                        PO.InsertPart(schema.groups.MaxigatorGuidance_FurrowGuidance.groupId, "352811", 1)  // GUIDANCE-FURROW-HOSE DG-REV2
                                        break;
                                    }
                                default:
                                    PO.InsertPart(schema.groups.MaxigatorGuidance_FurrowGuidance.groupId, "352204-G", 1)  // MASTER TOWER GUIDANCE-MAXI-GAL
                                    break;
                            }
                        }
                        break;
                    case GuidanceLocations.OuterTower:
                        if (HoseFeedType === HoseFeedTypes.CF200) {
                            if (SpanType === SpanTypes.AlumIV) {
                                PO.InsertPart(schema.groups.MaxigatorGuidance_FurrowGuidance.groupId, "352826", 1)  // GUIDE-FURROW-MID SYS-A4-REV2
                            } else {
                                switch (TowerHeight) {
                                    case TowerHeights.Standard:
                                        PO.InsertPart(schema.groups.MaxigatorGuidance_FurrowGuidance.groupId, "352820", 1)  // GUIDE-FURROW-MID SYS-STD-REV2
                                        break;
                                    case TowerHeights.Sugargator:
                                        PO.InsertPart(schema.groups.MaxigatorGuidance_FurrowGuidance.groupId, "352820S", 1)  // GUIDE-FURROW-MID SYS-SG-REV2
                                        break;
                                    case TowerHeights.LowProfile:
                                        PO.InsertPart(schema.groups.MaxigatorGuidance_FurrowGuidance.groupId, "352820L", 1)  // GUIDE-FURROW-MID SYS-LP-REV2
                                        break;
                                }
                            }
                        } else {
                            if (SpanType === SpanTypes.AlumIV) {
                                PO.InsertPart(schema.groups.MaxigatorGuidance_FurrowGuidance.groupId, "352232-G", 1); // FUR. GUIDE OPT-TWR BOX STYLE-A
                            } else {
                                switch (TowerHeight) {
                                    case TowerHeights.Standard:
                                        PO.InsertPart(schema.groups.MaxigatorGuidance_FurrowGuidance.groupId, "352231-G", 1); // FURROW GUIDANCE-MAXI-EII-STD
                                        break;
                                    case TowerHeights.Sugargator:
                                        PO.InsertPart(schema.groups.MaxigatorGuidance_FurrowGuidance.groupId, "352235-G", 1); // FURROW GUIDE-MAXI-EII-SUGAR
                                        break;
                                    case TowerHeights.LowProfile:
                                        PO.InsertPart(schema.groups.MaxigatorGuidance_FurrowGuidance.groupId, "352234-G", 1); // FURROW GUIDE-MAXI-EII-LOW PRO
                                        break;
                                }
                            }
                        }
                        break;
                }
                switch (this.Quote.System.Lateral.Guidance.FurrowOptions.Winch) {
                    case WinchTypes.Electric:
                        PO.InsertPart(schema.groups.MaxigatorGuidance_WinchFurrowGuidance.groupId, "352224", iWinchQty); // ELEC WINCH OPT-GUIDE ARMS-MAXI
                        break;
                    case WinchTypes.Manual:
                        PO.InsertPart(schema.groups.MaxigatorGuidance_WinchFurrowGuidance.groupId, "352225", iWinchQty); // MAN WINCH OPT-GUIDE ARMS-MAXI
                        break;
                }
                break;
            case GuidanceTypes.Cable:
                switch (this.Quote.System.SystemProperties.SystemType) {
                    case SystemTypes.CanalFeedMaxigator:
                        switch (this.Quote.System.Lateral.Guidance.Location) {
                            case GuidanceLocations.OuterTower:
                                PO.InsertPart(schema.groups.MaxigatorGuidance_CableGuidance.groupId, "392815", 1); // CABLE GUID-1/4" CBL-CF-MAXI
                                break;
                            case GuidanceLocations.PowerTower:
                                PO.InsertPart(schema.groups.MaxigatorGuidance_CableGuidance.groupId, "392813", 1); // CABLE GUIDANCE-RPM CANAL FEED
                                break;
                        }
                        break;
                    case SystemTypes.HoseFeedMaxigator:
                        switch (this.Quote.System.Lateral.Guidance.Location) {
                            case GuidanceLocations.OuterTower:
                                PO.InsertPart(schema.groups.MaxigatorGuidance_CableGuidance.groupId, "392816", 1); // CABLE GUIDE-1/4" CBL-HOSE-MAXI
                                break;
                            case GuidanceLocations.PowerTower:
                                PO.InsertPart(schema.groups.MaxigatorGuidance_CableGuidance.groupId, "392814", 1); // CABLE GUIDANCE-RPM HOSE DRAG
                                break;
                        }
                        break;
                }
                i = Math.floor((this.Quote.System.Lateral.LengthOfField - 15) / 30);
                if (i > 0) {
                    PO.InsertPart(schema.groups.MaxigatorGuidance_CablePostOptionCableGuidance.groupId, "392817", i); // CABLE POST OPT-1/4" CABLE-30'
                }
                if (this.Quote.System.Lateral.Guidance.CableOptions.RollsOfCable > 0) {
                    PO.InsertPart(schema.groups.MaxigatorGuidance_GuideCable.groupId, "392818", this.Quote.System.Lateral.Guidance.CableOptions.RollsOfCable); // GUIDE CABLE-1/4"CABLE-300 FT
                }
                if (this.Quote.System.Lateral.Guidance.CableOptions.ExtraCableStops > 0) {
                    PO.InsertPart(schema.groups.MaxigatorGuidance_ExtraCableStopsCableGuidance.groupId, "392820-G", this.Quote.System.Lateral.Guidance.CableOptions.ExtraCableStops); // CABLE TRIP OPT-CABLE GUIDE-C F
                }
                break;
            case GuidanceTypes.BuriedWire:
                switch (this.Quote.System.Lateral.Guidance.Location) {
                    case GuidanceLocations.OuterTower:
                        if (HoseFeedType === HoseFeedTypes.PivotingLateral) {
                            PO.InsertPart(schema.groups.ControlPanel_MCPMaxiKit.groupId, "350825", 1); // PIV LAT MID GUIDE MCP KIT
                        }
                        if (SpanType === SpanTypes.AlumIV) {
                            if (!(this.Quote.System.Lateral.Guidance.BuriedWireOptions.SolarPowered)) {
                                PO.InsertPart(schema.groups.MaxigatorGuidance_BuriedWireGuidance.groupId, "351719", 1); // BURIED WIRE GUIDE OPT-MAXI A4
                            }
                            else {
                                PO.InsertPart(schema.groups.MaxigatorGuidance_BuriedWireGuidance.groupId, "351720", 1); // BWG-MAXI A4 W/SOLAR TRANSMITER
                            }
                        }
                        else {
                            if (!(this.Quote.System.Lateral.Guidance.BuriedWireOptions.SolarPowered)) {
                                PO.InsertPart(schema.groups.MaxigatorGuidance_BuriedWireGuidance.groupId, "352207", 1); // BURIED WIRE GUIDE OPTION-MAXI
                            }
                            else {
                                PO.InsertPart(schema.groups.MaxigatorGuidance_BuriedWireGuidance.groupId, "352208", 1); // BWG-LINEAR-W/SOLAR TRANSMITTER
                            }
                        }
                        break;
                    case GuidanceLocations.PowerTower:
                        switch (HoseFeedType) {
                            case HoseFeedTypes.Standard:
                            case HoseFeedTypes.DoubleEndFeed:
                                PO.InsertPart(schema.groups.MaxigatorGuidance_BuriedWireGuidance.groupId, "352215", 1); // BURIED WIRE GUIDE OPT-2WD MAXI
                                break;
                            case HoseFeedTypes.FourWheelDrive:
                            case HoseFeedTypes.Sugargator:
                                PO.InsertPart(schema.groups.MaxigatorGuidance_BuriedWireGuidance.groupId, "352216", 1); // BURIED WIRE GUIDE OPT-4WD MAXI
                                break;
                        }
                        break;
                }
                switch (this.Quote.System.Lateral.Guidance.BuriedWireOptions.Wire.WireType) {
                    case WireTypes.Shielded:
                        if (this.Quote.System.Lateral.Guidance.BuriedWireOptions.Wire.RollsOfWire > 0) {
                            PO.InsertPart(schema.groups.MaxigatorGuidance_BuriedWire.groupId, "352715", this.Quote.System.Lateral.Guidance.BuriedWireOptions.Wire.RollsOfWire); // GDE WIRE-14/1 SHLD-SAC 10K SP
                        }
                        break;
                    case WireTypes.NonShielded:
                        if (this.Quote.System.Lateral.Guidance.BuriedWireOptions.Wire.RollsOfWire > 0) {
                            PO.InsertPart(schema.groups.MaxigatorGuidance_BuriedWire.groupId, "352717", this.Quote.System.Lateral.Guidance.BuriedWireOptions.Wire.RollsOfWire); // GDE WIR-14/1 NON SD-SAC 10K SP
                        }
                        break;
                }
                break;
            case GuidanceTypes.FenceLine:
                PO.InsertPart(schema.groups.MaxigatorGuidance_FenceLineGuidance.groupId, "352233", 1)  // FENCE CABLE GUIDE OPT-MAXI
                break;
            case GuidanceTypes.NavigatorGPS:
                switch (this.Quote.System.Lateral.Guidance.GPSBase) {
                    case GPSBaseOptions.WithBase:
                        if (this.Quote.System.SystemProperties.SystemType === SystemTypes.HoseFeedMaxigator && HoseFeedType === HoseFeedTypes.CF200) {
                            PO.InsertPart(schema.groups.MaxigatorGuidance_NavigatorGPS.groupId, "353999", 1)  // MAXI GPS BASE-FREEWAVE-TRIMBLE
                            switch (this.Quote.System.Lateral.Guidance.Location) {
                                case GuidanceLocations.PowerTower:
                                    PO.InsertPart(schema.groups.MaxigatorGuidance_NavigatorGPS.groupId, "352818", 1)  // GUIDANCE-GPS-CART-FRWV-REV2
                                    break;
                                case GuidanceLocations.OuterTower:
                                    PO.InsertPart(schema.groups.MaxigatorGuidance_NavigatorGPS.groupId, "352824", 1)  // GUIDE-GPS-MID SYS-FRWV-REV2
                                    break;
                            }
                        } else {
                            switch (this.Quote.System.DealerProperties.Country) {
                                case Countries.NewZealand:
                                    if ((HoseFeedType === HoseFeedTypes.PivotingLateral && this.Quote.System.Lateral.Guidance.Location === GuidanceLocations.PowerTower)) {
                                        PO.InsertPart(schema.groups.MaxigatorGuidance_NavigatorGPS.groupId, "395994NZ", 1); // GPS GUIDE-W/O RADIO-PIV MAX-NZ
                                        PO.InsertPart(schema.groups.ControlPanel_MCPMaxiKit.groupId, "350823", 1); // PIV LAT CART GUIDE MCP KIT
                                        PO.InsertPart(schema.groups.ControlPanel_VFDPivotingLateral.groupId, "350819", 1); // PIV LAT CART GPS VFD KIT
                                    }
                                    else {
                                        if ((HoseFeedType === HoseFeedTypes.PivotingLateral && this.Quote.System.Lateral.Guidance.Location === GuidanceLocations.OuterTower)) {
                                            PO.InsertPart(schema.groups.ControlPanel_MCPMaxiKit.groupId, "350825", 1); // PIV LAT MID GUIDE MCP KIT
                                        }
                                        if (SpanType === SpanTypes.AlumIV) {
                                            PO.InsertPart(schema.groups.MaxigatorGuidance_NavigatorGPS.groupId, "354011", 1); // GPS GUIDE MAXI-A4-W/O RADIO-NZ
                                        }
                                        else {
                                            PO.InsertPart(schema.groups.MaxigatorGuidance_NavigatorGPS.groupId, "354010", 1); // GPS GUIDE LATERAL-W/O RADIO-NZ
                                        }
                                    }
                                    PO.InsertPart(schema.groups.MaxigatorGuidance_NavigatorGPS.groupId, "354012", 1); // GPS BASE-FREEWAVE W/O RADIO-NZ
                                    break;
                                default:
                                    if ((HoseFeedType === HoseFeedTypes.PivotingLateral && this.Quote.System.Lateral.Guidance.Location === GuidanceLocations.PowerTower)) {
                                        PO.InsertPart(schema.groups.MaxigatorGuidance_NavigatorGPS.groupId, "395994", 1); // GPS GUIDE OPTION-PIV MAXI
                                        PO.InsertPart(schema.groups.MaxigatorGuidance_NavigatorGPS.groupId, "353999", 1); // MAXI GPS BASE-FREEWAVE-TRIMBLE
                                        PO.InsertPart(schema.groups.ControlPanel_MCPMaxiKit.groupId, "350823", 1); // PIV LAT CART GUIDE MCP KIT
                                        PO.InsertPart(schema.groups.ControlPanel_VFDPivotingLateral.groupId, "350819", 1); // PIV LAT CART GPS VFD KIT
                                    }
                                    else {
                                        if ((HoseFeedType === HoseFeedTypes.PivotingLateral && this.Quote.System.Lateral.Guidance.Location === GuidanceLocations.OuterTower)) {
                                            PO.InsertPart(schema.groups.ControlPanel_MCPMaxiKit.groupId, "350825", 1); // PIV LAT MID GUIDE MCP KIT
                                        }
                                        if (SpanType === SpanTypes.AlumIV) {
                                            PO.InsertPart(schema.groups.MaxigatorGuidance_NavigatorGPS.groupId, "353997", 1); // GPS GUIDE MAXI-FRWV-TRIMBLE-A4
                                        }
                                        else {
                                            PO.InsertPart(schema.groups.MaxigatorGuidance_NavigatorGPS.groupId, "353998", 1); // GPS GUID MAXI-FREEWAVE-TRIMBLE
                                        }
                                        PO.InsertPart(schema.groups.MaxigatorGuidance_NavigatorGPS.groupId, "353999", 1); // MAXI GPS BASE-FREEWAVE-TRIMBLE
                                    }
                                    break;
                            }
                        }
                        break;
                    case GPSBaseOptions.ExistingReinkeFreewave:
                        if (this.Quote.System.SystemProperties.SystemType === SystemTypes.HoseFeedMaxigator && HoseFeedType === HoseFeedTypes.CF200) {
                            switch (this.Quote.System.Lateral.Guidance.Location) {
                                case GuidanceLocations.PowerTower:
                                    PO.InsertPart(schema.groups.MaxigatorGuidance_NavigatorGPS.groupId, "352818", 1)  // GUIDANCE-GPS-CART-FRWV-REV2
                                    break;
                                case GuidanceLocations.OuterTower:
                                    PO.InsertPart(schema.groups.MaxigatorGuidance_NavigatorGPS.groupId, "352824", 1)  // GUIDE-GPS-MID SYS-FRWV-REV2
                                    break;
                            }
                        } else {
                            switch (this.Quote.System.DealerProperties.Country) {
                                case Countries.NewZealand:
                                    if (SpanType === SpanTypes.AlumIV) {
                                        PO.InsertPart(schema.groups.MaxigatorGuidance_NavigatorGPS.groupId, "354011", 1); // GPS GUIDE MAXI-A4-W/O RADIO-NZ
                                    }
                                    else {
                                        if ((HoseFeedType === HoseFeedTypes.PivotingLateral && this.Quote.System.Lateral.Guidance.Location === GuidanceLocations.OuterTower)) {
                                            PO.InsertPart(schema.groups.ControlPanel_MCPMaxiKit.groupId, "350825", 1); // PIV LAT MID GUIDE MCP KIT
                                            // @ts-ignore
                                            if (SpanType === SpanTypes.AlumIV) {
                                                PO.InsertPart(schema.groups.MaxigatorGuidance_NavigatorGPS.groupId, "354011", 1); // GPS GUIDE MAXI-A4-W/O RADIO-NZ
                                            }
                                            else {
                                                PO.InsertPart(schema.groups.MaxigatorGuidance_NavigatorGPS.groupId, "354010", 1); // GPS GUIDE LATERAL-W/O RADIO-NZ
                                            }
                                        }
                                        else {
                                            PO.InsertPart(schema.groups.ControlPanel_VFDPivotingLateral.groupId, "350819", 1); // PIV LAT CART GPS VFD KIT
                                            PO.InsertPart(schema.groups.ControlPanel_MCPMaxiKit.groupId, "350823", 1); // PIV LAT CART GUIDE MCP KIT
                                            PO.InsertPart(schema.groups.MaxigatorGuidance_NavigatorGPS.groupId, "395994NZ", 1); // GPS GUIDE-W/O RADIO-PIV MAX-NZ
                                        }
                                    }
                                    break;
                                default:
                                    if (HoseFeedType === HoseFeedTypes.PivotingLateral) {
                                        if (this.Quote.System.Lateral.Guidance.Location === GuidanceLocations.OuterTower) {
                                            if (SpanType === SpanTypes.AlumIV) {
                                                PO.InsertPart(schema.groups.MaxigatorGuidance_NavigatorGPS.groupId, "353997", 1); // GPS GUIDE MAXI-FRWV-TRIMBLE-A4
                                            }
                                            else {
                                                PO.InsertPart(schema.groups.MaxigatorGuidance_NavigatorGPS.groupId, "353998", 1); // GPS GUID MAXI-FREEWAVE-TRIMBLE
                                            }
                                            PO.InsertPart(schema.groups.ControlPanel_MCPMaxiKit.groupId, "350825", 1); // PIV LAT MID GUIDE MCP KIT
                                        }
                                        else {
                                            PO.InsertPart(schema.groups.MaxigatorGuidance_NavigatorGPS.groupId, "395994", 1); // GPS GUIDE OPTION-PIV MAXI
                                            PO.InsertPart(schema.groups.ControlPanel_VFDPivotingLateral.groupId, "350819", 1); // PIV LAT CART GPS VFD KIT
                                            PO.InsertPart(schema.groups.ControlPanel_MCPMaxiKit.groupId, "350823", 1); // PIV LAT CART GUIDE MCP KIT
                                        }
                                    }
                                    else {
                                        if (SpanType === SpanTypes.AlumIV) {
                                            PO.InsertPart(schema.groups.MaxigatorGuidance_NavigatorGPS.groupId, "353997", 1); // GPS GUIDE MAXI-FRWV-TRIMBLE-A4
                                        }
                                        else {
                                            PO.InsertPart(schema.groups.MaxigatorGuidance_NavigatorGPS.groupId, "353998", 1); // GPS GUID MAXI-FREEWAVE-TRIMBLE
                                        }
                                    }
                                    break;
                            }
                        }
                        break;
                    case GPSBaseOptions.ExistingNonReinke:
                        if (this.Quote.System.SystemProperties.SystemType === SystemTypes.HoseFeedMaxigator && HoseFeedType === HoseFeedTypes.CF200) {
                            switch (this.Quote.System.Lateral.Guidance.Location) {
                                case GuidanceLocations.PowerTower:
                                    PO.InsertPart(schema.groups.MaxigatorGuidance_NavigatorGPS.groupId, "352816", 1)  // GUIDANCE-GPS-CART-SCMBLD-REV2
                                    break;
                                case GuidanceLocations.OuterTower:
                                    PO.InsertPart(schema.groups.MaxigatorGuidance_NavigatorGPS.groupId, "352823", 1)  // GUIDE-GPS-MID SYS-SCMBLD-REV2
                                    break;
                            }
                        } else {
                        if (HoseFeedType === HoseFeedTypes.PivotingLateral) {
                            if (this.Quote.System.Lateral.Guidance.Location === GuidanceLocations.OuterTower) {
                                if (this.Quote.System.DealerProperties.Country === Countries.NewZealand) {
                                    if (SpanType === SpanTypes.AlumIV) {
                                        PO.InsertPart(schema.groups.MaxigatorGuidance_NavigatorGPS.groupId, "351718", 1); // GPS MAXI-TRIMBLE-NO RADIO-A4
                                    }
                                    else {
                                        PO.InsertPart(schema.groups.MaxigatorGuidance_NavigatorGPS.groupId, "357011", 1); // GPS GUID MAXI-TRIMBLE-NO RADIO
                                    }
                                }
                                PO.InsertPart(schema.groups.ControlPanel_MCPMaxiKit.groupId, "350825", 1); // PIV LAT MID GUIDE MCP KIT
                                if (SpanType === SpanTypes.AlumIV) {
                                    PO.InsertPart(schema.groups.MaxigatorGuidance_NavigatorGPS.groupId, "351718", 1); // GPS MAXI-TRIMBLE-NO RADIO-A4
                                }
                                else {
                                    PO.InsertPart(schema.groups.MaxigatorGuidance_NavigatorGPS.groupId, "357011", 1); // GPS GUID MAXI-TRIMBLE-NO RADIO
                                }
                            }
                            else {
                                PO.InsertPart(schema.groups.ControlPanel_MCPMaxiKit.groupId, "395995", 1); // GPS GUIDE-PIVOT MAXI-NO BASE
                                PO.InsertPart(schema.groups.ControlPanel_VFDPivotingLateral.groupId, "350819", 1); // PIV LAT CART GPS VFD KIT
                                PO.InsertPart(schema.groups.ControlPanel_MCPMaxiKit.groupId, "350823", 1); // PIV LAT CART GUIDE MCP KIT
                                if (this.Quote.System.DealerProperties.Country === Countries.NewZealand) {
                                    PO.InsertPart(schema.groups.ControlPanel_VFDPivotingLateral.groupId, "350819", 1); // PIV LAT CART GPS VFD KIT
                                }
                            }
                        }
                        else {
                            if (SpanType === SpanTypes.AlumIV) {
                                PO.InsertPart(schema.groups.MaxigatorGuidance_NavigatorGPS.groupId, "351718", 1); // GPS MAXI-TRIMBLE-NO RADIO-A4
                            }
                            else {
                                PO.InsertPart(schema.groups.MaxigatorGuidance_NavigatorGPS.groupId, "357011", 1); // GPS GUID MAXI-TRIMBLE-NO RADIO
                            }
                        }
                    }
                        break;
                    case GPSBaseOptions.ExistingReinke:
                        if (HoseFeedType === HoseFeedTypes.PivotingLateral) {
                            if (this.Quote.System.Lateral.Guidance.Location === GuidanceLocations.OuterTower) {
                                if (this.Quote.System.DealerProperties.Country === Countries.UnitedStates) {
                                    if (SpanType === SpanTypes.AlumIV) {
                                        PO.InsertPart(schema.groups.MaxigatorGuidance_NavigatorGPS.groupId, "351717", 1); // GPS GUIDANCE OPT-LINEAR A4
                                    }
                                    else {
                                        PO.InsertPart(schema.groups.MaxigatorGuidance_NavigatorGPS.groupId, "357009", 1); // GPS GUIDANCE OPT-LINEAR
                                    }
                                }
                                else {
                                    PO.InsertPart(schema.groups.ControlPanel_MCPMaxiKit.groupId, "395995", 1); // GPS GUIDE-PIVOT MAXI-NO BASE
                                }
                                PO.InsertPart(schema.groups.ControlPanel_MCPMaxiKit.groupId, "350825", 1); // PIV LAT MID GUIDE MCP KIT
                            }
                            else {
                                PO.InsertPart(schema.groups.ControlPanel_MCPMaxiKit.groupId, "395995", 1); // GPS GUIDE-PIVOT MAXI-NO BASE
                                PO.InsertPart(schema.groups.ControlPanel_MCPMaxiKit.groupId, "350823", 1); // PIV LAT CART GUIDE MCP KIT
                            }
                        }
                        else {
                            if (SpanType === SpanTypes.AlumIV) {
                                PO.InsertPart(schema.groups.MaxigatorGuidance_NavigatorGPS.groupId, "351717", 1); // GPS GUIDANCE OPT-LINEAR A4
                            }
                            else {
                                PO.InsertPart(schema.groups.MaxigatorGuidance_NavigatorGPS.groupId, "357009", 1); // GPS GUIDANCE OPT-LINEAR
                            }
                        }
                        break;
                }
                break;
        }
    }
}