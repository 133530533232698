import { Document, Page, Text, View } from '@react-pdf/renderer';
import i18next, { t } from "i18next";
import IDbGrower from "rdptypes/api/IGrowerBase";
import { SystemTypes } from "rdptypes/project/ISystemBase.AutoGenerated";
import * as React from "react";
import { FC } from 'react';
import QuoteClass from "roedata/roe_migration/QuoteClass";
import { configurationValid, customPricingApplied, getGroupedPartsForSystem } from '../../components/roe/componentRenderers/Proposal/ProposalHelpers';
import IProject from "../../model/project/IProject";
import DealerDataCtx from "../../userData/DealerDataCtx";
import { IDocumentType, formatDateLong } from '../DocumentGenerationHelpers';
import CustomerCommentsList from '../components/CustomerCommentsList';
import CustomerDealerTable from '../components/CustomerDealerTable';
import DocumentFirstPageHeader from '../components/DocumentFirstPageHeader';
import Footer from "../components/Footer";
import Header from '../components/Header';
import SpanInformationTable from '../components/SpanInformationTable';
import SystemComponentsTable from '../components/SystemComponentsTable';
import SystemInformationTable from '../components/SystemInformationTable';
import TermsSection from '../components/TermsSection';
import { WarrantyPage1, WarrantyPage2 } from "../components/Warranty";
import WarrantySummary from "../components/WarrantySummary";
import { documentStyles } from '../styles';

interface Props {
    systemId: string;
    layoutId: string;
    project: IProject;
    grower: IDbGrower;
}

const PAGE_SIZE = "LETTER";

const IrrigationSystemPurchaseAgreement: FC<Props> = (props) => {    
    const {systemId, layoutId, project, grower } = props;
    const layout = project.layouts[layoutId!];
    const system = layout.systems[systemId!];
    const groupedSystemParts = getGroupedPartsForSystem(system);

    const { dealership: dealer } = React.useContext(DealerDataCtx);

    let miscItems = (system.QuoteProperties && system.QuoteProperties.MiscItems) ?  system.QuoteProperties.MiscItems : [];
    let comments = system?.QuoteProperties?.ProposalInformation?.customerComments || "";

    let quote = new QuoteClass(system);

    return (
        <>
            <Document>
                <Page size={PAGE_SIZE} style={documentStyles.page}>
                    <DocumentFirstPageHeader documentType={IDocumentType.irrigationSystemPurchaseAgreement}/>
                    {
                        customPricingApplied(system) && <View style={documentStyles.heading}>
                            <Text style={documentStyles.sectionHeader}>CUSTOM PRICING APPLIED</Text>
                        </View>
                    }
                    {                    
                        !configurationValid(system) && <View style={documentStyles.heading}>
                            <Text style={documentStyles.sectionHeader}>INCOMPLETE PROPOSAL</Text>
                        </View>
                    }
                    <View style={{paddingLeft: 30, paddingRight: 30}}>
                        <Text style={{marginTop: 10}}>{`${t("proposals.irrigation-system-purchase-agreement.this-customer-irrigation")} ${grower.name} (${i18next.format(t('customer'), 'capitalize')}) ${t('by')} ${dealer.name} (${i18next.format(t('dealer'), 'capitalize')}) ${t("proposals.irrigation-system.and-the-prices")} ${formatDateLong(new Date())}.`}</Text>
                        <CustomerDealerTable project={project} systemId={systemId} layoutId={layoutId} grower={grower} includeDealerLogo poNumber={quote.System.QuoteProperties.ProposalInformation.PONumber}/>
                    </View>
                    <View style={{paddingLeft: 15, paddingRight: 15}}>
                        {system.SystemProperties.SystemType !== SystemTypes.Ancillary &&
                        <SystemInformationTable quote={quote} systemId={systemId} layoutId={layoutId} project={project}/>}
                        {system.SystemProperties.SystemType !== SystemTypes.Ancillary &&
                        system.SystemProperties.SystemType !== SystemTypes.SwingArmRetro &&
                        <SpanInformationTable quote={quote} system={system}/>}
                    </View>
                    <View fixed style={documentStyles.firstPageFooter}/>
                </Page>
                {
                    (comments !== "") && 
                    <Page size={PAGE_SIZE} style={[documentStyles.page, documentStyles.nonTitlePage]}>
                        <Header docType={IDocumentType.irrigationSystemPurchaseAgreement} dealerName={dealer.name}/>
                        {comments !== "" && <View style={{paddingLeft: 15, paddingRight: 15}}><CustomerCommentsList quote={quote} /></View>}
                        <Footer/>
                    </Page>
                }
                <Page size={PAGE_SIZE} style={[documentStyles.page, documentStyles.nonTitlePage]}>
                    <Header docType={IDocumentType.irrigationSystemPurchaseAgreement} dealerName={dealer.name}/>
                    <View style={{paddingLeft: 15, paddingRight: 15}}>
                        <SystemComponentsTable docType={IDocumentType.irrigationSystemPurchaseAgreement} quote={quote} system={system} miscItems={miscItems} groupedSystemParts={groupedSystemParts} />
                    </View>
                    <Footer/>
                </Page>
                <Page size={PAGE_SIZE} style={[documentStyles.page, documentStyles.nonTitlePage]}>
                    <Header docType={IDocumentType.irrigationSystemPurchaseAgreement} dealerName={dealer.name}/>
                    <View style={{paddingLeft: 15, paddingRight: 15}}>
                        <TermsSection quote={quote} system={system} />
                    </View>
                    <Footer/>
                </Page>
                <Page size={PAGE_SIZE} style={[documentStyles.page]}>
                    <WarrantySummary/>
                    <View fixed style={documentStyles.firstPageFooter}/>
                </Page>
                <Page size={PAGE_SIZE} style={[documentStyles.warrantyPage]}>
                    <View fixed style={{paddingTop: 15}}>
                        <Text style={{textAlign: "center", fontWeight: "bold", fontSize: "11px"}}>REINKE MANUFACTURING CO., INC. LIMITED WARRANTY (U.S. & CANADA)</Text>
                    </View>
                    <WarrantyPage1/>
                    <View fixed style={documentStyles.warrantyFooter}>
                        <Text>Effective Date: 11/1/2022</Text>
                        <Text>Reinke Manufacturing Co., Inc., Deshler, NE 68340 U.S.A.</Text>
                        <Text></Text>
                    </View>
                </Page>
                <Page size={PAGE_SIZE} style={[documentStyles.warrantyPage]}>
                    <View fixed style={{paddingTop: 15}}>
                        <Text style={{textAlign: "center", fontWeight: "bold", fontSize: "11px"}}>REINKE MANUFACTURING CO., INC. LIMITED WARRANTY (U.S. & CANADA)</Text>
                    </View>
                    <WarrantyPage2/>
                    <View fixed style={documentStyles.warrantyFooter}>
                        <Text>Effective Date: 11/1/2022</Text>
                        <Text>Reinke Manufacturing Co., Inc., Deshler, NE 68340 U.S.A.</Text>
                        <Text></Text>
                    </View>
                </Page>
            </Document>
        </>
    );
};

export default IrrigationSystemPurchaseAgreement;