import IAction from "rdptypes/IAction";
import XmlImporter from "roe/XmlImporter";
import { createNewImportSystemAction } from "../../../../actions/ImportSystemAction";
import { createNewLayoutAction } from "../../../../actions/NewLayoutAction";
import { createNewUpdateProjectPropertyAction } from "../../../../actions/UpdateProjectProperty";
import IAuthState from '../../../../auth/IAuthState';
import { ProjectState, ProjectType } from "../../../../model/project/IProject";

export const importFieldXml = async (file: File, gid: string, authState: IAuthState): Promise<IAction[]> => {
    var sys = new XmlImporter().decodeXml(await file.text());

    const actions: IAction[] = [];
    actions.push(
        createNewUpdateProjectPropertyAction(
            "name",
            "Imported - " + file.name,
            authState
        )
    );
    actions.push(
        createNewUpdateProjectPropertyAction(
            "growerId",
            gid,
            authState
        )
    );
    actions.push(
        createNewUpdateProjectPropertyAction(
            "projectType",
            ProjectType.DesignOnly,
            authState
        )
    );
    actions.push(
        createNewUpdateProjectPropertyAction(
            "projectState",
            ProjectState.Active,
            authState
        )
    )
    
    const { layoutId, action } = createNewLayoutAction(authState);
    actions.push(action);
    
    const { systemId, action: importSystemAction } = createNewImportSystemAction(layoutId, sys, authState);
    actions.push(importSystemAction);

    return actions;
  }