// This file was based on the class Reinke Systems/Data/ExtensionMethods.MapicsFeed.vb

import * as dayjs from "dayjs";
import IUserData from "rdptypes/IUserData";
import IDbGrower from "rdptypes/api/IGrowerBase";
import { BuckBoostTypes, ElectricalFrequencies, EndOfSystemTypes, ISystemBase, SwingArmLengths, SystemTypes } from "rdptypes/project/ISystemBase.AutoGenerated";
import { ISpan } from "rdptypes/project/Types";
import { swingArmLengthsFeet } from "rdptypes/reinkeComponents";
import { getCenterDriveType, getDiscountConfigFromUserData, getOverallTireSize, getPivotType, getSystemAreaAcres, getSystemHoursToApplyOneInch, getSystemModel, proposalSectionIsAncillary } from "../ProposalHelpers";
import { getGroupedPartsForSystem } from "../QuoteHelpers";
import { getESPRelatedLoss, getSystemElectricalFrequencyHertz, getSystemElevationFeet, getSystemLengthFeet, getSystemTopOfInletPressurePsi, isCenterPivot, systemHasPipeType } from "../SystemHelpers";
import { partsPackages } from "../data";
import { IValuesFromFieldDesign } from "../roe_migration/Acres";
import { BoosterPumpText, DistanceStringWithUnits, EndGunText, PSIToBar, SimpleEndGunText, SimpleValveText, SpacingText2, SpanLengthsToFeet, SpanTypeText, TireSize } from "../roe_migration/CommonFunctions";
import ElectricalCalculator from "../roe_migration/ElectricalCalculator";
import { GroupLookup } from "../roe_migration/GroupLookup";
import PriceCalculator from "../roe_migration/PriceCalculator";
import QuoteClass from "../roe_migration/QuoteClass";
import { GuidanceTower, NumberOfSpans, NumberOfTowers, Spans } from "../roe_migration/SideFunctions";
import { CableConductors, ConductorsRequired, EndingLocation, SpanPipeTypes, idxCableConductors } from "../roe_migration/SpanFunctions";
import { HasSwingArmCorner, HasVRI, IsCenterFeed, IsDualSided } from "../roe_migration/SystemFunctions";
import { Side } from "../roe_migration/Types";
import DiscountConfig from "./DiscountConfig";
import MapicsFeed from "./MapicsFeed";
import MapicsPart from "./MapicsPart";
import { slt15KVA, slt22_5KVA, sltBuckandBoostTransformer1, sltGuidance, sltLeftSpanInfo, sltPowerTowerEnd, sltRigid, sltTower } from "./MapicsPhrases";
import { OrderProcess } from "./OrderProcess";
import ReportData from "./ReportData";

export const buildMapics = (
    system: ISystemBase,
    grower: IDbGrower,
    user: IUserData,
    valuesFromFieldDesign: IValuesFromFieldDesign): MapicsFeed => {

    const { roeData } = partsPackages[system.partsPackageId];
    const { partsCatalog, poSchema } = roeData;

    let quote = new QuoteClass(system);
    let swingArmLength = 0;
    if (system.FlangedSide.EndOfSystem.EndOfSystemType === EndOfSystemTypes.SAC) {
        let ln = system.FlangedSide.EndOfSystem.SwingArmLength;
        if (swingArmLengthsFeet.findIndex(x => x.value === ln) !== -1) {
            swingArmLength = swingArmLengthsFeet[swingArmLengthsFeet.findIndex(x => x.value === ln)].feet;
        }
    }

    let listPriceCentsMinusAncillary = 0;
    let anyPriceIsNaN = false;

    let groupedParts = getGroupedPartsForSystem(system);
    for (let sectionId in groupedParts.partSections) {
        groupedParts.partSections[sectionId].forEach((p) => {
            if (!proposalSectionIsAncillary(parseInt(sectionId))) {
                let cost = (user.priceList && p.partNumber in user.priceList) ? p.qty * user.priceList[p.partNumber].unitPriceUsdCents : NaN;
                if (!isNaN(cost)) {
                    listPriceCentsMinusAncillary += cost;
                }
                else {
                    anyPriceIsNaN = true;
                }
            }
        });
    }

    const discountConfig: DiscountConfig = getDiscountConfigFromUserData(user, system);

    let pc = new PriceCalculator(quote, listPriceCentsMinusAncillary, discountConfig, groupedParts.partSections, user.priceList);
    let dealerPriceDollars = pc.getSystemDealerPriceCents() / 100;


    const priceList = user.priceList;
    const systemModel: string = getSystemModel(quote);
    const pivotType: string = getPivotType(quote);
    const systemLengthFeet: number = getSystemLengthFeet(system);
    const tiresType: string = getOverallTireSize(quote);
    const electricalFrequencyStr: string = `${getSystemElectricalFrequencyHertz(system) === ElectricalFrequencies.a50 ? "50" : "60"} Hz`;
    const centerDriveType: string = getCenterDriveType(quote);
    const topOfInletPressurePsi: number = getSystemTopOfInletPressurePsi(quote);
    const systemElevationFeet: number = getSystemElevationFeet(system);
    const espPsi: number = getESPRelatedLoss(quote);
    const systemAcres: number = getSystemAreaAcres(quote, valuesFromFieldDesign);
    let totalAcres: number;
    const hoursToApplyAnInch: number = getSystemHoursToApplyOneInch(quote, valuesFromFieldDesign);
    const reportData: ReportData = {
        DealerName: user.dealership.name,
        SystemDealerPrice: Math.round(dealerPriceDollars * 100) / 100,//ensure 2 dp - otherwise price and cost are the same
        SystemDealerCost: dealerPriceDollars,
        PartsDealerCost: (pc.getTotalDealerPriceCents() - pc.getSystemDealerPriceCents()) / 100,
        SystemType: system.SystemProperties.SystemType,
        SwingArmLength: swingArmLength,
        HoseFeedType: !isCenterPivot(system) ? system.Lateral.HoseFeed.HoseFeedType : null,
        WaterFeedType: (system.SystemProperties.SystemType === SystemTypes.CanalFeedMaxigator || system.SystemProperties.SystemType === SystemTypes.HoseFeedMaxigator) ? system.Lateral.WaterFeed : null,
        TowType: isCenterPivot(system) ? system.Circle.CenterPivot.TowOptions.TowType : null,
        HasAluminumPipe: systemHasPipeType(quote, [SpanPipeTypes.Aluminum]),
        HasPolyPipe: systemHasPipeType(quote, [SpanPipeTypes.Poly]),
        HasSteelPipe: systemHasPipeType(quote, [SpanPipeTypes.Galvanized, SpanPipeTypes.Painted]),
        HasTowable: quote.IsTowable(),
        HasSugargatorTower: quote.RightTowersClass.AnySugargators() || quote.LeftTowersClass.AnySugargators(),
        HasSupergatorTower: quote.RightTowersClass.AnySupergators() || quote.LeftTowersClass.AnySupergators(),
        HasVRI: HasVRI(system),
        TowerCount: system.FlangedSide.Tower.length + system.FlexSide.Tower.length
    };
    let sacAcres: number;
    let endgunAcres: number;
    const getDiscount: (sectionId: number) => number = pc.getDiscountSectionValue;






    /*if (isCenterPivot(system)){
        helper = new CenterPivotGeometryHelper({
            project: props.dbPrj.state,
            layoutId: props.layoutId!,
            systemId: props.systemId!
        });
        totalAcres = helper.irrigatedAreaAcres;
        sacAcres = helper.getSacAreaAcres;

        helper.getEndgunAcres.forEach((eg) => {
            let area = 0;
            eg.areas.forEach((a) => {
                area += a;
            });

            endgunAcres += area;
        });
    }
    else {
        helper = new LateralGeometryHelper({
            project: props.dbPrj.state,
            layoutId: props.layoutId!,
            systemId: props.systemId!
        });
        totalAcres = helper.getIrrigatedAreaAcres();
        let endGunAreas = helper.getEndGunIrrigatedAreaAcres();

        let rigidPrimaryAcres = endGunAreas.rigidAcres.primary.reduce((partialSum, a) => partialSum + a, 0);
        let rigidSecondaryAcres = endGunAreas.rigidAcres.secondary.reduce((partialSum, a) => partialSum + a, 0);
    
        let flexPrimaryAcres = endGunAreas.flexAcres.primary.reduce((partialSum, a) => partialSum + a, 0);
        let flexSecondaryAcres = endGunAreas.flexAcres.secondary.reduce((partialSum, a) => partialSum + a, 0);

        endgunAcres = rigidPrimaryAcres + rigidSecondaryAcres + flexPrimaryAcres + flexSecondaryAcres;
    } else */{
        totalAcres = 0;
        sacAcres = 0;
        endgunAcres = 0;
    }


    const mFeed: MapicsFeed = {} as any;
    mFeed.Parts = [];
    mFeed.Spans = [];

    mFeed.QuoteId = 0;//TODO
    mFeed.PartsAssemblerValid = true;
    mFeed.DealerNumber = `${user.dealership.number}`;
    mFeed.QuoteIsInternational = user.dealership.international;

    switch (system.SystemProperties.SystemType) {
        case SystemTypes.CenterPivot:
        case SystemTypes.KwikTow:
            mFeed.ShipDateSystemType = HasSwingArmCorner(system) ? "PIVOT+SAC" : "PIVOT";
            break;
        case SystemTypes.HoseFeedMaxigator:
        case SystemTypes.CanalFeedMaxigator:
            mFeed.ShipDateSystemType = "LATERAL"
            break;
        case SystemTypes.SwingArmRetro:
            mFeed.ShipDateSystemType = "SAC"
            break;
        default:
            // NOTE: for System Orders ONLY
            mFeed.ShipDateSystemType = "";
            break;
    }

    mFeed.ReportData = reportData;

    const pi = quote.System.QuoteProperties?.ProposalInformation;

    mFeed.DealerEmail = user.assumedUser.email;

    mFeed.Notes = pi.factoryNotes ?? "",
        mFeed.ShipDateRequested =
        system.SystemProperties.SystemType === SystemTypes.Ancillary ? dayjs().format("YYYY-MM-DD") // Ancillary send the current day but this is apparently ignored by the server anyway
            : pi?.requestedShippingDate;
    mFeed.Replaces = quote.System.Options.ReplaceSystem ? `${quote.System.Options.ReplaceSystem.toString()}${quote.System.Options.ReplaceComments ? " " + quote.System.Options.ReplaceComments : ""}` : "";
    mFeed.PONumber = pi?.PONumber;
    mFeed.ShipVia = pi?.shipVia.toString();

    mFeed.SalesPerson = user.assumedUser.name;

    // Customer Information
    mFeed.CustomerContactInformation = {
        Name: grower.name,
        ManagerName: grower.farmManagerName,
        IsMailingSameAsShippingAddress: !grower.mailingAddress,
        ShippingAddress: {
            AddressLine1: grower.shippingAddress.line1,
            AddressLine2: grower.shippingAddress.line2,
            City: grower.shippingAddress.city,
            State: grower.shippingAddress.state,
            country: grower.shippingAddress.country,
            Zip: grower.shippingAddress.zip,
        },
        MailingAddress: grower.mailingAddress ? {
            AddressLine1: grower.mailingAddress.line1,
            AddressLine2: grower.mailingAddress.line2,
            City: grower.mailingAddress.city,
            State: grower.mailingAddress.state,
            country: grower.mailingAddress.country,
            Zip: grower.mailingAddress.zip,
        } : undefined,
        Email: grower.email,
        Phone: grower.phone,
    };

    // System Information
    mFeed.Model = systemModel;
    mFeed.TowerCount = quote.System.FlangedSide.Tower.length + quote.System.FlexSide.Tower.length;
    mFeed.Pivot = pivotType;

    let towersType = "N/A";
    if (quote.RightTowersClass.DataValid()) {
        towersType = quote.RightTowersClass.TowersType();
    }

    mFeed.Towers = towersType;
    mFeed.SystemLength = systemLengthFeet;
    mFeed.TireSize = tiresType;
    mFeed.ElectricalFrequency = electricalFrequencyStr;
    mFeed.DriveSpeed = centerDriveType;

    // Sprinkler Package Information
    mFeed.SystemGPM = quote.System.SprinklerProperties.TotalGPM;
    mFeed.MaxSystemGPM = quote.System.SprinklerProperties.MaximumSystemGPM;
    mFeed.MinSystemGPM = quote.System.SprinklerProperties.MinimumSystemGPM;

    mFeed.EndPressure = quote.System.FlangedSide.SprinklerChart.IrrigationProperties.ComputedEndPressure;
    mFeed.TopOfInletPressure = topOfInletPressurePsi;
    mFeed.Elevation = systemElevationFeet;

    mFeed.EndGun1 = SimpleEndGunText(quote.System.FlangedSide.EndOfSystem.EndGun.EndGunTypePrimary);
    //mFeed.EndGun1Length = reportData.EndGunLength //ICON: this doesn't seem to ever be set in ROE
    mFeed.ESPPSI = espPsi;
    mFeed.BoosterPump1 = BoosterPumpText(quote.System.FlangedSide.EndOfSystem.EndGun.BoosterPump);
    mFeed.RightGPM = quote.System.FlangedSide.SprinklerChart.IrrigationProperties.SideGPM;

    if (IsDualSided(quote.System)) {
        mFeed.EndGun2 = EndGunText(quote.System.FlexSide.EndOfSystem.EndGun.EndGunTypePrimary,
            quote.System.FlexSide.EndOfSystem.EndGun.EndGunTypeSecondary,
            quote.System.FlexSide.EndOfSystem.EndGun.Valve);

        mFeed.LeftGPM = quote.System.FlexSide.SprinklerChart.IrrigationProperties.SideGPM;
        mFeed.BoosterPump2 = BoosterPumpText(quote.System.FlexSide.EndOfSystem.EndGun.BoosterPump);
    } else {
        if (quote.System.FlangedSide.EndOfSystem.EndGun) {
            mFeed.EndGun2 = SimpleEndGunText(quote.System.FlangedSide.EndOfSystem.EndGun.EndGunTypeSecondary) + "/" + SimpleValveText(quote.System.FlangedSide.EndOfSystem.EndGun.EndGunTypeSecondary, quote.System.FlangedSide.EndOfSystem.EndGun.SecondaryValve);
            //mFeed.EndGun2Length = reportData.EndGun2Length //ICON: this doesn't seem to ever be set in ROE
        }
    }

    mFeed.DiscountConfig = discountConfig;

    // Fifth Row
    mFeed.HoursToApplyAnInch = hoursToApplyAnInch;
    mFeed.SystemAcres = systemAcres;
    mFeed.TotalAcres = totalAcres;
    mFeed.SACAcres = sacAcres;
    mFeed.EndGunAcres = endgunAcres;

    // Parts
    for (let sectionId in groupedParts.partSections){
        groupedParts.partSections[sectionId].forEach((p) => {
            let gid = poSchema.groups[p.groupId].seq;
            let sid = poSchema.sections[poSchema.groups[p.groupId].sectionId].seq;
            let idx = mFeed.Parts.findIndex(x => x.PartNumber === p.partNumber && x.Group === gid);

            if (idx === -1) {
                const part: MapicsPart = {
                    Quantity: p.qty,
                    PartNumber: p.partNumber,
                    Description: partsCatalog[p.partNumber].mapicsDescription,
                    Discount: getDiscount(sid),
                    PartFlag: poSchema.groups[p.groupId].roeIsPartsPricing,
                    Price: (priceList && p.partNumber in priceList) ? priceList[p.partNumber].unitPriceUsdCents / 100 : NaN,
                    Section: sid,
                    Group: gid
                }
                mFeed.Parts.push(part);
            }
            else {
                const part = mFeed.Parts[idx];
                part.Quantity += p.qty;
            }
        });
    }

    // "Span Information"
    const iRigidSpan = quote.RigidSpan()
    const bCenterFeed = IsCenterFeed(quote.System);
    const bHasSAC = HasSwingArmCorner(quote.System);

    if (NumberOfSpans(quote.System.FlangedSide) > 0) {
        const agr = new ElectricalCalculator(quote.System, undefined).AutoGauge();

        for (let i = 1; i <= NumberOfSpans(quote.System.FlangedSide); i++) {
            const span = quote.System.FlangedSide.Span[i - 1];

            let ecc = isNaN(span.ExtraCableConductors) ? 0 : span.ExtraCableConductors;
            const idxCC = CableConductors(quote.System, quote.System.FlangedSide, span);
            let cc: number;
            //replaces the weird idxCableConductors.ToInteger method in ExtensionMethods.Enums.vb (we don't actually want the index here!)
            switch (idxCC) {
                case idxCableConductors.cc10:
                    cc = 10;
                    break;
                case idxCableConductors.cc11:
                    cc = 11;
                    break;
                case idxCableConductors.cc14:
                    cc = 14;
                    break;
            }

            mFeed.Spans.push({
                Slot: i.toString(),
                Type: iRigidSpan === i ? sltRigid : GuidanceTower(quote.System.FlangedSide) === i ? sltGuidance : SpanTypeText(span.SpanType),
                Length: DistanceStringWithUnits(SpanLengthsToFeet(span.Length)),
                WheelTrack: DistanceStringWithUnits(EndingLocation(quote.System, quote.System.FlangedSide, span)),
                Ext: span.Extension,
                Spacing: SpacingText2(span.Spacing),
                TireSize:
                    /* ROE-522 - Identify appropriate TireSize for (Lateral - HoseFeed - DoubleEndFeed) system.
                    The (number of towers) = (number of spans - 1 ) and a cart for the DoubleEndFeed
                    is ultimately a tower on the last span. Find the tire size from the cart. */
                    i === Spans(quote.System, quote.System.FlangedSide).SpanCount() && quote.IsDEF() ? TireSize(quote.System.Lateral.Tires.TireSize) :
                        i <= quote.System.FlangedSide.Tower.length ? TireSize(quote.System.FlangedSide.Tower[i - 1].Tires.TireSize) : "",
                PsiLoss: PSIToBar(quote.SprinklerChartClass.RightSprinklersClass.SpanPressureLoss(i)).toString(),
                SpanCable: `${cc}C/${agr.flangedSide[i - 1].cableGauge}/${cc - ConductorsRequired(quote.System, quote.System.FlangedSide, span) + ecc}s`,
                PipeSize: null
            });
        }

        if (bHasSAC) {
            const lastSpan = NumberOfSpans(quote.System.FlangedSide);

            const eos = quote.System.FlangedSide.EndOfSystem;
            let cLength: string;
            let cSpan: string;
            switch (eos.SwingArmLength) {
                case SwingArmLengths.SAC156:
                    cSpan = "SAC-156'";
                    cLength = DistanceStringWithUnits(SpanLengthsToFeet(156));
                    break;
                case SwingArmLengths.SAC175:
                    cSpan = "SAC-175'";
                    cLength = DistanceStringWithUnits(SpanLengthsToFeet(175));
                    break;
                case SwingArmLengths.SAC194:
                    cSpan = "SAC-194'";
                    cLength = DistanceStringWithUnits(SpanLengthsToFeet(194));
                    break;
                case SwingArmLengths.SAC213:
                    cSpan = "SAC-213'";
                    cLength = DistanceStringWithUnits(SpanLengthsToFeet(213));
                    break;
            }
            const span = quote.System.FlangedSide.Span[quote.SwingArmSpan()];
            mFeed.Spans.push({
                Slot: cSpan,
                Type: quote.SwingArmClass.GetPipeModel(mFeed.Spans[mFeed.Spans.length - 1].Type),
                Length: cLength,
                WheelTrack: DistanceStringWithUnits(EndingLocation(quote.System, quote.System.FlangedSide, span)),
                Ext: false,
                Spacing: SpacingText2(span.Spacing),
                TireSize: TireSize(quote.System.Circle.SwingArm.Tires.TireSize),
                PsiLoss: PSIToBar(quote.SprinklerChartClass.RightSprinklersClass.SpanPressureLoss(lastSpan + 1)).toString(),
                SpanCable: "",
                PipeSize: null
            });
        }

        const endBoom = endBoomSpan(quote.System.FlangedSide);
        if (endBoom) {
            const lastSpan = NumberOfSpans(quote.System.FlangedSide);
            mFeed.Spans.push({
                Slot: "EB",
                Type: "N/A",
                Length: DistanceStringWithUnits(SpanLengthsToFeet(endBoom.Length)),
                WheelTrack: DistanceStringWithUnits(EndingLocation(quote.System, quote.System.FlangedSide, endBoom)),
                Ext: false,
                Spacing: SpacingText2(endBoom.Spacing),
                TireSize: "",
                PsiLoss: PSIToBar(quote.SprinklerChartClass.RightSprinklersClass.SpanPressureLoss(lastSpan + 1)).toString(),
                SpanCable: "",
                PipeSize: null
            });
        }

        for (let i = 0; i < NumberOfTowers(quote.System.FlangedSide); i++) {
            const buckBoost = quote.System.FlangedSide.Tower[i].BuckBoost ?? BuckBoostTypes.aNone;
            if (buckBoost === BuckBoostTypes.a15KVA || buckBoost === BuckBoostTypes.a225KVA) {
                mFeed.Spans.push({
                    Slot: buckBoost === BuckBoostTypes.a15KVA ? slt15KVA : slt22_5KVA,
                    Type: sltBuckandBoostTransformer1,
                    Length: sltTower,
                    PipeSize: null,
                    Ext: false,
                    Spacing: null,
                    WheelTrack: null,
                    TireSize: null,
                    PsiLoss: null,
                    SpanCable: null
                });
                break;
            }
        }
    }

    if (IsDualSided(quote.System)) {
        const lastSpan = mFeed.Spans[mFeed.Spans.length - 1];

        mFeed.Spans.push({
            Slot: null,
            Type: bCenterFeed ? sltLeftSpanInfo : sltPowerTowerEnd,
            Length: null,
            WheelTrack: null,
            Ext: null,
            Spacing: null,
            TireSize: null,
            PsiLoss: null,
            SpanCable: null,
            PipeSize: null
        });

        mFeed.Spans.push(lastSpan);

        for (let i = 1; i <= NumberOfSpans(quote.System.FlexSide); i++) {
            const span = quote.System.FlexSide.Span[i - 1];
            mFeed.Spans.push({
                Slot: i.toString(),
                Type: SpanTypeText(span.SpanType),
                Length: DistanceStringWithUnits(SpanLengthsToFeet(span.Length)),
                WheelTrack: DistanceStringWithUnits(EndingLocation(quote.System, quote.System.FlexSide, span)),
                Ext: span.Extension,
                Spacing: SpacingText2(span.Spacing),
                TireSize: i <= quote.System.FlexSide.Tower.length ? TireSize(quote.System.FlexSide.Tower[i - 1].Tires.TireSize) : "",
                PsiLoss: PSIToBar(quote.SprinklerChartClass.LeftSprinklersClass.SpanPressureLoss(i)).toString(),
                SpanCable: GuidanceTower(quote.System.FlexSide) === i ? sltGuidance : "",
                PipeSize: null
            });
        }

        const endBoom = endBoomSpan(quote.System.FlexSide);
        if (endBoom) {
            const lastSpan = NumberOfSpans(quote.System.FlexSide);
            mFeed.Spans.push({
                Slot: "EB",
                Type: SpanTypeText(endBoom.SpanType),
                Length: DistanceStringWithUnits(SpanLengthsToFeet(endBoom.Length)),
                WheelTrack: DistanceStringWithUnits(EndingLocation(quote.System, quote.System.FlexSide, endBoom)),
                Ext: false,
                Spacing: SpacingText2(endBoom.Spacing),
                TireSize: "",
                PsiLoss: PSIToBar(quote.SprinklerChartClass.LeftSprinklersClass.SpanPressureLoss(lastSpan + 1)).toString(),
                SpanCable: "",
                PipeSize: null
            });
        }

        for (let i = 0; i < NumberOfTowers(quote.System.FlexSide); i++) {
            const buckBoost = quote.System.FlexSide.Tower[i].BuckBoost ?? BuckBoostTypes.aNone;
            if (buckBoost === BuckBoostTypes.a15KVA || buckBoost === BuckBoostTypes.a225KVA) {
                mFeed.Spans.push({
                    Slot: buckBoost === BuckBoostTypes.a15KVA ? slt15KVA : slt22_5KVA,
                    Type: sltBuckandBoostTransformer1,
                    Length: sltTower,
                    PipeSize: null,
                    Ext: false,
                    Spacing: null,
                    WheelTrack: null,
                    TireSize: null,
                    PsiLoss: null,
                    SpanCable: null
                });
                break;
            }
        }
    }

    const descQtys = new Map<string, number>();
    for (const p of mFeed.Parts) {
        if (poSchema.groups[GroupLookup[p.Section][p.Group]].roeDisplayOnCustomerReports
            && !partsCatalog[p.PartNumber].roeSuppressOnCustomerReports) {
            const desc = partsCatalog[p.PartNumber].customerReportsDescription
            ?? partsCatalog[p.PartNumber].description
            ?? partsCatalog[p.PartNumber].mapicsDescription;
            descQtys.set(desc, (descQtys.get(desc) ?? 0) + p.Quantity);
        }
    }
    mFeed.SystemComponents = Array.from(descQtys.entries()).map(([desc, qty]) => ({
        Qty: qty,
        Item: desc
    }));

    mFeed.OrderProcess = user.permissions.enableOnlineOrdering === "portal" ? OrderProcess.Portal : OrderProcess.Mapics;

    return mFeed;

}

const endBoomSpan = (side: Side): ISpan | undefined =>
    side.Span.length && side.Span[side.Span.length - 1].EndBoom ?
        side.Span[side.Span.length - 1]
        : undefined;