import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import EmojiObjectsOutlinedIcon from '@mui/icons-material/EmojiObjectsOutlined';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
} from "@mui/material";
import TableHead from "@mui/material/TableHead";
import i18next, { t } from "i18next";
import * as React from "react";
import { useContext, useState } from "react";
import { IGrowerWithId, IProjectWithId } from ".";
import AuthCtx from "../../../auth/AuthCtx";
import { formatAddress } from "../../../components/roe/componentRenderers/Proposal/ProposalHelpers";
import DbCtx from "../../../db/DbCtx";
import DevSettingsCtx from "../../../db/DevSettingsCtx";
import IDbState from "../../../db/IDbState";
import { ProjectState } from "../../../model/project/IProject";
import ImportFieldButton from "./ImportFieldButton";
import ProjectListTableRow from "./ProjectListTableRow";

interface Props {
  searching: boolean;
  navigateTo: (projectId?: string, layoutId?: string, systemId?: string) => void;
  projects: IProjectWithId[];
  dbState: IDbState;
  highlightText: (text: string) => string | JSX.Element;
  gwid: IGrowerWithId;
  clearSearch: () => void;
  setGrowerDialogOpen: (growerId: string) => void;
}

const showExportButton = true;

const ProjectListTableTab: React.FC<Props> = (props) => {
  const {gwid} = props;
  const devSettingsState = useContext(DevSettingsCtx);
  const [growerIsDeletedModalOpen, setGrowerIsDeletedModalOpen] = useState<boolean>(gwid ? gwid.grower.deleted : false);
  const authState = useContext(AuthCtx);

  React.useEffect(() => {
    if (props.gwid){
      setGrowerIsDeletedModalOpen(props.gwid.grower.deleted);
    }
}, [props.gwid]);

  const [filterEl, setFilterEl] = React.useState<null | HTMLElement>(null);
  const openFilterMenu = (event: React.MouseEvent<HTMLElement>) => {
      setFilterEl(event.currentTarget);
  };
  const closeFilterMenu = () => {
      setFilterEl(null);
  };

  const setShowDeleted = () => {
    closeFilterMenu(); 
    devSettingsState.projectFilterSettings.setShowDeleted(!devSettingsState.projectFilterSettings.showDeleted);
  }
  
  const dbState = useContext(DbCtx);

  const renderProjectRow = (projectWithId: IProjectWithId) => {
    if (dbState.projects[projectWithId.id].state.projectState === ProjectState.Deleted && !devSettingsState.projectFilterSettings.showDeleted) {
      return;
    }

    return (
      <ProjectListTableRow projectWithId={projectWithId} highlightText={props.highlightText} onSelect={props.navigateTo} showExportButton={showExportButton} showProjectState={devSettingsState.projectFilterSettings.showDeleted}/>
    )
  };

  //TODO: add a sort dropdown and then update how we are sorting the table
  props.projects.sort((a,b) => {
    if (a.project.name > b.project.name) return 1;
    if (b.project.name > a.project.name) return -1;
    return 0;
  });

  const ns = "projects.";

  return (
    <>
    {typeof(gwid) !== "undefined" && <>
      <Box>
        <Button variant="text" onClick={() => 
            {
              props.clearSearch();
              props.navigateTo();
            }}>
              <ChevronLeftOutlinedIcon/>
              {t("growers")}
          </Button>
      </Box>
      <Stack direction="row" spacing={1} sx={{alignItems:"center", marginLeft: "10px", marginTop: "10px", marginBottom: "10px", fontSize: "1.5em"}}>
            <span>{gwid.grower.name}</span>
            <Tooltip title={i18next.format(i18next.t("edit"), 'capitalize')} placement="bottom">
              <IconButton color="primary"  onClick={() => 
                {
                  props.setGrowerDialogOpen(gwid.id);
                }}>
                <CreateOutlinedIcon/>
              </IconButton>
            </Tooltip>
            {
              showExportButton
                ? <ImportFieldButton dbState={props.dbState} gid={gwid.id} />
                : null
            }
      </Stack>
      <span style={{marginLeft: "10px", marginBottom: "10px"}}>{gwid?.grower && formatAddress(gwid.grower.shippingAddress)}</span>
      </>
      }
      <Stack direction="row" spacing={2} sx={{alignItems:"center", marginLeft: "0.5em", marginTop:"0.5em", fontSize: "1.5em"}}>
          <Button variant="outlined"
                aria-label="filters"
                aria-haspopup="true"
                onClick={openFilterMenu}
              >
                {i18next.format(t("filter"), 'capitalize-each')}
          </Button>
          <Menu
                anchorEl={filterEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={!!filterEl}
                onClose={closeFilterMenu}
            >
                <MenuItem>
                    <FormControlLabel control={<Checkbox                           
                        checked={devSettingsState.projectFilterSettings.showDeleted}
                        onChange={setShowDeleted}
                    />} label={i18next.format(t("projects.show-deleted"), 'capitalize')} />
                </MenuItem>
            </Menu>
      </Stack>
      {!props.searching && (!devSettingsState.projectFilterSettings.showDeleted ? !props.projects.some(x => x.project.projectState === ProjectState.Active) : !props.projects.length) &&
          <Grid
            direction="column"
            container
            sx={{
              display: "flex",
              padding: "50px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
        <Box style={{display: "flex", alignItems: "center", marginBottom: "2em", marginTop: "2em"}}>
            <EmojiObjectsOutlinedIcon sx={{mr: 1, fontSize: "2.5em", color: "#1976d2"}} />
            <span style={{fontSize: "1.2em"}}>{t(ns + "you-havent-created")} {gwid.grower.name} - {t(ns + "click-to-start")}.</span>
          </Box>
        </Grid>
      }
      {
        (props.searching || (devSettingsState.projectFilterSettings.showDeleted ? props.projects.length > 0 : props.projects.some(x => x.project.projectState === ProjectState.Active))) && 
        <TableContainer sx={{
          marginBottom: 10 // Make space for content behind bottom-right button
          }}>
        <Table size="small" style={{marginTop: "1em"}}>
        <TableHead>
            <TableRow>
              <TableCell/>
              <TableCell><b>{i18next.format(t(ns + "field-name"), 'capitalize-each')}</b></TableCell>
              {
                devSettingsState.projectFilterSettings.showDeleted && <TableCell><b>{i18next.format(t(ns + "field-name"), 'capitalize-each')}</b></TableCell>
              }
              <TableCell><b>{i18next.format(t("layouts"), 'capitalize-each')}</b></TableCell>
              <TableCell><b>{i18next.format(t("systems"), 'capitalize-each')}</b></TableCell>
              <TableCell><b>{i18next.format(t(ns + "project-type"), 'capitalize-each')}</b></TableCell>
              <TableCell><b>{i18next.format(t(ns + "last-modified"), 'capitalize-each')}</b></TableCell>
              <TableCell><b>{i18next.format(t(ns + "last-modified-by"), 'capitalize-each')}</b></TableCell>
              {
                showExportButton 
                  ? <TableCell><b>{i18next.format(t("export"), 'capitalize-each')}</b></TableCell>
                  : null
              }
              <TableCell><b>{i18next.format(t("delete"), 'capitalize-each')}</b></TableCell>
              
            </TableRow>
        </TableHead>
        <TableBody>
            {props.projects.length > 0 && props.projects.map(renderProjectRow)}
            {props.projects.length === 0 && <TableRow>
              <TableCell colSpan={7} sx={{textAlign: "center"}}>
                {t("no-fields-match-criteria")}
              </TableCell>
            </TableRow>}
        </TableBody>
        </Table>
      </TableContainer>
      }
      {growerIsDeletedModalOpen && (
        <Dialog
          open={true}
          onClose={() => { }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
      >
          <DialogTitle id="alert-dialog-title">
            {i18next.format(t('projects.deleted-grower'), 'capitalize-each')}
          </DialogTitle>
          <DialogContent>
              <p>{t('projects.grower-has-been-deleted')}</p>
              {(props.gwid.grower.owner === authState.assumedUserId || props.gwid.grower.sharedWithDealership === "readwrite") && <p>{t('projects.un-delete-grower')}</p>}
          </DialogContent>
          <DialogActions>
              <Button onClick={() => {
                props.clearSearch();
                props.navigateTo();
              }}>{t("cancel")}</Button>
              <Button
                disabled={props.gwid.grower.owner !== authState.assumedUserId && props.gwid.grower.sharedWithDealership !== "readwrite"}
                onClick={async() => {
                  await dbState.deleteGrower(props.gwid.grower, props.gwid.id, false);
              }}>{t("ok")}</Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default ProjectListTableTab;
