import { BoosterPumpTypes, CF200Pumps, DualSprinklerPackageControlTypes, ElectricalFrequencies, EndGunTypes, GearDriveTypes, HoseFeedTypes, ISprinklerProperties, ISystemBase, SystemTypes } from "rdptypes/project/ISystemBase.AutoGenerated";
import { ISide } from "rdptypes/project/Types";
import { MaxAmpsFor10GWire } from "./CommonConstants";
import { MotorLocations, MotorTypes } from "./CommonEnums";
import * as cpf from "./ControlPanelFunctions";
import ElectricalCableGaugeHelper from "./ElectricalCableGaugeHelper";
import ElectricalCalculatorSide from "./ElectricalCalculatorSide";
import ElectricalMotor from "./ElectricalMotor";
import * as ohf from "./OtherHelpers";
import PartsAssembler from "./PartsAssembler";
import * as sidef from "./SideFunctions";
import * as spanf from "./SpanFunctions";
import * as sysf from "./SystemFunctions";
import * as towerf from "./TowerFunctions";
import { strLanguageText } from "./TranslationsLib";
import { Side, Span } from "./Types";
import VRI_Zones from "./VRIClass.VRI_Zones";

export interface ISpanAutoGaugeResult {
    cableGauge: number;
}

export interface IAutoGaugeResult {
    error: string | undefined;
    flangedSide: ISpanAutoGaugeResult[];
    flexSide?: ISpanAutoGaugeResult[];
}

export default class ElectricalCalculator {

    private _System: ISystemBase; // set by ctor
    private _PartsAssember: PartsAssembler = undefined;
    private _SourceVoltage: number = 0;
    private _RemotePanelVoltageLoss: number = 0;
    private _DragCordVoltageLoss: number = 0;
    private _Frequency: ElectricalFrequencies = undefined;
    private _PumpPowerConsumptionResult: PumpPowerConsumptionResult = {
        Pump: CF200Pumps.None,
        PumpDescription: null,
        PowerAmps: 0,
        PowerKWatts: 0
    }

    constructor(System: ISystemBase, PartsAssembler: PartsAssembler) {
        this._System = System;
        if (sysf.IsEDMP(this._System)) return;
        this._PartsAssember = PartsAssembler;

        this.MinimumVoltage = cpf.MinVoltage(this._System.ControlPanel);
        this.FlangedSideData = new ElectricalCalculatorSide(sysf.Is230V(this._System), this.MinimumVoltage, this._System.FlangedSide);
        this.FlexSideData = new ElectricalCalculatorSide(sysf.Is230V(this._System), this.MinimumVoltage, this._System.FlexSide);

        this.PrepData()
    }

    public readonly MinimumVoltage: number = 0; //As Double
    public readonly FlangedSideData: ElectricalCalculatorSide = undefined;
    public readonly FlexSideData: ElectricalCalculatorSide = undefined;

    public get SourceVoltage(): number {
        return this._SourceVoltage;
    }

    public get ElectricalFrequency(): ElectricalFrequencies {
        return this._Frequency;
    }

    public get RemotePanelVoltageLoss(): number {
        return this._RemotePanelVoltageLoss;
    }

    public get DragCordVoltageLoss(): number {
        return this._DragCordVoltageLoss;
    }

    public get PumpPowerConsumption(): PumpPowerConsumptionResult {
        return this._PumpPowerConsumptionResult;
    }

    public get FullLoadAmps(): number {
        if (sysf.IsEDMP(this._System)) return 0;
        return this.FlangedSideData.FullLoadAmps + this.FlexSideData.FullLoadAmps;
    }

    public get TotalKilowatts(): number {
        if (sysf.IsEDMP(this._System)) return 0;
        return this.FlangedSideData.TotalKilowatts + this.FlexSideData.TotalKilowatts;
    }

    private /* Shared */ GearDriveToMotorType(GearDrive: GearDriveTypes): MotorTypes {
        switch (GearDrive) {
            case GearDriveTypes.High:
                return MotorTypes.High;
            case GearDriveTypes.Low:
                return MotorTypes.Low;
            case GearDriveTypes.Standard:
                return MotorTypes.Standard;
            case GearDriveTypes.TwoThirtyVolt:
                return MotorTypes.TwoThirtyVolt;
        }
    }

    private /* Shared */ InitialWireGauge(side: Side, Span: Span, IsLongSinglePhaseSystem: boolean = false): ElectricalCableGaugeHelper {
        if (IsLongSinglePhaseSystem)
            return new ElectricalCableGaugeHelper(8, ElectricalCableGaugeHelper.AllowableResize.None);
        else {
            if (spanf.ConductorsRequired(this._System, side, Span) > 10) {
                // 'Only 10G has more than 10 conductors
                return new ElectricalCableGaugeHelper(10, ElectricalCableGaugeHelper.AllowableResize.None);
            }
            else {
                switch (Span.MinCableGauge) {
                    // 'Case SpanCableGauges.a14
                    // '    'ROE-410 - This is a HACK. Computation of motors should never be on an EndBoom Span with 14 Gauge wire, but occurs with 
                    // '    'a mismatch on spans / towers when changing to "Double End Feed" from any other hose feed type on a Lateral Move system.
                    // '    Return New CableGaugeHelper(SpanCableGauges.a14, CableGaugeHelper.AllowableResize.Larger)
                    case 12:
                        return new ElectricalCableGaugeHelper(12, ElectricalCableGaugeHelper.AllowableResize.Larger);
                    case 10:
                        return new ElectricalCableGaugeHelper(10, ElectricalCableGaugeHelper.AllowableResize.Larger);
                    case 8:
                        return new ElectricalCableGaugeHelper(8, ElectricalCableGaugeHelper.AllowableResize.None);
                    default:
                        // TODO Icon deal with null MinCableGauge
                        return new ElectricalCableGaugeHelper(12, ElectricalCableGaugeHelper.AllowableResize.Larger);
                }
            }
        }
    }

    private PrepData() {
        if (!sysf.FieldSets(this._System).SystemType.DataValid()) return;

        //         'If Not _System.FieldSets.ControlPanel.DataValid() Then Exit Sub
        this._Frequency = this._System.ControlPanel.ElectricalFrequency;

        let flangedside: ISide = this._System.FlangedSide;
        let systemType: SystemTypes = this._System.SystemProperties.SystemType;
        let hoseFeedType: HoseFeedTypes = HoseFeedTypes.Standard;
        let pump: CF200Pumps = CF200Pumps.None;
        let isDualSided: boolean = sysf.IsDualSided(this._System);
        let hasVRI: boolean = false;
        let iVRIAirCompressorLocation: number = 0;
        let isDoubleEndFeed: boolean = false;

        if (sysf.FieldSets(this._System).SprinklerConfig.DataValid()) {
            if (ohf.VRIZonesCount(this._System.SprinklerProperties.VRIZones) > 0) {
                hasVRI = true;
                let VRI: VRI_Zones = undefined;
                if (this._PartsAssember === undefined) {
                    VRI = new VRI_Zones(this._System);
                }
                else {
                    VRI = this._PartsAssember.VRI;
                }
                iVRIAirCompressorLocation = VRI.AirCompressorLocation
            }
        }

        switch (systemType) {
            case SystemTypes.HoseFeedMaxigator:
            case SystemTypes.CanalFeedMaxigator: {
                let MotorType: MotorTypes = this.GearDriveToMotorType(this._System.Lateral.GearDrive);
                switch (systemType) {
                    case SystemTypes.HoseFeedMaxigator: {
                        if (!sysf.FieldSets(this._System).HoseFeed.DataValid()) return;
                        hoseFeedType = this._System.Lateral.HoseFeed.HoseFeedType;
                        pump = this._System.Lateral.HoseFeed.CF200Pump;
                        switch (hoseFeedType) {
                            case HoseFeedTypes.Standard:
                                this.FlangedSideData.Motors.AddMulti(MotorLocations.PowerTower, MotorType, 2);
                                break;
                            case HoseFeedTypes.A100:
                                this.FlangedSideData.Motors.AddOne(MotorLocations.PowerTower, 0, MotorType);
                                this.FlangedSideData.Motors.AddOne(MotorLocations.A100Pump, 0, MotorTypes.A100Pump);
                                break;
                            case HoseFeedTypes.CF200:
                                this.FlangedSideData.Motors.AddOne(MotorLocations.PowerTower, 0, MotorType);
                                break;
                            case HoseFeedTypes.DoubleEndFeed:
                                this.FlangedSideData.Motors.AddMulti(MotorLocations.PowerTower, MotorType, 2);
                                isDoubleEndFeed = true;
                                break;
                            case HoseFeedTypes.FourWheelDrive:
                            case HoseFeedTypes.Sugargator:
                            case HoseFeedTypes.PivotingLateral:
                                this.FlangedSideData.Motors.AddMulti(MotorLocations.PowerTower, MotorType, 4);
                                break;
                        }
                        break;
                    }
                    case SystemTypes.CanalFeedMaxigator: {
                        if (!sysf.FieldSets(this._System).CanalFeed.DataValid()) return;
                        if (this._System.Lateral.CanalFeed.BoosterPumpSelfCleaningScreen) {
                            // 'NOTE: Booster Pump for Self Clean Screen is on the power tower before any span
                            // '   There is Only 1, so add to Flanged Side ElectricalCalculatorSide.Motors. 
                            // '   The Flex Side is optional on a Canal Feed system
                            let m: ElectricalMotor = this.FlangedSideData.Motors.AddOne(MotorLocations.BoosterPump, 1, MotorTypes.TwoHP);
                            m.IsMaxConsumer = true;
                        }
                        this.FlangedSideData.Motors.AddMulti(MotorLocations.PowerTower, MotorType, 2);
                        break;
                    }
                }
                if (isDualSided) {
                    this.FlexSideData.Motors.AddOne(MotorLocations.PowerTower, 0, MotorTypes.NoMotor);
                }
                break;
            }
            default: {
                this.FlangedSideData.Motors.AddOne(MotorLocations.PivotCenter, 0, MotorTypes.NoMotor);
                break;
            }
        }
        // Populate the pump resource ASAP to ensure it's value is available for further calculations
        this._PumpPowerConsumptionResult = GetPumpPowerConsumption(pump)

        // 'NOTE: diff between  System.FieldSets.SwingArm.DataValid and System.HasSwingArmCorner (only checks if end of system selected SAC)
        let bSACValid: boolean = sysf.FieldSets(this._System).SwingArm.DataValid();
        let bSACAirCompressor: boolean = bSACValid && this._System.Circle.SwingArm.AirCompressor;

        if (hasVRI && iVRIAirCompressorLocation === 0 && !bSACAirCompressor) { //  'Location 0 and SAC AirComp should never happen at the same time, but what the heck
            this.FlangedSideData.Motors.AddOne(MotorLocations.VRIAirCompPowerTower, 0, MotorTypes.VRIAirComp);
        }

        if (!sysf.FieldSets(this._System).FlangedTowers.DataValid()) return;

        let bLongSinglePhaseSystem: boolean = sysf.Is230V(this._System) && sidef.NumberOfSpans(flangedside) > 0 &&
            spanf.EndingLocation(this._System, flangedside, flangedside.Span[sidef.NumberOfSpans(flangedside) - 1]) > 600;

        let isWrapJointAirCompAdded = false;
        let hasDualSprinklerPackageAirComp = false;
        let insideTowerNumber: number = 0;

        if (sysf.FieldSets(this._System).SprinklerConfig.DataValid()) {
            let sp: ISprinklerProperties = this._System.SprinklerProperties;
            // 'find out where to place the dual sprinkler air compressor
            if (sp.DualSprinklerPackage && sp.DualSprinklerPackageControlType === DualSprinklerPackageControlTypes.Air) {
                hasDualSprinklerPackageAirComp = true;
                let totalSystemLength = spanf.EndingLocation(this._System, flangedside, flangedside.Span[flangedside.Span.length - 1]);
                flangedside.Span.forEach(s => {
                    if (spanf.EndingLocation(this._System, flangedside, s) <= totalSystemLength / 2) insideTowerNumber = s.SpanNumber;
                })
            }
        }

        // 'ROE-410 - There is a possibility the spans and towers collection can get out of sync while changing 
        // '   Beginning of System properties. Changing from "Hose Feed Type" to or from "Double End Feed" to any
        // '   other type will cause the towers collection be to out of sync with spans until the collection is
        // '   reset. This will cause the ElectricalCalculator to error out. Set count on Spans (with or without DEF)
        // '   to avoid sync issues. This applies only to "FlangedSide"! Not "FlexSide".
        // '   FILTER OUT THE ENDBOOM AND SWING-ARM. NOT SURE IF BOOMBACK IS ALSO REMOVED. 
        let NumberOfTowers: number = isDoubleEndFeed
            ? flangedside.Span.filter((s) => (!s.EndBoom && !s.SwingArm)).length - 1
            : sidef.NumberOfTowers(flangedside);

        if (this._System.partsPackageId >= "2024081901" && !isWrapJointAirCompAdded) {
            const airCompressorTower = towerf.getTowerForAirCompressor_post2024081901(
                this._System, flangedside
            );
            // NOTE: Below, if there is no allowable tower for the air compressor, the motor will not be added
            if (airCompressorTower.airCompressor && airCompressorTower.towerIdx !== -1) { // 'Only add 1 Air Compressor at the first allowed position
                this.FlangedSideData.Motors.AddOne(MotorLocations.WrapJointAirComp, 0, MotorTypes.WrapJointAirComp, flangedside.Span[airCompressorTower.towerIdx]);
                isWrapJointAirCompAdded = true;
            }
        }
        for (let i = 0; i < NumberOfTowers; i++) {
            let m: ElectricalMotor = this.FlangedSideData.Motors.AddOne(
                MotorLocations.Tower,
                i,
                this.GearDriveToMotorType(flangedside.Tower[i].CenterDrive),
                flangedside.Span[i],
                sysf.IsMaxigator(this._System)
            );
            m.CableGaugeHelper = this.InitialWireGauge(flangedside, m.Span, bLongSinglePhaseSystem);
            m.BuckBoostType = flangedside.Tower[i].BuckBoost;
            if (iVRIAirCompressorLocation === i + 1 && !bSACAirCompressor) {
                this.FlangedSideData.Motors.AddOne(MotorLocations.VRIAirCompOuterTower, 0, MotorTypes.VRIAirComp, flangedside.Span[i]);
            }

            let t = flangedside.Tower[i];
            if (t.AirCompressor && !isWrapJointAirCompAdded) { // 'Only add 1 Air Compressor (First closest to the Pivot Center)
                this.FlangedSideData.Motors.AddOne(MotorLocations.WrapJointAirComp, 0, MotorTypes.WrapJointAirComp, flangedside.Span[i]);
                isWrapJointAirCompAdded = true;
            }

            if (hasDualSprinklerPackageAirComp && insideTowerNumber === i) {
                this.FlangedSideData.Motors.AddOne(MotorLocations.DualSprinklerPkAirComp, 0, MotorTypes.DualSprinklerPkAirComp, flangedside.Span[i]);
            }
        }

        if (isDoubleEndFeed) {
            let m: ElectricalMotor = this.FlangedSideData.Motors.AddOne(
                MotorLocations.SecondaryPT,
                1,
                this.FlangedSideData.Motors.get(MotorLocations.PowerTower, 1).MotorType,
                flangedside.Span[NumberOfTowers]
            );
            m.CableGaugeHelper = this.InitialWireGauge(flangedside, m.Span);
            this.FlangedSideData.Motors.AddOne(
                MotorLocations.SecondaryPT,
                2,
                this.FlangedSideData.Motors.get(MotorLocations.PowerTower, 1).MotorType,
                flangedside.Span[NumberOfTowers])
        }
        if (bSACValid) {
            this.FlangedSideData.Motors.AddOne(
                MotorLocations.SACSteering,
                0,
                MotorTypes.Standard,
                flangedside.Span[NumberOfTowers]
            ).CableGaugeHelper = new ElectricalCableGaugeHelper(
                10,
                ElectricalCableGaugeHelper.AllowableResize.None
            ) //'SAC span cable is always 10G
            if (NumberOfTowers > 0){
                //won't have towers yet if using SAC EOS system default with new system
                switch (flangedside.Tower[NumberOfTowers - 1].CenterDrive) {
                    case GearDriveTypes.Standard:
                        this.FlangedSideData.Motors.AddMulti_WithSpan(MotorLocations.SACTower, MotorTypes.High, flangedside.Span[NumberOfTowers], 2)
                        break;
                    case GearDriveTypes.Low:
                        this.FlangedSideData.Motors.AddMulti_WithSpan(MotorLocations.SACTower, MotorTypes.Standard, flangedside.Span[NumberOfTowers], 2)
                        break;
                }
            }
            if (bSACAirCompressor && NumberOfTowers) {
                this.FlangedSideData.Motors.AddOne(MotorLocations.SACAirComp, 0, MotorTypes.SACAirComp, flangedside.Span[NumberOfTowers])
            }
        }
        if (
            sysf.FieldSets(this._System).FlangedEndOfSystem.DataValid() &&
            this._System.FlangedSide.EndOfSystem.EndGun.BoosterPump !== BoosterPumpTypes.None
        ) {
            if (flangedside.Span[flangedside.Span.length - 1].EndBoom) { // 'Not all systems with an end gun have an end boom, so check to see if the last span is an end boom
                switch (this._System.FlangedSide.EndOfSystem.EndGun.BoosterPump) {
                    case BoosterPumpTypes.TwoHP:
                        this.FlangedSideData.Motors.AddOne(
                            MotorLocations.BoosterPump,
                            0,
                            MotorTypes.TwoHP,
                            flangedside.Span[flangedside.Span.length - 1]
                        ).CableGaugeHelper = new ElectricalCableGaugeHelper(
                            10,
                            ElectricalCableGaugeHelper.AllowableResize.None
                        ); // 'End boom cable is always 10G
                        break;
                    case BoosterPumpTypes.FiveHP:
                        this.FlangedSideData.Motors.AddOne(
                            MotorLocations.BoosterPump,
                            0,
                            MotorTypes.FiveHP,
                            flangedside.Span[flangedside.Span.length - 1]
                        ).CableGaugeHelper = new ElectricalCableGaugeHelper(
                            10,
                            ElectricalCableGaugeHelper.AllowableResize.None
                        ); // 'End boom cable is always 10G
                        break;
                }
            }
            else {
                switch (this._System.FlangedSide.EndOfSystem.EndGun.BoosterPump) {
                    case BoosterPumpTypes.TwoHP:
                        this.FlangedSideData.Motors.AddOne(
                            MotorLocations.BoosterPump,
                            0,
                            MotorTypes.TwoHP,
                            flangedside.Span[flangedside.Span.length - 1]
                        ).CableGaugeHelper = new ElectricalCableGaugeHelper(
                            10,
                            ElectricalCableGaugeHelper.AllowableResize.None
                        ); // 'End boom cable is always 10G
                        break;
                    case BoosterPumpTypes.FiveHP:
                        this.FlangedSideData.Motors.AddOne(
                            MotorLocations.BoosterPump,
                            0,
                            MotorTypes.FiveHP,
                            flangedside.Span[flangedside.Span.length - 1]
                        ).CableGaugeHelper = new ElectricalCableGaugeHelper(
                            10,
                            ElectricalCableGaugeHelper.AllowableResize.None
                        ); // 'End boom cable is always 10G
                        break;
                }
            }
        }

        let flexside: Side = this._System.FlexSide;
        if (sysf.FieldSets(this._System).FlexTowers.DataValid()) {
            NumberOfTowers = sidef.NumberOfTowers(flexside);
            for (let i = 0; i < NumberOfTowers; i++) {
                let m: ElectricalMotor = this.FlexSideData.Motors.AddOne(
                    MotorLocations.Tower,
                    i,
                    this.GearDriveToMotorType(flexside.Tower[i].CenterDrive),
                    flexside.Span[i],
                    sysf.IsMaxigator(this._System)
                )
                m.CableGaugeHelper = this.InitialWireGauge(flexside, m.Span);
                m.BuckBoostType = flexside.Tower[i].BuckBoost;
                if (-iVRIAirCompressorLocation === i + 1) {
                    this.FlexSideData.Motors.AddOne(MotorLocations.VRIAirCompOuterTower, 0, MotorTypes.VRIAirComp, flexside.Span[i]);
                }
            }
        }

        if (
            sysf.FieldSets(this._System).FlexEndOfSystem.DataValid() &&
            (this._System.FlexSide.EndOfSystem.EndGun.EndGunTypePrimary ?? EndGunTypes.None) !== EndGunTypes.None &&
            this._System.FlexSide.EndOfSystem.EndGun.BoosterPump !== BoosterPumpTypes.None
        ) { // 'You can have a flex EOS without any towers for Power Tower End Booms
            if (flexside.Span[flexside.Span.length - 1].EndBoom) { // 'Not all systems with an end gun have an end boom, so check to see if the last span is an end boom
                switch (this._System.FlexSide.EndOfSystem.EndGun.BoosterPump) {
                    case BoosterPumpTypes.TwoHP:
                        this.FlexSideData.Motors.AddOne(
                            MotorLocations.BoosterPump,
                            0,
                            MotorTypes.TwoHP,
                            flexside.Span[flexside.Span.length - 1]
                        ).CableGaugeHelper = new ElectricalCableGaugeHelper(
                            10,
                            ElectricalCableGaugeHelper.AllowableResize.None
                        );// 'End boom cable is always 10G
                        break;
                    case BoosterPumpTypes.FiveHP:
                        this.FlexSideData.Motors.AddOne(
                            MotorLocations.BoosterPump,
                            0,
                            MotorTypes.FiveHP,
                            flexside.Span[flexside.Span.length - 1]
                        ).CableGaugeHelper = new ElectricalCableGaugeHelper(
                            10,
                            ElectricalCableGaugeHelper.AllowableResize.None
                        );// 'End boom cable is always 10G
                        break;
                }
            }
            else {
                switch (this._System.FlexSide.EndOfSystem.EndGun.BoosterPump) {
                    case BoosterPumpTypes.TwoHP:
                        this.FlexSideData.Motors.AddOne(
                            MotorLocations.BoosterPump,
                            0,
                            MotorTypes.TwoHP,
                            flexside.Span[flexside.Span.length - 1]
                        ).CableGaugeHelper = new ElectricalCableGaugeHelper(
                            10,
                            ElectricalCableGaugeHelper.AllowableResize.None
                        ); // 'End boom cable is always 10G
                        break;
                    case BoosterPumpTypes.FiveHP:
                        this.FlexSideData.Motors.AddOne(
                            MotorLocations.BoosterPump,
                            0,
                            MotorTypes.FiveHP,
                            flexside.Span[flexside.Span.length - 1]
                        ).CableGaugeHelper = new ElectricalCableGaugeHelper(
                            10,
                            ElectricalCableGaugeHelper.AllowableResize.None
                        ); // 'End boom cable is always 10G
                        break;
                }
            }
        }

        //         'Find largest current consumer 00000000000000000000000000000000000000000000000000

        //         '-->PIVOT & SINGLE SIDED MAXIGATOR<--
        //         ' -Booster pump always wins.
        //         ' -If there is a tie between the SAC and a tower motor, the SAC wins.
        //         '-->DUAL-SIDED MAXIGATOR<--
        //         ' -Booster pump always wins, if there are 2, the farthest from the power tower wins.
        //         ' -If symetrical then right side booster pump wins.
        //         ' -If no booster pump, the farthest and largest motor wins.

        let FlangedMaxConsumer: ElectricalMotor = undefined;
        let FlexMaxConsumer: ElectricalMotor = undefined;

        // TODO: Note: Introduced goto function to handle the VB gotos
        // These are called after the try/catch blocks as to not handle any errors
        // generated by the goto fns.
        // The initial gotos are wrapped in a closing function so that the GoTo_Fns
        // can be called from gotoHandlerWrapper
        // This gotoHandlerWrapper is called straigth after its declaration
        const GoTo_FlexSide = () => {
            if (this.FlexSideData.Motors.Count - 1 <= 0) return GoTo_FinalizeMaxConsumer();
            try {
                FlexMaxConsumer = this.FlexSideData.Motors.get(MotorLocations.BoosterPump);
                return GoTo_CompareTwoSides();
            }
            catch { }
            for (let i = 0; i < this.FlexSideData.Motors.Count; i++) {
                let m: ElectricalMotor = this.FlexSideData.Motors.getByIndex(i);
                if (FlexMaxConsumer === undefined)
                    FlexMaxConsumer = m;
                else {
                    if (m.RatedAmperage >= FlexMaxConsumer.RatedAmperage) FlexMaxConsumer = m;
                }
            }
            return GoTo_CompareTwoSides();
        }

        const GoTo_CompareTwoSides = () => {
            // 'Both sides have a BP
            if (FlangedMaxConsumer.Location === MotorLocations.BoosterPump && FlexMaxConsumer.Location === MotorLocations.BoosterPump) {
                // 'Largest BP wins
                // 'Is Flanged largest?
                if (FlangedMaxConsumer.RatedAmperage > FlexMaxConsumer.RatedAmperage) return GoTo_FinalizeMaxConsumer();
                // 'Is Flex largest?
                if (FlexMaxConsumer.RatedAmperage > FlangedMaxConsumer.RatedAmperage) {
                    FlexMaxConsumer.IsMaxConsumer = true;
                    return GoTo_FinalizeMaxConsumer();
                }

                // 'Farthest BP wins
                // 'Is Flanged farthest?
                if (spanf.EndingLocation(this._System, flangedside, FlangedMaxConsumer.Span) >= spanf.EndingLocation(this._System, flexside, FlexMaxConsumer.Span)) return GoTo_FinalizeMaxConsumer();
                // 'Flex is farthest
                FlexMaxConsumer.IsMaxConsumer = true;
                return GoTo_FinalizeMaxConsumer();
            }

            // 'Flanged side has a BP, Flex does not
            if (FlangedMaxConsumer.Location === MotorLocations.BoosterPump) return GoTo_FinalizeMaxConsumer();

            // 'Flex side has a BP, Flanged does not
            if (FlexMaxConsumer.Location === MotorLocations.BoosterPump) {
                FlexMaxConsumer.IsMaxConsumer = true;
                return GoTo_FinalizeMaxConsumer();
            }

            // 'No BP, either side

            // 'Largest Motor wins
            // 'Is Flanged largest?
            if (FlangedMaxConsumer.RatedAmperage > FlexMaxConsumer.RatedAmperage) return GoTo_FinalizeMaxConsumer();
            // 'Is Flex largest?
            if (FlexMaxConsumer.RatedAmperage > FlangedMaxConsumer.RatedAmperage) {
                FlexMaxConsumer.IsMaxConsumer = true;
                return GoTo_FinalizeMaxConsumer();
            }

            // 'Farthest Motor wins
            // 'Is Flanged farthest?
            if (FlexMaxConsumer.Span === undefined) return GoTo_FinalizeMaxConsumer();
            if (FlangedMaxConsumer.Span === undefined) {
                FlexMaxConsumer.IsMaxConsumer = true;
                return GoTo_FinalizeMaxConsumer();
            }
            if (spanf.EndingLocation(this._System, flangedside, FlangedMaxConsumer.Span) >= spanf.EndingLocation(this._System, flexside, FlexMaxConsumer.Span)) return GoTo_FinalizeMaxConsumer();
            // 'Flex is farthest
            FlexMaxConsumer.IsMaxConsumer = true;
            return GoTo_FinalizeMaxConsumer();
        }

        const GoTo_FinalizeMaxConsumer = () => {
            if (!(FlexMaxConsumer !== undefined && FlexMaxConsumer.IsMaxConsumer)) {
                FlangedMaxConsumer.IsMaxConsumer = true;
            }

            this.FlangedSideData.TotalAmpsCalc();
            this.FlangedSideData.TotalKilowattsCalc();
            this.FlexSideData.TotalAmpsCalc();
            this.FlexSideData.TotalKilowattsCalc();

            this._SourceVoltage = cpf.SourceVoltage(this._System.ControlPanel);
            this._RemotePanelVoltageLoss = cpf.RemotePanelOptions_VoltageLoss(this._System, this.FullLoadAmps);

            if (sysf.FieldSets(this._System).HoseFeed.DataValid()) {
                this._DragCordVoltageLoss = (
                    this._System.Lateral.HoseFeed.ElectricDragCordLength * (19 * this.FullLoadAmps)
                ) / 16510; // 'CircularMils(WireGauges.wg8)
            }

            this.FlangedSideData.Motors.getByIndex(0).VoltagePresent = this._SourceVoltage - this._RemotePanelVoltageLoss;// 'ROE-600 - Do not subtract Drag Cord Voltage Loss from SourceVoltage. Dealer subtracts before MCP. ( - _DragCordVoltageLoss )
            if (isDualSided) {
                this.FlexSideData.Motors.getByIndex(0).VoltagePresent = this.FlangedSideData.Motors.getByIndex(0).VoltagePresent;
            }
            this.FlangedSideData.VoltageCalc()
            this.FlexSideData.VoltageCalc()
        }

        const gotoHandlerWrapper = () => {
            let gotoFn: (() => void) | undefined = undefined;

            try {
                FlangedMaxConsumer = this.FlangedSideData.Motors.get(MotorLocations.BoosterPump);
                gotoFn = GoTo_FlexSide;
            }
            catch { }
            if (gotoFn) return gotoFn();

            try {
                FlangedMaxConsumer = this.FlangedSideData.Motors.get(MotorLocations.SACAirComp);
                gotoFn = GoTo_FinalizeMaxConsumer;
            }
            catch { }
            if (gotoFn) return gotoFn();

            try {
                FlangedMaxConsumer = this.FlangedSideData.Motors.get(MotorLocations.A100Pump);
                gotoFn = GoTo_FinalizeMaxConsumer;
            }
            catch { }
            if (gotoFn) return gotoFn();

            try {
                FlangedMaxConsumer = this.FlangedSideData.Motors.get(MotorLocations.VRIAirCompPowerTower);
                gotoFn = GoTo_FinalizeMaxConsumer;
            }
            catch { }
            if (gotoFn) return gotoFn();

            try {
                FlangedMaxConsumer = this.FlangedSideData.Motors.get(MotorLocations.VRIAirCompOuterTower);
                gotoFn = GoTo_FinalizeMaxConsumer;
            }
            catch { }
            if (gotoFn) return gotoFn();

            for (let i = 0; i < this.FlangedSideData.Motors.Count; i++) {
                let m: ElectricalMotor = this.FlangedSideData.Motors.getByIndex(i);
                if (FlangedMaxConsumer === undefined) {
                    FlangedMaxConsumer = m
                }
                else {
                    if (m.RatedAmperage >= FlangedMaxConsumer.RatedAmperage) FlangedMaxConsumer = m;
                }
            }
            gotoFn = GoTo_FlexSide;
            gotoFn();
        }
        gotoHandlerWrapper();



        //console.log("this", this)
    }

    public AutoGauge(): IAutoGaugeResult {
        const result: IAutoGaugeResult = {
            flangedSide: this._System.FlangedSide.Span.map(x => ({
                cableGauge: x.MinCableGauge
            })),
            flexSide: sysf.IsDualSided(this._System) ? this._System.FlexSide.Span.map(x => ({
                cableGauge: x.MinCableGauge
            })) : undefined,
            error: undefined
        };

        if (sysf.IsEDMP(this._System)) {
            return result;
        }

        let FlangedSideFusedAmps: number = 0;
        let FlexSideFusedAmps: number = 0;

        // 'If Center Feed system and System Amps > 30 then the Control Panel will be dual fused, one for each side, 20A per side
        if (sysf.IsCenterFeed(this._System) && this.FullLoadAmps > MaxAmpsFor10GWire) {
            FlangedSideFusedAmps = this.FlangedSideData.FullLoadAmps - this.FlangedSideData.PowerTowerAmps;
            FlexSideFusedAmps = this.FlexSideData.FullLoadAmps;
            if (FlangedSideFusedAmps > 20 || FlexSideFusedAmps > 20) {
                throw new Error(strLanguageText("lsltHighAmpsOnSide"));
            }
        }
        else { //'Otherwise it is a single fused system
            FlangedSideFusedAmps = this.FullLoadAmps;
            FlexSideFusedAmps = this.FullLoadAmps;
        }

        const errorFlanged = this.FlangedSideData.AutoGaugeCalc(FlangedSideFusedAmps);
        const errorFlex = this.FlexSideData.AutoGaugeCalc(FlexSideFusedAmps);

        result.error = errorFlanged ?? errorFlex;

        if (this.FlangedSideData.Motors.Count - 1 > 0) {
            for (let i = 0; i < this.FlangedSideData.Motors.Count; i++) {
                let m: ElectricalMotor = this.FlangedSideData.Motors.getByIndex(i);
                if (m.Span !== undefined && m.CableGaugeHelper.Gauge > -1) {
                    result.flangedSide[m.Span.SpanNumber - 1].cableGauge = m.CableGaugeHelper.Gauge;
                }
            }
        }

        if (this.FlexSideData.Motors.Count - 1 > 0) {
            for (let i = 0; i < this.FlexSideData.Motors.Count; i++) {
                let m: ElectricalMotor = this.FlexSideData.Motors.getByIndex(i);
                if (m.Span !== undefined && m.CableGaugeHelper.Gauge > -1) {
                    result.flexSide![m.Span.SpanNumber - 1].cableGauge = m.CableGaugeHelper.Gauge;
                }
            }
        }

        return result;
    }

}

const GetPumpPowerConsumption = (pump: CF200Pumps): PumpPowerConsumptionResult => {
    switch (pump) {
        case CF200Pumps.Hp15:
            return {
                Pump: CF200Pumps.Hp15, PumpDescription: "15Hp Electric Motor with 4RB Pump", PowerAmps: 14.4, PowerKWatts: 11.96
            };
        case CF200Pumps.Hp20:
            return {
                Pump: CF200Pumps.Hp20, PumpDescription: "20Hp Electric Motor with 4RB Pump", PowerAmps: 19.2, PowerKWatts: 15.95
            };
        case CF200Pumps.Hp15:
            return {
                Pump: CF200Pumps.Hp25, PumpDescription: "25Hp Electric Motor with 4RB Pump", PowerAmps: 24.0, PowerKWatts: 19.93
            };
        default:
            //Take "None" as the default
            return {
                Pump: CF200Pumps.None, PumpDescription: "None", PowerAmps: 0, PowerKWatts: 0
            };
    }
}

export interface PumpPowerConsumptionResult {
    Pump: CF200Pumps
    PumpDescription: string
    PowerAmps: number
    PowerKWatts: number
}