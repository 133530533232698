import IAction from "rdptypes/IAction";
import { SideEnum } from "rdptypes/helpers/SideEnum";
import { EndOfSystemTypes, SystemTypes, WrapAroundSpanTypes } from "rdptypes/project/ISystemBase.AutoGenerated";
import { ISpan, ITower } from "rdptypes/project/Types";
import { productSets } from "roedata/data";
import { createNewSystemActionV2 } from "../../actions/NewSystemActionV2";
import { createSetEndOfSystemAction } from "../../actions/SetEndOfSystemAction";
import { ISetSpanDataV2, createSetSpanTowersActionV2 } from "../../actions/SetSpansV2";
import { createNewUpdateSystemPropertyAction } from "../../actions/UpdateSystemProperty";
import { createSystemDefaultActions } from "../../actions/createSystemDefaultActions";
import IAuthState from "../../auth/IAuthState";
import IDbProject from "../../db/IDbProject";
import { IDealerCustomerSettings, IDealerPricing } from "../../db/IDevSettingsState";
import * as optimizeCenterPivotModule from "../../optimization/centerPivot";

export const createCenterPivotActionsFromOptimizedSystem = (args: {
    optimizedSystem: optimizeCenterPivotModule.IOptimizedSystem;
    layoutId: string;
    authState: IAuthState;
    spanDefaults?: ISpan;
    towerDefaults?: ITower;
    customerSettings: IDealerCustomerSettings;
    dealerPricing?: IDealerPricing;
    systemDefaults?: (layoutId: string, systemId: string, authState: IAuthState) => IAction[];
    dbPrj: IDbProject;
}) => {
    const { layoutId, authState, optimizedSystem, spanDefaults, towerDefaults, customerSettings, dealerPricing, dbPrj } = args;
    const { systemId, action } = createNewSystemActionV2(
        layoutId, SystemTypes.CenterPivot, 
        customerSettings, dealerPricing,
        authState,
        productSets[dbPrj.state.productSetId].latestPartsPackageId
    );
        
    const actions: IAction[] = [action];
    if (args.systemDefaults) {
        for (const def of args.systemDefaults(layoutId, systemId, authState)) {
            actions.push(def);
        }
    } else {
        actions.push(...createSystemDefaultActions(layoutId, systemId, authState));
    }
    actions.push(
        createNewUpdateSystemPropertyAction(layoutId!, systemId, "centerPivot.point", optimizedSystem.center, authState)
    );
    if (optimizedSystem.clockwiseCompassHeadingStart || optimizedSystem.clockwiseCompassHeadingEnd) {
        actions.push(
            createNewUpdateSystemPropertyAction(
                layoutId!,
                systemId,
                "Circle.CenterPivot.isPartialPivot",
                true,
                authState
            )
        );
    }
    if (optimizedSystem.clockwiseCompassHeadingStart) {
        actions.push(
            createNewUpdateSystemPropertyAction(
                layoutId!,
                systemId,
                "Circle.CenterPivot.clockwiseCompassHeadingStart",
                optimizedSystem.clockwiseCompassHeadingStart,
                authState
            )
        );
    }
    if (optimizedSystem.clockwiseCompassHeadingEnd) {
        actions.push(
            createNewUpdateSystemPropertyAction(
                layoutId!,
                systemId,
                "Circle.CenterPivot.clockwiseCompassHeadingEnd",
                optimizedSystem.clockwiseCompassHeadingEnd,
                authState
            )
        );
    }
    const forwardSpans: ISetSpanDataV2[] = [];
    for (let i = 0; i < optimizedSystem.spanTowers.length; i++) {
        const tower = optimizedSystem.spanTowers[i];
        const maxWrapAngle = Math.max(
            tower.anticlockwiseWrapAngleRelativeToPreviousSpanDegrees || 0,
            tower.clockwiseWrapAngleRelativeToPreviousSpanDegrees || 0
        )
        let wrapAroundSpan: WrapAroundSpanTypes;
        if (maxWrapAngle === 0) {
            wrapAroundSpan = WrapAroundSpanTypes.None;
        }
        else if (maxWrapAngle <= 10) {
            wrapAroundSpan = WrapAroundSpanTypes.TenDegree;
        }
        else {
            wrapAroundSpan = WrapAroundSpanTypes.NinetyDegree;
        }
        forwardSpans.push({
            span: {
                ...spanDefaults,
                Length: tower.spanLength,
                Extension: tower.extension,
            },
            tower: {
                ...towerDefaults,
                WrapAroundSpan: wrapAroundSpan,
                anticlockwiseWrapAngleRelativeToPreviousSpanDegrees: tower.anticlockwiseWrapAngleRelativeToPreviousSpanDegrees,
                clockwiseWrapAngleRelativeToPreviousSpanDegrees: tower.clockwiseWrapAngleRelativeToPreviousSpanDegrees,
            }
        })
    }

    actions.push(
        createSetSpanTowersActionV2(
            layoutId,
            systemId,
            {
                forwardSpans
            },
            authState
        )
    )
    
    if (optimizedSystem.endBoom) {
        actions.push(
            createSetEndOfSystemAction(
                layoutId,
                systemId,
                SideEnum.Flanged,
                {
                    endOfSystemType: EndOfSystemTypes.EndBoom,
                    endBoomLengthFeet: optimizedSystem.endBoom
                },
                authState
            )
        )
    }
    else if (optimizedSystem.sac) {
        actions.push(
            createNewUpdateSystemPropertyAction(
                layoutId,
                systemId,
                "Circle.SwingArm.LeadingSwingArm",
                optimizedSystem.sacIsLeading,
                authState
            )
        )
        actions.push(
            createSetEndOfSystemAction(
                layoutId,
                systemId,
                SideEnum.Flanged,
                {
                    endOfSystemType: EndOfSystemTypes.SAC,
                    swingArmLength: optimizedSystem.sac
                },
                authState
            )
        )
    }
    
    actions.push(
        createNewUpdateSystemPropertyAction(
            layoutId,
            systemId,
            "FlangedSide.EndOfSystem.EndGun.EndGunTypePrimary",
            optimizedSystem.primaryEndGun,        
            authState
        )
    )
    
    actions.push(
        createNewUpdateSystemPropertyAction(
            layoutId,
            systemId,
            "FlangedSide.EndOfSystem.EndGun.EndGunTypeSecondary",
            optimizedSystem.secondaryEndGun,        
            authState
        )
    )
    
    actions.push(
        createNewUpdateSystemPropertyAction(
            layoutId,
            systemId,
            "endGuns.flangedSidePrimaryThrow",
            optimizedSystem.primaryEndGunThrow,        
            authState
        )
    )
    
    actions.push(
        createNewUpdateSystemPropertyAction(
            layoutId,
            systemId,
            "endGuns.flangedSideSecondaryThrow",
            optimizedSystem.secondaryEndGunThrow,        
            authState
        )
    )
    
    return {
        systemId,
        actions,
    };
};