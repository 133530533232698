import { EndGunTypes, GearDriveTypes, HoseFeedTypes, ISystemBase, SpanTypes, SystemTypes, ValveTypes } from "rdptypes/project/ISystemBase.AutoGenerated";
import { FloatsAreEqual, GetRollingRadius } from "./CommonFunctions";
import { canalFeedHeight, centerPivotHeight, hoseFeedHeight, kwikTowHeight, swingArmHeight, towerHeight } from "./HeightFunctions";
import { ConductorCount, EndingLocation, LengthInFeet, PipeType, SpanPipeTypes, StartingLocation } from "./SpanFunctions";
import { HasPowerTowerEndBoom, HasSwingArmCorner } from "./SystemFunctions";
import { Side, TSpans, TTowers, Tires } from "./Types";
import { TowerDetailsDto } from "./Valids.dto.Valids";

export const NumberOfSpans = (side: Side): number => side.Span.filter(x => !x.EndBoom && !x.SwingArm).length;

export const NumberOfTowers = (side: Side): number => side.Tower.length;

export const FirstDisconnectingSpan = (side: Side): number => {
    for (let i = 1; i <= NumberOfSpans(side); i++) {
        if (side.Span[i - 1].Disconnecting) {
            return i;
        }
    }
    return 0;
}

export const GuidanceTower = (side: Side): number => {
    for (let i = 1; i <= NumberOfTowers(side); i++) {
        if (side.Tower[i - 1].Guidance) {
            return i;
        }
    }
    return 0;
}

export const Spans = (sys: ISystemBase, side: Side): TSpans => ({
    Span: side.Span,
    Count: side.Span.length,
    AnyStainless: () => side.Span.map(x => PipeType(sys, side, x)).some(x => x === SpanPipeTypes.Stainless),
    AnySteel: () => side.Span.map(x => PipeType(sys, side, x)).some(x => x === SpanPipeTypes.Painted || x === SpanPipeTypes.Galvanized),
    AnyAluminum: () => side.Span.map(x => PipeType(sys, side, x)).some(x => x === SpanPipeTypes.Aluminum),
    AnyAlum6: () => side.Span.some(x => x.SpanType === SpanTypes.A60G || x.SpanType === SpanTypes.AlumIV),
    ToList: () => side.Span,
    FundamentalConductorCount: (sys: ISystemBase) => side.Span.length > 0 ? ConductorCount(sys, side, side.Span[0], true) : 9,
    SpanCount: (excludeEndBoom = true, excludeSwingArm = true): number => {
        let count = 0;
        for (const s of side.Span) {
            if (excludeEndBoom && s.EndBoom) continue;
            if (excludeSwingArm && s.SwingArm) continue;
            count++;
        }
        return count;
    },
    Any57: () => side.Span.some(x => x.Spacing === 57)
})

export const Towers = (side: Side): TTowers => ({
    Tower: side.Tower,
    Count: side.Tower.length,
    ToList: () => side.Tower
})

export const FlangedSide = (sys: ISystemBase, side: Side): boolean => {
    return side === sys.FlangedSide;
}

export const AnyHighSpeed = (side: Side): boolean => side.Tower.some(x => x.CenterDrive === GearDriveTypes.High);

export const GetTowerDetails = (side: Side, TowerNumber: number): TowerDetailsDto =>
    TowerNumber === 0 || TowerNumber > side.Tower.length ? undefined
        : new TowerDetailsDto(
            side.Tower[TowerNumber - 1].TowerType,
            side.Tower[TowerNumber - 1].WrapAroundSpan,
            side.Span[TowerNumber - 1].Disconnecting
        );

export const HasEndGunPrimary = (side: Side): boolean =>
    (side.EndOfSystem.EndGun.EndGunTypePrimary ?? EndGunTypes.None) !== EndGunTypes.None;

export const HasEndGunSecond = (side: Side): boolean =>
    (side.EndOfSystem.EndGun.EndGunTypeSecondary ?? EndGunTypes.None) !== EndGunTypes.None;


export const ElectricEOS = (side: Side): boolean =>
    HasEndGunPrimary(side)
        ? side.EndOfSystem.EndGun.Valve === ValveTypes.Diaphragm
            || side.EndOfSystem.EndGun.Valve === ValveTypes.Auto800
            || side.EndOfSystem.EndGun.Valve === ValveTypes.Auto1000
            || side.EndOfSystem.EndGun.Valve === ValveTypes.Reinke
            || side.EndOfSystem.EndGun.EndGunTypePrimary === EndGunTypes.SR100NV
        : false;

export const ElectricEOSSecondary = (side: Side): boolean => 
    HasEndGunSecond(side)
        ? side.EndOfSystem.EndGun.SecondaryValve === ValveTypes.Diaphragm
            || side.EndOfSystem.EndGun.SecondaryValve === ValveTypes.Auto800
            || side.EndOfSystem.EndGun.SecondaryValve === ValveTypes.Auto1000
            || side.EndOfSystem.EndGun.SecondaryValve === ValveTypes.Reinke
            || side.EndOfSystem.EndGun.EndGunTypeSecondary === EndGunTypes.SR100NV
        : false;


export const BaselineHeightAtLocation = (sys: ISystemBase, side: Side, IsFlangedSide: boolean, location: number) => {
    let SpanNumber: number;

    for (SpanNumber = 1; SpanNumber <= side.Span.length; SpanNumber++) {
        const span = side.Span[SpanNumber - 1];

        let startingLoc = StartingLocation(sys, side, span);
        let endingLoc = EndingLocation(sys, side, span);

        if (location >= startingLoc && location <= endingLoc) {
            break;
        }
    }

    let PrevTowerHeight: number;

    if (SpanNumber === 1) {
        // Previous tower is main system tower
        switch (sys.SystemProperties.SystemType) {
            case SystemTypes.CenterPivot:
                PrevTowerHeight = centerPivotHeight(sys.Circle.CenterPivot);
                break;
            case SystemTypes.KwikTow:
                PrevTowerHeight = kwikTowHeight;
                break;
            case SystemTypes.CanalFeedMaxigator:
                PrevTowerHeight = canalFeedHeight(sys.Lateral);
                break;
            case SystemTypes.HoseFeedMaxigator:
                PrevTowerHeight = hoseFeedHeight(sys.Lateral);
                break;
        }
    } else if (SpanNumber <= side.Tower.length + 1) {
        // Previous tower is regular tower prior to span
        PrevTowerHeight = towerHeight(sys, side, side.Tower[SpanNumber - 2]);
    } else if (SpanNumber == side.Tower.length + 2) {
        let bDEF: boolean = 
            sys.SystemProperties.SystemType === SystemTypes.HoseFeedMaxigator
            && sys.Lateral.HoseFeed.HoseFeedType === HoseFeedTypes.DoubleEndFeed;
        if (HasSwingArmCorner(sys)) { // Swing Arm
            // Previous tower is swing tower 
            PrevTowerHeight = swingArmHeight(sys.Circle.SwingArm);
        } else if (bDEF) {
            // Previous tower is secondary power tower, same height as main power tower
            PrevTowerHeight = hoseFeedHeight(sys.Lateral);
        } else {
            return 0; // This is catching the EDIT SPAN BUG 
        }
    }

    let NextTowerHeight: number;

    if (!IsFlangedSide && HasPowerTowerEndBoom(sys)) {
        NextTowerHeight = PrevTowerHeight;
    } else {
        if (SpanNumber <= side.Tower.length) {
            // Next tower is regular tower after the span
            NextTowerHeight = towerHeight(sys, side, side.Tower[SpanNumber - 1]);
        } else if (SpanNumber == side.Tower.length + 1) {
            if (side.Span[SpanNumber - 1].EndBoom) {
                //Next Tower is the same as the Prev Tower for End Booms
                NextTowerHeight = PrevTowerHeight
            } else if (HasSwingArmCorner(sys)) { // Swing Arm
                NextTowerHeight = swingArmHeight(sys.Circle.SwingArm);
            } else { // Double End Feed
                // Next tower is secondary power tower, same height as main power tower
                NextTowerHeight = hoseFeedHeight(sys.Lateral);
            }
        } else if (SpanNumber == side.Tower.length + 2) {
            // Must be an end boom
            // Next Tower is the same as the Prev Tower for End Booms
            NextTowerHeight = PrevTowerHeight;
        }
    }


    return ((NextTowerHeight - PrevTowerHeight) * ((location - StartingLocation(sys, side, side.Span[SpanNumber - 1])) / LengthInFeet(side, side.Span[SpanNumber - 1]))) + PrevTowerHeight;
}

export const GetHoseFeedHeight = (sys: ISystemBase) => {
    if (sys.Lateral.HoseFeed.HoseFeedType === HoseFeedTypes.FourWheelDrive) {
        return 12.6 + GetTireHeightAdjustment(sys.Lateral.Tires);
    }
    if (sys.Lateral.HoseFeed.HoseFeedType === HoseFeedTypes.Sugargator) {
        return 16.6 + GetTireHeightAdjustment(sys.Lateral.Tires);
    }

    return 13 + GetTireHeightAdjustment(sys.Lateral.Tires);
}

export const GetTireHeightAdjustment = (tire: Tires) => {
    const dRollingRadius = GetRollingRadius(tire.TireSize, tire.TireType);
    const baseHeight = 20.1;
    return (dRollingRadius - baseHeight) / 12;
}

/**
 * Determine if any of the Spans between the startSpan and end range are
 * a SwingArm or an EndBoom on a SwingArm.
 */
export const AnySwingArmSpans = (side: Side, startSpan = 0, endSpan = 0): boolean => {
    if (endSpan === 0) endSpan = side.Span.length;
    if (startSpan < 1) startSpan = 1;
    const hasSAC = side.Span.some(x => x.SwingArm);
    // LOGIC: we're checking if the the side has a SwingArm or an EndBoom on a SwingArm 
    //    between the startSpan and end spans. 
    return side.Span
        .filter(w => w.SpanNumber >= startSpan && w.SpanNumber <= endSpan)
        .some(x => x.SwingArm || (hasSAC && x.SpanType === SpanTypes.EndBoom));
}

/**
 * Determine the Span number on a Side based on the location (in feet)
 * @param location Location down the System length to identify Span number. Unit is in Feet.
 * @param includeEOS 
 * @param zeroAsEOS 
 * @returns 
 */
export const SpanFromLocation = (sys: ISystemBase, side: Side, location: number, includeEOS = false, zeroAsEOS = false): number => {
    // NOTE: Packages designate EOS as (EndingLocation = 0)
    // Option of returning EOS must be specified
    if (zeroAsEOS && FloatsAreEqual(location, 0)) return SpanCount(side, includeEOS);
    if (location <= 30) return 1;

    const k = SpanCount(side, includeEOS);
    for (let i = 1; i <= k; i++) {
        const sp = side.Span[i - 1];
        if (location >= StartingLocation(sys, side, sp) && location <= EndingLocation(sys, side, sp)) {
            return i;
        }
    }
    // If location is > than the last span, then default to the last span number 
    //    This may occur if the location is on an EndBoom or SwingArm, but the SpanCount
    //    did not include the EOS. 
    return k;
}

/**
 * For Flange or Flex Side, returns the number of spans by default EXCLUDING SwingArm and EndBoom
 * @param side 
 * @param includeEOS Optionally may include the SwingArm and EndBoom spans in the total count
 * @returns Count of Spans on the System Side.
 */
export const SpanCount = (side: Side, includeEOS = false): number =>
    side.Span.filter(s => includeEOS || (!s.EndBoom && !s.SwingArm)).length;