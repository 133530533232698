/**
 * List changes here...
 */

import IPartsPackage from "rdptypes/roe/IPartsPackage";
import partsPackage_2024060101 from "../2024060101";

export default {
    ...partsPackage_2024060101,
    id: "2024081501"
} as IPartsPackage;