import IPoSchemaGroup from "rdptypes/roe/IPoSchemaGroup";
import IPoSchemaSection from "rdptypes/roe/IPoSchemaSection";

const sections: { [sectionId: string]: IPoSchemaSection }  = {
    CenterPivot: {
        sectionId: "CenterPivot",
        seq: 1,
        label: "Center Pivot",
        // roeSection: "1",
    },
    CanalFeed: {
        sectionId: "CanalFeed",
        seq: 2,
        label: "Canal Feed",
        // roeSection: "2",
    },
    KwikTow: {
        sectionId: "KwikTow",
        seq: 3,
        label: "Kwik Tow",
        // roeSection: "3",
    },
    HoseFeed: {
        sectionId: "HoseFeed",
        seq: 4,
        label: "Hose Feed",
        // roeSection: "4",
    },
    ControlPanel: {
        sectionId: "ControlPanel",
        seq: 5,
        label: "Control Panel",
        // roeSection: "5",
    },
    SpanAssemblies: {
        sectionId: "SpanAssemblies",
        seq: 6,
        label: "Span Assemblies",
        // roeSection: "6",
    },
    EndofSystem: {
        sectionId: "EndofSystem",
        seq: 7,
        label: "End of System",
        // roeSection: "7",
    },
    MaxigatorGuidance: {
        sectionId: "MaxigatorGuidance",
        seq: 8,
        label: "Maxigator Guidance",
        // roeSection: "8",
    },
    TowerAssemblies: {
        sectionId: "TowerAssemblies",
        seq: 9,
        label: "Tower Assemblies",
        // roeSection: "9",
    },
    SwingArmCorner: {
        sectionId: "SwingArmCorner",
        seq: 10,
        label: "Swing Arm Corner",
        // roeSection: "10",
    },
    OptionsandAccessories: {
        sectionId: "OptionsandAccessories",
        seq: 11,
        label: "Options and Accessories",
        // roeSection: "11",
    },
    Sprinklers: {
        sectionId: "Sprinklers",
        seq: 12,
        label: "Sprinklers",
        // roeSection: "12",
    },
    AgriInject: {
        sectionId: "AgriInject",
        seq: 13,
        label: "Agri-Inject",
        // roeSection: "13",
    },
    MainlineValves: {
        sectionId: "MainlineValves",
        seq: 14,
        label: "Mainline Valves",
        // roeSection: "14",
    },
    SprinklerLube: {
        sectionId: "SprinklerLube",
        seq: 16,
        label: "Sprinkler Lube",
        // roeSection: "16",
    },
    ClemonsFilters: {
        sectionId: "ClemonsFilters",
        seq: 17,
        label: "Clemons Filters",
        // roeSection: "17",
    },
    CheckValves: {
        sectionId: "CheckValves",
        seq: 18,
        label: "Check Valves",
        // roeSection: "18",
    },
    HeatExchangers: {
        sectionId: "HeatExchangers",
        seq: 19,
        label: "Heat Exchangers",
        // roeSection: "19",
    },
    RadioTelemetry: {
        sectionId: "RadioTelemetry",
        seq: 20,
        label: "Radio Telemetry",
        // roeSection: "20",
    },
    VRI: {
        sectionId: "VRI",
        seq: 21,
        label: "VRI",
        // roeSection: "21",
    },
    Flowmeters: {
        sectionId: "Flowmeters",
        seq: 22,
        label: "Flowmeters",
        // roeSection: "22",
    },
    RC10: {
        sectionId: "RC10",
        seq: 23,
        label: "RC10",
        // roeSection: "23",
    },
    DualSprinklerPackage: {
        sectionId: "DualSprinklerPackage",
        seq: 24,
        label: "Dual Sprinkler Package (Pivot while Wet)",
        // roeSection: "24",
    },
    CropX: {
        sectionId: "CropX",
        seq: 25,
        label: "CropX",
        // roeSection: "25",
    },
    RC10Retro: {
        sectionId: "RC10Retro",
        seq: 26,
        label: "RC10 Retro",
        // roeSection: "26",
    },
}
const groups: { [groupId: string]: IPoSchemaGroup }  = {
    CenterPivot_PivotCenterAssembly: {
        groupId: "CenterPivot_PivotCenterAssembly",
        sectionId: sections.CenterPivot.sectionId,
        seq: 1,
        label: "Pivot Center Assembly",
        // roeGroup: "1",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    CenterPivot_PivotRiserPipe: {
        groupId: "CenterPivot_PivotRiserPipe",
        sectionId: sections.CenterPivot.sectionId,
        seq: 2,
        label: "Pivot Riser Pipe",
        // roeGroup: "2",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    CenterPivot_PivotJointAssembly: {
        groupId: "CenterPivot_PivotJointAssembly",
        sectionId: sections.CenterPivot.sectionId,
        seq: 3,
        label: "Pivot Joint Assembly",
        // roeGroup: "3",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    CenterPivot_AnchorPackage: {
        groupId: "CenterPivot_AnchorPackage",
        sectionId: sections.CenterPivot.sectionId,
        seq: 4,
        label: "Anchor Package",
        // roeGroup: "4",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    CenterPivot_GasketPackage: {
        groupId: "CenterPivot_GasketPackage",
        sectionId: sections.CenterPivot.sectionId,
        seq: 5,
        label: "Gasket Package",
        // roeGroup: "5",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: false,
        // roeInternationalFreightRate: 1.0000,
    },
    CenterPivot_PadBolt: {
        groupId: "CenterPivot_PadBolt",
        sectionId: sections.CenterPivot.sectionId,
        seq: 6,
        label: "Pad Bolt",
        // roeGroup: "6",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: false,
        // roeInternationalFreightRate: 1.0000,
    },
    CenterPivot_TowSkidPackage: {
        groupId: "CenterPivot_TowSkidPackage",
        sectionId: sections.CenterPivot.sectionId,
        seq: 7,
        label: "Tow Skid Package",
        // roeGroup: "7",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    CenterPivot_TowCableAssembly: {
        groupId: "CenterPivot_TowCableAssembly",
        sectionId: sections.CenterPivot.sectionId,
        seq: 8,
        label: "Tow Cable Assembly",
        // roeGroup: "8",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: false,
        // roeInternationalFreightRate: 1.0000,
    },
    CenterPivot_4WheelPivotMoverAssembly: {
        groupId: "CenterPivot_4WheelPivotMoverAssembly",
        sectionId: sections.CenterPivot.sectionId,
        seq: 9,
        label: "4 Wheel Pivot Mover Assembly",
        // roeGroup: "9",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    CenterPivot_TireAndWheelAssembly: {
        groupId: "CenterPivot_TireAndWheelAssembly",
        sectionId: sections.CenterPivot.sectionId,
        seq: 10,
        label: "Tire And Wheel Assembly",
        // roeGroup: "10",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    CenterPivot_ExtraHitchAssembly: {
        groupId: "CenterPivot_ExtraHitchAssembly",
        sectionId: sections.CenterPivot.sectionId,
        seq: 11,
        label: "Extra Hitch Assembly",
        // roeGroup: "11",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    CenterPivot_PivotCenterWalkway: {
        groupId: "CenterPivot_PivotCenterWalkway",
        sectionId: sections.CenterPivot.sectionId,
        seq: 12,
        label: "Pivot Center Walkway",
        // roeGroup: "12",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    CenterPivot_MCPMounts: {
        groupId: "CenterPivot_MCPMounts",
        sectionId: sections.CenterPivot.sectionId,
        seq: 13,
        label: "MCP Mounts",
        // roeGroup: "13",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: false,
        // roeInternationalFreightRate: 1.0000,
    },
    CenterPivot_E2045FoundationFormKit: {
        groupId: "CenterPivot_E2045FoundationFormKit",
        sectionId: sections.CenterPivot.sectionId,
        seq: 14,
        label: "E2045 Foundation Form Kit",
        // roeGroup: "14",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: false,
        // roeInternationalFreightRate: 1.0000,
    },
    CenterPivot_PipeBottomFitting: {
        groupId: "CenterPivot_PipeBottomFitting",
        sectionId: sections.CenterPivot.sectionId,
        seq: 16,
        label: "Pipe Bottom Fitting",
        // roeGroup: "16",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    CanalFeed_PowerTowerCartAssembly: {
        groupId: "CanalFeed_PowerTowerCartAssembly",
        sectionId: sections.CanalFeed.sectionId,
        seq: 1,
        label: "Power Tower Cart Assembly",
        // roeGroup: "1",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    CanalFeed_CenterGearDrives: {
        groupId: "CanalFeed_CenterGearDrives",
        sectionId: sections.CanalFeed.sectionId,
        seq: 2,
        label: "Center Gear Drives",
        // roeGroup: "2",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: false,
        // roeInternationalFreightRate: 1.0000,
    },
    CanalFeed_TireandWheelAssembly: {
        groupId: "CanalFeed_TireandWheelAssembly",
        sectionId: sections.CanalFeed.sectionId,
        seq: 4,
        label: "Tire and Wheel Assembly",
        // roeGroup: "4",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    CanalFeed_Engine: {
        groupId: "CanalFeed_Engine",
        sectionId: sections.CanalFeed.sectionId,
        seq: 5,
        label: "Engine",
        // roeGroup: "5",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    CanalFeed_Pump: {
        groupId: "CanalFeed_Pump",
        sectionId: sections.CanalFeed.sectionId,
        seq: 6,
        label: "Pump",
        // roeGroup: "6",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    CanalFeed_FuelTank: {
        groupId: "CanalFeed_FuelTank",
        sectionId: sections.CanalFeed.sectionId,
        seq: 7,
        label: "Fuel Tank",
        // roeGroup: "7",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    CanalFeed_Generator: {
        groupId: "CanalFeed_Generator",
        sectionId: sections.CanalFeed.sectionId,
        seq: 8,
        label: "Generator",
        // roeGroup: "8",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    CanalFeed_Intake: {
        groupId: "CanalFeed_Intake",
        sectionId: sections.CanalFeed.sectionId,
        seq: 9,
        label: "Intake",
        // roeGroup: "9",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    CanalFeed_BoosterPumpSelfCleaningScreen: {
        groupId: "CanalFeed_BoosterPumpSelfCleaningScreen",
        sectionId: sections.CanalFeed.sectionId,
        seq: 10,
        label: "Booster Pump - Self Cleaning Screen",
        // roeGroup: "10",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    CanalFeed_PumpPrimer: {
        groupId: "CanalFeed_PumpPrimer",
        sectionId: sections.CanalFeed.sectionId,
        seq: 11,
        label: "Pump Primer",
        // roeGroup: "11",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: false,
        // roeInternationalFreightRate: 1.0000,
    },
    CanalFeed_WyeAssembly: {
        groupId: "CanalFeed_WyeAssembly",
        sectionId: sections.CanalFeed.sectionId,
        seq: 12,
        label: "Wye Assembly",
        // roeGroup: "12",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    CanalFeed_SuctionExtensions: {
        groupId: "CanalFeed_SuctionExtensions",
        sectionId: sections.CanalFeed.sectionId,
        seq: 13,
        label: "Suction Extensions",
        // roeGroup: "13",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: false,
        // roeInternationalFreightRate: 1.0000,
    },
    CanalFeed_FlangedTowerTop: {
        groupId: "CanalFeed_FlangedTowerTop",
        sectionId: sections.CanalFeed.sectionId,
        seq: 14,
        label: "Flanged Tower Top",
        // roeGroup: "14",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: false,
        // roeInternationalFreightRate: 1.0000,
    },
    CanalFeed_ReceiverJointTowerTop: {
        groupId: "CanalFeed_ReceiverJointTowerTop",
        sectionId: sections.CanalFeed.sectionId,
        seq: 15,
        label: "Receiver Joint Tower Top",
        // roeGroup: "15",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: false,
        // roeInternationalFreightRate: 1.0000,
    },
    KwikTow_KwikTowPivotTowerAssembly: {
        groupId: "KwikTow_KwikTowPivotTowerAssembly",
        sectionId: sections.KwikTow.sectionId,
        seq: 1,
        label: "Kwik Tow Pivot Tower Assembly",
        // roeGroup: "1",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    KwikTow_TireandWheelAssembly: {
        groupId: "KwikTow_TireandWheelAssembly",
        sectionId: sections.KwikTow.sectionId,
        seq: 2,
        label: "Tire and Wheel Assembly",
        // roeGroup: "2",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    KwikTow_ExtraPivotAnchor: {
        groupId: "KwikTow_ExtraPivotAnchor",
        sectionId: sections.KwikTow.sectionId,
        seq: 3,
        label: "Extra Pivot Anchor",
        // roeGroup: "3",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    KwikTow_MotorizedKwikTow: {
        groupId: "KwikTow_MotorizedKwikTow",
        sectionId: sections.KwikTow.sectionId,
        seq: 4,
        label: "Motorized Kwik Tow",
        // roeGroup: "4",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    KwikTow_PivotTowerTop: {
        groupId: "KwikTow_PivotTowerTop",
        sectionId: sections.KwikTow.sectionId,
        seq: 5,
        label: "Pivot Tower Top",
        // roeGroup: "5",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: false,
        // roeInternationalFreightRate: 1.0000,
    },
    KwikTow_TowRodKit: {
        groupId: "KwikTow_TowRodKit",
        sectionId: sections.KwikTow.sectionId,
        seq: 6,
        label: "Tow Rod Kit",
        // roeGroup: "6",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: false,
        // roeInternationalFreightRate: 1.0000,
    },
    KwikTow_HydraulicLift: {
        groupId: "KwikTow_HydraulicLift",
        sectionId: sections.KwikTow.sectionId,
        seq: 8,
        label: "Hydraulic Lift",
        // roeGroup: "8",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    HoseFeed_PowerTowerAssembly: {
        groupId: "HoseFeed_PowerTowerAssembly",
        sectionId: sections.HoseFeed.sectionId,
        seq: 1,
        label: "Power Tower Assembly",
        // roeGroup: "1",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    HoseFeed_SecondaryPowerTowerAssembly: {
        groupId: "HoseFeed_SecondaryPowerTowerAssembly",
        sectionId: sections.HoseFeed.sectionId,
        seq: 2,
        label: "Secondary Power Tower Assembly",
        // roeGroup: "2",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    HoseFeed_TireandWheelAssembly: {
        groupId: "HoseFeed_TireandWheelAssembly",
        sectionId: sections.HoseFeed.sectionId,
        seq: 3,
        label: "Tire and Wheel Assembly",
        // roeGroup: "3",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    HoseFeed_PowerTowerGears: {
        groupId: "HoseFeed_PowerTowerGears",
        sectionId: sections.HoseFeed.sectionId,
        seq: 4,
        label: "Power Tower Gears",
        // roeGroup: "4",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: false,
        // roeInternationalFreightRate: 1.0000,
    },
    HoseFeed_SecondaryPowerTowerGears: {
        groupId: "HoseFeed_SecondaryPowerTowerGears",
        sectionId: sections.HoseFeed.sectionId,
        seq: 5,
        label: "Secondary Power Tower Gears",
        // roeGroup: "5",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: false,
        // roeInternationalFreightRate: 1.0000,
    },
    HoseFeed_HoseandCouplers: {
        groupId: "HoseFeed_HoseandCouplers",
        sectionId: sections.HoseFeed.sectionId,
        seq: 7,
        label: "Hose and Couplers",
        // roeGroup: "7",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    HoseFeed_Hose: {
        groupId: "HoseFeed_Hose",
        sectionId: sections.HoseFeed.sectionId,
        seq: 8,
        label: "Hose",
        // roeGroup: "8",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    HoseFeed_FlangedTowerTop: {
        groupId: "HoseFeed_FlangedTowerTop",
        sectionId: sections.HoseFeed.sectionId,
        seq: 9,
        label: "Flanged Tower Top",
        // roeGroup: "9",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: false,
        // roeInternationalFreightRate: 1.0000,
    },
    HoseFeed_ReceiverJointTowerTop: {
        groupId: "HoseFeed_ReceiverJointTowerTop",
        sectionId: sections.HoseFeed.sectionId,
        seq: 10,
        label: "Receiver Joint Tower Top",
        // roeGroup: "10",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    HoseFeed_BaseInlets: {
        groupId: "HoseFeed_BaseInlets",
        sectionId: sections.HoseFeed.sectionId,
        seq: 11,
        label: "Base Inlets",
        // roeGroup: "11",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: false,
        // roeInternationalFreightRate: 1.0000,
    },
    HoseFeed_PowerTowerElbowWyeAssembly: {
        groupId: "HoseFeed_PowerTowerElbowWyeAssembly",
        sectionId: sections.HoseFeed.sectionId,
        seq: 12,
        label: "Power Tower Elbow/Wye Assembly",
        // roeGroup: "12",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    HoseFeed_TowKitAndCable: {
        groupId: "HoseFeed_TowKitAndCable",
        sectionId: sections.HoseFeed.sectionId,
        seq: 14,
        label: "Tow Kit & Cable",
        // roeGroup: "14",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: false,
        // roeInternationalFreightRate: 1.0000,
    },
    HoseFeed_PivotingAnchorKit: {
        groupId: "HoseFeed_PivotingAnchorKit",
        sectionId: sections.HoseFeed.sectionId,
        seq: 15,
        label: "Pivoting Anchor Kit",
        // roeGroup: "15",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: false,
        // roeInternationalFreightRate: 1.0000,
    },
    HoseFeed_PivotKit: {
        groupId: "HoseFeed_PivotKit",
        sectionId: sections.HoseFeed.sectionId,
        seq: 16,
        label: "Pivot Kit",
        // roeGroup: "16",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    HoseFeed_A100Pump: {
        groupId: "HoseFeed_A100Pump",
        sectionId: sections.HoseFeed.sectionId,
        seq: 17,
        label: "A100 Pump",
        // roeGroup: "17",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    HoseFeed_HydraulicLiftCylinders: {
        groupId: "HoseFeed_HydraulicLiftCylinders",
        sectionId: sections.HoseFeed.sectionId,
        seq: 18,
        label: "Hydraulic Lift Cylinders",
        // roeGroup: "18",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: false,
        // roeInternationalFreightRate: 1.0000,
    },
    HoseFeed_ElectricDragCord: {
        groupId: "HoseFeed_ElectricDragCord",
        sectionId: sections.HoseFeed.sectionId,
        seq: 19,
        label: "Electric Drag Cord",
        // roeGroup: "19",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    HoseFeed_ElectricDragCordHardwareKit: {
        groupId: "HoseFeed_ElectricDragCordHardwareKit",
        sectionId: sections.HoseFeed.sectionId,
        seq: 20,
        label: "Electric Drag Cord Hardware Kit",
        // roeGroup: "20",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: false,
        // roeInternationalFreightRate: 1.0000,
    },
    HoseFeed_HoseFeedWheelGear: {
        groupId: "HoseFeed_HoseFeedWheelGear",
        sectionId: sections.HoseFeed.sectionId,
        seq: 21,
        label: "Hose Feed Wheel Gear",
        // roeGroup: "21",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    HoseFeed_HardHoseAdapter: {
        groupId: "HoseFeed_HardHoseAdapter",
        sectionId: sections.HoseFeed.sectionId,
        seq: 22,
        label: "Hard Hose Adapter",
        // roeGroup: "22",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    HoseFeed_JunctionBoxWPlug: {
        groupId: "HoseFeed_JunctionBoxWPlug",
        sectionId: sections.HoseFeed.sectionId,
        seq: 23,
        label: "Junction Box W/Plug",
        // roeGroup: "23",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    HoseFeed_JunctionBoxWOPlug: {
        groupId: "HoseFeed_JunctionBoxWOPlug",
        sectionId: sections.HoseFeed.sectionId,
        seq: 24,
        label: "Junction Box W/O Plug",
        // roeGroup: "24",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    HoseFeed_HydraulicPump: {
        groupId: "HoseFeed_HydraulicPump",
        sectionId: sections.HoseFeed.sectionId,
        seq: 25,
        label: "Hydraulic Pump",
        // roeGroup: "25",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    HoseFeed_TowBarCenterLiftLateral: {
        groupId: "HoseFeed_TowBarCenterLiftLateral",
        sectionId: sections.HoseFeed.sectionId,
        seq: 26,
        label: "Tow Bar - Center Lift Lateral",
        // roeGroup: "26",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    HoseFeed_VFDPivotingLateralKit: {
        groupId: "HoseFeed_VFDPivotingLateralKit",
        sectionId: sections.HoseFeed.sectionId,
        seq: 27,
        label: "VFD Pivoting Lateral Kit",
        // roeGroup: "27",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    ControlPanel_MainControlPanel: {
        groupId: "ControlPanel_MainControlPanel",
        sectionId: sections.ControlPanel.sectionId,
        seq: 1,
        label: "Main Control Panel",
        // roeGroup: "1",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    ControlPanel_RAMSWeatherStation: {
        groupId: "ControlPanel_RAMSWeatherStation",
        sectionId: sections.ControlPanel.sectionId,
        seq: 2,
        label: "RAMS Weather Station",
        // roeGroup: "2",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    ControlPanel_Flowmeter: {
        groupId: "ControlPanel_Flowmeter",
        sectionId: sections.ControlPanel.sectionId,
        seq: 3,
        label: "Flowmeter",
        // roeGroup: "3",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    ControlPanel_RAMSPivotModem: {
        groupId: "ControlPanel_RAMSPivotModem",
        sectionId: sections.ControlPanel.sectionId,
        seq: 4,
        label: "RAMS Pivot Modem",
        // roeGroup: "4",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    ControlPanel_RAMSBaseStation: {
        groupId: "ControlPanel_RAMSBaseStation",
        sectionId: sections.ControlPanel.sectionId,
        seq: 5,
        label: "RAMS Base Station",
        // roeGroup: "5",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    ControlPanel_VoiceCard: {
        groupId: "ControlPanel_VoiceCard",
        sectionId: sections.ControlPanel.sectionId,
        seq: 6,
        label: "Voice Card",
        // roeGroup: "6",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    ControlPanel_ULCCertification: {
        groupId: "ControlPanel_ULCCertification",
        sectionId: sections.ControlPanel.sectionId,
        seq: 7,
        label: "UL/C Certification",
        // roeGroup: "7",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: false,
        // roeInternationalFreightRate: 1.0000,
    },
    ControlPanel_GroundFaultInterrupter: {
        groupId: "ControlPanel_GroundFaultInterrupter",
        sectionId: sections.ControlPanel.sectionId,
        seq: 8,
        label: "Ground Fault Interrupter",
        // roeGroup: "8",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    ControlPanel_MCPMaxiKit: {
        groupId: "ControlPanel_MCPMaxiKit",
        sectionId: sections.ControlPanel.sectionId,
        seq: 9,
        label: "MCP Maxi Kit",
        // roeGroup: "9",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: false,
        // roeInternationalFreightRate: 1.0000,
    },
    ControlPanel_GroundRodKit: {
        groupId: "ControlPanel_GroundRodKit",
        sectionId: sections.ControlPanel.sectionId,
        seq: 10,
        label: "Ground Rod Kit",
        // roeGroup: "10",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: false,
        // roeInternationalFreightRate: 1.0000,
    },
    ControlPanel_RAMSWeatherStationWindGauge: {
        groupId: "ControlPanel_RAMSWeatherStationWindGauge",
        sectionId: sections.ControlPanel.sectionId,
        seq: 11,
        label: "RAMS Weather Station - Wind Gauge",
        // roeGroup: "11",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    ControlPanel_RemoteControlPanel: {
        groupId: "ControlPanel_RemoteControlPanel",
        sectionId: sections.ControlPanel.sectionId,
        seq: 12,
        label: "Remote Control Panel",
        // roeGroup: "12",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    ControlPanel_GuidanceKit: {
        groupId: "ControlPanel_GuidanceKit",
        sectionId: sections.ControlPanel.sectionId,
        seq: 13,
        label: "Guidance Kit",
        // roeGroup: "13",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: false,
        // roeInternationalFreightRate: 1.0000,
    },
    ControlPanel_TemperatureControl: {
        groupId: "ControlPanel_TemperatureControl",
        sectionId: sections.ControlPanel.sectionId,
        seq: 14,
        label: "Temperature Control",
        // roeGroup: "14",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    ControlPanel_InjectorPumpReceptacle: {
        groupId: "ControlPanel_InjectorPumpReceptacle",
        sectionId: sections.ControlPanel.sectionId,
        seq: 15,
        label: "Injector Pump Receptacle",
        // roeGroup: "15",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    ControlPanel_PressureTransducer: {
        groupId: "ControlPanel_PressureTransducer",
        sectionId: sections.ControlPanel.sectionId,
        seq: 16,
        label: "Pressure Transducer",
        // roeGroup: "16",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    ControlPanel_VRIPanelKit: {
        groupId: "ControlPanel_VRIPanelKit",
        sectionId: sections.ControlPanel.sectionId,
        seq: 17,
        label: "VRI Panel Kit",
        // roeGroup: "17",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: false,
        // roeInternationalFreightRate: 1.0000,
    },
    ControlPanel_ShutdownValveKit: {
        groupId: "ControlPanel_ShutdownValveKit",
        sectionId: sections.ControlPanel.sectionId,
        seq: 18,
        label: "Shutdown Valve Kit",
        // roeGroup: "18",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: false,
        // roeInternationalFreightRate: 1.0000,
    },
    ControlPanel_GroundingBars: {
        groupId: "ControlPanel_GroundingBars",
        sectionId: sections.ControlPanel.sectionId,
        seq: 19,
        label: "Grounding Bars",
        // roeGroup: "19",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: false,
        // roeInternationalFreightRate: 1.0000,
    },
    ControlPanel_104PowerCord: {
        groupId: "ControlPanel_104PowerCord",
        sectionId: sections.ControlPanel.sectionId,
        seq: 20,
        label: "10/4 Power Cord",
        // roeGroup: "20",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: false,
        // roeInternationalFreightRate: 1.0000,
    },
    ControlPanel_142PowerCord: {
        groupId: "ControlPanel_142PowerCord",
        sectionId: sections.ControlPanel.sectionId,
        seq: 21,
        label: "14/2 Power Cord",
        // roeGroup: "21",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: false,
        // roeInternationalFreightRate: 1.0000,
    },
    ControlPanel_RAMSCallOutAlarm: {
        groupId: "ControlPanel_RAMSCallOutAlarm",
        sectionId: sections.ControlPanel.sectionId,
        seq: 22,
        label: "RAMS Call Out Alarm",
        // roeGroup: "22",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    ControlPanel_ResolverWire: {
        groupId: "ControlPanel_ResolverWire",
        sectionId: sections.ControlPanel.sectionId,
        seq: 23,
        label: "Resolver Wire",
        // roeGroup: "23",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: false,
        // roeInternationalFreightRate: 1.0000,
    },
    ControlPanel_CollectorRing: {
        groupId: "ControlPanel_CollectorRing",
        sectionId: sections.ControlPanel.sectionId,
        seq: 24,
        label: "Collector Ring",
        // roeGroup: "24",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    ControlPanel_LowVoltageSafety: {
        groupId: "ControlPanel_LowVoltageSafety",
        sectionId: sections.ControlPanel.sectionId,
        seq: 25,
        label: "Low Voltage Safety",
        // roeGroup: "25",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    ControlPanel_PigtailWire: {
        groupId: "ControlPanel_PigtailWire",
        sectionId: sections.ControlPanel.sectionId,
        seq: 26,
        label: "Pigtail Wire",
        // roeGroup: "26",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: false,
        // roeInternationalFreightRate: 1.0000,
    },
    ControlPanel_SwitchMount: {
        groupId: "ControlPanel_SwitchMount",
        sectionId: sections.ControlPanel.sectionId,
        seq: 27,
        label: "Switch Mount",
        // roeGroup: "27",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    ControlPanel_EndTowerControl: {
        groupId: "ControlPanel_EndTowerControl",
        sectionId: sections.ControlPanel.sectionId,
        seq: 28,
        label: "End Tower Control",
        // roeGroup: "28",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    ControlPanel_PivotAutoStopReverse: {
        groupId: "ControlPanel_PivotAutoStopReverse",
        sectionId: sections.ControlPanel.sectionId,
        seq: 29,
        label: "Pivot Auto Stop/Reverse",
        // roeGroup: "29",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    ControlPanel_PACTimer: {
        groupId: "ControlPanel_PACTimer",
        sectionId: sections.ControlPanel.sectionId,
        seq: 30,
        label: "PAC Timer",
        // roeGroup: "30",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    ControlPanel_EndGunShutoffOverride: {
        groupId: "ControlPanel_EndGunShutoffOverride",
        sectionId: sections.ControlPanel.sectionId,
        seq: 31,
        label: "End Gun Shutoff Override",
        // roeGroup: "31",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    ControlPanel_GeneratorStartRunControl: {
        groupId: "ControlPanel_GeneratorStartRunControl",
        sectionId: sections.ControlPanel.sectionId,
        seq: 32,
        label: "Generator Start/Run Control",
        // roeGroup: "32",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    ControlPanel_PowerAutoRestart: {
        groupId: "ControlPanel_PowerAutoRestart",
        sectionId: sections.ControlPanel.sectionId,
        seq: 33,
        label: "Power Auto Restart",
        // roeGroup: "33",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    ControlPanel_ChemAuxControl: {
        groupId: "ControlPanel_ChemAuxControl",
        sectionId: sections.ControlPanel.sectionId,
        seq: 34,
        label: "Chem/Aux Control",
        // roeGroup: "34",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    ControlPanel_TemperatureProbe: {
        groupId: "ControlPanel_TemperatureProbe",
        sectionId: sections.ControlPanel.sectionId,
        seq: 35,
        label: "Temperature Probe",
        // roeGroup: "35",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    ControlPanel_SACBuriedWireGuidanceTransmitter: {
        groupId: "ControlPanel_SACBuriedWireGuidanceTransmitter",
        sectionId: sections.ControlPanel.sectionId,
        seq: 36,
        label: "SAC Buried Wire Guidance Transmitter",
        // roeGroup: "36",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: false,
        // roeInternationalFreightRate: 1.0000,
    },
    ControlPanel_230VOption: {
        groupId: "ControlPanel_230VOption",
        sectionId: sections.ControlPanel.sectionId,
        seq: 37,
        label: "230V Option",
        // roeGroup: "37",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: false,
        // roeInternationalFreightRate: 1.0000,
    },
    ControlPanel_PanelFuse: {
        groupId: "ControlPanel_PanelFuse",
        sectionId: sections.ControlPanel.sectionId,
        seq: 38,
        label: "Panel Fuse",
        // roeGroup: "38",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: false,
        // roeInternationalFreightRate: 1.0000,
    },
    ControlPanel_LargeServiceWireAdapterKit: {
        groupId: "ControlPanel_LargeServiceWireAdapterKit",
        sectionId: sections.ControlPanel.sectionId,
        seq: 39,
        label: "Large Service Wire Adapter Kit",
        // roeGroup: "39",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: false,
        // roeInternationalFreightRate: 1.0000,
    },
    ControlPanel_GPSBaseSelectorSwith: {
        groupId: "ControlPanel_GPSBaseSelectorSwith",
        sectionId: sections.ControlPanel.sectionId,
        seq: 40,
        label: "GPS Base Selector Swith",
        // roeGroup: "40",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: false,
        // roeInternationalFreightRate: 1.0000,
    },
    ControlPanel_LightningArrestor: {
        groupId: "ControlPanel_LightningArrestor",
        sectionId: sections.ControlPanel.sectionId,
        seq: 41,
        label: "Lightning Arrestor",
        // roeGroup: "41",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    ControlPanel_SACPLCDiagSwitch: {
        groupId: "ControlPanel_SACPLCDiagSwitch",
        sectionId: sections.ControlPanel.sectionId,
        seq: 42,
        label: "SAC PLC Diag Switch",
        // roeGroup: "42",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: false,
        // roeInternationalFreightRate: 1.0000,
    },
    ControlPanel_ElectricLiftKit: {
        groupId: "ControlPanel_ElectricLiftKit",
        sectionId: sections.ControlPanel.sectionId,
        seq: 43,
        label: "Electric Lift Kit",
        // roeGroup: "43",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    ControlPanel_MotorizedKwikTowMCPOpt: {
        groupId: "ControlPanel_MotorizedKwikTowMCPOpt",
        sectionId: sections.ControlPanel.sectionId,
        seq: 44,
        label: "Motorized Kwik Tow MCP Opt",
        // roeGroup: "44",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: false,
        // roeInternationalFreightRate: 1.0000,
    },
    ControlPanel_GuidanceLampKit: {
        groupId: "ControlPanel_GuidanceLampKit",
        sectionId: sections.ControlPanel.sectionId,
        seq: 45,
        label: "Guidance Lamp Kit",
        // roeGroup: "45",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: false,
        // roeInternationalFreightRate: 1.0000,
    },
    ControlPanel_MainControlPanelManual: {
        groupId: "ControlPanel_MainControlPanelManual",
        sectionId: sections.ControlPanel.sectionId,
        seq: 46,
        label: "Main Control Panel Manual",
        // roeGroup: "46",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: false,
        // roeInternationalFreightRate: 1.0000,
    },
    ControlPanel_SubmersiblePumpKit: {
        groupId: "ControlPanel_SubmersiblePumpKit",
        sectionId: sections.ControlPanel.sectionId,
        seq: 47,
        label: "Submersible Pump Kit",
        // roeGroup: "47",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: false,
        // roeInternationalFreightRate: 1.0000,
    },
    ControlPanel_LinearPivotSwitch: {
        groupId: "ControlPanel_LinearPivotSwitch",
        sectionId: sections.ControlPanel.sectionId,
        seq: 48,
        label: "Linear Pivot Switch",
        // roeGroup: "48",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: false,
        // roeInternationalFreightRate: 1.0000,
    },
    ControlPanel_OntracSatellitePlus: {
        groupId: "ControlPanel_OntracSatellitePlus",
        sectionId: sections.ControlPanel.sectionId,
        seq: 49,
        label: "Ontrac Satellite Plus",
        // roeGroup: "49",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    ControlPanel_NonFuseCarrier: {
        groupId: "ControlPanel_NonFuseCarrier",
        sectionId: sections.ControlPanel.sectionId,
        seq: 50,
        label: "Non Fuse Carrier",
        // roeGroup: "50",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: false,
        // roeInternationalFreightRate: 1.0000,
    },
    ControlPanel_LowPressureSwitch: {
        groupId: "ControlPanel_LowPressureSwitch",
        sectionId: sections.ControlPanel.sectionId,
        seq: 51,
        label: "Low Pressure Switch",
        // roeGroup: "51",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    ControlPanel_PressureStart: {
        groupId: "ControlPanel_PressureStart",
        sectionId: sections.ControlPanel.sectionId,
        seq: 52,
        label: "Pressure Start",
        // roeGroup: "52",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    ControlPanel_CanalFeedSelfCleaningScreenBP: {
        groupId: "ControlPanel_CanalFeedSelfCleaningScreenBP",
        sectionId: sections.ControlPanel.sectionId,
        seq: 53,
        label: "Canal Feed Self Cleaning Screen BP",
        // roeGroup: "53",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: false,
        // roeInternationalFreightRate: 1.0000,
    },
    ControlPanel_Towable4PositionSwitch: {
        groupId: "ControlPanel_Towable4PositionSwitch",
        sectionId: sections.ControlPanel.sectionId,
        seq: 54,
        label: "Towable 4-Position Switch",
        // roeGroup: "54",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: false,
        // roeInternationalFreightRate: 1.0000,
    },
    ControlPanel_VFDPivotingLateral: {
        groupId: "ControlPanel_VFDPivotingLateral",
        sectionId: sections.ControlPanel.sectionId,
        seq: 55,
        label: "VFD/Pivoting Lateral",
        // roeGroup: "55",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: false,
        // roeInternationalFreightRate: 1.0000,
    },
    ControlPanel_RC10Components: {
        groupId: "ControlPanel_RC10Components",
        sectionId: sections.ControlPanel.sectionId,
        seq: 56,
        label: "RC10 Components",
        // roeGroup: "56",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    ControlPanel_WeepHolePlugs: {
        groupId: "ControlPanel_WeepHolePlugs",
        sectionId: sections.ControlPanel.sectionId,
        seq: 57,
        label: "Weep Hole Plugs",
        // roeGroup: "57",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    SpanAssemblies_SpanAssembly: {
        groupId: "SpanAssemblies_SpanAssembly",
        sectionId: sections.SpanAssemblies.sectionId,
        seq: 1,
        label: "Span Assembly",
        // roeGroup: "1",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    SpanAssemblies_SpanJointAssembly: {
        groupId: "SpanAssemblies_SpanJointAssembly",
        sectionId: sections.SpanAssemblies.sectionId,
        seq: 2,
        label: "Span Joint Assembly",
        // roeGroup: "2",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    SpanAssemblies_SpanExtensionAssembly: {
        groupId: "SpanAssemblies_SpanExtensionAssembly",
        sectionId: sections.SpanAssemblies.sectionId,
        seq: 3,
        label: "Span Extension Assembly",
        // roeGroup: "3",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    SpanAssemblies_LPDrainKit: {
        groupId: "SpanAssemblies_LPDrainKit",
        sectionId: sections.SpanAssemblies.sectionId,
        seq: 4,
        label: "LP Drain Kit",
        // roeGroup: "4",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: false,
        // roeInternationalFreightRate: 1.0000,
    },
    SpanAssemblies_SMMWireAlignmentKit: {
        groupId: "SpanAssemblies_SMMWireAlignmentKit",
        sectionId: sections.SpanAssemblies.sectionId,
        seq: 5,
        label: "SMM Wire Alignment Kit",
        // roeGroup: "5",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: false,
        // roeInternationalFreightRate: 1.0000,
    },
    SpanAssemblies_LongSystemAlignment: {
        groupId: "SpanAssemblies_LongSystemAlignment",
        sectionId: sections.SpanAssemblies.sectionId,
        seq: 7,
        label: "Long System Alignment",
        // roeGroup: "7",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    SpanAssemblies_FlangeBracket: {
        groupId: "SpanAssemblies_FlangeBracket",
        sectionId: sections.SpanAssemblies.sectionId,
        seq: 8,
        label: "Flange Bracket",
        // roeGroup: "8",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: false,
        // roeInternationalFreightRate: 1.0000,
    },
    SpanAssemblies_AlumIVLongitudinalSupport: {
        groupId: "SpanAssemblies_AlumIVLongitudinalSupport",
        sectionId: sections.SpanAssemblies.sectionId,
        seq: 9,
        label: "Alum IV Longitudinal Support",
        // roeGroup: "9",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: false,
        // roeInternationalFreightRate: 1.0000,
    },
    SpanAssemblies_TowCable: {
        groupId: "SpanAssemblies_TowCable",
        sectionId: sections.SpanAssemblies.sectionId,
        seq: 10,
        label: "Tow Cable",
        // roeGroup: "10",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: false,
        // roeInternationalFreightRate: 1.0000,
    },
    SpanAssemblies_SpanDisconnectWinch: {
        groupId: "SpanAssemblies_SpanDisconnectWinch",
        sectionId: sections.SpanAssemblies.sectionId,
        seq: 11,
        label: "Span Disconnect Winch",
        // roeGroup: "11",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    SpanAssemblies_TowRodExtension: {
        groupId: "SpanAssemblies_TowRodExtension",
        sectionId: sections.SpanAssemblies.sectionId,
        seq: 12,
        label: "Tow Rod Extension",
        // roeGroup: "12",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: false,
        // roeInternationalFreightRate: 1.0000,
    },
    SpanAssemblies_SpanStabilizers: {
        groupId: "SpanAssemblies_SpanStabilizers",
        sectionId: sections.SpanAssemblies.sectionId,
        seq: 13,
        label: "Span Stabilizers",
        // roeGroup: "13",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    SpanAssemblies_ConduitClips: {
        groupId: "SpanAssemblies_ConduitClips",
        sectionId: sections.SpanAssemblies.sectionId,
        seq: 17,
        label: "Conduit Clips",
        // roeGroup: "17",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: false,
        // roeInternationalFreightRate: 1.0000,
    },
    SpanAssemblies_SpanCableAssembly: {
        groupId: "SpanAssemblies_SpanCableAssembly",
        sectionId: sections.SpanAssemblies.sectionId,
        seq: 18,
        label: "Span Cable Assembly",
        // roeGroup: "18",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: false,
        // roeInternationalFreightRate: 1.0000,
    },
    EndofSystem_EndBoomAssembly: {
        groupId: "EndofSystem_EndBoomAssembly",
        sectionId: sections.EndofSystem.sectionId,
        seq: 1,
        label: "End Boom Assembly",
        // roeGroup: "1",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    EndofSystem_EndGunAssembly: {
        groupId: "EndofSystem_EndGunAssembly",
        sectionId: sections.EndofSystem.sectionId,
        seq: 2,
        label: "End Gun Assembly",
        // roeGroup: "2",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    EndofSystem_DiffuserAssembly: {
        groupId: "EndofSystem_DiffuserAssembly",
        sectionId: sections.EndofSystem.sectionId,
        seq: 3,
        label: "Diffuser Assembly",
        // roeGroup: "3",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: false,
        // roeInternationalFreightRate: 1.0000,
    },
    EndofSystem_EndGunValve: {
        groupId: "EndofSystem_EndGunValve",
        sectionId: sections.EndofSystem.sectionId,
        seq: 4,
        label: "End Gun Valve",
        // roeGroup: "4",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    EndofSystem_EndGunSwitch: {
        groupId: "EndofSystem_EndGunSwitch",
        sectionId: sections.EndofSystem.sectionId,
        seq: 5,
        label: "End Gun Switch",
        // roeGroup: "5",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    EndofSystem_EndGunRiserReducer: {
        groupId: "EndofSystem_EndGunRiserReducer",
        sectionId: sections.EndofSystem.sectionId,
        seq: 6,
        label: "End Gun Riser/Reducer",
        // roeGroup: "6",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: false,
        // roeInternationalFreightRate: 1.0000,
    },
    EndofSystem_EndGunWire: {
        groupId: "EndofSystem_EndGunWire",
        sectionId: sections.EndofSystem.sectionId,
        seq: 8,
        label: "End Gun Wire",
        // roeGroup: "8",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: false,
        // roeInternationalFreightRate: 1.0000,
    },
    EndofSystem_BoosterPump: {
        groupId: "EndofSystem_BoosterPump",
        sectionId: sections.EndofSystem.sectionId,
        seq: 9,
        label: "Booster Pump",
        // roeGroup: "9",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    EndofSystem_EndSign: {
        groupId: "EndofSystem_EndSign",
        sectionId: sections.EndofSystem.sectionId,
        seq: 10,
        label: "End Sign",
        // roeGroup: "10",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: false,
        // roeInternationalFreightRate: 1.0000,
    },
    EndofSystem_BoosterPumpControlBox: {
        groupId: "EndofSystem_BoosterPumpControlBox",
        sectionId: sections.EndofSystem.sectionId,
        seq: 11,
        label: "Booster Pump Control Box",
        // roeGroup: "11",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: false,
        // roeInternationalFreightRate: 1.0000,
    },
    EndofSystem_Strainer: {
        groupId: "EndofSystem_Strainer",
        sectionId: sections.EndofSystem.sectionId,
        seq: 12,
        label: "Strainer",
        // roeGroup: "12",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    EndofSystem_VerticalBoosterPumpMount: {
        groupId: "EndofSystem_VerticalBoosterPumpMount",
        sectionId: sections.EndofSystem.sectionId,
        seq: 13,
        label: "Vertical Booster Pump Mount",
        // roeGroup: "13",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: false,
        // roeInternationalFreightRate: 1.0000,
    },
    EndofSystem_EndLight: {
        groupId: "EndofSystem_EndLight",
        sectionId: sections.EndofSystem.sectionId,
        seq: 14,
        label: "End Light",
        // roeGroup: "14",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    EndofSystem_StrobeLightBulb: {
        groupId: "EndofSystem_StrobeLightBulb",
        sectionId: sections.EndofSystem.sectionId,
        seq: 15,
        label: "Strobe Light Bulb",
        // roeGroup: "15",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    EndofSystem_EndBoomConduitClips: {
        groupId: "EndofSystem_EndBoomConduitClips",
        sectionId: sections.EndofSystem.sectionId,
        seq: 16,
        label: "End Boom Conduit Clips",
        // roeGroup: "16",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: false,
        // roeInternationalFreightRate: 1.0000,
    },
    EndofSystem_PowerTowerBoosterWire: {
        groupId: "EndofSystem_PowerTowerBoosterWire",
        sectionId: sections.EndofSystem.sectionId,
        seq: 17,
        label: "Power Tower Booster Wire",
        // roeGroup: "17",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: false,
        // roeInternationalFreightRate: 1.0000,
    },
    MaxigatorGuidance_FurrowGuidance: {
        groupId: "MaxigatorGuidance_FurrowGuidance",
        sectionId: sections.MaxigatorGuidance.sectionId,
        seq: 1,
        label: "Furrow Guidance",
        // roeGroup: "1",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    MaxigatorGuidance_WinchFurrowGuidance: {
        groupId: "MaxigatorGuidance_WinchFurrowGuidance",
        sectionId: sections.MaxigatorGuidance.sectionId,
        seq: 3,
        label: "Winch - Furrow Guidance",
        // roeGroup: "3",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: false,
        // roeInternationalFreightRate: 1.0000,
    },
    MaxigatorGuidance_CableGuidance: {
        groupId: "MaxigatorGuidance_CableGuidance",
        sectionId: sections.MaxigatorGuidance.sectionId,
        seq: 4,
        label: "Cable Guidance",
        // roeGroup: "4",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    MaxigatorGuidance_CablePostOptionCableGuidance: {
        groupId: "MaxigatorGuidance_CablePostOptionCableGuidance",
        sectionId: sections.MaxigatorGuidance.sectionId,
        seq: 5,
        label: "Cable Post Option - Cable Guidance",
        // roeGroup: "5",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: false,
        // roeInternationalFreightRate: 1.0000,
    },
    MaxigatorGuidance_GuideCable: {
        groupId: "MaxigatorGuidance_GuideCable",
        sectionId: sections.MaxigatorGuidance.sectionId,
        seq: 6,
        label: "Guide Cable",
        // roeGroup: "6",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: false,
        // roeInternationalFreightRate: 1.0000,
    },
    MaxigatorGuidance_ExtraCableStopsCableGuidance: {
        groupId: "MaxigatorGuidance_ExtraCableStopsCableGuidance",
        sectionId: sections.MaxigatorGuidance.sectionId,
        seq: 7,
        label: "Extra Cable Stops - Cable Guidance",
        // roeGroup: "7",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: false,
        // roeInternationalFreightRate: 1.0000,
    },
    MaxigatorGuidance_BuriedWireGuidance: {
        groupId: "MaxigatorGuidance_BuriedWireGuidance",
        sectionId: sections.MaxigatorGuidance.sectionId,
        seq: 8,
        label: "Buried Wire Guidance",
        // roeGroup: "8",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    MaxigatorGuidance_BuriedWire: {
        groupId: "MaxigatorGuidance_BuriedWire",
        sectionId: sections.MaxigatorGuidance.sectionId,
        seq: 9,
        label: "Buried Wire",
        // roeGroup: "9",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    MaxigatorGuidance_FenceLineGuidance: {
        groupId: "MaxigatorGuidance_FenceLineGuidance",
        sectionId: sections.MaxigatorGuidance.sectionId,
        seq: 10,
        label: "Fence-Line Guidance",
        // roeGroup: "10",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    MaxigatorGuidance_NavigatorGPS: {
        groupId: "MaxigatorGuidance_NavigatorGPS",
        sectionId: sections.MaxigatorGuidance.sectionId,
        seq: 11,
        label: "Navigator GPS",
        // roeGroup: "11",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    MaxigatorGuidance_FurrowGuidanceAdapter: {
        groupId: "MaxigatorGuidance_FurrowGuidanceAdapter",
        sectionId: sections.MaxigatorGuidance.sectionId,
        seq: 12,
        label: "Furrow Guidance Adapter",
        // roeGroup: "12",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: false,
        // roeInternationalFreightRate: 1.0000,
    },
    TowerAssemblies_TowerAssembly: {
        groupId: "TowerAssemblies_TowerAssembly",
        sectionId: sections.TowerAssemblies.sectionId,
        seq: 1,
        label: "Tower Assembly",
        // roeGroup: "1",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    TowerAssemblies_DriveShield: {
        groupId: "TowerAssemblies_DriveShield",
        sectionId: sections.TowerAssemblies.sectionId,
        seq: 2,
        label: "Drive Shield",
        // roeGroup: "2",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: false,
        // roeInternationalFreightRate: 1.0000,
    },
    TowerAssemblies_TireandWheelAssembly: {
        groupId: "TowerAssemblies_TireandWheelAssembly",
        sectionId: sections.TowerAssemblies.sectionId,
        seq: 3,
        label: "Tire and Wheel Assembly",
        // roeGroup: "3",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    TowerAssemblies_TowerAutoReverse: {
        groupId: "TowerAssemblies_TowerAutoReverse",
        sectionId: sections.TowerAssemblies.sectionId,
        seq: 4,
        label: "Tower Auto Reverse",
        // roeGroup: "4",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    TowerAssemblies_WheelGear: {
        groupId: "TowerAssemblies_WheelGear",
        sectionId: sections.TowerAssemblies.sectionId,
        seq: 5,
        label: "Wheel Gear",
        // roeGroup: "5",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    TowerAssemblies_TowHub: {
        groupId: "TowerAssemblies_TowHub",
        sectionId: sections.TowerAssemblies.sectionId,
        seq: 6,
        label: "Tow Hub",
        // roeGroup: "6",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    TowerAssemblies_CouplerAssembly: {
        groupId: "TowerAssemblies_CouplerAssembly",
        sectionId: sections.TowerAssemblies.sectionId,
        seq: 7,
        label: "Coupler Assembly",
        // roeGroup: "7",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: false,
        // roeInternationalFreightRate: 1.0000,
    },
    TowerAssemblies_CenterGear: {
        groupId: "TowerAssemblies_CenterGear",
        sectionId: sections.TowerAssemblies.sectionId,
        seq: 8,
        label: "Center Gear",
        // roeGroup: "8",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: false,
        // roeInternationalFreightRate: 1.0000,
    },
    TowerAssemblies_TowerAutoStop: {
        groupId: "TowerAssemblies_TowerAutoStop",
        sectionId: sections.TowerAssemblies.sectionId,
        seq: 9,
        label: "Tower Auto Stop",
        // roeGroup: "9",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    TowerAssemblies_TowerBoxes: {
        groupId: "TowerAssemblies_TowerBoxes",
        sectionId: sections.TowerAssemblies.sectionId,
        seq: 10,
        label: "Tower Boxes",
        // roeGroup: "10",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: false,
        // roeInternationalFreightRate: 1.0000,
    },
    TowerAssemblies_EDMPMotor: {
        groupId: "TowerAssemblies_EDMPMotor",
        sectionId: sections.TowerAssemblies.sectionId,
        seq: 11,
        label: "EDMP Motor",
        // roeGroup: "11",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    TowerAssemblies_CenterDrive: {
        groupId: "TowerAssemblies_CenterDrive",
        sectionId: sections.TowerAssemblies.sectionId,
        seq: 12,
        label: "Center Drive",
        // roeGroup: "12",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    TowerAssemblies_Agritrac: {
        groupId: "TowerAssemblies_Agritrac",
        sectionId: sections.TowerAssemblies.sectionId,
        seq: 13,
        label: "Agritrac",
        // roeGroup: "13",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    TowerAssemblies_BuckAndBoostTransformer: {
        groupId: "TowerAssemblies_BuckAndBoostTransformer",
        sectionId: sections.TowerAssemblies.sectionId,
        seq: 14,
        label: "Buck & Boost Transformer",
        // roeGroup: "14",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    TowerAssemblies_FuseKitAssembly: {
        groupId: "TowerAssemblies_FuseKitAssembly",
        sectionId: sections.TowerAssemblies.sectionId,
        seq: 15,
        label: "Fuse Kit Assembly",
        // roeGroup: "15",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: false,
        // roeInternationalFreightRate: 1.0000,
    },
    TowerAssemblies_StiffenerSetsforLateralAluminumSpan: {
        groupId: "TowerAssemblies_StiffenerSetsforLateralAluminumSpan",
        sectionId: sections.TowerAssemblies.sectionId,
        seq: 16,
        label: "Stiffener Sets for Lateral/Aluminum Span",
        // roeGroup: "16",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: false,
        // roeInternationalFreightRate: 1.0000,
    },
    TowerAssemblies_ControlBoxWireAlignment: {
        groupId: "TowerAssemblies_ControlBoxWireAlignment",
        sectionId: sections.TowerAssemblies.sectionId,
        seq: 17,
        label: "Control Box Wire Alignment",
        // roeGroup: "17",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: false,
        // roeInternationalFreightRate: 1.0000,
    },
    TowerAssemblies_ControlBoxWireAlignmentCableOption: {
        groupId: "TowerAssemblies_ControlBoxWireAlignmentCableOption",
        sectionId: sections.TowerAssemblies.sectionId,
        seq: 18,
        label: "Control Box Wire Alignment Cable Option",
        // roeGroup: "18",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    TowerAssemblies_BoxMountOptWireAlignment: {
        groupId: "TowerAssemblies_BoxMountOptWireAlignment",
        sectionId: sections.TowerAssemblies.sectionId,
        seq: 19,
        label: "Box Mount Opt-Wire Alignment",
        // roeGroup: "19",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: false,
        // roeInternationalFreightRate: 1.0000,
    },
    TowerAssemblies_StiffenerStabilizers: {
        groupId: "TowerAssemblies_StiffenerStabilizers",
        sectionId: sections.TowerAssemblies.sectionId,
        seq: 20,
        label: "Stiffener - Stabilizers",
        // roeGroup: "20",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: false,
        // roeInternationalFreightRate: 1.0000,
    },
    TowerAssemblies_StiffenerTrusstoPipe: {
        groupId: "TowerAssemblies_StiffenerTrusstoPipe",
        sectionId: sections.TowerAssemblies.sectionId,
        seq: 21,
        label: "Stiffener - Truss to Pipe",
        // roeGroup: "21",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: false,
        // roeInternationalFreightRate: 1.0000,
    },
    TowerAssemblies_TowerCrossBrace: {
        groupId: "TowerAssemblies_TowerCrossBrace",
        sectionId: sections.TowerAssemblies.sectionId,
        seq: 22,
        label: "Tower Cross Brace",
        // roeGroup: "22",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: false,
        // roeInternationalFreightRate: 1.0000,
    },
    TowerAssemblies_CattleGuard: {
        groupId: "TowerAssemblies_CattleGuard",
        sectionId: sections.TowerAssemblies.sectionId,
        seq: 23,
        label: "Cattle Guard",
        // roeGroup: "23",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    TowerAssemblies_TowerBaseAccessStep: {
        groupId: "TowerAssemblies_TowerBaseAccessStep",
        sectionId: sections.TowerAssemblies.sectionId,
        seq: 24,
        label: "Tower Base Access Step",
        // roeGroup: "24",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    SwingArmCorner_MainControlPanelRetroKit: {
        groupId: "SwingArmCorner_MainControlPanelRetroKit",
        sectionId: sections.SwingArmCorner.sectionId,
        seq: 1,
        label: "Main Control Panel Retro Kit",
        // roeGroup: "1",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: false,
        // roeInternationalFreightRate: 1.0000,
    },
    SwingArmCorner_SwingArmCornerRetroKit: {
        groupId: "SwingArmCorner_SwingArmCornerRetroKit",
        sectionId: sections.SwingArmCorner.sectionId,
        seq: 2,
        label: "Swing Arm Corner Retro Kit",
        // roeGroup: "2",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    SwingArmCorner_SpanCableAssembly: {
        groupId: "SwingArmCorner_SpanCableAssembly",
        sectionId: sections.SwingArmCorner.sectionId,
        seq: 3,
        label: "Span Cable Assembly",
        // roeGroup: "3",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: false,
        // roeInternationalFreightRate: 1.0000,
    },
    SwingArmCorner_HingeTowerTowerBox: {
        groupId: "SwingArmCorner_HingeTowerTowerBox",
        sectionId: sections.SwingArmCorner.sectionId,
        seq: 4,
        label: "Hinge Tower Tower Box",
        // roeGroup: "4",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: false,
        // roeInternationalFreightRate: 1.0000,
    },
    SwingArmCorner_CenterGearAssembly: {
        groupId: "SwingArmCorner_CenterGearAssembly",
        sectionId: sections.SwingArmCorner.sectionId,
        seq: 5,
        label: "Center Gear Assembly",
        // roeGroup: "5",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    SwingArmCorner_SwingArmCornerAssembly: {
        groupId: "SwingArmCorner_SwingArmCornerAssembly",
        sectionId: sections.SwingArmCorner.sectionId,
        seq: 6,
        label: "Swing Arm Corner Assembly",
        // roeGroup: "6",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    SwingArmCorner_SwingArmTowerBox: {
        groupId: "SwingArmCorner_SwingArmTowerBox",
        sectionId: sections.SwingArmCorner.sectionId,
        seq: 7,
        label: "Swing Arm Tower Box",
        // roeGroup: "7",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: false,
        // roeInternationalFreightRate: 1.0000,
    },
    SwingArmCorner_TireandWheelAssembly: {
        groupId: "SwingArmCorner_TireandWheelAssembly",
        sectionId: sections.SwingArmCorner.sectionId,
        seq: 8,
        label: "Tire and Wheel Assembly",
        // roeGroup: "8",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    SwingArmCorner_AirCompressor: {
        groupId: "SwingArmCorner_AirCompressor",
        sectionId: sections.SwingArmCorner.sectionId,
        seq: 9,
        label: "Air Compressor",
        // roeGroup: "9",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    SwingArmCorner_ControlLineBoosterPump: {
        groupId: "SwingArmCorner_ControlLineBoosterPump",
        sectionId: sections.SwingArmCorner.sectionId,
        seq: 10,
        label: "Control Line Booster Pump",
        // roeGroup: "10",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    SwingArmCorner_ControlLineBoosterPumpESP: {
        groupId: "SwingArmCorner_ControlLineBoosterPumpESP",
        sectionId: sections.SwingArmCorner.sectionId,
        seq: 11,
        label: "Control Line Booster Pump ESP",
        // roeGroup: "11",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    SwingArmCorner_PerimeterWire: {
        groupId: "SwingArmCorner_PerimeterWire",
        sectionId: sections.SwingArmCorner.sectionId,
        seq: 12,
        label: "Perimeter Wire",
        // roeGroup: "12",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    SwingArmCorner_WheelGearSACOption: {
        groupId: "SwingArmCorner_WheelGearSACOption",
        sectionId: sections.SwingArmCorner.sectionId,
        seq: 13,
        label: "Wheel Gear SAC Option",
        // roeGroup: "13",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: false,
        // roeInternationalFreightRate: 1.0000,
    },
    SwingArmCorner_RadiusWire: {
        groupId: "SwingArmCorner_RadiusWire",
        sectionId: sections.SwingArmCorner.sectionId,
        seq: 14,
        label: "Radius Wire",
        // roeGroup: "14",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: false,
        // roeInternationalFreightRate: 1.0000,
    },
    SwingArmCorner_SwingArmSpanCable: {
        groupId: "SwingArmCorner_SwingArmSpanCable",
        sectionId: sections.SwingArmCorner.sectionId,
        seq: 15,
        label: "Swing Arm Span Cable",
        // roeGroup: "15",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: false,
        // roeInternationalFreightRate: 1.0000,
    },
    SwingArmCorner_SprinklerControlHarness: {
        groupId: "SwingArmCorner_SprinklerControlHarness",
        sectionId: sections.SwingArmCorner.sectionId,
        seq: 16,
        label: "Sprinkler Control Harness",
        // roeGroup: "16",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: false,
        // roeInternationalFreightRate: 1.0000,
    },
    SwingArmCorner_AgriTRACs: {
        groupId: "SwingArmCorner_AgriTRACs",
        sectionId: sections.SwingArmCorner.sectionId,
        seq: 20,
        label: "Agri-TRACs",
        // roeGroup: "20",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    SwingArmCorner_SACFuseKit: {
        groupId: "SwingArmCorner_SACFuseKit",
        sectionId: sections.SwingArmCorner.sectionId,
        seq: 21,
        label: "SAC Fuse Kit",
        // roeGroup: "21",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: false,
        // roeInternationalFreightRate: 1.0000,
    },
    SwingArmCorner_ConduitClips: {
        groupId: "SwingArmCorner_ConduitClips",
        sectionId: sections.SwingArmCorner.sectionId,
        seq: 22,
        label: "Conduit Clips",
        // roeGroup: "22",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: false,
        // roeInternationalFreightRate: 1.0000,
    },
    SwingArmCorner_SACSBoxValveKit: {
        groupId: "SwingArmCorner_SACSBoxValveKit",
        sectionId: sections.SwingArmCorner.sectionId,
        seq: 23,
        label: "SAC S-Box Valve Kit",
        // roeGroup: "23",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    SwingArmCorner_SACSBoxHardwareKit: {
        groupId: "SwingArmCorner_SACSBoxHardwareKit",
        sectionId: sections.SwingArmCorner.sectionId,
        seq: 24,
        label: "SAC S-Box Hardware Kit",
        // roeGroup: "24",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: false,
        // roeInternationalFreightRate: 1.0000,
    },
    SwingArmCorner_SACSBoxGuidanceOption: {
        groupId: "SwingArmCorner_SACSBoxGuidanceOption",
        sectionId: sections.SwingArmCorner.sectionId,
        seq: 25,
        label: "SAC S-Box Guidance Option",
        // roeGroup: "25",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: false,
        // roeInternationalFreightRate: 1.0000,
    },
    SwingArmCorner_SACSBoxLeadingSACKit: {
        groupId: "SwingArmCorner_SACSBoxLeadingSACKit",
        sectionId: sections.SwingArmCorner.sectionId,
        seq: 26,
        label: "SAC S-Box Leading SAC Kit",
        // roeGroup: "26",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: false,
        // roeInternationalFreightRate: 1.0000,
    },
    SwingArmCorner_SACGuidanceAntenna: {
        groupId: "SwingArmCorner_SACGuidanceAntenna",
        sectionId: sections.SwingArmCorner.sectionId,
        seq: 27,
        label: "SAC Guidance Antenna",
        // roeGroup: "27",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    SwingArmCorner_SACGuidanceBase: {
        groupId: "SwingArmCorner_SACGuidanceBase",
        sectionId: sections.SwingArmCorner.sectionId,
        seq: 28,
        label: "SAC Guidance Base",
        // roeGroup: "28",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    SwingArmCorner_ExtraGPSDataCable: {
        groupId: "SwingArmCorner_ExtraGPSDataCable",
        sectionId: sections.SwingArmCorner.sectionId,
        seq: 29,
        label: "Extra GPS Data Cable",
        // roeGroup: "29",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    SwingArmCorner_SteeringMotorCover: {
        groupId: "SwingArmCorner_SteeringMotorCover",
        sectionId: sections.SwingArmCorner.sectionId,
        seq: 30,
        label: "Steering Motor Cover",
        // roeGroup: "30",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: false,
        // roeInternationalFreightRate: 1.0000,
    },
    OptionsandAccessories_ReinkeSignMount: {
        groupId: "OptionsandAccessories_ReinkeSignMount",
        sectionId: sections.OptionsandAccessories.sectionId,
        seq: 1,
        label: "Reinke Sign Mount",
        // roeGroup: "1",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    OptionsandAccessories_MovableBarricades: {
        groupId: "OptionsandAccessories_MovableBarricades",
        sectionId: sections.OptionsandAccessories.sectionId,
        seq: 7,
        label: "Movable Barricades",
        // roeGroup: "7",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    OptionsandAccessories_SafetyValve: {
        groupId: "OptionsandAccessories_SafetyValve",
        sectionId: sections.OptionsandAccessories.sectionId,
        seq: 9,
        label: "Safety Valve",
        // roeGroup: "9",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    OptionsandAccessories_JackKits: {
        groupId: "OptionsandAccessories_JackKits",
        sectionId: sections.OptionsandAccessories.sectionId,
        seq: 10,
        label: "Jack Kits",
        // roeGroup: "10",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    OptionsandAccessories_JackFootKit: {
        groupId: "OptionsandAccessories_JackFootKit",
        sectionId: sections.OptionsandAccessories.sectionId,
        seq: 11,
        label: "Jack Foot Kit",
        // roeGroup: "11",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: false,
        // roeInternationalFreightRate: 1.0000,
    },
    OptionsandAccessories_SpareBoltPackage: {
        groupId: "OptionsandAccessories_SpareBoltPackage",
        sectionId: sections.OptionsandAccessories.sectionId,
        seq: 12,
        label: "Spare Bolt Package",
        // roeGroup: "12",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: false,
        // roeInternationalFreightRate: 1.0000,
    },
    OptionsandAccessories_StationaryBarricades: {
        groupId: "OptionsandAccessories_StationaryBarricades",
        sectionId: sections.OptionsandAccessories.sectionId,
        seq: 13,
        label: "Stationary Barricades",
        // roeGroup: "13",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    OptionsandAccessories_ElectricTowKits: {
        groupId: "OptionsandAccessories_ElectricTowKits",
        sectionId: sections.OptionsandAccessories.sectionId,
        seq: 14,
        label: "Electric Tow Kits",
        // roeGroup: "14",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: false,
        // roeInternationalFreightRate: 1.0000,
    },
    OptionsandAccessories_ElectricTowCable: {
        groupId: "OptionsandAccessories_ElectricTowCable",
        sectionId: sections.OptionsandAccessories.sectionId,
        seq: 15,
        label: "Electric Tow Cable",
        // roeGroup: "15",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: false,
        // roeInternationalFreightRate: 1.0000,
    },
    OptionsandAccessories_GeneratorAssembly: {
        groupId: "OptionsandAccessories_GeneratorAssembly",
        sectionId: sections.OptionsandAccessories.sectionId,
        seq: 16,
        label: "Generator Assembly",
        // roeGroup: "16",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    OptionsandAccessories_DieselEngineGeneratorSets: {
        groupId: "OptionsandAccessories_DieselEngineGeneratorSets",
        sectionId: sections.OptionsandAccessories.sectionId,
        seq: 17,
        label: "Diesel Engine/Generator Sets",
        // roeGroup: "17",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    OptionsandAccessories_GeneratorSlideRail: {
        groupId: "OptionsandAccessories_GeneratorSlideRail",
        sectionId: sections.OptionsandAccessories.sectionId,
        seq: 18,
        label: "Generator Slide Rail",
        // roeGroup: "18",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: false,
        // roeInternationalFreightRate: 1.0000,
    },
    OptionsandAccessories_FuelTank: {
        groupId: "OptionsandAccessories_FuelTank",
        sectionId: sections.OptionsandAccessories.sectionId,
        seq: 19,
        label: "Fuel Tank",
        // roeGroup: "19",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    OptionsandAccessories_PivotLight: {
        groupId: "OptionsandAccessories_PivotLight",
        sectionId: sections.OptionsandAccessories.sectionId,
        seq: 20,
        label: "Pivot Light",
        // roeGroup: "20",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    OptionsandAccessories_FlushValve: {
        groupId: "OptionsandAccessories_FlushValve",
        sectionId: sections.OptionsandAccessories.sectionId,
        seq: 22,
        label: "Flush Valve",
        // roeGroup: "22",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    OptionsandAccessories_StrobeLight: {
        groupId: "OptionsandAccessories_StrobeLight",
        sectionId: sections.OptionsandAccessories.sectionId,
        seq: 23,
        label: "Strobe Light",
        // roeGroup: "23",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    OptionsandAccessories_WarrantyPacket: {
        groupId: "OptionsandAccessories_WarrantyPacket",
        sectionId: sections.OptionsandAccessories.sectionId,
        seq: 25,
        label: "Warranty Packet",
        // roeGroup: "25",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: false,
        // roeInternationalFreightRate: 1.0000,
    },
    OptionsandAccessories_IntakeDrainValve: {
        groupId: "OptionsandAccessories_IntakeDrainValve",
        sectionId: sections.OptionsandAccessories.sectionId,
        seq: 26,
        label: "Intake Drain Valve",
        // roeGroup: "26",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    OptionsandAccessories_AutoReverseCart: {
        groupId: "OptionsandAccessories_AutoReverseCart",
        sectionId: sections.OptionsandAccessories.sectionId,
        seq: 27,
        label: "Auto Reverse Cart",
        // roeGroup: "27",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    OptionsandAccessories_PivotingLateralOptions: {
        groupId: "OptionsandAccessories_PivotingLateralOptions",
        sectionId: sections.OptionsandAccessories.sectionId,
        seq: 28,
        label: "Pivoting Lateral Options",
        // roeGroup: "28",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: false,
        // roeInternationalFreightRate: 1.0000,
    },
    Sprinklers_RigidDrops: {
        groupId: "Sprinklers_RigidDrops",
        sectionId: sections.Sprinklers.sectionId,
        seq: 1,
        label: "Rigid Drops",
        // roeGroup: "1",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    Sprinklers_RigidDropUPipe: {
        groupId: "Sprinklers_RigidDropUPipe",
        sectionId: sections.Sprinklers.sectionId,
        seq: 2,
        label: "Rigid Drop U-Pipe",
        // roeGroup: "2",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: false,
        // roeInternationalFreightRate: 1.0000,
    },
    Sprinklers_RigidDropBottomFitting: {
        groupId: "Sprinklers_RigidDropBottomFitting",
        sectionId: sections.Sprinklers.sectionId,
        seq: 3,
        label: "Rigid Drop Bottom Fitting",
        // roeGroup: "3",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: false,
        // roeInternationalFreightRate: 1.0000,
    },
    Sprinklers_RigidDropReinforcementClamp: {
        groupId: "Sprinklers_RigidDropReinforcementClamp",
        sectionId: sections.Sprinklers.sectionId,
        seq: 4,
        label: "Rigid Drop Reinforcement Clamp",
        // roeGroup: "4",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: false,
        // roeInternationalFreightRate: 1.0000,
    },
    Sprinklers_HoseDropUPipe: {
        groupId: "Sprinklers_HoseDropUPipe",
        sectionId: sections.Sprinklers.sectionId,
        seq: 5,
        label: "Hose Drop U-Pipe",
        // roeGroup: "5",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    Sprinklers_HoseDropFittings: {
        groupId: "Sprinklers_HoseDropFittings",
        sectionId: sections.Sprinklers.sectionId,
        seq: 6,
        label: "Hose Drop Fittings",
        // roeGroup: "6",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: false,
        // roeInternationalFreightRate: 1.0000,
    },
    Sprinklers_HoseDropHoseClamps: {
        groupId: "Sprinklers_HoseDropHoseClamps",
        sectionId: sections.Sprinklers.sectionId,
        seq: 7,
        label: "Hose Drop Hose Clamps",
        // roeGroup: "7",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: false,
        // roeInternationalFreightRate: 1.0000,
    },
    Sprinklers_HoseDropWeight: {
        groupId: "Sprinklers_HoseDropWeight",
        sectionId: sections.Sprinklers.sectionId,
        seq: 8,
        label: "Hose Drop Weight",
        // roeGroup: "8",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    Sprinklers_HoseDropSpringClamp: {
        groupId: "Sprinklers_HoseDropSpringClamp",
        sectionId: sections.Sprinklers.sectionId,
        seq: 9,
        label: "Hose Drop Spring Clamp",
        // roeGroup: "9",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: false,
        // roeInternationalFreightRate: 1.0000,
    },
    Sprinklers_HoseDropDragAdapter: {
        groupId: "Sprinklers_HoseDropDragAdapter",
        sectionId: sections.Sprinklers.sectionId,
        seq: 10,
        label: "Hose Drop Drag Adapter",
        // roeGroup: "10",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: false,
        // roeInternationalFreightRate: 1.0000,
    },
    Sprinklers_HoseDropDragSock: {
        groupId: "Sprinklers_HoseDropDragSock",
        sectionId: sections.Sprinklers.sectionId,
        seq: 11,
        label: "Hose Drop Drag Sock",
        // roeGroup: "11",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    Sprinklers_HoseDropHose: {
        groupId: "Sprinklers_HoseDropHose",
        sectionId: sections.Sprinklers.sectionId,
        seq: 12,
        label: "Hose Drop Hose",
        // roeGroup: "12",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: false,
        // roeInternationalFreightRate: 1.0000,
    },
    Sprinklers_DeviceWeight: {
        groupId: "Sprinklers_DeviceWeight",
        sectionId: sections.Sprinklers.sectionId,
        seq: 13,
        label: "Device Weight",
        // roeGroup: "13",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    Sprinklers_Regulators: {
        groupId: "Sprinklers_Regulators",
        sectionId: sections.Sprinklers.sectionId,
        seq: 14,
        label: "Regulators",
        // roeGroup: "14",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    Sprinklers_SprinklerDevices: {
        groupId: "Sprinklers_SprinklerDevices",
        sectionId: sections.Sprinklers.sectionId,
        seq: 15,
        label: "Sprinkler Devices",
        // roeGroup: "15",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    Sprinklers_PipeJointCompound: {
        groupId: "Sprinklers_PipeJointCompound",
        sectionId: sections.Sprinklers.sectionId,
        seq: 16,
        label: "Pipe Joint Compound",
        // roeGroup: "16",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: false,
        // roeInternationalFreightRate: 1.0000,
    },
    Sprinklers_ImpactRiser: {
        groupId: "Sprinklers_ImpactRiser",
        sectionId: sections.Sprinklers.sectionId,
        seq: 17,
        label: "Impact Riser",
        // roeGroup: "17",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: false,
        // roeInternationalFreightRate: 1.0000,
    },
    Sprinklers_Valves: {
        groupId: "Sprinklers_Valves",
        sectionId: sections.Sprinklers.sectionId,
        seq: 20,
        label: "Valves",
        // roeGroup: "20",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    Sprinklers_BoomBacks: {
        groupId: "Sprinklers_BoomBacks",
        sectionId: sections.Sprinklers.sectionId,
        seq: 21,
        label: "Boom Backs",
        // roeGroup: "21",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    Sprinklers_PartialCircles: {
        groupId: "Sprinklers_PartialCircles",
        sectionId: sections.Sprinklers.sectionId,
        seq: 22,
        label: "Partial Circles",
        // roeGroup: "22",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    Sprinklers_DualTripleNozzleClip: {
        groupId: "Sprinklers_DualTripleNozzleClip",
        sectionId: sections.Sprinklers.sectionId,
        seq: 23,
        label: "Dual/Triple Nozzle Clip",
        // roeGroup: "23",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    Sprinklers_PressureGauge: {
        groupId: "Sprinklers_PressureGauge",
        sectionId: sections.Sprinklers.sectionId,
        seq: 24,
        label: "Pressure Gauge",
        // roeGroup: "24",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    Sprinklers_WASControlValve: {
        groupId: "Sprinklers_WASControlValve",
        sectionId: sections.Sprinklers.sectionId,
        seq: 25,
        label: "WAS Control Valve",
        // roeGroup: "25",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    Sprinklers_AquamaticValve: {
        groupId: "Sprinklers_AquamaticValve",
        sectionId: sections.Sprinklers.sectionId,
        seq: 26,
        label: "Aquamatic Valve",
        // roeGroup: "26",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: false,
        // roeInternationalFreightRate: 1.0000,
    },
    Sprinklers_ESPBoostValve: {
        groupId: "Sprinklers_ESPBoostValve",
        sectionId: sections.Sprinklers.sectionId,
        seq: 27,
        label: "ESP Boost Valve",
        // roeGroup: "27",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: false,
        // roeInternationalFreightRate: 1.0000,
    },
    Sprinklers_ControlLineDrainValve: {
        groupId: "Sprinklers_ControlLineDrainValve",
        sectionId: sections.Sprinklers.sectionId,
        seq: 28,
        label: "Control Line Drain Valve",
        // roeGroup: "28",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: false,
        // roeInternationalFreightRate: 1.0000,
    },
    Sprinklers_ESPStrainerValve: {
        groupId: "Sprinklers_ESPStrainerValve",
        sectionId: sections.Sprinklers.sectionId,
        seq: 29,
        label: "ESP Strainer/Valve",
        // roeGroup: "29",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    Sprinklers_ControlLine: {
        groupId: "Sprinklers_ControlLine",
        sectionId: sections.Sprinklers.sectionId,
        seq: 30,
        label: "Control Line",
        // roeGroup: "30",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: false,
        // roeInternationalFreightRate: 1.0000,
    },
    Sprinklers_BoomBackValvesAndControls: {
        groupId: "Sprinklers_BoomBackValvesAndControls",
        sectionId: sections.Sprinklers.sectionId,
        seq: 31,
        label: "Boom Back Valves & Controls",
        // roeGroup: "31",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    Sprinklers_D3000ExtraPlate: {
        groupId: "Sprinklers_D3000ExtraPlate",
        sectionId: sections.Sprinklers.sectionId,
        seq: 33,
        label: "D3000 Extra Plate",
        // roeGroup: "33",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    Sprinklers_DeviceAdapter: {
        groupId: "Sprinklers_DeviceAdapter",
        sectionId: sections.Sprinklers.sectionId,
        seq: 34,
        label: "Device Adapter",
        // roeGroup: "34",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: false,
        // roeInternationalFreightRate: 1.0000,
    },
    Sprinklers_3000FlowControlNozzle: {
        groupId: "Sprinklers_3000FlowControlNozzle",
        sectionId: sections.Sprinklers.sectionId,
        seq: 35,
        label: "3000 Flow Control Nozzle",
        // roeGroup: "35",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    Sprinklers_HoseSling: {
        groupId: "Sprinklers_HoseSling",
        sectionId: sections.Sprinklers.sectionId,
        seq: 36,
        label: "Hose Sling",
        // roeGroup: "36",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: false,
        // roeInternationalFreightRate: 1.0000,
    },
    AgriInject: {
        groupId: "AgriInject",
        sectionId: sections.AgriInject.sectionId,
        seq: 1,
        label: "Agri-Inject",
        // roeGroup: "1",
        roeIsPartsPricing: true,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0500,
    },
    MainlineValves: {
        groupId: "MainlineValves",
        sectionId: sections.MainlineValves.sectionId,
        seq: 1,
        label: "Mainline Valves",
        // roeGroup: "1",
        roeIsPartsPricing: true,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    MainlineValves_AirValves: {
        groupId: "MainlineValves_AirValves",
        sectionId: sections.MainlineValves.sectionId,
        seq: 2,
        label: "Air Valves",
        // roeGroup: "2",
        roeIsPartsPricing: true,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    MainlineValves_MainlineValveComponents: {
        groupId: "MainlineValves_MainlineValveComponents",
        sectionId: sections.MainlineValves.sectionId,
        seq: 3,
        label: "Mainline Valve Components",
        // roeGroup: "3",
        roeIsPartsPricing: true,
        roeDisplayOnCustomerReports: false,
        // roeInternationalFreightRate: 1.0000,
    },
    SprinklerLube_SprinklerLube1200: {
        groupId: "SprinklerLube_SprinklerLube1200",
        sectionId: sections.SprinklerLube.sectionId,
        seq: 1,
        label: "Sprinkler Lube 1200",
        // roeGroup: "1",
        roeIsPartsPricing: true,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    SprinklerLube_SprinklerLube220: {
        groupId: "SprinklerLube_SprinklerLube220",
        sectionId: sections.SprinklerLube.sectionId,
        seq: 2,
        label: "Sprinkler Lube 220",
        // roeGroup: "2",
        roeIsPartsPricing: true,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    SprinklerLube_PumpOptions: {
        groupId: "SprinklerLube_PumpOptions",
        sectionId: sections.SprinklerLube.sectionId,
        seq: 3,
        label: "Pump Options",
        // roeGroup: "3",
        roeIsPartsPricing: true,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    ClemonsFilters_Filters: {
        groupId: "ClemonsFilters_Filters",
        sectionId: sections.ClemonsFilters.sectionId,
        seq: 1,
        label: "Filters",
        // roeGroup: "1",
        roeIsPartsPricing: true,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    CheckValves: {
        groupId: "CheckValves",
        sectionId: sections.CheckValves.sectionId,
        seq: 1,
        label: "Check Valves",
        // roeGroup: "1",
        roeIsPartsPricing: true,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    CheckValves_FlangeGaskets: {
        groupId: "CheckValves_FlangeGaskets",
        sectionId: sections.CheckValves.sectionId,
        seq: 2,
        label: "Flange Gaskets",
        // roeGroup: "2",
        roeIsPartsPricing: true,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    CheckValves_FlangeOptions: {
        groupId: "CheckValves_FlangeOptions",
        sectionId: sections.CheckValves.sectionId,
        seq: 3,
        label: "Flange Options",
        // roeGroup: "3",
        roeIsPartsPricing: true,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    HeatExchangers_HeatExchanger: {
        groupId: "HeatExchangers_HeatExchanger",
        sectionId: sections.HeatExchangers.sectionId,
        seq: 1,
        label: "Heat Exchanger",
        // roeGroup: "1",
        roeIsPartsPricing: true,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    HeatExchangers_PipeFitting: {
        groupId: "HeatExchangers_PipeFitting",
        sectionId: sections.HeatExchangers.sectionId,
        seq: 2,
        label: "Pipe Fitting",
        // roeGroup: "2",
        roeIsPartsPricing: true,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    HeatExchangers_SurgeTank: {
        groupId: "HeatExchangers_SurgeTank",
        sectionId: sections.HeatExchangers.sectionId,
        seq: 3,
        label: "Surge Tank",
        // roeGroup: "3",
        roeIsPartsPricing: true,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    RadioTelemetry_CableTheft: {
        groupId: "RadioTelemetry_CableTheft",
        sectionId: sections.RadioTelemetry.sectionId,
        seq: 1,
        label: "Cable Theft",
        // roeGroup: "1",
        roeIsPartsPricing: true,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    RadioTelemetry_Radio: {
        groupId: "RadioTelemetry_Radio",
        sectionId: sections.RadioTelemetry.sectionId,
        seq: 2,
        label: "Radio",
        // roeGroup: "2",
        roeIsPartsPricing: true,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    RadioTelemetry_SatellitePlus: {
        groupId: "RadioTelemetry_SatellitePlus",
        sectionId: sections.RadioTelemetry.sectionId,
        seq: 3,
        label: "Satellite Plus",
        // roeGroup: "3",
        roeIsPartsPricing: true,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    RadioTelemetry_Cell: {
        groupId: "RadioTelemetry_Cell",
        sectionId: sections.RadioTelemetry.sectionId,
        seq: 4,
        label: "Cell",
        // roeGroup: "4",
        roeIsPartsPricing: true,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    VRI_VRITubing: {
        groupId: "VRI_VRITubing",
        sectionId: sections.VRI.sectionId,
        seq: 1,
        label: "VRI Tubing",
        // roeGroup: "1",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: false,
        // roeInternationalFreightRate: 1.0000,
    },
    VRI_VRICompressor: {
        groupId: "VRI_VRICompressor",
        sectionId: sections.VRI.sectionId,
        seq: 2,
        label: "VRI Compressor",
        // roeGroup: "2",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    VRI_VRIValves: {
        groupId: "VRI_VRIValves",
        sectionId: sections.VRI.sectionId,
        seq: 3,
        label: "VRI Valves",
        // roeGroup: "3",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    VRI_VRIValveBox: {
        groupId: "VRI_VRIValveBox",
        sectionId: sections.VRI.sectionId,
        seq: 4,
        label: "VRI Valve Box",
        // roeGroup: "4",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    VRI_VRIMountKit: {
        groupId: "VRI_VRIMountKit",
        sectionId: sections.VRI.sectionId,
        seq: 7,
        label: "VRI Mount Kit",
        // roeGroup: "7",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: false,
        // roeInternationalFreightRate: 1.0000,
    },
    Flowmeters: {
        groupId: "Flowmeters",
        sectionId: sections.Flowmeters.sectionId,
        seq: 1,
        label: "Flowmeters",
        // roeGroup: "1",
        roeIsPartsPricing: true,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    RC10_RC10Components: {
        groupId: "RC10_RC10Components",
        sectionId: sections.RC10.sectionId,
        seq: 1,
        label: "RC10 Components",
        // roeGroup: "1",
        roeIsPartsPricing: true,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    DualSprinklerPackage_DualSprinklerPackageTubing: {
        groupId: "DualSprinklerPackage_DualSprinklerPackageTubing",
        sectionId: sections.DualSprinklerPackage.sectionId,
        seq: 1,
        label: "Dual Sprinkler Package Tubing",
        // roeGroup: "1",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    DualSprinklerPackage_DualSprinklerPackageCompressor: {
        groupId: "DualSprinklerPackage_DualSprinklerPackageCompressor",
        sectionId: sections.DualSprinklerPackage.sectionId,
        seq: 2,
        label: "Dual Sprinkler Package Compressor",
        // roeGroup: "2",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    DualSprinklerPackage_DualSprinklerPackageValves: {
        groupId: "DualSprinklerPackage_DualSprinklerPackageValves",
        sectionId: sections.DualSprinklerPackage.sectionId,
        seq: 3,
        label: "Dual Sprinkler Package Valves",
        // roeGroup: "3",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    DualSprinklerPackage_DualSprinklerPackageValveBox: {
        groupId: "DualSprinklerPackage_DualSprinklerPackageValveBox",
        sectionId: sections.DualSprinklerPackage.sectionId,
        seq: 4,
        label: "Dual Sprinkler Package Valve Box",
        // roeGroup: "4",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    DualSprinklerPackage_DualSprinklerPackageMountKit: {
        groupId: "DualSprinklerPackage_DualSprinklerPackageMountKit",
        sectionId: sections.DualSprinklerPackage.sectionId,
        seq: 5,
        label: "Dual Sprinkler Package Mount Kit",
        // roeGroup: "5",
        roeIsPartsPricing: false,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    CropX_SoilProbe: {
        groupId: "CropX_SoilProbe",
        sectionId: sections.CropX.sectionId,
        seq: 1,
        label: "Soil Probe",
        // roeGroup: "1",
        roeIsPartsPricing: true,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    CropX_ProbelessIrrigationScheduling: {
        groupId: "CropX_ProbelessIrrigationScheduling",
        sectionId: sections.CropX.sectionId,
        seq: 2,
        label: "Probeless Irrigation Scheduling",
        // roeGroup: "2",
        roeIsPartsPricing: true,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    CropX_InstallKit: {
        groupId: "CropX_InstallKit",
        sectionId: sections.CropX.sectionId,
        seq: 3,
        label: "Install Kit",
        // roeGroup: "3",
        roeIsPartsPricing: true,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    CropX_Antenna: {
        groupId: "CropX_Antenna",
        sectionId: sections.CropX.sectionId,
        seq: 4,
        label: "Antenna",
        // roeGroup: "4",
        roeIsPartsPricing: true,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
    RC10Retro_RC10RetroComponents: {
        groupId: "RC10Retro_RC10RetroComponents",
        sectionId: sections.RC10Retro.sectionId,
        seq: 1,
        label: "RC10 Retro Components",
        // roeGroup: "1",
        roeIsPartsPricing: true,
        roeDisplayOnCustomerReports: true,
        // roeInternationalFreightRate: 1.0000,
    },
}

export default { sections, groups };
