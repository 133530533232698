import { Stack } from "@mui/material";
import i18next, { t } from "i18next";
import IBoolean from "rdptypes/roe/IBoolean";
import ICard from "rdptypes/roe/ICard";
import IPage from "rdptypes/roe/IPage";
import * as React from "react";
import { partsPackages } from "roedata/data";
import * as sysf from "roedata/roe_migration/SystemFunctions";
import PageRenderer from "../../../../../components/roe/componentRenderers/PageRenderer";
import IDrawerTab from "../IDrawerTab";
import { createAlwaysValidComponentRendererCtx } from "../alwaysValidComponentRendererCtx";
import { PivotingLateralTable } from "./PivotingLateralTable";

const pivotImg = new URL(
    'lateral.png?as=webp&width=25',
    import.meta.url
  );

const page: IPage = {
    position: 0,
    title: "",
    components: [
        {
            type: "card",
            title: "Allow other systems to overlap this system",
            disableDealerSettings: true,
            children: [
                { 
                    type: "boolean", 
                    title: { "en": "Allow overlap", "es": "Allow overlap" }, 
                    fieldPath: "overlapping",
                    validate: false
                } as IBoolean,
            ]
        } as ICard
    ]
}

const lateralTab: IDrawerTab = {
    icon:  <img src={pivotImg}/>,
    getTitle: () => i18next.format(t("lateral"), 'capitalize-each'),
    component: ({ dbPrj, layoutId, systemId }) => {
         const { roeData } = partsPackages[dbPrj.state.layouts[layoutId].systems[systemId].partsPackageId];

        return (
            <Stack direction='column' margin={1}>
                <PageRenderer 
                    page={page} 
                    rules={roeData.rules}
                    dbPrj={dbPrj} 
                    layoutId={layoutId} 
                    systemId={systemId} 
                    ctx={createAlwaysValidComponentRendererCtx(dbPrj)}
                    oneColArrangement={true}
                />
                {
                    dbPrj.state.layouts[layoutId]?.systems[systemId] && 
                    sysf.IsPivotingLateral(dbPrj.state.layouts[layoutId].systems[systemId]) && (
                        <PivotingLateralTable
                            dbPrj={dbPrj}
                            layoutId={layoutId}
                            systemId={systemId}
                        />
                    )
                }
            </Stack>
        )
    }
}

export default lateralTab;