import { ISystemBase, SystemTypes, WrapAroundSpanTypes } from "rdptypes/project/ISystemBase.AutoGenerated";
import { IRule, IRuleResult } from "rdptypes/roe/IRule";
import { IsDualSided } from "../../../../../roe_migration/SystemFunctions";
import { AllSideTowerTiresNonDirectional } from "../../../../../roe_migration/TowerFunctions";

export default {
    pages: [
        {
            changeRequiresNewSprinklers: true,
            id: "towers",
            title: {"en" : "Towers", "es": "Torres"},
            position: 5,
            visible: (sys: ISystemBase) => sys.SystemProperties.SystemType !== SystemTypes.SwingArmRetro && sys.SystemProperties.SystemType !== SystemTypes.Ancillary,
            components: [
                {
                    type: "choice",
                    fieldPath: "TowerProperties.WheelGear",
                    title: "Wheel Gear",
                    options: [
                        { id: "None", title: 'Moderate Duty'},
                        { id: "SevenFourty", title: '740 Series'},
                        { id: "SevenFourtyAD", title: '740-AD Series'},
                        { id: "SevenFourtyFive", title: '745 Series XHD'},
                    ],
                    enumNameHint: "WheelGearSeries",
                },
                {
                    type: "sidetable",
                    cartDisconnectColumnIndex: 6,
                    columns: [
                        {
                            title: {"en" :"Type", "es": "Tipo"},
                            arrayPath: "Tower",
                            cmp: {
                                type: "choice",
                                fieldPath: "TowerType",
                                title: "",
                                options: [
                                    { id: "Standard", title: {"en" :"Standard", "es": "Estándar" }},
                                    { id: "LowProfile", title: {"en" :"Low Profile", "es": "Bajo Perfil" }},
                                    { id: "Sugargator", title: "Sugargator" },
                                    { id: "Supergator", title: "Supergator" },
                                ],
                                enumNameHint: "TowerHeights",
                                includeOnlyValidationRules: []
                            },
                        },
                        {
                            title:{"en" : "Center Drive", "es": "Transmisión Central"},
                            arrayPath: "Tower",
                            cmp: {
                                type: "choice",
                                fieldPath: "CenterDrive",
                                title: "",
                                options: [
                                    { id: "Standard", title:{"en" : "Std Speed, 40:1", "es": "Velocidad Std, 40:1" }},
                                    { id: "High", title:{"en" : "High Speed, 25:1 HDC", "es": "Velocidad Alta, 25:1" }},
                                    { id: "Low", title:{"en" : "Low Speed, 60:1", "es": "Baja Velocidad, 60:1" }},
                                ],
                                enumNameHint: "GearDriveTypes",
                            },
                        },
                        {
                            title: {"en" :"Buck & Boost Transformer","es": "Transformador Buster"},
                            arrayPath: "Tower",
                            disableDealerSettings: true,
                            cmp: {
                                type: "choice",
                                fieldPath: "BuckBoost",
                                disableDealerSettings: true,
                                title: "",
                                options: [
                                    { id: "aNone", title: "None", "es": "Ninguno" },
                                    { id: "a15KVA", title: "15KVA" },
                                    { id: "a225KVA", title: "22½ KVA" },
                                ],
                                enumNameHint: "BuckBoostTypes",
                            },
                        },
                        {
                            title: {"en" :"Tires", "es": "Neumático"},
                            arrayPath: "Tower",
                            disableDealerSettings: true,
                            cmp: {
                                type: "complexChoice",
                                options: [
                                    { title: "11 x 22.5", nestedOptions: [
                                        { title: {"en" :"Recaps", "es": "Recapeadas" }, values: { "Tires.TireSize": "a11x225", "Tires.TireType": "Recaps" } },
                                        { title: "Mach II", values: { "Tires.TireSize": "a11x225", "Tires.TireType": "MachII" } },
                                    ] },
                                    { title: "11.2 x 24", nestedOptions: [
                                        { title: { "en" :"4-Ply", "es": "4 Capas" }, values: { "Tires.TireSize": "a112x24", "Tires.TireType": "FourPly" } },
                                        { title: "Mach II", values: { "Tires.TireSize": "a112x24", "Tires.TireType": "MachII" } },
                                    ] },
                                    { title: "320/85R24", nestedOptions: [
                                        { title: "Radial", values: { "Tires.TireSize": "a32085R24", "Tires.TireType": "Radial" } },
                                    ] },
                                    { title: "14.9 x 24", nestedOptions: [
                                        { title: { "en" :"4-Ply", "es": "4 Capas" }, values: { "Tires.TireSize": "a149x24", "Tires.TireType": "FourPly" } },
                                        { title: "Non-Directional", values: { "Tires.TireSize": "a149x24", "Tires.TireType": "NonDirectional" } },
                                        { title: "Radial", values: { "Tires.TireSize": "a149x24", "Tires.TireType": "Radial" } },
                                        { title: "Mach II", values: { "Tires.TireSize": "a149x24", "Tires.TireType": "MachII" } },
                                        { title: "Turf", values: { "Tires.TireSize": "a149x24", "Tires.TireType": "Turf" } },
                                    ] },
                                    { title: "16.9 x 24", nestedOptions: [
                                        { title: { "en" :"6-Ply", "es": "6 Capas" }, values: { "Tires.TireSize": "a169x24", "Tires.TireType": "SixPly" } },
                                        { title: "Turf", values: { "Tires.TireSize": "a169x24", "Tires.TireType": "Turf" } },
                                        { title: "Shark", values: { "Tires.TireSize": "a169x24", "Tires.TireType": "Shark" } },
                                    ] },
                                    { title: "11.2 x 38", nestedOptions: [
                                        { title: { "en" :"4-Ply", "es": "4 Capas" }, values: { "Tires.TireSize": "a112x38", "Tires.TireType": "FourPly" } },
                                        { title: "Non-Directional", values: { "Tires.TireSize": "a112x38", "Tires.TireType": "NonDirectional" } },
                                        { title: "Mach II", values: { "Tires.TireSize": "a112x38", "Tires.TireType": "MachII" } },
                                    ] },
                                    { title: "320/85R38", nestedOptions: [
                                        { title: "Radial", values: { "Tires.TireSize": "a32085R38", "Tires.TireType": "Radial" } },
                                    ] },
                                    { title: "13.6 x 38", nestedOptions: [
                                        { title: { "en" :"6-Ply", "es": "6 Capas" }, values: { "Tires.TireSize": "a136x38", "Tires.TireType": "SixPly" } },
                                        { title: "Mach II", values: { "Tires.TireSize": "a136x38", "Tires.TireType": "MachII" } },
                                    ] },
                                    { title: "380/80R38", nestedOptions: [
                                        { title: "Radial", values: { "Tires.TireSize": "a38080R38", "Tires.TireType": "Radial" } },
                                    ] },
                                ],
                            },
                        },
                        {
                            title: {"en" :"Options", "es": "Opciones"},
                            arrayPath: "Tower",
                            disableDealerSettings: true,
                            cmp: {
                                type: "choice",
                                fieldPath: "",
                                title: "",
                                options: [
                                ],
                                additionalBooleans: [
                                    { visible: (sys: ISystemBase) => sys.partsPackageId < "2024081901", type: "boolean", fieldPath: "AirCompressor", title: {"en" :"Air Compressor", "es": "Compresor de Aire"} },
                                    { visible: (sys: ISystemBase) => sys.partsPackageId < "2024081901", type: "boolean", fieldPath: "ControlLineBoosterPump", title: {"en" :"Control Line Booster Pump", "es": "Línea de Control Buster ESP"} },
                                    // { type: "boolean", fieldPath: "mach12Lugs", title: "Mach II - 12 Lugs" },
                                    { type: "boolean", fieldPath: "HeavyDuty", title: {"en" :"Heavy Duty Legs", "es": "Patas de Uso Rudo" }, includeOnlyValidationRules: []},
                                    { type: "boolean", fieldPath: "TowerCrossBrace", title: {"en" :"Tower Cross Brace", "es": "Tensores Cruzados de Torre"} },
                                    { type: "boolean", fieldPath: "StiffenerTrussToPipe", title: {"en" :"Stiffener - Truss to Pipe", "es": "Estabilizador Estructura a Tubo" }},
                                    { type: "boolean", fieldPath: "StiffenerStabilizers", title: {"en" :"Stiffener - Stabilizers", "es": "Estabilizador de Brazos" }},
                                    { type: "boolean", fieldPath: "TowerBaseAccessStep", title: {"en" :"Tower Base Access Step", "es": "Peldaño de acceso en la base de la torre" }},
                                    { type: "boolean", fieldPath: "TowerAutoReverse", autoChange: false, title: {"en" :"Tower Auto Reverse", "es": "Auto-Reversa en la Torre" }},
                                    { type: "boolean", fieldPath: "TowerAutoStop", autoChange: false, title: {"en" :"Tower Auto Stop", "es": "Auto Stop de Torre" }},
                                    { type: "boolean", fieldPath: "Guidance", autoChange: false, title: {"en" :"Lateral Move Guidance", "es": "Dirección de Avance Frontal" }, includeOnlyValidationRules: []},
                                    { type: "boolean", fieldPath: "ThreeTires", title: {"en" :"3-Wheel Tower", "es": "Torre de 3 ruedas"}},
                                    { type: "boolean", fieldPath: "ThreeTiresFlex", title: {"en" :"Flex Tower", "es": "Torre Flex" }},
                                    { type: "boolean", fieldPath: "AgriTrac", title: "Agri-TRACS" },
                                ],
                            },
                        },
                        {
                            title: "Wrap",
                            arrayPath: "Tower",
                            disableDealerSettings: true,
                            cmp: {
                                type: "complexChoice",
                                title: "",
                                options: [
                                    { title: "None", values: { "WrapAroundSpan": WrapAroundSpanTypes.None, "AutomatedValves": undefined } },
                                    { title: "10°", values: { "WrapAroundSpan": WrapAroundSpanTypes.TenDegree, "AutomatedValves": undefined } },
                                    { title: "90° no valves", values: { "WrapAroundSpan": WrapAroundSpanTypes.NinetyDegree, "AutomatedValves": undefined } },
                                    { title: "90° + valves", values: { "WrapAroundSpan": WrapAroundSpanTypes.NinetyDegree, "AutomatedValves": true } },
                                ]
                            },
                            visible: (sys: ISystemBase) => !(sys.SystemProperties.SystemType === SystemTypes.CanalFeedMaxigator
                                || sys.SystemProperties.SystemType === SystemTypes.HoseFeedMaxigator)
                        },
                        {
                            title: {"en" :"Disconnect", "es": "Desconexión"},
                            arrayPath: "Span",
                            disableDealerSettings: true,
                            cmp: {
                                type: "boolean",
                                title: "",
                                fieldPath: "Disconnecting",
                                hook: (value: boolean) => {
                                    if (value) {
                                        return [
                                            { fieldPath: "dropSpanStartRelativeToPreviousSpanStart", value: 0 },
                                            { fieldPath: "dropSpanEndRelativeToPreviousSpanEnd", value: 0 }
                                        ]
                                    }
                                },
                                autoChange: false
                            },
                        },
                    ],
                },
                {
                    type: "choice",
                    fieldPath: "TowerProperties.Coupler",
                    title: {"en" :"Coupler","es": "Cople"},
                    options: [
                        { id: "HiTorque", title: {"en" :'Hi-Torque', "es": "Alto-Torque"}},
                        { id: "CXCoupler", title: {"en" :'C-X Coupler', "es": "Acoplador C-X"}},
                        { id: "SteelUJoints", title: {"en" :'Steel U-Joints', "es": "Junta-U Acero"}},
                    ],
                    enumNameHint: "CouplerTypes",
                },
                {
                    type: "booleangroup",
                    components: [
                        { visible: (sys: ISystemBase) => sys.partsPackageId >= "2024081901", type: "boolean", fieldPath: "TowerProperties.AirCompressor", title: {"en" :"Air Compressor", "es": "Compresor de Aire"} },
                        { visible: (sys: ISystemBase) => sys.partsPackageId >= "2024081901", type: "boolean", fieldPath: "TowerProperties.ControlLineBoosterPump", title: {"en" :"Control Line Booster Pump", "es": "Línea de Control Buster ESP"} },
                        { type: "boolean", title:{"en" : "Tubes For Tires", "es": "Cámara para Neumático"}, fieldPath: "TowerProperties.TireProperties.TubesForTires"},
                        { type: "boolean", title: {"en" :"One Tire Reversed","es": "Un Neumático Invertido"}, fieldPath: "TowerProperties.TireProperties.OneTireMountedInReverse" },
                        { type: "boolean", title: {"en" :"UMC Cover Kit", "es": "Cubierta UMC"}, fieldPath: "TowerProperties.UMCCoverKit"},
                        { type: "boolean", title: {"en" :"Motor Lead Cattle Guards", "es": "Guardas de Cable para Ganado"}, fieldPath: "TowerProperties.CattleGuards"},
                        { type: "boolean", title: {"en" :"Drain Couplers", "es": "Acoples para drenaje"}, fieldPath: "TowerProperties.DrainCouplers"},
                        { type: "boolean", title:{"en" : "Low Pressure Drain Kit", "es": "Kit de Drenaje LP"}, fieldPath: "TowerProperties.LPDrainKit"},
                        { type: "boolean", title: {"en" :"Waterman Drain"}, fieldPath: "TowerProperties.WatermanDrain"},
                       
                    ],
                },  
            ],
        },
    ],
    rules: [
        {
            apply: (system, user, grower, quick) =>
                (system.SystemProperties.SystemType === SystemTypes.CanalFeedMaxigator || system.SystemProperties.SystemType === SystemTypes.HoseFeedMaxigator) && 
                    !system.TowerProperties.TireProperties.OneTireMountedInReverse ? [{
                id: "roeLegacy.TireReversedAvail.3",
                description: "One tire mounted in reverse is requird for lateral towers.",
                severity: "error",
                targets: [
                    "TowerProperties.TireProperties.OneTireMountedInReverse"
                ]
            }] : []
        },
        {
            apply: (system, user, grower, quick) => {
                let rr: IRuleResult[] = [];

                if (system.SystemProperties.SystemType !== SystemTypes.CanalFeedMaxigator && system.SystemProperties.SystemType !== SystemTypes.HoseFeedMaxigator){
                    let allTowerTiresNonDirectional = AllSideTowerTiresNonDirectional(system.FlangedSide);
                    
                    if (IsDualSided(system)){
                        let flexTowerTiresNonDirectional = AllSideTowerTiresNonDirectional(system.FlexSide);
                        if (allTowerTiresNonDirectional){
                            allTowerTiresNonDirectional = flexTowerTiresNonDirectional;
                        }
                    }

                    if (allTowerTiresNonDirectional && !system.TowerProperties.TireProperties.OneTireMountedInReverse){
                        rr.push({
                            id: "roeLegacy.TireReversedAvail.2",
                            description: "One tire mounted in reverse invalid.",
                            severity: "error",
                            targets: [
                                "TowerProperties.TireProperties.OneTireMountedInReverse"
                            ]
                        })
                    }
                }

                return rr;
            }
        },
        {
            apply: (system, user, grower, quick) =>
                (!system.TowerProperties.DrainCouplers && system.TowerProperties.WatermanDrain) ? [{
                id: "watermanDrainRequiresDrainCouplers",
                description: "Waterman Drain is only available if Drain Couplers are checked.",
                severity: "error",
                targets: [
                    "TowerProperties.DrainCouplers",
                    "TowerProperties.WatermanDrain"
                ]
            }] : []
        },
        {
            apply: (system, user, grower, quick) =>
                (!system.TowerProperties.DrainCouplers && system.TowerProperties.LPDrainKit) ? [{
                id: "lpDrainKitRequiresDrainCouplers",
                description: "Low Pressure Drain Kit is only available if Drain Couplers are checked.",
                severity: "error",
                targets: [
                    "TowerProperties.DrainCouplers",
                    "TowerProperties.LPDrainKit"
                ]
            }] : []
        },
        {
            apply: (system, user, grower, quick) =>
                (system.TowerProperties.WatermanDrain && system.TowerProperties.LPDrainKit) ? [{
                id: "watermanDrainLpDrainKitIncompatible",
                description: "Low Pressure Drain Kit and Waterman Drain cannot both be selected.",
                severity: "error",
                targets: [
                    "TowerProperties.LPDrainKit",
                    "TowerProperties.WatermanDrain"
                ]
            }] : []
        },
    ] as IRule[]
};