import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { t } from "i18next";
import {
    EndGunTypes,
    EndOfSystemTypes,
    SwingArmLengths,
    SystemTypes,
} from "rdptypes/project/ISystemBase.AutoGenerated";
import { endBoomLengthsFeet } from "rdptypes/reinkeComponents";
import * as React from "react";
import { FC, useContext, useState } from "react";
import DevSettingsCtx from "../../db/DevSettingsCtx";
import { DEFAULT_CPA_RATIO } from "../../optimization/objectives/defaultCpaRatio";
import OptimizeCenterPivotForm, {
    IOptimizeCenterPivotFormState,
    OPTIMIZATION_CENTER_PIVOT_FORM_STATE_DEFAULT,
} from "./OptimizeCenterPivotForm";
import OptimizeLateralForm, {
    IOptimizeLateralFormState,
    OPTIMIZATION_LATERAL_FORM_STATE_DEFAULT,
} from "./OptimizeLateralForm";
import { PartialSpanLengthItem } from "./RestrictSpanLengthsControl";

export type IOptimizeSystemDialogResult =
    | {
          type: SystemTypes.CenterPivot;
          result: {
              optimizeSystem: boolean;
              optimizeCenter: boolean;
              allowPartialPivots: boolean;
              allowWrapSpans: boolean;
              maxNumberofWraps?: number;
              allowableSpanLengths?: PartialSpanLengthItem[];
              allowDropSpans: boolean;
              maxNumberofDropSpans?: number;
              allowableEndBoomLengths?: number[];
              endOfSystemType?: EndOfSystemTypes;
              sacType?: SwingArmLengths;
              sacIsLeading?: boolean;
              primaryEndGun: EndGunTypes;
              secondaryEndGun: EndGunTypes;
              primaryEndGunThrow?: number;
              secondaryEndGunThrow?: number;
              maxSystemRadiusFt?: number;
              minSystemRadiusFt?: number;
              maxNumberOfSystems?: number;
              cpaRatio: number;
          };
      }
    | {
          type: SystemTypes.HoseFeedMaxigator;
          result: {
              optimizeSystem: boolean;
              endFeed: boolean;
              pivotingLateral: false;

              allowableSpanLengths?: PartialSpanLengthItem[];
              allowDropSpans: boolean;
              maxNumberofDropSpans?: number;

              endOfSystemTypeFwd?: EndOfSystemTypes.EndBoom;
              restrictedEndBoomLengthsFwd: number[];
              endOfSystemTypeAft?: EndOfSystemTypes.EndBoom;
              restrictedEndBoomLengthsAft: number[];

              primaryEndGun: EndGunTypes;
              secondaryEndGun: EndGunTypes;
              primaryEndGunThrow?: number;
              secondaryEndGunThrow?: number;
          };
      }
    | {
          type: SystemTypes.HoseFeedMaxigator;
          result: {
              optimizeSystem: false;
              endFeed: true;
              pivotingLateral: true;
          };
      }
    | {
          type: SystemTypes.CanalFeedMaxigator;
          result: {
              optimizeSystem: boolean;
              allowableSpanLengths?: PartialSpanLengthItem[];
              canalWidthFeet: number;
              pivotingLateral: false;
              endFeed: boolean;

              distanceFromCanalCenterToFwdSide: number;
              distanceFromCanalCenterToAftSide: number;
              allowDropSpans: boolean;
              maxNumberofDropSpans?: number;

              endOfSystemTypeFwd?: EndOfSystemTypes.EndBoom;
              restrictedEndBoomLengthsFwd: number[];
              endOfSystemTypeAft?: EndOfSystemTypes.EndBoom;
              restrictedEndBoomLengthsAft: number[];

              primaryEndGun: EndGunTypes;
              secondaryEndGun: EndGunTypes;
              primaryEndGunThrow?: number;
              secondaryEndGunThrow?: number;
          };
      };

export type ISetClearancesFn = (sTowerClearance: number, hTowerClearnce: number) => void;
interface Props {
    type: "centerPivot" | "lateral";
    onClose: (result: IOptimizeSystemDialogResult | null) => void;
    hasClearancesBeenSet: boolean;
    setClearances: ISetClearancesFn;
}

const OptimizeSystemDialog: FC<Props> = (props) => {
    const { type, hasClearancesBeenSet, setClearances } = props;

    const ns = "optimization-dialog.";

    const dialogTitle =
        type === "centerPivot"
            ? t(ns + "center-pivot.center-pivot-optimization")
            : t(ns + "lateral.lateral-optimization");

    const settingsCtx = useContext(DevSettingsCtx);

    const [formStateCenterPivot, setFormStateCenterPivot] = useState<IOptimizeCenterPivotFormState>(
        settingsCtx.dealerSettings.optimization.centerPivot.useCustom
            ? settingsCtx.dealerSettings.optimization.centerPivot.custom.formState
            : OPTIMIZATION_CENTER_PIVOT_FORM_STATE_DEFAULT
    );

    const [formStateLateral, setFormStateLateral] = useState<IOptimizeLateralFormState>(
        settingsCtx.dealerSettings.optimization.lateral.useCustom
            ? settingsCtx.dealerSettings.optimization.lateral.custom.formState
            : OPTIMIZATION_LATERAL_FORM_STATE_DEFAULT
    );

    const handleCancel = () => {
        props.onClose(null);
    };
    const handleOkCenterPivot = () => {
        // Handle update clearances
        if (
            !hasClearancesBeenSet &&
            formStateCenterPivot.endOfSystemType === EndOfSystemTypes.SAC &&
            formStateCenterPivot.setClearances
        ) {
            const sTowerClearanceFt = formStateCenterPivot.sTowerClearanceFt || 0;
            const hTowerClearanceFt = formStateCenterPivot.hTowerClearanceFt || 0;
            setClearances(sTowerClearanceFt, hTowerClearanceFt);
        }

        const {
            optimizeSystem,
            allowPartialPivots,
            allowWrapSpans,
            maxNumberofWraps,
            optimizeCenterPivotLocation,
            restrictSpanLengths,
            restrictedSpanLengths,
            allowDropSpans,
            maxNumberOfDropSpans,
            restrictedEndBoomLengths,
            sacType,
            endOfSystemType,
            sacIsLeading,
            primaryEndGun,
            secondaryEndGun,
            primaryEndGunThrow,
            secondaryEndGunThrow,
            restrictMaxRadius, maxRadiusFt,
            restrictMinRadius, minRadiusFt,
            maxNumberOfSystems, cpaRatio
        } = formStateCenterPivot;
        const endBoomLengths = restrictedEndBoomLengths.length ? restrictedEndBoomLengths : endBoomLengthsFeet;
        const optimizeCenter = optimizeCenterPivotLocation;
        const result: IOptimizeSystemDialogResult = {
            type: SystemTypes.CenterPivot,
            result: {
                optimizeSystem,
                optimizeCenter,
                allowPartialPivots,
                allowWrapSpans,
                maxNumberofWraps: allowWrapSpans ? maxNumberofWraps : undefined,
                allowableSpanLengths: restrictSpanLengths ? restrictedSpanLengths : undefined,
                allowDropSpans,
                maxNumberofDropSpans: allowDropSpans ? maxNumberOfDropSpans : undefined,
                allowableEndBoomLengths: endOfSystemType === EndOfSystemTypes.EndBoom ? endBoomLengths : undefined,
                sacType: endOfSystemType === EndOfSystemTypes.SAC ? sacType : undefined,
                endOfSystemType,
                sacIsLeading: endOfSystemType === EndOfSystemTypes.SAC ? sacIsLeading : undefined,
                primaryEndGun,
                secondaryEndGun,
                primaryEndGunThrow,
                secondaryEndGunThrow,

                maxSystemRadiusFt: restrictMaxRadius ? (maxRadiusFt || 0) : undefined,
                minSystemRadiusFt: restrictMinRadius ? (minRadiusFt || 0) : undefined,

                // maxNumberOfSystems: maxNumberOfSystems === -1 
                //     ? undefined 
                //     : (maxNumberOfSystems === undefined || maxNumberOfSystems < 1)
                //         ? 1
                //         : maxNumberOfSystems,
                maxNumberOfSystems: 1,
                
                cpaRatio: cpaRatio ?? DEFAULT_CPA_RATIO
            },
        };
        props.onClose(result);
    };
    const handleOkLateral = () => {
        const {
            canalFeed,
            canalWidthFeet,
            distanceFromCanalCenterToFwdSide,
            distanceFromCanalCenterToAftSide,
            optimizeSystem,
            endFeed,
            restrictSpanLengths,
            restrictedSpanLengths,
            allowDropSpans,
            maxNumberOfDropSpans,
            endOfSystemTypeAft,
            restrictedEndBoomLengthsAft,
            endOfSystemTypeFwd,
            restrictedEndBoomLengthsFwd,
            primaryEndGun,
            secondaryEndGun,
            primaryEndGunThrow,
            secondaryEndGunThrow,
            pivotingLateral,
        } = formStateLateral;
        const endBoomLengthsAft = restrictedEndBoomLengthsAft.length ? restrictedEndBoomLengthsAft : endBoomLengthsFeet;
        const endBoomLengthsFwd = restrictedEndBoomLengthsFwd.length ? restrictedEndBoomLengthsFwd : endBoomLengthsFeet;

        let result: IOptimizeSystemDialogResult;

        if (canalFeed) {
            result = {
                type: SystemTypes.CanalFeedMaxigator,
                result: {
                    optimizeSystem,
                    allowableSpanLengths: restrictSpanLengths ? restrictedSpanLengths : undefined,
                    allowDropSpans,
                    maxNumberofDropSpans: allowDropSpans ? maxNumberOfDropSpans : undefined,
                    canalWidthFeet,
                    distanceFromCanalCenterToFwdSide,
                    distanceFromCanalCenterToAftSide,
                    endFeed,
                    endOfSystemTypeAft,
                    restrictedEndBoomLengthsAft: endBoomLengthsAft,
                    endOfSystemTypeFwd,
                    restrictedEndBoomLengthsFwd: endBoomLengthsFwd,
                    primaryEndGun,
                    secondaryEndGun,
                    primaryEndGunThrow,
                    secondaryEndGunThrow,
                    pivotingLateral: false
                },
            };
        } else if (endFeed && pivotingLateral) {
            result = {
                type: SystemTypes.HoseFeedMaxigator,
                result: {
                    optimizeSystem: false,
                    endFeed: true,
                    pivotingLateral: true,
                },
            };
        } else {
            result = {
                type: SystemTypes.HoseFeedMaxigator,
                result: {
                    optimizeSystem,
                    allowableSpanLengths: restrictSpanLengths ? restrictedSpanLengths : undefined,
                    allowDropSpans,
                    maxNumberofDropSpans: allowDropSpans ? maxNumberOfDropSpans : undefined,
                    endFeed,
                    endOfSystemTypeAft,
                    restrictedEndBoomLengthsAft: endBoomLengthsAft,
                    endOfSystemTypeFwd,
                    restrictedEndBoomLengthsFwd: endBoomLengthsFwd,
                    primaryEndGun,
                    secondaryEndGun,
                    primaryEndGunThrow,
                    secondaryEndGunThrow,
                    pivotingLateral: false
                },
            };
        }

        props.onClose(result);
    };

    return (
        <Dialog onClose={handleCancel} open={true} fullWidth maxWidth="md">
            <DialogTitle>{dialogTitle}</DialogTitle>
            <DialogContent dividers>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        m: "auto",
                        width: "fit-content",
                    }}
                >
                    {type === "centerPivot" ? (
                        <OptimizeCenterPivotForm
                            formState={formStateCenterPivot}
                            setFormState={setFormStateCenterPivot}
                            hasClearancesBeenSet={hasClearancesBeenSet}
                        />
                    ) : (
                        <OptimizeLateralForm formState={formStateLateral} setFormState={setFormStateLateral} />
                    )}
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel}>{t("cancel")}</Button>
                <Button onClick={type === "centerPivot" ? handleOkCenterPivot : handleOkLateral}>{t("ok")}</Button>
            </DialogActions>
        </Dialog>
    );
};

export default OptimizeSystemDialog;
