import { Position, lineString, point, polygon } from "@turf/turf";
import { BlobReader, TextWriter, ZipReader } from "@zip.js/zip.js";
import { ImportExportFeature, ImportExportTypes } from "../../../../../../helpers/importExport";
import { ImportExportProject } from "../common/interfaces";
import { EShapeType, IRapFile, IRapFileShape, RAP_PASSWORD } from "../common/rapFiles";

interface IArgs {
    blob: Blob
}

const getFeatureFromShape = (shape: IRapFileShape) => {
    let importType: ImportExportTypes;

    // boundary:
    // RAP files from app will have only equipment boundary, and the style will be null
    // RAP files from RDP will have a style = "EquipmentBoundary"
    if (shape.ShapeType === EShapeType.EquipmentBoundary && (!shape.Style || shape.Style === "EquipmentBoundary")) {
        importType = 'boundary';
    }
    // wet area:
    // RAP files from app will have no wet area boundaries, a app generated rap file can be detected by checking for .Style = null
    // RAP files from RDP will have a style = "WetAreaBoundary"
    else if (shape.ShapeType === EShapeType.EquipmentBoundary && shape.Style === "WetAreaBoundary") {
        importType = 'wetAreaBoundary';
    }
    // pivot center:
    // RAP files from app will have no wet area boundaries, a app generated rap file can be detected by checking for .Style = null
    // RAP files from RDP will have a style = "PivotCenterBoundary"
    else if (shape.ShapeType === EShapeType.EquipmentBoundary && shape.Style === "PivotCenterBoundary") {
        importType = 'pivotCenterBoundary';
    }
    // wheel obstacles:
    else if (shape.ShapeType === EShapeType.WheelObstacle) {
        importType = 'wheelObstacle';
    }    
    // span obstacles:
    else if (shape.ShapeType === EShapeType.SpanObstacle) {
        importType = 'obstacle';
    }
    // lines!?
    else if (
        shape.ShapeType === EShapeType.Line ||
        shape.ShapeType === EShapeType.WheelTrack
    ) {
        switch (shape.Style) {
            case 'Canal':
                // TODO: Should RDP3 have canal annotations?
                importType = 'canal';
                break;
            case 'ElectricLine':
                importType = 'electricLine';
                break;
            case 'Line':
                importType = 'line';
                break;
            case 'WaterLine':
                importType = 'waterLine';
                break;
            default:
                importType = "line";
        }
    }
    // points!?
    else if (shape.ShapeType === EShapeType.Point) {
        importType = 'point';
        switch (shape.Style) {
            case 'Cross':
                // TODO: RDP3 does not have cross type
                importType = 'point';
                break;
            case 'Label':
                importType = 'label';
                break;
            case 'Point':
                importType = 'point';
                break;
            case 'Pump':
                importType = 'pump';
                break;
            default:
                importType = "point";
        }
    }
    else {
        console.log("Unkown RAP file item:", shape);
        return undefined;
    }



    return handleShape(shape, importType);
}

const handleShape = (shape: IRapFileShape, importType: ImportExportTypes) => {
    const positions: Position[] = shape.Points.map(x => [x.Longitude, x.Latitude]);
    let feature: ImportExportFeature;
    switch (shape.ShapeType) {
        case EShapeType.PivotCenter:
        case EShapeType.Point:
            if (positions.length !== 1) return undefined;
            feature = point(positions[0], { importType, label: shape.Label });
            break;
        case EShapeType.Line:
        case EShapeType.WheelTrack:
            feature = lineString(positions, { importType, label: shape.Label });
            break;
        case EShapeType.EquipmentBoundary:
        case EShapeType.WheelObstacle:
        case EShapeType.SpanObstacle:
            if (positions.length && (
                positions[0][0] !== positions[positions.length - 1][0] ||
                positions[0][1] !== positions[positions.length - 1][1]
            )) {
                // Turf expects matching first and last points of polygon
                positions.push(positions[0]);
            }
            feature = polygon([positions], { importType, label: shape.Label });
            break;
        default:
            return undefined;
    }

    return feature;
}

const featuresFromRap = (rapFile: IRapFile) => {
    const features: ImportExportFeature[] = [];
    for (const shape of rapFile.ProjectData.Shapes) {
        const importFeature = getFeatureFromShape(shape);
        if (importFeature) {
            features.push(importFeature);
        }
    }
    return features;
}

const readRapFile = async ({ blob }: IArgs): Promise<IRapFile> => {
    const zipFileReader = new BlobReader(blob);
    const textWriter = new TextWriter();

    const zipReader = new ZipReader(zipFileReader, { password: RAP_PASSWORD });
    const firstEntry = (await zipReader.getEntries()).shift();

    let rapFile: IRapFile | null = null;
    if (firstEntry && firstEntry.getData) {
        const zipText = await firstEntry.getData(textWriter);
        rapFile = JSON.parse(zipText) as IRapFile;
    }
    await zipReader.close();

    if (!rapFile) throw new Error("Unable to read RAP file");

    return rapFile;
}

export const importRapFile = async ({ blob }: IArgs): Promise<ImportExportProject> => {
    const rapFile = await readRapFile({ blob });
    const features = featuresFromRap(rapFile);
    return { features };
}
