import { Feature, Polygon, Position } from "@turf/turf";
import { EndGunTypes } from "rdptypes/project/ISystemBase.AutoGenerated";

export interface IProperties {
    isLateral: true;
    systemId: string;
    layoutId: string;
    activeSystem: boolean;
}

export interface ILateralEndGunPartV2 {
    segments: IlateralSegment[];
    insideRadius: number;
    outsideRadius: number;
    isPrimary: boolean;
}

export type PivotDirection = "clockwise" | "anticlockwise";
export interface ILineSegment { 
    type: "line", 
    p1: Position, 
    p2: Position, 
    p1Direction: PivotDirection, 
    p2Direction: PivotDirection, 
    pivotStart?: boolean, 
    pivotEnd?: boolean
    inside: boolean;
    retracing?: boolean;
};
export interface IPivotSegment { 
    type: PivotDirection, 
    center: Position, 
    b1: number, 
    b2: number,
    pivotStart?: boolean, 
    pivotEnd?: boolean,
    retracing?: boolean
};
export type IlateralSegment = ILineSegment | IPivotSegment;

export interface ILateralSpanTowerV2 {
    lengthFeet: number;
    segments: IlateralSegment[];
    leftLostSegments: IlateralSegment[];
    rightLostSegments: IlateralSegment[];
    insideRadius: number;
    outsideRadius: number;
    isDropSpan: boolean;
    spanTowerIndex: number;
    isEndBoom?: boolean;
}


export interface ILateralEndGunInformation { 
    endGun: EndGunTypes,
    isPrimary: boolean,
    onOffs: { on: number, off: number }[] ,
    throwFeet: number;
}
export interface EndGunDescription{
    features: {feature: Feature<Polygon>, isPrimary: boolean}[];
    endGunInformation: ILateralEndGunInformation[]
}
