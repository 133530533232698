import IAction from "rdptypes/IAction";
import { v4 as uuidV4 } from "uuid";
import { createNewLayoutAction } from "../../../../actions/NewLayoutAction";
import { createNewUpdateLayoutPropertyAction } from "../../../../actions/UpdateLayoutProperty";
import { createNewUpdateProjectPropertyAction } from "../../../../actions/UpdateProjectProperty";
import IAuthState from '../../../../auth/IAuthState';
import { ProjectState, ProjectType } from "../../../../model/project/IProject";


const importJsonProject = (json: Object, gid: string, authState: IAuthState): IAction[] => {
    if (!("projectType" in json)) return [];
    
    const actions: IAction[] = [];
    const requiredKeys = [
        "name", "layouts"
    ]
    if (json.projectType === ProjectType.LayoutAndDesign) {
        requiredKeys.push("systemClearance");
    }

    for (const [k,v] of Object.entries(json)) {
        console.log(k,v)
    }
    const keys = Object.keys(json);
    if (requiredKeys.some(rk => !keys.includes(rk))) {
        console.log("The imported JSON is not valid")
        return;
    }
    actions.push(
        createNewUpdateProjectPropertyAction(
            "name",
            "Imported - " + json['name'],
            authState
        )
    )
    actions.push(
        createNewUpdateProjectPropertyAction(
            "systemClearance",
            json['systemClearance'] || 5,
            authState
        )
    )
    actions.push(
        createNewUpdateProjectPropertyAction(
            "growerId",
            gid,
            authState
        )
    )
    actions.push(
        createNewUpdateProjectPropertyAction(
            "projectType",
            json.projectType,
            authState
        )
    )
    if ('approximateLocation' in json) {
        actions.push(
            createNewUpdateProjectPropertyAction(
                "approximateLocation",
                json.approximateLocation,
                authState
            )
        )
    }
    actions.push(
        createNewUpdateProjectPropertyAction(
            "layouts",
            json['layouts'],
            authState
        )
    )
    actions.push(
        createNewUpdateProjectPropertyAction(
            "projectState",
            ProjectState.Active,
            authState
        )
    )
    return actions;
}

const importJsonSystem = (json: Object, gid: string, authState: IAuthState) => {
    const actions: IAction[] = [];
    const requiredKeys = [
        "name"
    ]
    for (const [k,v] of Object.entries(json)) {
        console.log(k,v)
    }
    const keys = Object.keys(json);
    if (requiredKeys.some(rk => !keys.includes(rk))) {
        console.log("The imported JSON is not valid")
        return;
    }
    actions.push(
        createNewUpdateProjectPropertyAction(
            "name",
            "Imported - " + json['name'],
            authState
        )
    )
    actions.push(
        createNewUpdateProjectPropertyAction(
            "growerId",
            gid,
            authState
        )
    )

    actions.push(
        createNewUpdateProjectPropertyAction(
            "projectType",
            ProjectType.DesignOnly,
            authState
        )
    )
    
    const createLayoutAction = createNewLayoutAction(authState);
    actions.push(createLayoutAction.action);
    
    const systems: {} = {};
    systems[uuidV4()] = json;
    actions.push(
        createNewUpdateLayoutPropertyAction(
            createLayoutAction.layoutId,
            "systems",
            systems,
            authState
        )
    );
    return actions;
}

enum EJsonType {
    Project, System
}
const getJsonType = (json: Object): EJsonType => {
    return "projectType" in json ? EJsonType.Project : EJsonType.System
}

export const importFieldJson = async (file: File, gid: string, authState: IAuthState): Promise<IAction[]> => {
    let json: Object;
    try {
        const r = new Response(file);
        json = await r.json();
    }
    catch {
        console.log("Error reading selected file");
        return [];
    }

    let actions: IAction[];
    if (getJsonType(json) === EJsonType.Project) {
        actions = importJsonProject(json, gid, authState);
    }
    else {
        actions = importJsonSystem(json, gid, authState);
    }
    return actions;
  }