import { View } from "@react-pdf/renderer";
import { t } from "i18next";
import * as React from "react";
import { FC, useContext } from "react";
import DiscountCalculator from "roedata/roe_migration/DiscountCalculator";
import PriceCalculator, { ISectionGroupedParts } from "roedata/roe_migration/PriceCalculator";
import QuoteClass from "roedata/roe_migration/QuoteClass";
import { HasSwingArmCorner } from "roedata/roe_migration/SystemFunctions";
import { getDiscountConfigFromUserData, proposalSectionIsAncillary } from "../../components/roe/componentRenderers/Proposal/ProposalHelpers";
import { formatPaymentTermsType } from "../../helpers/system";
import ISystem from "../../model/project/ISystem";
import DealerDataCtx from "../../userData/DealerDataCtx";
import { formatCents } from "../DocumentGenerationHelpers";
import FixedWidthTable, { IRow } from "./table/FixedWidthTable";

export interface Props {
    system: ISystem;
    quote: QuoteClass;
    groupedSections: ISectionGroupedParts;
}

const PurchaseOrderTermsPriceTable: FC<Props> = (props) => {
    let {quote, groupedSections } = props;
    const user = useContext(DealerDataCtx);

    const discountConfig = getDiscountConfigFromUserData(user, props.system);

    let listPriceCents = 0;
    let ancillaryPriceCents = 0;
    for (let sectionId in groupedSections){
        groupedSections[sectionId].forEach((p) => {
            let partPrice =  user.priceList[p.partNumber].unitPriceUsdCents;
            if (proposalSectionIsAncillary(parseInt(sectionId))){
                ancillaryPriceCents += p.qty * partPrice;
            }
            else {
                listPriceCents += p.qty * partPrice;
            }
        });
    }
    
    let swingArmPathPriceCents = 0;
    if (HasSwingArmCorner(quote.System)){
        swingArmPathPriceCents = quote.SwingArmClass.SpecialOrderPriceCents();
    }


    //Mainline Valves

    quote.System.MainlineValveOptions.Valves.MainlineValve.forEach((valve) => {
        let info = quote.MainlineValvesClass.GetMainlineValveInfo(valve, user.priceList);
        if (!isNaN(info.Price())){
            ancillaryPriceCents += info.Price();
        }
    });
    

    let pc = new PriceCalculator(quote, listPriceCents, discountConfig, groupedSections, user.priceList);
    let dc = new DiscountCalculator(discountConfig);

    let rows: IRow[] = [];
    
    rows.push({cells: [{
        text: "",
        widthPc: 60
    }, {
        text:  t('common-phrases.sltListPrice'),
        widthPc: 20,
        header: true
    }, {
        text: t('common-phrases.sltNetPrice'),
        widthPc: 20,
        header: true
    }]});

    let totalListPrice = listPriceCents + ancillaryPriceCents + swingArmPathPriceCents;

    rows.push({cells: [{
        text: "",
        widthPc: 40
    }, {
        text:  t('common-phrases.sltTotalPrice'),
        widthPc: 20,
        header: true
    }, {
        text: formatCents(totalListPrice),
        widthPc: 20
    }, {
        text: formatCents(pc.getTotalDealerPriceCents()),
        widthPc: 20
    }]});

    rows.push({cells: [{
        text: t('common-phrases.sltCustomerTerms'),
        widthPc: 20,
        header: true
    }, {
        text:  formatPaymentTermsType(quote.System.QuoteProperties.ProposalInformation.paymentTerms),
        widthPc: 20,
    }, {
        text: "",
        widthPc: 60
    }]});

    rows.push({cells: [{
        text: t('common-phrases.sltDealerTerms'),
        widthPc: 20,
        header: true
    }, {
        text:  "",
        widthPc: 80,
    }]});

    let downPayment = pc.getTotalDealerPriceCents() * 0.1;

    rows.push({cells: [{
        text: t('common-phrases.sltTradeDiscount'),
        widthPc: 20,
    }, {
        text:  `${discountConfig.StandardDiscount}%/${discountConfig.ProgramDiscount}%/${discountConfig.DeliveryDiscount}%/${discountConfig.TerritoryManagerDiscount}%`,
        widthPc: 20,
    }, {
        text: t('common-phrases.sltDownPayment10percent'),
        widthPc: 40,
        header: true
    }, {
        text: formatCents(downPayment),
        widthPc: 20,
        header: true
    }]});

    let cashDiscountStr = formatCents(dc.GetTerritoryManagerDiscountPrice(totalListPrice) - dc.GetCashDiscountPrice(totalListPrice));

    rows.push({cells: [{
        text: t('common-phrases.sltCashDiscount1'),
        widthPc: 20,
    }, {
        text:  `${discountConfig.CashDiscount}% (US ${cashDiscountStr}) @ 15/Net 30`,
        widthPc: 20,
    }, {
        text: t('common-phrases.sltBalanceDue'),
        widthPc: 40,
        header: true
    }, {
        text: formatCents(pc.getTotalDealerPriceCents() - downPayment),
        widthPc: 20,
        header: true
    }]});

    rows.push({cells: [{
        text: t('common-phrases.sltApplicableSalesIP'),
        widthPc: 100,
    },]});

    let elements: JSX.Element[] = [<FixedWidthTable
        data={{rows}}
    />];
    

    return (<View style={{paddingLeft: 15, paddingRight: 15}}>
            {elements}
        </View>
    );
};

export default PurchaseOrderTermsPriceTable;