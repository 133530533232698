import { WireGauge, WireMaterial } from "rdptypes/project/IMCP";
import { ISystemBase, SystemTypes } from "rdptypes/project/ISystemBase.AutoGenerated";
import { IRule } from "rdptypes/roe/IRule";

export default {
    pages: [
        {
            changeRequiresNewSprinklers: false,
            id: "mcpVoltage",
            title: {"en" : "Voltage Checks", "es": "Voltage Checks"},
            position: 9.5,
            disableDealerSettings: true,
            visible: (sys: ISystemBase) => !(sys.SystemProperties.SystemType === SystemTypes.Ancillary || sys.SystemProperties.SystemType === SystemTypes.SwingArmRetro),
            components: [
                {
                    type: "number",
                    disableDealerSettings: true,
                    fieldPath: "ControlPanel.SourceVoltage",
                    title: { en: "Voltage at Power Source", es: "Voltaje en la fuente de alimentación" },
                    units: "V"
                },
                {
                    type: "number",
                    disableDealerSettings: true,
                    fieldPath: "ControlPanel.MinVoltage",
                    title: { en: "Min Voltage at End of System", es: "Min Voltage at End of System" },
                    units: "V"
                },
                {
                    type: "number",
                    disableDealerSettings: true,
                    fieldPath: "mcp.lengthFt",
                    title: { en: "Length of run from Power Source to Pivot Point", es: "Length of run from Power Source to Pivot Point" },
                    units: "ft",
                    required: true
                },
                {
                    type: "choice",
                    disableDealerSettings: true,
                    fieldPath: "mcp.wireMaterial",
                    enumNameHint: 'WireMaterial',
                    validate: false,
                    options: [
                        { id: "Aluminium", title: { en: "Aluminium", es: "Aluminio" } },
                        { id: "Copper", title: { en: "Copper", es: "Cobre" } }
                    ],
                    title: { en: "Underground Wire Type", es: "Tipo de cable subterráneo" },
                    visible: (sys: ISystemBase) => sys.SystemProperties.SystemType !== SystemTypes.CanalFeedMaxigator
                },
                {
                    type: "choice",
                    disableDealerSettings: true,
                    fieldPath: "mcp.wireGauge",
                    enumNameHint: 'WireGauge',
                    options: [
                        { id: "AWG_14", title: { en: "14 AWG / 2.5 mm²" } },
                        { id: "AWG_12", title: { en: "12 AWG / 4 mm²" } },
                        { id: "AWG_10", title: { en: "10 AWG / 6 mm²" } },
                        { id: "AWG_8", title: { en: "8 AWG / 10 mm²" } },
                        { id: "AWG_6", title: { en: "6 AWG / 16 mm²" } },
                        { id: "AWG_4", title: { en: "4 AWG / 25 mm²" } },
                        { id: "AWG_2", title: { en: "2 AWG / 35 mm²" } },
                        { id: "AWG_1", title: { en: "1 AWG / 50 mm²" } },
                        { id: "AWG_0", title: { en: "0 AWG / 50 mm²" } },
                        { id: "AWG_00", title: { en: "00 AWG / 70 mm²" } },
                        { id: "AWG_000", title: { en: "000 AWG / 95 mm²" } },
                        { id: "AWG_0000", title: { en: "0000 AWG / 120 mm²" } },
                        { id: "MCM_250", title: { en: "250 MCM / 120 mm²" } },
                        { id: "MCM_300", title: { en: "300 MCM / 150 mm²" } },
                        { id: "MCM_350", title: { en: "350 MCM / 185 mm²" } },
                        { id: "MCM_400", title: { en: "400 MCM / 185 mm²" } },
                        { id: "MCM_450", title: { en: "450 MCM / 240 mm²" } },
                        { id: "MCM_500", title: { en: "500 MCM / 240 mm²" } },
                        { id: "MCM_600", title: { en: "600 MCM / 300 mm²" } },
                        { id: "MCM_750", title: { en: "750 MCM / 400 mm²" } },
                        { id: "MCM_1000", title: { en: "1000 MCM / 500 mm²" } }
                    ],
                    title: { en: "Underground Wire Size", es: "Calculo del Cable de Enterramiento Directo" },
                    visible: (sys: ISystemBase) => sys.SystemProperties.SystemType !== SystemTypes.CanalFeedMaxigator,
                    includeOnlyValidationRules: [
                        "mcp.wireGaugeOk"
                    ],
                },
                {
                    type: "mcpVoltageCalculator",
                    disableDealerSettings: true
                },
                {
                    type: "electricalReportRenderer"
                }
            ],
        },
    ],
    rules: [
        {
            apply: (system: ISystemBase, user, quick) => 
                (
                    system.mcp.wireMaterial === WireMaterial.Aluminium && 
                    [
                        // restricted values for aluminium
                        WireGauge.AWG_14,
                        WireGauge.AWG_12,
                        WireGauge.AWG_10,
                        WireGauge.AWG_8,
                        WireGauge.AWG_6
                    ].indexOf(system.mcp.wireGauge) !== -1 
                ) 
                    ? [{
                        id: "mcp.wireGaugeOk",
                        description: "Underground Wire Gauge not OK.",
                        severity: "error",
                        targets: [
                            "mcp.wireGauge"
                        ]
                    }] 
                    : []
        }
    ] as IRule[],
};