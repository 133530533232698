import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button, Card, CardContent, Collapse, FormControl, IconButton, IconButtonProps, InputAdornment, InputLabel, MenuItem, Select, Stack, styled, TextField, Tooltip } from "@mui/material";
import * as React from "react";
import { FC, useEffect } from "react";

import "@fontsource/libre-franklin/900.css";
import { HelpOutline } from "@mui/icons-material";
import Typography from "@mui/material/Typography/Typography";
import ToolsPageBase from "../toolComponents/ToolPageBase";

interface MainLinePressureLoss{
    pipeLengthFeet?: number;
    irrigationRateGPM?: number;
    psiLoss?: number;
    pumpToPivotElevation?: number;
    elevationPsiLoss?: number;
    totalPsiLoss?: number;
    velocityFeetPerSecond?: number;
    insideDiameterInches?: number;
    cFactor?: number;
    pipeMaterial?: PipeMaterial;
}

enum PipeStyle{
    IPS,
    PIP
}

interface CalculatePipeDiameter {
    pipeSize: number;
    maxPSI: number;
    pipeStyle: PipeStyle;
    calculatedInsideDiameterInches: number;
}


enum PipeMaterial{
    Custom,
    AcrylonitrileButadieneStyrene,
    Aluminium,
    AsbestosCement,
    AsphaltLining,
    Brass,
    CastIronNew,
    CastIron10YearsOld,
    CastIron20YearsOld,
    CastIron30YearsOld,
    CastIron40YearsOld,
    CastIronAsphaltCoated,
    CastIronBituminousLined,
    CastIronCementLined,
    CastIronSeaCoated,
    CastIronWroughtPlain,
    CementLining,
    Concrete,
    ConcreteLinedSteelForms,
    ConcreteLinedWoodenForms,
    ConcreteOld,
    Copper,
    CorrugatedMetal,
    DuctileIronPipe,
    DuctileIronCementLined,
    Fiber,
    Fiberglass,
    FireHoseRubberLined,
    GalvanizedIron,
    Glass,
    HDPE,
    Lead,
    MetalPipesVerySmooth,
    Plastic,
    Polyethylene,
    Polyvinylchloride,
    SmoothPipes,
    SteelNewUnlined,
    SteelCorrugated,
    SteelInteriorRivetedNoProjecting,
    SteelProjectingGirthHorizontalRivets,
    SteelVitrifiedSpiralRiveted,
    SteelWeldedAndSeamless,
    Tin,
    VitrifiedClay,
    WoodStave,
    WoodenOrMasonryPipeSmooth,
    WroughtIronPlain,
}

interface Props {

}

interface ExpandMoreProps extends IconButtonProps {
    expand: boolean;
  }

const ExpandMore = styled((props: ExpandMoreProps) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  }));

const MainLinePressureLossPage: FC<Props> = (props) => {
    const [mainLinePressureLoss, setMainLinePressureLoss] = React.useState<MainLinePressureLoss>({
        pumpToPivotElevation: 0,
        elevationPsiLoss: 0
    });

    const getCFactor = (pipeMaterial: PipeMaterial) => {
        switch (pipeMaterial){            
            case PipeMaterial.CorrugatedMetal:
            case PipeMaterial.SteelCorrugated:
                return 60;
            case PipeMaterial.CastIron40YearsOld:
                return 73.5;
            case PipeMaterial.CastIron30YearsOld:
                return 82.5;
            case PipeMaterial.CastIron20YearsOld:
                return 94.5;      
            case PipeMaterial.SteelVitrifiedSpiralRiveted:
                return 95;
            case PipeMaterial.CastIronAsphaltCoated:
            case PipeMaterial.CastIronWroughtPlain:
            case PipeMaterial.SteelProjectingGirthHorizontalRivets:
            case PipeMaterial.SteelWeldedAndSeamless:
            case PipeMaterial.WroughtIronPlain:
                return 100;
            case PipeMaterial.ConcreteOld:
                return 105;
            case PipeMaterial.CastIron10YearsOld:
            case PipeMaterial.SteelInteriorRivetedNoProjecting:
            case PipeMaterial.VitrifiedClay:
                return 110;
            case PipeMaterial.WoodStave:
                return 115;
            case PipeMaterial.CastIronSeaCoated:
            case PipeMaterial.Concrete:
            case PipeMaterial.ConcreteLinedWoodenForms:
            case PipeMaterial.DuctileIronCementLined:
            case PipeMaterial.GalvanizedIron:
            case PipeMaterial.WoodenOrMasonryPipeSmooth:
                return 120;
            case PipeMaterial.AcrylonitrileButadieneStyrene:
            case PipeMaterial.CastIronNew:
            case PipeMaterial.Glass:
            case PipeMaterial.Tin:
                return 130;
            case PipeMaterial.AsphaltLining:
            case PipeMaterial.Brass:
            case PipeMaterial.CementLining:
            case PipeMaterial.Copper:
            case PipeMaterial.FireHoseRubberLined:
            case PipeMaterial.Lead:
            case PipeMaterial.MetalPipesVerySmooth:
                return 135;
            case PipeMaterial.Aluminium:
            case PipeMaterial.AsbestosCement:
            case PipeMaterial.CastIronBituminousLined:
            case PipeMaterial.CastIronCementLined:
            case PipeMaterial.ConcreteLinedSteelForms:
            case PipeMaterial.Fiber:
            case PipeMaterial.Plastic:
            case PipeMaterial.Polyethylene:
            case PipeMaterial.SmoothPipes:
                return 140;
            case PipeMaterial.SteelNewUnlined:
                return 145;
            case PipeMaterial.Fiberglass:
            case PipeMaterial.Polyvinylchloride:
                return 150;
            case PipeMaterial.HDPE:
                return 152;
            case PipeMaterial.DuctileIronPipe:
                return 154;
        }
        return undefined;
    }
    
    const [calculatedPipeDiameter, setCalculatePipeDiameter] = React.useState<CalculatePipeDiameter>({
        maxPSI: undefined,
        pipeStyle: undefined,
        pipeSize: undefined,
        calculatedInsideDiameterInches: undefined
    });

    const calculateInsideDiameter = (pipeStyle: PipeStyle, maxPSI: number, pipeSize: number) => {
        let calculatedInsideDiameter: number;

        if (typeof pipeStyle !== "undefined" && maxPSI && pipeSize){
            if (pipeStyle === PipeStyle.IPS){
                switch (maxPSI){
                    case 63:
                        if (pipeSize === 6) calculatedInsideDiameter = 6.417;
                        else if (pipeSize === 8) calculatedInsideDiameter = 8.355;
                        else if (pipeSize === 10) calculatedInsideDiameter = 10.414;
                        else if (pipeSize === 12) calculatedInsideDiameter = 12.352;
                        break;
                    case 100:
                        if (pipeSize === 4) calculatedInsideDiameter = 4.28;
                        else if (pipeSize === 5) calculatedInsideDiameter = 5.291;
                        else if (pipeSize === 6) calculatedInsideDiameter = 6.301;
                        else if (pipeSize === 8) calculatedInsideDiameter = 8.205;
                        else if (pipeSize === 10) calculatedInsideDiameter = 10.226;
                        else if (pipeSize === 12) calculatedInsideDiameter = 12.158;
                        break;
                    case 125:
                        if (pipeSize === 3) calculatedInsideDiameter = 3.284;
                        else if (pipeSize === 4) calculatedInsideDiameter = 4.224;
                        else if (pipeSize === 5) calculatedInsideDiameter = 5.221;
                        else if (pipeSize === 6) calculatedInsideDiameter = 6.217;
                        else if (pipeSize === 8) calculatedInsideDiameter = 8.095;
                        else if (pipeSize === 10) calculatedInsideDiameter = 10.088;
                        else if (pipeSize === 12) calculatedInsideDiameter = 11.966;
                        break;
                    case 160:
                        if (pipeSize === 2) calculatedInsideDiameter = 2.193;
                        else if (pipeSize === 3) calculatedInsideDiameter = 3.23;
                        else if (pipeSize === 4) calculatedInsideDiameter = 4.154;
                        else if (pipeSize === 5) calculatedInsideDiameter = 5.135;
                        else if (pipeSize === 6) calculatedInsideDiameter = 6.115;
                        else if (pipeSize === 8) calculatedInsideDiameter = 7.961;
                        else if (pipeSize === 10) calculatedInsideDiameter = 9.924;
                        else if (pipeSize === 12) calculatedInsideDiameter = 11.538;
                        break;
                    case 200:
                        if (pipeSize === 2) calculatedInsideDiameter = 2.149;
                        else if (pipeSize === 3) calculatedInsideDiameter = 3.116;
                        else if (pipeSize === 4) calculatedInsideDiameter = 4.072;
                        else if (pipeSize === 6) calculatedInsideDiameter = 5.993;
                        else if (pipeSize === 8) calculatedInsideDiameter = 7.805;
                        else if (pipeSize === 10) calculatedInsideDiameter = 9.728;
                        else if (pipeSize === 12) calculatedInsideDiameter = 11.538;
                        break;
                }
            }
            else {
                switch (maxPSI){
                    case 80:
                        if (pipeSize === 6) calculatedInsideDiameter = 5.898;
                        else if (pipeSize === 8) calculatedInsideDiameter = 7.84;
                        else if (pipeSize === 10) calculatedInsideDiameter = 9.79;
                        else if (pipeSize === 12) calculatedInsideDiameter = 11.76;
                        else if (pipeSize === 15) calculatedInsideDiameter = 14.7;
                        else if (pipeSize === 18) calculatedInsideDiameter = 17.967;
                        else if (pipeSize === 21) calculatedInsideDiameter = 21.183;
                        else if (pipeSize === 24) calculatedInsideDiameter = 23.831;
                        else if (pipeSize === 27) calculatedInsideDiameter = 26.857;
                        break;
                    case 100:
                        if (pipeSize === 6) calculatedInsideDiameter = 5.84;
                        else if (pipeSize === 8) calculatedInsideDiameter = 7.762;
                        else if (pipeSize === 10) calculatedInsideDiameter = 9.702;
                        else if (pipeSize === 12) calculatedInsideDiameter = 11.642;
                        else if (pipeSize === 15) calculatedInsideDiameter = 14.55;
                        else if (pipeSize === 18) calculatedInsideDiameter = 17.789;
                        else if (pipeSize === 21) calculatedInsideDiameter = 20.971;
                        else if (pipeSize === 24) calculatedInsideDiameter = 23.593;
                        else if (pipeSize === 27) calculatedInsideDiameter = 26.589;
                        break;
                    case 125:
                        if (pipeSize === 6) calculatedInsideDiameter = 5.762;
                        else if (pipeSize === 8) calculatedInsideDiameter = 7.658;
                        else if (pipeSize === 10) calculatedInsideDiameter = 9.572;
                        else if (pipeSize === 12) calculatedInsideDiameter = 11.486;
                        else if (pipeSize === 15) calculatedInsideDiameter = 14.358;
                        else if (pipeSize === 18) calculatedInsideDiameter = 17.551;
                        else if (pipeSize === 21) calculatedInsideDiameter = 20.691;
                        else if (pipeSize === 24) calculatedInsideDiameter = 23.277;
                        else if (pipeSize === 27) calculatedInsideDiameter = 26.233;
                        break;
                    case 160:
                        if (pipeSize === 15) calculatedInsideDiameter = 14.124;
                        else if (pipeSize === 18) calculatedInsideDiameter = 17.263;
                        break;
                    case 200:
                        if (pipeSize === 15) calculatedInsideDiameter = 13.842;
                        break;
                }
            }
        }

        return calculatedInsideDiameter;
    }

    const calculatePsiLoss = (cFactor: number, insideDiameterInches: number, pipeLengthFeet: number, irrigationRateGPM: number) => {
        let psiLoss: number;
        if (typeof cFactor !== "undefined" && typeof insideDiameterInches !== "undefined" &&
            typeof pipeLengthFeet !== "undefined" && typeof irrigationRateGPM !== "undefined"
        )
        {
            //Hazen-Williams equation
            psiLoss = (4.52 * pipeLengthFeet * Math.pow(irrigationRateGPM, 1.852))/(Math.pow(cFactor, 1.852) * Math.pow(insideDiameterInches, 4.8704));
        }

        return psiLoss;
    }

    useEffect(() => {
        let elevationPsiLoss: number;
        let totalPsiLoss: number;
        if (typeof mainLinePressureLoss.pumpToPivotElevation !== "undefined" && typeof mainLinePressureLoss.psiLoss !== "undefined")
        {
            elevationPsiLoss = mainLinePressureLoss.pumpToPivotElevation * 0.432;
            totalPsiLoss = elevationPsiLoss + mainLinePressureLoss.psiLoss;
        }

        setMainLinePressureLoss({...mainLinePressureLoss, elevationPsiLoss, totalPsiLoss});
    }, [mainLinePressureLoss.pumpToPivotElevation, mainLinePressureLoss.psiLoss]);

    const calculateVelocityFeetPerSecond = (irrigationRateGPM: number, insideDiameterInches: number) => {
        let velocityFeetPerSecond: number;
        if (typeof irrigationRateGPM !== "undefined" && typeof insideDiameterInches !== "undefined")
        {
            velocityFeetPerSecond = (0.408 * irrigationRateGPM)/Math.pow(insideDiameterInches,2);
        }

        return velocityFeetPerSecond;
    }

    var supportedMaxPSIValues: number[];
    if (calculatedPipeDiameter.pipeStyle === PipeStyle.IPS){
        supportedMaxPSIValues = [63, 100, 125, 160, 200];
    }
    else {
        supportedMaxPSIValues = [80, 100, 125, 160, 200];
    }

    var supportedPipeSizes: number[] = [];

    switch (calculatedPipeDiameter.maxPSI) {
        case 63:
            if (calculatedPipeDiameter.pipeStyle === PipeStyle.IPS){
                supportedPipeSizes = [6, 8, 10, 12];
            }
            break;
        case 80:
            if (calculatedPipeDiameter.pipeStyle === PipeStyle.PIP) {
                supportedPipeSizes = [6, 8, 10, 12, 15, 18, 21, 24, 27];
            }
            break;
        case 100:
            if (calculatedPipeDiameter.pipeStyle === PipeStyle.IPS){
                supportedPipeSizes = [4, 5, 6, 8, 10, 12];
            }
            else {
                supportedPipeSizes = [6, 8, 10, 12, 15, 18, 21, 24, 27];
            }
            break;
        case 125:
            if (calculatedPipeDiameter.pipeStyle === PipeStyle.IPS){
                supportedPipeSizes = [3, 4, 5, 6, 8, 10, 12];
            }
            else {
                supportedPipeSizes = [6, 8, 10, 12, 15, 18, 21, 24, 27];
            }
            break;
        case 160:
            if (calculatedPipeDiameter.pipeStyle === PipeStyle.IPS){
                supportedPipeSizes = [2, 3, 4, 5, 6, 8, 10, 12];
            }
            else {
                supportedPipeSizes = [15, 18];
            }
            break;
        case 200:
            if (calculatedPipeDiameter.pipeStyle === PipeStyle.IPS){
                supportedPipeSizes = [2, 3, 4, 6, 8, 10, 12];
            }
            else {
                supportedPipeSizes = [15];
            }
            break;
    }

    const enumToNumericValues = (enumValue: object) => {
        let vals: number[] = [];
        for (const keyString in enumValue) {
            const key = Number(keyString);
            if (isNaN(key)) { continue; }
            vals.push(key);
        }
        return vals;
    }

    const formatPipeMaterial = (material: PipeMaterial) => {
        if (material === PipeMaterial.HDPE) return "HDPE";
        if (material === PipeMaterial.CastIron10YearsOld) return "Cast Iron - 10 years old";
        if (material === PipeMaterial.CastIron20YearsOld) return "Cast Iron - 20 years old";
        if (material === PipeMaterial.CastIron30YearsOld) return "Cast Iron - 30 years old";
        if (material === PipeMaterial.CastIron40YearsOld) return "Cast Iron - 40 years old";

        let s_ = PipeMaterial[material].split(/(?=[A-Z])/);
        return s_.join(" ");
    }

    const [expanded, setExpanded] = React.useState(false);

    let pipeMaterialOptions: JSX.Element[] = [];
    enumToNumericValues(PipeMaterial).forEach(material => {
        if (material === PipeMaterial.Custom){
            pipeMaterialOptions.push(<MenuItem key={material} value={material}><em>{formatPipeMaterial(material)}</em></MenuItem>);
        }
        else {
            pipeMaterialOptions.push(<MenuItem key={material} value={material}>{formatPipeMaterial(material)}</MenuItem>);
        }
    });

    return (
        <ToolsPageBase title="Main Line Pressure Loss">
             <Card className="no-print" variant="outlined" style={{marginBottom: 10}}>
                <CardContent>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary">Common Pipe Sizes / Specifications <ExpandMore
                        expand={expanded}
                        onClick={() => {
                            setExpanded(!expanded);
                        }}
                        aria-expanded={expanded}
                        aria-label="show more"
                        >
                        <ExpandMoreIcon />
                    </ExpandMore></Typography>
                    <Collapse in={!expanded} timeout="auto" unmountOnExit>
                        <Typography  sx={{ fontSize: 14 }} color="text.secondary">Optionally expand and use this tool to help you find inside diameters for common pipe sizes and specifications.</Typography>
                    </Collapse>
                    <Collapse in={expanded} timeout="auto" unmountOnExit>
                        <Stack direction="row">
                            <FormControl variant="filled" size="small" style={{minWidth: 150, marginRight: 10}}>
                                <InputLabel>Style</InputLabel>
                                <Select
                                    value={calculatedPipeDiameter.pipeStyle}
                                    onChange={(ev) => {
                                        const val = +ev.target.value;
                                        const calculatedInsideDiameterInches = calculateInsideDiameter(val, calculatedPipeDiameter.maxPSI, calculatedPipeDiameter.pipeSize);
                                        const psiLoss = calculatePsiLoss(mainLinePressureLoss.cFactor, calculatedInsideDiameterInches, mainLinePressureLoss.pipeLengthFeet, mainLinePressureLoss.irrigationRateGPM);
                                        setCalculatePipeDiameter({...calculatedPipeDiameter, pipeStyle: val, calculatedInsideDiameterInches});
                                        setMainLinePressureLoss({...mainLinePressureLoss, insideDiameterInches: calculatedInsideDiameterInches, psiLoss});
                                    }}
                                >
                                <MenuItem key={PipeStyle.IPS} value={PipeStyle.IPS}>IPS</MenuItem>
                                <MenuItem key={PipeStyle.PIP} value={PipeStyle.PIP}>PIP</MenuItem>
                                </Select>
                            </FormControl> 
                            <FormControl variant="filled" size="small" style={{minWidth: 150, marginRight: 10}}>
                                <InputLabel>Max PSI</InputLabel>
                                <Select
                                    value={calculatedPipeDiameter.maxPSI}
                                    onChange={(ev) => {
                                        const val = +ev.target.value;
                                        const calculatedInsideDiameterInches = calculateInsideDiameter(calculatedPipeDiameter.pipeStyle, val, calculatedPipeDiameter.pipeSize);
                                        const psiLoss = calculatePsiLoss(mainLinePressureLoss.cFactor, calculatedInsideDiameterInches, mainLinePressureLoss.pipeLengthFeet, mainLinePressureLoss.irrigationRateGPM);
                                        setCalculatePipeDiameter({...calculatedPipeDiameter, maxPSI: val, calculatedInsideDiameterInches});
                                        setMainLinePressureLoss({...mainLinePressureLoss, insideDiameterInches: calculatedInsideDiameterInches, psiLoss});
                                    }}
                                >
                                {supportedMaxPSIValues.map(v => <MenuItem key={v} value={v}>{v}</MenuItem>)}
                                </Select>
                            </FormControl> 
                            <FormControl variant="filled" size="small" style={{minWidth: 150, marginRight: 10}}>
                                <InputLabel>Size</InputLabel>
                                <Select
                                    value={calculatedPipeDiameter.pipeSize}
                                    onChange={(ev) => {
                                        const val = +ev.target.value;
                                        const calculatedInsideDiameterInches = calculateInsideDiameter(calculatedPipeDiameter.pipeStyle, calculatedPipeDiameter.maxPSI, val);
                                        const psiLoss = calculatePsiLoss(mainLinePressureLoss.cFactor, calculatedInsideDiameterInches, mainLinePressureLoss.pipeLengthFeet, mainLinePressureLoss.irrigationRateGPM);
                                        setCalculatePipeDiameter({...calculatedPipeDiameter, pipeSize: val, calculatedInsideDiameterInches});

                                        const velocityFeetPerSecond = calculateVelocityFeetPerSecond(mainLinePressureLoss.irrigationRateGPM, val);
                                        setMainLinePressureLoss({...mainLinePressureLoss, insideDiameterInches: calculatedInsideDiameterInches, psiLoss, velocityFeetPerSecond});
                                    }}
                                >
                                {supportedPipeSizes.map(v => <MenuItem key={v} value={v}>{v}</MenuItem>)}
                                </Select>
                            </FormControl>
                            <TextField
                                variant="filled"
                                size="small"
                                disabled
                                type="number"
                                label={"Pipe Inside Diameter"}       
                                InputLabelProps={{shrink: calculatedPipeDiameter.calculatedInsideDiameterInches} as any}         
                                value={calculatedPipeDiameter.calculatedInsideDiameterInches || ""}    
                                InputProps={{ endAdornment: <InputAdornment position="end">in</InputAdornment> }}       
                            />                       
                        </Stack> 
                    </Collapse>                 
                </CardContent>
            </Card>
            <Card variant="outlined" style={{marginBottom: 10}}>
                <CardContent>
                    <Stack direction="row" style={{ justifyContent: 'space-between', width: '100%'}}>
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>Pressure Loss Equation <Tooltip title="Fill out the boxes below to calculate PSI loss. If you do not know the pipe inside diameter, use the section above. If your pipe material is not listed, select Custom and enter the C-Factor (roughness coefficient) manually">
                                    <IconButton className="no-print" sx={{color: "info.main"}}>
                                        <HelpOutline />
                                    </IconButton>
                                    </Tooltip></Typography>
                        {
                            typeof mainLinePressureLoss.velocityFeetPerSecond !== "undefined" && 
                            <Typography sx={{ fontSize: 14 }} color={mainLinePressureLoss.velocityFeetPerSecond > 5 ? "red" :"info.main"} gutterBottom>Velocity: {mainLinePressureLoss.velocityFeetPerSecond.toFixed(2)} ft/sec</Typography>
                        }
                    </Stack>
                    <Stack direction="row">
                        <TextField
                                variant="filled"
                                size="small"
                                type="number"
                                style={{marginRight: 10}}
                                label={"Pipe Length"}      
                                onChange={(ev) => {
                                    const val = +ev.target.value;
                                    const psiLoss = calculatePsiLoss(mainLinePressureLoss.cFactor, mainLinePressureLoss.insideDiameterInches, val, mainLinePressureLoss.irrigationRateGPM);
                                    setMainLinePressureLoss({...mainLinePressureLoss, pipeLengthFeet: val, psiLoss});
                                }}          
                                value={mainLinePressureLoss.pipeLengthFeet}           
                                InputProps={{ endAdornment: <InputAdornment position="end">ft</InputAdornment>, }}       
                            />
                            <TextField
                                variant="filled"
                                size="small"                           
                                type="number"
                                style={{marginRight: 10}}
                                label={"Irrigation Rate"}                
                                value={mainLinePressureLoss.irrigationRateGPM}
                                onChange={(ev) => {
                                    const val = +ev.target.value;
                                    const psiLoss = calculatePsiLoss(mainLinePressureLoss.cFactor, mainLinePressureLoss.insideDiameterInches, mainLinePressureLoss.pipeLengthFeet, val);
                                    const velocityFeetPerSecond = calculateVelocityFeetPerSecond(val, mainLinePressureLoss.insideDiameterInches);
                                    setMainLinePressureLoss({...mainLinePressureLoss, irrigationRateGPM: val, psiLoss, velocityFeetPerSecond});
                                }}         
                                InputProps={{ endAdornment: <InputAdornment position="end">GPM</InputAdornment>, }}         
                            />
                            <FormControl variant="filled" size="small" style={{minWidth: 150, marginRight: 10}}>
                                <InputLabel>Pipe Material</InputLabel>
                                <Select
                                    value={mainLinePressureLoss.pipeMaterial}
                                    onChange={(ev) => {
                                        const val = +ev.target.value
                                        const cFactor = getCFactor(val);
                                        const psiLoss = calculatePsiLoss(cFactor, mainLinePressureLoss.insideDiameterInches, mainLinePressureLoss.pipeLengthFeet, mainLinePressureLoss.irrigationRateGPM);
                                        setMainLinePressureLoss({...mainLinePressureLoss, cFactor, psiLoss, pipeMaterial: val});
                                    }}
                                >
                                    {pipeMaterialOptions}
                                </Select>
                            </FormControl> 
                            <TextField
                                variant="filled"
                                style={{marginRight: 10}}
                                size="small"
                                type="number"
                                disabled={mainLinePressureLoss.pipeMaterial !== PipeMaterial.Custom}
                                label={"C-Factor"}               
                                InputLabelProps={{shrink: mainLinePressureLoss.cFactor} as any}     
                                value={mainLinePressureLoss.cFactor || ""}
                                onChange={(ev) => {
                                    const cFactor = +ev.target.value;
                                    const psiLoss = calculatePsiLoss(cFactor, mainLinePressureLoss.insideDiameterInches, mainLinePressureLoss.pipeLengthFeet, mainLinePressureLoss.irrigationRateGPM);
                                    setMainLinePressureLoss({...mainLinePressureLoss, cFactor, psiLoss});
                                }}
                            />
                            <TextField
                                variant="filled"
                                style={{marginRight: 10}}
                                size="small"
                                type="number"
                                label={"Pipe Inside Diameter"}   
                                InputLabelProps={{shrink: mainLinePressureLoss.insideDiameterInches} as any}                 
                                value={mainLinePressureLoss.insideDiameterInches}     
                                onChange={(ev) => {
                                    const val = +ev.target.value;
                                    const psiLoss = calculatePsiLoss(mainLinePressureLoss.cFactor, val, mainLinePressureLoss.pipeLengthFeet, mainLinePressureLoss.irrigationRateGPM);
                                    const velocityFeetPerSecond = calculateVelocityFeetPerSecond(mainLinePressureLoss.irrigationRateGPM, val);
                                    setMainLinePressureLoss({...mainLinePressureLoss, insideDiameterInches: val, psiLoss, velocityFeetPerSecond});
                                }}
                                InputProps={{ endAdornment: <InputAdornment position="end">in</InputAdornment>, }}           
                            />
                            <TextField
                                variant="filled"
                                size="small"
                                type="number"
                                label={"PSI Loss"}         
                                disabled       
                                InputLabelProps={{shrink: mainLinePressureLoss.psiLoss} as any}   
                                value={typeof mainLinePressureLoss.psiLoss !== "undefined" ? mainLinePressureLoss.psiLoss.toFixed(3) : ""}  
                                onChange={(ev) => setMainLinePressureLoss({...mainLinePressureLoss, psiLoss: +ev.target.value})}         
                            />
                    </Stack>                    
                </CardContent>
            </Card>
            <Card variant="outlined" style={{marginBottom: 10}}>
                <CardContent>
                    <TextField
                        variant="filled"
                        style={{marginRight: 10}}
                        size="small"
                        type="number"
                        label={"Pump to Pivot Elevation (+/-)"}                
                        value={mainLinePressureLoss.pumpToPivotElevation}
                        onChange={(ev) => setMainLinePressureLoss({...mainLinePressureLoss, pumpToPivotElevation: +ev.target.value})}           
                    />
                    <TextField
                        variant="filled"
                        style={{marginRight: 10}}
                        size="small"
                        disabled
                        type="number"
                        label={"Elevation PSI Loss"}    
                        InputLabelProps={{shrink: typeof mainLinePressureLoss.elevationPsiLoss !== "undefined"} as any}               
                        value={typeof mainLinePressureLoss.elevationPsiLoss !== "undefined" ? mainLinePressureLoss.elevationPsiLoss.toFixed(3): ""}      
                    />
                    <TextField
                        variant="filled"
                        style={{marginRight: 10}}
                        size="small"
                        type="number"
                        disabled
                        label={"Total PSI Loss"}  
                        InputLabelProps={{shrink: typeof mainLinePressureLoss.totalPsiLoss !== "undefined"} as any}                
                        value={typeof mainLinePressureLoss.totalPsiLoss !== "undefined" ? mainLinePressureLoss.totalPsiLoss.toFixed(3): ""}              
                    />                
                </CardContent>
            </Card>
            <Stack direction="row" style={{justifyContent: 'flex-end'}}>
                {
                    typeof mainLinePressureLoss.totalPsiLoss !== "undefined" && <Button className="no-print" variant="outlined" style={{marginRight: 5}} onClick={() => {window.print();}}>Print</Button>
                }
                <Button className="no-print" variant="contained" style={{marginRight: 5}} onClick={() => window.close()}>Close</Button>
            </Stack>
        </ToolsPageBase>
    );
};

export default MainLinePressureLossPage;