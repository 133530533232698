import { ISystemBase, PaymentTypes, SystemTypes } from "rdptypes/project/ISystemBase.AutoGenerated";
import { IRule } from "rdptypes/roe/IRule";

export default {
    pages: [
        {
            changeRequiresNewSprinklers: false,
            id: "proposalInformation",
            disableDealerSettings: true,
            title:{"en" :  "Proposal Information", "es": "Información de la Oferta"},
            position: 16,
            visible: () => true,
            components: [
                {
                    type: "text",
                    fieldPath: "QuoteProperties.ProposalInformation.customerComments",
                    title:{"en" :  "Customer Notes", "es": "Notas del Cliente"},
                    multiline: true,
                },
                {
                    type: "text",
                    fieldPath: "QuoteProperties.ProposalInformation.tradeinNotes",
                    title: {"en" : "Trade-In Notes", "es": "Notas de Canje"},
                    multiline: true,
                },
                {
                    type: "text",
                    fieldPath: "QuoteProperties.ProposalInformation.factoryNotes",
                    title: {"en" :  "Factory Notes", "es": "Notas de Fábrica"},
                    multiline: true,
                },
                {
                    type: "text",
                    fieldPath: "QuoteProperties.ProposalInformation.PONumber",
                    title: {"en" :  "PO Number", "es": "Número PO"},
                    multiline: false,
                },
                {
                    type: "choice",
                    fieldPath: "QuoteProperties.ProposalInformation.paymentTerms",
                    title: {"en" : "Payment Terms", "es": "Términos de Pago"},
                    options: [
                        { id: "Cash", title:{"en" :  "Cash", "es": "Efectivo" }},
                        { id: "ReinkeHuntington", title: "Reinke Finance / Huntington Bank (US)" },
                        { id: "ReinkeCoastCapital", title: "Reinke Finance / Coast Capital (Canada)"},
                        { id: "AgDirect", title: "AgDirect" },
                        { id: "DiversifiedFS", title: "Diversified Financial Services" },
                    ],
                    enumNameHint: "PaymentTermsType",
                    includeOnlyValidationRules: [],
                },
                {
                    type: "boolean",
                    fieldPath: "QuoteProperties.ProposalInformation.reinkeFinanceInterestBuyDown",
                    title: {"en" : "Reinke Finance Interest Buy Down"},
                    visible: (sys: ISystemBase) => sys.QuoteProperties.ProposalInformation?.paymentTerms === PaymentTypes.ReinkeHuntington || sys.QuoteProperties.ProposalInformation?.paymentTerms === PaymentTypes.ReinkeCoastCapital,
                    marginBottom: true
                },
                {
                    type: "choice",
                    fieldPath: "QuoteProperties.ProposalInformation.shipVia",
                    title: {"en" : "Ship Via", "es": "Enviar Vía"},
                    options: [
                        { id: "Dealer", title: "Dealer" },
                        { id: "Customer", title: "Customer" },
                        { id: "ADM", title: "ADM" },
                        { id: "Bazentine", title: "Bazentine" },
                        { id: "BBI", title: "BBI" },
                        { id: "Boyd", title: "Boyd" },
                        { id: "Daws", title: "Daws" },
                        { id: "GBI", title: "GBI" },
                        { id: "Ferguson", title: "Ferguson" },
                        { id: "Forbes", title: "Forbes" },
                        { id: "Hiway Blues", title: "Hiway Blues" },
                        { id: "Hunt Transportation", title: "Hunt Transportation" },
                        { id: "Johnson Electric", title: "Johnson Electric" },
                        { id: "Keim", title: "Keim" },
                        { id: "Mer-Dell", title: "Mer-Dell" },
                        { id: "MGH", title: "MGH" },
                        { id: "Olson", title: "Olson" },
                        { id: "Postma", title: "Postma" },
                        { id: "Prime", title: "Prime" },
                        { id: "Q-Line", title: "Q-Line" },
                        { id: "Reflex", title: "Reflex" },
                        { id: "Rezac", title: "Rezac" },
                        { id: "Ruggles", title: "Ruggles" },
                        { id: "S&D", title: "S&D" },
                        { id: "Schwartz", title: "Schwartz" },
                        { id: "Searcy", title: "Searcy" },
                        { id: "Soderquist", title: "Soderquist" },
                        { id: "Star Transport", title: "Star Transport" },
                        { id: "Stevens", title: "Stevens" },
                        { id: "Thiesen", title: "Thiesen" },
                        { id: "US Express", title: "US Express" },
                        { id: "Watkins", title: "Watkins" },
                    ],
                    enumNameHint: "ShipViaType",
                },
                {
                    // Shipping date calendar is hidden for ancillary orders. MapicsBuilder class uses today's date for these
                    // orders.
                    visible: (system: ISystemBase) => system.SystemProperties?.SystemType !== SystemTypes.Ancillary,
                    type: "shippingDate",
                    fieldPath: "QuoteProperties.ProposalInformation.requestedShippingDate",
                    title: {"en" : "Requested Ship Date","es": "Fecha de Envío Solicitada"},
                },
                {
                    type:"card",
                    title:"Property Insurance",
                    children: [
                        {
                            type: "boolean",
                            fieldPath: "QuoteProperties.ProposalInformation.hasMidAmericaRiskInsurance",
                            title: "UNICO",
                        }
                    ]

                },
                {
                    type: "proposalInformationPricingTable",
                    layoutHint: "secondary"
                },
                {
                    type: "proposalInformationDiscountsSection"
                }
            ],
        },
    ],
    rules: [
        {
            apply: (system, user, grower, quick) => (system.QuoteProperties?.ProposalInformation?.pricing?.tmDiscountPercent ?? 0) > user.discounts.tm ? [{
                id: "proposalInformation.pricing.tmDiscountPercent.tooLarge",
                description: "TM Discount Percentage Too Large.",
                severity: "error",
                targets: [
                    "proposalInformation.pricing.tmDiscountPercent"
                ]
            }] : []
        },
        {
            apply: (system, user, grower, quick) =>
            system.SystemProperties.SystemType !== SystemTypes.Ancillary &&
            system.QuoteProperties?.ProposalInformation?.paymentTerms &&
            !system.QuoteProperties?.ProposalInformation?.requestedShippingDate ? [{
                id: "proposalInformation.requestedShippingDate.isSet",
                description: "Requested Ship Date must be set.",
                severity: "error",
                targets: [
                    "QuoteProperties.ProposalInformation.requestedShippingDate"
                ]
            }] : []
        },
        {
            apply: (system, user, grower, quick) =>
            system.QuoteProperties?.ProposalInformation?.paymentTerms === PaymentTypes.ReinkeCoastCapital &&
            grower.shippingAddress.country !== "CAN" ? [{
                id: "proposalInformation.reinkeCoastCapital.country",
                description: "Coast Capital finance can only be selected for growers with a shipping address in Canada.",
                severity: "error",
                targets: [
                    "QuoteProperties.ProposalInformation.paymentTerms"
                ]
            }] : []
        },
        {
            apply: (system, user, grower, quick) =>
            system.QuoteProperties?.ProposalInformation?.paymentTerms === PaymentTypes.ReinkeHuntington &&
            grower.shippingAddress.country !== "USA" ? [{
                id: "proposalInformation.reinkeHuntington.country",
                description: "Huntington finance can only be selected for growers with a shipping address in the USA.",
                severity: "error",
                targets: [
                    "QuoteProperties.ProposalInformation.paymentTerms"
                ]
            }] : []
        },
        {
            apply: (system, user, grower, quick) =>
            system.QuoteProperties?.ProposalInformation?.paymentTerms === PaymentTypes.AgDirect &&
            grower.shippingAddress.country !== "USA" ? [{
                id: "proposalInformation.agDirect.country",
                description: "AgDirect finance can only be selected for growers with a shipping address in the USA.",
                severity: "error",
                targets: [
                    "QuoteProperties.ProposalInformation.paymentTerms"
                ]
            }] : []
        }
        ,
        {
            apply: (system, user, grower, quick) =>
            system.QuoteProperties?.ProposalInformation?.paymentTerms === PaymentTypes.DiversifiedFS &&
            grower.shippingAddress.country !== "USA" ? [{
                id: "proposalInformation.diversifiedFs.country",
                description: "Diversified Financial Services finance can only be selected for growers with a shipping address in the USA.",
                severity: "error",
                targets: [
                    "QuoteProperties.ProposalInformation.paymentTerms"
                ]
            }] : []
        }
    ] as IRule[]
};