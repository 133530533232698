import { GeneratorModels, GeneratorOptions, HoseFeedTypes, ISystemBase, SystemTypes } from "rdptypes/project/ISystemBase.AutoGenerated";
import { IRule } from "rdptypes/roe/IRule";
import { systemIsSugargator } from "../../../../../ProposalHelpers";
import QuoteClass from "../../../../../roe_migration/QuoteClass";
import { IsPivotingLateral } from "../../../../../roe_migration/SystemFunctions";
import { enableJackKit } from "../../../../../SystemHelpers";

export default {
    pages: [       
        {
            changeRequiresNewSprinklers: false,
            id: "options",
            title: {"en" : "Options & Accessories", "es": "Opciones & Accesorios"},
            position: 10,
            disableDealerSettings: true,
            visible: (sys: ISystemBase) => sys.SystemProperties.SystemType !== SystemTypes.SwingArmRetro && sys.SystemProperties.SystemType !== SystemTypes.Ancillary,
            components: [
                {
                    type: "choice",
                    fieldPath: "Options.ReplaceSystem",
                    title: {"en" : "This system replaces", "es": "El Sistema reemplaza"},
                    options: [
                        { id: "New_Development", title: 'New Development'},                      
                        { id: "Flood", title: {"en" : 'Flood system', "es": "Sistema inundación"}},                      
                        { id: "SideRoll_Traveler", title: 'Side Roll or Traveler'},                      
                        { id: "TLSystem", title: 'T-L System'},                      
                        { id: "Zimmatic", title: 'Zimmatic System'},                                       
                        { id: "Valley", title: 'Valley System'},                                       
                        { id: "Reinke", title: 'Reinke System'},                                         
                        { id: "Other", title: 'Other System'},                                     
                    ],
                }, 
                {
                    type: "booleangroup",
                    layoutHint: 'secondary',                
                    components: [
                        { type: "boolean", title: {"en" : "Flush Valve", "es": "Válvula de Descagra"}, fieldPath: "Options.FlushValve"},
                        { type: "boolean", title: {"en" : "Secondary Safety Valve", "es": "Válvula de Seguridad Secundaria"}, fieldPath: "Options.SafetyValve", visible: (sys: ISystemBase) => sys.SystemProperties.SystemType === SystemTypes.HoseFeedMaxigator && sys.Lateral.HoseFeed.HoseFeedType === HoseFeedTypes.DoubleEndFeed},
                        { type: "boolean", title: {"en" : "Intake Drain Valve", "es": "Válvula de Drenaje de Entrada"}, fieldPath: "Options.IntakeDrainValve", visible: (sys: ISystemBase) => sys.SystemProperties.SystemType === SystemTypes.CanalFeedMaxigator},
                        { type: "boolean", title: {"en" : "Spare Bolt Package", "es": "Paquete de Tornillería de Repuesto"}, fieldPath: "Options.SpareBoltPackage"},
                        { type: "boolean", title: {"en" : "Reinke Sign/Leg Mount Option", "es": "Montaje Logo Reinke en Pata"}, fieldPath: "Options.ReinkeSignMount"},
                        { type: "boolean", title: "Mount Kit for Chem Tank", fieldPath: "Options.ChemTankMountKit", visible: (sys: ISystemBase) => sys.SystemProperties.SystemType === SystemTypes.HoseFeedMaxigator && sys.Lateral?.HoseFeed?.HoseFeedType === HoseFeedTypes.PivotingLateral},
                        { type: "boolean", title: "Aux Access Step", fieldPath: "Options.AuxAccessStep", visible: (sys: ISystemBase) => sys.SystemProperties.SystemType === SystemTypes.HoseFeedMaxigator && sys.Lateral?.HoseFeed?.HoseFeedType === HoseFeedTypes.PivotingLateral},
                        { type: "boolean", title: {"en" : "DEF Safety Valve", "es": "Válvula de seguridad Alimentado en ambos lados."}, fieldPath: "Options.DoubleEndFeedSafetyValve", visible: (sys: ISystemBase) => sys.SystemProperties.SystemType === SystemTypes.HoseFeedMaxigator && sys.Lateral.HoseFeed.HoseFeedType === HoseFeedTypes.DoubleEndFeed},
                    ],
                },
                {
                    type: "card",
                    visible: enableJackKit,
                    children: [
                        {
                            type: "choice",
                            fieldPath: "Options.JackKit",
                            title: {"en" : "Jack Kits", "es": "Kits de Remolcado"},
                            options: [
                                { id: "None", title: {"en" : 'None', "es":"Ninguno"}, },                                            
                                { id: "Manual", title: 'Manual' }                                        
                            ],
                            enumNameHint: "JackKitTypes",
                        },   
                        { type: "boolean", title: {"en" : "Jack Foot Kit", "es": "Kit de Gato de Pie"}, fieldPath: "Options.JackFootKit"},  
                    ],
                },                       
                {
                    type: "card",
                    title: {"en" : "Barricades and Trailing Tire Plate","es": "Barricadas y Placa p/rueda posterior"},
                    children: [
                        {
                            type: "number",
                            fieldPath: "Options.MovableBarricades",
                            title: {"en" : "Movable", "es": "Movible"},
                            default: 0,
                            units: {"en" : "Quantity", "es" : "Calidad"}
                        },  
                        {
                            type: "number",
                            fieldPath: "Options.StandardLowProfileStationaryBarricades",
                            title: {"en" : "Standard/Low Profile Stationary","es": "Estacionario Standard/Bajo Perfil"},
                            default: 0,
                            units: {"en" : "Quantity", "es" : "Calidad"}
                        },  
                        {
                            type: "number",
                            fieldPath: "Options.SugargatorStationaryBarricades",
                            title:{"en" :  "Sugargator Stationary",  "es": "Sugargator Estacionario"},
                            default: 0,
                            units: {"en" : "Quantity", "es" : "Calidad"},
                            visible: (sys: ISystemBase) => {
                                let quote = new QuoteClass(sys);
                                if (systemIsSugargator(quote)){
                                    let canHaveBarricades = false;
                                    for (let i = 1; i <= quote.RightTowersClass.TowerCount(); i++){
                                        if (quote.RightTowersClass.Tower(i).TowerAutoReverse || quote.RightTowersClass.Tower(i).TowerAutoStop){
                                            canHaveBarricades = true;
                                        }
                                    }
                                    for (let i = 1; i <= quote.LeftTowersClass.TowerCount(); i++){
                                        if (quote.RightTowersClass.Tower(i).TowerAutoReverse || quote.RightTowersClass.Tower(i).TowerAutoStop){
                                            canHaveBarricades = true;
                                        }
                                    }
                                    return canHaveBarricades;
                                }
                                return false;
                            }
                        },  
                        {
                            type: "number",
                            fieldPath: "Options.ProxSwitchBarricades",
                            title: "Additional Proximity Switch Barricades",
                            default: 0,
                            units: {"en" : "Quantity", "es" : "Calidad"},
                            visible: (sys: ISystemBase) => IsPivotingLateral(sys)
                        },  
                    ],
                },
                {
                    type: "card",
                    children: [
                        {
                            type: "choice",
                            fieldPath: "Options.GeneratorOption",
                            title: {"en" : "Generator", "es": "Generador"},
                            options: [
                                { id: "None", title: {"en" : 'None', "es": "Ninguno"}, },                                           
                                { id: "GeneratorOnly", title: 'Generator Only'},                                           
                                { id: "GeneratorSet", title: 'GenSet w/Kubota'},                                       
                            ],
                            enumNameHint: "GeneratorOptions",
                            disabled: (sys: ISystemBase) => !sys.ControlPanel.GenStartControl,
                            includeOnlyValidationRules: []
                        },  
                        { type: "boolean", title: "Slide Rails", fieldPath: "Options.GeneratorSlideRails"},
                        { type: "boolean", title: "Murphy Control Panel", fieldPath: "Options.EngDigitalPanel"},
                        { type: "boolean", title: "Fuel Tank", fieldPath: "Options.FuelTank", marginBottom: true},
                        {
                            type: "choice",
                            fieldPath: "Options.GeneratorModel",
                            title: "Generator Power",
                            //Todo add description: 7.4 kW or more req.
                            options: [
                                { id: "None", title: {"en" : 'None', "es": "Ninguno"}, },                                            
                                { id: "Lima_10_0_kW", title: 'Lima 10 kW' } ,                                       
                                { id: "Lima_12_5_kW", title: 'Lima 12.5 kW' }  ,                                      
                                { id: "Lima_15_0_kW", title: 'Lima 15 kW' },                                        
                                { id: "Lima_20_0_kW", title: 'Lima 20 kW' },                                        
                                { id: "Lima_25_0_kW", title: 'Lima 25 kW' },                                        
                                { id: "Lima_30_0_kW", title: 'Lima 30 kW' },                                        
                                { id: "MeccAlte_05_0_kW", title: 'Mecc-Alte 5 kW' },                                        
                                { id: "MeccAlte_07_5_kW", title: 'Mecc-Alte 7.5 kW' },                                        
                                { id: "MeccAlte_10_0_kW", title: 'Mecc-Alte 10 kW' },                                        
                                { id: "MeccAlte_15_0_kW", title: 'Mecc-Alte 15 kW' },                                        
                                { id: "MeccAlte_18_0_kW", title: 'Mecc-Alte 18 kW' },                                        
                                { id: "MeccAlte_25_0_kW", title: 'Mecc-Alte 25 kW' },                                        
                                { id: "MeccAlte_27_0_kW", title: 'Mecc-Alte 27 kW' },                                        
                                { id: "MeccAlte_30_0_kW", title: 'Mecc-Alte 30 kW' },                                        
                            ],
                            enumNameHint: "GeneratorModels",
                            visible: (sys: ISystemBase) => sys.ControlPanel.GenStartControl
                                && (sys.Options.GeneratorOption ?? GeneratorOptions.None) !== GeneratorOptions.None,
                        },  
                        {
                            type: "choice",
                            fieldPath: "Options.GeneratorFrameType",
                            title: "Generator Frame Type",
                            options: [
                                { id: "None", title: {"en" : 'None', "es": "Ninguno"}, },     
                                { id: "OpenFrame", title: 'Open Frame'},                                            
                                { id: "EnclosedFrame", title: 'Enclosed Frame' } ,                                      
                            ],
                            enumNameHint: "GeneratorFrameTypes",
                            visible: (sys: ISystemBase) => sys.ControlPanel.GenStartControl
                                && (sys.Options.GeneratorOption ?? GeneratorOptions.None) !== GeneratorOptions.None,
                    },          
                    ]
                },                 
                {
                    type: "number",
                    fieldPath: "Options.ElectricTowKit",
                    title: {"en" : "Electric Tow kits", "es": "Kit Eléctico p/Remolcables"},
                    default: 0,
                    units: {"en" : "Quantity", "es" : "Calidad"}
                },  
            ],
        },
         
    ],
    rules: [
        {
            apply: (system, user, grower, quick) =>
                system.Options.GeneratorOption === GeneratorOptions.GeneratorOnly
                && system.Options.GeneratorModel === GeneratorModels.Lima_25_0_kW ? [{
                id: "RDC81.Options.GeneratorModel",
                description: "The Lima 25 kW generator is no longer available.",
                severity: "error",
                targets: [
                    "Options.GeneratorModel"
                ]
            }] : []
        },
    ] as IRule[],
};