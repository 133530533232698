import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import { Button, Menu, MenuItem } from "@mui/material";
import i18next, { t } from "i18next";
import { SystemTypes } from "rdptypes/project/ISystemBase.AutoGenerated";
import * as React from "react";
import { useContext, useState } from "react";
import CenterPivotGeometryHelper from "../../../../GeometryHelpers/SystemGeometryHelpers/CenterPivotGeometryHelper";
import LateralGeometryHelper from "../../../../GeometryHelpers/SystemGeometryHelpers/LateralGeometryHelper";
import { MapContext } from "../../../../components/Map/mapContext";
import DbCtx from "../../../../db/DbCtx";

interface IProps {
    projectId: string;
    layoutId: string;
    navigateOnSystem: (systemType: SystemTypes) => void;
    selectedSystemId?: string;
}

const SystemsMenu: React.FC<IProps> = ({
    layoutId, projectId, navigateOnSystem, selectedSystemId
}) => {
    const dbState = useContext(DbCtx);
    const map = useContext(MapContext);

    const dbProject = dbState.projects[projectId!];
    const project = dbProject.state;
    const layout = project.layouts[layoutId!];

    const [systemsMenuTarget, setSystemsMenuTarget] = useState<null | HTMLElement>(null);

    const openSystemsMenu = (event: React.MouseEvent<HTMLElement>) => {
        setSystemsMenuTarget(event.currentTarget);
    };

    const closeSystemsMenu = () => {
        setSystemsMenuTarget(null);
    };

    const layoutSystems = Object.entries(layout.systems);
    

    return (
        <>
            <Button
                endIcon={<ArrowDropDown />}
                onClick={openSystemsMenu}
                sx={{ color: "inherit" }}
            >
                <div style={{overflow: 'hidden', textOverflow: 'ellipsis', textTransform: 'none'}}>
                {
                    selectedSystemId 
                        ? layout.systems[selectedSystemId].name
                        : <em>{i18next.format(i18next.t('systems'), 'capitalize')}</em>
                }
                </div>
                
            </Button>

            <Menu
                anchorEl={systemsMenuTarget}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                open={!!systemsMenuTarget}
                onClose={closeSystemsMenu}
            >
            {
                layoutSystems.length === 0 &&
                    <MenuItem
                        sx={{ color: "inherit" }}
                        disabled={true}
                    >
                        {i18next.format(t('none'), 'capitalize')}
                    </MenuItem>
            }
                {
                    layoutSystems
                        .map(([systemId, system]) => (
                            <MenuItem key={systemId} sx={{ color: "inherit" }}
                                onClick={() => {
                                    if (map) {
                                        if (system.SystemProperties.SystemType === SystemTypes.CenterPivot) {
                                            const g = new CenterPivotGeometryHelper({
                                                project,
                                                systemId,
                                                layoutId
                                            })
                                            map.changeMode("center_pivot_select", {
                                                definition: g.propertiesForAll
                                            });
                                        }
                                        else if (system.SystemProperties.SystemType === SystemTypes.CanalFeedMaxigator
            || system.SystemProperties.SystemType === SystemTypes.HoseFeedMaxigator) {
                                            const g = new LateralGeometryHelper({
                                                project,
                                                systemId,
                                                layoutId
                                            })
                                            map.changeMode("lateral_select", {
                                                definition: g.propertiesForAll
                                            });
                                        }
                                    }
                                    navigateOnSystem(system.SystemProperties.SystemType);
                                    closeSystemsMenu();
                                }}
                                selected={selectedSystemId !== undefined && systemId === selectedSystemId}
                            >
                                {system.name}
                            </MenuItem>
                        ))
                }
            </Menu>
        </>
    );
};

export default SystemsMenu;
