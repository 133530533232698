import { t } from "i18next";
import { EndGunTypes, ISystemBase } from "rdptypes/project/ISystemBase.AutoGenerated";
import * as React from "react";
import { FC, useEffect } from "react";
import Acres from "roedata/roe_migration/Acres";
import { ElectricalFrequencyToText, FeetToMeters, GPMToM3Hour, PSIToBar } from "roedata/roe_migration/CommonFunctions";
import EndGunInfo, { EndGunItem } from "roedata/roe_migration/EndGunInfo";
import QuoteClass from "roedata/roe_migration/QuoteClass";
import * as spanf from "roedata/roe_migration/SpanFunctions";
import * as sysf from "roedata/roe_migration/SystemFunctions";
import DevSettingsCtx from "../../../../../db/DevSettingsCtx";
import { feetToMeters, gpmToM3ph, psiToBar } from "../../../../../docGeneration/DocumentGenerationHelpers";
import IProject from "../../../../../model/project/IProject";
import { pageStyles, secondaryColor } from "../../../styles";
import { getValuesFromFieldDesign } from "../../Proposal/ProposalHelpers";

interface Props {
    sys: ISystemBase;
    systemId: string;
    layoutId: string;
    project: IProject;
};

const SpanIrrigationInformation: FC<Props> = (props) => {


    const [quote, setQuote] = React.useState<QuoteClass>(new QuoteClass(JSON.parse(JSON.stringify(props.sys))));

    useEffect(() => {
       
    }, []);

    const  GetFraction2 = (diameter: number, egt: EndGunTypes): string =>{
        if(diameter === 0){
            return "0";
        }
       try{
        if (egt ===  EndGunTypes.SR100NV) {
            egt = EndGunTypes.SR100;
        }
        if(egt === EndGunTypes.DualP85){
            egt = EndGunTypes.SingleP85
        }
        var egItems = EndGunInfo.filter(x => x.EndGunType === egt && x.Nozzle > diameter * 0.995 && x.Nozzle < diameter * 1.005)
        var eg: EndGunItem = egItems.length > 0 ? egItems[0] : null;
        if(eg){
            if(eg.NozzleNumberText){
                return eg.NozzleNumberText;
            }
            if( Number.isInteger(eg.NozzleNumber)){
                return eg.NozzleNumber.toFixed(0);
            }
    
            return eg.NozzleNumber.toFixed(2);
    
        }else{
            return "";
        }      
       }catch(ex){
        //todo: handle exception
       }
    }

    let formInputLabelStyle = {...pageStyles.formInputLabelStyle};
    formInputLabelStyle["color"] = secondaryColor;

    let valuesFromFieldDesign = getValuesFromFieldDesign(quote, props.systemId, props.layoutId, props.project);
    const acres = new Acres(props.sys, valuesFromFieldDesign);
    const bHasESP = sysf.HasEnergySaverPackage(props.sys);
    const sSystemElevationLoss = props.sys.SprinklerProperties.PeakElevation / 2.31;
    const sSysGPMPSI = props.sys.SprinklerProperties.PumpPressureAtSysGPMForESP;
    const sMaxGPMMainLoss = props.sys.SprinklerProperties.MainlineLossAtMaxGPMForESP;
    const sPumpElevationLoss = - props.sys.SprinklerProperties.PumpElevation / 2.31;
    const settings = React.useContext(DevSettingsCtx);
    const displaySettings = settings.dealerSettings.display.current;

    var sEndPressure, sPSILossAtMaxFlow, sMainLineFactorLoss,  cRightRadius, cRightEG1Radius, cRightEG2Radius, cRightCovWOEG, cRightCovWEG1, cRightCovWEG2, 
    cRightSideGPM, cRightEG1GPMDel, cRightEG2GPMDel, cRightEndPressure, cRightEG1GPMReq, cLeftEG2GPMReq, dLeftEndPressure, dLeftEGPressure, cLeftEGPressure, 
    cLeftBoostedPressure, dLeftEndPressureNonAdjusted, cLeftEG1Nozzle, cLeftEndPressure, cLeftEG2Nozzle, dRightEGPressure, cRightEGPressure, cRightBoostedPressure, 
    dRightEndPressureNonAdjusted, cRightEG1Nozzle, cRightEG2GPMReq, cRightEG2Nozzle, cLeftRadius, cLeftSideGPM, cLeftCovWOEG, cLeftCovWEG1, cLeftCovWEG2, 
    cLeftEG1Radius, cLeftEG1GPMDel, cLeftEG1GPMReq, cLeftEG2Radius, cLeftEG2GPMDel;

    const bDualSided = sysf.IsCenterFeed(props.sys) || sysf.HasPowerTowerEndBoom(props.sys);

    if(quote /*&& quote.SprinklerChartClass.FieldSet.DataValid()*/){
        sEndPressure = props.sys.FlangedSide.SprinklerChart.IrrigationProperties.ComputedEndPressure;
        let mmfrp = quote.GetMinMaxFlowRateAndPSILoss();
        sPSILossAtMaxFlow = mmfrp.psiLossAtMaxFlow;
        sMainLineFactorLoss = sMaxGPMMainLoss * ((props.sys.SprinklerProperties.TotalGPM / mmfrp.maxFlow) ^ 1.85);
        cRightRadius = FeetToMeters(spanf.EndingRadius(props.sys, props.sys.FlangedSide,props.sys.FlangedSide.Span[props.sys.FlangedSide.Span.length - 1]));
        cRightEG1Radius = FeetToMeters(props.sys.FlangedSide.SprinklerChart.IrrigationProperties.EndGunIrrigationProperties.Radius);
        cRightEG2Radius = FeetToMeters(props.sys.FlangedSide.SprinklerChart.IrrigationProperties.SecondaryEndGunIrrigationProperties.Radius);
        cRightCovWOEG = FeetToMeters(props.sys.FlangedSide.SprinklerChart.IrrigationProperties.CoverageRadiusWithoutEndGun);
        cRightCovWEG1 = FeetToMeters(props.sys.FlangedSide.SprinklerChart.IrrigationProperties.CoverageRadiusWithEndGun);
        cRightCovWEG2 = FeetToMeters(props.sys.FlangedSide.SprinklerChart.IrrigationProperties.CoverageRadiusWithSecondaryEndGun);
        cRightSideGPM = GPMToM3Hour(props.sys.FlangedSide.SprinklerChart.IrrigationProperties.SideGPM);
        cRightEG1GPMDel = GPMToM3Hour(props.sys.FlangedSide.SprinklerChart.IrrigationProperties.EndGunIrrigationProperties.GPMDelivered);
        cRightEG2GPMDel = GPMToM3Hour(props.sys.FlangedSide.SprinklerChart.IrrigationProperties.SecondaryEndGunIrrigationProperties.GPMDelivered);
        cRightEndPressure = PSIToBar(props.sys.FlangedSide.SprinklerChart.IrrigationProperties.ComputedEndPressure);
        cRightEG1GPMReq = GPMToM3Hour(props.sys.FlangedSide.SprinklerChart.IrrigationProperties.EndGunIrrigationProperties.GPMRequired);
        dRightEGPressure = props.sys.FlangedSide.SprinklerChart.IrrigationProperties.EndGunIrrigationProperties.Pressure;
        cRightEGPressure = PSIToBar(dRightEGPressure);
        dRightEndPressureNonAdjusted = props.sys.FlangedSide.SprinklerChart.IrrigationProperties.ComputedEndPressure + props.sys.SprinklerProperties.PeakElevation / 2.31;
        cRightBoostedPressure = PSIToBar(dRightEGPressure - dRightEndPressureNonAdjusted);
        cRightEG1Nozzle = GetFraction2(props.sys.FlangedSide.SprinklerChart.IrrigationProperties.EndGunIrrigationProperties.NozzleSize, props.sys.FlangedSide.SprinklerChart.IrrigationProperties.EndGunIrrigationProperties.EndGunTypeCalculated);
        cRightEG2GPMReq = GPMToM3Hour(props.sys.FlangedSide.SprinklerChart.IrrigationProperties.SecondaryEndGunIrrigationProperties.GPMRequired);
        cRightEG2Nozzle = GetFraction2(props.sys.FlangedSide.SprinklerChart.IrrigationProperties.SecondaryEndGunIrrigationProperties.NozzleSize, props.sys.FlangedSide.SprinklerChart.IrrigationProperties.SecondaryEndGunIrrigationProperties.EndGunTypeCalculated);

        if(bDualSided){
            cLeftRadius = FeetToMeters(spanf.EndingRadius(props.sys, props.sys.FlexSide, props.sys.FlexSide.Span[props.sys.FlexSide.Span.length - 1]));
            var flexIP = props.sys.FlexSide.SprinklerChart.IrrigationProperties;           
            dLeftEndPressure = flexIP.ComputedEndPressure;
            cLeftEndPressure = PSIToBar(dLeftEndPressure);
            dLeftEndPressureNonAdjusted = props.sys.FlangedSide.SprinklerChart.IrrigationProperties.ComputedEndPressure + props.sys.SprinklerProperties.PeakElevation / 2.31
            cLeftSideGPM =GPMToM3Hour(flexIP.SideGPM);
            cLeftCovWOEG = FeetToMeters(flexIP.CoverageRadiusWithoutEndGun);
            cLeftCovWEG1 = FeetToMeters(flexIP.CoverageRadiusWithEndGun);
            cLeftCovWEG2 = FeetToMeters(flexIP.CoverageRadiusWithSecondaryEndGun);

            var egip = flexIP.EndGunIrrigationProperties;
            dLeftEGPressure = egip.Pressure;
            cLeftEGPressure = PSIToBar(dLeftEGPressure);
            cLeftBoostedPressure = PSIToBar(dLeftEGPressure - dLeftEndPressureNonAdjusted);
            cLeftEG1Radius = FeetToMeters(egip.Radius);
            cLeftEG1Nozzle = GetFraction2(egip.NozzleSize, egip.EndGunTypeCalculated);
            cLeftEG1GPMDel = GPMToM3Hour(egip.GPMDelivered);
            cLeftEG1GPMReq = GPMToM3Hour(egip.GPMRequired);
            egip = flexIP.SecondaryEndGunIrrigationProperties;
            cLeftEG2Radius = FeetToMeters(egip.Radius);
            cLeftEG2Nozzle = GetFraction2(egip.NozzleSize, egip.EndGunTypeCalculated);
            cLeftEG2GPMDel = GPMToM3Hour(egip.GPMDelivered);
            cLeftEG2GPMReq = GPMToM3Hour(egip.GPMRequired);
            cLeftEG2GPMReq = GPMToM3Hour(props.sys.FlexSide.SprinklerChart.IrrigationProperties.SecondaryEndGunIrrigationProperties.GPMRequired);
            dLeftEndPressure = props.sys.FlexSide.SprinklerChart.IrrigationProperties.ComputedEndPressure;
            cLeftEGPressure = PSIToBar(dLeftEGPressure);
            dLeftEndPressureNonAdjusted = (props.sys.FlexSide.SprinklerChart.IrrigationProperties.ComputedEndPressure + props.sys.SprinklerProperties.PeakElevation / 2.31);
            cLeftBoostedPressure = PSIToBar(dLeftEGPressure - dLeftEndPressureNonAdjusted);
            cLeftEG1Nozzle = GetFraction2(props.sys.FlexSide.SprinklerChart.IrrigationProperties.EndGunIrrigationProperties.NozzleSize, props.sys.FlexSide.SprinklerChart.IrrigationProperties.EndGunIrrigationProperties.EndGunTypeCalculated);
            cLeftEndPressure = PSIToBar(dLeftEndPressure);
            cLeftEG2Nozzle = GetFraction2(props.sys.FlexSide.SprinklerChart.IrrigationProperties.SecondaryEndGunIrrigationProperties.NozzleSize, props.sys.FlexSide.SprinklerChart.IrrigationProperties.SecondaryEndGunIrrigationProperties.EndGunTypeCalculated);
        }
       
    }else{
        sEndPressure = 0;
        sMainLineFactorLoss = 0;
    }
    var sMaxGPMPSI = quote ? ( sEndPressure + sPSILossAtMaxFlow + sSystemElevationLoss + quote.BasePressureMod() + sMaxGPMMainLoss + sPumpElevationLoss) : null;
    const dTopOfInletPressureESP = sEndPressure + sPSILossAtMaxFlow + sSystemElevationLoss + sSysGPMPSI - sMaxGPMPSI + sMaxGPMMainLoss - sMainLineFactorLoss;
    const sPSILossAtSysFlow = PSIToBar(props.sys.SprinklerProperties.PivotPressure - sEndPressure - sSystemElevationLoss);
    const dESPRelatedLoss = sPSILossAtMaxFlow - sPSILossAtSysFlow + sSysGPMPSI - sMaxGPMPSI + sMaxGPMMainLoss - sMainLineFactorLoss;
    const dRightEndPressure = props.sys.FlangedSide.SprinklerChart.IrrigationProperties.ComputedEndPressure;


    var flangedSideHeaders = [];
    var radiusCells = [];
    var coverageCells = [];
    var sideGPMCells = [];
    var endPressureCells = [];
    var endGunPressureCells = [];
    var endgunNozzleCells = [];

    var flexedSideHeaders = [];
    var flexedRadiusCells = [];
    var flexedCoverageCells = [];
    var flexedSideGPMCells = [];
    var flexEndPressureCells = [];
    var flexEndGunPressureCells = [];
    var flexEndgunNozzleCells = [];

    //conversions
    let cRightRadius_ = displaySettings.lengths === "meters" ? feetToMeters(cRightRadius) : cRightRadius;
    let cRightCovWOEG_ = displaySettings.lengths === "meters" ? feetToMeters(cRightCovWOEG) : cRightCovWOEG;
    let cRightSideGPM_ = displaySettings.irrigationRates === "cmh" ? gpmToM3ph(cRightSideGPM) : cRightSideGPM;
    let cRightEndPressure_ = displaySettings.pressures === "bar" ? psiToBar(cRightEndPressure) : cRightEndPressure;
    let cRightEGPressure_ = displaySettings.pressures === "bar" ? psiToBar(cRightEGPressure) : cRightEGPressure;
    let cRightBoostedPressure_ = displaySettings.pressures === "bar" ? psiToBar(cRightBoostedPressure) : cRightBoostedPressure;
    let cRightEG1Radius_ = displaySettings.lengths === "meters" ? feetToMeters(cRightEG1Radius) : cRightEG1Radius;
    let cRightEG2Radius_ = displaySettings.lengths === "meters" ? feetToMeters(cRightEG2Radius) : cRightEG2Radius;
    let cRightCovWEG1_ = displaySettings.lengths === "meters" ? feetToMeters(cRightCovWEG1) : cRightCovWEG1;
    let cRightCovWEG2_ = displaySettings.lengths === "meters" ? feetToMeters(cRightCovWEG2) : cRightCovWEG2;
    let cRightEG1GPMDel_ = displaySettings.irrigationRates === "cmh" ? gpmToM3ph(cRightEG1GPMDel) : cRightEG1GPMDel;
    let cRightEG2GPMDel_ = displaySettings.irrigationRates === "cmh" ? gpmToM3ph(cRightEG2GPMDel) : cRightEG2GPMDel;
    let cRightEG1GPMReq_ = displaySettings.irrigationRates === "cmh" ? gpmToM3ph(cRightEG1GPMReq) : cRightEG1GPMReq;
    let cRightEG2GPMReq_ = displaySettings.irrigationRates === "cmh" ? gpmToM3ph(cRightEG2GPMReq) : cRightEG2GPMReq;

    if(props.sys.FlangedSide.SprinklerChart.IrrigationProperties.EndGunIrrigationProperties.EndGunTypeCalculated === EndGunTypes.None){
        flangedSideHeaders.push("Flanged Side", "", "");
        radiusCells.push("",`Radius (${displaySettings.lengths === "feet" ? "ft" : "m"})`, cRightRadius_);
        coverageCells.push("", `Coverage (${displaySettings.lengths === "feet" ? "ft" : "m"})`, cRightCovWOEG_);
        sideGPMCells.push("", t("roe.sprinklers." + (displaySettings.irrigationRates === "gpm" ? "side-gpm" : "side-cmh")), cRightSideGPM_);
        endPressureCells.push("", `${t("common-phrases.sltEndPressure1")} (${displaySettings.pressures === "psi" ? t("common-phrases.sltPSI") : t("common-phrases.sltBar")})`, cRightEndPressure_);
    }else if (props.sys.FlangedSide.SprinklerChart.IrrigationProperties.SecondaryEndGunIrrigationProperties.EndGunTypeCalculated === EndGunTypes.None){
        flangedSideHeaders.push("Flanged Side", "", "", "", "");
        radiusCells.push("",`Radius (${displaySettings.lengths === "feet" ? "ft" : "m"})`, cRightRadius_, `${t("common-phrases.sltEndGunRadius")} (${displaySettings.lengths === "feet" ? "ft" : "m"})`, cRightEG1Radius_);
        coverageCells.push("", `Coverage (${displaySettings.lengths === "feet" ? "ft" : "m"})`, cRightCovWOEG_, `${t("common-phrases.sltCoverageWithEG")} (${displaySettings.lengths === "feet" ? "ft" : "m"})`, cRightCovWEG1_);
        sideGPMCells.push("", t("roe.sprinklers." + (displaySettings.irrigationRates === "gpm" ? "side-gpm" : "side-cmh")), cRightSideGPM_, `End Gun ${displaySettings.irrigationRates === "gpm" ? "GPM" : "m3/h"} Delivered`, cRightEG1GPMDel_);
        endPressureCells.push("", `${t("common-phrases.sltEndPressure1")} (${displaySettings.pressures === "psi" ? t("common-phrases.sltPSI") : t("common-phrases.sltBar")})`, cRightEndPressure_, `End Gun ${displaySettings.irrigationRates === "gpm" ? "GPM" : "m3/h"} Required`, cRightEG1GPMReq_);
        if(dRightEndPressure === dRightEGPressure){
            endGunPressureCells.push("", "", "", `${t("common-phrases.sltEndGunPressure")} (${displaySettings.pressures === "psi" ? t("common-phrases.sltPSI") : t("common-phrases.sltBar")})`, cRightEGPressure_);
        }else{
            endGunPressureCells.push("", `${t("common-phrases.sltBoostedPressure")} (${displaySettings.pressures === "psi" ? t("common-phrases.sltPSI") : t("common-phrases.sltBar")})`, cRightBoostedPressure_, `${t("common-phrases.sltEndGunPressure")} (${displaySettings.pressures === "psi" ? t("common-phrases.sltPSI") : t("common-phrases.sltBar")})`, cRightEGPressure_);
        }
        endgunNozzleCells.push("", "", "", "End Gun Nozzle", cRightEG1Nozzle);
    }else{
        flangedSideHeaders.push("Flanged Side","", "", "End Gun Performance", "Primary", "Secondary");
        radiusCells.push("",`Radius (${displaySettings.lengths === "feet" ? "ft" : "m"})`, cRightRadius_, `${t("common-phrases.sltEndGunRadius")} (${displaySettings.lengths === "feet" ? "ft" : "m"})`, cRightEG1Radius_, cRightEG2Radius_);
        coverageCells.push("", `Coverage (${displaySettings.lengths === "feet" ? "ft" : "m"})`, cRightCovWOEG_, `${t("common-phrases.sltCoverageWithEG")} (${displaySettings.lengths === "feet" ? "ft" : "m"})`, cRightCovWEG1_, cRightCovWEG2_);
        sideGPMCells.push("", t("roe.sprinklers." + (displaySettings.irrigationRates === "gpm" ? "side-gpm" : "side-cmh")), cRightSideGPM_, `End Gun ${displaySettings.irrigationRates === "gpm" ? "GPM" : "m3/h"} Delivered`, cRightEG1GPMDel_, cRightEG2GPMDel_);
        endPressureCells.push("", `${t("common-phrases.sltEndPressure1")} (${displaySettings.pressures === "psi" ? t("common-phrases.sltPSI") : t("common-phrases.sltBar")})`, cRightEndPressure_, `End Gun ${displaySettings.irrigationRates === "gpm" ? "GPM" : "m3/h"} Required`, cRightEG1GPMReq_, cRightEG2GPMReq_);
        if(dRightEndPressure === dRightEGPressure){
            endGunPressureCells.push("", "", "", `${t("common-phrases.sltEndGunPressure")} (${displaySettings.pressures === "psi" ? t("common-phrases.sltPSI") : t("common-phrases.sltBar")})`, cRightEGPressure_, cRightEndPressure_);
        }else{
            endGunPressureCells.push("", `${t("common-phrases.sltBoostedPressure")} (${displaySettings.pressures === "psi" ? t("common-phrases.sltPSI") : t("common-phrases.sltBar")})`, cRightBoostedPressure_, `${t("common-phrases.sltEndGunPressure")} (${displaySettings.pressures === "psi" ? t("common-phrases.sltPSI") : t("common-phrases.sltBar")})`, cRightEGPressure_, cRightEndPressure_);
        }
        endgunNozzleCells.push("", "", "", "End Gun Nozzle", cRightEG1Nozzle, cRightEG2Nozzle);
    }

    //conversions
    let cLeftRadius_ = displaySettings.lengths === "meters" ? feetToMeters(cLeftRadius) : cLeftRadius;
    let cLeftCovWOEG_ = displaySettings.lengths === "meters" ? feetToMeters(cLeftCovWOEG) : cLeftCovWOEG;
    let cLeftSideGPM_ = displaySettings.irrigationRates === "cmh" ? gpmToM3ph(cLeftSideGPM) : cLeftSideGPM;
    let cLeftEndPressure_ = displaySettings.pressures === "bar" ? psiToBar(cLeftEndPressure) : cLeftEndPressure;
    let cLeftEGPressure_ = displaySettings.pressures === "bar" ? psiToBar(cLeftEGPressure) : cLeftEGPressure;
    let cLeftBoostedPressure_ = displaySettings.pressures === "bar" ? psiToBar(cLeftBoostedPressure) : cLeftBoostedPressure;
    let cLeftEG1Radius_ = displaySettings.lengths === "meters" ? feetToMeters(cLeftEG1Radius) : cLeftEG1Radius;
    let cLeftEG2Radius_ = displaySettings.lengths === "meters" ? feetToMeters(cLeftEG2Radius) : cLeftEG2Radius;
    let cLeftCovWEG1_ = displaySettings.lengths === "meters" ? feetToMeters(cLeftCovWEG1) : cLeftCovWEG1;
    let cLeftCovWEG2_ = displaySettings.lengths === "meters" ? feetToMeters(cLeftCovWEG2) : cLeftCovWEG2;
    let cLeftEG1GPMDel_ = displaySettings.irrigationRates === "cmh" ? gpmToM3ph(cLeftEG1GPMDel) : cLeftEG1GPMDel;
    let cLeftEG2GPMDel_ = displaySettings.irrigationRates === "cmh" ? gpmToM3ph(cLeftEG2GPMDel) : cLeftEG2GPMDel;
    let cLeftEG1GPMReq_ = displaySettings.irrigationRates === "cmh" ? gpmToM3ph(cLeftEG1GPMReq) : cLeftEG1GPMReq;
    let cLeftEG2GPMReq_ = displaySettings.irrigationRates === "cmh" ? gpmToM3ph(cLeftEG2GPMReq) : cLeftEG2GPMReq;

    if(bDualSided){
        if(props.sys.FlexSide.SprinklerChart.IrrigationProperties.EndGunIrrigationProperties.EndGunTypeCalculated === EndGunTypes.None){
            flexedSideHeaders.push("Flexed Side", "", "");
            flexedRadiusCells.push("",`Radius (${displaySettings.lengths === "feet" ? "ft" : "m"})`, cLeftRadius_);
            flexedCoverageCells.push("",`Coverage (${displaySettings.lengths === "feet" ? "ft" : "m"})`, cLeftCovWOEG_);
            flexedSideGPMCells.push("", t("roe.sprinklers." + (displaySettings.irrigationRates === "gpm" ? "side-gpm" : "side-cmh")), cLeftSideGPM_);
            flexEndPressureCells.push("", `${t("common-phrases.sltEndPressure1")} (${displaySettings.pressures === "psi" ? t("common-phrases.sltPSI") : t("common-phrases.sltBar")})`, cLeftEndPressure_);
        }else if (props.sys.FlexSide.SprinklerChart.IrrigationProperties.SecondaryEndGunIrrigationProperties.EndGunTypeCalculated === EndGunTypes.None){
            flexedSideHeaders.push("Flexed Side", "", "", "", "");
            flexedRadiusCells.push("",`Radius (${displaySettings.lengths === "feet" ? "ft" : "m"})`, cLeftRadius_, `${t("common-phrases.sltEndGunRadius")} (${displaySettings.lengths === "feet" ? "ft" : "m"})`, cLeftEG1Radius_);
            flexedCoverageCells.push("", `Coverage (${displaySettings.lengths === "feet" ? "ft" : "m"})`, cLeftCovWOEG_, `${t("common-phrases.sltCoverageWithEG")} (${displaySettings.lengths === "feet" ? "ft" : "m"})`, cLeftCovWEG1_);
            flexedSideGPMCells.push("", t("roe.sprinklers." + (displaySettings.irrigationRates === "gpm" ? "side-gpm" : "side-cmh")), cLeftSideGPM_, `End Gun ${displaySettings.irrigationRates === "gpm" ? "GPM" : "m3/h"} Delivered`, cLeftEG1GPMDel_);
            flexEndPressureCells.push("", `${t("common-phrases.sltEndPressure1")} (${displaySettings.pressures === "psi" ? t("common-phrases.sltPSI") : t("common-phrases.sltBar")})`, cLeftEndPressure_, `End Gun ${displaySettings.irrigationRates === "gpm" ? "GPM" : "m3/h"} Required`, cLeftEG1GPMReq_);
            if(dLeftEndPressure === dLeftEGPressure){
                flexEndGunPressureCells.push("", "", "", `${t("common-phrases.sltEndPressure1")} (${displaySettings.pressures === "psi" ? t("common-phrases.sltPSI") : t("common-phrases.sltBar")})`, cLeftEGPressure_);
            }else{
                flexEndGunPressureCells.push("", `${t("common-phrases.sltBoostedPressure")} (${displaySettings.pressures === "psi" ? t("common-phrases.sltPSI") : t("common-phrases.sltBar")})`, cLeftBoostedPressure_, `${t("common-phrases.sltEndGunPressure")} (${displaySettings.pressures === "psi" ? t("common-phrases.sltPSI") : t("common-phrases.sltBar")})`, cLeftEGPressure_);
            }
            flexEndgunNozzleCells.push("", "", "", "End Gun Nozzle:", cLeftEG1Nozzle);
        }else{
            flexedSideHeaders.push("Flexed Side","", "", "End Gun Performance", "Primary", "Secondary");
            flexedRadiusCells.push("",`Radius (${displaySettings.lengths === "feet" ? "ft" : "m"})`, cLeftRadius_, `${t("common-phrases.sltEndGunRadius")} (${displaySettings.lengths === "feet" ? "ft" : "m"})`, cLeftEG1Radius_, cLeftEG2Radius_);
            flexedCoverageCells.push("", `Coverage (${displaySettings.lengths === "feet" ? "ft" : "m"})`, cLeftCovWOEG_, `${t("common-phrases.sltCoverageWithEG")} (${displaySettings.lengths === "feet" ? "ft" : "m"})`, cLeftCovWEG1_, cLeftCovWEG2_);
            flexedSideGPMCells.push("", t("roe.sprinklers." + (displaySettings.irrigationRates === "gpm" ? "side-gpm" : "side-cmh")), cLeftSideGPM_, `End Gun ${displaySettings.irrigationRates === "gpm" ? "GPM" : "m3/h"} Delivered`, cLeftEG1GPMDel_, cLeftEG2GPMDel_);
            flexEndPressureCells.push("", `${t("common-phrases.sltEndPressure1")} (${displaySettings.pressures === "psi" ? t("common-phrases.sltPSI") : t("common-phrases.sltBar")})`, cLeftEndPressure_, `End Gun ${displaySettings.irrigationRates === "gpm" ? "GPM" : "m3/h"} Required`, cLeftEG1GPMReq_, cLeftEG2GPMReq_);
            if(dLeftEndPressure === dLeftEGPressure){
                flexEndGunPressureCells.push("", "", "", `${t("common-phrases.sltEndPressure1")} (${displaySettings.pressures === "psi" ? t("common-phrases.sltPSI") : t("common-phrases.sltBar")})`, cLeftEGPressure_, cLeftEndPressure_);
            }else{
                flexEndGunPressureCells.push("", `${t("common-phrases.sltBoostedPressure")} (${displaySettings.pressures === "psi" ? t("common-phrases.sltPSI") : t("common-phrases.sltBar")})`, cLeftBoostedPressure_, `${t("common-phrases.sltEndGunPressure")} (${displaySettings.pressures === "psi" ? t("common-phrases.sltPSI") : t("common-phrases.sltBar")})`, cLeftEGPressure_, cLeftEndPressure_);
            }
            flexEndgunNozzleCells.push("", "", "", "End Gun Nozzle:", cLeftEG1Nozzle, cLeftEG2Nozzle);
        }
    }

    //conversions
    let irrigationRate =  displaySettings.irrigationRates === "cmh" ? gpmToM3ph(props.sys.SprinklerProperties.TotalGPM) : props.sys.SprinklerProperties.TotalGPM;
    let pivotPressure = displaySettings.pressures === "bar" ? psiToBar(props.sys.SprinklerProperties.PivotPressure) : props.sys.SprinklerProperties.PivotPressure;
    let dTopOfInletPressureESP_ = displaySettings.pressures === "bar" ? psiToBar(dTopOfInletPressureESP) : dTopOfInletPressureESP;
    let basePressureMod = displaySettings.pressures === "bar" ? psiToBar(quote.BasePressureMod()) : quote.BasePressureMod();
    let elevationPLoss = displaySettings.pressures === "bar" ? psiToBar(props.sys.SprinklerProperties.PeakElevation / 2.31) : props.sys.SprinklerProperties.PeakElevation / 2.31;
    let dESPRelatedLoss_ = displaySettings.pressures === "bar" ? psiToBar(dESPRelatedLoss) : dESPRelatedLoss;
    let dRightEndPressure_ = displaySettings.pressures === "bar" ? psiToBar(dRightEndPressure) : dRightEndPressure;
    
    return (
            <div>
                <table className="span-irrigation-table">
                    <thead>
                        <tr>
                            <th>{t("common-phrases.sltSystemInformation")}</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            sysf.HasSwingArmCorner(props.sys) &&
                            <tr>
                                <td></td>
                                <td>{t("common-phrases." + displaySettings.irrigationRates === "cmh" ? "sltMaximumSystemCMH" : "sltMaximumSystemGPM")}</td>
                                <td>{displaySettings.irrigationRates === "cmh" ? gpmToM3ph(props.sys.SprinklerProperties.MaximumSystemGPM).toFixed(1) : props.sys.SprinklerProperties.MaximumSystemGPM.toFixed(1)}</td>
                            </tr>
                        }
                        {
                            sysf.HasSwingArmCorner(props.sys) &&
                            <tr>
                                <td></td>
                                <td>{t("common-phrases." + displaySettings.pressures === "bar" ? "sltMinTopInletBaratMaxGPM" : "sltMinTopInletPSIatMaxGPM")}</td>
                                <td>{displaySettings.pressures === "bar" ? psiToBar(props.sys.SprinklerProperties.MaximumTopInletPSI).toFixed(1) : props.sys.SprinklerProperties.MaximumTopInletPSI.toFixed(1)}</td>
                            </tr>
                        }
                        {
                            sysf.HasSwingArmCorner(props.sys) &&
                            <tr>
                                <td></td>
                                <td>{t("common-phrases." + displaySettings.irrigationRates === "cmh" ? "sltMinimumSystemCMH" : "sltMinimumSystemGPM")}</td>
                                <td>{displaySettings.irrigationRates === "cmh" ? gpmToM3ph(props.sys.SprinklerProperties.MinimumSystemGPM).toFixed(1) : props.sys.SprinklerProperties.MinimumSystemGPM.toFixed(1)}</td>
                            </tr>
                        }
                        <tr>
                            <td></td>
                            <td>{t("common-phrases." +  (displaySettings.irrigationRates === "gpm" ? "sltGPMPerAcre1" : "sltCMHPerHectare"))}:</td>
                            <td>{acres.GPMPerAcreGivenGPM(irrigationRate, displaySettings.irrigationRates).toFixed(1)}</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>{t("roe.sprinklers." + (displaySettings.irrigationRates === "gpm" ? "system-gpm" : "system-cmh"))}:</td>
                            <td>{irrigationRate.toFixed(1)}</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>{t("common-phrases.sltPivotTopPressure")} ({displaySettings.pressures === "psi" ? t("common-phrases.sltPSI") : t("common-phrases.sltBar")}):</td>
                            <td>{bHasESP ? pivotPressure.toFixed(1) + " with ESP " + dTopOfInletPressureESP_.toFixed(1) : pivotPressure.toFixed(1)}</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>{t("common-phrases.sltBaseOfInletPressure")} ({displaySettings.pressures === "psi" ? t("common-phrases.sltPSI") : t("common-phrases.sltBar")}):</td>
                            <td>{quote ? (bHasESP ? (pivotPressure + basePressureMod).toFixed(1) +  " with ESP " +  (dTopOfInletPressureESP_ + basePressureMod).toFixed(1) : (pivotPressure + basePressureMod).toFixed(1)) : ""}</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>{t("common-phrases.sltElevationPressureLoss")} ({displaySettings.pressures === "psi" ? t("common-phrases.sltPSI") : t("common-phrases.sltBar")}):</td>
                            <td>{bHasESP ? elevationPLoss.toFixed(1) +  " ESP Pressure Loss " + dESPRelatedLoss_.toFixed(1) : elevationPLoss.toFixed(1)}</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>{t("common-phrases.sltSystemLoss")} ({displaySettings.pressures === "psi" ? t("common-phrases.sltPSI") : t("common-phrases.sltBar")}):</td>
                            <td>{(pivotPressure - dRightEndPressure_ - elevationPLoss).toFixed(1)}</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>{t("common-phrases.sltElectricalFrequency")}:</td>
                            <td>{sysf.FieldSets(props.sys).ControlPanel.DataValid() ?  ElectricalFrequencyToText(props.sys.ControlPanel.ElectricalFrequency) : ""}</td>
                        </tr>
                    </tbody>
                </table>

                <table style={{marginTop: 20}} className="span-irrigation-table">
                    <thead>
                        <tr>{ flangedSideHeaders.map(h => <th style={{textAlign: 'left'}}>{h}</th>)}</tr>
                    </thead>
                    <tbody>
                        <tr>{ radiusCells.map(c => <td>{ Number(c) ? Number(c).toFixed(1) : c}</td>)}</tr>
                        <tr>{ coverageCells.map(c => <td>{ Number(c) ? Number(c).toFixed(1) : c}</td>)}</tr>
                        <tr>{ sideGPMCells.map(c => <td>{ Number(c) ? Number(c).toFixed(1) : c}</td>)} </tr>
                        <tr>{ endPressureCells.map(c => <td>{ Number(c) ? Number(c).toFixed(1) : c}</td>)} </tr>
                        <tr>{ endGunPressureCells.map(c => <td>{ Number(c) ? Number(c).toFixed(1) : c}</td>)}</tr>
                        <tr>{ endgunNozzleCells.map(c => <td>{ Number(c) ? Number(c).toFixed(2) : c}</td>)}</tr>
                    </tbody>
                </table>

                <table style={{marginTop: 20}} className="span-irrigation-table">
                    <thead>
                        <tr>{ flexedSideHeaders.map(h => <th style={{textAlign: 'left'}}>{h}</th>)}</tr>
                    </thead>
                    <tbody>
                        <tr>{ flexedRadiusCells.map(c => <td>{ Number(c) ? Number(c).toFixed(1) : c }</td>)}</tr>
                        <tr>{ flexedCoverageCells.map(c => <td>{ Number(c) ? Number(c).toFixed(1) : c}</td>)}</tr>
                        <tr>{ flexedSideGPMCells.map(c => <td>{ Number(c) ? Number(c).toFixed(1) : c}</td>)} </tr>
                        <tr>{ flexEndPressureCells.map(c => <td>{ Number(c) ? Number(c).toFixed(1) : c}</td>)} </tr>
                        <tr>{ flexEndGunPressureCells.map(c => <td>{ Number(c) ? Number(c).toFixed(1) : c}</td>)}</tr>
                        <tr>{ flexEndgunNozzleCells.map(c => <td>{ Number(c) ? Number(c).toFixed(2) : c}</td>)}</tr>
                    </tbody>
                </table>
                
                         
            </div> 
    );
};

export default SpanIrrigationInformation;