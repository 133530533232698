import { Button, Card, Checkbox, CircularProgress, FormControl, FormControlLabel, InputAdornment, InputLabel, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import { convertLength } from "@turf/turf";
import { t } from "i18next";
import { SideEnum } from "rdptypes/helpers/SideEnum";
import { PressureGaugeTypes, SprinklerManufacturerTypes } from "rdptypes/project/ISystemBase.AutoGenerated";
import * as React from "react";
import { FC, useContext, useRef, useState } from "react";
import Acres from "roedata/roe_migration/Acres";
import QuoteClass from "roedata/roe_migration/QuoteClass";
import { HasPowerTowerEndBoom, IsDualSided } from "roedata/roe_migration/SystemFunctions";
import { sprinklersPrefix } from ".";
import { ICenterPivotElevation, ILateralElevation, ILayoutElevation } from "../../../../GeometryHelpers/GeometryProvider";
import DevSettingsCtx from "../../../../db/DevSettingsCtx";
import { barToPsi, feetToMeters, gpmToM3ph, m3phToGpm, metersToFeet, psiToBar } from "../../../../docGeneration/DocumentGenerationHelpers";
import { getImgSource } from "../../../../images/ImagePathHelper";
import IProject from "../../../../model/project/IProject";
import ISystem from "../../../../model/project/ISystem";
import { pageStyles } from "../../styles";
import { getValuesFromFieldDesign } from "../Proposal/ProposalHelpers";
import SprinklerBoomBacks from "./SprinklerBoomBacks";
import { getPressureGaugeType } from "./helpers/SprinklerHelper";
import { ISprinklerValidator } from "./sprinklerValidator/interfaces";

interface Props {
    handleTooltipOpen: (src?: string) => void;
    handleTooltipClose: () => void;
    system: ISystem;
    project: IProject;
    layoutId: string;
    systemId: string;
    sprinklerValidator: ISprinklerValidator;
    layoutElevation: ILayoutElevation | null;
};

const SprinklerOptions: FC<Props> = (props) => {
    const formInputLabelStyle = {...pageStyles.formInputLabelStyle};
    const flexBoomBack = IsDualSided(props.system) && !HasPowerTowerEndBoom(props.system) ? props.system.FlexSide.Sprinklers.BoomBacks : undefined;
    const settings = useContext(DevSettingsCtx);
    const displaySettings = settings.dealerSettings.display.current;
    let systemMaxRelativeToCenter: { available: true, value: number } | { available: false };
    //added useRef because slow to rerender if we are recalculating v
    const v = useRef<ILateralElevation | ICenterPivotElevation>();
    if (
        props.layoutElevation?.available &&
        props.layoutElevation.systems[props.systemId] &&
        !props.layoutElevation.systems[props.systemId].loading
    ) {
        const systemElevationObj = props.layoutElevation.systems[props.systemId];
        if (!v.current){
            v.current = systemElevationObj.systemElevation;
        }
        const relativeToCenterMeters = v.current.system?.max?.properties?.relativeToCenter ?? null;
        if (relativeToCenterMeters !== null) {
            systemMaxRelativeToCenter = {
                available: true,
                value: Math.sign(relativeToCenterMeters) * convertLength(relativeToCenterMeters, 'meters', 'feet')
            }
        }
        else {
            systemMaxRelativeToCenter = { available: false };
        }
    }
    else {
        systemMaxRelativeToCenter = { available: false };
    }
    const loadingElevation = systemMaxRelativeToCenter.available && 
        props.layoutElevation?.loading && 
        props.layoutElevation.systems[props.systemId] &&
        props.layoutElevation.systems[props.systemId].loading;
    const mapElvationValue = props.layoutElevation?.loading || 
        (props.layoutElevation.systems[props.systemId] && props.layoutElevation.systems[props.systemId].loading)
            ? "loading..."
            : systemMaxRelativeToCenter.available
                ? displaySettings.lengths === "feet" 
                    ? systemMaxRelativeToCenter.value.toFixed(1)
                    : feetToMeters(systemMaxRelativeToCenter.value).toFixed(1)
                : "unavailable"

    //added useRef because slow to rerender if we are recalculating acres
    const acres = useRef<Acres>();
    if (!acres.current) {
        let valuesFromFieldDesign = getValuesFromFieldDesign(new QuoteClass(props.system), props.systemId, props.layoutId, props.project);
        acres.current = new Acres(props.system, valuesFromFieldDesign);
    }

    const getIrrigationRate = () => {
        if (typeof props.sprinklerValidator.options.totalGPM.value !== "undefined"){
            let val = props.sprinklerValidator.options.totalGPM.value;
            if (displaySettings.irrigationRates === "cmh"){
                val = gpmToM3ph(val);
            }

            return acres.current.GPMPerAcreGivenGPM(val, displaySettings.irrigationRates).toFixed(1);
        }
        return "";
    }

    const [irrigationRate, setIrrigationRate] = useState<string | undefined>(getIrrigationRate());

    const getDisplayFlowRate = () => {
        return (isFinite(props.sprinklerValidator.options.totalGPM.value) && props.sprinklerValidator.options.totalGPM.value !== null)
        ? displaySettings.irrigationRates === "gpm" 
            ? props.sprinklerValidator.options.totalGPM.value.toFixed(2)
            : gpmToM3ph(props.sprinklerValidator.options.totalGPM.value).toFixed(2) 
        : "";
    }

    const [displayFlowRate, setDisplayFlowRate] = useState<string>(getDisplayFlowRate());

    React.useEffect(() => {
        setDisplayFlowRate(getDisplayFlowRate());
        setIrrigationRate(getIrrigationRate());
   }, [ displaySettings.irrigationRates ]);
    
    const [ peakElevationAutoset, setPeakElevationAutoset ] = useState(true);
    React.useEffect(() => {
        if (!peakElevationAutoset) return;

        // If the peakElevation has already been set, then exit
        if (!(
            props.sprinklerValidator.options.peakElevation.value === null || 
            props.sprinklerValidator.options.peakElevation.value === undefined
        )) {
            return;
        }
        // If the map elevation is not available, then exit
        if (!systemMaxRelativeToCenter.available) {
            return;
        }
        const v = Math.round(systemMaxRelativeToCenter.value * 10) / 10;
        props.sprinklerValidator.options.peakElevation.set(v);
    }, [ props.sprinklerValidator.options.peakElevation.value, systemMaxRelativeToCenter, peakElevationAutoset ]);

    const getDisplayEndPressure = () => {
        return (isFinite(props.sprinklerValidator.options.designedEndPressure.value) && props.sprinklerValidator.options.designedEndPressure.value !== null)
        ?  (displaySettings.pressures === "psi" ? props.sprinklerValidator.options.designedEndPressure.value.toFixed(2)
         : psiToBar(props.sprinklerValidator.options.designedEndPressure.value).toFixed(2)) 
         : "";
    }

    const [displayEndPressure, setDisplayEndPressure] = useState<string>(getDisplayEndPressure());

    const getDisplayElevation = () => {
        return (isFinite(props.sprinklerValidator.options.peakElevation.value) && props.sprinklerValidator.options.peakElevation.value !== null) 
        ? displaySettings.lengths === "feet" 
            ? props.sprinklerValidator.options.peakElevation.value.toFixed(2)
            : feetToMeters(props.sprinklerValidator.options.peakElevation.value).toFixed(2)
        : "";
    }

    const [displayElevation, setDisplayElevation] = useState<string>(getDisplayElevation());

    React.useEffect(() => {
        setDisplayElevation(getDisplayElevation());
   }, [ displaySettings.lengths ]);

   React.useEffect(() => {
        setDisplayEndPressure(getDisplayEndPressure());
    }, [ displaySettings.pressures ]);

    return (
        <>
            <Stack direction="column" style={{ paddingTop: 30}}>
                <Card style={{ marginBottom: 20, marginRight: 20, padding: 10, maxWidth: 900 }} variant={"outlined"}>
                <Typography sx={pageStyles.cardTitleStyle}>Sprinklers</Typography>
                    <Stack direction="row">
                        <FormControl variant="standard"  fullWidth  style={{marginLeft: 10, marginRight: 10}} 
                            error={props.sprinklerValidator.options.sprinklerEngineCode.isError}>
                            <InputLabel shrink={true} sx={formInputLabelStyle} >{t(sprinklersPrefix + "sprinkler-brand")}</InputLabel>
                            <Select
                                displayEmpty
                                renderValue={(selected) => {
                                    if (!selected) return "Select";
                                    else return selected;
                                }}
                                value={props.sprinklerValidator.options.sprinklerEngineCode.value || ""}
                                onChange={(ev) => props.sprinklerValidator.options.sprinklerEngineCode.set(ev.target.value as SprinklerManufacturerTypes)}
                            >
                                {
                                    props.sprinklerValidator.options.sprinklerEngineCode.allowableValues
                                        .map((key) => <MenuItem onMouseEnter={() => props.handleTooltipOpen(getImgSource("SprinklerProperties.SprinklerEngineCode", key))} onMouseLeave={props.handleTooltipClose} key={key} value={key}>{key}</MenuItem>)
                                }
                            </Select>
                        </FormControl>
                        <FormControl variant="standard" fullWidth style={{marginLeft: 10, marginRight: 10}} 
                            error={props.sprinklerValidator.options.pressureGauge.isError}>
                            <InputLabel shrink={true} sx={formInputLabelStyle} >{t(sprinklersPrefix + "pressure-gauge")}</InputLabel>
                            <Select
                                displayEmpty
                                renderValue={(selected) => {
                                    if (!selected) return "Select";
                                    else return getPressureGaugeType(selected, displaySettings);
                                }}                         
                                value={props.sprinklerValidator.options.pressureGauge.value ?? ""}
                                onChange={(ev) => props.sprinklerValidator.options.pressureGauge.set(ev.target.value as PressureGaugeTypes)}
                            >
                                {
                                    props.sprinklerValidator.options.pressureGauge.allowableValues
                                        .map((key) => <MenuItem onMouseEnter={() => props.handleTooltipOpen(getImgSource("SprinklerProperties.PressureGauge", key))} onMouseLeave={props.handleTooltipClose} 
                                            key={key} value={key}>{getPressureGaugeType(key as PressureGaugeTypes, displaySettings)}</MenuItem>)
                                }                              
                            </Select>
                        </FormControl>
                        <FormControlLabel onMouseEnter={() => props.handleTooltipOpen(getImgSource("SprinklerProperties.EndPressureGauge"))} onMouseLeave={props.handleTooltipClose} style={{minWidth: 290}} control={<Checkbox 
                            style={{padding: 0, paddingLeft: 10, paddingRight: 5}}
                            checked={props.sprinklerValidator.options.endPressureGauge.value || false}                              
                            onChange={(ev) => props.sprinklerValidator.options.endPressureGauge.set(ev.target.checked)}
                        />} label={displaySettings.pressures === "psi" ? t(sprinklersPrefix + "end-gauge-30-psi") : t(sprinklersPrefix + "end-gauge-2-1-bar")} />
                    </Stack>
                    <Stack direction="row"  style={{marginTop: 20}}>
                        <TextField
                            error={props.sprinklerValidator.options.totalGPM.isError}
                            style={{marginLeft: 10, marginRight: 10}}
                                InputLabelProps={{sx:formInputLabelStyle, shrink: true}}
                                fullWidth
                                variant="standard"
                                label={t(sprinklersPrefix + (displaySettings.irrigationRates === "gpm" ? "system-gpm" : "system-cmh"))}
                                type="number"
                                value={displayFlowRate}
                                onChange={(ev) => {
                                        setDisplayFlowRate(ev.target.value);

                                        let irrigationRate: string = undefined;
                                        //set irrigationRate
                                        let val = parseFloatOrUndefined(ev.target.value);
                                        if (typeof val !== "undefined"){ 
                                            irrigationRate = acres.current.GPMPerAcreGivenGPM(val, displaySettings.irrigationRates).toFixed(2);
                                        }

                                        setIrrigationRate(irrigationRate);
                                    }
                                }
                                onBlur={() => {
                                    let val = parseFloatOrUndefined(displayFlowRate);
                                    if (typeof val !== "undefined" && displaySettings.irrigationRates === "cmh"){
                                        val = m3phToGpm(val);
                                    }
                                    props.sprinklerValidator.options.totalGPM.set(val)
                                }}
                            />
                        <TextField
                            error={props.sprinklerValidator.options.totalGPM.isError}
                            style={{marginLeft: 10, marginRight: 10}}
                                InputLabelProps={{sx:formInputLabelStyle, shrink: true}}
                                fullWidth
                                variant="standard"
                                label={t("common-phrases." +  (displaySettings.irrigationRates === "gpm" ? "sltGPMPerAcre1" : "sltCMHPerHectare"))}
                                type="number"
                                value={irrigationRate}
                                onChange={(ev) => {
                                    setIrrigationRate(ev.target.value);
                                    let val = parseFloatOrUndefined(ev.target.value);
                                    let displayFlowRate: number = undefined;
                                    if (typeof val !== "undefined"){
                                        displayFlowRate = acres.current.GPMGivenGPMPerAcre(val, displaySettings.irrigationRates);
                                    }
                                    setDisplayFlowRate(displayFlowRate.toFixed(2));
                                }}

                                onBlur={() => {
                                    let val = parseFloatOrUndefined(displayFlowRate);
                                    if (typeof val !== "undefined" && displaySettings.irrigationRates === "cmh"){
                                        val = m3phToGpm(val);
                                    }
                                    props.sprinklerValidator.options.totalGPM.set(val)
                                }}
                            />
                    </Stack>
                    <Stack direction="row"  style={{marginTop: 20}}>
                        <TextField
                            error={props.sprinklerValidator.options.designedEndPressure.isError}
                                style={{marginLeft: 10, marginRight: 10, width: "50%"}}
                                InputLabelProps={{sx:formInputLabelStyle, shrink: true}}
                                variant="standard"
                                label={t(sprinklersPrefix + "end-pressure")}
                                type="number"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">{
                                            displaySettings.pressures === "psi" ? t("common-phrases.sltPSI") : t("common-phrases.sltBar")
                                        }
                                        </InputAdornment>
                                }}
                                value={displayEndPressure}
                                onChange={(ev) => {
                                  setDisplayEndPressure(ev.target.value);
                                }}

                                onBlur={() => {
                                    let val = parseFloatOrUndefined(displayEndPressure);
                                    if (typeof val !== "undefined" && displaySettings.pressures === "bar"){
                                        val = barToPsi(val);
                                    }

                                    props.sprinklerValidator.options.designedEndPressure.set(val);
                                }}
                            />
                            <Stack direction={'row'} style={{marginLeft: 10, marginRight: 10}}>
                                <TextField
                                error={props.sprinklerValidator.options.peakElevation.isError}
                                    style={{width: "70%", marginRight: 20}}
                                    InputLabelProps={{sx:formInputLabelStyle, shrink: true}}
                                    variant="standard"
                                    label={t(sprinklersPrefix + "elevation")}
                                    type="number"
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">{
                                            displaySettings.lengths === "feet" ? "ft" : "m"
                                        }</InputAdornment>
                                    }}
                                    value={displayElevation}
                                    onChange={(ev) => {
                                        setPeakElevationAutoset(false); // to prevent the effect auto changing the elev if this field is blanked
                                        setDisplayElevation(ev.target.value);
                                    }}
                                    onBlur={() => {
                                        let val = parseFloatOrUndefined(displayElevation);
                                        if (typeof val !== "undefined" && displaySettings.lengths === "meters"){
                                            val = metersToFeet(val);
                                        }
                                        props.sprinklerValidator.options.peakElevation.set(val);
                                    }}
                                />
                                <TextField
                                    style={{width: "30%"}}
                                    InputLabelProps={{sx:formInputLabelStyle, shrink: true}}                          
                                    variant="standard"
                                    label={t(sprinklersPrefix + "map-elevation")}
                                    type="text"
                                    InputProps={{
                                        endAdornment: 
                                            <InputAdornment position="end">
                                                {displaySettings.lengths === "feet" ? "ft" : "m"}
                                            </InputAdornment>,
                                        startAdornment: loadingElevation
                                            ? (
                                                <InputAdornment position="start">
                                                    <CircularProgress size={20} />
                                                </InputAdornment>
                                            )
                                            : undefined
                                    }}
                                    value={mapElvationValue}
                                    disabled
                                />
                            </Stack>
                    </Stack>
                </Card>
                {
                    ((props.sprinklerValidator.options.sprinklerEngineCode.value ?? SprinklerManufacturerTypes.None) !== SprinklerManufacturerTypes.None) && (
                        <>
                            <Card style={{ marginBottom: 20, marginRight: 20, padding: 10, maxWidth: 900 }} variant={"outlined"}>
                                <Typography sx={pageStyles.cardTitleStyle}>{flexBoomBack ? t("roe.sprinklers.flanged-boombacks") : "Boom Backs"}</Typography>
                                <SprinklerBoomBacks
                                    side={SideEnum.Flanged}
                                    sprinklerValidator={props.sprinklerValidator}
                                />
                            </Card>
                            { 
                                flexBoomBack &&
                                    <Card style={{ marginTop: 5, marginRight: 20, padding: 10, maxWidth: 900 }} variant={"outlined"}>
                                        <Stack direction="row">
                                            <Typography sx={{ ...pageStyles.cardTitleStyle, flexGrow: 1 }}>{t("roe.sprinklers.flexed-boombacks")}</Typography>
                                            <Button
                                                disabled={!props.sprinklerValidator.copyFromFlanged.boomBacks.canCopy}
                                                onClick={props.sprinklerValidator.copyFromFlanged.boomBacks.copy}
                                            >
                                                {t("roe.sprinklers.copy-from-flanged-side-boombacks")}
                                            </Button>
                                        </Stack>
                                        <SprinklerBoomBacks
                                            side={SideEnum.Flex}
                                            sprinklerValidator={props.sprinklerValidator}
                                        />
                                    </Card>
                            }
                        </>
                    )
                }
            </Stack>
        </>
    );
};
const parseFloatOrUndefined = (str: string): number | undefined => {
    const num = parseFloat(str);
    return isNaN(num) ? undefined : num;
}

export default SprinklerOptions;