import { t } from "i18next";
import IUserData from "rdptypes/IUserData";
import { IAddress } from "rdptypes/api/IGrowerBase";
import { getSwingArmAndEndBoomLengthFeet } from "rdptypes/helpers/EndOfSystem";
import { DeviceTypes, DropTypes, IPackage, RegulatorLocations, RegulatorTypes, RigidDropTypes } from "rdptypes/project/ISprinklers";
import { BaseInletSizes, BoosterPumpTypes, BuckBoostTypes, ElectricalFrequencies, EndGunTypes, EndOfSystemTypes, GearDriveTypes, HoseFeedTypes, IFlangedSide, IFlexSide, ISystemBase, PanelModels, RadioTelemetrySubscriptionPeriods, RadioTelemetryTypes, SACDistributionFlowRate, SACZoneControlTypes, SwingArmLengths, SystemTypes, TireSizes, TowerHeights, WheelGearSeries } from "rdptypes/project/ISystemBase.AutoGenerated";
import IPartsCatalogEntry from "rdptypes/roe/IPartsCatalogEntry";
import IPartsList from "rdptypes/roe/IPartsList";
import IBillOfMaterialsEntry from "rdptypes/roe/IPartsListEntry";
import { partsPackages } from "roedata/data";
import DiscountConfig from "roedata/mapics/DiscountConfig";
import Acres, { IValuesFromFieldDesign } from "roedata/roe_migration/Acres";
import { MotorTypes } from "roedata/roe_migration/CommonEnums";
import { BuckBoostText, DeviceTypeText, SpanCableText, TireAndWheelText } from "roedata/roe_migration/CommonFunctions";
import ElectricalCalculator from "roedata/roe_migration/ElectricalCalculator";
import { DegreeOfSweep, PercentOfCircle } from "roedata/roe_migration/OtherHelpers";
import { POSections } from "roedata/roe_migration/POConstants";
import PartsAssembler from "roedata/roe_migration/PartsAssembler";
import { ISectionGroupedParts } from "roedata/roe_migration/PriceCalculator";
import QuoteClass from "roedata/roe_migration/QuoteClass";
import { GuidanceTower, NumberOfSpans } from "roedata/roe_migration/SideFunctions";
import { CableConductors, ConductorCount, ConductorsRequired, EndingLocation, PipeType, SpanPipeTypes, idxCableConductors } from "roedata/roe_migration/SpanFunctions";
import { ESACZoneFactors } from "roedata/roe_migration/SprinklerEngineTypes/ESACZoneFactors";
import { DeviceWateringDiameter } from "roedata/roe_migration/SprinklersChartSideClass.SprinklerHelper";
import { FieldSets, HasEnergySaverPackage, HasSwingArmCorner, IsDualSided, IsEDMP, IsReverseTow } from "roedata/roe_migration/SystemFunctions";
import { strLanguageText } from "roedata/roe_migration/TranslationsLib";
import { IrrigatedAreaHelper } from "../../../../GeometryHelpers/IrrigatedAreaHelper";
import IDevSettingsState from "../../../../db/IDevSettingsState";
import { DisplayLengthUnitBuilder } from "../../../../helpers/lengths";
import { isCenterPivot } from "../../../../helpers/system";
import IProject from "../../../../model/project/IProject";
import ISystem from "../../../../model/project/ISystem";
import { IDisplaySettingsFormState } from "../../../DealerSettingsDialog/TabDisplaySettings";
import { SpacingValToSpacingEnum, descriptionFromSpacingClass, getRegulatorPressureLabel, getSpacingDropdownValues } from "../Sprinklers/helpers/SprinklerHelper";
import { IElectricalMotorInformationTableRow } from "./../../../../docGeneration/components/ElectricalMotorInformationTable";
import { IOntracFeesInformationTable } from "./../../../../docGeneration/components/OntracFeesTable";
import { IProposalSpan, ISpanInformationTableTowerBuckBoost } from "./../../../../docGeneration/components/SpanInformationTable";

export enum SystemSpanSide {
    flanged,
    flex
}
export const getGroupedPartsForSystem = (system: ISystem) => {
    let partGroups: IGroupedSystemParts = {};
    let partSections: ISectionGroupedParts = {};
    let partEntries: {[partNumber: string]: IBillOfMaterialsEntry} = {};


    const { roeData } = partsPackages[system.partsPackageId];

    for (const plg of roeData.partsListGenerators) {
        let pl: IPartsList;
        try {
            pl = plg(system);
        } catch (ex) {
            /* Unhandled exceptions in a parts list generator should never happen and need to
            be fixed in the code. However the user might be able to work around the error themselves.
            For this reason we skip this parts list generator. There is a rule which will generate a
            critical error if the parts list generation fails and so the user won't be able to ignore
            it and submit an order. */
            break;
        }

        for (const e of pl.entries) {
            if (e.partNumber in partEntries) {
                partEntries[e.partNumber].qty += e.qty;
            } else {
                if (!(e.groupId in partGroups)){
                    partGroups[e.groupId] = [];
                }
                if (!(e.sectionId in partSections)){
                    partSections[e.sectionId] = [];
                }
    
                partEntries[e.partNumber] = e;
                partGroups[e.groupId].push(e);
                partSections[e.sectionId].push(e);
            }
        }
    }

    return {partGroups, partSections};
}

export interface IAncillaryPart {
    section: POSections;
    part: IPartsCatalogEntry;
    qty: number;
    partPriceCents: number;
}

export const proposalSectionIsAncillary = (section: POSections) => {
    switch(section){
        case POSections.AgriInject:
        case POSections.MainlineValves:
        case POSections.RadioTelemetry:
        case POSections.ClemonsFilters:
        case POSections.CheckValves:
        case POSections.HeatExchangers:
        case POSections.SprinklerLube:
        case POSections.FlowmeterComponentsPartsPricing:
        case POSections.RC10:
        case POSections.RC10Retro:
        case POSections.CropX:
            return true;
        default:
            return false;
    }
}

export const getSectionLabel = (section: POSections) => {
    //currently only used for ancillary sections
    switch(section){
        case POSections.AgriInject:
            return "Agri Inject";
        case POSections.MainlineValves:
            return "Mainline Valves";
        case POSections.RadioTelemetry:
            return "Radio Telemetry";
        case POSections.ClemonsFilters:
            return "Clemons Filters";
        case POSections.CheckValves:
            return "Check Valves";
        case POSections.HeatExchangers:
            return "Heat Exchangers";
        case POSections.SprinklerLube:
            return "Sprinkler Lube";
        case POSections.FlowmeterComponentsPartsPricing:
            return "Flowmeter";
        case POSections.RC10:
            return "RC10";
        case POSections.RC10Retro:
            return "RC10 Retro";
        case POSections.CropX:
            return "CropX";
        default:
            return "Section label not defined";
    }
}

export interface IGroupedSystemParts {
    [groupId: string]: {
        qty: number, 
        partNumber: string
    }[];
}

export const formatAddress = (address: IAddress, separator = ", ") => {
    // Combine state and zip into one part
    let stateZip = address.state;
    if (address.zip) {
        if (stateZip) {
            stateZip += " ";
        }
        stateZip += address.zip;
    }
    
    return [
        address.line1,
        address.line2,
        address.city,
        stateZip,
        address.country
    ]
    .filter(part => !!part)
    .join(separator);
}

export const getFactorData = (quote: QuoteClass) => {
    if (!quote.RightEOSClass?.FieldSet.DataValid() || !HasSwingArmCorner(quote.System) || !quote.SwingArmClass.FieldSet.DataValid()){
        return "";
    }
    
    let zaf = quote.System.Circle.SwingArm.ESAC125DistributionFlowRateFactors;
    let ezf = new ESACZoneFactors();
    ezf.LoadESAC125Factors(zaf);

    let str = "AF: ";
    let topEnd = quote.System.Circle.SwingArm.ZoneControlType === SACZoneControlTypes.ESAC060 ? 6 : 12;
    for (let zone = 1; zone <= topEnd; zone++){
        str += ezf.SelectFactor(quote.System.Circle.SwingArm.ZoneControlType, 
            quote.System.Circle.SwingArm.DistributionFlowRate,
            quote.System.FlangedSide.EndOfSystem.SwingArmLength,
            zone);
        
        if (zone !== topEnd){
            str += ", ";
        }
    }

    return str;
}

export const getGPSSurveyData = (quote: QuoteClass) => {
    let st = quote.System.SystemProperties.SystemType;
    if (!FieldSets(quote.System).SystemType.DataValid() ||
        typeof(st) === "undefined" || 
        st === SystemTypes.KwikTow) {
        return undefined;
    }

    if (st === SystemTypes.CenterPivot || st === SystemTypes.SwingArmRetro){
        if (HasSwingArmCorner(quote.System) && FieldSets(quote.System).SwingArm.DataValid() && quote.System.Circle?.SwingArm?.GPSCoverageStudyNetworkName){
            let sa = quote.System.Circle.SwingArm;
            return {
                network: sa.GPSCoverageStudyNetworkName,
                site: sa.GPSCoverageStudySiteName,
                baseLoc: sa.GPSCoverageStudyBaseLocation,
                startLoc: sa.GPSCoverageStudyPivotLocation,
                endLoc: undefined
            }
        }
    }
    else if (st === SystemTypes.HoseFeedMaxigator || st === SystemTypes.CanalFeedMaxigator){
        if ((FieldSets(quote.System).HoseFeed.DataValid() || FieldSets(quote.System).CanalFeed.DataValid()) && FieldSets(quote.System).Guidance.DataValid() && quote.System.Lateral?.Guidance?.GPSCoverageStudyNetworkName) {
            let g = quote.System.Lateral.Guidance;
            return {
                network: g.GPSCoverageStudyNetworkName,
                site: g.GPSCoverageStudySiteName,
                baseLoc: g.GPSCoverageStudyBaseLocation,
                startLoc: g.GPSCoverageStudyLateralStartLocation,
                endLoc: g.GPSCoverageStudyLateralEndLocation
            }
        }
    }

    return undefined;
}

export const getPivotType = (quote: QuoteClass) => {
        if (quote.System.SystemProperties.SystemType === SystemTypes.SwingArmRetro || quote.System.SystemProperties.SystemType === SystemTypes.Ancillary){
            return "N/A";
        }
        else if (quote.System.SystemProperties.SystemType !== SystemTypes.CenterPivot){
            return "G";
        }
        else if (quote.CenterPivotClass.FieldSet.DataValid()){
            let cp = quote.System.Circle.CenterPivot;

            if (cp.PivotCenterHeight === TowerHeights.LowProfile) {
                return "LP";
            }
            if (cp.PivotCenterHeight === TowerHeights.Sugargator){
                return "SG";
            }
            if (cp.PivotCenterHeight === TowerHeights.Supergator){
                return "SP";
            }
            return "G";
        }
    
    return "N/A";
}

export const getRDPSystemModel = (quote: QuoteClass) => {
    let st = quote.System.SystemProperties.SystemType;

    if (isCenterPivot(quote.System)){
        if (st === SystemTypes.SwingArmRetro) return quote.System.Circle.SACRetro.ParentSpanType;
        if (quote.System.Circle.CenterPivot.CenterPivotType) return quote.System.Circle.CenterPivot.CenterPivotType;
    }
    else {
        if (quote.System.FlangedSide.Span.length) return quote.System.FlangedSide.Span[0].SpanType;
        if (quote.System.FlexSide.Span.length) return quote.System.FlexSide.Span[0].SpanType;
    }
    return t("reports.not-yet-set");
}

export const getDiscountConfigFromUserData = (user: IUserData, system: ISystem): DiscountConfig => {
    let dd = user.discounts;
    let pricing = system.QuoteProperties.ProposalInformation.pricing;

    return {
        StandardDiscount: dd.standard,
        ProgramDiscount: pricing.programDiscountPercent ?? dd.program,
        CashDiscount: dd.cash ?? 0,
        ClemonsFiltersDiscount: dd.clemonsFilters,
        CheckValvesDiscount: dd.checkValves,
        HeatExchangersDiscount: dd.heatExchangers,
        MainlineValveDiscount: dd.mainlineValve,
        SprinklerLubeDiscount: dd.sprinklerLube,
        AgriInjectDiscount: dd.agriInject,
        RadioTelemetryDiscount: dd.radioTelemetry ?? 0,
        RC10Discount: dd.rc10RadioTelemetry,
        RC10RetroDiscount: dd.rc10Retro,
        CropXDiscount: dd.cropXProbe,
        FlowmeterComponentsDiscount: dd.flowmeter ?? 0,
        DeliveryDiscount: pricing.deliveryDiscountPercent ?? dd.delivery ?? 0,
        TerritoryManagerDiscount: pricing.tmDiscountPercent ?? dd.tm,
        CreateDateUtc: null,
        ModifyDateUtc: null,
        IsInternational: user.dealership.international,
        IsNationalAccountOrder: user.dealership.nationalAccount
    };
}

export const getSystemModel = (quote: QuoteClass, settings: IDevSettingsState) => {
    let model = "";
    let spacing = "";

        if (quote.System.SystemProperties.SystemType === SystemTypes.SwingArmRetro && 
            quote.SwingArmClass.FieldSet.DataValid()) {
            model += quote.SwingArmClass.GetPipeModel(model);
        }
        
        if (quote.RightSpansClass.FieldSet.DataValid()) {
            model += quote.RightSpansClass.GetPipeModel(model);
            spacing = quote.RightSpansClass.GetPipeSpacingText();
        }

        if (quote.LeftSpansClass.FieldSet.DataValid()) {
            model += quote.LeftSpansClass.GetPipeModel(model);
            spacing = quote.LeftSpansClass.GetPipeSpacingText();
        }

        model += "-G";

    if (quote.IsTowable()){
        model += " Tow";
    }

    if (systemHasHoseFeed(quote) && quote.HoseFeedClass.FieldSet.DataValid()){
        let hf = quote.System.Lateral.HoseFeed;
        switch (hf.HoseFeedType){
            case HoseFeedTypes.Standard:
                model += " Maxi";
                break;
            case HoseFeedTypes.DoubleEndFeed:
                model += " DEF Maxi";
                break;
            case HoseFeedTypes.FourWheelDrive:
                if (hf.BaseInletSize != BaseInletSizes.a8) {
                    model += " 4WD Maxi";
                }
                else {
                    model += " 4WD 8 Maxi";
                }
                break;
            case HoseFeedTypes.Sugargator:
                model += " Sug Maxi";
                break;
            case HoseFeedTypes.PivotingLateral:
                model += " Piv Maxi";
                break;
        }
    }

    if (systemIsCanalFeed(quote)){
        model += " Canal Feed";
    }

    if (systemIsKwikTow(quote)) {
        model += " KT";
    }

    if (quote.RightEOSClass.FieldSet.DataValid()) {
        let eos = quote.System.FlangedSide.EndOfSystem;
        if (eos.EndOfSystemType === EndOfSystemTypes.SAC && quote.SwingArmClass.FieldSet.DataValid()){
            if (eos.SwingArmLength === SwingArmLengths.SAC213){
                model += " ESSAC";
            }
            else {
                model += " ESAC";
            }

            if (quote.System.Circle.SwingArm.VRISACSpan) {
                model += " VRI";
            }
            else {
                let zct = quote.System.Circle.SwingArm.ZoneControlType;
                switch (zct){
                    case SACZoneControlTypes.ESAC060:
                        model += " 6.0";
                        break;
                    case SACZoneControlTypes.ESAC120:
                        model += " 12.0";
                        break;
                    case SACZoneControlTypes.ESAC125:
                        model += " 12.5";
                        break;
                }
            }

            let fr = quote.System.Circle.SwingArm.DistributionFlowRate;
            switch (fr){
                case SACDistributionFlowRate.Low:
                    model += " LOW";
                    break;
                case SACDistributionFlowRate.Medium:
                    model += " MEDIUM";
                    break;
                case SACDistributionFlowRate.High:
                    model += " HIGH";
                    break;
            }
        }

        if (HasSwingArmCorner(quote.System) && quote.SwingArmClass.FieldSet.DataValid()){
            if (quote.System.Circle.SwingArm.LeadingSwingArm){
                model += " / Leading";
            }
            else {
                model += " / Trailing";
            }
        }

        if (systemIsSugargator(quote)) model += " SG";
        if (systemIsSupergator(quote)) model += " SP";
        if (systemIsLowProfile(quote)) model += " LP";
        if (IsEDMP(quote.System)) model += " EDMP";
        if (IsReverseTow(quote.System)) model += " Rev Tow";

        if (spacing.length > 0 && !isNaN(parseFloat(spacing))){
            let spacingStr = new DisplayLengthUnitBuilder(parseFloat(spacing), 'inches')
                .convert(settings.dealerSettings.display.current.minorLengths)
                .appendValue(0)
                .appendString(" ")
                .appendSymbol()
                .toString();


            model += " / " + spacingStr;
        }
    }

    return model;
}

export const systemHasHoseFeed = (quote: QuoteClass): boolean => {
    return quote.System.SystemProperties.SystemType === SystemTypes.HoseFeedMaxigator;
}

export const systemIsKwikTow = (quote: QuoteClass): boolean => {
    return quote.System.SystemProperties.SystemType === SystemTypes.KwikTow;
}

export const systemIsCanalFeed = (quote: QuoteClass): boolean => {
    return quote.System.SystemProperties.SystemType === SystemTypes.CanalFeedMaxigator;
}

export const systemIsLowProfile = (quote: QuoteClass): boolean => {
    let lp = false;
    if (quote.System.SystemProperties.SystemType === SystemTypes.CenterPivot &&
        quote.System.Circle.CenterPivot.PivotCenterHeight === TowerHeights.LowProfile) {
        lp = true;
    }

    if (quote.RightTowersClass.FieldSet.DataValid() && quote.RightTowersClass.AnyLowProfiles()){
        lp = true;
    }

    if (quote.LeftTowersClass.FieldSet.DataValid() && quote.LeftTowersClass.AnyLowProfiles()){
        lp = true;
    }

    return lp;
}

export const systemIsSugargator = (quote: QuoteClass): boolean => {
    let sugargator = false;
    if (quote.System.SystemProperties.SystemType === SystemTypes.CenterPivot &&
        quote.System.Circle.CenterPivot.PivotCenterHeight === TowerHeights.Sugargator) {
        sugargator = true;
    }

    if (quote.RightTowersClass.FieldSet.DataValid() && quote.RightTowersClass.AnySugargators()){
        sugargator = true;
    }

    if (quote.LeftTowersClass.FieldSet.DataValid() && quote.LeftTowersClass.AnySugargators()){
        sugargator = true;
    }

    return sugargator;
}

export const systemIsSupergator = (quote: QuoteClass): boolean => {
    let supergator = false;
    if (quote.System.SystemProperties.SystemType === SystemTypes.CenterPivot &&
        quote.System.Circle.CenterPivot.PivotCenterHeight === TowerHeights.Supergator) {
            supergator = true;
    }

    if (quote.RightTowersClass.FieldSet.DataValid() && quote.RightTowersClass.AnySupergators()){
        supergator = true;
    }

    if (quote.LeftTowersClass.FieldSet.DataValid() && quote.LeftTowersClass.AnySupergators()){
        supergator = true;
    }

    return supergator;
}

export const getSystemGPM = (quote: QuoteClass): number => {
    if (quote.System.SprinklerProperties && typeof quote.System.SprinklerProperties.TotalGPM !== "undefined"){
        return quote.System.SprinklerProperties.TotalGPM;
    }

    return undefined;
}

export const getSystemMaxGPM = (quote: QuoteClass): number => {
    if (quote.System.SprinklerProperties && typeof quote.System.SprinklerProperties.MaximumSystemGPM !== "undefined"){
        return quote.System.SprinklerProperties.MaximumSystemGPM;
    }

    return undefined;
}

export const getSystemMinGPM = (quote: QuoteClass): number => {
    if (quote.System.SprinklerProperties && typeof quote.System.SprinklerProperties.MinimumSystemGPM !== "undefined"){
        return quote.System.SprinklerProperties.MinimumSystemGPM;
    }

    return undefined;
}

export const getSystemLengthFeet = (quote: QuoteClass): number => {
    let sysLengthFeet = 0;
    let flangedSpans = quote.System.FlangedSide.Span;
    if (flangedSpans.length > 0){
        sysLengthFeet += EndingLocation(quote.System, quote.System.FlangedSide, flangedSpans[flangedSpans.length - 1]);
    }

    if (IsDualSided(quote.System)){
        let flexSideSpans = quote.System.FlexSide.Span;
        if (flexSideSpans.length > 0){
            sysLengthFeet += EndingLocation(quote.System, quote.System.FlexSide, flexSideSpans[flexSideSpans.length - 1]);
        }
    }

    return sysLengthFeet;
}


export const getSystemElevationFeet = (quote: QuoteClass): number => {
    if (quote.SprinklerChartClass.FieldSet.DataValid() && quote.System.SprinklerProperties && typeof quote.System.SprinklerProperties.PeakElevation !== "undefined"){
        return quote.System.SprinklerProperties.PeakElevation;
    }

    return 0;
}

export const getSystemTopOfInletPressurePsi = (quote: QuoteClass): number => {
    //this used to use PumpElevation but I don't think current ROE has a separate Pump/PeakElevation anyway
    if (!quote.System.SprinklerProperties || typeof quote.System.SprinklerProperties.PivotPressure === "undefined" || typeof quote.System.SprinklerProperties.PeakElevation === "undefined"){
        return undefined;
    }

    if (HasEnergySaverPackage(quote.System)){
        let mmfrp = quote.GetMinMaxFlowRateAndPSILoss();

        let sPumpElevationLoss = 0 - (quote.System.SprinklerProperties.PeakElevation / 2.31);
        let totalGPM = quote.System.SprinklerProperties.TotalGPM;

        let sMainLineFactorLoss = quote.System.SprinklerProperties.MainlineLossAtMaxGPMForESP * Math.pow((totalGPM/mmfrp.maxFlow), 1.85);

        return quote.System.SprinklerProperties.PumpPressureAtSysGPMForESP - quote.BasePressureMod() - sPumpElevationLoss - sMainLineFactorLoss;
    }
    else {
        return quote.System.SprinklerProperties.PivotPressure;
    }
}

export const getESPRelatedLoss = (quote: QuoteClass): number => {
    if (!HasEnergySaverPackage(quote.System) || !FieldSets(quote.System).SprinklerChart.DataValid() || !FieldSets(quote.System).SprinklerConfig.DataValid()){
        return 0;
    }

    let sprinklerprops = quote.System.SprinklerProperties;

    let mmfrp = quote.GetMinMaxFlowRateAndPSILoss();
    let endPressure = quote.System.FlangedSide.SprinklerChart.IrrigationProperties.ComputedEndPressure;
    let systemElevationLoss = sprinklerprops.PeakElevation / 2.31;
    let pumpElevationLoss = 0 - sprinklerprops.PumpElevation / 2.31;

    let totalGPM = quote.System.SprinklerProperties.TotalGPM;
    let sMainLineFactorLoss = quote.System.SprinklerProperties.MainlineLossAtMaxGPMForESP * Math.pow((totalGPM/mmfrp.maxFlow), 1.85);

    let psiLossAtSysFlow = sprinklerprops.PivotPressure - endPressure - systemElevationLoss;

    return psiLossAtSysFlow + sprinklerprops.PumpPressureAtSysGPMForESP - endPressure - systemElevationLoss - quote.BasePressureMod()  - pumpElevationLoss - sMainLineFactorLoss;
}

export const SpacingBuilder = (quote: QuoteClass, side: SystemSpanSide, desc: string) => {
    let quoteSide: IFlangedSide | IFlexSide = (side === SystemSpanSide.flanged) ? quote.System.FlangedSide : quote.System.FlexSide;
    let descriptors: string[] = [];

    let maxSpace = -1;
    quoteSide.SprinklerChart.Outlet.forEach((o, i) => {
        if (o.Device !== DeviceTypes.None && !o.ESP){
            if (o.MaximumSpacingSetting < maxSpace){
                let str = `${desc} maximum spacing set from ${maxSpace} feet to ${o.MaximumSpacingSetting} feet at outlet ${i + 1} located at ${o.Location.toFixed(2)} feet.`;
                descriptors.push(str);
            }
            if (o.MaximumSpacingSetting < maxSpace || maxSpace === -1){
                maxSpace = o.MaximumSpacingSetting;
            }
        }
    });
    return descriptors.join("/");
}

export const PackageDescriptorBuilder = (pkg: IPackage, title: string, settings: IDisplaySettingsFormState, quote: QuoteClass, side: SystemSpanSide) => {
    let str = `${title}: ${DeviceTypeText(pkg.Device, pkg.UseNelson3030, pkg.UseNelsonAD3030MT)}`;
    if (pkg.EndingLocation > 0){
        str += `, ${pkg.EndingLocation} feet`
    }
    else {
        str += ", EOS";
    }

    if (pkg.Drop === DropTypes.Hose){
        str += `, Drop Clearance: ${pkg.HoseDrop.GroundClearance.toFixed(2)} feet`;
        if (pkg.Regulator.RegulatorType !== RegulatorTypes.None){
            if (pkg.HoseDrop.RegulatorLocation === RegulatorLocations.Top){
                str += ", Reg on Top";
            }
            else if (pkg.HoseDrop.RegulatorLocation === RegulatorLocations.Bottom){
                str += ", Reg on Bottom";
            }
        }
    }
    else if (pkg.Drop === DropTypes.Rigid){
        if (pkg.RigidDrop.GroundClearance > 0){
            str += `, Drop Clearance: ${pkg.RigidDrop.GroundClearance.toFixed(2)} feet`;
        }
    }

    if (pkg.Regulator.RegulatorType !== RegulatorTypes.None){
        str += `, Reg : ${getRegulatorPressureLabel(pkg.Regulator.Pressure, settings)}`;
    }

    if (pkg.Spacing > 0){
        let quoteSide: IFlangedSide | IFlexSide = (side === SystemSpanSide.flanged) ? quote.System.FlangedSide : quote.System.FlexSide;

        let allowedSpacings = getSpacingDropdownValues(pkg, quote.System, quoteSide);
        let mySpacingIdx = allowedSpacings.findIndex(x => x.value === SpacingValToSpacingEnum(pkg.Spacing));
        let spacingStr = "Unknown";

        if (mySpacingIdx > -1){
            spacingStr = descriptionFromSpacingClass(allowedSpacings[mySpacingIdx], settings.lengths === "feet" ? "feet" : "meters");
        }

        str += `, Spacing: ${spacingStr}`;
    }

    if (pkg.SecondNozzleGPM > 0) {
        str += `, Second Nozzle GPM ${pkg.SecondNozzleGPM}`;
    }

    if (pkg.ThirdNozzleGPM > 0){
        str += `, Third Nozzle GPM ${pkg.ThirdNozzleGPM}`;
    }

    if (pkg.NormalSpacing){
        str += `, Drop Spacing Gradually`;
    }

    if (pkg.DevicesDoubled){
        str += `, Devices Doubled`;
    }

    return str;

}

export const getSystemEndPressurePsi = (quote: QuoteClass): number => {
    if (typeof quote.System.SprinklerProperties?.DesignedEndPressure !== "undefined"){
        return quote.System.SprinklerProperties?.DesignedEndPressure;
    }

    return undefined;
}

export const getSpans = (system: ISystem, side: SystemSpanSide, quote: QuoteClass): IProposalSpan[] => {
    //flanged side === right side
    let spans: IProposalSpan[] = [];
    let quoteSide: IFlangedSide | IFlexSide = (side === SystemSpanSide.flanged) ? quote.System.FlangedSide : quote.System.FlexSide;

    const ec = new ElectricalCalculator(system, undefined);
    const agr = ec.AutoGauge();
    const cableGauges = side === SystemSpanSide.flanged ? agr.flangedSide : agr.flexSide!;

    for (let i = 1; i <= NumberOfSpans(quoteSide); i++) {
        const span = quoteSide.Span[i - 1];
        if (span.EndBoom || span.SwingArm) return;

        let tireSize: string;

        if (i === NumberOfSpans(quoteSide) && quote.IsDEF() && FieldSets(quote.System).HoseFeed.DataValid()){
            //last span
            if (quote.System.Lateral?.Tires?.TireSize) {
                tireSize = TireAndWheelText(quote.System.Lateral.Tires);
            }
        }
        else {
            if (quoteSide.Tower[i - 1]?.Tires?.TireSize) {
                tireSize = TireAndWheelText(quoteSide.Tower[i - 1].Tires);
            }
        }

        let psiLoss = 0.0;
        if (quote.SprinklerChartClass && quote.SprinklerChartClass.FieldSet.DataValid()){
            if (side === SystemSpanSide.flanged){
                if (quote.SprinklerChartClass.RightSprinklersClass){
                    psiLoss = quote.SprinklerChartClass.RightSprinklersClass.SpanPressureLoss(i);
                }
            }
            else {
                if (quote.SprinklerChartClass.RightSprinklersClass){
                    psiLoss = quote.SprinklerChartClass.LeftSprinklersClass.SpanPressureLoss(i);
                }
            }
        }

        let type: string = span.SpanType;

        if (i === quote.RigidSpan()){
            type += " " + strLanguageText("sltRigid");
        }
        else if (i === GuidanceTower(quoteSide)){
            type += " " + strLanguageText("sltGuidance");
        }

        const idxCC = CableConductors(quote.System, quote.System.FlangedSide, span);
        let cc: number;
        //replaces the weird idxCableConductors.ToInteger method in ExtensionMethods.Enums.vb (we don't actually want the index here!)
        switch (idxCC) {
            case idxCableConductors.cc10:
                cc = 10;
                break;
            case idxCableConductors.cc11:
                cc = 11;
                break;
            case idxCableConductors.cc14:
                cc = 14;
                break;
        }
        let ecc = cc - ConductorsRequired(quote.System, quoteSide, span) + (isNaN(span.ExtraCableConductors) ? 0 : span.ExtraCableConductors);
        let spanCable = `${cc}C/${SpanCableText(cableGauges[i - 1].cableGauge, false)}/${ecc} ${strLanguageText("sltSpare")}`;

        spans.push({
            number: span.SpanNumber.toString(),
            type,
            lengthFeet: span.Length,
            spacingInches: span.Spacing,
            ext: span.Extension ? "Y":"N",
            spanCable,
            tireSize,
            psiLoss,
            endingLocationFeet: EndingLocation(quote.System, quoteSide, span)
        });
    }

    //then we do something special with a SAC
    if (HasSwingArmCorner(quote.System) && side === SystemSpanSide.flanged && quote.System.FlangedSide.EndOfSystem.EndOfSystemType === EndOfSystemTypes.SAC){
        spans.push(getSACSpan(quote));
    }

    //then deal with the EOS
    if (side === SystemSpanSide.flanged || (side === SystemSpanSide.flex && IsDualSided(quote.System))) {
        spans.push(getEOSSpan(quote, side));
    }
    return spans;
}

export const getEOSSpan = (quote: QuoteClass, side: SystemSpanSide): IProposalSpan => {
    //if complete, EOSClass is either an end boom or a sac + endboom (sac only possible RHS)
    //valid LeftEOSClass if it is dual sided

    let eosClass = side === SystemSpanSide.flanged ? quote.RightEOSClass : quote.LeftEOSClass;
    let boomInfo = eosClass.GetBoomInfo();

    let psiLoss = 0.0;
    if (quote.SprinklerChartClass && quote.SprinklerChartClass.FieldSet.DataValid()){
        const i = eosClass.EndBoomSpan();
        if ((side === SystemSpanSide.flanged && i != 0) || (side === SystemSpanSide.flex && i !== quote.LeftSpansClass.Side.Span.length)) {
            if (side === SystemSpanSide.flanged){
                if (quote.SprinklerChartClass.RightSprinklersClass){
                    psiLoss = quote.SprinklerChartClass.RightSprinklersClass.SpanPressureLoss(i);
                }
            }
            else {
                if (quote.SprinklerChartClass.LeftSprinklersClass){
                    psiLoss = quote.SprinklerChartClass.LeftSprinklersClass.SpanPressureLoss(i);
                }
            }
        }
    }

    return {
        number: "EB",
        ext: "N",
        psiLoss,
        type: boomInfo.spanType,
        tireSize: "",
        spanCable: "",
        lengthFeet: boomInfo.lengthFeet,
        spacingInches: boomInfo.spacingInches,
        endingLocationFeet: boomInfo.endingLocationFeet
    };
}

export const getSACSpan = (quote: QuoteClass): IProposalSpan => {
    let iSwingArmSpan = quote.System.FlangedSide.Span.length > 0 ? quote.System.FlangedSide.Span.length - 1 : 0;

    let psiLoss = 0.0;
    if (quote.SprinklerChartClass && quote.SprinklerChartClass.FieldSet.DataValid() && quote.SprinklerChartClass.RightSprinklersClass){
        let i = NumberOfSpans(quote.System.FlangedSide) + 1;
        psiLoss = quote.SprinklerChartClass.RightSprinklersClass.SpanPressureLoss(i);
    }

    let span = quote.System.FlangedSide.Span[iSwingArmSpan];

    return {
        number: quote.System.FlangedSide.EndOfSystem.SwingArmLength,
        type: span.SpanType,
        lengthFeet: getSwingArmAndEndBoomLengthFeet(quote.System.FlangedSide.EndOfSystem.SwingArmLength).swingArmLengthFeet,
        ext: "N",
        spacingInches: 57,
        tireSize: TireAndWheelText(quote.System.Circle.SwingArm.Tires),
        psiLoss,
        spanCable: "",
        endingLocationFeet: EndingLocation(quote.System, quote.System.FlangedSide, span)
    };
}

export const getTowerBuckboosts = (quote: QuoteClass, side: SystemSpanSide): ISpanInformationTableTowerBuckBoost[] => {
    let towerBuckBoosts: ISpanInformationTableTowerBuckBoost[] = [];

    let quoteSide: IFlangedSide | IFlexSide = (side === SystemSpanSide.flanged) ? quote.System.FlangedSide : quote.System.FlexSide;
    
    quoteSide.Tower.forEach((tower) => {
        if (tower.BuckBoost !== BuckBoostTypes.aNone){
            towerBuckBoosts.push({buckboostType: tower.BuckBoost});
        }
    });

    return towerBuckBoosts;
}

const getPeriodStr = (period: RadioTelemetrySubscriptionPeriods) => {
    switch (period){
        case RadioTelemetrySubscriptionPeriods.a4Months:
            return "4 Month";
        case RadioTelemetrySubscriptionPeriods.a6Months:
            return "6 Month";
        case RadioTelemetrySubscriptionPeriods.a9Months:
            return "9 Month";
        case RadioTelemetrySubscriptionPeriods.a12Months:
            return "12 Month";
    }
}

export const getOntracFees = (quote: QuoteClass): IOntracFeesInformationTable[] => {
    let ontracFees: IOntracFeesInformationTable[] = [];
    
    let ontrac = quote.System.Ontrac;
    if (ontrac){
        if (ontrac.Radio){
            ontracFees.push({feeUsdCents: 0, period: ""});
        }
        if (ontrac.Cell && ontrac.Cell.CellUnit && ontrac.Cell.CellUnit.length){
            ontrac.Cell.CellUnit.forEach((cu) => {
                if (typeof(cu.SubscriptionPeriod) !== "undefined"){
                    let fee = getSubscriptionPriceUsdCents(RadioTelemetryTypes.Cell, cu.SubscriptionPeriod);
                    ontracFees.push({feeUsdCents: fee, period: getPeriodStr(cu.SubscriptionPeriod)});
                }
            });
        }
        if (ontrac.SatellitePlus && ontrac.SatellitePlus.SatellitePlusUnit && ontrac.SatellitePlus.SatellitePlusUnit.length){
            ontrac.SatellitePlus.SatellitePlusUnit.forEach((spu) => {
                let fee = getSubscriptionPriceUsdCents(RadioTelemetryTypes.SatellitePlus, spu.SubscriptionPeriod);
                ontracFees.push({feeUsdCents: fee, period: getPeriodStr(spu.SubscriptionPeriod)});
            });
        }
    }

    return ontracFees;
}
//TODO
export const getOntracAnnualFeeCents = (system: ISystem) => {
    return 25000;
}

const getSubscriptionPriceUsdCents = (type: RadioTelemetryTypes, period: RadioTelemetrySubscriptionPeriods): number => {
    switch (type) {
        case RadioTelemetryTypes.Cell:
            switch (period) {
                case RadioTelemetrySubscriptionPeriods.a4Months:
                    return 10000;
                case RadioTelemetrySubscriptionPeriods.a6Months:
                    return 12500;
                case RadioTelemetrySubscriptionPeriods.a9Months:
                    return 17500;
                case RadioTelemetrySubscriptionPeriods.a12Months:
                    return 20000;
            }
        case RadioTelemetryTypes.SatellitePlus:
            switch (period) {
                case RadioTelemetrySubscriptionPeriods.a4Months:
                    return 22500;
                case RadioTelemetrySubscriptionPeriods.a6Months:
                    return 27500;
                case RadioTelemetrySubscriptionPeriods.a9Months:
                    return 31500;
                case RadioTelemetrySubscriptionPeriods.a12Months:
                    return 35500;
            }
        case RadioTelemetryTypes.Radio:
            return 0;
    }      
}

export const systemHasESP = (quote: QuoteClass) => {
    return HasSwingArmCorner(quote.System) && quote.System.Circle.SwingArm.EnergySaverPackage;
}

export const systemHasPipeType = (quote: QuoteClass, pts: SpanPipeTypes[]) => {
    if (quote.System.FlangedSide.Span.length > 0){
        quote.System.FlangedSide.Span.forEach((s) => {
            let pt = PipeType(quote.System, quote.System.FlangedSide, s);
            if (pts.indexOf(pt) !== -1) return true;
        });
    }
    if (quote.System.FlexSide.Span.length > 0){
        quote.System.FlexSide.Span.forEach((s) => {
            let pt = PipeType(quote.System, quote.System.FlexSide, s);
            if (pts.indexOf(pt) !== -1) return true;
        });
    }

    return false;
}

export const systemHasNonBasicControlPanel = (quote: QuoteClass) => {
    return quote.System.ControlPanel && quote.System.ControlPanel.PanelModel !== PanelModels.RPMBasic
}

export const systemHasHeavyDutyWheelGear = (quote: QuoteClass) => {
    return quote.System.TowerProperties && 
        (quote.System.TowerProperties.WheelGear === WheelGearSeries.SevenFourty ||
         quote.System.TowerProperties.WheelGear === WheelGearSeries.SevenFourtyAD ||
         quote.System.TowerProperties.WheelGear === WheelGearSeries.SevenFourtyFive);
}

export const customPricingApplied = (system: ISystem): boolean => {
    //used to write CUSTOM PRICING APPLIED at the top of some documents
    return false;
}

export const configurationValid = (system: ISystem): boolean => {
    //used to write INCOMPLETE PROPOSAL at the top of some documents
    //TODO: replace with watermark?
    return true;
}

export const getSystemFullLoadAmps = (quote: QuoteClass): number => {
    let ec = new ElectricalCalculator(quote.System, new PartsAssembler(quote));
    return (Math.ceil(ec.FullLoadAmps * 10) / 10);
}

export const getSystemRequiredkW = (quote: QuoteClass): number => {
    let ec = new ElectricalCalculator(quote.System, new PartsAssembler(quote));
    return (Math.ceil(ec.TotalKilowatts * 10) / 10);
}

export const getSystemRequiredGeneratorHP = (quote: QuoteClass): number => {
    //TODO: check this because kW to horse power according to google is * 1.3 but in the Reinke code line 155 of PdfElectricalReport.vb they are doing * 1.6?
    return getSystemRequiredkW(quote) * 1.3;
}

export const getSystemVoltageatPowerSource = (quote: QuoteClass): number => {
    let ec = new ElectricalCalculator(quote.System, new PartsAssembler(quote));
    return ec.SourceVoltage;
}

export const getSystemVoltageatMCP = (quote: QuoteClass): number => {
    let ec = new ElectricalCalculator(quote.System, new PartsAssembler(quote));
    return ec.SourceVoltage - ec.RemotePanelVoltageLoss;
}

export const getSystemMinimumVoltage = (quote: QuoteClass): number => {
    let ec = new ElectricalCalculator(quote.System, new PartsAssembler(quote));
    return ec.MinimumVoltage;
}

export const getSystemElectricalFrequencyHertz = (quote: QuoteClass): ElectricalFrequencies => {
    let ec = new ElectricalCalculator(quote.System, new PartsAssembler(quote));
    return ec.ElectricalFrequency;
}

export const getRemotePanelVoltageLoss = (quote: QuoteClass): number => {
    let ec = new ElectricalCalculator(quote.System, new PartsAssembler(quote));
    return ec.RemotePanelVoltageLoss;
}

export const getSystemDragCordVoltageLoss = (quote: QuoteClass): number  => {
    let ec = new ElectricalCalculator(quote.System, new PartsAssembler(quote));
    return ec.DragCordVoltageLoss;
}

export const motorTypeToString = (mt: MotorTypes): string => {
    switch (mt){
        case MotorTypes.NoMotor:
            return "No Motor";
        case MotorTypes.Low:
            return "Low";
        case MotorTypes.Standard:
            return "Standard";
        case MotorTypes.High:
            return "High";
        case MotorTypes.TwoHP:
            return "Two HP";
        case MotorTypes.FiveHP:
            return "Five HP";
        case MotorTypes.TwoThirtyVolt:
            return "230 Volt";
        case MotorTypes.A100Pump:
            return "A100 Pump";
        case MotorTypes.SACAirComp:
            return "SAC AirComp";
        case MotorTypes.VRIAirComp:
            return "VRI AirComp";
        case MotorTypes.DualSprinklerPkAirComp:
            return "Dual SpPk AirComp";
        case MotorTypes.WrapJointAirComp:
            return "WrapSpan AirComp";
    }
}

export const getElectricalReportMotorInformation = (quote: QuoteClass, side: SystemSpanSide): IElectricalMotorInformationTableRow[] => {
    let items: IElectricalMotorInformationTableRow[] = [];
    let ec = new ElectricalCalculator(quote.System, new PartsAssembler(quote));
    ec.AutoGauge();
    // TODO display error on report

    let motors = side === SystemSpanSide.flanged ? ec.FlangedSideData.Motors : ec.FlexSideData.Motors;
    let quoteSide = side === SystemSpanSide.flanged ? quote.System.FlangedSide : quote.System.FlexSide;

    for (let i = 0; i <motors.Count; i++){
        let motor = motors.getByIndex(i);
        if (motor) {
            let cable = "";
            let length = 0;
            let bb = "";
            let location = motor.Key;

            if (typeof motor.BuckBoostType !== "undefined" && motor.BuckBoostType !== BuckBoostTypes.aNone){
                bb = BuckBoostText(motor.BuckBoostType) + " B&B";
                location += ` /${bb}`;
            }

            if (motor.IsMaxConsumer) location += " /Max";

            if (motor.Span){
                let cr = ConductorCount(quote.System, quoteSide, motor.Span);
                if (cr){
                    let idxCC = CableConductors(quote.System, quoteSide, motor.Span);
                    let ecc = isNaN(motor.Span.ExtraCableConductors) ? 0 : motor.Span.ExtraCableConductors;
                    let cc: number | string = "N/A";
                    //replaces the weird idxCableConductors.ToInteger method in ExtensionMethods.Enums.vb (we don't actually want the index here!)
                    switch (idxCC){
                        case idxCableConductors.cc10:
                            cc = 10;
                            break;
                        case idxCableConductors.cc11:
                            cc = 11;
                            break;
                        case idxCableConductors.cc14:
                            cc = 14;
                            break;
                    }
                    cable = `${cc}C/${cc - cr + ecc}${strLanguageText("sltSpare")}`;
                }

                if (motor.CableGaugeHelper.Gauge > -1){
                    if (cable !== "") cable += "/";
                    cable += SpanCableText(motor.CableGaugeHelper.Gauge, false);
                    }

                    length = motor.Span.Length;
            }

            items.push({
                location,
                type: motorTypeToString(motor.MotorType),
                length,
                amps: motor.LoadAmperage,
                totalAmps: motor.CumulativeAmperage,
                kW: motor.LoadKilowatts,
                voltage: motor.VoltagePresent,
                kva: motor.KVA,
                cable
            });
        }
    }

    return items;
}

export const getPrimaryEndGunFlowGPM = (quote: QuoteClass) => {
    if (quote.SprinklerChartClass.FieldSet.DataValid() && quote.System.FlangedSide?.SprinklerChart?.IrrigationProperties?.EndGunIrrigationProperties?.GPMDelivered){
        return quote.System.FlangedSide.SprinklerChart.IrrigationProperties.EndGunIrrigationProperties.GPMDelivered;
    }
    else {
        return 0;
    }
}

export const getBoosterPumpDescription = (quote: QuoteClass) => {
    let str = "";
    let bp = quote.System?.FlangedSide?.EndOfSystem?.EndGun?.BoosterPump;
    if (bp === BoosterPumpTypes.TwoHP){
        str = "2 HP";
    }
    else if (bp === BoosterPumpTypes.FiveHP){
        str = "5 HP";
    }
    else {
        str = "None";
    }
    return str;
}

export const getEndGunPsi = (quote: QuoteClass) => {
    if (quote.SprinklerChartClass.FieldSet.DataValid()){
        return quote.System.FlangedSide.SprinklerChart.IrrigationProperties.EndGunIrrigationProperties.Pressure;
    }
}

export const getPrimaryEndGunModel = (quote: QuoteClass) => {
    return quote?.System?.FlangedSide?.EndOfSystem?.EndGun?.EndGunTypePrimary?.toString() ?? "None";
}

export const getSecondaryEndGunModel = (quote: QuoteClass) => {
    return quote?.System?.FlangedSide?.EndOfSystem?.EndGun?.EndGunTypeSecondary?.toString() ?? "None";
}

export const getEffectiveGunThrow = (quote: QuoteClass, secondary: boolean) => {
    let gunProps = secondary ? quote.System.FlangedSide.SprinklerChart.IrrigationProperties.SecondaryEndGunIrrigationProperties : 
        quote.System.FlangedSide.SprinklerChart.IrrigationProperties.EndGunIrrigationProperties;

    if (typeof gunProps !== "undefined"){
        let r = gunProps.Radius;
        if (typeof  r !== "undefined"){
            return r;
        }
    }
    return undefined;
}

const GetFactor = (endgunType: EndGunTypes, bDiffuser: boolean) => {
    switch (endgunType){
        case EndGunTypes.SR75:
        case EndGunTypes.SR100:
        case EndGunTypes.SR100NV:
        case EndGunTypes.SimeWing:
        case EndGunTypes.TwinMax:
        case EndGunTypes.Twin101Ultra:
            return 0.84;
        case EndGunTypes.SingleP85:
        case EndGunTypes.DualP85:
            return bDiffuser? 0.68 : 0.8;
        case EndGunTypes.R75:
            return 0.834;
        case EndGunTypes.R55:
        case EndGunTypes.R55i:
            return 0.83;
        case EndGunTypes.None:
            return 0;
        default:
            return 0.84;
    }
}

export const getMaxGunThrow = (quote: QuoteClass, secondary: boolean) => {
    let bDiffuser = quote.System.FlangedSide.SprinklerChart.IrrigationProperties.EndGunIrrigationProperties.DiffuserCalculated;
    let gun = secondary ? quote.System.FlangedSide.EndOfSystem.EndGun.EndGunTypeSecondary : quote.System.FlangedSide.EndOfSystem.EndGun.EndGunTypePrimary;

    let maxFactor = GetFactor(gun, bDiffuser);
    let throw_ = getEffectiveGunThrow(quote, secondary);

    if (typeof throw_ !== "undefined"){
        return throw_/maxFactor;
    }

    return undefined;
}

export const getSprinklerBrand = (quote: QuoteClass) => {
    let packages = quote.System.FlangedSide.Sprinklers.Package;
    if (typeof(packages) === "undefined" || !packages.length){
        return undefined;
    }

    let package_ = packages[0];
    return DeviceTypeText(package_.Device, package_.UseNelson3030, package_.UseNelsonAD3030MT);
}

export const getSprinklerGroundClearanceFeet = (quote: QuoteClass) => {
    let packages = quote.System.FlangedSide.Sprinklers.Package;
    if (typeof(packages) === "undefined" || !packages.length){
        return undefined;
    }
    
    let package_ = packages[0];

    let rigidType = package_.RigidDrop.DropType;
    let minimumHeight: number | undefined = undefined;


    quote.System.FlangedSide.SprinklerChart.Outlet.forEach((o) => {
        let myheight: number;

        if (o.TopOfDropHeight > 0){
            myheight = o.TopOfDropHeight - o.DeviceHeight - o.DropLength;
        }
        else {
            myheight = o.AbsoluteHeight - o.DeviceHeight;
        }
        
        if (typeof(minimumHeight) === "undefined" || isNaN(minimumHeight) || minimumHeight < 0.01){
            minimumHeight = myheight;
        }
    });

    switch (package_.Drop){
        case DropTypes.Rigid:
            return rigidType === RigidDropTypes.Variable ? package_.RigidDrop.GroundClearance : minimumHeight;
        case DropTypes.Hose:
            return package_.HoseDrop.GroundClearance;
        default:
            return minimumHeight;
    }
}

export const getSprinklerDiameterFeet = (quote: QuoteClass) => {
    if (quote.SprinklerChartClass.FieldSet.DataValid() && quote.SprinklerConfigClass.FieldSet.DataValid()){
        let gpm1 = 0;
        let gpm2 = 0;

        let maxDiameter = 0;
        let lastTowerDistanceFeet = getLastTowerDistanceFeet(quote);

        quote.System.FlangedSide.SprinklerChart.Outlet.forEach((o) => {
            if (o.GPMDelivered > 0){
                let outletLocation = o.Location;
                gpm1 = o.GPMDelivered;

                if (outletLocation >= (lastTowerDistanceFeet - 100) && outletLocation <= lastTowerDistanceFeet){
                    if (gpm1 >= gpm2){
                        gpm2 = gpm1;
                        maxDiameter = DeviceWateringDiameter(HasSwingArmCorner(quote.System), quote.System.FlangedSide.SprinklerChart.Outlet.length, o);
                    }
                }
            }
        });

        return maxDiameter;
    }

    return undefined;
}

export const getIntensityApplicationRatePerHourInches = (quote: QuoteClass) => {
    if (quote.SprinklerChartClass.FieldSet.DataValid() && quote.SprinklerConfigClass.FieldSet.DataValid()){
        let intensity: number | undefined = undefined;
        let maxDiameterFeet = getSprinklerDiameterFeet(quote);
        if (typeof maxDiameterFeet !== "undefined"){
            let gpm1 = 0;
            let gpm2 = 0;

            let lastTowerDistanceFeet = getLastTowerDistanceFeet(quote);
            let gpm = getSystemGPM(quote);

            if (typeof gpm !== "undefined"){
                let totalIrrigatedAcres = getSystemCoverageAcres(quote);

                let spans = quote.System.FlangedSide.Span;
                if (totalIrrigatedAcres && spans && spans.length > 0){
                    gpm = gpm/totalIrrigatedAcres;
                    let lastspanDistance = EndingLocation(quote.System, quote.System.FlangedSide, spans[spans.length - 1]);

                    quote.System.FlangedSide.SprinklerChart.Outlet.forEach((o) => {
                        if (o.GPMDelivered > 0){
                            let outletLocation = o.Location;
                            gpm1 = o.GPMDelivered;

                            if (outletLocation >= (lastTowerDistanceFeet - 100) && outletLocation <= lastTowerDistanceFeet){
                                if (gpm1 >= gpm2){
                                    gpm2 = gpm1;
                                    intensity = (gpm * lastspanDistance)/ (72 * (maxDiameterFeet as number));
                                }
                            }
                        }
                    });
                }
            }
        }

        if (typeof intensity !== "undefined"){
            return intensity;
        }
    }

    return undefined;
}

export const getSystemCoverageAcres = (quote: QuoteClass) => {
    if (quote.SprinklerChartClass.FieldSet.DataValid() && quote.SprinklerConfigClass.FieldSet.DataValid()){
        let ip = quote.System.FlangedSide.SprinklerChart.IrrigationProperties;
        let radius: number | undefined = undefined;

        if (ip){
            if (ip.CoverageRadiusWithEndGun > 0){
                radius = ip.CoverageRadiusWithEndGun;
            }
            else {
                radius = ip.CoverageRadiusWithoutEndGun;
            }
        }

        if (radius){
            return (Math.PI * Math.pow(radius, 2)) / 43560;
        }
    }
    
    return null;
}

export const getValuesFromFieldDesign = (quote: QuoteClass, systemId: string, layoutId: string, project: IProject) => {
    let valuesFromFieldDesign: IValuesFromFieldDesign;
    if ((quote.System as ISystem).lateral || (quote.System as ISystem).centerPivot){
        let helper = new IrrigatedAreaHelper({project, layoutId}).getSystem(systemId);
        let endGunAcres: number = 0;
        let systemAcres: number;

        if ((quote.System as ISystem).lateral){
            helper.lateralInfo.flangedEndGunInfo.forEach(egi => {
                egi.areas.forEach(a => endGunAcres += a);
            });
            helper.lateralInfo.flexEndGunInfo.forEach(egi => {
                egi.areas.forEach(a => endGunAcres += a);
            });

            systemAcres = helper.lateralInfo.flangedArea + helper.lateralInfo.flexArea;
        }
        else {
            helper.centerPivotInfo.endGunInfo.forEach((eg) => {
                eg.areas.forEach((a) => {
                    endGunAcres += a;
                });
            });

            systemAcres = helper.centerPivotInfo.pivotAreaAcres;
        }

        valuesFromFieldDesign = {
            irrigatedAcres: helper.irrigatedArea - endGunAcres,
            irrigatedAcresFullEndGun: helper.irrigatedArea,
            systemAcres,
            endGunAcres
          };
    }

    return valuesFromFieldDesign;
}

export const getSystemHoursToApplyOneInch = (quote: QuoteClass, valuesFromFieldDesign: IValuesFromFieldDesign) => {
    if (quote.System.SprinklerProperties && typeof quote.System.SprinklerProperties.MinimumSystemGPM !== "undefined"){
        const acres = new Acres(quote.System, valuesFromFieldDesign);
        return acres.HoursToApplyAnInch(quote.System.SprinklerProperties.TotalGPM);
    }

    return undefined;
}

export const getIrrigationRatePerDayInches = (quote: QuoteClass, valuesFromFieldDesign: IValuesFromFieldDesign) => {
    let inchHours = getSystemHoursToApplyOneInch(quote, valuesFromFieldDesign);
    if (typeof inchHours !== "undefined"){
        return (24 / getSystemHoursToApplyOneInch(quote, valuesFromFieldDesign));
    }
    return undefined;
}

export const getIrrigationRatePerMonthInches = (quote: QuoteClass, valuesFromFieldDesign: IValuesFromFieldDesign) => {
    let inchPerDay = getIrrigationRatePerDayInches(quote, valuesFromFieldDesign);
    if (typeof inchPerDay !== "undefined"){
        return getIrrigationRatePerDayInches(quote, valuesFromFieldDesign) * 30.437;
    }
    return undefined;
}

export const getOverallTireSize = (quote: QuoteClass) => {
    let tiresType = "";
    if (quote.RightTowersClass.FieldSet.DataValid()){
        tiresType = quote.RightTowersClass.TiresType(tiresType);
    }

    if (quote.LeftTowersClass.FieldSet.DataValid()){
        tiresType = quote.LeftTowersClass.TiresType(tiresType);
    }

    if (tiresType === ""){
        tiresType = "N/A";
    }

    return tiresType;
}

export const getLastTowerDistanceFeet = (quote: QuoteClass) => {
    let spans = quote.System?.FlangedSide?.Span ?? [];
    if (spans.length > 0){
        return EndingLocation(quote.System, quote.System.FlangedSide, spans[spans.length - 1]);
    }

    return 0;
}

export const getCenterDriveType = (quote: QuoteClass) => {
    let driveType = "";
    if (quote.RightTowersClass.FieldSet.DataValid()){
        driveType = quote.RightTowersClass.CenterDriveType(driveType);
    }

    if (quote.LeftTowersClass.FieldSet.DataValid()){
        driveType = quote.LeftTowersClass.CenterDriveType(driveType);
    }

    if (driveType === ""){
        driveType = "N/A";
    }
    
    return driveType;
}

export const getTowerSpeedFeetPerMinute = (quote: QuoteClass) => {
    let towers = quote.System.FlangedSide.Tower;
    if (towers && towers.length > 0 && FieldSets(quote.System).ControlPanel.DataValid()){
        let lastTower = towers[towers.length - 1];

        let circumference: number | undefined = undefined;

        switch (lastTower.Tires.TireSize){
            case TireSizes.a11x225:
                circumference = 137.25;
                break;
            case TireSizes.a112x24:
                circumference = 129.0;
                break;
            case TireSizes.a112x38:
                circumference = 172.0;
                break;
            case TireSizes.a149x24:
                circumference = 148.0;
                break;
            case TireSizes.a169x24:
                circumference = 155.0;
                break;
            case TireSizes.a136x38:
                circumference = 183.0;
                break;
            case TireSizes.a32085R38:
                circumference = 177.7;
                break;
        }

        let freq = quote.System.ControlPanel.ElectricalFrequency;
        let wheelGearRatio: number | undefined;

        switch (lastTower.CenterDrive){
            case GearDriveTypes.Standard:
                wheelGearRatio = 40;
                break;
            case GearDriveTypes.High:
                wheelGearRatio = 25.5;
                break;
            case GearDriveTypes.Low:
                wheelGearRatio = 60;
                break;
        }

        if (typeof(circumference) === "undefined" || !freq || typeof(wheelGearRatio) === "undefined"){
            return undefined;
        }

        let rpm: number;
        if (freq === ElectricalFrequencies.a60){
            rpm = (1/50) * (1/wheelGearRatio) * 1745;
        }
        else {
            rpm = (1/50) * (1/wheelGearRatio) * 1425;
        }

        return (circumference * rpm)/12;
    }

    return undefined;
}

export const getDegreeOfSweep = (quote: QuoteClass) => {
    if (isCenterPivot(quote.System)){
        return DegreeOfSweep(quote.System);
    }
    return 0;
}

export { isCenterPivot };

export const isCenterPivotNotKwikTow = (sys: ISystemBase) => {
    let st = sys.SystemProperties.SystemType;
    return st === SystemTypes.CenterPivot || st === SystemTypes.SwingArmRetro;
}

export const getPercentOfCircleCoverage = (quote: QuoteClass) => {
    if (isCenterPivotNotKwikTow(quote.System)){
        return PercentOfCircle(quote.System);
    }
    else if (quote.System.SystemProperties.SystemType === SystemTypes.KwikTow){
        return quote.System.Circle.KwikTow.PercentOfCircle;
    }

    return 0;
}

export const getSystemAreaAcres = (quote: QuoteClass, valuesFromFieldDesign: IValuesFromFieldDesign): number => {
    let acres = new Acres(quote.System, valuesFromFieldDesign);
    return acres.SystemAcres;
}

export const getSystemSACAcres = (quote: QuoteClass, valuesFromFieldDesign: IValuesFromFieldDesign) => {
    let acres = new Acres(quote.System, valuesFromFieldDesign);
    return acres.IrrigatedAcresFullEndGun - acres.EndGunAcres - acres.SystemAcres;
}

export const getSystemEndGunAcres = (quote: QuoteClass, valuesFromFieldDesign: IValuesFromFieldDesign) => {
    let acres = new Acres(quote.System, valuesFromFieldDesign);
    return acres.EndGunAcres;
}

export const getSystemIrrigatedAcres = (quote: QuoteClass, valuesFromFieldDesign: IValuesFromFieldDesign) => {
    let acres = new Acres(quote.System, valuesFromFieldDesign);
    return acres.IrrigatedAcresFullEndGun;
}

export const getEndGunPlusSACAreaAcres = (quote: QuoteClass, valuesFromFieldDesign: IValuesFromFieldDesign) => {
    let acres = new Acres(quote.System, valuesFromFieldDesign);
    return acres.IrrigatedAcresFullEndGun - acres.SystemAcres;
}