import { AlertColor } from "@mui/material";
import { t } from "i18next";
import { BoomBackDevices, D3000Plates, DeviceTypes, DeviceWeights, DropTypes, FittingTypes, HoseClamps, HoseDropWeights, HoseTopFittings, IPackage, LDNChemPadTypes, LDNPadTypes, LDNTypes, NozzleClipTypes, OutletValveTypes, RegulatorLocations, RegulatorPressures, RegulatorTypes, RigidDropBottomFittings, RigidDropMaterialTypes, RigidDropTypes, SuperSprayPadTypes, ThreadTypes, UPipeFittings, UPipeMaterialTypes, UPipeReaches, UPipeTypes, WeightBottomFittings, WeightTopFittings, idxSprinkler, idxWobbler, idxXiWob } from "rdptypes/project/ISprinklers";
import { IFlangedSide, IFlexSide, ISprinklerProperties, ISystemBase, PressureGaugeTypes, SpanTypes, SprinklerManufacturerTypes } from "rdptypes/project/ISystemBase.AutoGenerated";
import { DeterminePackageTypeFromDeviceType } from "roedata/roe_migration/OtherHelpers";
import * as sidef from "roedata/roe_migration/SideFunctions";
import { EndingLocation, StartingLocation } from "roedata/roe_migration/SpanFunctions";
import { WarningSeverity, WarningTypes } from "roedata/roe_migration/SprinklerEngineTypes/Enums";
import { HasSprinklerPolyValve, IsMaxigator } from "roedata/roe_migration/SystemFunctions";
import { SprinklerKometDropsOptionOk, SprinklerNelsonDropsOptionOK, SprinklerSenningerDropsOptionOK } from "roedata/roe_migration/Valids.Valids";
import { idxRSNPlates } from "roedata/roe_migration/Valids.dto.Valids";
import { sprinklersPrefix } from "..";
import { feetToMeters } from "../../../../../docGeneration/DocumentGenerationHelpers";
import { DisplayPressureUnitBuilder } from "../../../../../helpers/pressures";
import { IDisplaySettingsFormState } from "../../../../DealerSettingsDialog/TabDisplaySettings";
import { DetermineSprinklerDeviceType, DetermineSprinklerExtrasFromPackage } from "./Rules";

export function getAllEnumValues(array: Array<any>): Array<number> {
    var filtered  = array.filter((item) => {
        return !isNaN(Number(item));
    });
    return filtered.map(f => Number(f));
}

export function isSprinklerCalcAvailable(sprinklerProperties: ISprinklerProperties, sprinklerPackages: IPackage[]): boolean{
    //Checks if all necessary fields are filled in before Calculate button can be pressed to avoid errors
    if(!sprinklerProperties || !sprinklerProperties.SprinklerEngineCode || sprinklerProperties.SprinklerEngineCode === SprinklerManufacturerTypes.None ||
        !sprinklerProperties.PressureGauge || sprinklerProperties.TotalGPM === null || sprinklerProperties.TotalGPM === undefined ||
        sprinklerProperties.DesignedEndPressure === null || sprinklerProperties.DesignedEndPressure === undefined || sprinklerProperties.PeakElevation === null || 
        sprinklerProperties.PeakElevation === undefined){
        return false;
    }
    sprinklerPackages.forEach(p => {
        if(DeterminePackageTypeFromDeviceType(p) === null){
            return false;
        }
    });

    return true;
}

export function getWarningSeverityType(severity: WarningSeverity): AlertColor  {
    switch(severity){
        case WarningSeverity.Critical:
            return "error";
        case WarningSeverity.Information:
            return "info";
        case WarningSeverity.UserStop:
            return "warning";
    }
}

export function getRegulatorFittingTypeLabel(type: FittingTypes, manufacturer: SprinklerManufacturerTypes){
    switch(type){
        case FittingTypes.None:
            return t("none");
        case FittingTypes.Close:
            switch (manufacturer) {
                case SprinklerManufacturerTypes.Nelson:
                    return "3/4\" Close (Nelson HD Black Plastic)";
                default:
                    return "3/4\" Close (Senninger Black Plastic)";
            }
        case FittingTypes.Galvanized:
            return "3/4\" x 2 1/2\" (Galvanized)";
        case FittingTypes.StainlessSteel:
            return "3/4\" x 2 1/2\" (Stainless Steel)";
        default:
            throw new Error("Unsupported");
    }
}

export function getRSNPlatesLabel(type: idxRSNPlates): string{
    switch(type){
        case idxRSNPlates.rsnU4Blue:
            return "U4 - Blue";
        case  idxRSNPlates.rsnU6White:
            return "U6 - White";
        case  idxRSNPlates.rsnD4Green:
            return "D4 - Green";
        case  idxRSNPlates.rsnD6Red:
            return "D6 - Red";
        case  idxRSNPlates.rsnD6Purple:
            return "D6 - Purple";
        case  idxRSNPlates.rsnD8Yellow:
            return "D8 - Yellow";
        case  idxRSNPlates.rsnD8Orange:
            return "D8 - Orange";
        case  idxRSNPlates.rsnD10Brown:
            return "D10 - Brown";
        case  idxRSNPlates.rsnD10Olive:
            return "D10 - Olive";
        case  idxRSNPlates.rsnD4Maroon:
            return "D4 - Maroon";
        case  idxRSNPlates.rsnD6Gold:
            return "D6 - Gold";
        case  idxRSNPlates.rsnU6Navy:
            return "U6 - Navy";
        case  idxRSNPlates.rsn9GBlack:
            return "9G - Black";
        case  idxRSNPlates.rsn9GBlue:
            return "9G - Blue";
        case  idxRSNPlates.rsn18GPurple:
            return "18G - Purple";
        case  idxRSNPlates.rsn9GWhite:
            return "9G - White";
        default: return "";
    }
}

export function getWarningType(severity: WarningTypes): string  {
    switch(severity){
        case WarningTypes.InvalidData:
            return "Invalid Data";
        case WarningTypes.NozzleCritical:
            return "Nozzle Critical";
        case WarningTypes.NozzlePressureExceeded:
            return "Nozzle Pressure Exceeded";
        case WarningTypes.NozzlePressureNotMet:
            return "Nozzle Pressure Not Met";
        case WarningTypes.OutofWater:
            return "Out of Water";
        case WarningTypes.NozzleSpacingNotMet:
            return "Nozzle Spacing Not Met";
        case WarningTypes.SprinklerCriticalError:
            return "Sprinkler Critical Error";
        case WarningTypes.SprinklerRegularError:
            return "Sprinkler Regular Error";
        case WarningTypes.SystemParams:
            return "System Parameter Error";
        case WarningTypes.UserStop:
            return "User Stopped";
      
    }
}

export function getBoomBackDeviceName(type: BoomBackDevices): string{
    switch(type){
        case BoomBackDevices.D3000:
            return t(sprinklersPrefix + "boomback-device-type.D3000");
        case BoomBackDevices.S3000:
            return t(sprinklersPrefix + "boomback-device-type.S3000");
        case BoomBackDevices.R3000:
            return t(sprinklersPrefix + "boomback-device-type.R3000");
        case BoomBackDevices.LDN:
            return t(sprinklersPrefix + "boomback-device-type.LDN");
        case BoomBackDevices.Fan:
            return t(sprinklersPrefix + "boomback-device-type.Fan");
        case BoomBackDevices.Komet:
            return t(sprinklersPrefix + "boomback-device-type.Komet");
        case BoomBackDevices.KometTwisterPartCircle:
            return t(sprinklersPrefix + "boomback-device-type.KometTwisterPartCircle");
            default: return "";
    }
}

export function getPressureGaugeType(type: PressureGaugeTypes, settings: IDisplaySettingsFormState){
    let psi: number;

    switch(type){
        case PressureGaugeTypes.a100PSI:
            psi = 100;
            break;
        case PressureGaugeTypes.a200PSI:
            psi = 200;
            break;
        case PressureGaugeTypes.a30PSI:
            psi = 30;
            break;
        case PressureGaugeTypes.a60PSI:
            psi = 60;
            break;
    }

    return  new DisplayPressureUnitBuilder(psi, 'psi')
        .convert(settings.pressures)
        .appendValue(settings.pressures === "psi" ? 0 : 2)
        .appendString(" ")
        .appendShortName()
        .toString();
}

export function getDropTypeLabel(type: DropTypes): string{
    switch(type){
        case DropTypes.Rigid:
            return t(sprinklersPrefix + "package.drop-types.Rigid");
        case DropTypes.Hose:
            return t(sprinklersPrefix + "package.drop-types.Hose");
        case DropTypes.None:
            return t("none");
    }
}

export function getNozzleClipTypeLabel(type: NozzleClipTypes): string{
    switch(type){
        case NozzleClipTypes.Dual:
            return t(sprinklersPrefix + "package.nozzle-clip-types.Dual");
        case NozzleClipTypes.Triple:
            return  t(sprinklersPrefix + "package.nozzle-clip-types.Triple");
        case NozzleClipTypes.None:
            return  t("none");
    }
}

export function getDeviceWeightTypeLabel(type: DeviceWeights){
    switch(type){
        case DeviceWeights.None:
            return t("none");
        case DeviceWeights.Magnum:
            return "Magnum";
        case DeviceWeights.OnePound:
            return "1 lb";
    }
}

export function getValveTypeName(type: OutletValveTypes, settings: IDisplaySettingsFormState): string{
    var prefix = sprinklersPrefix + "package.valve-types.";
    let s = settings.minorLengths === "inches" ? `3/4\"` : `19.1mm`;
    switch(type){
        case OutletValveTypes.Brass:
            return  s + " " + t(prefix + "Brass");
        case OutletValveTypes.PlasticLever:
            return  s + " " + t(prefix + "PlasticLever");
        case OutletValveTypes.PlasticKnob:
            return  s + " " + t(prefix + "PlasticKnob");
        case OutletValveTypes.None:
            return  t("none");
    }
}

export function getDropBottomFittingLabel(type: RigidDropBottomFittings, settings: IDisplaySettingsFormState){
    const prefix = sprinklersPrefix + "package.drop-bottom-fittings.";
    let s = settings.minorLengths === "inches" ? `3/4\"` : `19.1mm`;

    switch(type){
        case RigidDropBottomFittings.Gray:
            return s + " " + t(prefix + "Gray");
        case RigidDropBottomFittings.Black:
            return s + " " + t(prefix + "Black");
        case RigidDropBottomFittings.Galvanized:
            return s + " " + t(prefix + "Galvanized");
    }
}

export function getUPipeMaterialTypeLabel(type: UPipeMaterialTypes){
    const prefix = sprinklersPrefix +  "package.u-pipe-materials.";
    switch(type){
        case UPipeMaterialTypes.Galvanized:
            return t(prefix + "Galvanized");
        case UPipeMaterialTypes.PVC:
            return t(prefix + "PVC");
        case UPipeMaterialTypes.BlackPlastic:
            return t(prefix + "BlackPlastic");
        case UPipeMaterialTypes.Nelson180:
            return t(prefix + "Nelson180");
    }
}

export function getRigidDropMaterialTypeLabel(type: RigidDropMaterialTypes){
    const prefix = sprinklersPrefix + "package.rigid-drop-type-materials."
    switch(type){
        case RigidDropMaterialTypes.Galvanized:
            return t(prefix + "Galvanized");
        case RigidDropMaterialTypes.PVC:
            return  t(prefix + "PVC");
        case RigidDropMaterialTypes.ComboPVCHose:
            return  t(prefix + "ComboPVCHose");
        case RigidDropMaterialTypes.Poly:
            return  t(prefix + "Poly");
        case RigidDropMaterialTypes.Combo:
            return  t(prefix + "Combo");
    }
}

export function getHoseTopFittingLabel(type: HoseTopFittings){
    switch(type){
        case HoseTopFittings.None:
            return t("none");
        case HoseTopFittings.FxHBBlack:
            return t(sprinklersPrefix + "package.hose-top-fittings.FxHBBlack");
        case HoseTopFittings.MxHBGray:
            return t(sprinklersPrefix + "package.hose-top-fittings.MxHBGray");
        case HoseTopFittings.MxHBBlack:
            return t(sprinklersPrefix + "package.hose-top-fittings.MxHBBlack");
    }
}

export function getHoseClampTypeLabel(type: HoseClamps){
    switch(type){
        case HoseClamps.Pinch:
            return t(sprinklersPrefix + "package.clamp-types.Pinch");
        case HoseClamps.Screw:
            return t(sprinklersPrefix + "package.clamp-types.Screw");
    }
}

export function getUPipeFittingTypeLabel(type: UPipeFittings){
    switch(type){
        case UPipeFittings.MxF:
            return t(sprinklersPrefix + "package.u-pipe-fitting-types.MxF");
        case UPipeFittings.MxHB:
            return t(sprinklersPrefix + "package.u-pipe-fitting-types.MxHB");
        case UPipeFittings.MxM:
            return t(sprinklersPrefix + "package.u-pipe-fitting-types.MxM");
    }
}

export function getUPipeTypeLabel(type: UPipeTypes){
    const prefix = sprinklersPrefix + "package.u-pipe-types.";
    switch(type){
        case UPipeTypes.Galv:
            return "Galvanized";
        case UPipeTypes.PVC:
            return "PVC";
        case UPipeTypes.Alum:
            return t(prefix + "Alum");
        case UPipeTypes.Nelson180:
            return t(prefix + "Nelson180");
        case UPipeTypes.BlackPlasticSingle180:
            return t(prefix + "BlackPlasticSingle180");
        case UPipeTypes.BlackPlasticSingle125:
            return t(prefix + "BlackPlasticSingle125");
        case UPipeTypes.BlackPlasticDouble125:
            return t(prefix + "BlackPlasticDouble125");
    }
}

export function getUPipeReachLabel(type: UPipeReaches, settings: IDisplaySettingsFormState){
    switch(type){
        case UPipeReaches.Standard:
            return t("standard");
        case UPipeReaches.a12Inch:
            return settings.minorLengths === "inches" ? "12\"" : "30.48cm";
        case UPipeReaches.a20Inch:
            return settings.minorLengths === "inches" ? "20\"" : "50.8cm";
    }
}

export function getRegulatorLocationLabel(type: RegulatorLocations){
    switch(type){
        case RegulatorLocations.Bottom:
            return t(sprinklersPrefix + "package.regulator-locations.Bottom");
        case RegulatorLocations.Top:
            return t(sprinklersPrefix + "package.regulator-locations.Top");
    }
}

export function getHoseDropWeightTypeLabel(type: HoseDropWeights, settings: IDisplaySettingsFormState){
    switch(type){
        case HoseDropWeights.None:
            return t("none");
        case HoseDropWeights.SlimMxHB:
            return "Slim Male by Hose Barb";
        case HoseDropWeights.MxM18Galv:
            return settings.minorLengths === "inches" ? "3/4\" Male by Male 18\" Galvanized" : "19.1mm Male by Male 45.72cm Galvanized";
        case HoseDropWeights.MxHB18Galv:
            return settings.minorLengths === "inches" ? "3/4\" Male by Hose Barb 18\" Galvanized" : "19.1mm Male by Hose Barb 45.72cm Galvanized";
        case HoseDropWeights.NelsonCoil:
            return "Nelson Coil Male by Hose Barb";
        case HoseDropWeights.SlipOver30Poly:
            return t(sprinklersPrefix + (settings.minorLengths === "inches" ? "package.drop-weight-types.SlipOver30Poly" : "package.drop-weight-types.SlipOver30Poly-m"));
        case HoseDropWeights.FxF24Poly:
            return t(sprinklersPrefix + (settings.minorLengths === "inches" ? "package.drop-weight-types.FxF24Poly" : "package.drop-weight-types.FxF24Poly-m"));
    }
}

export function getWeightFittingTypeLabel(type: WeightBottomFittings | WeightTopFittings){
    const prefix = sprinklersPrefix + "package.weight-fitting-types."
    switch(type){
        case WeightBottomFittings.None:
        case WeightTopFittings.None:
            return t("none");
        case WeightBottomFittings.FxHBBlack:
        case WeightTopFittings.FxHBBlack:
            return t(prefix +  "FxHBBlack");
        case WeightBottomFittings.MxHBGray:
        case WeightTopFittings.MxHBGray:
            return t(prefix +  "MxHBGray");
        case WeightBottomFittings.MxHBBlack:
        case WeightTopFittings.MxHBBlack:
            return t(prefix +  "MxHBBlack");
        case WeightBottomFittings.CloseGray:
            return t(prefix +  "CloseGray");
        case WeightBottomFittings.CloseBlack:
            return t(prefix +  "CloseBlack");
        case WeightBottomFittings.CloseGalv:
            return t(prefix +  "CloseGalv");
        case WeightBottomFittings.CouplingGray:
            return t(prefix +  "CouplingGray");
        case WeightBottomFittings.CouplingBlack:
            return t(prefix +  "CouplingBlack");
        case WeightBottomFittings.CouplingGalv:
            return t(prefix +  "CouplingGalv");
    }
}

export function getHoseDropDeviceWeightType(type: DeviceWeights){
    switch(type){
        case DeviceWeights.None:
            return t("none");
        case DeviceWeights.OnePound:
            return "1 lb Modular or One Weight";
        case DeviceWeights.Magnum:
            return "Magnum";
    }
}

export function getRegulatorPressureLabel(type: RegulatorPressures, settings: IDisplaySettingsFormState){

    let psi: number;

    switch(type){
        case RegulatorPressures.a10:
            psi = 10;
            break;
        case RegulatorPressures.a15:
            psi = 15;
            break;
        case RegulatorPressures.a20:
            psi = 20;
            break;
        case RegulatorPressures.a25:
            psi = 25;
            break;
        case RegulatorPressures.a30:
            psi = 30;
            break;
        case RegulatorPressures.a40:
            psi = 40;
            break;
        case RegulatorPressures.a6:
            psi = 6;
            break;
    }

    return  new DisplayPressureUnitBuilder(psi, 'psi')
        .convert(settings.pressures)
        .appendValue(settings.pressures === "psi" ? 0 : 2)
        .appendString(" ")
        .appendShortName()
        .toString();
}

export const getThreadTypesLabel = (type: ThreadTypes): string => {
    switch(type){
        case ThreadTypes.Female:
            return t(sprinklersPrefix + "package.thread-types.female");
        case ThreadTypes.Square:
            return t(sprinklersPrefix + "package.thread-types.square");
    }
}

export const getLDNTypeLabel = (type: LDNTypes): string => {
    switch(type){
        case LDNTypes.MultiPad:
            return "Multi Pad";
        case LDNTypes.SinglePad:
            return "Single Pad";
        default: return "";
    }
}

export const getLDNPadTypeLabel = (type: LDNPadTypes): string => {
    switch(type){
        case LDNPadTypes.Blue:
            return "Concave - Blue";
        case LDNPadTypes.Black:
            return "Flat - Black";
        case LDNPadTypes.Black24Deep:
            return "Flat - Black 24 Deep Groove";
        case LDNPadTypes.White:
            return "Cotton - White";
        default: return "";
    }
}

export const getLDNChemPadTypeLabel = (type: LDNChemPadTypes): string => {
    switch(type){
        case LDNChemPadTypes.None:
            return "None";
        case LDNChemPadTypes.Red:
            return "Com - Red";
        case LDNChemPadTypes.White:
            return "Cotton - White";
        case LDNChemPadTypes.Beige:
            return "Bubbler";
        
        default: return "";
    }
}

export const getIWobTypeLabel = (type: idxWobbler): string => {
    switch(type){
        case idxWobbler.wob9GBlack:
            return "9-G Black Std Angle";
        case idxWobbler.wob6GGray:
            return "6-G Gray Std Angle";
        case idxWobbler.wob9GBlue:
            return "9-G Blue Low Angle";
        case idxWobbler.wob6GWhite:
            return "6-G White Low Angle";
    }
}

export const getXiWobTypeLabel = (type: idxXiWob): string => {
    switch(type){
        case idxXiWob.xiWobBlack:
            return "615 Black, Med Droplet";
        case idxXiWob.xiwobBlue:
            return "615 Blue, Med Droplet";
        case idxXiWob.xiwobGray:
            return "910 Gray, Small Droplet";
        case idxXiWob.xiwobWhite:
            return "";
    }
}

export const getRigidDropTypeLabel = (type: RigidDropTypes, t: (s: string) => string): string => {
    switch(type){
        case RigidDropTypes.Fixed:
            return t(sprinklersPrefix + "package.drop-length-types.Fixed");
        case RigidDropTypes.Variable:
            return  t(sprinklersPrefix + "package.drop-length-types.Variable");
    }
    return "";
}

export const getSuperSprayLabel = (type: SuperSprayPadTypes): string => {
    switch(type){
        case SuperSprayPadTypes.CottonChemWhite:
            return "Cotton - Chem - White";
        case SuperSprayPadTypes.Concave36DeepBlue:
            return "Concave - 36 Deep - Blue";
        case SuperSprayPadTypes.Concave36MedBlue:
            return "Concave - 36 Med - Blue";
        case SuperSprayPadTypes.Flat24DeepBlack:
            return "Flat - 24 Deep - Black";
        case SuperSprayPadTypes.Flat36DeepBlack:
            return "Flat - 36 Deep - Black";
        case SuperSprayPadTypes.Flat36MedBlack:
            return "Flat - 36 Med - Black";
        case SuperSprayPadTypes.FlatSmoothBlack:
            return "Flat - Smooth - Black";
        default: 
            return "";
    }
}

export function getRegulatorTypeOptions(type: RegulatorTypes, t: (s: string) => string){
    const prefix = sprinklersPrefix + "package.regulator-types.";
    switch(type){
        case RegulatorTypes.KometKPR:
            return t(prefix + "KometKPR");
        case RegulatorTypes.NelsonBlueFTHF:
            return t(prefix + "NelsonBlueFTHF");
        case RegulatorTypes.NelsonBlueFTLF:
            return  t(prefix + "NelsonBlueFTLF");
        case RegulatorTypes.NelsonBlueLFHF:
            return  t(prefix + "NelsonBlueLFHF");
        case RegulatorTypes.NelsonBlueSTHF:
            return  t(prefix + "NelsonBlueSTHF");
        case RegulatorTypes.NelsonBlueSTLF:
            return  t(prefix + "NelsonBlueSTLF");
        case RegulatorTypes.None:
            return  t("none");
        case RegulatorTypes.SenningerLF:
            return  t(prefix + "SenningerLF");
        case RegulatorTypes.SenningerLFMF:
            return  t(prefix + "SenningerLFMF");
        case RegulatorTypes.SenningerMF:
            return  t(prefix + "SenningerMF");
        case RegulatorTypes.SenningerPSR:
            return  t(prefix + "SenningerPSR");
    }
}

export function getSpecificPackageTypeKeys(SprinklerManufacturerType: SprinklerManufacturerTypes): idxSprinkler[]{
    
    switch(SprinklerManufacturerType){
        case SprinklerManufacturerTypes.Nelson:
            return [idxSprinkler.NelsonR3000Rotator, idxSprinkler.NelsonS3000Spinner, idxSprinkler.NelsonO3000Orbitor, idxSprinkler.NelsonO3030OrbitorFX,
                idxSprinkler.NelsonA3000Accelerator, idxSprinkler.NelsonD3000Spray];
        case SprinklerManufacturerTypes.Komet:
            return [
                idxSprinkler.sprKometTwisterWhite, idxSprinkler.sprKometTwisterBlack, idxSprinkler.sprKometTwisterBlue, idxSprinkler.sprKometTwisterYellow, idxSprinkler.sprKometSprayGrey,
                idxSprinkler.sprKometSprayBlue, idxSprinkler.sprKometSprayBlack, idxSprinkler.sprKometSprayYellow
            ];
        case SprinklerManufacturerTypes.Senninger:
            return [
                idxSprinkler.sprSenningerConstantSpacingPlasticImpact, idxSprinkler.sprSenningerVariableSpacingPlasticImpactWhite, idxSprinkler.sprSenningerVariableSpacingPlasticImpactRed,
                idxSprinkler.sprSenningerIWob, idxSprinkler.sprSenningerXcelWobbler, idxSprinkler.sprSenningerXiwob, idxSprinkler.sprSenningerSuperSpray, idxSprinkler.sprSenningerLDNSpray,
                idxSprinkler.sprSenningerQuadSpray
            ];
    }

}

export function  SecondNozzleGPMOptionValid(sys: ISystemBase, pck: IPackage): boolean{
    const SecondNozzleGPMMax = sys.SprinklerProperties.TotalGPM - 1;
    if( pck.SecondNozzleGPM > SecondNozzleGPMMax){
        return false;
    }
    if(pck.NozzleClip !== NozzleClipTypes.None){
        if(pck.SecondNozzleGPM < 1){
            return false
        }
    }

    return true;
}

export function  ThirdNozzleGPMOptionValid(sys: ISystemBase, pck: IPackage): boolean{
    const ThirdNozzleGPMMax = pck.SecondNozzleGPM - 1;
    if( pck.ThirdNozzleGPM > ThirdNozzleGPMMax){
        return false;
    }
    if(pck.NozzleClip === NozzleClipTypes.Triple){
        if(pck.ThirdNozzleGPM < 1){
            return false
        }
    }

    return true;
}

export function getAvailableDropTypes(system: ISystemBase, pck: IPackage, side: IFlangedSide | IFlexSide){
    const packageType = DeterminePackageTypeFromDeviceType(pck);
    if (!packageType) return [DropTypes.None, DropTypes.Hose, DropTypes.Rigid];
    var AnyPolyLinedSpans = false;

    var startStop = DetermineStartAndStopSpans(pck, system, side);
    for(let i = startStop.iStart; i<=startStop.iEnd; i++){
        if(side.Span[i-1].SpanType === SpanTypes.PL2065G || side.Span[i-1].SpanType === SpanTypes.PL2085G){
            AnyPolyLinedSpans = true;
        }
    }
    var dropOptions: DropTypes[] = [];
    switch(system.SprinklerProperties.SprinklerEngineCode){     
        case SprinklerManufacturerTypes.Nelson:
            [DropTypes.None, DropTypes.Hose, DropTypes.Rigid].forEach(dropType => {
                if(SprinklerNelsonDropsOptionOK(dropType, {
                    SelectedSprinkler: packageType,
                    RegulatorType: pck.Regulator.RegulatorType,
                    RegulatorPressure: pck.Regulator.Pressure,
                    UseNelson3030: pck.UseNelson3030,
                    UseNelsonAD3030MT: pck.UseNelsonAD3030MT,
                    AnyPolyLinedSpans: AnyPolyLinedSpans,
                    NozzleClipType: pck.NozzleClip,
                    HasSprinklerPolyValve: HasSprinklerPolyValve(system),
                })){
                    dropOptions.push(dropType);
                }            
            });
            break;
        case SprinklerManufacturerTypes.Senninger:
                [DropTypes.None, DropTypes.Hose, DropTypes.Rigid].forEach(dropType => {
                    if(SprinklerSenningerDropsOptionOK(dropType, {
                        SelectedSprinkler: packageType,
                        IsAnyPolyLinedSpans: AnyPolyLinedSpans,
                        RegulatorPressure: pck.Regulator.Pressure,
                        NozzleClipType: pck.NozzleClip,
                        HasSprinklerPolyValve: HasSprinklerPolyValve(system),
                    })){
                        dropOptions.push(dropType);
                    }            
                });
                break;
        case SprinklerManufacturerTypes.Komet:
            [DropTypes.None, DropTypes.Hose, DropTypes.Rigid].forEach(dropType => {
                if(SprinklerKometDropsOptionOk(dropType, {
                    Sprinkler: packageType,
                    AnyPolyLinedSpans: AnyPolyLinedSpans,
                    NozzleClipType: pck.NozzleClip,
                    HasSprinklerPolyValve: HasSprinklerPolyValve(system),
                })){
                    dropOptions.push(dropType);
                }            
            });
            break;
    }      
    
    return dropOptions;
}

export function getSpaceRange(pck: IPackage, system: ISystemBase){
    var plateExtras = DetermineSprinklerExtrasFromPackage(pck);
    var packageType = DeterminePackageTypeFromDeviceType(pck);
    var deviceType = DetermineSprinklerDeviceType({...plateExtras, packageType: packageType}, pck.Drop);
    var SpaceMin = 0;
    var SpaceMax = 0;
    switch(deviceType as DeviceTypes){
            case DeviceTypes.Nelson1518MediumSpacingBrassImpact:

            case DeviceTypes.Nelson15Single3RNBrassImpact:
            case DeviceTypes.Nelson15Double3RNBrassImpact:
            case DeviceTypes.Nelson15DoubleFCNBrassImpact:
            case DeviceTypes.Nelson8Double3RNPlasticImpact:
            case DeviceTypes.Nelson8DoubleFCNPlasticImpact:
                if(IsMaxigator(system)){
                    SpaceMin = 3.3;
                    SpaceMax = 20.0;
                }
                break;
            case DeviceTypes.NelsonR3000FCNRotatorBlue:
            case DeviceTypes.NelsonR3000RotatorBlue:
            case DeviceTypes.NelsonR3000RotatorWhite:
            case DeviceTypes.NelsonR3000FCNRotatorGreen:
            case DeviceTypes.NelsonR3000RotatorGreen:
                SpaceMin = 3.3;
                SpaceMax = IsMaxigator(system) ? 20.0 : 28.0;
                break;
            case DeviceTypes.NelsonR3000RotatorRed:
            case DeviceTypes.NelsonR3000RotatorBrown:
            case DeviceTypes.NelsonR3000RotatorOlive:
                SpaceMin = 3.3;
                SpaceMax = IsMaxigator(system) ? 20.0 : 24.0;
                break;
            case DeviceTypes.NelsonR3000RotatorOrange:
                SpaceMin = 3.3;
                SpaceMax = IsMaxigator(system) ? 20.0 : 22.0;
                break;               
            case DeviceTypes.NelsonS3000SpinnerPurple:
            case DeviceTypes.NelsonS3000SpinnerPurpleLowPressure:
                SpaceMin = 3.3;
                SpaceMax = IsMaxigator(system) ? 11.0 : 15.2;
                break;
            case DeviceTypes.NelsonS3000SpinnerRed:
            case DeviceTypes.NelsonD3000FCNSpray:
            case DeviceTypes.NelsonD3000Spray:
                SpaceMin = 3.3;
                SpaceMax = 11.0;
                break;
            case DeviceTypes.NelsonS3000SpinnerYellow:
            case DeviceTypes.NelsonS3000SpinnerYellowLowPressure:
                SpaceMin = 3.3;
                SpaceMax = IsMaxigator(system) ? 11.0 : 19.0;
                break;
            case DeviceTypes.NelsonO3000OrbitorBlack:
            case DeviceTypes.NelsonO3000OrbitorBlue:
            case DeviceTypes.NelsonO3000OrbitorPurple:
            case DeviceTypes.NelsonO3030OrbitorFXBlack:
            case DeviceTypes.NelsonO3030OrbitorFXWhite:
                SpaceMin = 3.3;
                SpaceMax = IsMaxigator(system) ? 11.0 : 20.0;
                break;
            case DeviceTypes.NelsonA3000AcceleratorMaroon:
            case DeviceTypes.NelsonA3000AcceleratorGold:
            case DeviceTypes.NelsonA3000AcceleratorNavyLowPressure:
                SpaceMin = 3.3;
                SpaceMax = 11.0;
                break;
            case DeviceTypes.NelsonA3000AcceleratorNavy:
                SpaceMin = 3.3;
                SpaceMax = 21;
            case DeviceTypes.KometTwisterBlack:
                SpaceMin = 3.3;
                SpaceMax = IsMaxigator(system) ? 11.0 : 20.0;
                break;
            case DeviceTypes.KometTwisterWhite:
                SpaceMin = 3.3;
                SpaceMax = IsMaxigator(system) ? 11.0 : 20.0;
                break;
            case DeviceTypes.KometTwisterBlue:
                SpaceMin = 3.3;
                SpaceMax = IsMaxigator(system) ? 11.0 : 18.0;
                break;
            case DeviceTypes.KometTwisterYellow:
                SpaceMin = 3.3;
                SpaceMax = IsMaxigator(system) ? 11.0 : 15.0;
                break;
            case DeviceTypes.KometSpray:
                SpaceMin = 3.3;
                SpaceMax = 11.0;
                break;
            case DeviceTypes.Senninger6DoubleWhiteVanePlasticImpact:
            case DeviceTypes.Senninger6DoubleRedVanePlasticImpact:
                SpaceMin = IsMaxigator(system) ? 3.3 : 0.0;
                SpaceMax = IsMaxigator(system) ? 20.0 : 0.0;
                break;
            case DeviceTypes.Senninger12MediumSpacingPlasticImpact:
                SpaceMin = IsMaxigator(system) ? 3.3 : 0.0;
                SpaceMax = IsMaxigator(system) ? 20.0 : 0.0;
                break;
            case DeviceTypes.SenningerHighAngleIWobBlack:
            case DeviceTypes.SenningerLowAngleIWobBlue:
            case DeviceTypes.SenningerStandardAngleIWobGray:
            case DeviceTypes.SenningerLowAngleIWobWhite:
            case DeviceTypes.SenningerXiWobBlack:
            case DeviceTypes.SenningerXiWobWhite:
            case DeviceTypes.SenningerXiWobBlue:
            case DeviceTypes.SenningerXiWobGray:
                SpaceMin = 3.3;
                SpaceMax = IsMaxigator(system) ? 13.0 : 20.0;
                break;
            case DeviceTypes.SenningerSuperSpray:
            case DeviceTypes.SenningerLDNSpray:
                SpaceMin = 3.3;
                SpaceMax = 10.0;
                break;
            case DeviceTypes.SenningerQuadSpray:
                SpaceMin = 3.3;
                SpaceMax = 7.0;
                break;
            case DeviceTypes.SenningerXcelWobbler:
                SpaceMin = 3.3;
                SpaceMax = IsMaxigator(system) ? 13.0 : 22.0;
                break;
            break;
    }

    return {SpaceMin: SpaceMin, SpaceMax: SpaceMax}
}

export enum SpacingDistances{
    a4_8,
    a5,
    a7,
    a10,
    a14_5,
    a15,
    a17,
    a20,
    a25,
    a27,
}

export enum OutletSpacingOptions{
    a40 = 0,
    a57 = 1,
    a114 = 2,
    aFurrow = 3,  //'60" Spacing
}


export function SpacingEnumToDouble(SpacingDistance: SpacingDistances): number{
    switch(SpacingDistance){
        case SpacingDistances.a4_8:
            return 4.8;
        case SpacingDistances.a5:
            return 5;
        case SpacingDistances.a7:
            return 7;
        case SpacingDistances.a10:
            return 10;
        case SpacingDistances.a14_5:
            return 14.5;
        case SpacingDistances.a15:
            return 15;
        case SpacingDistances.a17:
            return 17;
        case SpacingDistances.a20:
            return 20;
        case SpacingDistances.a25:
            return 25;
        case SpacingDistances.a27:
            return 27;
    }
}

export function SpacingValToSpacingEnum(val: number): SpacingDistances{
    switch(val){
        case 4.8:
            return SpacingDistances.a4_8;
        case 5:
            return SpacingDistances.a5;
        case 7:
            return SpacingDistances.a7;
        case 10:
            return SpacingDistances.a10;
        case 14.5:
            return SpacingDistances.a14_5;
        case 15:
            return SpacingDistances.a15;
        case 17:
            return SpacingDistances.a17;
        case 20:
            return SpacingDistances.a20;
        case 25:
            return SpacingDistances.a25;
        case 27:
            return SpacingDistances.a27;
    }
}

export interface SpacingClass{
    value: SpacingDistances;
    min: number;
    max: number;
}

function SpanFromLocation(l: number, side: IFlexSide | IFlangedSide, system: ISystemBase): number{
        if(l <= 30){
            return 1;
        }
        var k = sidef.NumberOfSpans(side);  
        for(let i = 1; i<=k; i++){
            const span = side.Span[i - 1];
            if(l >= StartingLocation(system, side, span) ||l <= EndingLocation(system, side, span)){
                return i;
            }
        }

        return k;
}

export function DetermineStartAndStopSpans(pck: IPackage, system: ISystemBase, side: IFlexSide | IFlangedSide, includeEOS = false){
    var iStart = 0;
    var iEnd = 0;

    if(pck.PackageNumber === 1){
        iStart = 1;
    }else{     
        iStart = SpanFromLocation(side.Sprinklers.Package[pck.PackageNumber - 2].EndingLocation + 0.01, side, system);
    }

    if(pck.EndingLocation === 0){
        iEnd = includeEOS ? side.Span.length : sidef.NumberOfSpans(side);     
    }else{
        iEnd = SpanFromLocation(pck.EndingLocation, side, system);
    }

    return {iStart: iStart, iEnd: iEnd,
        PackageCoversSAC: sidef.AnySwingArmSpans(side, iStart, iEnd)
    };
}


function getSpanOutlets(pck: IPackage, system: ISystemBase, side: IFlexSide | IFlangedSide){
    var SpanOutlets: boolean[] = Array(OutletSpacingOptions.a114 + 1);
    for(let i=0; i<SpanOutlets.length; i++){
        SpanOutlets[i] = false;
    }
    var startStop = DetermineStartAndStopSpans(pck, system, side);
    for(let i = startStop.iStart; i<=startStop.iEnd; i++){
        switch(side.Span[i - 1].Spacing){
            case 40:
                SpanOutlets[OutletSpacingOptions.a40] = true;
                break;
            case 57:
                SpanOutlets[OutletSpacingOptions.a57] = true;
                break;
            case 114:
                SpanOutlets[OutletSpacingOptions.a114] = true;
                break;
        }
    }
    return SpanOutlets;
}

const SpacingOptions: number[][] = Array(SpacingDistances.a27 + 1);
for (let i = 0; i < SpacingOptions.length; i++) {
    SpacingOptions[i] = Array(OutletSpacingOptions.aFurrow + 1);
}
SpacingOptions[SpacingDistances.a4_8][OutletSpacingOptions.a40] = 3.33;
SpacingOptions[SpacingDistances.a4_8][OutletSpacingOptions.a57] = 4.75;
SpacingOptions[SpacingDistances.a4_8][OutletSpacingOptions.a114] = 0;
SpacingOptions[SpacingDistances.a4_8][OutletSpacingOptions.aFurrow] = 0;

SpacingOptions[SpacingDistances.a5][OutletSpacingOptions.a40] = 0;
SpacingOptions[SpacingDistances.a5][OutletSpacingOptions.a57] = 0;
SpacingOptions[SpacingDistances.a5][OutletSpacingOptions.a114] = 0;
SpacingOptions[SpacingDistances.a5][OutletSpacingOptions.aFurrow] = 5;

SpacingOptions[SpacingDistances.a7][OutletSpacingOptions.a40] = 6.67;
SpacingOptions[SpacingDistances.a7][OutletSpacingOptions.a57] = 0;
SpacingOptions[SpacingDistances.a7][OutletSpacingOptions.a114] = 0;
SpacingOptions[SpacingDistances.a7][OutletSpacingOptions.aFurrow] = 0;

SpacingOptions[SpacingDistances.a10][OutletSpacingOptions.a40] = 10;
SpacingOptions[SpacingDistances.a10][OutletSpacingOptions.a57] = 9.5;
SpacingOptions[SpacingDistances.a10][OutletSpacingOptions.a114] = 9.5;
SpacingOptions[SpacingDistances.a10][OutletSpacingOptions.aFurrow] = 10;

SpacingOptions[SpacingDistances.a14_5][OutletSpacingOptions.a40] = 13.33;
SpacingOptions[SpacingDistances.a14_5][OutletSpacingOptions.a57] = 14.25;
SpacingOptions[SpacingDistances.a14_5][OutletSpacingOptions.a114] = 0;
SpacingOptions[SpacingDistances.a14_5][OutletSpacingOptions.aFurrow] = 0;

SpacingOptions[SpacingDistances.a15][OutletSpacingOptions.a40] = 0;
SpacingOptions[SpacingDistances.a15][OutletSpacingOptions.a57] = 0;
SpacingOptions[SpacingDistances.a15][OutletSpacingOptions.a114] = 0;
SpacingOptions[SpacingDistances.a15][OutletSpacingOptions.aFurrow] = 15;

SpacingOptions[SpacingDistances.a17][OutletSpacingOptions.a40] = 16.67;
SpacingOptions[SpacingDistances.a17][OutletSpacingOptions.a57] = 0;
SpacingOptions[SpacingDistances.a17][OutletSpacingOptions.a114] = 0;
SpacingOptions[SpacingDistances.a17][OutletSpacingOptions.aFurrow] = 0;

SpacingOptions[SpacingDistances.a20][OutletSpacingOptions.a40] = 20;
SpacingOptions[SpacingDistances.a20][OutletSpacingOptions.a57] = 19;
SpacingOptions[SpacingDistances.a20][OutletSpacingOptions.a114] = 19;
SpacingOptions[SpacingDistances.a20][OutletSpacingOptions.aFurrow] = 20;

SpacingOptions[SpacingDistances.a25][OutletSpacingOptions.a40] = 23.33;
SpacingOptions[SpacingDistances.a25][OutletSpacingOptions.a57] = 23.75;
SpacingOptions[SpacingDistances.a25][OutletSpacingOptions.a114] = 0;
SpacingOptions[SpacingDistances.a25][OutletSpacingOptions.aFurrow] = 0;

SpacingOptions[SpacingDistances.a27][OutletSpacingOptions.a40] = 26.67;
SpacingOptions[SpacingDistances.a27][OutletSpacingOptions.a57] = 0;
SpacingOptions[SpacingDistances.a27][OutletSpacingOptions.a114] = 0;
SpacingOptions[SpacingDistances.a27][OutletSpacingOptions.aFurrow] = 0;

function SkipFurrow(i: SpacingDistances, so: number, min: number, max: number, 
    SpaceMin: number, SpaceMax: number, distFeet: number, spanOutlets: boolean[]
): SpacingClass | undefined {
    for (let k = OutletSpacingOptions.a40; k <= OutletSpacingOptions.a114; k++) {
        if(spanOutlets[k]){
            so = SpacingOptions[i][k];
            if(so === 0){
                //'Skip if no furrow arm
                if(max === 0) return;
            }else{
                if(so < min) min = so;
                if(so > max) max = so;
            }
        }
    }
    if(distFeet >= SpaceMin && distFeet <= SpaceMax){
        return { value: i, min, max };
    }
    return undefined;
}

export function descriptionFromSpacingClass(spacingClass: SpacingClass, units: "feet" | "meters"): string {
    const { min, max } = spacingClass;
    let cMinEng = units === "feet" ? min.toFixed(1) + "'" : feetToMeters(min).toFixed(2) + "m";
    let cMaxEng = units === "feet" ? max.toFixed(1) + "'" : feetToMeters(max).toFixed(2) + "m";

    if(min !== max){
        return cMinEng + " - " + cMaxEng;
    }
    else {
        if (units === "feet"){
            return cMinEng + " / " + (12 * min).toFixed(0) + "\"";
        }
        else {
            return cMinEng;
        }
    }
}

export function getSpacingDropdownValues(pck: IPackage, system: ISystemBase, side: IFlangedSide | IFlexSide): SpacingClass[]{
        var bHasFurrowArm = pck.Drop === DropTypes.Hose && (pck.HoseDrop.UPipeReach === UPipeReaches.a12Inch || pck.HoseDrop.UPipeReach === UPipeReaches.a20Inch);
        var minMax = getSpaceRange(pck, system);
       
        const spanOutlets = getSpanOutlets(pck, system, side); 

        var SpacingArray: SpacingClass[] = [];
        var spacingDistances = [SpacingDistances.a4_8, SpacingDistances.a5, SpacingDistances.a7, SpacingDistances.a10, SpacingDistances.a14_5,SpacingDistances.a15,
            SpacingDistances.a17, SpacingDistances.a20, SpacingDistances.a25, SpacingDistances.a27];
        spacingDistances.forEach(i =>{
            var min = 500;
            var max = 0;
            var distFeet = SpacingEnumToDouble(i);
            if( bHasFurrowArm){
                var so = SpacingOptions[i][OutletSpacingOptions.aFurrow];            
                if(so !== 0){
                    if(so < min) min = so;
                    if(so > max) max = so;
                }                    
            }
            const sc = SkipFurrow(i, so, min, max, minMax.SpaceMin, minMax.SpaceMax, distFeet, spanOutlets);
            if (sc) {
                SpacingArray.push(sc);
            }
        });
        

    return SpacingArray;
}

export function getD3000PlateLabel(d3000: D3000Plates): string{
    switch(d3000){
        case D3000Plates.D6Gold:
            return "D6 - Gold";
        case D3000Plates.D4Maroon:
            return "D4 - Maroon";
        case D3000Plates.Red:
            return "Chemigation - Red";
        case D3000Plates.Purple:
            return "Convex - Fine -Purple";
        case D3000Plates.Turqoise:
            return "Flat - Smooth - Turq.";
        case D3000Plates.Black:
            return "Flat - Med - Black";
        case D3000Plates.Gray:
            return "Flat - Coarse - Gray";
        case D3000Plates.Orange:
            return "Flat - Extra Coarse - Orange";
        case D3000Plates.Brown:
            return "Concave - Multi Traj - Brown";
        case D3000Plates.Green:
            return "Concave - Fine - Green";
        case D3000Plates.Blue:
            return "Concave - Med - Blue";
        case D3000Plates.Tan:
            return "Bubble-Wide - Tan";
        case D3000Plates.None:
            return "None";
        default:
            return "";
    }
}

export function getDeviceTypeLabel(type: DeviceTypes){
    switch(type){
        case DeviceTypes.None:
            return "None";
        case DeviceTypes.NelsonR3000PartCircle:
            return "Nelson R3000 Partial Circle Rotator";
        case DeviceTypes.Senninger12MediumSpacingPlasticImpact:
            return "Senninger 12 Medium Spacing Plastic Impact";
        case DeviceTypes.SenningerXiWobBlue:
            return "Senninger Xi-Wob 610 Blue";
        case DeviceTypes.Senninger6DoubleWhiteVanePlasticImpact :
            return "Senninger 6 Double White Vane Plastic Impact";
        case DeviceTypes.Senninger6DoubleRedVanePlasticImpact:
            return "Senninger 6 Double Red Vane Plastic Impact";
        case DeviceTypes.NelsonO3000OrbitorPurple:
            return "Nelson O3000 Orbitor Purple";
        case DeviceTypes.SenningerStandardAngleIWobGray:
            return "Senninger Standard Angle I-Wob Gray";
        case DeviceTypes.NelsonS3000SpinnerRed:
            return "Nelson S3000 Spinner Red";
        case DeviceTypes.NelsonS3000SpinnerPurple:
            return "Nelson S3000 Spinner Purple";
        case DeviceTypes.NelsonS3000SpinnerYellow:
            return "Nelson S3000 Spinner Yellow";
        case DeviceTypes.NelsonS3000SpinnerPurpleLowPressure:
            return "Nelson S3000 Spinner Purple Low Pressure";
        case DeviceTypes.NelsonS3000SpinnerYellowLowPressure:
            return "Nelson S3000 Spinner Yellow Low Pressure";
        case DeviceTypes.NelsonD3000Spray:
            return "Nelson D3000 Spray";
        case DeviceTypes.NelsonD3000FCNSpray:
            return "Nelson D3000 FCN Spray"
        case DeviceTypes.NelsonR3000RotatorRed:
            return "Nelson R3000 Rotator Red";
        case DeviceTypes.NelsonR3000RotatorBlue:
            return "Nelson R3000 Rotator Blue";
        case DeviceTypes.NelsonR3000FCNRotatorBlue:
            return "Nelson R3000 FCN Rotator Blue";
        case DeviceTypes.NelsonR3000RotatorGreen:
            return "Nelson R3000 Rotator Green";
        case DeviceTypes.NelsonR3000FCNRotatorGreen:
            return "Nelson R3000 FCN Rotator Green";
        case DeviceTypes.NelsonR3000RotatorOrange:
            return "Nelson R3000 Rotator Orange";
        case DeviceTypes.NelsonR3000RotatorBrown:
            return " Nelson R3000 Rotator Brown";
        case DeviceTypes.NelsonR3000RotatorWhite:
            return "Nelson R3000 Rotator White";
        case DeviceTypes.NelsonA3000AcceleratorMaroon:
            return "Nelson A3000 Accelerator Maroon";
        case DeviceTypes.SenningerSuperSpray:
            return "Senninger Super Spray";
        case DeviceTypes.SenningerLDNSpray:
            return "Senninger LDN Spray";
        case DeviceTypes.SenningerLowAngleIWobWhite:
            return "Senninger Low Angle I-Wob White";
        case DeviceTypes.SenningerHighAngleIWobBlack:
            return "Senninger High Angle I-Wob Black"
        case DeviceTypes.SenningerLowAngleIWobBlue:
            return "Senninger Low Angle I-Wob Blue";
        case DeviceTypes.Senninger180Fan:
            return "Senninger 180 Fan Spray";
        case DeviceTypes.NelsonD3000PartCircle:
            return "Nelson D3000 Partial Circle Spray";
        case DeviceTypes.NelsonS3000PartCircle:
            return "Nelson S3000 Partial Circle Spinner";
        case DeviceTypes.SenningerXiWobGray:
            return "Senninger Xi-Wob 910 Gray";
        case DeviceTypes.SenningerXiWobBlack:
            return "Senninger Xi-Wob 615 Black";
        case DeviceTypes.KometTwisterBlack:
            return "Komet Twister Black";
        case DeviceTypes.NelsonA3000AcceleratorGold:
            return "Nelson A3000 Accelerator Gold";
        case DeviceTypes.NelsonO3000OrbitorBlack:
            return "Nelson O3000 Orbitor Black";
        case DeviceTypes.NelsonO3000OrbitorBlue:
            return "Nelson O3000 Orbitor Blue";
        case DeviceTypes.NelsonA3000AcceleratorNavy:
            return "Nelson A3000 Accelerator Navy";
        case DeviceTypes.NelsonA3000AcceleratorNavyLowPressure:
            return "Nelson A3000 Accelerator Navy Low Pressure";
        case DeviceTypes.KometTwisterBlue:
            return "Komet Twister Blue";
        case DeviceTypes.KometTwisterYellow:
            return "Komet Twister Yellow";
        case DeviceTypes.KometSpray:
            return "Komet Spray";
        case DeviceTypes.KometSprayPartCircle:
            return "Komet Partial Circle Spray";
        case DeviceTypes.KometTwisterPartCircle:
            return "Komet Partial Circle Twister";
        case DeviceTypes.KometTwisterWhite:
            return "Komet Twister White";
        case DeviceTypes.SenningerXcelWobbler:
            return "Senninger Xcel Wobbler";
        case DeviceTypes.NelsonR3000RotatorOlive:
            return "Nelson R3000 Rotator Olive";
        case DeviceTypes.NelsonO3030OrbitorFXWhite:
            return "Nelson O3030 OrbitorFX White";
        case DeviceTypes.NelsonO3030OrbitorFXBlack:
            return "Nelson O3030 OrbitorFX Black";
        default:
            return type;
    }
}

export const getPackageTypeLabel = (type: idxSprinkler, t: (s: string) => string): string => {
    var prefix = sprinklersPrefix + "package.package-types.";
    switch(type){
        case idxSprinkler.sprKometTwisterWhite:
            return t(prefix +"sprKometTwisterWhite");
        case idxSprinkler.sprKometTwisterBlack:
            return  t(prefix + "sprKometTwisterBlack");
        case idxSprinkler.sprKometTwisterBlue:
            return t(prefix + "sprKometTwisterBlue");
        case idxSprinkler.sprKometTwisterYellow:
            return t(prefix + "sprKometTwisterYellow");
        case idxSprinkler.sprKometSprayBlue:
            return t(prefix + "sprKometSprayBlue");
        case idxSprinkler.sprKometSprayGrey:
            return t(prefix + "sprKometSprayGrey");
        case idxSprinkler.sprKometSprayBlack:
            return t(prefix + "sprKometSprayBlack");
        case idxSprinkler.sprKometSprayYellow:
            return t(prefix + "sprKometSprayYellow");
        case idxSprinkler.sprSenningerQuadSpray:
            return t(prefix + "sprSenningerQuadSpray");
        case idxSprinkler.sprSenningerLDNSpray:
            return t(prefix + "sprSenningerLDNSpray");
        case idxSprinkler.sprSenningerSuperSpray:
            return t(prefix + "sprSenningerSuperSpray");
        case idxSprinkler.sprSenningerXiwob:
            return t(prefix + "sprSenningerXiwob");
        case idxSprinkler.sprSenningerXcelWobbler:
            return t(prefix + "sprSenningerXcelWobbler");
        case idxSprinkler.sprSenningerIWob:
            return t(prefix + "sprSenningerIWob");
        case idxSprinkler.sprSenningerVariableSpacingPlasticImpactRed:
            return t(prefix + "sprSenningerVariableSpacingPlasticImpactRed");
        case idxSprinkler.sprSenningerVariableSpacingPlasticImpactWhite:
            return t(prefix + "sprSenningerVariableSpacingPlasticImpactWhite");
        case idxSprinkler.sprSenningerConstantSpacingPlasticImpact:
            return t(prefix + "sprSenningerConstantSpacingPlasticImpact");
        case idxSprinkler.NelsonR3000Rotator:
            return t(prefix + "NelsonR3000Rotator");
        case idxSprinkler.NelsonS3000Spinner:
            return t(prefix + "NelsonS3000Spinner");
        case idxSprinkler.NelsonO3000Orbitor:
            return t(prefix + "NelsonO3000Orbitor");
        case idxSprinkler.NelsonO3030OrbitorFX:
            return t(prefix + "NelsonO3030OrbitorFX");
        case idxSprinkler.NelsonA3000Accelerator:
            return t(prefix + "NelsonA3000Accelerator");
        case idxSprinkler.NelsonD3000Spray:
            return t(prefix + "NelsonD3000Spray");
        default:
            return type.toString();
    }
}

export const getInitialPackageState = (packageNumber: number): IPackage => {
    return {
        Device: DeviceTypes.None,
        D3000: {
            BubblerClipLEPA: false,
            Plate1: null,
            Plate2: null,
            Plate3: null,
            SprinklerConverter: false,
        },
        DevicesDoubled: false,
        Drop: null,
        EndingLocation: 0,
        Fitting: {
            DropNextToEndGun: false,
            FittingType: null
        },
        HoseDrop: {
            DeviceWeight: null,
            DragAdapter: false,
            DragSock: false,
            GroundClearance: 0,
            HoseBottomClamp: null,
            HoseTopClamp: null,
            HoseTopFitting:null,
            RegulatorLocation: null,
            ReinkeBlue: false,
            SubstituteSTxHB: false,
            UPipeFitting: null,
            UPipeReach: null,
            UPipeType: null,
            UseKometTrussRodSlings: false,
            ScrewClamp: false,
            Weight: null,
            WeightBottomFitting: null,
            WeightTopFitting: null,
        },
        KometSpray: {
            Pad: null,
        },
        LDN: {
            BubblerShroud: false,
            ChemPad: null,
            LDNType: null,
            Pad: null
        },
        NormalSpacing: false,
        NozzleClip: null,
        PackageNumber: packageNumber,
        Regulator: {
            AsNeeded: false,
            Pressure: null,
            RegulatorType: null,
            Thread: null,
        },
        RigidDrop: {
            BottomFitting: null,
            DeviceWeight: null,
            DropMaterial: null,
            DropType: null,
            GroundClearance: 0,
            Length: 0,
            ReinforcementClamp: false,
            UPipeMaterial: null,            
        },
        SecondNozzleGPM: 0,
        Spacing: 0,
        SuperSprayPad: null,
        ThirdNozzleGPM: 0,
        TrashBusterBody: false,
        UseNelson3030: false,
        UseNelsonAD3030MT: false,
        Valve: null,
        DropSpacingGradually: false,
        userSelectedPlate: false
    };
}


