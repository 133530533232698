import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from "@mui/material";
import i18next, { t } from "i18next";
import * as React from "react";
import { FC } from "react";
import IProject from "../../../../model/project/IProject";
import { EDeleteItemMode } from "./EDeleteItemMode";

interface Props {
    open: boolean;
    onConfirm: () => any;
    onClose: () => any;
    mode: EDeleteItemMode;
    project: IProject;
    layoutId?: string;
}

const DeleteItemConfirmationDialog: FC<Props> = (props) => {
    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            aria-labelledby="confirm-delete-dialog-title"
            aria-describedby="confirm-delete-description"
        >
        <DialogTitle id="confirm-delete-dialog-title">
            {i18next.format(t("project-list.confirm-delete-item-title"), "capitalize-each")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="confirm-delete-description">
            <Typography>{i18next.format(t("project-list.confirm-delete-body-field"), "capitalize-each")}: {props.project.name}</Typography>
            {
              props.mode === EDeleteItemMode.PROJECT && (
                <Typography>{t("project-list.confirm-delete-body-multiple-layouts-part1")} {Object.keys(props.project.layouts || {}).length} {t("project-list.confirm-delete-body-multiple-layouts-part2")}</Typography>
              )
            }
            {
              props.mode === EDeleteItemMode.LAYOUT && (
                <Typography>{i18next.format(t("project-list.confirm-delete-body-layout"), "capitalize-each")}: {props.project.layouts[props.layoutId]?.name}</Typography>
              )
            }
            <br />
            <Typography>
              {t("project-list.confirm-delete-body-confirm-text")} {props.mode === EDeleteItemMode.PROJECT ? t("project-list.confirm-delete-body-field") : t("project-list.confirm-delete-body-layout")}?
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onClose}>{t("no")}</Button>
          <Button onClick={props.onConfirm} autoFocus>
            {t("yes")}
          </Button>
        </DialogActions>
      </Dialog>
    );
};

export default DeleteItemConfirmationDialog;