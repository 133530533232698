/**
 * List changes here...
 */

import IPartsPackage from "rdptypes/roe/IPartsPackage";
import partsPackage_2024081501 from "../2024081501";

export default {
    ...partsPackage_2024081501,
    id: "2024081901"
} as IPartsPackage;